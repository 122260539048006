import {
  closestCenter,
  DndContext,
  DragOverEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { ReactNode, useState } from "react";
import { Block } from "~/layout/block-list";
import { useUpdateEffect } from "~/util";
import BlockHeader from "./BlockHeader";

export const Item = ({ id, children }: { id: string; children: ReactNode }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Block ref={setNodeRef} style={style}>
      <BlockHeader sortable={{ attributes, listeners }}>{children}</BlockHeader>
    </Block>
  );
};

const SortableList = <T extends { id: string }>({
  items,
  onSort,
  children,
}: {
  items: T[];
  onSort: (ids: string[]) => void;
  children: (item: T) => ReactNode;
}) => {
  const itemIds = items.map(({ id }) => id);
  const [itemsOrder, setItemsOrder] = useState(itemIds);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useUpdateEffect(() => {
    onSort(itemsOrder);
  }, [itemsOrder]);

  useUpdateEffect(() => {
    setItemsOrder(itemIds);
  }, [items]);

  const handleDragEnd = ({ active, over }: DragOverEvent) => {
    if (!over || active.id === over.id) return;
    setItemsOrder((order) =>
      arrayMove(
        order,
        order.indexOf(active.id.toString()),
        order.indexOf(over.id.toString())
      )
    );
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={itemsOrder}
        strategy={verticalListSortingStrategy}
      >
        {itemsOrder.map((id) => (
          <Item key={id} id={id}>
            {children(items.find((item) => item.id === id) as T)}
          </Item>
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;

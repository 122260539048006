import React from "react";
import styled from "styled-components";
import { ImageLightbox } from "~/layout";
import { CostLine } from "./constants";

interface Props {
  image: CostLine["image"] | null;
  onRemove: () => void;
}

const StyledImageContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const StyledRemoveLink = styled.a`
  color: inherit;
  font-size: 0.875rem;
  text-decoration: underline;
`;

const CostLineImage: React.FC<Props> = ({ image, onRemove }) => {
  if (!image) return null;
  return (
    <StyledImageContainer>
      <ImageLightbox images={[image]} />
      <br />
      <StyledRemoveLink
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onRemove();
        }}
      >
        Slett
      </StyledRemoveLink>
    </StyledImageContainer>
  );
};

export default CostLineImage;

import gql from "graphql-tag";
import { attachmentFragment } from "~/attachment";

export const CONSTRUCTION_LOAN_PERIODS_QUERY = gql`
  query constructionLoanPeriods($slug: ID!) {
    report(slug: $slug) {
      id
      ... on ConstructionLoan {
        periods {
          id
          full
          published
          publishedAt
          status
          startedAt
          title
        }
      }
    }
  }
`;

export const CONSTRUCTION_LOAN_CYCLE_IMAGES_QUERY = gql`
  query constructionLoanPeriodImages($slug: ID!, $period: ID) {
    report(slug: $slug) {
      id
      ... on ConstructionLoan {
        cycle(period: $period) {
          images {
            ...AttachmentFields
          }
        }
      }
    }
  }
  ${attachmentFragment}
`;

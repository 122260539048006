import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, SelectField } from "~/form";
import { Button } from "~/layout";
import Icon from "~/layout/icon";
import { Member } from "~/report/member";
import { toOptions, User, userFragment, WorkingArea } from "~/user";
import { useUpdateEffect } from "~/util";
import { Report } from "../constants";
import {
  ReportConsultants,
  reportSetConsultantsMutation,
  useReportConsultants,
} from "../member/consultant";

interface Props {
  report: Pick<Report, "slug">;
  workingArea: WorkingArea;
}

const CONSULTANTS_QUERY = gql`
  query consultants($workingArea: String!) {
    consultants(workingArea: $workingArea) {
      ...UserFields
    }
  }
  ${userFragment}
`;

const StyledForm = styled(Form)`
  .row {
    margin-bottom: 1rem;
  }
  .form-group {
    margin-bottom: 0;
  }
`;

const labels = {
  construction: "Bygg",
  plumbing: "Vvs",
  electrical: "Elektro",
  fire: "Brann",
};

const resolver = ({
  consultantIds,
  ksConsultantIds,
}: {
  consultantIds: string[];
  ksConsultantIds: string[];
}) => {
  return {
    values: { consultantIds, ksConsultantIds },
    errors:
      (consultantIds?.length || 0) === 0 && ksConsultantIds?.length > 0
        ? {
            ksConsultantIds: {
              type: "validation",
              message: (
                <>
                  <Icon icon="warning" /> Cannot have only KS consultants
                </>
              ),
            },
          }
        : {},
  };
};

const ReportConsultantsForm: React.FC<Props> = ({
  report: { slug },
  workingArea,
}) => {
  const formMethods = useForm({
    defaultValues: {
      consultantIds: [],
      ksConsultantIds: [],
    },
    mode: "all",
    resolver,
  });
  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const [editable, setEditable] = useState(false);
  const { loading, report } = useReportConsultants({ slug }, workingArea);
  const { data: consultantsData } = useQuery<{ consultants: User[] }>(
    CONSULTANTS_QUERY,
    {
      variables: { workingArea },
    }
  );

  // const { loading, data } = useQuery(reportConsultantsQuery(workingArea), {
  //   variables: { slug },
  // });
  // const report = data?.report;

  const [setConsultants] = useMutation(
    reportSetConsultantsMutation(workingArea)
  );

  useUpdateEffect(() => {
    reset({
      consultantIds: (
        report?.[(workingArea + "Consultants") as keyof ReportConsultants] || []
      ).map((m: Member) => m.userId),
      ksConsultantIds: (
        report?.[(workingArea + "KsConsultants") as keyof ReportConsultants] ||
        []
      ).map((m: Member) => m.userId),
    });
  }, [loading]);

  const onSubmit = ({
    consultantIds,
    ksConsultantIds,
  }: {
    consultantIds: string[];
    ksConsultantIds: string[];
  }) =>
    setConsultants({
      variables: {
        input: {
          reportId: report?.id,
          workingArea,
          consultantIds,
          ksConsultantIds,
        },
      },
      // optimisticResponse: reportMembersOptimisticResponse({
      //   report: data.report,
      //   relation: "project_manager",
      //   users: projectManagers,
      //   newUserIds: userIds,
      // }),
    });

  const consultants = consultantsData?.consultants || [];

  // if (error) return <p>Error :(</p>;

  return (
    <StyledForm formMethods={formMethods} onSubmit={onSubmit}>
      <Row className="align-items-end">
        <Col>
          <SelectField
            name="consultantIds"
            label={labels[workingArea]}
            isDisabled={!editable}
            isMulti
            isLoading={loading}
            options={toOptions(consultants)}
          />
        </Col>
        <Col>
          <SelectField
            name="ksConsultantIds"
            label={`KS ${labels[workingArea]}`}
            isDisabled={!editable}
            isMulti
            isLoading={loading}
            options={toOptions(consultants)}
          />
        </Col>
        <Col lg="auto">
          {!editable && (
            <Button color="mystic" onClick={() => setEditable(!editable)}>
              Rediger
            </Button>
          )}
          {editable && (
            <>
              <Button
                color="yellow"
                // disabled={pristine || submitting}
                onClick={async () => {
                  await handleSubmit(onSubmit)();
                  if (isValid) setEditable(false);
                }}
              >
                Lagre
              </Button>
              &nbsp; &nbsp;
              <Button
                color="danger"
                // disabled={submitting}
                onClick={() => {
                  setEditable(!editable);
                  reset();
                }}
              >
                Avbryt
              </Button>
            </>
          )}
        </Col>
      </Row>
    </StyledForm>
  );
};

export default ReportConsultantsForm;

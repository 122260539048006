import React from "react";
import { Icons } from "~/layout/icon";
import { getPieData } from "./helpers";
import Legend from "./Legend";
import { Canvas, SVG, Title, Total, Wrapper } from "./styled";

export interface ChartProps {
  title?: string;
  pieLabel?: string;
  subLabel?: string;
  data: {
    value: number | null;
    label: string;
    color?: string;
    icon?: Icons;
  }[];
  showZero?: true;
  small?: true;
  hideValue?: true;
  hideLabel?: true;
}

const Chart: React.FC<ChartProps> = ({
  data: dataProp,
  title,
  pieLabel,
  subLabel,
  small,
  ...rest
}) => {
  const { data, total } = getPieData(dataProp);
  return (
    <Wrapper small={small}>
      <Title>{title}</Title>
      <Canvas>
        <SVG viewBox="-2 -2 36 36">
          {data.map(({ color, key, offset, percentage, width }) => (
            <circle
              key={key}
              cx="16"
              cy="16"
              fill="none"
              r="15.9"
              stroke={color}
              strokeDasharray={`${percentage}, 100`}
              strokeDashoffset={offset}
              strokeWidth={width}
            ></circle>
          ))}
        </SVG>
        <Total
          total={total}
          header={pieLabel}
          footer={subLabel}
          small={small}
        />
      </Canvas>
      <Legend data={dataProp} small={small} {...rest} />
    </Wrapper>
  );
};

export default Chart;

import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import invariant from "tiny-invariant";
import { Form } from "~/form";
import { User } from "~/graphql-operations";
import { Report } from "~/report";
import {
  reportMembersMultiSelectMutation,
  reportMembersMultiSelectQuery,
  reportMembersOptimisticResponse,
} from "~/report/member";
import { UserSelectField } from "~/user";

interface Props {
  report: {
    slug: string;
  };
}

const ReportProjectManagersForm: React.FC<Props> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery<{
    report: Report;
    projectManagers: User[];
  }>(reportMembersMultiSelectQuery("projectManagers"), {
    variables: { slug },
  });
  const [setProjectManagers] = useMutation(
    reportMembersMultiSelectMutation("projectManagers")
  );

  const reportProjectManagers = data?.report?.projectManagers || [];
  const projectManagers = data?.projectManagers || [];

  const values = {
    userIds: reportProjectManagers.map((pm) => pm.userId),
  };

  const onSubmit = ({ userIds }: { userIds: string[] }) => {
    const report = data?.report;
    invariant(report, "No report loaded.");

    return setProjectManagers({
      variables: {
        input: {
          reportId: report.id,
          userIds,
        },
      },
      optimisticResponse: reportMembersOptimisticResponse({
        report,
        relation: "project_manager",
        users: projectManagers,
        newUserIds: userIds,
      }),
    });
  };

  if (error) return <p>Error :(</p>;

  return (
    <Form autoSave onSubmit={onSubmit} values={values}>
      <UserSelectField
        name="userIds"
        label="Prosjektansvarlig"
        isLoading={loading}
        users={projectManagers}
        required
        isMulti
        role
      />
    </Form>
  );
};

export default ReportProjectManagersForm;

import { gql, useApolloClient } from "@apollo/client";
import React from "react";
import SaveButton, { SaveButtonProps } from "~/layout/Button/SaveButton";

export interface Props extends SaveButtonProps {
  onFetch: (data: QueryResult["currencyRates"] | undefined) => void;
  style?: React.CSSProperties;
}

const CURRENCY_EXCHANGE_RATES_QUERY = gql`
  query {
    currencyRates {
      sek
      dkk
      eur
      usd

      date
      source
      link
    }
  }
`;

type QueryResult = {
  currencyRates: {
    sek: number;
    dkk: number;
    eur: number;
    usd: number;

    date: string;
    source: string;
    link: string;
  };
};

const CurrencyRatesFetch: React.FC<Props> = ({ onFetch, ...rest }) => {
  const client = useApolloClient();

  const onClick = async () => {
    const { data } = await client.query<QueryResult>({
      query: CURRENCY_EXCHANGE_RATES_QUERY,
      // fetchPolicy: "no-cache",
    });
    return onFetch(data?.currencyRates);
  };

  return (
    <SaveButton
      icon="sync"
      iconOnly
      variant="info"
      onClick={onClick}
      {...rest}
    />
  );
};

export default CurrencyRatesFetch;

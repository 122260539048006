import { useQuery } from "@apollo/client";
import React from "react";
import { SelectField } from "~/form";
import {
  DocumentationBlockTemplate,
  DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
} from ".";

interface Props {
  name: string;
  label?: string | null;
  required?: boolean;
}

const DocumentationBlockTemplateSelectField: React.FC<Props> = ({
  name,
  label,
  required = false,
}) => {
  const { loading, error, data } = useQuery<{
    documentationBlockTemplates: DocumentationBlockTemplate[];
  }>(DOCUMENTATION_BLOCK_TEMPLATES_QUERY);

  if (error) return <p>Error :(</p>;

  const documentationBlockTemplateOptions = (
    data?.documentationBlockTemplates || []
  ).map(({ id, title }) => ({
    value: id,
    label: title,
  }));

  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={documentationBlockTemplateOptions}
      required={required}
    />
  );
};

export default DocumentationBlockTemplateSelectField;

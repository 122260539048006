import { gql } from "@apollo/client";
import { Attachment, attachmentFragment } from "~/attachment";
import { ResourceInterface } from "~/node";
import { DocumentationBlock } from "../block";
import { DocumentationLineTemplate } from "../line-template";

export type DocumentationLineRatingValue = 1 | 2 | 3 | 4 | 5 | 6;

export interface DocumentationLine extends ResourceInterface {
  documentationBlock: DocumentationBlock;
  documentationLineTemplate: DocumentationLineTemplate;

  title: string;
  number: string;
  description: string;

  rating1: DocumentationLineRatingValue;
  rating2: DocumentationLineRatingValue;
  rating3: DocumentationLineRatingValue;

  averageRating: number;
  maxScore: number;
  score: number;

  rating1Files: Attachment[];
  rating2Files: Attachment[];
  rating3Files: Attachment[];
}

export const documentationLineFragment = gql`
  fragment DocumentationLineFields on DocumentationLine {
    id
    title
    number
    description

    rating1
    rating2
    rating3

    averageRating
    maxScore
    score

    rating1Files {
      ...AttachmentFields
    }
    rating2Files {
      ...AttachmentFields
    }
    rating3Files {
      ...AttachmentFields
    }
  }
  ${attachmentFragment}
`;

export const DOCUMENTATION_LINE_CREATE_MUTATION = gql`
  mutation DocumentationLineCreate($input: DocumentationLineCreateInput!) {
    documentationLineCreate(input: $input) {
      documentationLine {
        ...DocumentationLineFields
      }
    }
  }
  ${documentationLineFragment}
`;

export const DOCUMENTATION_LINE_UPDATE_MUTATION = gql`
  mutation DocumentationLineUpdate($input: DocumentationLineUpdateInput!) {
    documentationLineUpdate(input: $input) {
      documentationLine {
        ...DocumentationLineFields
      }
    }
  }
  ${documentationLineFragment}
`;

export const DOCUMENTATION_LINE_DELETE_MUTATION = gql`
  mutation DocumentationLineDelete($input: DocumentationLineDeleteInput!) {
    documentationLineDelete(input: $input) {
      documentationLine {
        ...DocumentationLineFields
        documentationBlock {
          id
          maxScore
          score
        }
      }
    }
  }
  ${documentationLineFragment}
`;

import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from ".";

const NAME_REPORT_QUERY = gql`
  query ProsjektinformasjonProsjektnavn($slug: ID!) {
    report(slug: $slug) {
      id
      name
    }
  }
`;

const ProsjektinformasjonProsjektnavn: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(NAME_REPORT_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  const { name } = data?.report || {};

  return <>{loading ? <WordLoader /> : `${name}`}</>;
};

export default ProsjektinformasjonProsjektnavn;

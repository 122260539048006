import { pick } from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { SubmitHandler } from "react-hook-form";
import {
  DateField,
  Form,
  SaveFormButton,
  TextAreaField,
  TextField,
} from "~/form";
import { Event } from ".";

interface Props {
  event?: Event;
  onSubmit: SubmitHandler<Partial<Event>>;
  onDelete?: SubmitHandler<Event>;
}

const EventForm: React.FC<Props> = ({ event, onSubmit, ...rest }) => {
  const { id } = event || {};

  return (
    <Form
      defaultValues={
        event
          ? pick(event, [
              "agenda",
              "attendees",
              "date",
              "description",
              "notes",
              "place",
              "url",
            ])
          : {}
      }
      onSubmit={onSubmit}
      resetBlank={!id}
      autoSave={!!id}
      {...rest}
    >
      <Row className={id ? "align-items-center" : ""}>
        <Col lg="5">
          <TextField name="place" label="Sted" required />
        </Col>
        <Col lg="3">
          <DateField name="date" label="Dato" required />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <TextAreaField name="description" label="Beskrivelse" required />
        </Col>
        <Col lg="6">
          <TextAreaField name="notes" label="Notater" />
        </Col>
      </Row>
      <Row>
        <Col lg="8">
          <TextField name="url" />
        </Col>
      </Row>
      {!id && <SaveFormButton />}
    </Form>
  );
};

export default EventForm;

import React, { DOMAttributes } from "react";
import styled from "styled-components";
import { Toggled } from "../../collapsible";

const StyledBody = styled.div`
  padding: 1rem;
`;

const Body: React.FC<DOMAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  return (
    <Toggled>
      <StyledBody {...rest}>{children}</StyledBody>
    </Toggled>
  );
};

export default Body;

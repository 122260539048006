import { gql, useQuery } from "@apollo/client";
import { MaintenanceReport, Report } from "../constants";

const MAINTENANCE_DURATION_QUERY = gql`
  query ReportMaintenanceDuration($id: ID!) {
    publication(id: $id) {
      ... on BuildingMaintenance {
        maintenance {
          duration
        }
      }
    }
  }
`;

// const MAINTENANCE_INTERVALS_QUERY = gql`
//   query ReportMaintenanceIntervals($slug: ID!) {
//     report(slug: $slug) {
//       id
//       ... on Maintainable {
//         maintenance {
//           intervals
//         }
//       }
//     }
//   }
// `;

const MAINTENANCE_PERIODS_QUERY = gql`
  query ReportMaintenancePeriods($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Maintainable {
        maintenance {
          periods
        }
      }
    }
  }
`;

export const useMaintenanceDuration = ({ id }: Pick<Report, "id">) => {
  const { loading, error, data } = useQuery(MAINTENANCE_DURATION_QUERY, {
    variables: { id },
  });

  return {
    loading,
    error,
    duration: data?.publication.maintenance.duration,
  };
};

// export const useMaintenanceIntervals = ({ slug }: Pick<Report, "slug">) => {
//   const { loading, error, data } = useQuery<{
//     report: Pick<MaintenanceReport, "maintenance">;
//   }>(MAINTENANCE_INTERVALS_QUERY, {
//     variables: { slug },
//   });

//   return {
//     loading,
//     error,
//     maintenanceIntervals: data?.report.maintenance.intervals || [],
//   };
// };

export const useMaintenancePeriods = ({ slug }: Pick<Report, "slug">) => {
  const { loading, error, data } = useQuery<{
    report: Pick<MaintenanceReport, "maintenance">;
  }>(MAINTENANCE_PERIODS_QUERY, {
    variables: { slug },
  });

  return {
    loading,
    error,
    periods: data?.report.maintenance.periods || [],
  };
};

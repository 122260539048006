import React from "react";
import { Col, Form as BSForm } from "react-bootstrap";
import { Form, NumberField, Row } from "~/form";
import {
  ConstructionLoanPeriod,
  UpdatePeriodLoanInput,
  useUpdatePeriodLoanMutation,
} from "~/graphql-operations";
import { formatCurrency } from "~/util/currency";

interface Props {
  period: Pick<
    ConstructionLoanPeriod,
    "id" | "title" | "loanAmount" | "equity" | "published"
  >;
}

const EditLoan: React.FC<Props> = ({
  period: { id, loanAmount, equity, published, title },
}) => {
  const [update] = useUpdatePeriodLoanMutation();

  const onSubmit = (input: UpdatePeriodLoanInput) => {
    update({ variables: { input } });
  };

  const formattedTotal =
    loanAmount || equity
      ? formatCurrency((loanAmount || 0) + (equity || 0))
      : "";

  return (
    <Form
      defaultValues={{ id, loanAmount, equity }}
      onSubmit={onSubmit}
      autoSave
      horizontal
    >
      <Row>
        <Col>
          <BSForm.Group>
            <BSForm.Label>Period</BSForm.Label>
            <BSForm.Control name="of-no-importance" value={title} disabled />
          </BSForm.Group>
        </Col>
        <Col lg="auto">
          <NumberField
            name="loanAmount"
            label="Lånebeløp"
            append="NOK"
            disabled={published}
          />
        </Col>
        <Col lg="auto">
          <NumberField
            name="equity"
            label="Egenkapital"
            append="NOK"
            disabled={published}
          />
        </Col>
        <Col lg="auto">
          <BSForm.Group>
            <BSForm.Label>Totalt</BSForm.Label>
            <BSForm.Control
              name="of-no-importance"
              value={formattedTotal}
              disabled
            />
          </BSForm.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default EditLoan;

import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { NumberFormatProps } from "react-number-format";
import { InputFieldProps } from ".";
import FieldError from "./FieldError";
import NumberInput from "./NumberInput";

export interface NumberFieldProps extends InputFieldProps {
  min?: number;
  max?: number;
  step?: number;
  placeholder?: number;
  decimals?: boolean;
}
// export const NumberField = forwardRef(
//   (props: NumberFieldProps, ref: React.Ref<Bla>) => (
//     <InputField ref={ref} type={INPUT_TYPES.NUMBER} min={0} {...props} />
//   )
// );
// NumberField.displayName = "NumberField";

/**
 * !!!
 * react-number-format: "4.4.1" resets value on NULL (resetBlank), new versions do NOT
 * !!!
 */
const NumberField: React.FC<NumberFieldProps> = ({
  name,
  label = name,
  required,
  validation,
  prepend,
  append,
  disabled,
  placeholder,
  decimals,
  // ...rest
}) => {
  const { control } = useFormContext();

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const onValueChange: NumberFormatProps["onValueChange"] = ({
            floatValue,
          }) => onChange(floatValue ?? null);

          const formControl = (
            <NumberInput
              className={error && "is-invalid"}
              fixedDecimalScale={!!decimals}
              onValueChange={onValueChange}
              value={value ?? null}
              placeholder={placeholder}
              disabled={disabled}
            />
          );
          return (
            <>
              {prepend || append ? (
                <>
                  <InputGroup>
                    {prepend && (
                      <InputGroup.Prepend>
                        <InputGroup.Text>{prepend}</InputGroup.Text>
                      </InputGroup.Prepend>
                    )}
                    {formControl}
                    {append && (
                      <InputGroup.Append>
                        <InputGroup.Text>{append}</InputGroup.Text>
                      </InputGroup.Append>
                    )}
                  </InputGroup>
                </>
              ) : (
                formControl
              )}
              {error && <FieldError error={error} />}
            </>
          );
        }}
        rules={validation || { required }}
        // {...rest}
      />
    </Form.Group>
  );
};

export default NumberField;

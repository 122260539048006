import React from "react";
import styled from "styled-components";
import Icon from "~/layout/icon";

interface Props {
  estimate: number | null;
  actual: number | null;
  style: React.CSSProperties;
}

const StyledWrap = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 300;
`;

const CostTrend: React.FC<Props> = ({ estimate, actual, ...rest }) => {
  if (!estimate || !actual) return null;

  const trend = (actual / estimate) * 100 - 100;

  return (
    <StyledWrap {...rest}>
      {trend.toLocaleString("en-US", { maximumFractionDigits: 2 })}%
      {trend > 0 ? (
        <Icon icon="caretUp" color="red" />
      ) : (
        <Icon icon="caretDown" color="green" />
      )}
    </StyledWrap>
  );
};

export default CostTrend;

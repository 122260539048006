import React, { createContext, useContext } from "react";
import { Report } from ".";

export type ReportContextType = (Partial<Report> & Pick<Report, "id">) | null;

const ReportContext = createContext<ReportContextType>(null);

export const useReport = (): ReportContextType => useContext(ReportContext);

const ReportProvider: React.FC<{ report: Report }> = ({ report, children }) => {
  return (
    <ReportContext.Provider value={report}>{children}</ReportContext.Provider>
  );
};

export default ReportProvider;

import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, SelectField } from "~/form";
import {
  reportMembersMultiSelectMutation,
  reportMembersMultiSelectQuery,
} from "~/report/member";
import { toOptions, User } from "~/user";
import { Report } from "../constants";
import { Member, reportMembersOptimisticResponse } from "../member";

interface Props {
  report: {
    slug: string;
  };
}

const ReportPartnersForm: React.FC<Props> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery<{
    report: Report;
    partners: User[];
  }>(reportMembersMultiSelectQuery("partners"), {
    variables: { slug },
  });
  const [setPartners] = useMutation(
    reportMembersMultiSelectMutation("partners")
  );

  const reportPartners = data?.report?.partners || [];
  const partners = data?.partners || [];

  const formMethods = useForm();
  const { reset } = formMethods;

  useEffect(() => {
    reset({
      userIds: reportPartners.map((pm: Member) => pm.userId),
    });
  }, [reportPartners.length > 0]);

  const onSubmit = ({ userIds }: { userIds: string[] }) => {
    setPartners({
      variables: {
        input: {
          reportId: data?.report?.id,
          userIds,
        },
      },
      optimisticResponse: reportMembersOptimisticResponse({
        report: data.report,
        relation: "partner",
        users: partners,
        newUserIds: userIds,
      }),
    });
  };

  if (error) return <p>Error :(</p>;

  return (
    <Form autoSave formMethods={formMethods} onSubmit={onSubmit}>
      <SelectField
        name="userIds"
        label="Kvalitetssikret av"
        isMulti
        isLoading={loading}
        options={toOptions(partners)}
      />
    </Form>
  );
};

export default ReportPartnersForm;

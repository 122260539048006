import { ApolloProvider, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { BuildingTypeListEditable, BuildingTypeListSortable } from ".";
import BlockListHeaderEditableSortable from "../layout/BlockList/BlockListHeaderEditableSortable";
import { client } from "../setup";
import { BuildingType, BUILDING_TYPES_QUERY } from "./constants";
import NewBuildingTypeForm from "./NewBuildingTypeForm";

interface Props {
  costIndex: number;
}

type State = "editable" | "sortable";

const BuildingTypeIndexPage: React.FC<Props> = ({ costIndex }) => {
  const [state, setState] = useState<State>("editable");
  const { loading, error, data } = useQuery<{ buildingTypes: BuildingType[] }>(
    BUILDING_TYPES_QUERY
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const onSortable = () => setState("sortable");
  const onEditable = () => setState("editable");

  const { buildingTypes = [] } = data || {};
  const Component =
    state == "sortable" ? BuildingTypeListSortable : BuildingTypeListEditable;

  return (
    <div className="reports">
      <div className="container">
        <div className="cardx">
          <div className="cardx-content">
            <BlockListHeaderEditableSortable
              onSortable={onSortable}
              onEditable={onEditable}
              state={state}
            />
            <Component buildingTypes={buildingTypes} costIndex={costIndex} />
          </div>
        </div>

        <div className="cardx">
          <div className="cardx-content">
            <NewBuildingTypeForm />
          </div>
        </div>
      </div>
    </div>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <BuildingTypeIndexPage {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import { useQuery } from "@apollo/client";
import React from "react";
import { SelectField } from "~/form";
import { CostBlockTemplate, COST_BLOCK_TEMPLATES_QUERY } from ".";

interface Props {
  name: string;
  label?: string | null;
  required?: boolean;
}

const CostBlockTemplateSelectField: React.FC<Props> = ({
  name,
  label,
  required = false,
}) => {
  const { loading, error, data } = useQuery<{
    costBlockTemplates: CostBlockTemplate[];
  }>(COST_BLOCK_TEMPLATES_QUERY);

  if (error) return <p>Error :(</p>;

  const costBlockTemplateOptions = (data?.costBlockTemplates || []).map(
    ({ id, number, title }) => ({
      value: id,
      label: `#${number} — ${title}`,
    })
  );

  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={costBlockTemplateOptions}
      required={required}
    />
  );
};

export default CostBlockTemplateSelectField;

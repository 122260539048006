import { ApolloProvider, useMutation } from "@apollo/client";
import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import { AddFormButton, Form, NumberField, Row, TextField } from "~/form";
import { client } from "../setup";
import { Building, BUILDING_CREATE_MUTATION } from "./constants";
import BuildingTypeSelectField from "./Form/BuildingTypeSelectField";

interface Props {
  reportId: string;
}

const NewBuilding: React.FC<Props> = ({ reportId }) => {
  const [createBuilding] = useMutation(BUILDING_CREATE_MUTATION);

  const onSubmit = async (input: Partial<Building>) => {
    return createBuilding({
      variables: {
        input,
      },
    });
  };

  return (
    <Form defaultValues={{ reportId }} onSubmit={onSubmit} resetBlank>
      <Row className="align-items-end">
        <Col>
          <TextField name="name" label="Navn" required />
        </Col>
        <Col lg="3">
          <BuildingTypeSelectField
            name="buildingTypeId"
            label="Type"
            required
          />
        </Col>
        <Col lg="2">
          <NumberField
            name="area"
            label={
              <>
                BTA (M<sup>2</sup>)
              </>
            }
            // disabled={values.autoTotal}
          />
        </Col>
        <Col lg="2">
          <NumberField name="constructionYear" label="Byggeår" />
        </Col>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <NewBuilding {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

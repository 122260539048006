import { gql } from "@apollo/client";

export interface BuildingType {
  id: string;
  name: string;
  cost: number;
  order: number;
  default: boolean;
  hidden: boolean;
}

export const buildingTypeFieldsFragment = gql`
  fragment BuildingTypeFields on BuildingType {
    id
    name
    cost
    order
    default
    hidden
  }
`;

export const BUILDING_TYPES_QUERY = gql`
  query BuildingTypes($default: Boolean) {
    buildingTypes(default: $default) {
      ...BuildingTypeFields
    }
  }
  ${buildingTypeFieldsFragment}
`;

export const BUILDING_TYPE_CREATE_MUTATION = gql`
  mutation BuildingTypeCreate($input: BuildingTypeCreateInput!) {
    buildingTypeCreate(input: $input) {
      id
      ...BuildingTypeFields
    }
  }
  ${buildingTypeFieldsFragment}
`;

export const BUILDING_TYPE_UPDATE_MUTATION = gql`
  mutation BuildingTypeUpdate($input: BuildingTypeUpdateInput!) {
    buildingTypeUpdate(input: $input) {
      id
      ...BuildingTypeFields
    }
  }
  ${buildingTypeFieldsFragment}
`;

export const BUILDING_TYPES_SORT_MUTATION = gql`
  mutation BuildingTypesSort($input: BuildingTypesSortInput!) {
    buildingTypesSort(input: $input) {
      id
      order
    }
  }
`;

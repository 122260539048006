import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Fieldset, FieldsetTitle } from "~/form";
import { client } from "~/setup";
import ReportPartnersForm from "../form/ReportPartnersForm";
import ReportProjectManagersForm from "../form/ReportProjectManagersForm";

interface Props {
  report: {
    slug: string;
  };
}

const EditConsultants: React.FC<Props> = ({ report }) => (
  <ApolloProvider client={client}>
    <Fieldset>
      <FieldsetTitle>Prosjekteiere</FieldsetTitle>
      <Row>
        <Col lg="6">
          <ReportProjectManagersForm report={report} />
        </Col>
        <Col lg="6">
          <ReportPartnersForm report={report} />
        </Col>
      </Row>
    </Fieldset>
  </ApolloProvider>
);

export default EditConsultants;

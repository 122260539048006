import React from "react";
import styled from "styled-components";
import {
  MaintenanceInterval,
  MaintenancePeriod,
  maintenancePeriodLabel,
  MaintenancePriority,
  maintenancePriorityLabel,
  useMaintenancePeriods,
} from "~/report/maintenance";
import { ShortcodeProps } from "../Shortcode";
import Chart from "./Chart";

const ChartsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 6rem;
`;

const H3 = styled.h3`
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 3rem;
`;

const intervalsFromPeriods = (periods: MaintenancePeriod[]) =>
  periods.filter((period) =>
    Object.values(MaintenanceInterval).includes(period)
  );

const CostOverviewCharts: React.FC<ShortcodeProps> = ({ report }) => {
  const { periods } = useMaintenancePeriods(report);
  const intervals = intervalsFromPeriods(periods);
  const has = (period: MaintenancePeriod) => periods.includes(period);

  return (
    <>
      <ChartsRow>
        {[
          MaintenancePeriod.MISSING_REGULATION,
          MaintenancePeriod.IMMEDIATE,
        ].map(
          (period) =>
            has(period) && (
              <Chart
                title={maintenancePeriodLabel(period)}
                report={report}
                period={period}
                key={period}
              />
            )
        )}
      </ChartsRow>

      {intervals.map((interval) => (
        <div key={interval}>
          <H3>{maintenancePeriodLabel(interval)}</H3>
          <ChartsRow>
            {[
              MaintenancePriority.HIGH,
              MaintenancePriority.MEDIUM,
              MaintenancePriority.LOW,
              MaintenancePriority.REPLACEMENT,
            ].map((priority) => (
              <Chart
                title={maintenancePriorityLabel(priority)}
                report={report}
                period={interval}
                priority={priority}
                key={`${interval}-${priority}`}
                small
                hideLabel
                hideValue
              />
            ))}
          </ChartsRow>
        </div>
      ))}
    </>
  );
};

export default CostOverviewCharts;

import React from "react";
import { pluralize } from "~/util";
import { CostSection } from ".";

export type TouchedTypes = Set<"cost" | "request">;

export const getTypes = (costs: number, requests: number): TouchedTypes => {
  const types: TouchedTypes = new Set();
  if (costs) types.add("cost");
  if (requests) types.add("request");
  return types;
};

const statusMessage = (types: TouchedTypes) => {
  if (types.size == 2) {
    return `Innholdet i rapporten har ${pluralize("avvik")} og ${pluralize(
      "dokumentasjonpunkt"
    )} som enten er ufullstendig eller fraviker det opprinnelige oppsettet. Vennligst revider innholdet.`;
  } else if (types.has("cost")) {
    return `Innholdet i rapporten har ${pluralize(
      "avvik"
    )} som enten er ufullstendig eller fraviker det opprinnelige oppsettet. Vennligst revider innholdet.`;
  } else {
    return `Innholdet i rapporten har ${pluralize(
      "dokumentasjonpunkt"
    )} som enten er ufullstendig eller fraviker det opprinnelige oppsettet. Vennligst revider innholdet.`;
  }
};

const TouchedStatusMessage: React.FC<{
  statusInfo: CostSection["statusInfo"];
}> = ({
  statusInfo: { touchedCostLinesCount, touchedRequestCostLinesCount = 0 },
}) => {
  const types = getTypes(
    touchedCostLinesCount,
    touchedRequestCostLinesCount || 0
  );

  return (
    <>
      <p>{statusMessage(types)}</p>
      <ul>
        {touchedCostLinesCount > 0 && (
          <li>
            {touchedCostLinesCount} {pluralize("avvik")} trenger revidering.
          </li>
        )}
        {touchedRequestCostLinesCount > 0 && (
          <li>
            {touchedRequestCostLinesCount} {pluralize("dokumentasjonpunkt")}{" "}
            trenger revidering.
          </li>
        )}
      </ul>
    </>
  );
};

export default TouchedStatusMessage;

import React from "react";
import { useFormContext } from "react-hook-form";
import { AddButton } from "~/layout/Button";
import { SaveButtonProps } from "~/layout/Button/SaveButton";

const AddFormButton: React.FC<SaveButtonProps> = ({ ...rest }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return <AddButton type="submit" loading={isSubmitting} {...rest} />;
};

export default AddFormButton;

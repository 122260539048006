import React from "react";
import { Col, Form as BSForm, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import {
  AttachmentField,
  Form,
  FormUpdateState,
  NumberField,
  RedactorField,
  Row,
  SelectField,
  TextField,
} from "~/form";
import { DeleteButton } from "~/layout";
import { StatusBadge } from "~/sections/cost-section/line";
import { CostLineTemplateSelectField } from "~/sections/cost-section/line-template";
import {
  BudgetLine,
  deleteBudgetLineMutation,
  toFormData,
  updateBudgetLineMutation,
} from ".";
import { BuildingPart } from "../building-part";
import { costTypeOptions, unitOptions } from "./helpers";

interface Props {
  buildingPart: BuildingPart;
  budgetLine: BudgetLine;
}

const StyledForm = styled(Form)`
  position: relative;
`;
const StyledFormUpdateState = styled(FormUpdateState)`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
`;
const StyledStatusBadge = styled(StatusBadge)`
  position: absolute;
  top: -1rem;
  left: -1rem;
  border-radius: 4px 0 8px 0;
`;

const EditBudgetLine: React.FC<Props> = ({
  buildingPart: { costBlockTemplateId },
  budgetLine,
  budgetLine: { id, cost, status, files },
}) => {
  const onUpdate = updateBudgetLineMutation(id);
  const onDelete = deleteBudgetLineMutation(id);

  return (
    <StyledForm
      onSubmit={onUpdate}
      defaultValues={toFormData(budgetLine)}
      autoSave
    >
      <StyledFormUpdateState />
      <StyledStatusBadge status={status} />
      <Row className="align-items-end">
        <Col lg="3">
          {costBlockTemplateId && (
            <CostLineTemplateSelectField
              name="costLineTemplateId"
              label="Bygningsdel"
              costBlockTemplateId={costBlockTemplateId}
            />
          )}
        </Col>
        <Col>
          <SelectField name="unit" label="Enhet" options={unitOptions} />
        </Col>
        <Col lg="1">
          <NumberField name="amount" label="Mengde" min={1} />
        </Col>
        <Col lg="1">
          <NumberField name="unitPrice" label="Enhetspris" step={0.1} />
        </Col>
        <Col lg="3">
          <SelectField
            name="costType"
            label="Type kostnad"
            options={costTypeOptions}
          />
        </Col>
        <Col lg="1">
          <FormGroup>
            <BSForm.Label>Kostnad</BSForm.Label>
            <BSForm.Control type="text" disabled value={cost || ""} />
          </FormGroup>
        </Col>
        <Col lg="auto">
          {onDelete && (
            <DeleteButton
              iconOnly
              onClick={() => onDelete()}
              style={{ marginBottom: "1.25rem" }}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col lg="8">
          <RedactorField name="description" label="Kommentar" />
        </Col>
        <Col lg="4">
          <AttachmentField
            attachments={files}
            name="files"
            label="Avviksbilde"
            multiple
          />
        </Col>
      </Row>
      <TextField name="note" />
    </StyledForm>
  );
};

export default EditBudgetLine;

import { Reference, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AddFormButton, Fieldset, Form, SelectField } from "~/form";
import { requestCostBlockWithSummaryFragment } from ".";
import { RequestSection } from "../constants";
import {
  RequestCostBlockTemplate,
  REQUEST_COST_BLOCK_TEMPLATES_QUERY
} from "../cost-block-template";
import {
  RequestCostBlock,
  REQUEST_COST_BLOCK_CREATE_MUTATION
} from "./constants";

interface Props {
  requestSection: RequestSection;
}

const NewRequestCostBlock: React.FC<Props> = ({ requestSection }) => {
  const { loading, error, data } = useQuery<{
    requestCostBlockTemplates: RequestCostBlockTemplate[];
  }>(REQUEST_COST_BLOCK_TEMPLATES_QUERY);
  const [createRequestCostBlock] = useMutation(
    REQUEST_COST_BLOCK_CREATE_MUTATION
  );
  const formMethods = useForm();
  const { reset } = formMethods;

  if (error) return <p>Error :(</p>;

  const onSubmit = async (input: Partial<RequestCostBlock>) => {
    await createRequestCostBlock({
      variables: {
        input: {
          ...input,
          requestSectionId: requestSection.id,
        },
      },
      update: (cache, { data: { requestCostBlockCreate } }) => {
        const newRef = cache.writeFragment({
          data: requestCostBlockCreate,
          fragment: requestCostBlockWithSummaryFragment,
          fragmentName: "RequestCostBlockWithSummary",
        });
        cache.modify({
          id: cache.identify(requestSection),
          fields: {
            requestCostBlocks: (refs: Reference[], { readField }) =>
              [...refs, newRef].sort((a, b) => {
                const [aNumber, bNumber] = [a, b].map((ref) =>
                  parseInt(
                    (readField("number", ref) as string).replace(/\./g, "")
                  )
                );
                return aNumber > bNumber ? 1 : bNumber > aNumber ? -1 : 0;
              }),
          },
        });
      },
    });
    reset();
  };

  const requestCostBlockTemplateOptions = (
    data?.requestCostBlockTemplates || []
  ).map(({ id, number, title }) => ({
    value: id,
    label: `#${number} — ${title}`,
  }));

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} horizontal>
      <Fieldset title="Ny innhold">
        <Row>
          <Col lg="4">
            <SelectField
              name="requestCostBlockTemplateId"
              label="TDD Vedlikeholdsdeler"
              isLoading={loading}
              options={requestCostBlockTemplateOptions}
              required
            />
          </Col>
          <Col lg="auto">
            <FormGroup>
              <AddFormButton style={{ marginTop: "1.5rem" }} />
            </FormGroup>
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewRequestCostBlock;

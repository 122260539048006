import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { StyledBlockListHeader } from "./BlockListHeader";
import { StyledBlockListItem } from "./BlockListItem";

interface Props {
  sub?: boolean;
  children?: ReactNode;
}

interface UlProps {
  $sub: boolean;
}

const StyledUl = styled.ul<UlProps>`
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;

  ${({ $sub }) =>
    $sub &&
    css`
      padding: 1rem;
      border: 1px solid var(--mystic);
      background-color: var(--smoke);
      border-radius: 4px;

      ${StyledBlockListHeader} {
        padding: 1rem;
        border-bottom: 1px solid var(--mystic);
      }

      ${StyledBlockListItem} {
        border: 0;
      }
    `}

  .block-list-item-row {
    padding: 1rem;
    border: 1px solid var(--mystic);
    border-radius: 3px;
    background-color: var(--white);
  }

  .block-list-new-block {
    border-top: 0;
  }
`;

const BlockList = React.forwardRef<HTMLUListElement, Props>(
  ({ children, sub = false }, ref) => {
    return (
      <StyledUl $sub={sub} ref={ref}>
        {children}
      </StyledUl>
    );
  }
);
BlockList.displayName = "BlockListFwRef";

export default BlockList;

import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { AddFormButton, Fieldset, Form } from "~/form";
import { CostLineTemplateSelectField } from "~/sections/cost-section/line-template";
import { createBudgetLineMutation } from ".";
import { BuildingPart } from "../building-part";

interface Props {
  buildingPart: BuildingPart & {
    costBlockTemplateId: string;
  };
}

const NewBudgetLine: React.FC<Props> = ({
  buildingPart,
  buildingPart: { costBlockTemplateId },
}) => {
  const onSubmit = createBudgetLineMutation(buildingPart);

  return (
    <Form onSubmit={onSubmit} horizontal>
      <Fieldset title="Ny innhold">
        <Row className="align-items-start">
          <Col lg="6">
            <CostLineTemplateSelectField
              costBlockTemplateId={costBlockTemplateId}
              name="costLineTemplateId"
              label="Bygningsdel"
              required
            />
          </Col>
          <Col lg="auto">
            <FormGroup>
              <AddFormButton style={{ marginTop: "1.5rem" }} />
            </FormGroup>
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewBudgetLine;

import React from "react";
import { ProgressBar } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  uploads: Array<{
    id: string;
    file: File;
    state: "waiting" | "uploading" | "error" | "finished";
    progress: number;
    error: string;
  }>;
}

const UploadsWrap = styled.div`
  margin-bottom: 1rem;
`;

const StyledProgressBar = styled(ProgressBar)`
  margin: 0.5rem 0;
`;

const UploadStatus: React.FC<Props> = ({ uploads }) => {
  if (!uploads.length) return null;
  return (
    <UploadsWrap>
      {uploads.map((upload) => {
        switch (upload.state) {
          case "waiting":
            return null;
          // <p key={upload.id}>Waiting to upload {upload.file.name}</p>
          case "uploading":
            /* {upload.file.name} -{" "} */
            return (
              <StyledProgressBar
                animated
                now={upload.progress}
                key={upload.id}
              />
            );
          case "error":
            return <p key={upload.id}>Error: {upload.error}</p>;
          case "finished":
            return null;
          // <p key={upload.id}>Finished uploading {upload.file.name}</p>
        }
      })}
    </UploadsWrap>
  );
};

export default UploadStatus;

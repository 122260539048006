import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AddFormButton, Form, RadioField, Row } from "~/form";
import { Option } from "~/form/SelectField";
import {
  MembershipCreateInput,
  MembershipRelation,
  PermissionEnum,
  Report,
  useMembershipCreateMutation,
  User,
} from "~/graphql-operations";
import { ReportSelectField } from "~/report";
import { EditPermissions } from "../report/member";
import { relationLabel } from "./helpers";

interface NewAffiliateProps {
  user: Pick<User, "id">;
  // update?: MutationUpdaterFn;
  excludeReports?: string[];
  className?: string;
}

const relationOptions: Option[] = [
  MembershipRelation.Client,
  MembershipRelation.Associate,
  MembershipRelation.Collaborator,
].map((relation) => ({
  value: relation,
  label: relationLabel(relation),
}));

type FormValues = Pick<
  MembershipCreateInput,
  "reportId" | "relation" | "permissions"
>;

const NewAffiliate: React.FC<NewAffiliateProps> = ({
  user: { id: userId },
  excludeReports,
}) => {
  const formMethods = useForm({
    defaultValues: { reportId: "", relation: "" },
  });

  const [createMutation] = useMembershipCreateMutation();

  const [report, setReport] = useState<Report>();
  const [permissions, setPermissions] = useState<Array<PermissionEnum>>([
    PermissionEnum.View,
  ]);

  const [disabledRelations, setDisabledRelations] = useState<
    MembershipRelation[]
  >([]);
  const [disabledPermissions, setDisabledPermissions] = useState<
    Array<PermissionEnum>
  >([]);

  const onReportChange = (report: Report) => {
    setReport(report);
    if (report.lockedAt) {
      setDisabledRelations([
        MembershipRelation.Client,
        MembershipRelation.Associate,
      ]);
      setDisabledPermissions([PermissionEnum.Edit]);
    } else if (report.client) {
      setDisabledRelations([MembershipRelation.Client]);
      setDisabledPermissions([]);
    } else {
      setDisabledRelations([]);
      setDisabledPermissions([]);
    }
  };

  const onPermissionChange = (newPermissions: Array<PermissionEnum>) => {
    setPermissions(newPermissions);
  };

  const onSubmit = async (input: FormValues) => {
    const res = await createMutation({
      variables: {
        input: {
          ...input,
          permissions,
          userId,
        },
      },
      refetchQueries: ["Memberships"],
    });
    setReport(undefined);
    return res;
  };

  return (
    <Form
      id="new-affiliate-form"
      formMethods={formMethods}
      onSubmit={onSubmit}
      horizontal
      resetBlank
    >
      <Row>
        <Col>
          <ReportSelectField
            name="reportId"
            exclude={excludeReports}
            onChange={({ data }: { data: Report }) => onReportChange(data)}
          />
        </Col>
        <Col md="auto">
          <AddFormButton disabled={!report} style={{ marginTop: "1.4rem" }}>
            <span>Legg til rapport</span>
          </AddFormButton>
        </Col>
      </Row>
      {report && (
        <Row className="align-items-start mt-3">
          <Col lg="auto" className="mr-3">
            <RadioField
              name="relation"
              options={relationOptions}
              disabled={disabledRelations}
              required
            />
          </Col>
          <Col lg="auto">
            <label className="form-label">Permissions</label>
            <EditPermissions
              id={userId}
              permissions={permissions}
              disabled={disabledPermissions}
              onChange={onPermissionChange}
            />
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default NewAffiliate;

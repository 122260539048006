import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { onReportSelect } from "~/Company/CompaniesPage";
import { Fieldset, Form, FormProps, TextField } from "~/form";
import { ReportSelectField } from "~/report";

type Props = FormProps<{
  term: string;
  reportId: string;
}>;

const UserFilter: React.FC<Props> = ({ onSubmit, ...rest }) => {
  const searchRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => searchRef.current?.focus(), []);

  return (
    <Form autoSave onSubmit={onSubmit} {...rest}>
      <Fieldset>
        <Row>
          <Col lg="7">
            <TextField
              name="term"
              label={null}
              ref={searchRef}
              type="search"
              wrapperClassName="search-field"
            />
          </Col>
          <Col lg="5">
            <ReportSelectField
              name="reportId"
              label={null}
              onChange={onReportSelect}
            />
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default UserFilter;

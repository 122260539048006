import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";
import { User } from "~/user";
import { HousingMaintenanceSection } from "..";
import { budgetPlanFragment } from "../fragments";

export interface BudgetPlan extends ResourceInterface {
  housingMaintenanceSectionId: string;
  housingMaintenanceSection: HousingMaintenanceSection;
  userId: string;
  user: User;

  name: string;
  currentBudget: number;
  collectedBudget: number;
  interestRate: number;
  unitType: string;
  units: number;
  period: number;
}

export const BUDGET_PLANS_QUERY = gql`
  query housingMaintenanceBudgetPlans($id: ID!) {
    housingMaintenanceSection(id: $id) {
      id
      budgetPlans {
        ...HousingMaintenanceBudgetPlanFields
      }
    }
  }
  ${budgetPlanFragment}
`;

export const BUDGET_PLAN_CREATE_MUTATION = gql`
  mutation HousingMaintenanceBudgetPlanCreate(
    $input: HousingMaintenanceBudgetPlanCreateInput!
  ) {
    housingMaintenanceBudgetPlanCreate(input: $input) {
      ...HousingMaintenanceBudgetPlanFields
    }
  }
  ${budgetPlanFragment}
`;

export const BUDGET_PLAN_UPDATE_MUTATION = gql`
  mutation HousingMaintenanceBudgetPlanUpdate(
    $input: HousingMaintenanceBudgetPlanUpdateInput!
  ) {
    housingMaintenanceBudgetPlanUpdate(input: $input) {
      id
      ...HousingMaintenanceBudgetPlanFields
    }
  }
  ${budgetPlanFragment}
`;

export const BUDGET_PLAN_DELETE_MUTATION = gql`
  mutation HousingMaintenanceBudgetPlanDelete(
    $input: HousingMaintenanceBudgetPlanDeleteInput!
  ) {
    housingMaintenanceBudgetPlanDelete(input: $input) {
      id
    }
  }
`;

import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";
import {
  DocumentationLineTemplate,
  documentationLineTemplateFragment,
} from "../line-template";

export interface DocumentationBlockTemplate extends ResourceInterface {
  title: string;
  description: string;
  documentationLineTemplates?: DocumentationLineTemplate[];
}
export type CreateDocumentationBlockTemplateInput = Pick<
  DocumentationBlockTemplate,
  "title" | "description"
>;

export type UpdateDocumentationBlockTemplateInput = Pick<
  DocumentationBlockTemplate,
  "id" | "title" | "description"
>;

export const documentationBlockTemplateFragment = gql`
  fragment DocumentationBlockTemplateFields on DocumentationBlockTemplate {
    id
    title
    description
    createdAt
  }
`;

export const DOCUMENTATION_BLOCK_TEMPLATES_QUERY = gql`
  query documentationBlockTemplates {
    documentationBlockTemplates {
      ...DocumentationBlockTemplateFields
      documentationLineTemplates {
        ...DocumentationLineTemplateFields
      }
    }
  }
  ${documentationBlockTemplateFragment}
  ${documentationLineTemplateFragment}
`;

export const DOCUMENTATION_BLOCK_TEMPLATE_CREATE_MUTATION = gql`
  mutation CreateDocumentationBlockTemplate(
    $input: CreateDocumentationBlockTemplateInput!
  ) {
    createDocumentationBlockTemplate(input: $input) {
      errors
      documentationBlockTemplate {
        ...DocumentationBlockTemplateFields
        documentationLineTemplates {
          ...DocumentationLineTemplateFields
        }
      }
    }
  }
  ${documentationBlockTemplateFragment}
  ${documentationLineTemplateFragment}
`;

export const DOCUMENTATION_BLOCK_TEMPLATE_UPDATE_MUTATION = gql`
  mutation UpdateDocumentationBlockTemplate(
    $input: UpdateDocumentationBlockTemplateInput!
  ) {
    updateDocumentationBlockTemplate(input: $input) {
      errors
      documentationBlockTemplate {
        ...DocumentationBlockTemplateFields
        documentationLineTemplates {
          ...DocumentationLineTemplateFields
        }
      }
    }
  }
  ${documentationBlockTemplateFragment}
  ${documentationLineTemplateFragment}
`;

export const DOCUMENTATION_BLOCK_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteDocumentationBlockTemplate(
    $input: DeleteDocumentationBlockTemplateInput!
  ) {
    deleteDocumentationBlockTemplate(input: $input) {
      errors
      documentationBlockTemplate {
        ...DocumentationBlockTemplateFields
      }
    }
  }
  ${documentationBlockTemplateFragment}
`;

import {
  InvoiceCategoriesFieldsFragmentDoc,
  InvoiceCategory,
  useSortInvoiceCategoryMutation,
} from "~/graphql-operations";
import { client } from "~/setup";

export const useSortInvoiceCategories = () => {
  const [sortMutation] = useSortInvoiceCategoryMutation();

  return async ({
    from: { id: fromId, reportId, order: fromOrder },
    to: { id: toId, order: toOrder },
  }: {
    from: InvoiceCategory;
    to: InvoiceCategory;
  }) => {
    const report = client.readFragment<{
      invoiceCategories: InvoiceCategory[];
    }>({
      id: `ConstructionLoan:${reportId}`,
      fragment: InvoiceCategoriesFieldsFragmentDoc,
      fragmentName: "InvoiceCategoriesFields",
    });

    const categories = (report?.invoiceCategories || [])
      .map((category) => {
        if (category.id === fromId) {
          return {
            ...category,
            order: toOrder,
          };
        } else if (category.id === toId) {
          return {
            ...category,
            order: fromOrder,
          };
        } else {
          return category;
        }
      })
      .sort((a, b) => a.order - b.order);

    return sortMutation({
      variables: { input: { from: fromId, to: toId } },
      optimisticResponse: {
        __typename: "Mutation",
        sortInvoiceCategory: {
          __typename: "SortInvoiceCategoryPayload",
          report: {
            __typename: "ConstructionLoan",
            id: reportId,
            invoiceCategories: categories,
          },
        },
      },
    });
  };
};

import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from "./Shortcode";
import { WordLoader } from "~/layout/Loading";

const PROPERTY_REGISTRATON_NUMBER_REPORT_QUERY = gql`
  query ShortcodeMatrikkelKomunne($slug: ID!) {
    report(slug: $slug) {
      id
      propertyRegistrationNumber
    }
  }
`;

const MatrikkelGnr: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery(
    PROPERTY_REGISTRATON_NUMBER_REPORT_QUERY,
    {
      variables: { slug },
    }
  );
  if (error) return <p>Error</p>;

  const { propertyRegistrationNumber } = data?.report || {};

  return (
    <>
      {loading ? (
        <WordLoader />
      ) : (
        propertyRegistrationNumber && `${propertyRegistrationNumber}`
      )}
    </>
  );
};

export default MatrikkelGnr;

import React from "react";
import styled, { css } from "styled-components";
import { Icons } from "~/layout/icon/constants";
import IconButton, { Props as IconButtonProps } from "./IconButton";

interface Props extends Omit<IconButtonProps, "icon"> {
  icon?: Icons;
}

const StyledIconButton = styled(IconButton)`
  padding: ${({ size }) =>
    (() => {
      switch (size) {
        case "lg":
          return "1rem";
        case "sm":
          return "0.25rem";
        default:
          return "0.5rem";
      }
    })()};
  ${({ variant }) =>
    !variant &&
    css`
      border-color: transparent;
      background-color: #fff;
    `}
  border-radius: 50%;
`;

const CloseButton: React.FC<Props> = ({ onClick, icon = "times", ...rest }) => {
  return <StyledIconButton icon={icon} onClick={onClick} {...rest} />;
};

export default CloseButton;

import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import Icon from "~/layout/icon";

interface Props {
  links: {
    csv: string;
    xlsx: string;
  };
}

const StyledDropdown = styled(Dropdown)`
  display: inline-flex;
`;

const StyledToggle = styled(Dropdown.Toggle)`
  padding: 0;
  background: transparent;
  border: 0;

  &:after {
    display: none;
  }
`;

const ExportDropdown: React.FC<Props> = ({ links: { csv, xlsx } }) => {
  return (
    <StyledDropdown>
      <StyledToggle variant="link" id="dropdown-basic">
        <Icon icon="export" />
      </StyledToggle>

      <Dropdown.Menu>
        <Dropdown.Item href={csv}>
          <Icon icon="fileCsv" /> Export as CSV
        </Dropdown.Item>
        <Dropdown.Item href={xlsx}>
          <Icon icon="fileExcel" /> Export as XLSX
        </Dropdown.Item>
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

export default ExportDropdown;

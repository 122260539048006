import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { Session, SessionProvider, useSession } from ".";
import UserSVG from "./ico-user.svg";

interface Props {
  routes: {
    changePassword: string;
    logout: string;
  };
}

const StyledDropdown = styled(Dropdown)`
  position: relative;
  margin-left: 20px;
  font-family: var(--font-moon);
  font-weight: 700;
  cursor: pointer;
  outline: none;
`;

const StyledToggle = styled(Dropdown.Toggle)`
  display: flex;
  align-items: center;
  padding: 0;
  height: 43px;
  line-height: 43px;
  font-size: 1rem;
  font-weight: bold;
  color: var(--white);
  background: transparent;
  border: none;

  &:after {
    display: none;
  }

  &:hover {
    color: var(--white);
  }
  &:focus {
    box-shadow: none;
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  inset: 0 0 auto 0 !important;
  border: none;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  min-width: 200px;
  padding: 1rem;
  font-weight: bold;
  text-align: right;
  color: #000;
`;

const UserIcon = styled.div`
  margin-left: 0.75rem;
  width: 43px;
  height: 43px;
  background-color: var(--yellow);
  mask: url("${UserSVG}") no-repeat left center;
`;

const SessionMenu: React.FC<Props> = ({
  routes: { changePassword, logout },
}) => {
  const { email } = useSession() as Session;

  return (
    <StyledDropdown>
      <StyledToggle variant="link" id="dropdown-basic">
        {email}
        <UserIcon />
      </StyledToggle>

      <StyledDropdownMenu>
        <StyledDropdownItem href={changePassword}>
          Endre passord
        </StyledDropdownItem>
        <StyledDropdownItem href={logout} data-method="delete">
          Logg Ut
        </StyledDropdownItem>
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

interface WrapProps extends Props {
  session: Session;
}

const Wrap: React.FC<WrapProps> = ({ session, ...rest }) => (
  // <ApolloProvider client={client}>
  <SessionProvider session={session}>
    <SessionMenu {...rest} />
  </SessionProvider>
  // </ApolloProvider>
);

export default Wrap;

import { ApolloProvider } from "@apollo/client";
import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";
import styled from "styled-components";
import { Attachment, AttachmentList } from "~/attachment";
import { CloseButton } from "~/layout/Button";
import { client } from "~/setup";
import { DocumentationLineRatingValue } from "./constants";

interface Props {
  rating: DocumentationLineRatingValue;
  files: Pick<
    Attachment,
    "contentType" | "download" | "filename" | "signedId"
  >[];
  placement: Placement;
}

const Badge = styled.span<{ rating: DocumentationLineRatingValue }>`
  border-radius: 50%;
  box-shadow: 0 0 26px 0 rgba(var(--black), 0.15);
  color: var(--dark-gray);
  cursor: pointer;
  display: inline-block;
  font-family: var(--moon);
  font-size: 1rem;
  font-weight: 700;
  height: 33px;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;
  width: 33px;

  background-color: ${({ rating }) =>
    (() => {
      switch (rating) {
        case 1:
          return "var(--green)";
        case 2:
          return "var(--yellow)";
        case 3:
        case 4:
          return "var(--red)";
        case 5:
          return "var(--blue)";
        case 6:
          return "var(--gray)";
        default:
          return "var(--black)";
      }
    })()};

  &.large {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 1.375rem;
  }
`;

const DocumentationLineScoreBadge: React.FC<Props> = ({
  rating,
  files,
  placement = "top",
}) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  const text = (() => {
    switch (rating) {
      case 1:
        return "Dokumentasjon foreligger og er korrekt.";
      case 2:
        return "Det er opplyst om at dokumentasjon foreligger, men denne er ikke lastet opp.";
      case 3:
        return "Dokumentasjon foreligger ikke.";
      case 4:
        return "Dokumentasjon er lastet opp, men det er ikke korrekt dokumentasjon.";
      case 5:
        return "Ingen avvik foreligger eller avvikene er lukket.";
      case 6:
        return "Ikke relevant (Medregnes ikke i max score/karakteren).";
    }
  })();

  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Title as="h3">Rating</Popover.Title> */}
      <Popover.Content style={{ position: "relative" }}>
        <CloseButton
          onClick={toggle}
          size="sm"
          style={{ position: "absolute", top: "-0.75rem", right: "-1.25rem" }}
        />
        {text}
        <AttachmentList attachments={files} />
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      show={show}
      onToggle={toggle}
      placement={placement}
      overlay={popover}
      rootClose
    >
      <Badge rating={rating} />
    </OverlayTrigger>
  );
};

const ApolloWrap: React.FC<Props> = ({ ...rest }) => (
  <ApolloProvider client={client}>
    <DocumentationLineScoreBadge {...rest} />
  </ApolloProvider>
);

export default ApolloWrap;

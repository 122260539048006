import Block from "./Block";
import BlockHeader from "./BlockHeader";
import List from "./List";
import ListHeader from "./ListHeader";
import BlockTitle from "./Title";

export { Block, BlockHeader, List, ListHeader, BlockTitle };

export default Object.assign(List, {
  Block: Object.assign(Block, {
    Header: BlockHeader,
    Title: BlockTitle,
  }),
  BlockHeader,
  BlockTitle,
  Header: ListHeader,
});

import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  inverse?: boolean;
  id?: string;
  style?: React.CSSProperties;
  children?: ReactNode;
}

export const StyledBlockListItem = styled.div<Props>`
  margin-bottom: 1rem;
  padding: 1rem;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; */
  color: var(--${(props) => (props.inverse ? "white" : "dark-gray")});
  background: var(--${(props) => (props.inverse ? "dark-gray" : "white")});
  border: 1px solid var(--light-gray);
  border-radius: 6px;

  /* font-family: var(--font-moon);
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700; */

  &:last-child:not(:first-child) {
    margin-bottom: 0;
  }
`;

const BlockListItem = React.forwardRef<HTMLLIElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <StyledBlockListItem {...rest} ref={ref}>
        {children}
      </StyledBlockListItem>
    );
  }
);
BlockListItem.displayName = "BlockListItemFwRef";

export default BlockListItem;

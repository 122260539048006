import React from "react";
import { Collapse } from "react-bootstrap";
import { useCollapsed } from "./Collapsible";

const Toggled: React.FC = ({ children }) => {
  const { collapsed } = useCollapsed();

  return (
    <Collapse in={!collapsed}>
      {collapsed ? <div></div> : <div>{children}</div>}
    </Collapse>
  );
};

export default Toggled;

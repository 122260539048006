import React from "react";
import styled, { css } from "styled-components";
import { Currency } from "~/util";

interface TotalProps {
  total: number | string;
  header?: string;
  footer?: string;
  small?: true;
}

const Wrap = styled.div<{ small?: true }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 50px);
  height: calc(100% - 90px);
  font-size: 1.5rem;
  border-radius: 50%;

  ${({ small }) =>
    small &&
    css`
      width: calc(100% - 18px);
      height: calc(100% - 18px);
      font-size: 0.85rem;
    `}
`;

const Header = styled.label`
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  word-break: break-all;
  margin-bottom: 0.25em;
  font-family: "Moon", sans-serif;
`;

const Total: React.FC<TotalProps> = ({ total, header, footer, small }) => {
  return (
    <Wrap small={small}>
      {header && <Header>{header}</Header>}
      <Currency>{total}</Currency>
      {footer && <span style={{ textTransform: "uppercase" }}>{footer}</span>}
    </Wrap>
  );
};

export default Total;

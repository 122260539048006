import { useMutation } from "@apollo/client";
import React from "react";
import { Modal } from "~/layout";
import { AddButton } from "~/layout/Button";
import { Report } from "~/report";
import { parseDate, useModal } from "~/util";
import { Delivery, DeliveryForm, DELIVERY_CREATE_MUTATION } from ".";

interface Props {
  report: Pick<Report, "id">;
}

const NewDeliveryForm: React.FC<Props> = ({ report: { id: reportId } }) => {
  const { active, show, close } = useModal();
  const [createDelivery] = useMutation(DELIVERY_CREATE_MUTATION);

  const onSubmit = async (input: Delivery) => {
    const { date } = input;
    await createDelivery({
      variables: {
        input: {
          ...input,
          reportId,
          date: parseDate(date),
        },
      },
    });

    close();
  };

  return (
    <>
      <AddButton onClick={() => show()} />
      <Modal active={active} onHide={close} title="New Delivery" size="lg">
        <DeliveryForm onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

export default NewDeliveryForm;

import invariant from "invariant";
import React from "react";
import { useCostOverviewTableQuery } from "~/graphql-operations";
import { WordLoader } from "~/layout/Loading";
import { Table, TCell, THCell, THead, TRow } from "~/layout/table/CostTable";
import { ShortcodeProps } from ".";

const CostOverviewTable: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data: { report } = {} } = useCostOverviewTableQuery({
    variables: { slug },
  });

  if (loading) return <WordLoader />;
  if (error) return <p>Error</p>;

  invariant(report && "cost" in report, "No report data");

  const { cost } = report;

  const {
    missingRegulationElectrical,
    missingRegulationConstruction,
    missingRegulationPlumbing,
    immediateActionElectrical,
    immediateActionConstruction,
    immediateActionPlumbing,

    oneToFiveHighElectrical,
    oneToFiveHighConstruction,
    oneToFiveHighPlumbing,

    oneToFiveMediumElectrical,
    oneToFiveMediumConstruction,
    oneToFiveMediumPlumbing,

    oneToFiveLowElectrical,
    oneToFiveLowConstruction,
    oneToFiveLowPlumbing,

    oneToFiveReplacementElectrical,
    oneToFiveReplacementConstruction,
    oneToFiveReplacementPlumbing,
    missingRegulationTotal,

    immediateActionTotal,
    oneToFiveHighTotal,
    oneToFiveMediumTotal,
    oneToFiveLowTotal,
    oneToFiveReplacementTotal,

    sixToTenHighElectrical,
    sixToTenHighConstruction,
    sixToTenHighPlumbing,
    sixToTenHighTotal,

    sixToTenMediumElectrical,
    sixToTenMediumConstruction,
    sixToTenMediumPlumbing,
    sixToTenMediumTotal,

    sixToTenLowElectrical,
    sixToTenLowConstruction,
    sixToTenLowPlumbing,
    sixToTenLowTotal,

    sixToTenReplacementElectrical,
    sixToTenReplacementConstruction,
    sixToTenReplacementPlumbing,
    sixToTenReplacementTotal,

    oneToFiveTotal,
    sixToTenTotal,
    constructionTotal,
    electricalTotal,
    plumbingTotal,

    total,
  } = cost;

  const construction = !!constructionTotal;
  const electrical = !!electricalTotal;
  const plumbing = !!plumbingTotal;

  return (
    <Table responsive="sm" borderless>
      <THead>
        <TRow>
          <THCell left>Type / Tidsrom</THCell>
          <THCell borderRight>Prioritet</THCell>
          {construction && <THCell>Bygg</THCell>}
          {plumbing && <THCell>VVS</THCell>}
          {electrical && <THCell borderRight>Elektro</THCell>}
          <THCell borderY>Total</THCell>
          <THCell right>Per m2 BTA/År</THCell>
        </TRow>
      </THead>
      <tbody>
        <TRow>
          <TCell left borderRight colSpan={2}>
            Forskriftsmangel
          </TCell>
          {construction && <TCell>{missingRegulationConstruction}</TCell>}
          {plumbing && <TCell>{missingRegulationPlumbing}</TCell>}
          {electrical && <TCell>{missingRegulationElectrical}</TCell>}
          <TCell total borderY>
            {missingRegulationTotal}
          </TCell>
          <TCell right>000</TCell>
        </TRow>
        <TRow>
          <TCell left borderRight colSpan={2}>
            Strakstiltak
          </TCell>
          {construction && <TCell>{immediateActionConstruction}</TCell>}
          {plumbing && <TCell>{immediateActionPlumbing}</TCell>}
          {electrical && <TCell>{immediateActionElectrical}</TCell>}
          <TCell total borderY>
            {immediateActionTotal}
          </TCell>
          <TCell right>000</TCell>
        </TRow>
        {oneToFiveTotal && (
          <>
            <TRow borderless>
              <TCell left>1-5 år</TCell>
              <TCell borderRight>Høy</TCell>
              {construction && <TCell>{oneToFiveHighConstruction}</TCell>}
              {plumbing && <TCell>{oneToFiveHighPlumbing}</TCell>}
              {electrical && <TCell>{oneToFiveHighElectrical}</TCell>}
              <TCell total borderY>
                {oneToFiveHighTotal}
              </TCell>
              <TCell right>000</TCell>
            </TRow>
            <TRow borderless>
              <TCell left>{}</TCell>
              <TCell borderRight>Middels</TCell>
              {construction && <TCell>{oneToFiveMediumConstruction}</TCell>}
              {plumbing && <TCell>{oneToFiveMediumPlumbing}</TCell>}
              {electrical && <TCell>{oneToFiveMediumElectrical}</TCell>}
              <TCell total borderY>
                {oneToFiveMediumTotal}
              </TCell>
              <TCell right>000</TCell>
            </TRow>
            <TRow>
              <TCell left>{}</TCell>
              <TCell borderRight>Lav</TCell>
              {construction && <TCell>{oneToFiveLowConstruction}</TCell>}
              {plumbing && <TCell>{oneToFiveLowPlumbing}</TCell>}
              {electrical && <TCell>{oneToFiveLowElectrical}</TCell>}
              <TCell total borderY>
                {oneToFiveLowTotal}
              </TCell>
              <TCell right>000</TCell>
            </TRow>
            <TRow light>
              <TCell borderY></TCell>
              <TCell borderRight>Utskiftning</TCell>
              {construction && (
                <TCell>{oneToFiveReplacementConstruction}</TCell>
              )}
              {plumbing && <TCell>{oneToFiveReplacementPlumbing}</TCell>}
              {electrical && <TCell>{oneToFiveReplacementElectrical}</TCell>}
              <TCell total borderY>
                {oneToFiveReplacementTotal}
              </TCell>
              <TCell right>000</TCell>
            </TRow>
          </>
        )}
        {sixToTenTotal && (
          <>
            <TRow borderless>
              <TCell left>6-10 år</TCell>
              <TCell borderRight>Høy</TCell>
              {construction && <TCell>{sixToTenHighConstruction}</TCell>}
              {plumbing && <TCell>{sixToTenHighPlumbing}</TCell>}
              {electrical && <TCell>{sixToTenHighElectrical}</TCell>}
              <TCell total borderY>
                {sixToTenHighTotal}
              </TCell>
              <TCell right>000</TCell>
            </TRow>
            <TRow borderless>
              <TCell left>{}</TCell>
              <TCell borderRight>Middels</TCell>
              {construction && <TCell>{sixToTenMediumConstruction}</TCell>}
              {plumbing && <TCell>{sixToTenMediumPlumbing}</TCell>}
              {electrical && <TCell>{sixToTenMediumElectrical}</TCell>}
              <TCell total borderY>
                {sixToTenMediumTotal}
              </TCell>
              <TCell right>000</TCell>
            </TRow>
            <TRow>
              <TCell left>{}</TCell>
              <TCell borderRight>Lav</TCell>
              {construction && <TCell>{sixToTenLowConstruction}</TCell>}
              {plumbing && <TCell>{sixToTenLowPlumbing}</TCell>}
              {electrical && <TCell>{sixToTenLowElectrical}</TCell>}
              <TCell total borderY>
                {sixToTenLowTotal}
              </TCell>
              <TCell right>000</TCell>
            </TRow>
            <TRow light>
              <TCell borderY></TCell>
              <TCell borderRight>Utskiftning</TCell>
              {construction && <TCell>{sixToTenReplacementConstruction}</TCell>}
              {plumbing && <TCell>{sixToTenReplacementPlumbing}</TCell>}
              {electrical && <TCell>{sixToTenReplacementElectrical}</TCell>}
              <TCell total borderY>
                {sixToTenReplacementTotal}
              </TCell>
              <TCell right>000</TCell>
            </TRow>
          </>
        )}
      </tbody>
      <tfoot>
        <TRow>
          <TCell left borderRight colSpan={2}>
            Totalt
          </TCell>
          {construction && <TCell>{constructionTotal}</TCell>}
          {plumbing && <TCell>{plumbingTotal}</TCell>}
          {electrical && <TCell>{electricalTotal}</TCell>}
          <TCell total>{total}</TCell>
          <TCell right>000</TCell>
        </TRow>
      </tfoot>
    </Table>
  );
};

export default CostOverviewTable;

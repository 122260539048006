import { ApolloProvider, gql, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { ConfirmButton, SaveButton } from "~/layout";
import { CostSection } from "~/sections/cost-section";
import { getTypes } from "~/sections/cost-section/TouchedStatusMessage";
import { client } from "~/setup";
import { pluralize } from "~/util/String";
import { Report, ReportProvider, REPORT_PUBLISH_MUTATION, useReport } from ".";

const publishTxt = "Lås og publiser";

export const joinCounts = (
  costs: number,
  requests: number | undefined
): string => {
  const texts: string[] = [];
  if (costs) texts.push(`${costs} ${pluralize("avvik", costs)}`);
  if (requests)
    texts.push(`${requests} ${pluralize("dokumentasjonpunkt", requests)}`);
  return texts.join(" og ");
};

interface TouchedListProps {
  costSections: CostSection[];
}

const TouchedList: React.FC<TouchedListProps> = ({ costSections }) => (
  <ul>
    {costSections.map(
      ({
        id,
        title,
        statusInfo: { touchedCostLinesCount, touchedRequestCostLinesCount },
        urls: { show },
      }) => (
        <li key={id}>
          <a href={show}>
            {title} -{" "}
            {joinCounts(touchedCostLinesCount, touchedRequestCostLinesCount)}{" "}
            trenger spesifisering/tilsyn
          </a>
        </li>
      )
    )}
  </ul>
);

const PublishReport: React.FC = () => {
  const { id, slug } = useReport() as Pick<Report, "id" | "slug">;
  const { loading, error, data } = useQuery(
    gql`
      query ReportCostSectionStatuses($slug: ID!) {
        report(slug: $slug) {
          id
          ... on Costs {
            costSections {
              id
              title
              visible
              status
              statusInfo {
                touchedCostLinesCount
                touchedRequestCostLinesCount
              }
              urls {
                show
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        slug,
      },
    }
  );
  const [publishReport] = useMutation(REPORT_PUBLISH_MUTATION);

  if (error) return <p>Error :(</p>;
  if (loading)
    return (
      <SaveButton onClick={() => Promise.resolve()} disabled={true}>
        {publishTxt}
      </SaveButton>
    );

  const onPublish = () =>
    publishReport({
      variables: {
        input: {
          id,
        },
      },
      update: (_cache, { data: { reportPublish } }) => {
        const url = reportPublish?.urls?.show || "/";
        window.location = url;
      },
    });

  const {
    report: { costSections },
  } = data;

  const touchedDisplayableSections: CostSection[] = (costSections || []).filter(
    ({ visible, status }: CostSection) => visible && status === "touched"
  );

  const touchedTypes = new Set(
    ...touchedDisplayableSections.map(
      ({
        statusInfo: { touchedCostLinesCount, touchedRequestCostLinesCount },
      }) => getTypes(touchedCostLinesCount, touchedRequestCostLinesCount || 0)
    )
  );

  let warningMessage: React.ReactNode | null;
  if (!touchedTypes.size) warningMessage = null;
  else if (touchedTypes.size == 1) {
    if (touchedTypes.has("cost")) {
      warningMessage = (
        <p>
          Rapporten har avvik som enten er ufullstendig eller fraviker det
          opprinnelige oppsettet.
        </p>
      );
    } else {
      warningMessage = (
        <p>
          Rapporten har dokumentasjonpunkter som enten er ufullstendig eller
          fraviker det opprinnelige oppsettet.
        </p>
      );
    }
  } else {
    warningMessage = (
      <p>
        Rapporten har avvik or dokumentasjonpunkter som enten er ufullstendig
        eller fraviker det opprinnelige oppsettet.
      </p>
    );
  }

  return (
    <>
      {warningMessage ? (
        <ConfirmButton
          icon="warning"
          message={
            <>
              {warningMessage}
              <p>
                Vennligst revider innholdet i følgende{" "}
                {pluralize("kapittel", touchedDisplayableSections.length)}:
              </p>
              <TouchedList costSections={touchedDisplayableSections} />
            </>
          }
          title="Advarsel"
          confirmLabel="Revidere"
          rejectLabel="Ignorer og fortsett"
          onConfirm={() => undefined}
          onReject={onPublish}
        >
          {publishTxt}
        </ConfirmButton>
      ) : (
        <SaveButton onClick={onPublish}>{publishTxt}</SaveButton>
      )}
    </>
  );
};

interface WrapProps {
  report: Report;
}

const ApolloWrap: React.FC<WrapProps> = ({ report, ...rest }) => (
  <ApolloProvider client={client}>
    <ReportProvider report={{ ...report, id: report.id.toString() }}>
      <PublishReport {...rest} />
    </ReportProvider>
  </ApolloProvider>
);

export default ApolloWrap;

import { pick } from "lodash";
import { attachmentInput } from "~/form/attachment";
import {
  ClientInvoice,
  ContractorInvoice,
  ExternalInvoice,
  Invoice,
} from "~/graphql-operations";
import { InvoiceUpdatePayload } from "./constants";

// type autoDeposit = (input: {
//   balance: number | null;
//   total: number;
// }) => number | null;
// export const autoDeposit: autoDeposit = ({ balance, total }) => {
//   return balance ? (total * balance) / 100 : null;
// };

// type Invoice = ClientInvoice | ContractorInvoice | ExternalInvoice;

export const isExternalInvoice = (
  invoice: Pick<Invoice, "type">
): invoice is ExternalInvoice => invoice.type === "ExternalInvoice";

export const isClientInvoice = (
  invoice: Pick<Invoice, "type">
): invoice is ClientInvoice => invoice.type === "ClientInvoice";

export const isContractorInvoice = (
  invoice: Pick<Invoice, "type">
): invoice is ContractorInvoice => invoice.type === "ContractorInvoice";

export const toFormData = (
  invoice: Invoice,
  acl?: "generalEditor" | "collaborator"
): InvoiceUpdatePayload => {
  const { file } = invoice;
  const picks = [
    "id",
    "amount",
    "currency",
    "dueDate",
    "number",
    "receivedDate",
    "sender",
  ];
  if (acl === "generalEditor") picks.push("categoryId", "deposit");
  return {
    ...pick(invoice, picks),
    file: file && attachmentInput(file),
  } as InvoiceUpdatePayload;
};

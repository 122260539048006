import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from ".";

const DOCUMENTATION_CONTROL_REPORT_QUERY = gql`
  query KonsulenterDokumentasjonskontroll($slug: ID!) {
    report(slug: $slug) {
      id
      documentationControl
    }
  }
`;

const KonsulenterDokumentasjonskontroll: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(
    DOCUMENTATION_CONTROL_REPORT_QUERY,
    { variables: { slug } }
  );

  if (error) return <p>Error :(</p>;
  const { documentationControl } = data?.report || {};

  return <>{loading ? <WordLoader /> : `${documentationControl}`}</>;
};

export default KonsulenterDokumentasjonskontroll;

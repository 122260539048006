import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import ImageCarousel from "~/layout/ImageCarousel";
import { client } from "~/setup";
import { ConstructionLoan } from ".";
import { CONSTRUCTION_LOAN_CYCLE_IMAGES_QUERY } from "./period";

interface Props {
  report: Pick<ConstructionLoan, "slug">;
}

const ConstructionLoanImagesPage: React.FC<Props> = ({ report: { slug } }) => {
  const period = new URL(window.location.href).searchParams.get("period");

  const { loading, error, data } = useQuery<{
    report: ConstructionLoan;
  }>(CONSTRUCTION_LOAN_CYCLE_IMAGES_QUERY, {
    variables: { slug, period },
  });

  const images = data?.report?.cycle?.images;

  if (loading) return <p>Loading...</p>;
  if (error || !images) return <p>Error :(</p>;

  return <ImageCarousel images={images} />;
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <ConstructionLoanImagesPage {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Fieldset,
  Form,
  RedactorField,
  SaveFormButton,
  TextField,
} from "~/form";
import { Block } from "~/layout";
import { useCreateDocumentationBlockTemplate } from ".";

const NewDocumentationBlockTemplate: React.FC = () => {
  const createBlockTemplate = useCreateDocumentationBlockTemplate();

  return (
    <Block>
      <Form onSubmit={createBlockTemplate} resetBlank horizontal>
        <Fieldset title="Legg Til Ny Mal">
          <Row>
            <Col>
              <TextField name="title" label="tittel" required />
            </Col>
            <Col lg="2" className="d-flex justify-content-end">
              <SaveFormButton />
            </Col>
          </Row>

          <Row>
            <Col>
              <RedactorField name="description" label="Beskrivelse" />
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default NewDocumentationBlockTemplate;

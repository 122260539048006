import React from "react";
import styled, { css } from "styled-components";

interface Props {
  children: Period;
}

type Period =
  | "none"
  | "missing_regulation"
  | "immediate"
  | "1_5_years"
  | "6_10_years"
  | "11_15_years"
  | "16_20_years"
  | "21_25_years"
  | "26_30_years"
  | "reclamation"
  | "text"
  | null;

const getLabel = (value: Period) => {
  switch (value) {
    case "none":
      return "-";
    case "missing_regulation":
      return "Forskriftsmangel";
    case "immediate":
      return "Strakstiltak";
    case "1_5_years":
      return "1-5 år";
    case "6_10_years":
      return "6-10 år";
    case "11_15_years":
      return "11-15 år";
    case "16_20_years":
      return "16-20 år";
    case "21_25_years":
      return "21-25 år";
    case "26_30_years":
      return "26-30 år";
    case "reclamation":
      return "Reklamasjon";
    case "text":
      return "IA";
    default:
      return "-";
  }
};

type Severity = "high" | "medium" | "low" | "";

export const periodSeverity = (period: Period): Severity => {
  switch (period) {
    case "immediate":
      return "high";
    case "1_5_years":
      return "medium";
    case "6_10_years":
      return "low";
    default:
      return "";
  }
};

const StyledPeriod = styled.span<{
  colored?: boolean;
  severity: Severity;
}>`
  ${({ colored }) =>
    colored &&
    css`
      background: ${({ severity }) => {
        switch (severity) {
          case "high":
            return "#ffdfe1";
          case "medium":
            return "#ffe7ab";
          case "low":
            return "#d0f8dd";
          default:
            return "transparent";
        }
      }};
    `}
`;

const Period: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <StyledPeriod severity={periodSeverity(children)} {...rest}>
      {getLabel(children)}
    </StyledPeriod>
  );
};

export default Period;

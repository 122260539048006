import React from "react";
import AttachmentFile, {
  AttachmentFileProps,
} from "~/attachment/AttachmentFile";
import {
  AttachmentListWrap,
  AttachmentWrap,
} from "~/attachment/AttachmentList";
import { FileVersion } from "~/graphql-operations";
import FileVersionInfo, { FileVersionInfoProps } from "./FileVersionInfo";

interface Props {
  fileVersions: (Pick<FileVersion, "id" | "file"> &
    Omit<FileVersionInfoProps["fileVersion"], "file">)[];
  disposition?: AttachmentFileProps["disposition"];
  onDelete?: (id: string) => Promise<unknown>;
}

const FileVersionList: React.FC<Props> = ({
  fileVersions,
  disposition,
  onDelete,
  ...rest
}) => {
  if (!fileVersions || !fileVersions.length) return null;

  return (
    <AttachmentListWrap {...rest}>
      {fileVersions.map((fileVersion) => {
        const {
          id,
          file,
          file: { signedId },
        } = fileVersion;

        return (
          <AttachmentWrap key={signedId}>
            <AttachmentFile
              attachment={file}
              onDelete={onDelete ? () => onDelete(id) : undefined}
              disposition={disposition}
            >
              <FileVersionInfo fileVersion={fileVersion} />
            </AttachmentFile>
          </AttachmentWrap>
        );
      })}
    </AttachmentListWrap>
  );
};

export default FileVersionList;

import { useSortable } from "@dnd-kit/sortable";
import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import Icon from "~/layout/icon";
import BlockHeader from "../BlockHeader";

interface Props {
  children: ReactNode;
  sortable: Pick<ReturnType<typeof useSortable>, "attributes" | "listeners">;
}

const SortableBlockHeader = ({
  children,
  sortable: { attributes, listeners },
}: Props) => {
  return (
    <BlockHeader>
      <Row className="align-items-center">
        <Col xs="auto" className="pr-0">
          <Icon
            icon="move"
            className="block-header-icon"
            {...attributes}
            {...listeners}
          />
        </Col>
        <Col>{children}</Col>
      </Row>
    </BlockHeader>
  );
};

export default SortableBlockHeader;

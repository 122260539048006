import React from "react";
import { Col, Form as BSForm, Row } from "react-bootstrap";

type Line = {
  company: string;
  name: string;
  uid?: number;
};

interface Props {
  line: Line;
  param: string;
  onLineChange: (field: string, value: string) => void;
  removeLine?: (uid: number) => void;
  readOnly?: boolean;
}

const AudienceLine: React.FC<Props> = ({
  line: { company, name, uid },
  param,
  onLineChange,
  removeLine,
  readOnly = false,
}: Props) => {
  return (
    <Row>
      <Col>
        <BSForm.Group>
          <BSForm.Label>Firmanavn</BSForm.Label>
          <BSForm.Control
            type="text"
            name={`${param}[company]`}
            value={company || ""}
            readOnly={readOnly}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onLineChange("company", e.target.value)
            }
          />
        </BSForm.Group>
      </Col>
      <Col>
        <BSForm.Group>
          <BSForm.Label>Navn</BSForm.Label>
          <BSForm.Control
            type="text"
            name={`${param}[name]`}
            value={name || ""}
            readOnly={readOnly}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onLineChange("name", e.target.value)
            }
          />
        </BSForm.Group>
      </Col>
      {removeLine && !readOnly && (
        <Col lg="auto" className="d-flex align-items-end">
          <button
            type="button"
            className="delete-link"
            onClick={() => removeLine(uid)}
          >
            Remove
          </button>
        </Col>
      )}
    </Row>
  );
};

export default AudienceLine;

import { Icons } from "~/layout/icon";
import { Attachment, ImageAttachment } from ".";

// .avi   video/x-msvideo
// .csv   text/csv
// .doc   application/msword
// .docx  application/vnd.openxmlformats-officedocument.wordprocessingml.document
// .gz    application/gzip
// .gif   image/gif
// .htm   text/html
// .html  text/html
// .ico   image/vnd.microsoft.icon
// .jpeg  image/jpeg
// .jpg   image/jpeg
// .json  application/json
// .mp3   audio/mpeg
// .mpeg  video/mpeg
// .odp   application/vnd.oasis.opendocument.presentation
// .ods   application/vnd.oasis.opendocument.spreadsheet
// .odt   application/vnd.oasis.opendocument.text
// .png   image/png
// .pdf   application/pdf
// .ppt   application/vnd.ms-powerpoint
// .pptx  application/vnd.openxmlformats-officedocument.presentationml.presentation
// .rar   application/vnd.rar
// .rtf   application/rtf
// .svg   image/svg+xml
// .tar   application/x-tar
// .tif   image/tiff
// .tiff  image/tiff
// .ttf   font/ttf
// .txt   text/plain
// .vsd   application/vnd.visio
// .wav   audio/wav
// .xhtml application/xhtml+xml
// .xls   application/vnd.ms-excel
// .xlsx  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
// .xml   application/xml
// .zip   application/zip
// .7z    application/x-7z-compressed

export const contentTypeToIcon = (
  // contentType: ContentType
  contentType: string | null
): Icons | "file" => {
  switch (contentType) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "fileWord";
    case "application/pdf":
      return "filePdf";
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "fileExcel";
    case "image/gif":
    case "image/jpeg":
    case "image/png":
      return "fileImage";
    default:
      return "file";
  }
};

export const filterImages = (files: Attachment[]): ImageAttachment[] => {
  return files.filter((file) =>
    file.contentType.includes("image")
  ) as ImageAttachment[];
};

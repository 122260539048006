import { useQuery } from "@apollo/client";
import React from "react";
import { SelectField } from "~/form";
import { CostLineTemplate } from ".";
import { COST_LINE_TEMPLATES_QUERY } from "./constants";

interface Props {
  costBlockTemplateId: string;
  name: string;
  label?: string | null;
  required?: boolean;
}

const CostLineTemplateSelectField: React.FC<Props> = ({
  costBlockTemplateId,
  name,
  label,
  required = false,
}) => {
  const { loading, error, data } = useQuery<{
    costLineTemplates: CostLineTemplate[];
  }>(COST_LINE_TEMPLATES_QUERY, {
    variables: { costBlockTemplateId },
  });

  if (error) return <p>Error :(</p>;

  const costLineTemplateOptions = (data?.costLineTemplates || []).map(
    ({ id, ref, title }) => ({
      value: id,
      label: `#${ref} — ${title}`,
    })
  );

  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={costLineTemplateOptions}
      required={required}
    />
  );
};

export default CostLineTemplateSelectField;

import { default as React } from "react";
import { Col, FormGroup } from "react-bootstrap";
import { AddFormButton } from "~/form";
import { ConstructionLoan } from "~/report/construction-loan";
import { ContractorForm } from ".";
import { useCreateContractor } from "./actions";

interface Props {
  report: Pick<ConstructionLoan, "id">;
}

const NewContractor: React.FC<Props> = ({ report: { id: reportId } }) => {
  const createMutation = useCreateContractor();

  return (
    <ContractorForm
      defaultValues={{ reportId }}
      onSubmit={createMutation}
      horizontal
      resetBlank
    >
      <Col lg="auto">
        <FormGroup>
          <AddFormButton style={{ marginTop: "1.5rem" }} />
        </FormGroup>
      </Col>
    </ContractorForm>
  );
};

export default NewContractor;

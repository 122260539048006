import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Badge, Table } from "react-bootstrap";
import styled from "styled-components";
import invariant from "tiny-invariant";
import { useConstructionLoanBudgetQuery } from "~/graphql-operations";
import { Report } from "~/report";
import { client } from "~/setup";
import { Currency } from "~/util";

interface Props extends React.HTMLProps<HTMLDivElement> {
  report: Pick<Report, "id">;
  block?: boolean;
}

const BudgetBadge = ({
  totalFinanced,
  projectBudget,
  style,
  ...rest
}: {
  totalFinanced: number;
  projectBudget: number;
  style?: React.CSSProperties;
}) => {
  const offset = totalFinanced - projectBudget;
  const variant = offset >= 0 ? "success" : "red";
  return (
    <Badge style={{ backgroundColor: `var(--${variant})`, ...style }} {...rest}>
      <Currency>{offset}</Currency>
    </Badge>
  );
};

const Label = styled.span`
  display: block;
  margin: 0.5rem 0 0.125rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: var(--dark-gray);
`;

const StyledTable = styled(Table)<{ $block: boolean }>`
  margin: 0;
  font-family: var(--font-moon);

  td {
    padding: 0.25rem 0;
    border: 0;
  }

  .currency {
    font-family: var(--font-circular);
    font-size: 1.125rem;
    font-weight: normal;
  }
`;

const Title = styled.h3`
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--light-gray);
`;

const BudgetStatus: React.FC<Props> = ({ report: { id }, block, ...rest }) => {
  const { loading, error, data } = useConstructionLoanBudgetQuery({
    variables: { id },
  });

  if (error) return <p>Error :(</p>;
  if (loading) return null;

  const constructionLoan = data?.constructionLoan;
  invariant(constructionLoan, "Construction loan not found");

  const {
    equity,
    loanAmount,
    budget: { client, enterprise, plot, total },
  } = constructionLoan;

  const totalFinanced = (loanAmount || 0) + (equity || 0);

  return (
    <div {...rest}>
      <Title>Finansieringsplan</Title>
      <StyledTable $block={block}>
        <tbody>
          <tr>
            <td>
              <Label>Lånebeløp</Label> <Currency>{loanAmount}</Currency>
            </td>
            <td>
              <Label>Egenkapital</Label> <Currency>{equity}</Currency>
            </td>
            <td></td>
            <td className="text-right">
              <Label>Total finansiering</Label>
              <Currency>{totalFinanced}</Currency>
            </td>
          </tr>
          <tr>
            <td>
              <Label>Entreprise Budsjett</Label>
              <Currency>{enterprise}</Currency>
            </td>
            <td>
              <Label>BH-Kostnad Budsjett</Label>
              <Currency>{client}</Currency>
            </td>
            <td>
              {plot > 0 && (
                <>
                  <Label>Tomt</Label>
                  <Currency>{plot}</Currency>
                </>
              )}
            </td>
            <td className="text-right">
              <Label>Projsektbudsjett</Label> <Currency>{total}</Currency>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-right">
              <Label>Differanse</Label>
              <BudgetBadge
                totalFinanced={totalFinanced}
                projectBudget={total}
                style={{ marginLeft: "0" }}
              />
            </td>
          </tr>
        </tbody>
      </StyledTable>
      {/* <StyledRow>
        <Col sm="auto">
          <Label>Lånebeløp</Label>: <Currency>{loanAmount}</Currency>
        </Col>
        <Col sm="auto">
          <Label>Egenkapital</Label>: <Currency>{equity}</Currency>
        </Col>
        <Col sm="auto">
          <Label>Total finansiering</Label>
          <Currency>{totalFinanced}</Currency>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm="auto">
          <Label>Enterprise Budsjett</Label>
          <Currency>{enterprise}</Currency>
        </Col>
        <Col sm="auto">
          <Label>BH-Kostnad Budsjett</Label>: <Currency>{client}</Currency>
        </Col>
        <Col sm="auto">
          <Label>Projsektbudsjett</Label>: <Currency>{total}</Currency>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm="auto">
          <Label>Differanse</Label>
          <BudgetBadge budget={total} offset={totalFinanced - total} />
        </Col>
      </StyledRow> */}
    </div>
  );
};

// export default BudgetStatus;

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <BudgetStatus {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

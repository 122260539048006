import { useMutation } from "@apollo/client";
import { AttachableInput } from "~/form/attachment";
import { COMPANIES_QUERY } from ".";
import {
  Company,
  COMPANY_CREATE_MUTATION,
  COMPANY_UPDATE_MUTATION,
} from "./constants";

type UpdateCompanyInput = AttachableInput<
  Pick<Company, "id" | "name" | "logo">,
  "logo"
>;

export const useUpdateCompany = () => {
  const [updateMutation] = useMutation(COMPANY_UPDATE_MUTATION);
  return (input: UpdateCompanyInput) => {
    return updateMutation({
      variables: {
        input,
      },
    });
  };
};

export const useCreateCompany = () => {
  const [createMutation] = useMutation(COMPANY_CREATE_MUTATION);
  return (input: UpdateCompanyInput) => {
    return createMutation({
      variables: {
        input,
      },
      update: (cache, mutationResult) => {
        const newCompany = mutationResult.data.companyCreate.company;
        const data = cache.readQuery({
          query: COMPANIES_QUERY,
        });
        cache.writeQuery({
          query: COMPANIES_QUERY,
          variables: { name: newCompany.name },
          data: {
            companies: [...data.companies, newCompany].sort((a, b) =>
              a.name.localeCompare(b.name)
            ),
          },
        });
      },
      //refetchQueries: ["Companies"],
    });
  };
};

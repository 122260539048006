import { useMutation } from "@apollo/client";
import {
  CreateDocumentationBlockTemplateInput,
  DocumentationBlockTemplate,
  DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
  DOCUMENTATION_BLOCK_TEMPLATE_CREATE_MUTATION,
  DOCUMENTATION_BLOCK_TEMPLATE_DELETE_MUTATION,
  DOCUMENTATION_BLOCK_TEMPLATE_UPDATE_MUTATION,
  UpdateDocumentationBlockTemplateInput,
} from ".";

export const useCreateDocumentationBlockTemplate = () => {
  const [createDocumentationBlockTemplate] = useMutation<{
    createDocumentationBlockTemplate: {
      documentationBlockTemplate: DocumentationBlockTemplate;
      errors: string[];
    };
  }>(DOCUMENTATION_BLOCK_TEMPLATE_CREATE_MUTATION);
  return (input: CreateDocumentationBlockTemplateInput) => {
    return createDocumentationBlockTemplate({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation failed");
        const newDocumentationBlockTemplate =
          data?.createDocumentationBlockTemplate.documentationBlockTemplate;

        const cacheData = cache.readQuery<{
          documentationBlockTemplates: DocumentationBlockTemplate[];
        }>({
          query: DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { documentationBlockTemplates } = cacheData;

        cache.writeQuery({
          query: DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
          data: {
            documentationBlockTemplates: [
              ...documentationBlockTemplates,
              newDocumentationBlockTemplate,
            ],
          },
        });
      },
    });
  };
};

export const useUpdateDocumentationBlockTemplate = () => {
  const [updateDocumentationBlockTemplate] = useMutation(
    DOCUMENTATION_BLOCK_TEMPLATE_UPDATE_MUTATION
  );
  return (input: UpdateDocumentationBlockTemplateInput) => {
    return updateDocumentationBlockTemplate({
      variables: {
        input,
      },
    });
  };
};

export const useDeleteDocumentationBlockTemplate = () => {
  const [deleteDocumentationBlockTemplate] = useMutation(
    DOCUMENTATION_BLOCK_TEMPLATE_DELETE_MUTATION
  );
  return (id: string) => {
    return deleteDocumentationBlockTemplate({
      variables: {
        input: { id },
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation faild");

        const cacheData = cache.readQuery<{
          documentationBlockTemplates: DocumentationBlockTemplate[];
        }>({
          query: DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { documentationBlockTemplates } = cacheData;

        cache.writeQuery({
          query: DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
          data: {
            documentationBlockTemplates: documentationBlockTemplates.filter(
              (template) => template.id !== id
            ),
          },
        });
      },
    });
  };
};

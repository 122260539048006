import React from "react";
import { Col } from "react-bootstrap";
import { Fieldset, Form, NumberField, Row, TextField } from "~/form";
import { Block } from "~/layout";
import { CostBlockTemplate } from ".";
import { useUpdateCostBlockTemplate } from "./hooks";

type Props = {
  template: Pick<CostBlockTemplate, "title" | "number" | "id">;
};

const EditCostBlockTemplate: React.FC<Props> = ({ template }) => {
  const { id, title, number } = template;
  const defaultValues = { id, title, number };

  const update = useUpdateCostBlockTemplate();

  return (
    <Block>
      <Form defaultValues={defaultValues} onSubmit={update} autoSave horizontal>
        <Fieldset title="Informasjon">
          <Row>
            <Col sm="8">
              <TextField name="title" label="tittel" />
            </Col>
            <Col sm="2">
              <NumberField name="number" label="nummer" />
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default EditCostBlockTemplate;

import { ReportType } from "../constants";
import BoligselskapTiltaksliste from "./BoligselskapTiltaksliste";
import BygningsdetaljerTabell from "./BygningsdetaljerTabell";
import CostOverviewCharts from "./cost-overview-charts";
import CostOverviewTable from "./CostOverviewTable";
import DokumentasjonKontrollDato from "./DokumentasjonKontrollDato";
import DokumentasjonRevisjonDato from "./DokumentasjonRevisjonDato";
import DokumentasjonsKarakter from "./DokumentasjonsKarakter";
import EiendomByggear from "./EiendomByggear";
import EiendomHjemmelshaver from "./EiendomHjemmelshaver";
import EiendomMatrikkel from "./EiendomMatrikkel";
import EiendomMatrikkelList from "./EiendomMatrikkelList";
import EiendomTomteareal from "./EiendomTomteareal";
import InspeksjonBefaringsdato from "./InspeksjonBefaringsdato";
import InspeksjonTilstede from "./InspeksjonTilstede";
import KonsulenterAnsvarligPartnerKS from "./KonsulenterAnsvarligPartnerKS";
import KonsulenterDokumentasjonskontroll from "./KonsulenterDokumentasjonskontroll";
import KonsulenterProsjektansvarlig from "./KonsulenterProsjektansvarlig";
import KonsulenterUtforendeKonsulenter from "./KonsulenterUtforendeKonsulenter";
import KostnadsfordelingDiagram from "./KostnadsfordelingDiagram";
import KostnadsfordelingTabell from "./KostnadsfordelingTabell";
import LegendGround from "./LegendGround";
import LegendRadon from "./LegendRadon";
import Leieavtale from "./Leieavtale";
import MatrikkelBnr from "./MatrikkelBnr";
import MatrikkelGnr from "./MatrikkelGnr";
import MatrikkelKomune from "./MatrikkelKomune";
import MatrikkelMatrikkelkart from "./MatrikkelMatrikkelkart";
import MatrikkelSkrafoto from "./MatrikkelSkrafoto";
import MatrikkelSnr from "./MatrikkelSnr";
import MiljoForurensetGrunn from "./MiljoForurensetGrunn";
import MiljoRadonAktsomhet from "./MiljoRadonAktsomhet";
import OppdragsgiverFirmanavn from "./OppdragsgiverFirmanavn";
import OppdragsgiverNavn from "./OppdragsgiverNavn";
import PrioritetFargekoderBygningssjekk from "./PrioritetFargekoderBygningssjekk";
import ProsjektinformasjonAdresse from "./ProsjektinformasjonAdresse";
import ProsjektinformasjonProsjektnavn from "./ProsjektinformasjonProsjektnavn";
import ProsjektinformasjonProsjektnummer from "./ProsjektinformasjonProsjektnummer";
import ProsjektinformasjonType from "./ProsjektinformasjonType";
import Rapportkopi from "./Rapportkopi";
import RapportVedlikeholdslengde from "./RapportVedlikeholdslengde";
import { ShortcodeProps } from "./Shortcode";
import TddOppsummering from "./TddOppsummering";
import VedlikeholdKostnadsDiagram from "./VedlikeholdKostnadsDiagram";
import VedlikeholdKostnadsfordelingDiagram from "./VedlikeholdKostnadsfordelingDiagram";
import VedlikeholdKostnadsTabell from "./VedlikeholdKostnadsTabell";
import VedlikeholdKostnadsTddTabell from "./VedlikeholdKostnadsTddTabell";
import VedlikeholdsKarakter from "./VedlikeholdsKarakter";

type DisplayType = "block" | "inline";

const housingReports = [ReportType.HOUSING_ASSESSMENT];
const maintenanceReports = [
  ReportType.ASSESSMENT,
  ReportType.BREEAM,
  ReportType.TDD,
];

type ShortCodeMap = Record<
  string,
  {
    component: React.ComponentType<ShortcodeProps>;
    display: DisplayType;
    types: ReportType[];
  }
>;

export const shortcodeMap: ShortCodeMap = {
  boligselskap_tiltaksliste: {
    component: BoligselskapTiltaksliste,
    display: "block",
    types: housingReports,
  },
  bygningsdetaljer_tabell: {
    component: BygningsdetaljerTabell,
    display: "block",
    types: maintenanceReports,
  },
  cost_overview_charts: {
    component: CostOverviewCharts,
    display: "block",
    types: maintenanceReports,
  },
  cost_overview_table: {
    component: CostOverviewTable,
    display: "block",
    types: maintenanceReports,
  },
  dokumentasjons_karakter: {
    component: DokumentasjonsKarakter,
    display: "block",
    types: maintenanceReports,
  },
  dokumentasjon_kontroll_dato: {
    component: DokumentasjonKontrollDato,
    display: "inline",
    types: maintenanceReports,
  },
  dokumentasjon_revisjon_dato: {
    component: DokumentasjonRevisjonDato,
    display: "inline",
    types: maintenanceReports,
  },
  eiendom_byggear: {
    component: EiendomByggear,
    display: "inline",
    types: maintenanceReports,
  },
  eiendom_hjemmelshaver: {
    component: EiendomHjemmelshaver,
    display: "inline",
    types: maintenanceReports,
  },
  eiendom_matrikkel: {
    component: EiendomMatrikkel,
    display: "inline",
    types: maintenanceReports,
  },
  eiendom_matrikkel_list: {
    component: EiendomMatrikkelList,
    display: "inline",
    types: maintenanceReports,
  },
  eiendom_tomteareal: {
    component: EiendomTomteareal,
    display: "inline",
    types: maintenanceReports,
  },
  inspeksjon_befaringsdato: {
    component: InspeksjonBefaringsdato,
    display: "inline",
    types: maintenanceReports,
  },
  inspeksjon_tilstede: {
    component: InspeksjonTilstede,
    display: "inline",
    types: maintenanceReports,
  },
  konsulenter_ansvarlig_partner_ks: {
    component: KonsulenterAnsvarligPartnerKS,
    display: "inline",
    types: maintenanceReports,
  },
  konsulenter_dokumentasjonskontroll: {
    component: KonsulenterDokumentasjonskontroll,
    display: "inline",
    types: maintenanceReports,
  },
  konsulenter_prosjektansvarlig: {
    component: KonsulenterProsjektansvarlig,
    display: "inline",
    types: maintenanceReports,
  },
  konsulenter_utforende_konsulenter: {
    component: KonsulenterUtforendeKonsulenter,
    display: "inline",
    types: maintenanceReports,
  },
  kostnadsfordeling_diagram: {
    component: KostnadsfordelingDiagram,
    display: "block",
    types: maintenanceReports,
  },
  kostnadsfordeling_tabell: {
    component: KostnadsfordelingTabell,
    display: "block",
    types: maintenanceReports,
  },
  legend_ground_contamination: {
    component: LegendGround,
    display: "block",
    types: maintenanceReports,
  },
  legend_radon_caution: {
    component: LegendRadon,
    display: "block",
    types: maintenanceReports,
  },
  leieavtale: {
    component: Leieavtale,
    display: "block",
    types: maintenanceReports,
  },
  matrikkel_bnr: {
    component: MatrikkelBnr,
    display: "inline",
    types: maintenanceReports,
  },
  matrikkel_gnr: {
    component: MatrikkelGnr,
    display: "inline",
    types: maintenanceReports,
  },
  matrikkel_komune: {
    component: MatrikkelKomune,
    display: "inline",
    types: maintenanceReports,
  },
  matrikkel_matrikkelkart: {
    component: MatrikkelMatrikkelkart,
    display: "inline",
    types: maintenanceReports,
  },
  matrikkel_snr: {
    component: MatrikkelSnr,
    display: "inline",
    types: maintenanceReports,
  },
  matrikkel_skrafoto: {
    component: MatrikkelSkrafoto,
    display: "inline",
    types: maintenanceReports,
  },
  miljo_forurenset_grunn: {
    component: MiljoForurensetGrunn,
    display: "inline",
    types: maintenanceReports,
  },
  miljo_radon_aktsomhet: {
    component: MiljoRadonAktsomhet,
    display: "inline",
    types: maintenanceReports,
  },
  oppdragsgiver_firmanavn: {
    component: OppdragsgiverFirmanavn,
    display: "inline",
    types: maintenanceReports,
  },
  oppdragsgiver_navn: {
    component: OppdragsgiverNavn,
    display: "inline",
    types: maintenanceReports,
  },
  prioritet_fargekoder_bygningssjekk: {
    component: PrioritetFargekoderBygningssjekk,
    display: "block",
    types: maintenanceReports,
  },
  prioritet_fargekoder: {
    component: PrioritetFargekoderBygningssjekk,
    display: "block",
    types: maintenanceReports,
  },
  prosjektinformasjon_adresse: {
    component: ProsjektinformasjonAdresse,
    display: "inline",
    types: maintenanceReports,
  },
  prosjektinformasjon_prosjektnavn: {
    component: ProsjektinformasjonProsjektnavn,
    display: "inline",
    types: maintenanceReports,
  },
  prosjektinformasjon_prosjektnummer: {
    component: ProsjektinformasjonProsjektnummer,
    display: "inline",
    types: maintenanceReports,
  },
  prosjektinformasjon_type: {
    component: ProsjektinformasjonType,
    display: "inline",
    types: maintenanceReports,
  },
  rapport_vedlikeholdslengde: {
    component: RapportVedlikeholdslengde,
    display: "inline",
    types: maintenanceReports,
  },
  rapportkopi: {
    component: Rapportkopi,
    display: "inline",
    types: maintenanceReports,
  },
  tdd_oppsummering: {
    component: TddOppsummering,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_dokumentasjon_kostnads_tabell_utten_mva: {
    component: VedlikeholdKostnadsTabell,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_dokumentasjon_kostnads_tabell: {
    component: VedlikeholdKostnadsTabell,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_kostnads_diagram: {
    component: VedlikeholdKostnadsDiagram,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_kostnads_tabell_utten_area_mva: {
    component: VedlikeholdKostnadsTabell,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_kostnads_tabell_utten_area: {
    component: VedlikeholdKostnadsTabell,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_kostnads_tabell_utten_mva: {
    component: VedlikeholdKostnadsTabell,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_kostnads_tabell: {
    component: VedlikeholdKostnadsTabell,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_kostnads_tdd_tabell: {
    component: VedlikeholdKostnadsTddTabell,
    display: "block",
    types: maintenanceReports,
  },
  vedlikehold_kostnadsfordeling_diagram: {
    component: VedlikeholdKostnadsfordelingDiagram,
    display: "block",
    types: maintenanceReports,
  },
  vedlikeholds_karakter: {
    component: VedlikeholdsKarakter,
    display: "block",
    types: maintenanceReports,
  },
};

// type Shortcode = keyof typeof shortcodeMap;
// const shortcodes = Object.keys(shortcodeMap) as Shortcode[];
// const shortcodesEnum = shortcodes.reduce(
//   (acc, key) => (acc[key] = key) && acc,
//   {} as Record<keyof typeof shortcodeMap, string>
// );

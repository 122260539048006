import { useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Building } from "~/Building";
import BlockList, { BlockListHeader } from "~/layout/BlockList";
import { CostSection, COST_SECTION_COST_BLOCKS_QUERY } from "../constants";
import { CostBlock } from "./constants";
import EditCostBlockListItem from "./EditCostBlockListItem";

interface Props {
  costSection: CostSection;
  buildings: Building[];
}

const EditCostBlockList: React.FC<Props> = ({
  costSection: { id },
  buildings,
}) => {
  const { loading, error, data } = useQuery(COST_SECTION_COST_BLOCKS_QUERY, {
    variables: {
      id: id.toString(),
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    costSection,
    costSection: { costBlocks },
  } = data;

  return (
    <>
      <BlockListHeader>
        <Row>
          <Col lg="5">Vedlikeholdsdel</Col>
          <Col lg="2">Bygning</Col>
          <Col lg="2">Avvik</Col>
          <Col lg="2" className="_tar">
            Kostnad
          </Col>
          <Col lg="1" className="_tar">
            Valg
          </Col>
        </Row>
      </BlockListHeader>
      <BlockList>
        {costBlocks.map((costBlock: CostBlock) => (
          <EditCostBlockListItem
            costSection={costSection}
            costBlock={costBlock}
            reportBuildings={buildings}
            key={costBlock.id}
            id={`cost-block-${costBlock.id}`}
          />
        ))}
      </BlockList>
    </>
  );
};

export default EditCostBlockList;

import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  narrow?: boolean;
}

const StyledDiv = styled.div<Props>`
  margin: 0 0 1rem 0;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--titanium);

  ${({ narrow }) =>
    narrow &&
    css`
      .row {
        margin-left: -5px;
        margin-right: -5px;
      }
      [class*="col-"] {
        padding-left: 5px;
        padding-right: 5px;
      }
    `}
`;

const NodeHeader: React.FC<Props> = ({ children, ...rest }) => {
  return <StyledDiv {...rest}>{children}</StyledDiv>;
};

export default NodeHeader;

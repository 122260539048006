import { useQuery } from "@apollo/client";
import { DOCUMENTS_ADMIN_QUERY, DOCUMENTS_CLIENT_QUERY } from "~/Document";
import { Session } from "~/session";

export const useConstructionLoanDocuments = (
  slug: string,
  { generalEditor }: Session
) => {
  const { loading, error, data } = useQuery<{
    report: { documents: Document[]; documentsComment: string | null };
  }>(generalEditor ? DOCUMENTS_ADMIN_QUERY : DOCUMENTS_CLIENT_QUERY, {
    variables: { slug },
  });

  const { documents, documentsComment } = data?.report || {};
  return { loading, error, documents, documentsComment };
};

import {
  FieldValues,
  SubmitHandler,
  useFormContext,
  useWatch,
} from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Option } from "~/form/SelectField";

// NOTE: resetting after submit will override external reset eg. after mutation result
// isDirty update comes later?! -> https://codesandbox.io/s/async-setvalue-lnj5lf?file=/src/App.tsx
export const useFormAutoSave = <T extends FieldValues>(
  onSubmit: SubmitHandler<T>,
  options?: { delay?: number }
) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<T>();
  const data = useWatch({ control });
  const { delay = 1000 } = options || {};

  useDeepCompareEffect(() => {
    if (!isDirty) return; // submit even if invalid to show errors

    if (delay === 0) {
      handleSubmit(onSubmit)();
      return;
    }

    const timer = setTimeout(() => handleSubmit(onSubmit)(), delay);
    return () => clearTimeout(timer);
  }, [data, isDirty]); // isDirty update comes later?!
};

type Optionable = {
  id: string;
} & (
  | {
      name: string;
    }
  | {
      title: string;
    }
);

type LabelFormatter<T extends Optionable> = (option: T) => string;

export const toOption = <T extends Optionable>(
  option: T,
  labelFormatter?: LabelFormatter<T>
) => {
  const { id: value } = option;
  const label = labelFormatter
    ? labelFormatter(option)
    : ("title" in option && option.title) || ("name" in option && option.name);

  if (!label) throw new Error("NO NAME OR TITLE");

  return {
    label,
    value,
    data: option,
  } as Option<T>;
};

export const toOptions = <T extends Optionable>(
  records: T[],
  labelFormatter?: LabelFormatter<T>
) => records.map((record) => toOption(record, labelFormatter)) as Option<T>[];

import { useMutation } from "@apollo/client";
import {
  CreateRequestCostBlockTemplateInput,
  RequestCostBlockTemplate,
  REQUEST_COST_BLOCK_TEMPLATES_QUERY,
  REQUEST_COST_BLOCK_TEMPLATE_CREATE_MUTATION,
  REQUEST_COST_BLOCK_TEMPLATE_DELETE_MUTATION,
  REQUEST_COST_BLOCK_TEMPLATE_UPDATE_MUTATION,
  UpdateRequestCostBlockTemplateInput,
} from ".";

export const useCreateRequestCostBlockTemplate = () => {
  const [createRequestCostBlockTemplate] = useMutation<{
    createRequestCostBlockTemplate: {
      requestCostBlockTemplate: RequestCostBlockTemplate[];
      errrors: string[];
    };
  }>(REQUEST_COST_BLOCK_TEMPLATE_CREATE_MUTATION);
  return (input: CreateRequestCostBlockTemplateInput) => {
    return createRequestCostBlockTemplate({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation Failed");
        const newRequestCostBlockTemplate =
          data?.createRequestCostBlockTemplate.requestCostBlockTemplate;

        const cacheData = cache.readQuery<{
          requestCostBlockTemplates: RequestCostBlockTemplate[];
        }>({
          query: REQUEST_COST_BLOCK_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { requestCostBlockTemplates } = cacheData;

        cache.writeQuery({
          query: REQUEST_COST_BLOCK_TEMPLATES_QUERY,
          data: {
            requestCostBlockTemplates: [
              ...requestCostBlockTemplates,
              newRequestCostBlockTemplate,
            ],
          },
        });
      },
    });
  };
};

export const useUpdateRequestCostBlockTemplate = () => {
  const [updateRequestCostBlockTemplate] = useMutation(
    REQUEST_COST_BLOCK_TEMPLATE_UPDATE_MUTATION
  );
  return (input: UpdateRequestCostBlockTemplateInput) => {
    return updateRequestCostBlockTemplate({
      variables: {
        input,
      },
    });
  };
};

export const useDeleteRequestCostBlockTemplate = () => {
  const [deleteRequestCostBlockTemplate] = useMutation(
    REQUEST_COST_BLOCK_TEMPLATE_DELETE_MUTATION
  );
  return (id: string) => {
    return deleteRequestCostBlockTemplate({
      variables: {
        input: { id },
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation faild");

        const cacheData = cache.readQuery<{
          requestCostBlockTemplates: RequestCostBlockTemplate[];
        }>({
          query: REQUEST_COST_BLOCK_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { requestCostBlockTemplates } = cacheData;

        cache.writeQuery({
          query: REQUEST_COST_BLOCK_TEMPLATES_QUERY,
          data: {
            requestCostBlockTemplates: requestCostBlockTemplates.filter(
              (template) => template.id !== id
            ),
          },
        });
      },
    });
  };
};

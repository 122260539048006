import styled from "styled-components";

const Canvas = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin: 1.5rem auto;
  border-radius: 50%;
`;

export default Canvas;

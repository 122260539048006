import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";
import { RequestSection } from "../constants";
import {
  RequestCostBlockTemplate,
  requestCostBlockTemplateFragment,
} from "../cost-block-template";
import { RequestCostLine, requestCostLineFragment } from "../cost-line";
import { requestCostLineTemplateFragment } from "../cost-line-template";

export interface RequestCostBlock extends ResourceInterface {
  requestSectionId: string;
  requestSection: RequestSection;

  requestCostBlockTemplateId?: string;
  requestCostBlockTemplate?: RequestCostBlockTemplate;
  title: string;
  order: number;

  requestCostLines: RequestCostLine;
  requestCostLinesCount: number;

  cost: number;
  status: RequestCostLine["status"];
}

export const requestCostBlockFragment = gql`
  fragment RequestCostBlockFields on RequestCostBlock {
    id
    title
    number
    requestCostBlockTemplate {
      id
      title
      number
    }
  }
`;

export const requestCostBlockSummaryFragment = gql`
  fragment RequestCostBlockSummary on RequestCostBlock {
    id
    requestCostLinesCount
    cost
    status
  }
`;

export const requestCostBlockWithSummaryFragment = gql`
  fragment RequestCostBlockWithSummary on RequestCostBlock {
    id
    ...RequestCostBlockFields
    ...RequestCostBlockSummary
  }
  ${requestCostBlockFragment}
  ${requestCostBlockSummaryFragment}
`;

export const REQUEST_COST_BLOCK_SUMMARY_QUERY = gql`
  query requestCostBlockSummary($id: ID!) {
    requestCostBlock(id: $id) {
      ...RequestCostBlockSummary
    }
  }
  ${requestCostBlockSummaryFragment}
`;

export const REQUEST_COST_BLOCK_REQUEST_COST_LINES_ADMIN_QUERY = gql`
  query requestCostBlockWithRequestCostLines($id: ID!) {
    requestCostBlock(id: $id) {
      ...RequestCostBlockFields
      requestCostLines {
        ...RequestCostLineFields
      }
      requestCostBlockTemplate {
        ...RequestCostBlockTemplateFields
        requestCostLineTemplates {
          ...RequestCostLineTemplateFields
        }
      }
    }
  }
  ${requestCostBlockFragment}
  ${requestCostLineFragment}
  ${requestCostBlockTemplateFragment}
  ${requestCostLineTemplateFragment}
`;

export const REQUEST_COST_BLOCK_CREATE_MUTATION = gql`
  mutation RequestCostBlockCreate($input: RequestCostBlockCreateInput!) {
    requestCostBlockCreate(input: $input) {
      id
      ...RequestCostBlockWithSummary
    }
  }
  ${requestCostBlockWithSummaryFragment}
`;

export const REQUEST_COST_BLOCK_DELETE_MUTATION = gql`
  mutation RequestCostBlockDelete($input: RequestCostBlockDeleteInput!) {
    requestCostBlockDelete(input: $input) {
      id
    }
  }
`;

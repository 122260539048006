import { ApolloProvider } from "@apollo/client";
import invariant from "invariant";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useHousingMaintenanceBuildingPartRatingRulesQuery } from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import List from "~/layout/block-list";
import { Block, Body, Header } from "~/layout/block-list/collapsible";
import { BlockListHeader } from "~/layout/BlockList";
import { client } from "~/setup";
import {
  EditBuildingPartRatingRule,
  NewBuildingPartRatingRule,
  useDeleteBuildingPartRatingRule,
} from ".";
import {
  KGTypeLabel,
  periodTypeLabel,
  ratingTypeLabel,
  TGTypeLabel,
} from "./helpers";

const BuildingPartRatingRuleListPage: React.FC = () => {
  const destroy = useDeleteBuildingPartRatingRule();
  // const [
  //   deleteMutation,
  // ] = useDeleteHousingMaintenanceBuildingPartRatingRuleMutation();

  // const onDelete = async (id: string) => {
  //   return deleteMutation({
  //     variables: { input: { id } },
  //   });
  // };

  const {
    loading,
    error,
    data: { housingMaintenanceBuildingPartRatingRules } = {},
  } = useHousingMaintenanceBuildingPartRatingRulesQuery();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  invariant(housingMaintenanceBuildingPartRatingRules, "Data is missing");

  return (
    <>
      <BlockListHeader>
        <Row>
          <Col lg="1">TG</Col>
          <Col lg="1">KG</Col>
          <Col lg="1">Karakter</Col>
          <Col lg="2">Periode</Col>
          <Col>Beskrivelse</Col>
        </Row>
      </BlockListHeader>
      <List>
        {housingMaintenanceBuildingPartRatingRules.map(
          ({ id, tg, kg, rating, period, description }) => (
            <Block key={id} id={`building-part-rating-rule-${id}`}>
              <Header>
                <Row
                  className="align-items-center"
                  style={{ fontSize: "1rem", fontWeight: "normal" }}
                >
                  <Col lg="1">{TGTypeLabel(tg)}</Col>
                  <Col lg="1">{KGTypeLabel(kg)}</Col>
                  <Col lg="1">{ratingTypeLabel(rating)}</Col>
                  <Col lg="2">{periodTypeLabel(period)}</Col>
                  <Col>{description}</Col>
                  <Col lg="auto" className="text-right">
                    <DeleteButton iconOnly onClick={async () => destroy(id)} />
                  </Col>
                </Row>
              </Header>
              <Body style={{ paddingBottom: 0 }}>
                <EditBuildingPartRatingRule
                  buildingPart={{ id, rating, period, description, tg, kg }}
                />
              </Body>
            </Block>
          )
        )}
      </List>
      <NewBuildingPartRatingRule />
    </>
  );
};

const ApolloWrap: React.FC = () => (
  <ApolloProvider client={client}>
    <BuildingPartRatingRuleListPage />
  </ApolloProvider>
);

export default ApolloWrap;

import React from "react";
import {
  Report,
  ReportSectionsQuery,
  useReportSectionsQuery,
  useSortSectionsMutation,
} from "~/graphql-operations";
import { BlockTitle, List } from "~/layout/block-list";
import Sortable from "~/layout/block-list/sortable2";
import ErrorAlert from "~/layout/ErrorAlert";
import { reportUrl } from "~/report";
import Item from "./Item";
import { New } from "./text-section";

const getSections = (data?: ReportSectionsQuery) => {
  const report = data?.report;
  return report && "sections" in report ? report.sections : [];
};

type ReportUrlReportParam = Parameters<typeof reportUrl>[0];
export type ReportProp = ReportUrlReportParam & Pick<Report, "id">;

const SectionList = ({
  report,
  report: { id, slug },
}: {
  report: ReportProp;
}) => {
  const [sortSections] = useSortSectionsMutation();
  const { loading, error, data } = useReportSectionsQuery({
    variables: { slug },
  });

  if (loading)
    return (
      <List>
        <BlockTitle>Loading...</BlockTitle>
      </List>
    );

  if (error) <ErrorAlert error={error} />;

  const sections = getSections(data);
  const onSort = (sections: string[]) =>
    sortSections({
      variables: { input: { report: id, sections } },
    });

  return (
    <>
      <Sortable items={sections} onSort={onSort}>
        {(section) => <Item report={report} section={section} />}
      </Sortable>
      <New report={report} />
    </>
  );
};

export default SectionList;

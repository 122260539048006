import { gql } from "@apollo/client";
import { Report } from "~/report";
import { BuildingType } from "./../BuildingType/constants";

export interface Building {
  id: string; // Buildingship ID
  reportId: string;
  report: Report;
  buildingTypeId: string | null;
  buildingType: BuildingType | null;
  buildingTypeName: string;
  costPerM2: number;
  default: boolean;
  name: string;
  order: number;
  constructionYear?: string;
  renovation?: string;
  area?: number;
  gnr?: string;
  bnr?: string;
  snr?: string;
  fnr?: string;
  parts?: BuildingPart[];
  autoTotal: boolean;
  extraInfo?: string;
  // costBlockBuildingInfo
}

export interface BuildingPart {
  usage: string;
  floor?: string;
  area?: number;
}

export const buildingFieldsFragment = gql`
  fragment BuildingFields on Building {
    id
    name
    order
    constructionYear
    renovation
    area
    gnr
    bnr
    snr
    fnr
    autoTotal
    extraInfo
    parts {
      usage
      floor
      area
    }
    report {
      id
    }
    buildingTypeId
    buildingTypeName
    costPerM2
    default
  }
`;

export const BUILDINGS_QUERY = gql`
  query ReportBuildings($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Buildings {
        buildings {
          ...BuildingFields
        }
      }
    }
  }
  ${buildingFieldsFragment}
`;

export const BUILDING_CREATE_MUTATION = gql`
  mutation BuildingCreate($input: BuildingCreateInput!) {
    buildingCreate(input: $input) {
      ...BuildingFields
      report {
        id
        ... on Buildings {
          buildings {
            id
          }
        }
      }
    }
  }
  ${buildingFieldsFragment}
`;

export const BUILDING_UPDATE_MUTATION = gql`
  mutation BuildingUpdate($input: BuildingUpdateInput!) {
    buildingUpdate(input: $input) {
      ...BuildingFields
    }
  }
  ${buildingFieldsFragment}
`;

export const BUILDING_DELETE_MUTATION = gql`
  mutation BuildingDelete($input: BuildingDeleteInput!) {
    buildingDelete(input: $input) {
      errors
      building {
        ...BuildingFields
        report {
          id
          ... on Buildings {
            buildings {
              ...BuildingFields
            }
          }
        }
      }
    }
  }
  ${buildingFieldsFragment}
`;

export const BUILDINGS_SORT_MUTATION = gql`
  mutation BuildingsSort($input: BuildingsSortInput!) {
    buildingsSort(input: $input) {
      id
      report {
        id
        ... on Buildings {
          buildings {
            id
          }
        }
      }
    }
  }
`;

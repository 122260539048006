import { gql } from "@apollo/client";
import { Company } from "~/Company/constants";
import { NodeUrlsInterface, ResourceInterface } from "~/node";
import { CostSection, CostType } from "~/sections/cost-section";
import { TextSection } from "~/sections/text-section";
import { reportFieldsFragment } from "./fragments";
import { MaintenanceInterval, MaintenancePeriod } from "./maintenance";
import { Member } from "./member";

export enum ReportType {
  ASSESSMENT = "Assessment",
  TDD = "TDD",
  HOUSING_ASSESSMENT = "HousingAssessment",
  BREEAM = "Breeam",
  CONSTRUCTION_LOAN = "ConstructionLoan",
}

export interface Rental {
  tenant: string | null;
  startsAt: string | null;
  endsAt: string | null;
  signedAt: string | null;
}

export interface Report extends ResourceInterface, NodeUrlsInterface {
  type: ReportType;
  slug: string;
  name: string;
  companyId: string;
  company: Company;
  lockedAt: string | null;

  rentals: Rental[];

  projectManagers: Member[];
  partners: Member[];

  client: Member | null;
  // clientMembership?: [Membership];
  associates: Member[];
  // associateMemberships?: [Membership];
  collaborators: Member[];
  // collaboratorMemberships?: [Membership];

  costSections: CostSection[];
  textSections: TextSection[];
}

export interface MaintenanceReport extends Report {
  maintenance: {
    duration: number | null;
    intervals: MaintenanceInterval[];
    periods: MaintenancePeriod[];
  };
  cost: Record<CostType, number | null>;
}

export const REPORT_QUERY = gql`
  query Report($slug: ID!) {
    report(slug: $slug) {
      ...ReportFields
    }
  }
  ${reportFieldsFragment}
`;

export const REPORT_CREATE_MUTATION = gql`
  mutation ReportCreate($input: ReportCreateInput!) {
    reportCreate(input: $input) {
      id
      ...ReportFields
    }
  }
  ${reportFieldsFragment}
`;

export const REPORT_UPDATE_MUTATION = gql`
  mutation ReportUpdate($input: ReportUpdateInput!) {
    reportUpdate(input: $input) {
      id
      ...ReportFields
    }
  }
  ${reportFieldsFragment}
`;

export const REPORT_PUBLISH_MUTATION = gql`
  mutation ReportPublish($input: ReportPublishInput!) {
    reportPublish(input: $input) {
      id
      lockedAt
      urls {
        show
      }
    }
  }
`;

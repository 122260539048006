import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { formatDate } from "~/util";
import { ShortcodeProps } from ".";

const DOCUMENTATION_CONTROL_DATE_QUERY = gql`
  query DokumentasjonKontrollDato($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Documentation {
        documentationSection {
          creationDate
          revisionDate
        }
      }
    }
  }
`;

const DokumentasjonKontrollDato: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(DOCUMENTATION_CONTROL_DATE_QUERY, {
    variables: { slug },
  });

  if (loading) return <WordLoader />;
  if (error) return <p>Error :(</p>;

  const { creationDate, revisionDate } =
    data?.report?.documentationSection || {};

  return (
    <>
      {creationDate ? (
        revisionDate ? (
          <span>
            <strong>{formatDate(creationDate, { format: "L" })}</strong>,
            revidert
            <strong> {formatDate(revisionDate, { format: "L" })}</strong>
          </span>
        ) : (
          <span>
            <strong>{formatDate(creationDate, { format: "L" })}</strong>.
          </span>
        )
      ) : (
        <span>
          <strong>dd.mm.yyyy</strong>.
        </span>
      )}
    </>
  );
};

export default DokumentasjonKontrollDato;

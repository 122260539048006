import { pick } from "lodash";
import React from "react";
import { Col } from "react-bootstrap";
import { FileVersionsToggle, useDeleteFileVersion } from "~/file-version";
import { AttachmentField, DateField, Form, Row, TextField } from "~/form";
import { attachmentInput } from "~/form/attachment";
import {
  ExternalInvoice,
  InvoiceCategory,
  UpdateExternalInvoiceInput,
  useUpdateExternalInvoiceMutation,
} from "~/graphql-operations";
import { AdditionalCurrency } from "~/util/currency";
import { InvoiceAmountFields } from ".";
import { CategorySelectField } from "./category";

interface Props {
  invoice: ExternalInvoice;
  categories: InvoiceCategory[];
  currencies: AdditionalCurrency[];
  vatIncluded: boolean;
}

const EditInvoice: React.FC<Props> = ({
  invoice,
  invoice: { id, file },
  categories,
  currencies,
  vatIncluded,
}) => {
  const [updateMutation] = useUpdateExternalInvoiceMutation();
  const deleteFile = useDeleteFileVersion();

  const onSubmit = async (input: UpdateExternalInvoiceInput) => {
    return await updateMutation({ variables: { input } });
  };

  const defaultValues = {
    ...pick(invoice, [
      "id",
      "amount",
      "categoryId",
      "currency",
      "description",
      "dueDate",
      "number",
      "receivedDate",
      "sender",
    ]),
    file: file && file && attachmentInput(file),
  };

  return (
    <Form
      autoSave
      horizontal
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      id={`external-invoice-${id}-form`}
    >
      <Row className="align-items-end">
        <Col>
          <TextField name="sender" label="Avsender" required />
        </Col>
        <Col lg="2">
          <TextField name="number" label="Faktura" required />
        </Col>
        <Col lg="2">
          <DateField name="dueDate" label="Faktura dato" />
        </Col>
        <Col lg="auto">
          <InvoiceAmountFields
            currencies={currencies}
            vatIncluded={vatIncluded}
          />
        </Col>
      </Row>
      <Row className="align-items-end">
        <Col>
          <CategorySelectField
            name="categoryId"
            label="Kategori"
            categories={categories}
            required
          />
        </Col>
        <Col>
          <TextField name="description" label="Faktura beskrivelse" />
        </Col>
        <Col lg="auto">
          <DateField
            name="receivedDate"
            label="Mottat BER"
            required
            notClearable
          />
        </Col>
      </Row>
      <AttachmentField
        name="file"
        label="Fakturafil"
        attachments={file}
        noDelete
      />
      {file && <FileVersionsToggle node={{ id }} onDelete={deleteFile} />}
    </Form>
  );
};

export default EditInvoice;

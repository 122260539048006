import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from ".";
import { WordLoader } from "~/layout/Loading";

const RAPPORTKOPI_QUERY = gql`
  query ReportRapportkopiQuery($slug: ID!) {
    report(slug: $slug) {
      associates {
        companyName
        name
      }
    }
  }
`;

const Rapportkopi: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery<{
    report: {
      associates: {
        companyName: string;
        name: string;
      }[];
    };
  }>(RAPPORTKOPI_QUERY, {
    variables: { slug },
  });

  if (loading) return <WordLoader />;
  if (error) return <p>Error :(</p>;

  const associates = data?.report?.associates || [];

  return (
    <>
      {associates.map((associate, index) => {
        const { companyName, name } = associate;
        if (companyName && name)
          return (
            <span key={index}>
              {companyName} v/ {name} <br />
            </span>
          );
        return (
          <span key={index}>
            {companyName}
            {name}
            <br />
          </span>
        );
      })}
    </>
  );
};

export default Rapportkopi;

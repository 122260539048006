import React from "react";
import { Col, Row } from "react-bootstrap";
import { Fieldset, Form, NumberField, SaveFormButton, TextField } from "~/form";
import { Block } from "~/layout";
import { useCreateCostBlockTemplate } from "./hooks";

const NewCostBlockTemplate: React.FC = () => {
  const create = useCreateCostBlockTemplate();

  return (
    <Block>
      <Form onSubmit={create} resetBlank horizontal>
        <Fieldset title="Ny Kostnadsblokkmal">
          <Row>
            <Col sm="8">
              <TextField name="title" label="tittel" />
            </Col>
            <Col sm="2">
              <NumberField name="number" label="nummer" />
            </Col>
            <Col>
              <SaveFormButton />
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default NewCostBlockTemplate;

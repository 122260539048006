import { FetchResult } from "@apollo/client";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { SubmitHandler } from "react-hook-form";
import { Building } from "~/Building";
import {
  AddFormButton,
  CheckboxField,
  Form,
  RedactorField,
  SelectField,
  toOptions
} from "~/form";
import { DeleteButton } from "~/layout";
import { CostBlockBuildingInfo } from "./constants";
import { toFormData } from "./helpers";

interface Props
  extends Omit<React.HTMLAttributes<HTMLFormElement>, "onSubmit"> {
  reportBuildings?: Building[];
  buildingInfo?: Partial<CostBlockBuildingInfo>;
  onSubmit: SubmitHandler<Partial<CostBlockBuildingInfo>>;
  onDelete?: () => Promise<FetchResult<CostBlockBuildingInfo>>;
}

const tgOptions = [
  { value: -1, label: "Ingen" },
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "IU" },
];

const kgOptions = [
  { value: -1, label: "Ingen" },
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
];

const BuildingInfoForm: React.FC<Props> = ({
  reportBuildings,
  buildingInfo = {},
  onSubmit,
  onDelete,
  ...rest
}) => {
  const edit = !!buildingInfo.id;
  return (
    <Form
      defaultValues={toFormData(buildingInfo)}
      onSubmit={onSubmit}
      autoSave={edit}
      horizontal
      {...rest}
    >
      <Row className={edit ? "align-items-center" : undefined}>
        <Col lg="4">
          {reportBuildings ? (
            <SelectField
              name="buildingId"
              label="Bygning"
              options={toOptions(reportBuildings)}
              required
            />
          ) : (
            <FormGroup>
              <span className="block-list-item-title">
                {buildingInfo.building?.name}
              </span>
            </FormGroup>
          )}
        </Col>
        <Col lg="1">
          <CheckboxField name="hidden" label={edit ? "Ja" : "Skjult?"} />
        </Col>
        <Col lg="2">
          <SelectField
            name="tg"
            label={edit ? null : undefined}
            options={tgOptions}
          />
        </Col>
        <Col lg="2">
          <SelectField
            name="kg"
            label={edit ? null : undefined}
            options={kgOptions}
          />
        </Col>
        <Col className={edit ? "text-right" : undefined}>
          {buildingInfo.id && onDelete ? (
            <FormGroup>
              <DeleteButton iconOnly onClick={onDelete} />
            </FormGroup>
          ) : (
            <AddFormButton />
          )}
        </Col>
      </Row>
      {buildingInfo.id && (
        <RedactorField name="description" label="Beskrivelse" />
      )}
    </Form>
  );
};

export default BuildingInfoForm;

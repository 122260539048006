import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { SaveButton } from "~/layout";

interface Props {
  rew: (message: string) => void;
  rewinding?: boolean;
  label?: string;
}

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100%;
`;

const StepRewButton: React.FC<Props> = ({
  rew,
  rewinding = false,
  label = "Send tilbake",
}) => {
  const [show, setShow] = useState(false);
  const textArea = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onRewind = () => {
    rew(textArea.current.value);
    handleClose();
  };

  return (
    <>
      <SaveButton
        size="sm"
        icon="deny"
        loading={rewinding}
        onClick={handleShow}
      >
        {label}
      </SaveButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send tilbake for revisjon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StyledTextArea ref={textArea} placeholder="Hva må revideres?" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={onRewind}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StepRewButton;

import styled from "styled-components";

// interface Props {
//   ref?: React.Ref<HTMLDivElement>;
// }

export const Wrap = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  color: var(--dark-gray);
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 6px;

  &:last-child:not(:first-child) {
    margin-bottom: 0;
  }
`;

// const Block: React.FC<Props> = ({ children, ...rest }) => {
//   return <Wrap {...rest}>{children}</Wrap>;
// };

// const BlockFwRef = React.forwardRef<HTMLDivElement, Props>(
//   ({ children }, ref) => <Block ref={ref}>{children}</Block>
// );
// BlockFwRef.displayName = "BlockFwRef";

// export default BlockFwRef;

export default Wrap;

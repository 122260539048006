import React, { forwardRef, ReactNode } from "react";
import { Form, FormCheckProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import FieldError from "./FieldError";
import { inputFieldRef } from "./InputField";

export interface Props extends CheckboxProps {
  validation?: Record<string, unknown>;
  style?: React.CSSProperties;
}

export interface CheckboxProps extends Omit<FormCheckProps, "type"> {
  name: string;
  label: string | ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ isInvalid, children, ...rest }, ref) => (
    <Form.Group>
      <Form.Check type="checkbox" isInvalid={isInvalid} ref={ref} {...rest} />
      {children}
    </Form.Group>
  )
);

const CheckboxField: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label = name, validation, ...rest },
  ref
) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  const options = validation || { required: false };
  // if (type === "number") options["valueAsNumber"] = true;
  // else if (type === "date") options["valueAsDate"] = true;
  const { ref: hookFormRef, ...formControlProps } = register(name, options);

  return (
    <Checkbox
      label={label}
      isInvalid={error}
      type="checkbox"
      {...formControlProps}
      ref={inputFieldRef(hookFormRef, ref)}
      {...rest}
    >
      {error && <FieldError error={error} />}
    </Checkbox>
  );
};

const ForwardedCheckboxField = forwardRef<HTMLInputElement, Props>(
  CheckboxField
);

export default ForwardedCheckboxField;

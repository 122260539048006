import { useDeleteFileVersionMutation } from "~/graphql-operations";

export const useDeleteFileVersion = () => {
  const [deleteFile] = useDeleteFileVersionMutation();

  return (id: string) =>
    deleteFile({
      variables: { input: { id } },
    });
};

import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Building } from "~/Building";
import { WordLoader } from "~/layout/Loading";
import { Table, TCell, THCell, THead, TRow } from "~/layout/table/CostTable";
import { ShortcodeProps } from ".";
import { Report } from "../constants";

const BUILDING_QUERY = gql`
  query ReportBuildingsQuery($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Buildings {
        buildings {
          extraInfo
          name
          area
          parts {
            area
            floor
            usage
          }
        }
      }
    }
  }
`;

const BygningsdetaljerTabell: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery<{
    report: Pick<Report, "id"> & { buildings: Building[] };
  }>(BUILDING_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  const { buildings = [] } = data?.report || {};

  return (
    <>
      {loading ? (
        <WordLoader />
      ) : (
        <>
          {buildings.map((building, index) => {
            const { name, area, extraInfo } = building;
            return (
              <div key={`${index}-${name}`}>
                <h3>{name}</h3>
                <Table
                  responsive="sm"
                  borderless
                  style={{ marginBottom: "20px" }}
                >
                  <THead>
                    <TRow>
                      <THCell left style={{ width: "auto", minWidth: "80px" }}>
                        Etasje
                      </THCell>
                      <THCell left style={{ width: "auto" }}>
                        Bruk
                      </THCell>
                      <THCell right style={{ width: "auto" }}>
                        Bruttoareal (BTA) 33
                      </THCell>
                    </TRow>
                  </THead>
                  <tbody>
                    {(building?.parts || []).map((part, index) => {
                      const { floor, usage, area } = part;
                      return (
                        <TRow key={index}>
                          <TCell left>{floor}</TCell>
                          <TCell left>{usage}</TCell>
                          <TCell right>
                            {area?.toFixed(1)} m<sup>2</sup>
                          </TCell>
                        </TRow>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <TRow right>
                      <TCell colSpan={2}>Sum areal</TCell>
                      <TCell>
                        {area?.toFixed(1)} m<sup>2</sup>
                      </TCell>
                    </TRow>
                  </tfoot>
                </Table>
                {extraInfo && (
                  <div style={{ marginTop: "20px", marginBottom: "60px" }}>
                    {extraInfo}
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}
      {buildings.length > 1 && (
        <>
          <h3>Hele eiendommen</h3>
          <Table responsive="sm" borderless>
            <tfoot>
              <TRow right>
                <TCell>Sum areal</TCell>
                <TCell>
                  {buildings
                    .map((building) => building.area)
                    .reduce((sum, curr) => (sum || 0) + (curr || 0), 0)
                    ?.toFixed(1)}
                  m<sup>2</sup>
                </TCell>
              </TRow>
            </tfoot>
          </Table>
        </>
      )}
    </>
  );
};

export default BygningsdetaljerTabell;

import { snakeCase } from "lodash";
import { Section } from "~/graphql-operations";
import { reportUrl } from "~/report";

const SectionUrlActionEnum = {
  View: "view",
  Edit: "edit",
} as const;

type SectionUrlAction = typeof SectionUrlActionEnum[keyof typeof SectionUrlActionEnum];

export const sectionUrl = (
  report: Parameters<typeof reportUrl>[0],
  { id, __typename }: Pick<Section, "id"> & { __typename: string },
  action: SectionUrlAction = "view"
) => {
  const url = `${reportUrl(report)}/${snakeCase(__typename)}s/${id}`;
  return action !== "view" ? `${url}/${action}` : url;
};

import React from "react";
import { Badge, Col } from "react-bootstrap";
import Select from "react-select";
import styled from "styled-components";
import { customStyles } from "~/form/SelectField";
import { ConstructionLoanPeriod } from "~/graphql-operations";
import { IconButton } from "~/layout";
import PeriodTitle from "./Title";

type BarePeriod = Pick<
  ConstructionLoanPeriod,
  "id" | "title" | "startedAt" | "published" | "publishedAt" | "full"
>;

interface Props {
  current: string;
  periods: BarePeriod[];
  // tab: "summary" | "progress";
}

interface PeriodTitleProps {
  period: BarePeriod;
}

// // TODO: PeriodTitle already exists in Title.tsx, reuse it
// const PeriodTitle: React.FC<PeriodTitleProps> = ({
//   period,
//   period: { full },
// }) => {
//   return (
//     <>
//       {periodTitle(period)}
//       {!full && (
//         <Badge variant="secondary" className="ml-2">
//           soft
//         </Badge>
//       )}
//     </>
//   );
// };

const toOption = (period: BarePeriod) => {
  return {
    label: <PeriodTitle period={period} />,
    value: period.id,
  };
};

const StyledTitle = styled.p`
  color: white;
  text-align: center;
`;

const StyledPeriodTitle: React.FC<PeriodTitleProps> = ({ period }) => {
  const { full } = period;
  return (
    <StyledTitle>
      <PeriodTitle period={period} />
      {!full && (
        <Badge variant="secondary" className="ml-2">
          soft
        </Badge>
      )}
    </StyledTitle>
  );
};

const PeriodNavigation: React.FC<Props> = ({ current, periods }) => {
  const currentPeriod = periods.find(
    (period) => period.id === current
  ) as BarePeriod;
  const currentIndex = periods.indexOf(currentPeriod);
  const nextPeriod = periods[currentIndex + 1];
  const prevPeriod = periods[currentIndex - 1];
  const options = periods.map(toOption);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ color: "initial", textAlign: "left" }}
    >
      {nextPeriod && (
        <IconButton
          icon="chevronLeft"
          variant="primary"
          onClick={() => (window.location.href = `?period=${nextPeriod.id}`)}
          title={nextPeriod.title}
        />
      )}
      <Col lg="3">
        {nextPeriod || prevPeriod ? (
          <Select
            options={options}
            value={toOption(currentPeriod)}
            onChange={({ value }) =>
              (window.location.href = `?period=${value}`)
            }
            styles={customStyles()}
          />
        ) : (
          <StyledPeriodTitle period={currentPeriod} />
        )}
      </Col>
      {prevPeriod && (
        <IconButton
          icon="chevronRight"
          variant="primary"
          onClick={() => (window.location.href = `?period=${prevPeriod.id}`)}
          title={prevPeriod.title}
        />
      )}
    </div>
  );
};

export default PeriodNavigation;

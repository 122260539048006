import React from "react";
import styled from "styled-components";
import { CheckboxField, Form } from "~/form";
import { Membership } from "~/graphql-operations";
import { updateMembership } from "~/Membership";

// const SET_CONSTRUCTION_LOAN_MEMBER_EMAIL_PREFERENCES = gql`
//   mutation SetConstructionLoanMemberPreferences(
//     $input: setConstructionLoanMemberEmailPreferencesInput!
//   ) {
//     setConstructionLoanMemberEmailPreferences(input: $input) {
//       id
//       preferences {
//         email {
//           document
//           invoice
//           report
//         }
//       }
//     }
//   }
// `;

const Wrap = styled.fieldset`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;

  .form-group,
  label {
    margin: 0;
  }
  .form-check {
    margin: 0 0 0 0.5rem;
  }
`;

type EmailPreferences = {
  payout: boolean;
  invoices: boolean;
  report: boolean;
};

const EditEmailPreferences = ({
  membership: { id, preferences },
}: {
  membership: Pick<Membership, "id" | "preferences">;
}) => {
  const updateMutation = updateMembership();

  const handleSubmit = (email: EmailPreferences) =>
    updateMutation({
      id,
      preferences: { email },
    });

  return (
    <Form
      id={`constuction-loan-member-${id}-email-preferences`}
      defaultValues={preferences?.email}
      onSubmit={handleSubmit}
      autoSave
    >
      <Wrap>
        <label className="mr-2">Email Preferences</label>

        <CheckboxField name="payout" label="Trekkanmodning" className="mr-2" />
        <CheckboxField name="invoices" label="Fakturaer" className="mr-2" />
        <CheckboxField name="report" label="Rapport" className="mr-2" />

        {/* <label className="mr-2">
        <input
          type="checkbox"
          value="document"
          // checked={true}
          // onChange={(e) =>
          //   e.target.checked
          //     ? onChange([...permissions, "view"])
          //     : onChange(permissions.filter((p) => p !== "view"))
          // }
        />{" "}
        Trekkanmodning
      </label>
      <label className="mr-2">
        <input type="checkbox" value="report" /> Rapport
      </label>
      <label className="mr-2">
        <input type="checkbox" value="invoice" /> Fakturaer
      </label> */}
      </Wrap>
    </Form>
  );
};

export default EditEmailPreferences;

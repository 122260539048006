import { gql } from "@apollo/client";
import { ImageAttachment } from "~/attachment";

export interface Company {
  id: string;
  name: string;
  logo: ImageAttachment | null;
  reportsCount: number;
}

export const companyFragment = gql`
  fragment CompanyFields on Company {
    id
    name
    reportsCount
    logo {
      url
    }
  }
`;

export const COMPANIES_QUERY = gql`
  query Companies {
    companies {
      ...CompanyFields
    }
  }
  ${companyFragment}
`;

export const COMPANY_QUERY = gql`
  query Company($id: ID!) {
    company(id: $id) {
      ...CompanyFields
    }
  }
  ${companyFragment}
`;

export const COMPANY_UPDATE_MUTATION = gql`
  mutation updateCompany($input: CompanyUpdateInput!) {
    companyUpdate(input: $input) {
      errors
      company {
        ...CompanyFields
      }
    }
  }
  ${companyFragment}
`;

export const COMPANY_CREATE_MUTATION = gql`
  mutation createCompany($input: CompanyCreateInput!) {
    companyCreate(input: $input) {
      errors
      company {
        ...CompanyFields
      }
    }
  }
  ${companyFragment}
`;

import React from "react";
import styled from "styled-components";
import { LegendEnvironment, LegendTitle } from "./styles";

const LegendItemTriangleRed = styled.li`
  padding-left: 28px;
  position: relative;
  line-height: 1.75;
  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid black;
    border-bottom-color: #ff3265;
  }
`;

const LegendItemTriangleYellow = styled.li`
  padding-left: 28px;
  position: relative;
  line-height: 1.75;
  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid black;
    border-bottom-color: #ffcc4f;
  }
`;

const LegendItemTriangleGreen = styled.li`
  padding-left: 28px;
  position: relative;
  line-height: 1.75;
  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid black;
    border-bottom-color: #80eca4;
  }
`;

const LegendItemTrianglePurple = styled.li`
  padding-left: 28px;
  position: relative;
  line-height: 1.75;
  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid black;
    border-bottom-color: #800080;
  }
`;

const LegendGround: React.FC = () => {
  return (
    <>
      <LegendEnvironment>
        <LegendTitle>Forurenset grunn</LegendTitle>
        <LegendItemTriangleRed>
          Alvorlig forerensning - tiltak nødvendig
        </LegendItemTriangleRed>
        <LegendItemTriangleYellow>
          Kan brukes med restriksjoner
        </LegendItemTriangleYellow>
        <LegendItemTriangleGreen>Kan brukes</LegendItemTriangleGreen>
        <LegendItemTrianglePurple>
          Mistanke om påvirkning
        </LegendItemTrianglePurple>
      </LegendEnvironment>
    </>
  );
};

export default LegendGround;

import { useQuery } from "@apollo/client";
import React from "react";
import Block from "~/layout/Block";
import BlockList from "~/layout/BlockList";
import { Session, useSession } from "~/session";
import { generalEditor } from "~/user";
import { EditDocumentationLine, NewDocumentationLine } from ".";
import { DocumentationBlock, DOCUMENTATION_BLOCK_LINES_QUERY } from "../block";

interface Props {
  documentationBlock: DocumentationBlock;
}

const EditDocumentationLineList: React.FC<Props> = ({
  documentationBlock: { id },
}) => {
  const { loading, error, data } = useQuery(DOCUMENTATION_BLOCK_LINES_QUERY, {
    variables: {
      id,
    },
  });
  const { role } = useSession() as Session;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    documentationBlock,
    documentationBlock: { documentationLines },
  } = data;

  return (
    <>
      <BlockList sub>
        {documentationLines.map((documentationLine) => (
          <EditDocumentationLine
            key={documentationLine.id}
            documentationBlock={documentationBlock}
            documentationLine={documentationLine}
          />
        ))}
      </BlockList>
      {generalEditor(role) && (
        <Block>
          <NewDocumentationLine documentationBlock={documentationBlock} />
        </Block>
      )}
    </>
  );
};

export default EditDocumentationLineList;

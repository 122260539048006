import React from "react";
import styled from "styled-components";

export const StyledBlockListItemHeader = styled.div`
  margin: 0 -1rem 1rem -1rem;
  padding: 0 1rem 1rem 1rem;
  font-family: var(--font-moon);
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  /* color: var(--titanium); */
  border-bottom: 2px solid var(--mystic);
`;

const BlockListItemHeader: React.FC = ({ children }) => {
  return <StyledBlockListItemHeader>{children}</StyledBlockListItemHeader>;
};

export default BlockListItemHeader;

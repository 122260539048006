import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import { AddFormButton, DateField, FileField, Form, Row } from "~/form";
import ToggleField from "~/form/ToggleField";
import { ConstructionLoanPeriod } from "~/graphql-operations";
import { mutationSuccess } from "~/util";
import {
  ContractorInvoice,
  InvoiceAmountFields,
  InvoiceType,
  useCreateInvoice,
} from ".";
import { ContractorSelectField } from "./contractor";
import FakturaFields from "./FakturaFields";

interface Props {
  period: Pick<ConstructionLoanPeriod, "id"> & {
    report: Pick<
      ConstructionLoanPeriod["report"],
      "currencies" | "contractors" | "vatIncluded"
    >;
  };
  onCreate: () => void;
}

const NewContractorInvoice: React.FC<Props> = ({
  period: {
    id: periodId,
    report: { currencies, contractors, vatIncluded },
  },
  onCreate,
  children,
}) => {
  const [create, uploadProgress] = useCreateInvoice<ContractorInvoice>(
    InvoiceType.CONTRACTOR
  );

  const onSubmit: typeof create = async (input) => {
    const res = await create(input);
    if (mutationSuccess(res)) onCreate();
    return res;
  };

  return (
    <Form
      defaultValues={{
        accordingToPlan: true,
        currency: "nok",
        multi: false,
        periodId,
        receivedDate: new Date().toString(),
      }}
      onSubmit={onSubmit}
      resetBlank
    >
      <Row className="align-items-end">
        <Col>
          <ContractorSelectField
            name="contractorId"
            contractors={contractors}
            required
          />
        </Col>
        <FakturaFields />
        <Col lg="2">
          <DateField name="dueDate" label="Faktura dato" required />
        </Col>
        <Col lg="auto">
          <ToggleField name="accordingToPlan" label="According to plan" />
        </Col>
      </Row>
      <Row>
        <Col lg="auto">
          <InvoiceAmountFields
            currencies={currencies}
            vatIncluded={vatIncluded}
          />
        </Col>
        <Col lg="auto">
          <DateField
            name="receivedDate"
            label="Mottat BER"
            required
            notClearable
          />
        </Col>
        <Col>
          <FileField name="file" label="Fakturafil" progress={uploadProgress} />
        </Col>
      </Row>
      <FormGroup style={{ margin: "1rem 0 0.5rem 0" }}>
        <Row>
          <Col sm="auto">
            <AddFormButton />
          </Col>
          {children && <Col sm="auto">{children}</Col>}
        </Row>
      </FormGroup>
    </Form>
  );
};

export default NewContractorInvoice;

import React from "react";

interface Props {
  children: number;
}

const getLabel = (value: number) => {
  switch (value) {
    case -1:
      return "Ingen";
    case 0:
    case 1:
    case 2:
    case 3:
      return value.toString();
    case 4:
      return "IU";
    default:
      return "-";
  }
};

const TG: React.FC<Props> = ({ children }) => {
  return <span>{getLabel(children)}</span>;
};

export default TG;

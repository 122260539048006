import { pick } from "lodash";
import { Option } from "~/form/SelectField";
import { User, UserRole } from "~/graphql-operations";
import { nodeAttributeLabel } from "~/node";
import { CVLine, userRoleOptions } from ".";

export const getCurrentUserId = (): string | null => {
  return document.querySelector("html")?.dataset?.currentUserId || null;
};

export const generalEditor = (role: User["role"]): boolean =>
  [UserRole.Admin, UserRole.Editor].includes(role);

export const userRoleLabel = nodeAttributeLabel(userRoleOptions);

type UserToLabel = Pick<User, "id" | "companyName" | "email" | "name" | "role">;

export const userToLabel = (user: UserToLabel) =>
  [user.name, user.email, user.role === UserRole.Client ? user.companyName : ""]
    .filter((v) => v !== "")
    .join(" - ");

export const toOptions = (userList: UserToLabel[]): Option<UserToLabel>[] =>
  userList.map((user) => toOption(user));

export const toOption = (user: UserToLabel): Option<UserToLabel> => {
  return {
    label: userToLabel(user),
    value: user.id,
    data: user,
  };
};

export const cvLineToInput = (arr: CVLine[]) =>
  arr.map((el: CVLine) =>
    pick(el, ["text", "startYear", "endYear", "type", "hidden"])
  );

import React from "react";
import { useFormContext } from "react-hook-form";
import { SaveButton } from "~/layout";
import { SaveButtonProps } from "../layout/Button/SaveButton";

export type SaveFormButtonProps = SaveButtonProps;

const SaveFormButton: React.FC<SaveFormButtonProps> = ({
  disabled = false,
  ...rest
}) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <SaveButton
      type="submit"
      loading={isSubmitting}
      disabled={disabled || isSubmitting}
      {...rest}
    />
  );
};

export default SaveFormButton;

import React from "react";
import {
  InvoiceCategoryTypeEnum,
  InvoiceContractor,
} from "~/graphql-operations";
import { invoiceCategoryTypeOptions } from "~/invoice/category-type";
import { CategoryForm, useCreateInvoiceEntryCategory } from ".";

interface Props {
  contractor: Pick<InvoiceContractor, "id">;
  vatIncluded: boolean;
}

// vatIncluded is inverted from the report setting
const NewCategory: React.FC<Props> = ({
  contractor: { id: contractorId },
  vatIncluded,
}) => {
  const createMutation = useCreateInvoiceEntryCategory();
  const typeOptions = invoiceCategoryTypeOptions.filter(
    ({ value }) => value !== InvoiceCategoryTypeEnum.Client
  );

  return (
    <CategoryForm
      defaultValues={{ contractorId, vatIncluded: !vatIncluded }}
      onSubmit={createMutation}
      typeOptions={typeOptions}
    />
  );
};

export default NewCategory;

import React from "react";
import styled, { css } from "styled-components";

const Wrap = styled.span<{ $ellipsis: boolean }>`
  font-family: var(--font-moon);
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const BlockListItemData: React.FC<{ ellipsis?: boolean }> = ({
  children,
  ellipsis = false,
}) => {
  return <Wrap $ellipsis={ellipsis}>{children}</Wrap>;
};

export default BlockListItemData;

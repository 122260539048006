import React from "react";
import { Badge } from "react-bootstrap";
import { User, userRoleLabel } from ".";

interface Props {
  role: User["role"];
}

const roleVariant = (role: Props["role"]) => {
  switch (role) {
    case "admin":
    case "editor":
    case "consultant":
    case "client":
      return "secondary";
    default:
      return "dark";
  }
};

const RoleBadge: React.FC<Props> = ({ role }) => (
  <Badge variant={roleVariant(role)}>{userRoleLabel(role)}</Badge>
);

export default RoleBadge;

import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import Icon from "~/layout/icon";
import { EditCategory, InvoiceCategory } from ".";

interface Props {
  index: number;
  category: InvoiceCategory;
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "0.25rem 0.75rem 0.25rem 0.75rem",
  // margin: `0 0 ${8}px 0`,

  // change background colour if dragging
  background: isDragging ? "var(--yellow)" : "transparent",
  borderRadius: "6px",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ItemWrap = styled.div`
  display: flex;
  align-items: center;
`;

const DraggableCategory: React.FC<Props> = ({
  index,
  category,
  category: { id },
}) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <ItemWrap
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style || {}
          )}
          id={`invoice-category-${id}`}
        >
          <Icon
            icon="move"
            {...provided.dragHandleProps}
            style={{ marginRight: "0.75rem" }}
          />

          <EditCategory category={category} key={id} />
        </ItemWrap>
      )}
    </Draggable>
  );
};

export default DraggableCategory;

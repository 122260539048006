import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import {
  AddFormButton,
  DateField,
  Fieldset,
  Form,
  Row,
  TextField,
} from "~/form";
import {
  ConstructionLoan,
  CreateConstructionLoanPeriodInput,
  useCreateConstructionLoanPeriodMutation,
} from "~/graphql-operations";

interface Props {
  name?: string;
  reportId: ConstructionLoan["id"];
}

const NewConstructionLoanPeriod: React.FC<Props> = ({ name, reportId }) => {
  const [createMutation] = useCreateConstructionLoanPeriodMutation();
  const onSubmit = async (input: CreateConstructionLoanPeriodInput) => {
    return createMutation({ variables: { input } });
  };

  return (
    <Form
      values={{
        name,
        reportId,
        startedAt: new Date(),
      }}
      onSubmit={onSubmit}
      resetBlank
      horizontal
    >
      <Fieldset title="Ny innhold">
        <Row>
          <Col lg="2">
            <DateField name="startedAt" label="Startdato" required />
          </Col>
          <Col lg="4">
            <TextField name="name" label="Tittel" required />
          </Col>
          <Col lg="auto">
            <FormGroup>
              <AddFormButton style={{ marginTop: "1.5rem" }} />
            </FormGroup>
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewConstructionLoanPeriod;

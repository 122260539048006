import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { AddFormButton, Fieldset, Form, TextField } from "~/form";
import { CostBlockTemplateSelectField } from "~/sections/cost-section/block-template";
import { createMutation } from ".";
import { HousingMaintenanceSection } from "../constants";

interface Props {
  housingMaintenanceSection: HousingMaintenanceSection;
}

const NewBuildingPart: React.FC<Props> = ({ housingMaintenanceSection }) => {
  const onSubmit = createMutation(housingMaintenanceSection);

  return (
    <Form onSubmit={onSubmit} horizontal>
      <Fieldset title="Ny innhold">
        <Row className="align-items-start">
          <Col lg="4">
            <CostBlockTemplateSelectField
              name="costBlockTemplateId"
              label="Bygningsdel"
              required
            />
          </Col>
          <Col lg="4">
            <TextField name="name" label="Navn" required />
          </Col>
          <Col lg="auto">
            <FormGroup>
              <AddFormButton style={{ marginTop: "1.5rem" }} />
            </FormGroup>
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewBuildingPart;

import React from "react";
import styled from "styled-components";
import { FileVersion } from "~/graphql-operations";
import { formatDate } from "~/util";

export interface FileVersionInfoProps {
  fileVersion: Pick<FileVersion, "createdAt"> & {
    user: Pick<FileVersion["user"], "name" | "email">;
  };
}

const StyledEmail = styled.span`
  font-size: 0.875rem;
  color: var(--titanium);
`;

const StyledDate = styled(StyledEmail)`
  margin-left: 0.25rem;
`;

const StyledSeparator = styled(StyledEmail)`
  padding: 0 0.25rem;
  color: var(--dark-gray);
`;

const FileVersionInfo: React.FC<FileVer> = ({
  fileVersion: {
    user: { name, email },
    createdAt,
  },
}) => {
  return (
    <>
      <StyledDate>{createdAt && formatDate(createdAt)}</StyledDate>
      <StyledSeparator>/</StyledSeparator>
      <StyledEmail>
        {name} - {email}
      </StyledEmail>
    </>
  );
};

export default FileVersionInfo;

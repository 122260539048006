import ReactOnRails from "react-on-rails";
import { BuildingList, NewBuilding } from "~/Building";
import { BuildingTypeIndexPage } from "~/BuildingType";
import {
  CompaniesPage,
  CompanyFilter,
  CompanyIndex,
  EditCompanyPage,
} from "~/Company";
import ImageLightbox from "~/layout/ImageLightbox";
import { AffiliateList, ConsultantList } from "~/Membership";
import { CostSectionSummary } from "~/publication/sections/cost-section/summary";
import { InfoCard as ReportInfoCard, PublishReport } from "~/report";
import {
  ConstructionLoanCategoriesPage,
  ConstructionLoanContentPage,
  ConstructionLoanDeliveriesPage,
  ConstructionLoanDocumentsPage,
  ConstructionLoanEventsPage,
  ConstructionLoanImagesPage,
  ConstructionLoanPeriodNavigation,
  ConstructionLoanPeriodPage,
  EditConstructionLoanExtra,
  EditConstructionQuarter,
  NewConstructionLoan,
} from "~/report/construction-loan";
import { BudgetStatus } from "~/report/construction-loan/budget";
import ReportDeprecatedAudience from "~/report/DeprecatedAudience";
import ReportExportDropdown from "~/report/ExportDropdown";
import { Hero as ReportHero } from "~/report/hero";
import EditConsultants from "~/report/member/EditConsultants";
import EditLeads from "~/report/member/EditLeads";
import { NewMulti } from "~/report/multi";
import ReportRegeneratePdf from "~/report/RegeneratePdf";
import { CostLineNotePopover, StatusBadge } from "~/sections/cost-section/line";
import {
  CostSectionStateAlert,
  CostSectionStateSteps,
} from "~/sections/cost-section/state";
import TestComponent from "~/Test";
import { EditUser, NewUser, UserFilter, UserIndexPage } from "~/user";
// import NewReportForm from "~/report/form/NewReportForm";
// import ReportForm from "~/report/form/ReportForm";
import { EditAudience } from "~/report/member";
import { ShortcodeParser } from "~/report/shortcode";
import { CostSectionPage } from "~/sections/cost-section";
import { CostBlockTemplateListPage } from "~/sections/cost-section/block-template";
import { EditDocumentationSectionPage } from "~/sections/documentation";
import { DocumentationBlockTemplatesListPage } from "~/sections/documentation/block-template";
import { DocumentationLineScoreBadge } from "~/sections/documentation/line";
import { HousingMaintenanceSectionPage } from "~/sections/housing-maintenance-section";
import { BudgetLinesPage as HousingMaintenanceBudgetLinesPage } from "~/sections/housing-maintenance-section/budget-line";
import { BuildingPartsPage as HousingMaintenanceBuildingPartsPage } from "~/sections/housing-maintenance-section/building-part";
import { BuildingPartRatingRuleListPage } from "~/sections/housing-maintenance-section/building-part-rating-rule";
import { RequestSectionPage } from "~/sections/request-section";
import { RequestCostBlockTemplatesListPage } from "~/sections/request-section/cost-block-template";
import { RequestLineFilePopover } from "~/sections/request-section/line";
import { LineTemplateListPage } from "~/sections/request-section/line-template";
import { EditTextSectionPage } from "~/sections/text-section";
import { SessionMenu } from "~/session";

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  // NewReportForm,
  // ReportForm,
  AffiliateList,
  BudgetStatus,
  BuildingList,
  BuildingPartRatingRuleListPage,
  BuildingTypeIndexPage,
  CompaniesPage,
  CompanyFilter,
  CompanyIndex,
  ConstructionLoanCategoriesPage,
  ConstructionLoanContentPage,
  ConstructionLoanDeliveriesPage,
  ConstructionLoanDocumentsPage,
  ConstructionLoanEventsPage,
  ConstructionLoanImagesPage,
  ConstructionLoanPeriodNavigation,
  ConstructionLoanPeriodPage,
  EditConsultants,
  EditLeads,
  ConsultantList,
  CostBlockTemplateListPage,
  CostLineNotePopover,
  CostSectionPage,
  CostSectionStateAlert,
  CostSectionStateSteps,
  DocumentationBlockTemplatesListPage,
  DocumentationLineScoreBadge,
  EditAudience,
  EditAudience,
  EditCompanyPage,
  EditConstructionLoanExtra,
  EditConstructionQuarter,
  EditDocumentationSectionPage,
  EditTextSectionPage,
  EditUser,
  HousingMaintenanceBudgetLinesPage,
  HousingMaintenanceBuildingPartsPage,
  HousingMaintenanceSectionPage,
  ImageLightbox,
  LineTemplateListPage,
  NewBuilding,
  NewConstructionLoan,
  NewMulti,
  NewUser,
  PublishReport,
  ReportDeprecatedAudience,
  ReportExportDropdown,
  ReportRegeneratePdf,
  ReportHero,
  ReportInfoCard,
  RequestCostBlockTemplatesListPage,
  RequestLineFilePopover,
  RequestSectionPage,
  SessionMenu,
  ShortcodeParser,
  CostSectionSummary,
  StatusBadge,
  TestComponent,
  UserFilter,
  UserIndexPage,
});

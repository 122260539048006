import React from "react";
import ContentLoader from "react-content-loader";

const LineLoader: React.FC = () => {
  return (
    <ContentLoader
      speed={1}
      width={160}
      height={11}
      backgroundColor="#bebebe"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="60" height="11" />
      <rect x="70" y="0" rx="3" ry="3" width="90" height="11" />
    </ContentLoader>
  );
};

export default LineLoader;

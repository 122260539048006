import { gql } from "@apollo/client";
import { Building } from "~/Building";
import { ResourceInterface } from "~/node";
import {
  CostBlockTemplate,
  costBlockTemplateFragment,
} from "~/sections/cost-section/block-template";
import {
  costBlockFragment,
  costBlockWithSummaryFragment,
  costLineFragment,
} from "~/sections/cost-section/fragments";
import { CostLine } from "~/sections/cost-section/line";
import { costLineTemplateFragment } from "~/sections/cost-section/line-template";
import {
  CostBlockBuildingInfo,
  costBlockBuildingInfoFragment,
} from "./building-info";

export interface CostBlock extends ResourceInterface {
  costSectionId: string;
  costBlockTemplateId: string;
  costBlockTemplate?: CostBlockTemplate;
  buildings?: Building[];
  buildingsInfo?: CostBlockBuildingInfo[];
  title: string;
  number: number;
  order: number;
  // summary
  buildingsCount: number;
  costLinesCount: number;
  cost: number;
  status: CostLine["status"];
}

// export const COST_BLOCK_SUMMARY_QUERY = gql`
//   query costBlockSummary($id: ID!) {
//     costBlock(id: $id) {
//       ...CostBlockSummary
//     }
//   }
//   ${costBlockSummaryFragment}
// `;

export const COST_BLOCK_COST_LINE_ADMIN_QUERY = gql`
  query CostBlockWithCostLines($id: ID!) {
    costBlock(id: $id) {
      ...CostBlockFields
      buildings {
        id
        name
      }
      costLines {
        ...CostLineFields
      }
      costBlockTemplate {
        ...CostBlockTemplateFields
        costLineTemplates {
          ...CostLineTemplateFields
        }
      }
    }
  }
  ${costBlockFragment}
  ${costLineFragment}
  ${costBlockTemplateFragment}
  ${costLineTemplateFragment}
`;

export const COST_BLOCK_BUILDINGS_INFO_QUERY = gql`
  query CostBlockWithBuildingInfo($id: ID!) {
    costBlock(id: $id) {
      id
      buildings {
        id
        name
      }
      buildingsInfo {
        ...CostBlockBuildingInfoFields
      }
    }
  }
  ${costBlockBuildingInfoFragment}
`;

export const COST_BLOCK_CREATE_MUTATION = gql`
  mutation CostBlockCreate($input: CostBlockCreateInput!) {
    costBlockCreate(input: $input) {
      id
      ...CostBlockWithSummary
    }
  }
  ${costBlockWithSummaryFragment}
`;

export const COST_BLOCK_DELETE_MUTATION = gql`
  mutation CostBlockDelete($input: CostBlockDeleteInput!) {
    costBlockDelete(input: $input) {
      id
    }
  }
`;

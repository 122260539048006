import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AddFormButton, Form } from "~/form";
import { createDocumentationLineMutation } from ".";
import { DocumentationBlock } from "../block";
import { DocumentationLineTemplateSelectField } from "../line-template";

interface Props {
  documentationBlock: DocumentationBlock;
}

const NewDocumentationBlock: React.FC<Props> = ({ documentationBlock }) => {
  const formMethods = useForm();
  const { reset } = formMethods;
  const create = createDocumentationLineMutation(documentationBlock);

  const onSubmit = async (
    input: Pick<DocumentationBlock, "documentationBlockTemplateId">
  ) => {
    await create(input);
    reset();
  };

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} horizontal>
      <Row>
        <Col>
          <DocumentationLineTemplateSelectField
            documentationBlockTemplateId={
              documentationBlock.documentationBlockTemplateId
            }
            name="documentationLineTemplateId"
            label="Komponent"
            required
          />
        </Col>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton style={{ marginTop: "1.5rem" }} />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default NewDocumentationBlock;

import { CostBlockBuildingInfo } from "./constants";

type toFormData = (
  attributes: Partial<CostBlockBuildingInfo>
) => Partial<CostBlockBuildingInfo>;
export const toFormData: toFormData = ({ tg, kg, description, hidden }) => ({
  tg,
  kg,
  description,
  hidden,
});

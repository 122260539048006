import React from "react";
import { Col } from "react-bootstrap";
import {
  AddFormButton,
  CheckboxField,
  Form,
  FormProps,
  Row,
  TextField,
} from "~/form";
import { CVLine } from "..";

interface Props {
  onSubmit: FormProps<CVLine>["onSubmit"];
}

const NewCVLine: React.FC<Props> = ({ onSubmit }) => {
  return (
    <>
      <h5>New EducationLine</h5>
      <Form onSubmit={onSubmit} resetBlank>
        <Row>
          <Col>
            <TextField name="text" label="Innold" required />
          </Col>
          <Col sm="2">
            <TextField name="startYear" label="STARTÅR" required />
          </Col>
          <Col sm="2">
            <TextField name="endYear" label="SLUTTÅR" required />
          </Col>
          <Col sm="1" style={{ display: "none" }}>
            <TextField name="type" label="type" />
          </Col>
          <Col sm="auto">
            <CheckboxField name="hidden" label="hidden" />
          </Col>
          <AddFormButton />
        </Row>
      </Form>
    </>
  );
};

export default NewCVLine;

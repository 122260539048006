import React from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import Icon, { Icons, StyledIcon } from "../icon";

export interface BlockHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: Icons;
  variant?: "light" | "dark";
  ref?: React.Ref<HTMLDivElement>;
}

const Wrap = styled.div<BlockHeaderProps>`
  margin: -1rem;
  padding: 1rem;
  font-family: var(--font-moon);
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--dark-gray);
  background: var(--white);
  border-radius: 6px;
  transition: all 0.2s ease-in-out;

  ${({ variant }) =>
    variant === "dark"
      ? css`
          color: var(--white);
          background: var(--dark-gray);
          border-radius: 6px 6px 0 0;

          ${StyledIcon} {
            background-color: var(--yellow);
          }
        `
      : css`
          &:hover {
            color: var(--dark-gray);
            background: var(--smoke);
          }
        `}
`;

const BlockHeader: React.FC<BlockHeaderProps> = ({
  icon,
  children,
  ...rest
}) => {
  return (
    <Wrap {...rest}>
      {!icon ? (
        children
      ) : (
        <Row className="align-items-center">
          <Col xs="auto" className="pr-0">
            <Icon icon={icon} className="block-header-icon" />
          </Col>
          <Col>{children}</Col>
        </Row>
      )}
    </Wrap>
  );
};

const BlockHeaderFwRef = React.forwardRef<HTMLDivElement, BlockHeaderProps>(
  ({ children, ...rest }, ref) => {
    return (
      <BlockHeader ref={ref} {...rest}>
        {children}
      </BlockHeader>
    );
  }
);
BlockHeaderFwRef.displayName = "BlockHeaderFwRef";

export default BlockHeaderFwRef;

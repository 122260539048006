export const capitalize = (text: string): string =>
  text[0].toUpperCase() + text.slice(1);

/**
 * String camelization: camelize("hip_hop") => hipHop.
 * @param text Word or sentence
 * @param separator delimiter, '_' by default
 */
export const camelize = (text: string, separator = "_"): string => {
  const words = text.split(separator);
  const result = [words[0]];
  words.slice(1).forEach((word) => result.push(capitalize(word)));
  return result.join("");
};

export const constantize = (text: string): string => capitalize(camelize(text));

/**
 * Norwegian pluralization
 * @param word
 * @param count
 */
export const pluralize = (word: string, count = 2): string => {
  if (count == 1) return word;
  switch (word) {
    case "avvik":
      return "avvik";
    case "kapittel":
      return "kapitler";
    // case "punkt":
    //   return "punkter";
    default:
      return `${word}er`;
  }
};

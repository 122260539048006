import { default as React } from "react";
import { Card, CardProps } from "react-bootstrap";

interface Props extends CardProps {
  title?: string;
  subtitle?: string;
}

// const StyledModal = styled(BSModal)`
//   .modal-body > p:last-child {
//     margin-bottom: 0;
//   }
// `;

const MyCard: React.FC<Props> = ({ children, title, subtitle, ...rest }) => {
  return (
    <Card {...rest}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      {title && <Card.Title as="h3">{title}</Card.Title>}
      <Card.Body className="card-content">
        {subtitle && (
          <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
        )}
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
        {children}
      </Card.Body>
    </Card>
  );
};

export default MyCard;

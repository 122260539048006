import React from "react";
import styled from "styled-components";
import invariant from "tiny-invariant";
import {
  ConstructionLoan,
  useInvoiceCategoriesQuery,
} from "~/graphql-operations";
import { SortableCategories } from ".";

interface Props extends React.HTMLProps<HTMLDivElement> {
  report: Pick<ConstructionLoan, "id">;
}

const Wrapper = styled.div`
  .form-group {
    margin: 0;
  }
  .form-label {
    display: none;
  }
`;

const StyledGroupName = styled.h3`
  margin: 1rem 0;
  padding: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  background: #fff;
`;

const SortableCategoryTypes: React.FC<Props> = ({
  report: { id },
  ...rest
}) => {
  const { loading, error, data } = useInvoiceCategoriesQuery({
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  invariant(data?.constructionLoan, "Construction loan not found");
  const categories = data.constructionLoan.invoiceCategories;

  return (
    <Wrapper {...rest}>
      <StyledGroupName>BH-Kostnad</StyledGroupName>
      <SortableCategories categories={categories} />
    </Wrapper>
  );
};

export default SortableCategoryTypes;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { DeliveryList, NewDelivery } from "~/delivery";
import { Report } from "~/report";
import { client } from "~/setup";

interface Props {
  report: Pick<Report, "id" | "slug">;
}

const DeliveriesPage: React.FC<Props> = ({ report }) => {
  return (
    <>
      <DeliveryList report={report} />
      <NewDelivery report={report} />
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <DeliveriesPage {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

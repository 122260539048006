import { Reference, useMutation } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DeleteButton } from "~/layout";
import {
  BlockListItemData,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { StatusBadge } from "~/sections/cost-section/line";
import { Session, useSession } from "~/session";
import { RequestSection } from "../constants";
import {
  RequestCostBlock,
  REQUEST_COST_BLOCK_DELETE_MUTATION,
} from "./constants";
import EditRequestCostBlockCostLines from "./EditRequestCostBlockRequestCostLines";

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  requestSection: RequestSection;
  requestCostBlock: RequestCostBlock;
}

const EditRequestCostBlockListItem: React.FC<Props> = ({
  requestSection,
  requestCostBlock,
  requestCostBlock: { id, number, title, requestCostLinesCount, cost, status },
  ...rest
}) => {
  const { role } = useSession() as Session;
  const [deleteCostBlock] = useMutation(REQUEST_COST_BLOCK_DELETE_MUTATION);

  // const { id, number, title } = requestCostBlock;

  const onDelete = ["admin", "editor"].includes(role)
    ? () => {
        return deleteCostBlock({
          variables: {
            input: {
              id,
            },
          },
          update(cache) {
            cache.modify({
              id: cache.identify(requestSection),
              fields: {
                requestCostBlocks: (refs, { readField }) =>
                  refs.filter((ref: Reference) => id !== readField("id", ref)),
              },
            });
          },
        });
      }
    : null;

  return (
    <BlockListItemExpandable
      render={({ expanded }) => (
        <Row style={{ alignItems: "center" }}>
          <Col lg="2">
            <BlockListItemTitle>
              <StatusBadge status={status} className="mr-2" /> {number}
            </BlockListItemTitle>
          </Col>
          <Col lg={onDelete ? 4 : 6}>
            <BlockListItemData>{title}</BlockListItemData>
          </Col>
          <Col lg="2">
            <BlockListItemData>
              {requestCostLinesCount} Elementer
            </BlockListItemData>
          </Col>
          <Col lg="2" className="_tar">
            <BlockListItemData>{cost}</BlockListItemData>
          </Col>
          {onDelete && (
            <Col lg="2" className="_tar">
              <DeleteButton
                onClick={onDelete}
                iconOnly
                inverse={expanded}
                style={{ padding: 0 }}
              />
            </Col>
          )}
        </Row>
      )}
      {...rest}
    >
      <EditRequestCostBlockCostLines requestCostBlock={requestCostBlock} />
    </BlockListItemExpandable>
  );
};

export default EditRequestCostBlockListItem;

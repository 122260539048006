import React from "react";
import List from "~/layout/block-list";
import { Block, Body, Header } from "~/layout/block-list/collapsible";
import EditParagraph, { EditParagraphProps } from "./EditParagraph";
import ParagraphHeader from "./ParagraphHeader";

type ParagraphProp = EditParagraphProps["paragraph"];

interface Props {
  paragraphs: ParagraphProp[];
}

const ParagraphBlock: React.FC<{ paragraph: ParagraphProp }> = ({
  paragraph,
}) => {
  return (
    <Block id={`paragraph-${paragraph.id}`}>
      <Header>
        <ParagraphHeader paragraph={paragraph} showDelete />
      </Header>
      <Body>
        <EditParagraph paragraph={paragraph} />
      </Body>
    </Block>
  );
};

const EditParagraphList: React.FC<Props> = ({ paragraphs }) => {
  return (
    <List>
      {paragraphs.map((paragraph) => (
        <ParagraphBlock key={paragraph.id} paragraph={paragraph} />
      ))}
    </List>
  );
};

export default EditParagraphList;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Building } from "~/Building";
import Block from "~/layout/Block";
import { Report } from "~/report";
import ReportProvider from "~/report/ReportProvider";
import { Session, SessionProvider } from "~/session";
import { client } from "~/setup";
import { EditCostBlockList, NewCostBlock } from "./block";
import { CostSection } from "./constants";
import EditCostSectionRequestCostLines from "./EditCostSectionRequestCostLines";

interface Props {
  report: Report;
  buildings: Building[];
  costSection: CostSection;
}

const CostSectionPage: React.FC<
  Omit<Props, "report"> & { reportType: Report["type"] }
> = ({ reportType, costSection: costSection, buildings }) => {
  const { requestCostLineCount } = costSection;
  return (
    <>
      <div id="cost-block-list">
        <EditCostBlockList costSection={costSection} buildings={buildings} />
        <hr />
        <Block>
          <NewCostBlock costSection={costSection} />
        </Block>
      </div>

      {requestCostLineCount > 0 && reportType === "TDD" && (
        <>
          <hr />
          <h2 id="request-cost-line-list">Dokumentasjonsbehov</h2>
          <EditCostSectionRequestCostLines costSection={costSection} />
        </>
      )}
    </>
  );
};

interface WrapProps extends Props {
  session: Session;
}

const ApolloWrap: React.FC<WrapProps> = ({ session, report, ...rest }) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <ReportProvider report={{ ...report, id: report.id.toString() }}>
        <CostSectionPage {...rest} reportType={report.type} />
      </ReportProvider>
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

import { Reference, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Building } from "~/Building";
import Block from "~/layout/Block";
import { NodeHeader } from "~/node";
import { BuildingInfoForm } from ".";
import { CostBlock } from "..";
import { COST_BLOCK_BUILDINGS_INFO_QUERY } from "../constants";
import {
  CostBlockBuildingInfo,
  costBlockBuildingInfoFragment,
  COST_BLOCK_BUILDING_INFO_CREATE_MUTATION,
  COST_BLOCK_BUILDING_INFO_DELETE_MUTATION,
  COST_BLOCK_BUILDING_INFO_UPDATE_MUTATION,
} from "./constants";

interface Props {
  costBlock: CostBlock;
  reportBuildings: Building[];
}

const EditBuildingInfoList: React.FC<Props> = ({
  costBlock,
  reportBuildings,
}) => {
  const { data } = useQuery<CostBlock>(COST_BLOCK_BUILDINGS_INFO_QUERY, {
    variables: {
      id: costBlock.id,
    },
  });
  const [createBuildingInfo] = useMutation(
    COST_BLOCK_BUILDING_INFO_CREATE_MUTATION
  );
  const [updateBuildingInfo] = useMutation(
    COST_BLOCK_BUILDING_INFO_UPDATE_MUTATION
  );
  const [deleteBuildingInfo] = useMutation(
    COST_BLOCK_BUILDING_INFO_DELETE_MUTATION
  );

  const onCreate = (input: Partial<CostBlockBuildingInfo>) => {
    return createBuildingInfo({
      variables: {
        input: {
          ...input,
          costBlockId: costBlock.id,
        },
      },
      // TODO: write to costBlock.buildings
      update: (cache, { data: { costBlockBuildingInfoCreate } }) => {
        const newRef = cache.writeFragment({
          data: costBlockBuildingInfoCreate,
          fragment: costBlockBuildingInfoFragment,
        });
        cache.modify({
          id: cache.identify(costBlock),
          fields: {
            buildings: (refs: Reference[]) => [
              ...refs,
              costBlockBuildingInfoCreate.building,
            ],
            buildingsCount: (count: number) => count + 1,
            buildingsInfo: (refs: Reference[]) => [...refs, newRef],
          },
        });
      },
    });
  };

  const onUpdate = (id: string) => (input: Partial<CostBlockBuildingInfo>) => {
    return updateBuildingInfo({
      variables: {
        input: {
          ...input,
          id,
        },
      },
    });
  };

  const onDelete = (id: string) => () => {
    return deleteBuildingInfo({
      variables: {
        input: {
          id,
        },
      },
      update: (
        cache,
        {
          data: {
            costBlockBuildingInfoDelete: {
              building: { id: buildingId },
            },
          },
        }
      ) => {
        cache.modify({
          id: cache.identify(costBlock),
          fields: {
            buildings: (refs: Reference[], { readField }) =>
              refs.filter((ref) => readField("id", ref) !== buildingId),
            buildingsCount: (count: number) => count - 1,
            buildingsInfo: (refs, { readField }) =>
              refs.filter((ref: Reference) => id !== readField("id", ref)),
            costLines: (refs, { readField }) =>
              refs.filter(
                (ref: Reference) => buildingId !== readField("buildingId", ref)
              ),
          },
        });
      },
    });
  };

  const { costBlock: { buildings = [], buildingsInfo = [] } = {} } = data || {};
  const availableBuildings = reportBuildings.filter(
    (building) =>
      !buildings.find(
        (reportBuilding: Building) =>
          building.id.toString() === reportBuilding.id
      )
  );

  return (
    <>
      {!buildingsInfo?.length || costBlock?.buildingsCount === 0 ? (
        <p className="empty-records">No buildings created yet.</p>
      ) : (
        <>
          <NodeHeader>
            <Row>
              <Col lg="4">Bygning</Col>
              <Col lg="1">Skjult?</Col>
              <Col lg="2">TG</Col>
              <Col lg="2">KG</Col>
              <Col lg="3" className="text-right">
                Valg
              </Col>
            </Row>
          </NodeHeader>
          {buildingsInfo.map((buildingInfo: CostBlockBuildingInfo) => {
            const { id } = buildingInfo;
            return (
              <BuildingInfoForm
                onSubmit={onUpdate(id)}
                onDelete={onDelete(id)}
                buildingInfo={buildingInfo}
                key={id}
                className="mb-3"
              />
            );
          })}
        </>
      )}
      <Block>
        <BuildingInfoForm
          reportBuildings={availableBuildings}
          onSubmit={onCreate}
        />
      </Block>
    </>
  );
};

export default EditBuildingInfoList;

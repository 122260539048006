import React from "react";
import { Form, FormProps } from "~/form";
import {
  UpdateConstructionLoanPeriodInput,
  useUpdateConstructionLoanPeriodMutation,
} from "~/graphql-operations";

type Props = Omit<FormProps<UpdateConstructionLoanPeriodInput>, "onSubmit">;

const EditPeriod: React.FC<Props> = ({ children, ...rest }) => {
  const [updateMutation] = useUpdateConstructionLoanPeriodMutation();
  const onSubmit = (input: UpdateConstructionLoanPeriodInput) => {
    return updateMutation({
      variables: {
        input,
      },
    });
  };

  return (
    <Form autoSave onSubmit={onSubmit} {...rest}>
      {children}
    </Form>
  );
};

export default EditPeriod;

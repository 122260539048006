import { useMutation } from "@apollo/client";
import {
  ExtendedCategorization,
  INVOICE_ENTRY_CATEGORIZATIONS_SORT_MUTATION,
} from ".";

export const useSortInvoiceEntryCategorizations = () => {
  const [sortMutation] = useMutation(
    INVOICE_ENTRY_CATEGORIZATIONS_SORT_MUTATION
  );

  return async (
    contractorId: string,
    optimisticResponse: ExtendedCategorization[]
  ) => {
    return sortMutation({
      variables: {
        input: {
          contractorId,
          optimisticResponse: JSON.stringify(optimisticResponse),
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        invoiceEntryCategorizationsSort: {
          __typename: "InvoiceEntryCategorizationsSortPayload",
          contractor: {
            __typename: "InvoiceContractor",
            id: contractorId,
            invoiceEntryCategorizations: optimisticResponse,
          },
        },
      },
    });
  };
};

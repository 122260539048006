import { gql, useMutation } from "@apollo/client";
import React from "react";
import { Badge } from "react-bootstrap";
import { client } from "~/setup";
import { Building, BUILDING_DELETE_MUTATION } from ".";
import DeleteWarningButton from "../layout/Button/DeleteWarningButton";

interface Props {
  building: Pick<Building, "id" | "reportId">;
  inverse?: boolean;
}

export const BUILDING_COST_LINES_COUNT_QUERY = gql`
  query BuildingCostLinesCount($id: ID!) {
    building(id: $id) {
      id
      costBlocksCount
      costLinesCount
    }
  }
`;

const DeleteBuildingButton: React.FC<Props> = ({
  building: { id },
  inverse,
}) => {
  const [deleteBuilding] = useMutation(BUILDING_DELETE_MUTATION);

  const handleWarning = async () => {
    const { data } = await client.query<{
      building: { costBlocksCount: number; costLinesCount: number };
    }>({
      query: BUILDING_COST_LINES_COUNT_QUERY,
      variables: { id },
    });

    const costBlocksCount = data?.building.costBlocksCount || 0;
    const costLinesCount = data?.building.costLinesCount || 0;

    if (costLinesCount > 0) {
      return (
        <>
          <p>
            This building has <Badge variant="danger">{costLinesCount}</Badge>{" "}
            deviations (cost lines) in
            <Badge variant="danger">{costBlocksCount}</Badge> cost block(s).
            Deleting the building will also remove the deviations and this
            action cannot be undone.
          </p>
          <p>
            This action cannot be undone. Are you sure you want to delete this
            building?
          </p>
        </>
      );
    }

    return null;
  };

  const onDelete = async () =>
    deleteBuilding({
      variables: {
        input: {
          id,
        },
      },
    });

  return (
    <DeleteWarningButton
      // confirmText="I am sure"
      inverse={inverse}
      onDelete={onDelete}
      warning={handleWarning}
    />
  );
};

export default DeleteBuildingButton;

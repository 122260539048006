import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  AttachmentField,
  Form,
  FormUpdateState,
  RedactorField,
  SelectField,
} from "~/form";
import { DeleteButton } from "~/layout";
import {
  BlockListItem,
  BlockListItemHeader,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { Session, useSession } from "~/session";
import {
  deleteDocumentationLineMutation,
  DocumentationLine,
  toFormData,
  updateDocumentationLineMutation,
} from ".";
import { DocumentationBlock } from "../block";
import { ratingOptions } from "./helpers";

interface Props {
  documentationBlock?: DocumentationBlock;
  documentationLine: DocumentationLine;
}

const EditDocumentationLine: React.FC<Props> = ({
  documentationBlock,
  documentationLine,
}) => {
  const {
    id,
    title,
    number,
    maxScore,
    rating1Files,
    rating2Files,
    rating3Files,
  } = documentationLine;
  const { role } = useSession() as Session;

  const onSubmit = updateDocumentationLineMutation(id);
  const handleDelete =
    documentationBlock && ["admin", "editor"].includes(role)
      ? deleteDocumentationLineMutation(documentationBlock)
      : null;

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={toFormData(documentationLine)}
      autoSave
      id={`form-documentation-line-${id}`}
    >
      <BlockListItem id={`documentation-line-${id}`}>
        <BlockListItemHeader>
          <Row className="align-items-center">
            <Col>
              <BlockListItemTitle>
                #{number} {title}
              </BlockListItemTitle>
            </Col>
            <Col lg="auto" className="d-flex justify-content-end">
              <FormUpdateState />
              &nbsp;
              {handleDelete && (
                <DeleteButton iconOnly onClick={() => handleDelete(id)} />
              )}
            </Col>
          </Row>
        </BlockListItemHeader>
        <Row>
          <Col lg="4">
            <SelectField
              name="rating1"
              label="Punkt 1"
              options={ratingOptions}
            />
          </Col>
          {maxScore > 1 && (
            <Col lg="4">
              <SelectField
                name="rating2"
                label="Punkt 2"
                options={ratingOptions}
              />
            </Col>
          )}
          {maxScore > 2 && (
            <Col lg="4">
              <SelectField
                name="rating3"
                label="Punkt 3"
                options={ratingOptions}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col lg="8">
            <RedactorField name="description" label="Kommentar" />
          </Col>
          <Col lg="4">
            <AttachmentField
              attachments={rating1Files}
              name="rating1Files"
              label="Filer Punkt 1"
              multiple
            />
            {maxScore > 1 && (
              <AttachmentField
                attachments={rating2Files}
                name="rating2Files"
                label="Filer Punkt 2"
                multiple
              />
            )}
            {maxScore > 2 && (
              <AttachmentField
                attachments={rating3Files}
                name="rating3Files"
                label="Filer Punkt 3"
                multiple
              />
            )}
          </Col>
        </Row>
      </BlockListItem>
    </Form>
  );
};

export default EditDocumentationLine;

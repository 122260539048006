import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node/constants";

export interface CostLineTemplate extends ResourceInterface {
  costBlockTemplateId: string;
  ref: string;
  title: string;
  description: string;
}

export type CreateCostLineTemplateInput = Pick<
  CostLineTemplate,
  "costBlockTemplateId" | "ref" | "title"
>;

export type UpdateCostLineTemplateInput = Pick<
  CostLineTemplate,
  "id" | "ref" | "title" | "description"
>;

export const costLineTemplateFragment = gql`
  fragment CostLineTemplateFields on CostLineTemplate {
    id
    ref
    title
    description
    costBlockTemplateId
  }
`;

export const COST_LINE_TEMPLATES_QUERY = gql`
  query costLineTemplates($costBlockTemplateId: ID!) {
    costLineTemplates(costBlockTemplateId: $costBlockTemplateId) {
      ...CostLineTemplateFields
    }
  }
  ${costLineTemplateFragment}
`;

export const COST_LINE_TEMPLATE_CREATE_MUTATION = gql`
  mutation CreateCostLineTemplate($input: CreateCostLineTemplateInput!) {
    createCostLineTemplate(input: $input) {
      errors
      costLineTemplate {
        ...CostLineTemplateFields
        costBlockTemplate {
          id
          costLineTemplates {
            id
          }
        }
      }
    }
  }
  ${costLineTemplateFragment}
`;

export const COST_LINE_TEMPLATE_UPDATE_MUTATION = gql`
  mutation UpdateCostLineTemplate($input: UpdateCostLineTemplateInput!) {
    updateCostLineTemplate(input: $input) {
      errors
      costLineTemplate {
        ...CostLineTemplateFields
        costBlockTemplate {
          id
        }
      }
    }
  }
  ${costLineTemplateFragment}
`;

export const COST_LINE_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteCostLinetemplate($input: DeleteCostLineTemplateInput!) {
    deleteCostLineTemplate(input: $input) {
      errors
      costLineTemplate {
        ...CostLineTemplateFields
        costBlockTemplate {
          id
          costLineTemplates {
            id
          }
        }
      }
    }
  }
  ${costLineTemplateFragment}
`;

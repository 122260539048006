import { useMutation } from "@apollo/client";
import { NodeUpdateInput } from "~/node/constants";
import { User, UserIndexDocument } from "./../graphql-operations";
import {
  RESET_USER_PASSWORD_MUTATION,
  USER_CREATE_MUTATION,
  USER_EDIT_MUTATION,
} from "./constants";

export const useCreateUser = () => {
  const [createMutation] = useMutation<{
    createUser: { user: User; errors: string[] };
  }>(USER_CREATE_MUTATION);
  return (input: Partial<User>) => {
    return createMutation({
      variables: {
        input,
      },
      update: (cache, mutationResult) => {
        const newUser = mutationResult.data;

        try {
          const data = cache.readQuery<{ users: User[] }>({
            query: UserIndexDocument,
          });
          if (!data) return;

          cache.writeQuery({
            query: UserIndexDocument,
            data: {
              users: [...data.users, newUser],
            },
          });
        } catch (e) {}
      },
    });
  };
};

export const useUpdateUser = () => {
  const [updateUser] = useMutation(USER_EDIT_MUTATION);
  return (input: NodeUpdateInput<User>) => {
    return updateUser({
      variables: {
        input,
      },
    });
  };
};

export const useResetUserPassword = () => {
  const [resetPassword] = useMutation(RESET_USER_PASSWORD_MUTATION);
  return (id: string) =>
    resetPassword({
      variables: { input: { id } },
    });
};

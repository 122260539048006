import React from "react";
import { Card as BootstrapCard, ListGroup } from "react-bootstrap";
import styled from "styled-components";
import introImg from "~/layout/intro-background.png";

type CardProps = {
  name: string;
  number: number | string;
};
const StyledCard = styled(BootstrapCard)`
  /* width: 16rem; */
  margin: auto;
  margin-bottom: 30px;
  text-align: center;
  font-size: 1rem; // ne radi
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%);
`;
const StyledImg = styled(BootstrapCard.Img)`
  height: 190px;
  width: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px 5px 0 0;
`;
const StyledTitle = styled(BootstrapCard.Title)`
  font-size: 1rem; // ne radi !?
  padding: 1.75rem 4rem;
  background-color: var(--dark-gray);
  color: #fff;
  text-align: center;
  line-height: 1.64;
`;

const StyledUL = styled(ListGroup)`
  display: block;
  list-style: none;
  /* margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */
`;
const StyledLi = styled(ListGroup.Item)`
  display: list-item;
  padding: 24px 40px;
  border-bottom: 1px solid var(--smoke);
  line-height: 1.75;
  text-align: left;
  /* text-align: -webkit-match-parent; */
`;
const StyledLabel = styled.label`
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-bottom: 0.25em;
  font-size: 0.75rem;
  font-family: Moon, sans-serif;
  text-transform: uppercase;
`;
const StyledP = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1rem;
  margin: 0;
  font-weight: 300;
`;

const Card: React.FC<CardProps> = ({ name, number }) => {
  return (
    <StyledCard>
      <StyledImg src={introImg} />
      <StyledTitle>{name}</StyledTitle>
      <StyledUL variant="flush">
        <StyledLi>
          <StyledLabel>Rapporttype</StyledLabel>
          <StyledP>CADASTRE INFO</StyledP>
        </StyledLi>
        <StyledLi>
          <StyledLabel>Matrikkel</StyledLabel>
          <StyledP>CADASTRE INFO</StyledP>
        </StyledLi>
        <StyledLi>
          <StyledLabel>Prosjektnr.</StyledLabel>
          <StyledP>{number}</StyledP>
        </StyledLi>
      </StyledUL>
    </StyledCard>
  );
};

export default Card;

import { gql, useQuery } from "@apollo/client";

import React from "react";
import { Report } from "../constants";
import { ShortcodeProps } from "./Shortcode";
import { WordLoader } from "~/layout/Loading";

const PROJECT_MENAGERS_NAME_REPORT_QUERY = gql`
  query ShortcodeMatrikkelKomunne($slug: ID!) {
    report(slug: $slug) {
      id
      projectManagers {
        name
      }
    }
  }
`;

const KonsulenterProsjektansvarlig: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery<{
    report: Pick<Report, "id" | "projectManagers">;
  }>(PROJECT_MENAGERS_NAME_REPORT_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  const projectManagers = data?.report.projectManagers || [];

  return <>{projectManagers.map(({ name }) => name).join(", ")}</>;
};

export default KonsulenterProsjektansvarlig;

import React from "react";
import SelectField, { SyncSelectFieldProps } from "~/form/SelectField";
import { additionalCurrencies, AdditionalCurrency } from "./constants";
import { toOptions } from "./helpers";

type Props = SyncSelectFieldProps<AdditionalCurrency> & {
  currencies?: AdditionalCurrency[];
  default?: boolean;
  noDefault?: boolean;
  loading?: boolean;
};

const CurrencySelectField: React.FC<Props> = ({
  name,
  label,
  currencies = Object.values(additionalCurrencies),
  noDefault = false,
  default: nok = noDefault ? false : true,
  loading = false,
  ...rest
}) => {
  const options = nok
    ? [{ label: "NOK", value: "nok" }, ...toOptions(currencies)]
    : toOptions(currencies);

  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={loading ? [] : options}
      {...rest}
    />
  );
};

export default CurrencySelectField;

import React, { createContext, useContext } from "react";
import { DecoratedSession, Session } from ".";

export type SessionContextType = Session | null;

const SessionContext = createContext<SessionContextType>(null);

export const useSession = () => useContext(SessionContext) as Session;

const decorateSession = ({ role, ...rest }: Session): DecoratedSession => {
  return {
    ...rest,
    role,
    generalEditor: ["admin", "editor"].includes(role),
  };
};

const SessionProvider: React.FC<{ session: Session }> = ({
  session,
  children,
}) => {
  return (
    <SessionContext.Provider value={decorateSession(session)}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

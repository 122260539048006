import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { EditBuildingType } from ".";
import { BuildingType } from "./constants";

interface Props {
  buildingTypes: BuildingType[];
  costIndex: number;
}

const BuildingTypeListEditable: React.FC<Props> = ({
  buildingTypes,
  costIndex,
}) => {
  return (
    <Row className="align-items-start">
      {buildingTypes.map((buildingType) => (
        <Col lg="4" key={buildingType.id}>
          <Card className="mb-4">
            <Card.Body>
              <EditBuildingType
                buildingType={buildingType}
                costIndex={costIndex}
              />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default BuildingTypeListEditable;

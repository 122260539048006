import { useMutation } from "@apollo/client";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Building } from "~/Building";
import { AddFormButton, Form, SelectField, toOptions } from "~/form";
import { CostLine, COST_LINE_CREATE_MUTATION } from ".";
import { CostBlock } from "../block";
import { costLineFragment } from "../fragments";

interface Props {
  buildings: Building[];
  costBlock: CostBlock;
}

const NewCostLineForm: React.FC<Props> = ({
  buildings,
  costBlock,
  costBlock: { costBlockTemplate: { costLineTemplates = [] } = {} },
}) => {
  const [createCostLine] = useMutation(COST_LINE_CREATE_MUTATION);

  const costLineTemplateIdOptions = costLineTemplates.map(
    ({ id, ref, title }) => ({
      value: id,
      label: `#${ref} - ${title}`,
    })
  );

  const onCreate = (input: Partial<CostLine>) => {
    return createCostLine({
      variables: {
        input: {
          ...input,
          costBlockId: costBlock.id.toString(),
        },
      },
      update: (cache, { data: { costLineCreate } }) => {
        const newCostLineRef = cache.writeFragment({
          data: costLineCreate,
          fragment: costLineFragment,
          fragmentName: "CostLineFields",
        });
        cache.modify({
          id: cache.identify(costBlock),
          fields: {
            costLinesCount: (count: number) => count + 1,
            costLines: (existingCostLines) => [
              ...existingCostLines,
              newCostLineRef,
            ],
          },
        });
      },
    });
  };

  return (
    <Form onSubmit={onCreate} resetBlank horizontal>
      <Row>
        <Col lg="4">
          <SelectField
            name="costLineTemplateId"
            label="Element"
            options={costLineTemplateIdOptions}
            required
          />
        </Col>
        <Col lg="4">
          <SelectField
            name="buildingId"
            label="Bygning"
            options={toOptions(buildings)}
            required
          />
        </Col>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default NewCostLineForm;

import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Form,
  NumberField,
  Row as FormRow,
  TextAreaField,
  TextField,
} from "~/form";
import { DeleteButton } from "~/layout";
import {
  BlockList,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { CostLineTemplate, NewCostLineTemplate } from ".";
import { useDeleteCostLineTemplate, useUpdateCostLineTemplate } from "./hooks";

type Props = {
  lines: CostLineTemplate[];
  costBlockTemplateId: string;
};

const EditCostLineTemplateList: React.FC<Props> = ({
  lines,
  costBlockTemplateId,
}) => {
  const update = useUpdateCostLineTemplate();
  const deleteTemplate = useDeleteCostLineTemplate();

  return (
    <>
      <BlockList>
        {lines.map(({ id, ref, title, description }) => (
          <BlockListItemExpandable
            key={id}
            id={`cost-line-template-${id}`}
            render={({ expanded }) => (
              <Row className="align-items-center">
                <Col xs="auto">#{ref}</Col>
                <Col className="pl-0">
                  <BlockListItemTitle>{title}</BlockListItemTitle>
                </Col>
                <Col className="d-flex justify-content-end">
                  <DeleteButton
                    iconOnly
                    onClick={() => deleteTemplate(id)}
                    inverse={expanded}
                  />
                </Col>
              </Row>
            )}
          >
            <Form
              defaultValues={{
                id,
                ref,
                title,
                description,
              }}
              onSubmit={update}
              autoSave
            >
              <FormRow>
                <Col sm="2" lg="1">
                  <NumberField name="ref" label="Ref" />
                </Col>
                <Col>
                  <TextField name="title" label="Tittel" />
                </Col>
              </FormRow>
              <FormRow>
                <Col lg="12">
                  <TextAreaField name="description" label="Beskrivelse" />
                </Col>
              </FormRow>
            </Form>
          </BlockListItemExpandable>
        ))}
      </BlockList>
      <NewCostLineTemplate costBlockTemplateId={costBlockTemplateId} />
    </>
  );
};

export default EditCostLineTemplateList;

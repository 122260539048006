import { upperCase } from "lodash";
import React from "react";
import { Col, Form as BSForm, InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { NumberField, Row } from "~/form";
import NumberInput from "~/form/NumberInput";
import { ConstructionLoan, ContractorInvoice } from "~/graphql-operations";
import { useToggle, useUpdateEffect } from "~/util";

interface Props {
  invoice: ContractorInvoice;
  report: Pick<ConstructionLoan, "vatIncluded">;
}

// const StyledInputGroup = styled(InputGroup)`
//   .form-control,
//   .input-group-text {
//     color: ${({ $variant }) => {
//       switch ($variant) {
//         case "danger":
//           return "var(--white)";
//         default:
//           return "inherit";
//       }
//     }};
//     background-color: ${({ $variant }) => `var(--${$variant})`};
//     border-color: ${({ $variant }) => `var(--${$variant})`};
//   }
// `;

const EditContractorInvoiceDepositFields: React.FC<Props> = ({
  invoice: {
    id,
    currency,
    deposit: { auto: autoDeposit, manual },
    produced,
  },
  report: { vatIncluded },
}) => {
  const { setValue } = useFormContext();
  const [auto, toggleAuto] = useToggle(!manual);

  useUpdateEffect(() => {
    if (auto) setValue("deposit", null, { shouldDirty: true });
  }, [auto, autoDeposit, setValue]);

  const vatVariant = vatIncluded ? "inklusive" : "eksklusive";

  return (
    <Row className="align-items-center">
      <Col lg="auto">
        <NumberField
          name="deposit"
          label={`Innestående ${vatVariant} MVA`}
          disabled={auto}
          placeholder={autoDeposit}
          required={!auto}
        />
      </Col>
      <Col lg="auto">
        <BSForm.Check
          custom
          id={`invoice-${id}-auto-deposit`}
          type="switch"
          label="Auto innestående"
          checked={auto}
          onChange={toggleAuto}
          style={{
            marginTop: "1rem",
          }}
        />
      </Col>
      <Col lg="auto" className="ml-3">
        <BSForm.Group>
          <BSForm.Label>{`Produsert verdi ${vatVariant} MVA`}</BSForm.Label>
          <InputGroup>
            <NumberInput name="of-no-importance" value={produced} disabled />
            {/* <Currency>{produced}</Currency> */}
            <InputGroup.Prepend>
              <InputGroup.Text>{upperCase(currency)}</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </BSForm.Group>
      </Col>
    </Row>
  );
};

export default EditContractorInvoiceDepositFields;

import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import { AddFormButton, CheckboxField, Form, NumberField, Row } from "~/form";
import {
  ContractorInvoice,
  CreateInvoiceEntryInput,
  InvoiceEntryCategorization,
  useCreateInvoiceEntryMutation,
} from "~/graphql-operations";
import { currencyLabel } from "~/util/currency";
// import { Invoice } from "../constants";
// import { InvoiceEntryCategorization } from "./categorization";
import InvoiceEntryCategorySelectField from "./category/CategorySelectField";

interface Props {
  invoice: ContractorInvoice;
  categorizations: InvoiceEntryCategorization[];
  vatIncluded: boolean;
}

// export interface NewInvoice extends Omit<Invoice, "file"> {
//   file: FileList;
// }

const StyledForm = styled(Form)`
  .form-label {
    display: block !important;
  }
`;

const NewEntry: React.FC<Props> = ({
  invoice: { id: invoiceId, currency },
  categorizations,
  vatIncluded,
}) => {
  const [createMutation] = useCreateInvoiceEntryMutation();
  const onSubmit = async (input: CreateInvoiceEntryInput) => {
    return createMutation({
      variables: { input },
    });
  };

  return (
    <StyledForm
      onSubmit={onSubmit}
      defaultValues={{ invoiceId, vatIncluded }}
      horizontal
      resetBlank
    >
      <Row className="align-items-end">
        <Col lg="3">
          <NumberField
            name="amount"
            label="Sum (inc MVA)"
            append={currencyLabel(currency)}
            required
          />
        </Col>
        <Col lg="4">
          <InvoiceEntryCategorySelectField
            name="categoryId"
            label="Category"
            categorizations={categorizations}
            noDefault
            required
          />
        </Col>
        <Col lg="auto">
          <CheckboxField name="vatIncluded" label="MVA inkludert" />
        </Col>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton />
          </FormGroup>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default NewEntry;

import { useMutation } from "@apollo/client";
import {
  CreateRequestCostLineTemplateInput,
  REQUEST_COST_LINE_TEMPLATE_CREATE_MUTATION,
  REQUEST_COST_LINE_TEMPLATE_DELETE_MUTATION,
  REQUEST_COST_LINE_TEMPLATE_UPDATE_MUTATION,
  UpdateRequestCostLineTemplateInput,
} from ".";

export const useCreateRequestCostLineTemplate = () => {
  const [createRequestCostLinetemplate] = useMutation(
    REQUEST_COST_LINE_TEMPLATE_CREATE_MUTATION
  );
  return (input: CreateRequestCostLineTemplateInput) => {
    return createRequestCostLinetemplate({
      variables: {
        input,
      },
    });
  };
};

export const useUpdateRequestCostLineTemplate = () => {
  const [updateRequestCostLineTemplate] = useMutation(
    REQUEST_COST_LINE_TEMPLATE_UPDATE_MUTATION
  );
  return (input: UpdateRequestCostLineTemplateInput) => {
    return updateRequestCostLineTemplate({
      variables: {
        input,
      },
    });
  };
};
export const useDeleteRequestCostLineTemplate = () => {
  const [deleteRequestCostLineTemplate] = useMutation(
    REQUEST_COST_LINE_TEMPLATE_DELETE_MUTATION
  );
  return (id: string) => {
    return deleteRequestCostLineTemplate({
      variables: {
        input: { id },
      },
    });
  };
};

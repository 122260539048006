import React from "react";
import styled from "styled-components";

export interface ButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: Size;
  disabled?: boolean;
  type?: "button" | "submit";
  color?: ButtonColor;
  style?: React.CSSProperties;
}

const getColorVar = (color: ButtonColor) => {
  switch (color) {
    case "black":
    case "dark":
    case "red":
    case "danger":
      return "mystic";
    default:
      return "dark";
  }
};

type Size = "small" | "medium" | "large";
type ButtonColor =
  | "white"
  | "black"
  | "yellow"
  | "juice"
  | "peach"
  | "dark"
  | "light"
  | "smoke"
  | "titanium"
  | "mystic"
  | "eco"
  | "green"
  | "red"
  | "danger";

// const className = ({ size }: { size: Size }) =>
//   ["button", sizeClass(size)].join(" ");
// const sizeClass = (size: Size) => `button-${size}`;

const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  padding: ${({ size }) =>
    size === "small" ? "0.5rem 1rem" : "0.75rem 1.5rem"};
  font-family: var(--font-moon);
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--${({ color }) => getColorVar(color)});
  background-color: var(--${({ color }) => color});
  border: 0;
  border-radius: 5px;
  opacity: ${({ disabled }) => (disabled ? "0.67" : "1")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const Button: React.FC<ButtonProps> = ({
  size = "medium",
  color = "yellow",
  type = "button",
  disabled = false,
  children,
  onClick,
  ...rest
}) => (
  <StyledButton
    type={type}
    color={color}
    size={size}
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    {children}
  </StyledButton>
);

export default Button;

import { gql } from "@apollo/client";
import { attachmentFragment } from "~/attachment";
import { FileVersionable } from "~/file-version";
import { ResourceInterface } from "~/node";
import { Report } from "~/report";
import { User } from "~/user";

export interface Document extends ResourceInterface, FileVersionable {
  reportId: string;
  report: Report;
  visibility: string;
  name: string;
  sender: string;
  date: string;
  preparedBy: string | null;
  receivedDate: string | null;
  fileUploadedBy?: User;
  acl?: {
    view: boolean;
    delete: boolean;
  };
}

export const documentClientFragment = gql`
  fragment DocumentClientFields on Document {
    id
    reportId
    name
    sender
    date
    file {
      ...AttachmentFields
    }
    preparedBy
    createdAt
  }
  ${attachmentFragment}
`;

export const documentAdminFragment = gql`
  fragment DocumentAdminFields on Document {
    ...DocumentClientFields
    visibility
    file {
      ...AttachmentFields
    }
    receivedDate
  }
  ${documentClientFragment}
  ${attachmentFragment}
`;

export const DOCUMENTS_CLIENT_QUERY = gql`
  query ConstructionLoanClientDocuments($slug: ID!) {
    report(slug: $slug) {
      id
      ... on ConstructionLoan {
        documentsComment
        documents {
          ...DocumentClientFields
          acl {
            delete
          }
        }
      }
    }
  }
  ${documentClientFragment}
`;

export const DOCUMENTS_ADMIN_QUERY = gql`
  query ConstructionLoanAdminDocuments($slug: ID!) {
    report(slug: $slug) {
      id
      ... on ConstructionLoan {
        documentsComment
        documents {
          ...DocumentAdminFields
        }
      }
    }
  }
  ${documentAdminFragment}
`;

export const DOCUMENT_CREATE_MUTATION = gql`
  mutation DocumentCreate($input: DocumentCreateInput!) {
    documentCreate(input: $input) {
      document {
        ...DocumentClientFields
        report {
          id
        }
      }
      errors
    }
  }
  ${documentClientFragment}
`;

export const DOCUMENT_ADMIN_CREATE_MUTATION = gql`
  mutation DocumentAdminCreate($input: DocumentCreateInput!) {
    documentCreate(input: $input) {
      document {
        ...DocumentAdminFields
        report {
          id
        }
      }
      errors
    }
  }
  ${documentAdminFragment}
`;

export const DOCUMENT_UPDATE_MUTATION = gql`
  mutation DocumentUpdate($input: DocumentUpdateInput!) {
    documentUpdate(input: $input) {
      document {
        ...DocumentClientFields
      }
      errors
    }
  }
  ${documentClientFragment}
`;

export const DOCUMENT_DELETE_MUTATION = gql`
  mutation DocumentDelete($input: DocumentDeleteInput!) {
    documentDelete(input: $input) {
      document {
        id
        report {
          id
        }
      }
    }
  }
`;

import { useQuery } from "@apollo/client";
import React from "react";
import { SelectField } from "~/form";
import {
  DocumentationLineTemplate,
  DOCUMENTATION_LINE_TEMPLATES_QUERY,
} from ".";

interface Props {
  documentationBlockTemplateId: string;
  name: string;
  label?: string | null;
  required?: boolean;
}

const DocumentationLineTemplateSelectField: React.FC<Props> = ({
  documentationBlockTemplateId,
  name,
  label,
  required = false,
}) => {
  const { loading, error, data } = useQuery<{
    documentationLineTemplates: DocumentationLineTemplate[];
  }>(DOCUMENTATION_LINE_TEMPLATES_QUERY, {
    variables: {
      documentationBlockTemplateId,
    },
  });

  if (error) return <p>Error :(</p>;

  const documentationLineTemplateOptions = (
    data?.documentationLineTemplates || []
  ).map(({ id, title, number }) => ({
    value: id,
    label: `#${number} ${title}`,
  }));

  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={documentationLineTemplateOptions}
      required={required}
    />
  );
};

export default DocumentationLineTemplateSelectField;

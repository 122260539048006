import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Fieldset, Form, FormProps, TextField } from "~/form";
import { Report, ReportSelectField } from "~/report";
import { ReportSelectFieldProps } from "~/report/form/ReportSelectField";

type FormValues = Pick<Report, "name"> & { reportId: Report["id"] };

interface CompanyFilterProps {
  onSubmit: FormProps<FormValues>["onSubmit"];
  onReportSelect: ReportSelectFieldProps["onChange"];
}

const CompanyFilter: React.FC<CompanyFilterProps> = ({
  onSubmit,
  onReportSelect,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => inputRef.current?.focus(), []);

  return (
    <Form autoSave={0} onSubmit={onSubmit}>
      <Fieldset>
        <Row>
          <Col lg="5">
            <TextField
              name="name"
              label={null}
              ref={inputRef}
              type="search"
              wrapperClassName="search-field"
            />
          </Col>
          <Col lg="7">
            <ReportSelectField
              name="reportId"
              label={null}
              onChange={onReportSelect}
            />
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default CompanyFilter;

import React from "react";
import { Col } from "react-bootstrap";
import { Fieldset, Form, Row, SaveFormButton, TextField } from "~/form";
import { Block } from "~/layout";
import { useCreateRequestCostLineTemplate } from ".";

type Props = {
  requestCostBlockTemplateId: string;
};

const NewRequestCostLineTemplate: React.FC<Props> = ({
  requestCostBlockTemplateId,
}) => {
  const create = useCreateRequestCostLineTemplate();

  return (
    <Block>
      <Form
        onSubmit={create}
        defaultValues={{ requestCostBlockTemplateId }}
        horizontal
      >
        <Fieldset title="Legg Til Ny Kostnadslinje">
          <Row>
            <Col lg="1">
              <TextField name="number" label="nummer" />
            </Col>
            <Col>
              <TextField name="title" label="tittel" />
            </Col>
            <Col lg="auto" className="d-flex justify-content-end">
              <SaveFormButton />
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default NewRequestCostLineTemplate;

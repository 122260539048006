import React from "react";
import { Modal } from "react-bootstrap";
import ContentEditable from "react-contenteditable";
import styled from "styled-components";
import { DeleteButton, SaveButton } from "~/layout";
import { useModal } from "~/util";
import { useDeleteInvoiceEntryCategoryGroup } from "./actions";

interface Props {
  group: { id: string; name: string };
  hasChildren: boolean;
}

const StyledContentEditable = styled(ContentEditable)`
  margin: 0;
  padding: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: none;
  background: #fff;
  &:hover {
    background: var(--smoke);
  }
  &:focus {
    background: var(--yellow);
    outline: none;
  }
`;

const DeleteGroupButton: React.FC<Props> = ({
  group: { id, name },
  hasChildren,
}) => {
  const { active, show, close } = useModal();

  const deleteMutation = useDeleteInvoiceEntryCategoryGroup();

  const handleDelete = async (cascade: boolean) => {
    await deleteMutation(id, cascade);
    close();
  };

  return (
    <>
      <DeleteButton
        noConfirm
        iconOnly
        onClick={() => Promise.resolve(show())}
      />
      <Modal show={active} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {name}?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>{message}</Modal.Body> */}
        <Modal.Footer>
          {hasChildren ? (
            <>
              <DeleteButton noConfirm onClick={() => handleDelete(true)}>
                All?
              </DeleteButton>
              <DeleteButton noConfirm onClick={() => handleDelete(false)}>
                Leave categories
              </DeleteButton>
            </>
          ) : (
            <DeleteButton noConfirm onClick={() => handleDelete(false)}>
              Delete
            </DeleteButton>
          )}
          <SaveButton
            variant="primary"
            icon="deny"
            onClick={() => Promise.resolve(close())}
          >
            Cancel
          </SaveButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteGroupButton;

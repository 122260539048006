import React from "react";
import { Col, Row } from "react-bootstrap";
import { DeleteButton, HTMLContent } from "~/layout";
import {
  BlockListItemData,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { Session, useSession } from "~/session";
import { DocumentationSection } from "../constants";
import { EditDocumentationLineList } from "../line";
import { deleteDocumentationBlockMutation } from "./actions";
import { DocumentationBlock } from "./constants";

interface Props {
  documentationSection?: DocumentationSection;
  documentationBlock: DocumentationBlock;
}

const DocumentationBlockListItem: React.FC<Props> = ({
  documentationSection,
  documentationBlock,
  documentationBlock: { id, title, description, maxScore, score },
  ...rest
}) => {
  const { role } = useSession() as Session;
  const canDelete = ["admin", "editor"].includes(role);
  const deleteMutation = deleteDocumentationBlockMutation(documentationSection);

  return (
    <BlockListItemExpandable
      render={({ expanded }) => (
        <Row style={{ alignItems: "center" }}>
          <Col lg="6">
            <BlockListItemTitle>{title}</BlockListItemTitle>
          </Col>
          <Col lg="2">
            <BlockListItemData>{maxScore}</BlockListItemData>
          </Col>
          <Col lg="2">
            <BlockListItemData>{score}</BlockListItemData>
          </Col>
          <Col lg="2" className="_tar">
            {canDelete && (
              <DeleteButton
                onClick={() => deleteMutation(id)}
                iconOnly
                inverse={expanded}
                style={{ padding: 0 }}
              />
            )}
          </Col>
        </Row>
      )}
      {...rest}
    >
      <HTMLContent>{description}</HTMLContent>
      <EditDocumentationLineList documentationBlock={documentationBlock} />
    </BlockListItemExpandable>
  );
};

export default DocumentationBlockListItem;

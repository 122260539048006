import React, { HTMLAttributes } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  narrow?: boolean;
}

const Wrap = styled.p`
  margin: 1rem 0;
  color: var(--titanium);

  &:first-child {
    margin-top: 0;
  }
`;

const NodeListEmpty: React.FC<Props> = ({
  children = "Listen er tom",
  ...rest
}) => {
  return <Wrap {...rest}>{children}</Wrap>;
};

export default NodeListEmpty;

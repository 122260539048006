import gql from "graphql-tag";
import { attachmentFragment } from "~/attachment";

export enum InvoiceType {
  CLIENT = "ClientInvoice",
  CONTRACTOR = "ContractorInvoice",
  EXTERNAL = "ExternalInvoice",
}

export const invoiceFragment = gql`
  fragment InvoiceFields on Invoice {
    id
    acl {
      view
      delete
    }
    amount
    currency
    dueDate
    number
    receivedDate
    sender
    status
    total
    type
    ... on FileVersionable {
      file {
        ...AttachmentFields
      }
    }
    ... on ContractorInvoice {
      accordingToPlan
      autoDeposit
      contractorId
      deposit
      hasEntries
    }
    ... on ExternalInvoice {
      categoryId
      category {
        ...InvoiceCategoryFields
      }
    }
  }
  ${attachmentFragment}
`;

export const invoiceUpdateFragment = gql`
  fragment InvoiceUpdateFields on Invoice {
    ...InvoiceFields
    period {
      id
      publishable
      status
    }
  }
  ${invoiceFragment}
`;

export const INVOICE_AFFILIATE_UPDATE_MUTATION = gql`
  mutation UpdateAffiliateInvoice($input: UpdateAffiliateInvoiceInput!) {
    updateAffiliateInvoice(input: $input) {
      invoice {
        ...InvoiceUpdateFields
      }
      errors
    }
  }
  ${invoiceUpdateFragment}
`;

import React from "react";
import styled from "styled-components";
import "./loading-dots.sass";

const StyledDotPulse = styled.div.attrs({
  className: "dot-pulse",
})`
  text-align: left;
  /* &:before,
  &:after {
    color: var(--white);
    background-color: var(--white);
  }

  color: var(--white);
  background-color: var(--white);
  box-shadow: 9999px 0 0 -5px var(--white); */
`;

const LoadingDots: React.FC = ({ ...rest }) => {
  return <StyledDotPulse {...rest}></StyledDotPulse>;
};

export default LoadingDots;

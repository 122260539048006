import { Option } from "~/form/SelectField";
import { InvoiceCategory } from "~/graphql-operations";

const toOption = <T extends { id: string; name: string }>(
  option: T
): Option<T> => ({
  label: option.name,
  value: option.id,
  data: option,
});

export const toOptions = (categories: InvoiceCategory[]) =>
  categories.map(toOption);

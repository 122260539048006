import React from "react";
import { NumberField } from "~/form";
import { ConstructionLoan } from "~/graphql-operations";
import { CurrencySelectField } from "~/util/currency";

type Props = Pick<ConstructionLoan, "currencies" | "vatIncluded">;

const InvoiceAmountFields: React.FC<Props> = ({ currencies, vatIncluded }) => {
  const vatVariant = vatIncluded ? "inklusive" : "eksklusive";
  return (
    <div className="d-flex">
      <NumberField
        name="amount"
        label={`Sum ${vatVariant} MVA`}
        required
        decimals
      />
      <div style={{ width: "90px", marginLeft: "-2px" }}>
        <CurrencySelectField
          name="currency"
          label="Kurs"
          currencies={currencies}
          default
          required
        />
      </div>
    </div>
  );
};

export default InvoiceAmountFields;

import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";

export interface RequestLineTemplate extends ResourceInterface {
  number: string;
  title: string;
  id: string;
}
export type CreateRequestLineTemplateInput = Pick<
  RequestLineTemplate,
  "number" | "title"
>;

export type UpdateRequestlineTemplateInput = Pick<
  RequestLineTemplate,
  "id" | "number" | "title"
>;
export const requestLineTemplateFragment = gql`
  fragment RequestLineTemplateFields on RequestLineTemplate {
    id
    title
    number
  }
`;

export const REQUEST_LINE_TEMPLATES_QUERY = gql`
  query requestLineTemplates {
    requestLineTemplates {
      ...RequestLineTemplateFields
    }
  }
  ${requestLineTemplateFragment}
`;

export const REQUEST_LINE_TEMPLATE_CREATE_MUTATION = gql`
  mutation CreateRequestLineTemplate($input: CreateRequestLineTemplateInput!) {
    createRequestLineTemplate(input: $input) {
      errors
      requestLineTemplate {
        ...RequestLineTemplateFields
      }
    }
  }
  ${requestLineTemplateFragment}
`;

export const REQUEST_LINE_TEMPLATE_UPDATE_MUTATION = gql`
  mutation UpdateRequestLineTemplate($input: UpdateRequestLineTemplateInput!) {
    updateRequestLineTemplate(input: $input) {
      errors
      requestLineTemplate {
        ...RequestLineTemplateFields
      }
    }
  }
  ${requestLineTemplateFragment}
`;

export const REQUEST_LINE_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteRequestLineTemplate($input: DeleteRequestLineTemplateInput!) {
    deleteRequestLineTemplate(input: $input) {
      errors
      requestLineTemplate {
        ...RequestLineTemplateFields
      }
    }
  }
  ${requestLineTemplateFragment}
`;

import React from "react";
import { Form } from "~/form";
import { attachmentInput } from "~/form/attachment";
import {
  ConstructionLoanPeriod,
  UpdateConstructionLoanPeriodInput,
  useUpdateConstructionLoanPeriodMutation,
} from "~/graphql-operations";
import { PayoutFields, PeriodTitle } from ".";

interface Props {
  period: ConstructionLoanPeriod;
}

const EditPeriod: React.FC<Props> = ({
  period,
  period: {
    id,
    payout,
    payout: { files, information },
  },
}) => {
  const defaultValues = {
    id,
    payout: {
      files: files ? attachmentInput(files) : undefined,
      information,
    },
  };
  const [updateMutation] = useUpdateConstructionLoanPeriodMutation();
  const onSubmit = (input: UpdateConstructionLoanPeriodInput) => {
    return updateMutation({
      variables: {
        input,
      },
    });
  };

  return (
    <Form autoSave horizontal defaultValues={defaultValues} onSubmit={onSubmit}>
      <h1>
        <PeriodTitle period={period} />
      </h1>
      <PayoutFields payout={payout} />
    </Form>
  );
};

export default EditPeriod;

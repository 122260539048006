import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import {
  AddFormButton,
  DateField,
  FileField,
  Form,
  Row,
  TextField,
} from "~/form";
import { ConstructionLoanPeriod, CurrencyEnum } from "~/graphql-operations";
import { mutationSuccess } from "~/util";
import { InvoiceAmountFields, InvoiceType, useCreateInvoice } from ".";
import { CategorySelectField } from "./category";

interface Props {
  period: Pick<ConstructionLoanPeriod, "id"> & {
    report: Pick<
      ConstructionLoanPeriod["report"],
      "currencies" | "invoiceCategories" | "vatIncluded"
    >;
  };
  onCreate: () => void;
}

const NewExternalInvoice: React.FC<Props> = ({
  period: {
    id: periodId,
    report: { currencies, invoiceCategories: categories, vatIncluded },
  },
  onCreate,
  children,
}) => {
  const [create, uploadProgress] = useCreateInvoice(InvoiceType.EXTERNAL);

  const onSubmit: typeof create = async (input) => {
    const res = await create(input);
    if (mutationSuccess(res)) onCreate();
    return res;
  };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={{
        periodId,
        receivedDate: new Date().toString(),
        currency: CurrencyEnum.Nok,
      }}
      horizontal
      resetBlank
    >
      <Row>
        <Col>
          <TextField name="sender" label="Avsender" required />
        </Col>
        <Col lg="2">
          <TextField name="number" label="Faktura" required />
        </Col>
        <Col lg="2">
          <DateField name="dueDate" label="Faktura dato" />
        </Col>
        <Col lg="auto">
          <InvoiceAmountFields
            currencies={currencies}
            vatIncluded={vatIncluded}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CategorySelectField
            name="categoryId"
            label="Kategori"
            categories={categories}
            required
          />
        </Col>
        <Col lg="auto">
          <DateField
            name="receivedDate"
            label="Mottat BER"
            required
            notClearable
          />
        </Col>
        <Col>
          <FileField name="file" label="Fakturafil" progress={uploadProgress} />
        </Col>
      </Row>
      <FormGroup style={{ margin: "1rem 0 0.5rem 0" }}>
        <Row>
          <Col sm="auto">
            <AddFormButton />
          </Col>
          {children && <Col sm="auto">{children}</Col>}
        </Row>
      </FormGroup>
    </Form>
  );
};

export default NewExternalInvoice;

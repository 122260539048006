import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node/constants";

export interface RequestCostLineTemplate extends ResourceInterface {
  requestCostBlockId: string;
  ref: string;
  title: string;
  descriotion: string;
  number: string | number;
  requestType: string;
  costTypePrimary: string;
  costTypeSecondary: string;
  requestCostBlockTemplateId: string;
}
export type CreateRequestCostLineTemplateInput = Pick<
  RequestCostLineTemplate,
  "number" | "requestCostBlockTemplateId" | "title"
>;

export type UpdateRequestCostLineTemplateInput = Pick<
  RequestCostLineTemplate,
  | "costTypePrimary"
  | "costTypeSecondary"
  | "id"
  | "number"
  | "title"
  | "requestType"
>;

export const requestCostLineTemplateFragment = gql`
  fragment RequestCostLineTemplateFields on RequestCostLineTemplate {
    id
    number
    title
    requestType
    costTypePrimary
    costTypeSecondary
  }
`;

export const REQUEST_COST_LINE_TEMPLATE_CREATE_MUTATION = gql`
  mutation CreateRequestCostLineTemplate(
    $input: CreateRequestCostLineTemplateInput!
  ) {
    createRequestCostLineTemplate(input: $input) {
      errors
      requestCostLineTemplate {
        ...RequestCostLineTemplateFields
        requestCostBlockTemplate {
          id
          requestCostLineTemplates {
            id
          }
        }
      }
    }
  }
  ${requestCostLineTemplateFragment}
`;

export const REQUEST_COST_LINE_TEMPLATE_UPDATE_MUTATION = gql`
  mutation UpdateRequestCostLineTemplate(
    $input: UpdateRequestCostLineTemplateInput!
  ) {
    updateRequestCostLineTemplate(input: $input) {
      errors
      requestCostLineTemplate {
        ...RequestCostLineTemplateFields
      }
    }
  }
  ${requestCostLineTemplateFragment}
`;

export const REQUEST_COST_LINE_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteRequestCostLineTemplate(
    $input: DeleteRequestCostLineTemplateInput!
  ) {
    deleteRequestCostLineTemplate(input: $input) {
      errors
      requestCostLineTemplate {
        ...RequestCostLineTemplateFields
        requestCostBlockTemplate {
          id
          requestCostLineTemplates {
            id
          }
        }
      }
    }
  }
  ${requestCostLineTemplateFragment}
`;

import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;

  button:first-child {
    border-radius: 3px 0 0 3px;
  }
  button:last-child {
    border-radius: 0 3px 3px 0;
  }
`;

const ButtonGroup: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => <StyledDiv {...rest}>{children}</StyledDiv>;

export default ButtonGroup;

import { useQuery } from "@apollo/client";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { AddFormButton, Fieldset, Form, SelectField } from "~/form";
import {
  CreateRequestLineInput,
  useCreateRequestLineMutation,
} from "~/graphql-operations";
import { RequestSection } from "../constants";
import {
  REQUEST_LINE_TEMPLATES_QUERY,
  RequestLineTemplate,
} from "../line-template";

interface Props {
  requestSection: RequestSection;
}

const NewRequestLine: React.FC<Props> = ({
  requestSection: { id: requestSectionId },
}) => {
  const { loading, error, data } = useQuery<{
    requestLineTemplates: RequestLineTemplate[];
  }>(REQUEST_LINE_TEMPLATES_QUERY);

  const [createRequestLine] = useCreateRequestLineMutation();
  const onSubmit = (input: CreateRequestLineInput) =>
    createRequestLine({ variables: { input } });

  const requestLineTemplateOptions = (data?.requestLineTemplates || []).map(
    ({ id, number, title }) => ({
      value: id,
      label: `#${number} — ${title}`,
    })
  );

  if (error) return <p>Error :(</p>;

  return (
    <Form defaultValues={{ requestSectionId }} onSubmit={onSubmit} horizontal>
      <Fieldset title="Ny innhold">
        <Row>
          <Col>
            <SelectField
              name="requestLineTemplateId"
              label="TDD Vedlikeholdsdeler"
              isLoading={loading}
              options={requestLineTemplateOptions}
              required
            />
          </Col>
          <Col lg="auto">
            <FormGroup>
              <AddFormButton style={{ marginTop: "1.5rem" }} />
            </FormGroup>
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewRequestLine;

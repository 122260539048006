import { Member, User } from "~/graphql-operations";
import { Report } from "~/report";
import { camelize, constantize } from "~/util/String";

type reportMembersOptimisticResponseT = (arg: {
  report: Report;
  relation: Member["relation"];
  users: User[];
  newUserIds: string[];
}) => unknown;

export const reportMembersOptimisticResponse: reportMembersOptimisticResponseT = ({
  report,
  report: { id: reportId },
  relation,
  users,
  newUserIds,
}) => {
  const constantizedRelation = constantize(relation) + "s";
  const camelizedRelation = camelize(relation) + "s";
  const members = (report[camelizedRelation] || []) as Member[];

  return {
    __typename: "Mutation",
    ["reportSet" + constantizedRelation]: {
      __typename: "Report",
      id: reportId,
      [camelizedRelation]: newUserIds
        .map((userId) => {
          const userData = users.find((u) => u.id === userId);
          const memberData = members.find((m) => m.userId === userId);

          return (
            memberData || {
              ...userData,
              id: undefined,
              __typename: "Member",
              membershipId: Math.round(Math.random() * -1000 + 1).toString(),
              userId,
              reportId,
              permissions: [],
              preferences: {},
              relation,
              role: "",
              createdAt: new Date().toISOString().replace(/.\d+Z$/g, "Z"),
            }
          );
        })
        .sort((a, b) => {
          if (a.createdAt < b.createdAt) return -1;
          if (a.createdAt > b.createdAt) return 1;
          return 0;
        }),
    },
  };
};

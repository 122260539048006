import { useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import BlockList, { BlockListHeader } from "~/layout/BlockList";
import { DocumentationBlock } from ".";
import { DOCUMENTATION_SECTION_DOCUMENTATION_BLOCKS_QUERY } from "..";
import { DocumentationSection } from "../constants";
import DocumentationBlockListItem from "./DocumentationBlockListItem";
// import EditDocumentationBlock from "./EditDocumentationBlock";

interface Props {
  documentationSection: DocumentationSection;
}

const EditDocumentationBlockList: React.FC<Props> = ({
  documentationSection: { id },
}) => {
  // const { role } = useSession() as Session;
  const { loading, error, data } = useQuery(
    DOCUMENTATION_SECTION_DOCUMENTATION_BLOCKS_QUERY,
    {
      variables: {
        id: id.toString(),
      },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    documentationSection,
    documentationSection: { documentationBlocks },
  } = data;

  return (
    <>
      <BlockListHeader>
        <Row>
          <Col lg="6">Vedlikeholdsdel</Col>
          <Col lg="2">Max score</Col>
          <Col lg="2">Score</Col>
          <Col lg="2" className="_tar">
            Valg
          </Col>
        </Row>
      </BlockListHeader>
      <BlockList>
        {documentationBlocks.map((documentationBlock: DocumentationBlock) => (
          <DocumentationBlockListItem
            key={documentationBlock.id}
            documentationBlock={documentationBlock}
            documentationSection={documentationSection}
          />
          // <EditDocumentationBlock
          //   documentationSection={documentationSection}
          //   documentationBlock={documentationBlock}
          //   key={documentationBlock.id}
          //   id={`request-cost-block-${documentationBlock.id}`}
          // />
        ))}
      </BlockList>
    </>
  );
};

export default EditDocumentationBlockList;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BuildingMaintenance: [
      "AssessmentPublication",
      "BreeamPublication",
      "TDDPublication",
    ],
    Buildings: ["Assessment", "Breeam", "TDD"],
    Consultable: ["Assessment", "Breeam", "TDD"],
    Costs: ["Assessment", "Breeam", "TDD"],
    Documentation: ["Assessment", "Breeam"],
    FileVersionable: [
      "ClientInvoice",
      "ContractorInvoice",
      "Document",
      "ExternalInvoice",
    ],
    Invoice: ["ClientInvoice", "ContractorInvoice", "ExternalInvoice"],
    InvoiceEntryCategorization: [
      "InvoiceEntryCategory",
      "InvoiceEntryCategoryGroup",
    ],
    MultiReport: ["MultiAssessment", "MultiTDD"],
    MultiReportConnection: ["MultiAssessmentConnection", "MultiTDDConnection"],
    Node: [
      "ClientInvoice",
      "ContractorInvoice",
      "ContractorInvoiceDeposit",
      "CostSection",
      "CVSection",
      "Document",
      "ExternalInvoice",
      "FileVersion",
      "MultiAssessmentConnection",
      "MultiReportDeviation",
      "MultiTDDConnection",
      "Paragraph",
      "TextSection",
    ],
    NodeInterface: [
      "Attachment",
      "Building",
      "BuildingType",
      "ClientInvoice",
      "Company",
      "CompanyStats",
      "ConstructionLoanPeriod",
      "ContractorInvoice",
      "ContractorInvoiceDeposit",
      "CostBlock",
      "CostBlockBuildingInfo",
      "CostBlockTemplate",
      "CostLine",
      "CostLineTemplate",
      "CostSection",
      "CVSection",
      "Document",
      "DocumentationBlock",
      "DocumentationBlockTemplate",
      "DocumentationLine",
      "DocumentationLineTemplate",
      "DocumentationSection",
      "Event",
      "ExternalInvoice",
      "FileVersion",
      "HousingMaintenanceBudgetLine",
      "HousingMaintenanceBudgetPlan",
      "HousingMaintenanceBuildingPart",
      "HousingMaintenanceBuildingPartRatingRule",
      "InvoiceCategory",
      "InvoiceContractor",
      "InvoiceEntry",
      "InvoiceEntryCategory",
      "InvoiceEntryCategoryGroup",
      "MultiAssessmentConnection",
      "MultiReportDeviation",
      "MultiTDDConnection",
      "Paragraph",
      "RequestCostBlock",
      "RequestCostBlockTemplate",
      "RequestCostLine",
      "RequestCostLineTemplate",
      "RequestLine",
      "RequestLineTemplate",
      "RequestSection",
      "Session",
      "TextSection",
      "User",
    ],
    NodeUrlsInterface: [
      "CostSection",
      "DocumentationSection",
      "RequestSection",
    ],
    PublicationSection: [
      "CVSection",
      "DocumentationSection",
      "MaintenanceSectionPublication",
      "RequestSection",
      "TextSection",
    ],
    Report: [
      "Assessment",
      "Breeam",
      "ConstructionLoan",
      "HousingAssessment",
      "MultiAssessment",
      "MultiTDD",
      "TDD",
    ],
    ReportPublication: [
      "AssessmentPublication",
      "BreeamPublication",
      "TDDPublication",
    ],
    Section: [
      "CostSection",
      "CVSection",
      "DocumentationSection",
      "HousingMaintenanceSection",
      "MaintenanceSectionPublication",
      "TextSection",
    ],
    Sectionable: [
      "Assessment",
      "Breeam",
      "ConstructionLoan",
      "HousingAssessment",
      "MultiAssessment",
      "MultiTDD",
      "TDD",
    ],
    TextSectionsReport: [
      "Assessment",
      "Breeam",
      "ConstructionLoan",
      "HousingAssessment",
      "MultiAssessment",
      "MultiTDD",
      "TDD",
    ],
  },
};
export default result;

import { get } from "lodash";
import React, { ReactNode } from "react";
import { Form, FormGroup } from "react-bootstrap";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import Select, {
  ActionMeta,
  Props as SelectProps,
  StylesConfig,
} from "react-select";
import AsyncSelect, { Props as AsyncProps } from "react-select/async";
import FieldError from "./FieldError";

type OptionBase<T = undefined> = {
  label: string;
  value: T extends undefined ? string | number | null : T;
};

export type Option<V = OptionBase["value"], D = undefined> = D extends undefined
  ? OptionBase<V>
  : OptionBase<V> & { data: D };

// const bla: Option = {
//   value: "bli",
//   label: "Bli",
// };
// const bli: Option<number, number> = {
//   value: "bla",
//   label: "Bla",
//   data: 1,
// };
// console.log("🚀 ~ file: SelectField.tsx ~ line 29 ~ bla", bla, bli);

// name is in SelectProps
interface FieldProps {
  // name: string;
  label?: string | ReactNode;
  validation?: UseControllerProps["rules"];
  required?: boolean;
}

export interface SyncSelectFieldProps<T>
  extends FieldProps,
    SelectProps<Option<T>> {
  name: string;
}

export interface AsyncSelectFieldProps<T>
  extends FieldProps,
    AsyncProps<Option<T>> {
  async: true;
  name: string;
}

// UNION type, cannot be extended by an interface: https://stackoverflow.com/questions/56085306/error-message-an-interface-can-only-extend-an-object-type-or-intersection-of-o
export type SelectFieldProps<T> =
  | AsyncSelectFieldProps<T>
  | SyncSelectFieldProps<T>;

type customStyles = (args?: { error?: boolean }) => StylesConfig;
export const customStyles: customStyles = ({ error = false } = {}) => {
  return {
    control: (styles, { isDisabled, isFocused }) => {
      const stylez = {
        ...styles,
        minHeight: "43px",
        backgroundColor: isDisabled ? "var(--smoke)" : "var(--white)",
        // border: `1px solid var(--mystic)`,
        borderColor: `var(--${error ? "danger" : "mystic"})`,
        // borderRadius: "3px",
        "&:hover": undefined,
        // "&:hover": {
        //   borderColor: `var(--${error ? "danger" : "mystic"})`,
        // },
      };
      if (isFocused) {
        stylez["borderColor"] = error ? "var(--danger)" : "#d99e38";
        stylez["boxShadow"] = error
          ? "0 0 0 0.2rem rgb(207 60 60 / 25%)"
          : "0 0 0 0.2rem rgb(255 204 79 / 25%)";
      }
      return stylez;
    },
    dropdownIndicator: (_styles, { isDisabled }) => ({
      // ...styles,
      padding: "0.5rem .25rem",
      opacity: isDisabled ? 0.3 : 1,
    }),
    indicatorsContainer: (styles, _state) => ({
      ...styles,
      alignItems: "center",
    }),
    indicatorSeparator: (_styles, _state) => ({
      // ...styles,
      display: "none",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
    }),
    group: (styles, _state) => ({
      ...styles,
      "& [class*=-option]": {
        paddingLeft: "24px",
      },
    }),
  };
};

// const SelectField: React.FC<SelectFieldProps> = ({
const SelectField = <T extends undefined>({
  name,
  label = name,
  async = false,
  options,
  loadOptions,
  required,
  validation,
  isMulti = false,
  disabled = false,
  onChange: onChangeProp,
  placeholder = "Velg...",
  ...rest
}: SelectFieldProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);

  // undefined does not clear selected value in UI, null DOES!
  const getOption = (value: string | number) => {
    // ASYNC does not have options, so this is a hack?
    if (!options) return value ? undefined : null;
    if (!Array.isArray(options)) throw "Options must be an array!";

    // groupedOptions: [{ label, options: [{ label, value }...]}]
    return Array.isArray(value)
      ? options.filter((o) => value?.includes(o.value))
      : options
          .map((option) => ("options" in option ? option.options : option)) // is it grouped?
          .flat()
          .find((o) => o.value === value) || null;
  };

  return (
    <FormGroup>
      {label && <Form.Label>{label}</Form.Label>}
      {/* <Component {...register(name)} {...passProps} /> */}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const props = {
            value: getOption(value),
            onChange: (
              selected: Option<T> | Option<T>[],
              action: ActionMeta<Option<T>>
            ) => {
              const value = Array.isArray(selected)
                ? selected.map((o: Option) => o.value)
                : isMulti
                ? []
                : selected?.value;

              onChange(value ?? null);
              if (onChangeProp) onChangeProp(selected, action);
            },
            placeholder,
            isDisabled: disabled,
            isMulti,
            className: errors[name] && "is-invalid",
            styles: customStyles({ error }),
            ...rest,
          };

          return async ? (
            <AsyncSelect loadOptions={loadOptions} {...props} />
          ) : (
            <Select options={options} {...props} />
          );
        }}
        rules={validation || { required }}
        defaultValue={null}
      />
      {error && <FieldError error={error} />}
    </FormGroup>
  );
};

export default SelectField;

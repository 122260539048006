// https://github.com/redwoodjs/redwood/blob/main/packages/forms/src/index.tsx
import React, { FC, ReactNode } from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";
import { FieldError } from ".";

export interface TextInputProps extends FormControlProps {
  name: string;
  label?: string | ReactNode;
  error?: string;
  prepend?: ReactNode;
  append?: ReactNode;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
}

const TextInput: FC<TextInputProps> = ({
  append,
  children,
  error,
  name,
  label = name,
  prepend,
  wrapperClassName,
  wrapperStyle,
  ...rest
}) => {
  const formControl = (
    <Form.Control
      isInvalid={!!error}
      {...rest}
      // ref={inputFieldRef(hookFormRef, ref)}
    />
  );

  return (
    <Form.Group className={wrapperClassName} style={wrapperStyle}>
      {label && <Form.Label>{label}</Form.Label>}
      {prepend || append ? (
        <>
          <InputGroup>
            {prepend && (
              <InputGroup.Prepend>
                <InputGroup.Text>{prepend}</InputGroup.Text>
              </InputGroup.Prepend>
            )}
            {formControl}
            {append && (
              <InputGroup.Append>
                <InputGroup.Text>{append}</InputGroup.Text>
              </InputGroup.Append>
            )}
          </InputGroup>
        </>
      ) : (
        formControl
      )}
      {error && <FieldError error={{ type: "required", message: error }} />}
      {children}
    </Form.Group>
  );
};

export default TextInput;

import React from "react";
import { Col } from "react-bootstrap";
import {
  Fieldset,
  Form,
  Row,
  SaveFormButton,
  SelectField,
  TextField,
} from "~/form";
import { Block } from "~/layout";
import { useCreateDocumentationLineTemplate } from ".";

type Props = {
  documentationBlockTemplateId: string;
};

const NewDocumentationLineTemplate: React.FC<Props> = ({
  documentationBlockTemplateId,
}) => {
  const create = useCreateDocumentationLineTemplate();

  const columnOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
  ];

  return (
    <>
      <Block>
        <Form
          onSubmit={create}
          defaultValues={{
            documentationBlockTemplateId,
          }}
          resetBlank
          horizontal
        >
          <Fieldset title="Legg Til Ny Dokumentasjonslinje">
            <Row>
              <Col>
                <TextField name="title" label="Tittel" required />
              </Col>
              <Col lg="2">
                <TextField name="number" label="Pkt" required />
              </Col>
              <Col lg="3">
                <SelectField
                  name="columns"
                  label="kolonner"
                  placeholder="--Please Select--"
                  options={columnOptions}
                />
              </Col>
              <Col lg="2" className="d-flex justify-content-end">
                <SaveFormButton />
              </Col>
            </Row>
          </Fieldset>
        </Form>
      </Block>
    </>
  );
};

export default NewDocumentationLineTemplate;

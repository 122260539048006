import React from "react";
import {
  DateField,
  Form,
  FormProps,
  RedactorField,
  SaveFormButton,
} from "~/form";
import { Delivery } from "~/graphql-operations";

const DeliveryForm: React.FC<FormProps<Delivery>> = ({
  defaultValues,
  onSubmit,
  children,
  ...rest
}) => {
  const { id } = defaultValues || {};
  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      resetBlank={!id}
      autoSave={!!id}
      {...rest}
    >
      <div style={{ maxWidth: "180px" }}>
        <DateField name="date" label="Dato" required />
      </div>
      <RedactorField name="info" label="Beskrivelse" required />
      {children}
      {!id && <SaveFormButton />}
    </Form>
  );
};

export default DeliveryForm;

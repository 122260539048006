import React, { useState } from "react";
import { ConstructionLoanPeriod } from "~/graphql-operations";
import { IconButton } from "~/layout";
import { InvoiceType, NewContractorInvoice, NewExternalInvoice } from ".";

interface Props {
  period: Pick<ConstructionLoanPeriod, "id"> & {
    report: Pick<
      ConstructionLoanPeriod["report"],
      "currencies" | "contractors" | "invoiceCategories" | "vatIncluded"
    >;
  };
}

const NewInvoice: React.FC<Props> = ({ period }) => {
  const [type, setType] = useState<
    InvoiceType.CONTRACTOR | InvoiceType.EXTERNAL | null
  >(null);

  const resetType = () => setType(null);

  const CancelButton = () => (
    <IconButton
      icon="chevronLeft"
      variant="danger"
      className="mr-3"
      onClick={resetType}
    >
      Avbryt
    </IconButton>
  );

  return type ? (
    <>
      {type === InvoiceType.CONTRACTOR && (
        <NewContractorInvoice period={period} onCreate={resetType}>
          <CancelButton />
        </NewContractorInvoice>
      )}
      {type === InvoiceType.EXTERNAL && (
        <NewExternalInvoice period={period} onCreate={resetType}>
          <CancelButton />
        </NewExternalInvoice>
      )}
    </>
  ) : (
    <>
      <IconButton
        icon="plus"
        variant="primary"
        className="mr-3"
        onClick={() => setType(InvoiceType.CONTRACTOR)}
      >
        Enterprise
      </IconButton>
      <IconButton
        icon="plus"
        variant="info"
        onClick={() => setType(InvoiceType.EXTERNAL)}
      >
        BH-Kostnad
      </IconButton>
    </>
  );
};

export default NewInvoice;

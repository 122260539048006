import React from "react";
import { ShortcodeProps } from ".";
import styled from "styled-components";

const LegendTable = styled.table`
  max-width: 500px;
  text-align: center;
  border-spacing: 0;
`;

const LegendTr = styled.tr`
  padding: 0.25rem;
`;

const LegendTd = styled.td`
  padding: 0.5rem;
  border: 1px solid black;
  text-align: center;
`;

const PrioritetFargekoderBygningssjekk: React.FC<ShortcodeProps> = ({
  code,
}) => {
  return (
    <LegendTable>
      <thead>
        <LegendTr>
          <LegendTd>Prioritet</LegendTd>
          <LegendTd>Fargekode</LegendTd>
        </LegendTr>
      </thead>
      <tbody>
        <LegendTr>
          <LegendTd>Høy</LegendTd>
          <LegendTd
            style={{
              backgroundColor: "var(--red)",
            }}
          >
            Rød
          </LegendTd>
        </LegendTr>
        <LegendTr>
          <LegendTd>Middels</LegendTd>
          <LegendTd style={{ backgroundColor: "var(--yellow)" }}>Gul</LegendTd>
        </LegendTr>
        <LegendTr>
          <LegendTd>Lav</LegendTd>
          <LegendTd style={{ backgroundColor: "var(--green)" }}>Grønn</LegendTd>
        </LegendTr>
        {code === "prioritet_fargekoder" && (
          <LegendTr>
            <LegendTd>Utskiftning</LegendTd>
            <LegendTd>Ingen</LegendTd>
          </LegendTr>
        )}
      </tbody>
    </LegendTable>
  );
};

export default PrioritetFargekoderBygningssjekk;

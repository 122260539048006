import React from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { UploadStatus } from "~/form/attachment";
import { useCostLineUpdate } from "./actions";
import { CostLine } from "./constants";
import CostLineImage from "./Image";

interface Props {
  costLine: CostLine;
}

// FIXME: implement me in EditCostLine form
const CostLineImageUploader: React.FC<Props> = ({
  costLine: { id, image, showImage },
}) => {
  const costLineUpdate = useCostLineUpdate();

  const handleShowImage = (visible: boolean) =>
    costLineUpdate({ id, showImage: visible });

  const handleAttachment = (signedIds: string[], visible = !!showImage) => {
    const image = signedIds[0];
    return costLineUpdate({ id, image, showImage: visible });
  };

  return (
    <DirectUploadProvider
      onSuccess={handleAttachment}
      render={({ handleUpload, uploads, ready }) => (
        <div>
          <input
            type="file"
            accept=".gif,.jpg,.jpeg,.png"
            disabled={!ready}
            onChange={(e) => handleUpload(e.currentTarget.files)}
            style={{ width: "100%", textOverflow: "ellipsis" }}
          />

          {image && (
            <>
              <CostLineImage
                image={image}
                onRemove={() => handleAttachment([null], false)}
              />
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    checked={showImage}
                    onChange={(e) => handleShowImage(e.target.checked)}
                  />{" "}
                  Se i rapport
                </label>
              </div>
            </>
          )}

          <UploadStatus uploads={uploads} />
        </div>
      )}
    />
  );
};

export default CostLineImageUploader;

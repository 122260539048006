import {
  ApolloProvider,
  MutationFunction,
  useMutation,
  useQuery,
} from "@apollo/client";
// import { Modal } from "antd";
import moment from "moment";
import React from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import { ConfirmButton, SaveButton } from "~/layout";
import { Step, StepInfo, Steps } from "~/layout/Steps";
import { Member } from "~/report/member";
import { client } from "~/setup";
import { getCurrentUserId } from "~/user";
import { CostSection, TouchedStatusMessage } from "..";
import {
  COST_SECTION_STATE_FFW_MUTATION,
  COST_SECTION_STATE_QUERY,
  COST_SECTION_STATE_REW_MUTATION,
} from "./constants";
import StepRewButton from "./StepRewButton";

// const StyledTextArea = styled.textarea`
//   width: 100%;
//   height: 100%;
// `;

export interface Props {
  id: number;
}

type QueryData = { costSection: CostSection };

const getStepTitle = (state: CostSection["state"]) => {
  switch (state) {
    case "awaiting_consultant":
      return "Arbeid";
    case "awaiting_ks_consultant":
      return "Kvalitetssikring";
    case "awaiting_project_manager":
      return "Godkjenning";
    default:
      return "DONE";
  }
};

const RestyledStepInfo = styled(StepInfo)`
  flex: 1;
  justify-content: flex-start;
`;

const StyledMembers = styled.p`
  margin: 0;
`;

const StyledDate = styled.p`
  margin: 2px 0 0 0;
`;

const Members: React.FC<{ members: Member[] }> = ({ members }) => {
  const currentUserId = getCurrentUserId();
  const displayMember =
    members.find((m) => m.id === currentUserId) || members[0];
  const otherMembers = members.filter((m) => m.id !== displayMember.id);
  const otherMemberNames = otherMembers.map((m) => m.name).join(", ");
  return (
    <StyledMembers>
      {members.length <= 1 ? (
        members[0]?.name
      ) : (
        <>
          {displayMember.name}{" "}
          <Badge variant="light" title={otherMemberNames}>
            +{otherMembers.length}
          </Badge>
        </>
      )}
    </StyledMembers>
  );
};

const CostSectionStateSteps: React.FC<Props> = ({ id }) => {
  const { loading, error, data } = useQuery<QueryData>(
    COST_SECTION_STATE_QUERY,
    {
      variables: { id },
    }
  );

  const [ffwMutation, { loading: loadingFfw }] = useMutation(
    COST_SECTION_STATE_FFW_MUTATION
  );
  const [rewMutation, { loading: loadingRew }] = useMutation(
    COST_SECTION_STATE_REW_MUTATION
  );

  if (error) return <p>Error :(</p>;
  if (loading)
    return (
      <Steps>
        <Step
          active
          title="Loading"
          subtitle="Getting section state status..."
        />
      </Steps>
    );

  const step = (
    mutation: MutationFunction,
    state: CostSection["state"],
    message: string | undefined = undefined
  ) =>
    mutation({
      variables: {
        input: {
          id,
          state,
          message,
        },
      },
    });

  const {
    costSection: { status, statusInfo, state, steps },
  } = data as QueryData;

  return (
    <Steps>
      {steps.slice(0, steps.length - 1).map(
        (
          {
            state: stepState,
            ffw: ffwStep,
            // ffwWarning,
            finished,
            members,
            deliveryDate,
            deliveredDate,
          },
          index
        ) => {
          const nextStep = steps[index + 1];
          return (
            <Step
              title={getStepTitle(stepState)}
              active={stepState === state}
              finished={finished}
              key={stepState}
            >
              <RestyledStepInfo>
                <Members members={members} />
                <StyledDate>
                  {finished && deliveredDate && (
                    <span>Levert: {moment(deliveredDate).format("LL")}</span>
                  )}
                  {!finished && deliveryDate && (
                    <span>Frist: {moment(deliveryDate).format("LL")}</span>
                  )}
                </StyledDate>
              </RestyledStepInfo>
              {ffwStep &&
                (status == "touched" ? (
                  <ConfirmButton
                    size="sm"
                    message={<TouchedStatusMessage statusInfo={statusInfo} />}
                    title="Advarsel"
                    confirmLabel="Revidere"
                    rejectLabel="Ignorer og fortsett"
                    onConfirm={() => undefined}
                    onReject={() => step(ffwMutation, stepState)}
                  >
                    Godkjenne
                  </ConfirmButton>
                ) : (
                  <SaveButton
                    size="sm"
                    loading={loadingFfw}
                    onClick={() => step(ffwMutation, stepState)}
                  >
                    Godkjenne
                  </SaveButton>
                ))}
              {nextStep?.rew && (
                <StepRewButton
                  rew={(message: string) =>
                    step(rewMutation, nextStep.state, message)
                  }
                  rewinding={loadingRew}
                  label={
                    stepState === "awaiting_project_manager"
                      ? "Revidere"
                      : "Send tilbake"
                  }
                />
              )}
            </Step>
          );
        }
      )}
    </Steps>
  );
};

const ApolloProviderWrapper: React.FC<Props> = (props) => (
  <ApolloProvider client={client}>
    <CostSectionStateSteps {...props} />
  </ApolloProvider>
);

export default ApolloProviderWrapper;

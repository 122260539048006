import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import { AddFormButton } from "~/form";
import {
  ConstructionLoan,
  CreateInvoiceCategoryInput,
  InvoiceCategoryTypeEnum,
  useCreateInvoiceCategoryMutation,
} from "~/graphql-operations";
import { CategoryForm } from ".";
import { invoiceCategoryTypeOptions } from "../category-type";

interface Props {
  report: Pick<ConstructionLoan, "id" | "slug" | "vatIncluded" | "__typename">;
}

const NewCategory: React.FC<Props> = ({
  report: { id: reportId, vatIncluded },
}) => {
  const [createMutation] = useCreateInvoiceCategoryMutation();
  const typeOptions = invoiceCategoryTypeOptions.filter(({ value }) =>
    [InvoiceCategoryTypeEnum.Client].includes(value)
  );

  const onSubmit = (input: CreateInvoiceCategoryInput) => {
    return createMutation({
      variables: { input },
      refetchQueries: ["ConstructionLoanBudget"],
    });
  };

  return (
    <CategoryForm
      horizontal
      defaultValues={{
        reportId,
        vatIncluded,
        type: InvoiceCategoryTypeEnum.Client,
      }}
      onSubmit={onSubmit}
      typeOptions={typeOptions}
      typeLocked
      resetBlank
    >
      <Col lg="auto">
        <FormGroup>
          <AddFormButton style={{ marginTop: "1.5rem" }} />
        </FormGroup>
      </Col>
    </CategoryForm>
  );
};

export default NewCategory;

import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { DraggableCategory } from ".";
// import { isGroup } from "..";
import { VirtualCategorization } from "../constants";
import { getListStyle } from "../SortableCategorizations";

interface DroppableCategoryListProps {
  categorization: VirtualCategorization;
}

const DroppableCategoryList: React.FC<DroppableCategoryListProps> = ({
  categorization,
}) => {
  const { __typename, id, categories } = categorization;
  return (
    <Droppable droppableId={`${__typename}-${id}`} type="categories">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {categories.map((category, index) => (
            <DraggableCategory
              index={index}
              category={category}
              // group={isGroup(categorization) ? categorization : undefined}
              key={category.id}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableCategoryList;

import moment from "moment";
import React from "react";

type Format = "L" | "LL";

interface Props {
  date: string;
  format?: Format;
}

export const formatDate = (
  date: string | null,
  options?: { format?: Format }
) => {
  const format = options?.format || "LL";
  return date && moment(date).format(format);
};

const Date = ({ date, format, ...rest }: Props) => {
  return <span {...rest}>{formatDate(date, { format })}</span>;
};

export default Date;

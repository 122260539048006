import { useAutoAnimate } from "@formkit/auto-animate/react";
import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import {
  ConstructionLoanPeriod,
  useConstructionLoanPeriodsQuery,
  useDeleteConstructionLoanPeriodMutation,
} from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import List, { Block, BlockHeader } from "~/layout/block-list";
import ErrorAlert from "~/layout/ErrorAlert";
import Icon from "~/layout/icon";
import { NodeListEmpty } from "~/node";
import { useSession } from "~/session";
import StatusBadge from "~/util/WorkStatus/StatusBadge";
import NewConstructionLoanPeriod from "./NewPeriod";
import PeriodTitle from "./Title";

interface Props {
  report: { id: string };
}

const EditConstructionLoanPeriodsList: React.FC<Props> = ({
  report: { id },
}) => {
  const { generalEditor } = useSession();
  const { loading, error, data } = useConstructionLoanPeriodsQuery({
    variables: { id },
  });
  const [deleteMutation] = useDeleteConstructionLoanPeriodMutation();
  const [animRef] = useAutoAnimate<HTMLDivElement>();

  if (loading) return <p>Loading...</p>;
  if (error) return <ErrorAlert error={error} />;

  const periods = data?.constructionLoan?.periods || [];

  return (
    <>
      <List.Header>
        <Row>
          <Col lg="10">Periode</Col>
          <Col lg="2" className="text-right">
            Valg
          </Col>
        </Row>
      </List.Header>

      {periods.length ? (
        <List ref={animRef}>
          {periods.map((period: ConstructionLoanPeriod) => {
            const { id, full, published, status, acl } = period;
            const Title = () => <PeriodTitle period={period} />;
            return (
              <Block key={id} id={`construction-loan-period-${id}`}>
                <BlockHeader>
                  <Row className="align-items-center">
                    <Col lg="auto" className="pr-0">
                      <StatusBadge status={status} />
                    </Col>
                    <Col>
                      {acl.edit ? (
                        <a href={`./period/${id}`}>
                          <Title />
                        </a>
                      ) : (
                        <span className="text-muted">
                          <Title />
                        </span>
                      )}
                    </Col>
                    <Col lg="auto">
                      {full && <Badge variant="info">Rapport</Badge>}
                      {published && <Badge variant="danger">Publisert</Badge>}
                    </Col>
                    {!acl.edit && (
                      <Col lg="auto" className="text-right pl-0">
                        <a
                          href={`./summary?period=${id}`}
                          className="d-flex align-items-center"
                        >
                          <Icon icon="externalLink" />
                        </a>
                      </Col>
                    )}
                    {acl.delete && (
                      <Col lg="auto" className="text-right">
                        <DeleteButton
                          iconOnly
                          onClick={() =>
                            deleteMutation({ variables: { input: { id } } })
                          }
                          style={{ padding: 0 }}
                        />
                      </Col>
                    )}
                  </Row>
                </BlockHeader>
              </Block>
            );
          })}
        </List>
      ) : (
        <NodeListEmpty />
      )}

      {generalEditor && (
        <>
          <hr />
          <NewConstructionLoanPeriod
            reportId={id}
            name={`Rapport ${periods.length + 1}`}
          />
        </>
      )}
    </>
  );
};

export default EditConstructionLoanPeriodsList;

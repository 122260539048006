import { ApolloProvider } from "@apollo/client";
import invariant from "invariant";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useRequestCostBlockTemplatesQuery } from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import List, { BlockTitle } from "~/layout/block-list";
import { Block, Body, Header } from "~/layout/block-list/collapsible";
import { client } from "~/setup";
import {
  EditRequestCostBlockTemplate,
  NewRequestCostBlockTemplate,
  useDeleteRequestCostBlockTemplate,
} from ".";
import { EditRequestCostLineTemplateList } from "../cost-line-template";

const RequestCostBlockTemplatesListPage: React.FC = () => {
  const deleteRequestCostBlockTemplate = useDeleteRequestCostBlockTemplate();
  const {
    loading,
    error,
    data: { requestCostBlockTemplates } = {},
  } = useRequestCostBlockTemplatesQuery();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  invariant(requestCostBlockTemplates, "No requestCostBlockTemplates");

  return (
    <>
      <List>
        {requestCostBlockTemplates.map(
          ({ id, title, number, internalUse, requestCostLineTemplates }) => {
            return (
              <Block key={id} id={`request-cost-block-template-${id}`}>
                <Header>
                  <Row className="align-items-center">
                    <Col lg="auto" style={{ fontSize: "1rem" }}>
                      # {number}
                    </Col>
                    <Col className="pl-0">
                      <BlockTitle>{title}</BlockTitle>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <DeleteButton
                        iconOnly
                        onClick={async () => deleteRequestCostBlockTemplate(id)}
                      />
                    </Col>
                  </Row>
                </Header>
                <Body>
                  <EditRequestCostBlockTemplate
                    template={{ id, title, number, internalUse }}
                  />
                  {requestCostLineTemplates && (
                    <EditRequestCostLineTemplateList
                      lines={requestCostLineTemplates}
                      requestCostBlockTemplateId={id}
                    />
                  )}
                </Body>
              </Block>
            );
          }
        )}
      </List>
      <NewRequestCostBlockTemplate />
    </>
  );
};

const ApolloWrap: React.FC = () => (
  <ApolloProvider client={client}>
    <RequestCostBlockTemplatesListPage />
  </ApolloProvider>
);

export default ApolloWrap;

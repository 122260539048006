import { gql } from "@apollo/client";
import { Attachment, attachmentFragment } from "~/attachment";
import { NodeUrlsInterface, ResourceInterface } from "~/node";
import {
  RequestCostBlock,
  requestCostBlockWithSummaryFragment,
} from "./cost-block";
import { requestLineFragment } from "./line";

export interface RequestSection extends ResourceInterface, NodeUrlsInterface {
  title: string;
  description?: string;

  hidden?: boolean;
  visible?: boolean;

  srlTitle: string | null;
  srlAnswer: string | null;
  srlAnswered_by: string | null;
  srlReference: string | null;
  srlFiles: Attachment | null;

  // status: CostLine["status"];
  // statusInfo: {
  //   // message?: string;
  //   revisionItemsCount: number;
  // };

  requestCostBlocks: RequestCostBlock[];
  // requestLines: CostLine[];
  // displayableCostLines: CostLine[];
}

export const requestSectionFragment = gql`
  fragment RequestSectionFields on RequestSection {
    id
    title
    description
    srlTitle
    srlAnswer
    srlAnsweredBy
    srlReference
    srlFiles {
      ...AttachmentFields
    }
  }
  ${attachmentFragment}
`;

export const REQUEST_SECTION_QUERY = gql`
  query requestSection($id: ID!) {
    requestSection(id: $id) {
      ...RequestSectionFields
    }
  }
  ${requestSectionFragment}
`;

export const REQUEST_SECTION_REQUEST_LINES_QUERY = gql`
  query requestSectionLines($id: ID!) {
    requestSection(id: $id) {
      id
      requestLines {
        ...RequestLineFields
      }
    }
  }
  ${requestLineFragment}
`;

export const REQUEST_SECTION_REQUEST_COST_BLOCKS_QUERY = gql`
  query requestSectionCostBlocks($id: ID!) {
    requestSection(id: $id) {
      id
      requestCostBlocks {
        ...RequestCostBlockWithSummary
      }
    }
  }
  ${requestCostBlockWithSummaryFragment}
`;

export const REQUEST_SECTION_UPDATE_MUTATION = gql`
  mutation RequestSectionUpdate($input: RequestSectionUpdateInput!) {
    requestSectionUpdate(input: $input) {
      requestSection {
        ...RequestSectionFields
      }
    }
  }
  ${requestSectionFragment}
`;

import React from "react";
import { WORK_STATUS } from "~/util/WorkStatus";
import WorkStatusBadge, {
  StatusBadgeProps,
} from "~/util/WorkStatus/StatusBadge";

type Props = Omit<StatusBadgeProps, "color"> &
  Required<Pick<StatusBadgeProps, "status">>;

const StatusBadge: React.FC<Props> = ({ status, ...rest }) => {
  return (
    <WorkStatusBadge
      status={status}
      color={status === WORK_STATUS.PRISTINE ? "danger" : undefined}
      {...rest}
    />
  );
};

export default StatusBadge;

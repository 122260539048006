import React from "react";
import styled, { css } from "styled-components";
import { Attachment, AttachmentFile } from ".";
import { AttachmentFileProps } from "./AttachmentFile";

interface Props {
  attachments: Attachment[];
  cards?: boolean;
  disposition?: AttachmentFileProps["disposition"];
  onDelete?: AttachmentFileProps["onDelete"];
}

export const AttachmentListWrap = styled.ul<{ cards?: boolean }>`
  display: ${({ cards }) => (cards ? "flex" : "block")};
  flex-wrap: wrap;
  margin: 1rem 0;
  padding: 0;
`;

export const AttachmentWrap = styled.li<{ card?: boolean }>`
  padding: 0;
  list-style: none;
  /* ${({ card }) =>
    card &&
    css`
      margin: 0 0 0.5rem 0;
      border: 1px solid;
    `}; */
`;

const AttachmentList: React.FC<Props> = ({
  attachments,
  cards,
  disposition,
  onDelete,
  ...rest
}) => {
  if (!attachments || !attachments.length) return null;

  return (
    <AttachmentListWrap cards={cards} {...rest}>
      {attachments.map((attachment) => (
        <AttachmentWrap key={attachment.signedId}>
          <AttachmentFile
            attachment={attachment}
            card={cards}
            onDelete={onDelete}
            disposition={disposition}
          />
        </AttachmentWrap>
      ))}
    </AttachmentListWrap>
  );
};

export default AttachmentList;

import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";
import { DocumentationSection } from "../";
import { DocumentationBlockTemplate } from "../block-template";
import { DocumentationLine, documentationLineFragment } from "../line";

export interface DocumentationBlock extends ResourceInterface {
  documentationSection: DocumentationSection;
  documentationBlockTemplate: DocumentationBlockTemplate;
  documentationBlockTemplateId: string;
  documentationLines: DocumentationLine[];

  title: string;
  description: string;
  order: number;
}

export const documentationBlockFragment = gql`
  fragment DocumentationBlockFields on DocumentationBlock {
    id
    documentationBlockTemplateId
    title
    description
    order
    maxScore
    score
  }
`;

export const DOCUMENTATION_BLOCK_LINES_QUERY = gql`
  query DocumentationBlockLines($id: ID!) {
    documentationBlock(id: $id) {
      ...DocumentationBlockFields
      documentationLines {
        ...DocumentationLineFields
      }
    }
  }
  ${documentationBlockFragment}
  ${documentationLineFragment}
`;

export const DOCUMENTATION_BLOCK_CREATE_MUTATION = gql`
  mutation DocumentationBlockCreate($input: DocumentationBlockCreateInput!) {
    documentationBlockCreate(input: $input) {
      documentationBlock {
        ...DocumentationBlockFields
      }
    }
  }
  ${documentationBlockFragment}
`;

export const DOCUMENTATION_BLOCK_UPDATE_MUTATION = gql`
  mutation DocumentationBlockUpdate($input: DocumentationBlockUpdateInput!) {
    documentationBlockUpdate(input: $input) {
      documentationBlock {
        ...DocumentationBlockFields
      }
    }
  }
  ${documentationBlockFragment}
`;

export const DOCUMENTATION_BLOCK_DELETE_MUTATION = gql`
  mutation DocumentationBlockDelete($input: DocumentationBlockDeleteInput!) {
    documentationBlockDelete(input: $input) {
      documentationBlock {
        ...DocumentationBlockFields
      }
    }
  }
  ${documentationBlockFragment}
`;

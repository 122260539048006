import React from "react";
import styled from "styled-components";

const StyledH3 = styled.h3`
  margin: 0;
  font-weight: 300;
  font-size: 1rem;
  text-transform: uppercase;
`;

const BlockListItemTitle: React.FC = ({ children }) => {
  return <StyledH3>{children}</StyledH3>;
};

export default BlockListItemTitle;

import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Fieldset,
  Form,
  SaveFormButton,
  SelectField,
  TextAreaField,
} from "~/form";
import { Block } from "~/layout";
import { useCreateBuildingPartRatingRule } from ".";
import {
  KGOptions,
  maintenancePeriodOptions,
  ratingOptions,
  TGOptions,
} from "./helpers";

const NewBuildingPartRatingRule: React.FC = () => {
  const create = useCreateBuildingPartRatingRule();

  // const [
  //   createMutation,
  // ] = useCreateHousingMaintenanceBuildingPartRatingRuleMutation();

  // const onSubmit = async (
  //   input: CreateHousingMaintenanceBuildingPartRatingRuleInput
  // ) => {
  //   return createMutation({
  //     variables: { input },
  //   });
  // };
  // Note : UPDATE CASH

  return (
    <Form onSubmit={create} horizontal resetBlank>
      <Block>
        <Fieldset title="Ny Vurderingstabell">
          <Row>
            <Col>
              <SelectField
                name="tg"
                label="Tg"
                options={TGOptions}
                placeholder="-"
              />
            </Col>
            <Col>
              <SelectField
                name="kg"
                label="Kg"
                options={KGOptions}
                placeholder="-"
              />
            </Col>
            <Col>
              <SelectField
                name="rating"
                label="Karakter"
                options={ratingOptions}
                placeholder="-"
              />
            </Col>
            <Col>
              <SelectField
                name="period"
                label="Periode"
                options={maintenancePeriodOptions}
                placeholder="-"
              />
            </Col>
            <Col lg="2" className="d-flex justify-content-end">
              <SaveFormButton />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextAreaField name="description" label="Generell Vurdering" />
            </Col>
          </Row>
        </Fieldset>
      </Block>
    </Form>
  );
};

export default NewBuildingPartRatingRule;

import { gql } from "@apollo/client";
import { Member, memberFragment } from "../constants";

export interface ReportConsultants {
  constructionConsultants: Member[];
  constructionKsConsultants: Member[];
  electricalConsultants: Member[];
  electricalKsConsultants: Member[];
  fireConsultants: Member[];
  fireKsConsultants: Member[];
  plumbingConsultants: Member[];
  plumbingKsConsultants: Member[];
}

export const reportSetConsultantsMutation = (workingArea: string) => gql`
    mutation reportSetConsultants($input: ReportSetConsultantsInput!) {
      reportSetConsultants(input: $input) {
        id
        ... on Consultable {
          ${workingArea}Consultants {
            ...MemberFields
          }
          ${workingArea}KsConsultants {
            ...MemberFields
          }
        }
      }
    }
    ${memberFragment}
  `;

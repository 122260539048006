import React from "react";
import { Col, Row } from "react-bootstrap";
import DeleteBuildingButton from "~/Building/DeleteBuildingButton";
import { BlockListItemData, BlockListItemTitle } from "../layout/BlockList";
import { Building } from "./constants";

interface Props {
  building: Building;
  showDelete?: boolean;
  inverse?: boolean;
}

const BuildingListItemHeader: React.FC<Props> = ({
  building: { id, name, area, buildingTypeName, reportId },
  showDelete,
  inverse = false,
}) => {
  return (
    <Row>
      <Col lg="4">
        <BlockListItemTitle>{name}</BlockListItemTitle>
      </Col>
      <Col lg="4">
        <BlockListItemData>{buildingTypeName}</BlockListItemData>
      </Col>
      <Col lg="2" className="text-right">
        <BlockListItemData>
          {area?.toFixed(1).replace(".", ",")}
        </BlockListItemData>
      </Col>
      {showDelete && (
        <Col lg="2" className="text-right">
          <DeleteBuildingButton building={{ id, reportId }} inverse={inverse} />
        </Col>
      )}
    </Row>
  );
};

export default BuildingListItemHeader;

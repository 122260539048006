import { gql } from "@apollo/client";
import { NodeUrlsInterface, ResourceInterface } from "~/node";
import { Member } from "~/report/member";
import {
  RequestCostLine,
  requestCostLineFragment,
} from "../request-section/cost-line";
import { CostBlock } from "./block";
import { costBlockWithSummaryFragment } from "./fragments";
import { CostLine } from "./line";

export type CostSectionState =
  | "awaiting_consultant"
  | "awaiting_ks_consultant"
  | "awaiting_project_manager"
  | "done";

export type CostSectionStateStep = {
  state: CostSectionState;
  ffw: boolean;
  // ffwWarning?: string;
  rew: boolean;
  finished: boolean;
  deliveryDate: Date | null;
  deliveredDate: Date | null;
  members: Member[];
};

export enum CostType {
  CONSTRUCTION = "construction",
  PLUMBING = "plumbing",
  ELECTRICAL = "electrical",
  FIRE = "fire",
  ECO = "eco",
}

export const costTypeOptions = [
  {
    value: CostType.CONSTRUCTION,
    label: "Bygg",
  },
  {
    value: CostType.PLUMBING,
    label: "VVS",
  },
  {
    value: CostType.ELECTRICAL,
    label: "Elektro",
  },
  {
    value: CostType.FIRE,
    label: "Brann",
  },
  {
    value: CostType.ECO,
    label: "Miljø",
  },
];

export interface CostSection extends ResourceInterface, NodeUrlsInterface {
  costType: CostType;
  state: CostSectionState;
  title: string;
  description?: string;
  note?: string;

  hidden?: boolean;
  visible?: boolean;

  status: CostLine["status"];
  statusInfo: {
    // message?: string;
    touchedCostLinesCount: number;
    touchedRequestCostLinesCount?: number;
  };
  stateMessage?: string;
  steps: CostSectionStateStep[];

  costBlocks: CostBlock[];
  // costLines: CostLine[];
  // displayableCostLines: CostLine[];

  requestCostLineCount: number;
  requestCostLines?: RequestCostLine[];
}

export const costSectionFragment = gql`
  fragment CostSectionFields on CostSection {
    id
    title
  }
`;

export const COST_OPTIONS_QUERY = gql`
  query costOptions($maintenanceDuration: Int) {
    costOptions {
      period(maintenanceDuration: $maintenanceDuration) {
        value
        label
      }
      priority {
        value
        label
      }
      coverage {
        value
        label
      }
      tg {
        value
        label
      }
      kg {
        value
        label
      }
    }
  }
`;

export const COST_SECTION_COST_BLOCKS_QUERY = gql`
  query costSectionCostBlocks($id: ID!) {
    costSection(id: $id) {
      id
      ...CostSectionFields
      costBlocks {
        ...CostBlockWithSummary
      }
    }
  }
  ${costBlockWithSummaryFragment}
  ${costSectionFragment}
`;

export const COST_SECTION_REQUEST_COST_LINES_QUERY = gql`
  query costSectionRequestCostLines($id: ID!) {
    costSection(id: $id) {
      id
      ...CostSectionFields
      requestCostLines {
        ...RequestCostLineFields
      }
    }
  }
  ${costSectionFragment}
  ${requestCostLineFragment}
`;

import { gql } from "@apollo/client";
import { userFragment } from "~/user/fragments";

export const attachmentFragment = gql`
  fragment AttachmentFields on Attachment {
    id
    key
    signedId
    filename
    contentType
    # metadata {
    #   identified
    #   analyzed
    #   width
    #   height
    # }
    byteSize
    # checksum
    view
    download
    variants {
      small
      small2x
      large
      large2x
      # original
    }
  }
`;

export const attachmentAuditFragment = gql`
  fragment AttachmentAudit on Attachment {
    id
    createdAt
    uploadedBy {
      ...UserFields
    }
  }
  ${userFragment}
`;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { ConstructionLoan } from "~/graphql-operations";
import List, { Block, BlockTitle } from "~/layout/block-list";
import { SectionList } from "~/sections";
import { Session, SessionProvider, useSession } from "~/session";
import { client } from "~/setup";
import { EditConstructionLoanPeriodList } from ".";

interface Props {
  report: ConstructionLoan;
}

const ContentPage: React.FC<Props> = ({ report }) => {
  const { generalEditor } = useSession();
  return (
    <>
      <List>
        {generalEditor && (
          <Block>
            <BlockTitle>
              <a href="./events">Møter / Befaring</a>
            </BlockTitle>
          </Block>
        )}
        <Block>
          <BlockTitle>
            <a href="./documents">Dokumenter</a>
          </BlockTitle>
        </Block>
        <Block>
          <BlockTitle>
            <a href="./deliveries">Leveranser</a>
          </BlockTitle>
        </Block>
      </List>

      <hr />
      <SectionList report={report} />
      <hr />

      <EditConstructionLoanPeriodList report={report} />
    </>
  );
};

const ApolloWrap: React.FC<Props & { session: Session }> = ({
  session,
  ...rest
}) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <ContentPage {...rest} />
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

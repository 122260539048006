import { gql } from "@apollo/client";
import { attachmentFragment } from "~/attachment";

// putting fragments in a single place to prevent import circular dependencies
// cost line needs cost block, cost block need cost line

export const costBlockFragment = gql`
  fragment CostBlockFields on CostBlock {
    id
    title
    number
    costBlockTemplate {
      id
      title
      number
    }
  }
`;

export const costBlockSummaryFragment = gql`
  fragment CostBlockSummary on CostBlock {
    id
    buildingsCount
    costLinesCount
    cost
    status
  }
`;

export const costBlockWithSummaryFragment = gql`
  fragment CostBlockWithSummary on CostBlock {
    id
    ...CostBlockFields
    ...CostBlockSummary
  }
  ${costBlockFragment}
  ${costBlockSummaryFragment}
`;

export const costLineFragment = gql`
  fragment CostLineFields on CostLine {
    id
    costBlockId
    buildingId
    ref
    title
    period
    priority
    costCoverage
    tg
    kg
    cost
    description
    note
    status
    image {
      ...AttachmentFields
    }
    showImage
  }
  ${attachmentFragment}
`;

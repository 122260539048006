import React from "react";
import { Col } from "react-bootstrap";
import { CheckboxField, Form, NumberField, Row } from "~/form";
import {
  CurrencyEnum,
  InvoiceEntry,
  UpdateInvoiceEntryInput,
  useDeleteInvoiceEntryMutation,
  useUpdateInvoiceEntryMutation,
} from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import Currency, { currencyLabel } from "~/util/currency";
import { InvoiceEntryCategorization } from "./categorization";
import InvoiceEntryCategorySelectField from "./category/CategorySelectField";

interface Props {
  categorizations: InvoiceEntryCategorization[];
  currency: CurrencyEnum;
  entry: Pick<
    InvoiceEntry,
    "id" | "amount" | "categoryId" | "total" | "vatIncluded"
  >;
}

const EditEntry: React.FC<Props> = ({
  entry: { id, categoryId, amount, total, vatIncluded },
  currency,
  categorizations,
}) => {
  const [updateMutation] = useUpdateInvoiceEntryMutation();
  const [deleteMutation] = useDeleteInvoiceEntryMutation();

  const onSubmit = async (input: UpdateInvoiceEntryInput) => {
    return updateMutation({
      variables: { input },
    });
  };

  const onDelete = async (id: string) => {
    return deleteMutation({
      variables: { input: { id } },
    });
  };

  return (
    <Form
      autoSave
      horizontal
      defaultValues={{ id, categoryId, amount, vatIncluded }}
      onSubmit={onSubmit}
      id={`invoice-entry-${id}`}
    >
      <Row className="align-items-center">
        <Col lg="3">
          <NumberField
            name="amount"
            label={null}
            required
            append={currencyLabel(currency)}
          />
        </Col>
        <Col lg="4">
          <InvoiceEntryCategorySelectField
            name="categoryId"
            label={null}
            categorizations={categorizations}
            noDefault
          />
        </Col>
        <Col lg="auto">
          <CheckboxField name="vatIncluded" label="MVA inkludert" />
        </Col>
        <Col className="text-right">
          <Currency decimals>{total}</Currency>
        </Col>
        <Col lg="auto" className="d-flex justify-content-end">
          <DeleteButton onClick={() => onDelete(id)} iconOnly />
        </Col>
      </Row>
    </Form>
  );
};

// 830 : 92,7 = x : 100

export default EditEntry;

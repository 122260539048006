import invariant from "invariant";
import React from "react";
import { useKostnadsfordelingTabellQuery } from "~/graphql-operations";
import { WordLoader } from "~/layout/Loading";
import { Table, TCell, THCell, THead, TRow } from "~/layout/table/CostTable";
import { ShortcodeProps } from ".";

export const MVA = 0.25;

const KostnadsfordelingTabell: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const {
    loading,
    error,
    data: { report } = {},
  } = useKostnadsfordelingTabellQuery({ variables: { slug } });
  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  invariant(report && "cost" in report, "No report data");

  const { cost } = report;

  const {
    coverageTenant,
    coverageLandlord,
    coverageBoth,

    coverageTenantConstruction,
    coverageTenantElectrical,
    coverageTenantPlumbing,
    coverageTenantFire,

    coverageLandlordConstruction,
    coverageLandlordElectrical,
    coverageLandlordPlumbing,
    coverageLandlordFire,

    coverageBothConstruction,
    coverageBothElectrical,
    coverageBothPlumbing,
    coverageBothFire,

    totalConstruction,
    totalElectrical,
    totalPlumbing,
    totalFire,

    total,
  } = cost;

  const showConstruction = !!totalConstruction;
  const showElectrical = !!totalElectrical;
  const showFire = !!totalFire;
  const showPlumbing = !!totalPlumbing;
  const showTable = !!total;

  return (
    <>
      {showTable && (
        <Table responsive="sm" borderless>
          <THead>
            <TRow>
              <THCell borderRight left>
                Type/<br></br>Tidsrom
              </THCell>
              <THCell right>Leier</THCell>
              <THCell right>Gårdeier</THCell>
              <THCell right>Leier/Gårdeier</THCell>
              <THCell right borderY>
                Sum
              </THCell>
            </TRow>
          </THead>
          <tbody>
            {showConstruction && (
              <TRow right>
                <TCell borderRight>Bygg</TCell>
                <TCell>{coverageTenantConstruction}</TCell>
                <TCell>{coverageLandlordConstruction}</TCell>
                <TCell>{coverageBothConstruction}</TCell>
                <TCell total borderY>
                  {totalConstruction}
                </TCell>
              </TRow>
            )}
            {showPlumbing && (
              <TRow right>
                <TCell borderRight>Vvs</TCell>
                <TCell>{coverageTenantPlumbing}</TCell>
                <TCell>{coverageLandlordPlumbing}</TCell>
                <TCell>{coverageBothPlumbing}</TCell>
                <TCell total borderY>
                  {totalPlumbing}
                </TCell>
              </TRow>
            )}
            {showElectrical && (
              <TRow right>
                <TCell borderRight>Elektro</TCell>
                <TCell>{coverageTenantElectrical}</TCell>
                <TCell>{coverageLandlordElectrical}</TCell>
                <TCell>{coverageBothElectrical}</TCell>
                <TCell total borderY>
                  {totalElectrical}
                </TCell>
              </TRow>
            )}
            {showFire && (
              <TRow right>
                <TCell borderRight>Brann</TCell>
                <TCell>{coverageTenantFire}</TCell>
                <TCell>{coverageLandlordFire}</TCell>
                <TCell>{coverageBothFire}</TCell>
                <TCell total borderY>
                  {totalFire}
                </TCell>
              </TRow>
            )}
            <TRow important right>
              <TCell borderRight>Sum ekskl. mva.</TCell>
              <TCell>{coverageTenant}</TCell>
              <TCell>{coverageLandlord}</TCell>
              <TCell>{coverageBoth}</TCell>
              <TCell total borderY>
                {total}
              </TCell>
            </TRow>
            <TRow right>
              <TCell borderRight>Merverdiavgift (25%)</TCell>
              <TCell>{coverageTenant * MVA}</TCell>
              <TCell>{coverageLandlord * MVA}</TCell>
              <TCell>{coverageBoth * MVA}</TCell>
              <TCell total borderY>
                {total * MVA}
              </TCell>
            </TRow>
          </tbody>
          <tfoot>
            <TRow right>
              <TCell borderRight>Sum ekskl. mva.</TCell>
              <TCell>{coverageTenant * (1 + MVA)}</TCell>
              <TCell>{coverageLandlord * (1 + MVA)}</TCell>
              <TCell>{coverageBoth * (1 + MVA)}</TCell>
              <TCell total borderY>
                {total * (1 + MVA)}
              </TCell>
            </TRow>
          </tfoot>
        </Table>
      )}
    </>
  );
};

export default KostnadsfordelingTabell;

import React from "react";
import { Col } from "react-bootstrap";
import { DateField, Fieldset, Row, TextField } from "~/form";
import ToggleField from "~/form/ToggleField";
import { attachmentInput } from "~/form/attachment";
import AttachmentField from "~/form/attachment/AttachmentField";
import { ConstructionLoanPeriod } from "~/graphql-operations";
import CurrencyInfo from "./CurrencyInfo";
import EditPeriodForm from "./EditPeriodForm";

interface Props {
  period: ConstructionLoanPeriod;
}

const EditPeriod: React.FC<Props> = ({
  period,
  period: {
    report: { currencies },
    files,
    full,
    id,
    // invoices,
    name,
    startedAt,
  },
}) => {
  const defaultValues = {
    id,
    name,
    files: files ? attachmentInput(files) : undefined,
    full,
    startedAt,
  };
  // const hasInvoices = invoices.length > 0;

  return (
    <EditPeriodForm horizontal defaultValues={defaultValues}>
      <Fieldset>
        <Row>
          <Col lg="5">
            <TextField
              name="name"
              label="Tittel"
              // disabled={hasInvoices}
            />
          </Col>
          <Col lg="3">
            <DateField
              name="startedAt"
              label="Startdato"
              // disabled={hasInvoices}
              required
            />
          </Col>
          <Col lg="auto" className="pl-3">
            <ToggleField name="full" label="Full period" />
          </Col>
        </Row>
      </Fieldset>
      {currencies.length > 0 && (
        <Fieldset title="Valutakonverteringskurs">
          <CurrencyInfo period={period} />
          {/* <CurrencyFields period={period} /> */}
        </Fieldset>
      )}
      <Fieldset title="Bilder">
        <AttachmentField
          attachments={files}
          label={null}
          multiple
          name="files"
        />
      </Fieldset>
    </EditPeriodForm>
  );
};

export default EditPeriod;

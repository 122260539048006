import React from "react";
import styled, { css } from "styled-components";
import Icon, { StyledIcon } from "~/layout/icon";

interface Props {
  active?: boolean;
  finished?: boolean;
}

const StyledIndicator = styled.div<Pick<Props, "active" | "finished">>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin: 1rem 0;
  border-radius: 50%;
  background: ${({ active, finished }) => {
    if (active) return "var(--white)";
    else if (finished) return "var(--teal)";
    else return "var(--white)";
  }};
  ${({ active }) =>
    active &&
    css`
      border: 6px solid var(--orange);
    `}
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25);
  transform: scale(
    ${({ active, finished }) => (active || finished ? 1 : 0.75)}
  );

  ${StyledIcon} {
    width: 15px;
    height: 15px;
  }
`;

const Indicator: React.FC<Props> = ({ finished, ...rest }) => {
  return (
    <StyledIndicator {...{ finished }} {...rest}>
      {finished && <Icon icon="checkSolid" color="white" />}
    </StyledIndicator>
  );
};

export default Indicator;

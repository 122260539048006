import { ApolloProvider } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { useReportHeroQuery } from "~/graphql-operations";
import ErrorAlert from "~/layout/ErrorAlert";
import { DateLoader, H1Loader, WordLoader } from "~/layout/Loading";
import Icon from "~/layout/icon";
import { reportTypeLabel } from "~/report";
import { client } from "~/setup";
import { Date } from "~/util";
import image from "./hero-report-homepage.png";

type Props = {
  slug: string;
};

const Container = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--dark-gray);
  background-image: url(${image});
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const P = styled.p`
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
`;

const H1 = styled.h1`
  position: relative;
  margin: 2.5rem 0;
  padding-bottom: 2rem;
  padding-top: 80px;
  font-size: 2.625rem;
  letter-spacing: 3px;
  font-weight: 700;
  color: #fff;
  &:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    content: "";
    width: 114px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--yellow);
  }
`;

const Scroll = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  margin-top: 100px;
  background-color: var(--yellow);
  border-radius: 50%;
  border: 3px solid var(--juice);
  cursor: pointer;
`;

const scrollDown = () => {
  window.scrollTo({
    top: window.innerHeight,
    behavior: "smooth",
  });
};

const Hero: React.FC<Props> = ({ slug }) => {
  const { loading, error, data } = useReportHeroQuery({
    variables: { slug },
  });
  const report = data?.report;

  if (error) return <ErrorAlert error={error} />;

  const { name, lockedAt, originalReportDate, type, typeManual } = report || {};

  return (
    <>
      <Container>
        <HeroContent>
          {loading && (
            <P>
              <DateLoader />
            </P>
          )}
          {originalReportDate && (
            <P>
              Publisert: <Date date={originalReportDate} />
            </P>
          )}
          <P>
            {originalReportDate && "Revidert: "}
            {lockedAt && <Date date={lockedAt} />}
          </P>

          <H1>{loading ? <H1Loader /> : name}</H1>
          <P>
            {loading ? (
              <WordLoader />
            ) : typeManual ? (
              typeManual
            ) : (
              type && reportTypeLabel(type)
            )}
          </P>

          <Scroll onClick={scrollDown}>
            <Icon icon="caretDown" />
          </Scroll>
        </HeroContent>
      </Container>
    </>
  );
};

const ApolloWrap: React.FC<Props> = ({ slug }) => (
  <ApolloProvider client={client}>
    <Hero slug={slug} />
  </ApolloProvider>
);

export default ApolloWrap;

//http://localhost:3000/assessments/B1BLYvFqjeWAWN0FeF5uzQ
// published  https://rapport.daytwo.no/assessments/B1BLYvFqjeWAWN0FeF5uzQ

import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Block from "~/layout/Block";
import { BlockListHeader } from "~/layout/BlockList";
import { client } from "~/setup";
import { CostBlock, COST_BLOCK_COST_LINE_ADMIN_QUERY } from "../block";
import { CostLine } from "./constants";
import EditCostLine from "./EditCostLine";
import NewCostLine from "./NewCostLine";

interface Props {
  costBlock: CostBlock;
}

const EditCostLineList: React.FC<Props> = ({ costBlock: { id } }) => {
  const { loading, error, data } = useQuery(COST_BLOCK_COST_LINE_ADMIN_QUERY, {
    variables: {
      id: id.toString(),
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    costBlock,
    costBlock: { buildings, costLines },
  } = data;

  if (!costBlock.buildings.length) return null;

  return (
    <>
      {costLines.length ? (
        <ul className="block-list subblock">
          <li style={{ paddingTop: 0 }}>
            <BlockListHeader>
              <Row>
                <Col lg="5">Avvik og vedlikeholdsbehov</Col>
                <Col lg="5"></Col>
                <Col lg="2" className="text-right">
                  Valg
                </Col>
              </Row>
            </BlockListHeader>
          </li>
          {costLines.map((costLine: CostLine) => (
            <li
              id={`cost-line-${costLine.id}`}
              className="block-list-item"
              style={{ margin: "1rem 0 0 0", padding: 0 }}
              key={costLine.id}
            >
              <EditCostLine
                costBlock={costBlock}
                costLine={costLine}
                buildings={buildings}
              />
            </li>
          ))}
        </ul>
      ) : (
        <p className="empty-records">No cost lines created yet.</p>
      )}
      <Block>
        <NewCostLine buildings={buildings} costBlock={costBlock} />
      </Block>
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <EditCostLineList {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

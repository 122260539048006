import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import { filterImages } from "~/attachment";
import { HTMLContent, ImageLightbox } from "~/layout";
import { KG, Period, TG } from "~/node/label";
import { periodSeverity } from "~/node/label/Period";
import { Report, ReportProvider } from "~/report";
import { Session, SessionProvider } from "~/session";
import { client } from "~/setup";
import {
  HousingMaintenanceSection,
  HOUSING_MAINTENANCE_SECTION_ACTIONABLE_BUILDING_PARTS_QUERY,
} from "..";
import CostEstimate from "./CostEstimate";

interface Props {
  report: Report;
  housingMaintenanceSection: HousingMaintenanceSection;
}

type QueryResult = {
  housingMaintenanceSection: HousingMaintenanceSection;
};

const BuildingPartsPage: React.FC<Omit<Props, "report">> = ({
  housingMaintenanceSection: { id: sectionId },
}) => {
  // const update = updateMutation(id);
  const { loading, error, data } = useQuery<QueryResult>(
    HOUSING_MAINTENANCE_SECTION_ACTIONABLE_BUILDING_PARTS_QUERY,
    {
      variables: { id: sectionId },
    }
  );

  const {
    housingMaintenanceSection: {
      description = "",
      actionableBuildingParts = [],
    } = {},
  } = data || {};

  const images = actionableBuildingParts.find(
    ({ files }) => files && filterImages(files).length
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="section">
      <div className="container card flat">
        <HTMLContent>{description}</HTMLContent>
        <table className="accordion-table">
          <thead className="thead">
            <tr>
              <th className="th">Bygningsdel</th>
              <th className="th text-center">TG</th>
              <th className="th text-center">KG</th>
              <th className="th">Prioritet/ tidsforløp</th>
              <th className="th">Beskrivelse avvik</th>
              {images && <th className="th">Bilder</th>}
              <th className="th">Estimert kostnad</th>
            </tr>
          </thead>

          <tbody className="tbody">
            {actionableBuildingParts.map((buildingPart) => {
              const {
                id,
                name,
                tg,
                kg,
                period,
                description,
                descriptionDeviation,
                files,
              } = buildingPart;
              return (
                <tr key={id}>
                  <td className="td">{name}</td>
                  <td className="td text-center">
                    <TG>{tg}</TG>
                  </td>
                  <td className="td text-center">
                    <KG>{kg}</KG>
                  </td>
                  <td className={`td ${periodSeverity(period)}`}>
                    <Period>{period}</Period>
                  </td>
                  <td className="td">
                    <p>{description}</p>
                    {descriptionDeviation && (
                      <>
                        <hr />
                        <HTMLContent>{descriptionDeviation}</HTMLContent>
                      </>
                    )}
                  </td>
                  {images && (
                    <td className="td">
                      <ImageLightbox images={filterImages(files)} group />
                    </td>
                  )}
                  <td className="td">
                    <CostEstimate buildingPart={buildingPart} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

interface WrapProps extends Props {
  session: Session;
}

const ApolloWrap: React.FC<WrapProps> = ({ session, report, ...rest }) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <ReportProvider report={{ ...report, id: report.id.toString() }}>
        <BuildingPartsPage {...rest} />
      </ReportProvider>
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

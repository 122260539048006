/**
 * a special file to prevent circular dependencies
 **/
import { gql } from "@apollo/client";
import { attachmentFragment } from "~/attachment";

export const buildingPartFragment = gql`
  fragment HousingMaintenanceBuildingPartFields on HousingMaintenanceBuildingPart {
    id
    costBlockTemplateId
    costBlockTemplateTitle
    costBlockTemplateNumber
    name
    tg
    kg
    rating
    period
    description
    descriptionDeviation
    comment
    order
    files {
      ...AttachmentFields
    }
  }
  ${attachmentFragment}
`;

export const buildingPartCostFragment = gql`
  fragment HousingMaintenanceBuildingPartCost on HousingMaintenanceBuildingPart {
    id
    costEstimate
    cost
  }
`;

export const buildingPartSummaryFragment = gql`
  fragment HousingMaintenanceBuildingPartSummary on HousingMaintenanceBuildingPart {
    id
    status
    budgetLinesCount
  }
`;

export const budgetLineFragment = gql`
  fragment HousingMaintenanceBudgetLineFields on HousingMaintenanceBudgetLine {
    id
    costLineTemplateId
    costLineTemplateRef
    costLineTemplateTitle
    unit
    unitPrice
    amount
    cost
    costActual
    costType
    description
    finishedAt
    note
    status
    files {
      ...AttachmentFields
    }
  }
  ${attachmentFragment}
`;

export const budgetPlanFragment = gql`
  fragment HousingMaintenanceBudgetPlanFields on HousingMaintenanceBudgetPlan {
    id
    housingMaintenanceSectionId
    userId
    name
    currentBudget
    collectedBudget
    interestRate
    unitType
    units
    period
  }
`;

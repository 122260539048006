import React from "react";
import { Col, Form as BSForm, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { NumberField, RedactorField, TextInput } from "~/form";
import AttachmentField from "~/form/attachment/AttachmentField";
import { ConstructionLoanPeriodPayout } from "~/graphql-operations";
import { Block } from "~/layout";
import { useToggle, useUpdateEffect } from "~/util";
import { formatCurrency } from "~/util/currency";

interface Props {
  payout: ConstructionLoanPeriodPayout;
}

const PayoutFields: React.FC<Props> = ({
  payout: { amount, manualAmount, files },
}) => {
  const { setValue } = useFormContext();
  const [manual, toggleManual] = useToggle(!!manualAmount);

  useUpdateEffect(() => {
    setValue("payout.manualAmount", manual ? manualAmount : null, {
      shouldDirty: true,
    });
  }, [manual, setValue]);

  return (
    <Block>
      <Row className="align-items-start">
        <Col lg="8">
          <RedactorField
            name="payout.information"
            label="Utbetalingsinformasjon"
          />
        </Col>
        <Col lg="4">
          <AttachmentField
            attachments={files}
            name="payout.files"
            label="Utbetalingsfiler"
            multiple
          />
        </Col>
      </Row>
      <Row className="align-items-center mt-3">
        <Col lg="3">
          <TextInput
            name="of-no-importance"
            label="Sum fra fakturaer"
            value={formatCurrency(amount)}
            append="NOK"
            disabled
          />
        </Col>
        <Col lg="auto">
          <BSForm.Check
            custom
            id="period-payout-checkbox"
            type="switch"
            label="Tilpass"
            checked={manual}
            onChange={toggleManual}
            style={{
              marginTop: "1rem",
            }}
          />
        </Col>
        <Col lg="3">
          {manual && (
            <NumberField
              name="payout.manualAmount"
              label="Utbetaling"
              disabled={!manual}
              placeholder={amount}
              append="NOK"
            />
          )}
        </Col>
      </Row>
    </Block>
  );
};

export default PayoutFields;

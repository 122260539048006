import React from "react";
import { Col } from "react-bootstrap";
import { Fieldset, Form, Row, SaveFormButton, TextField } from "~/form";
import { Block } from "~/layout";
import { useCreateRequestLineTemplate } from ".";

const NewLineTemplate: React.FC = () => {
  const create = useCreateRequestLineTemplate();

  return (
    <Form onSubmit={create} resetBlank horizontal>
      <Block>
        <Fieldset title="New Request Line Template">
          <Row>
            <Col lg="2">
              <TextField name="number" label="Nummer" required />
            </Col>
            <Col>
              <TextField name="title" label="Tittel" required />
            </Col>
            <Col lg="2">
              <SaveFormButton />
            </Col>
          </Row>
        </Fieldset>
      </Block>
    </Form>
  );
};

export default NewLineTemplate;

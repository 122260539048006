import React from "react";
import styled from "styled-components";
import { Attachment } from "~/graphql-operations";
import { Card, DeleteButton } from "~/layout";
import Icon, { StyledIcon } from "~/layout/icon";
import { contentTypeToIcon } from ".";

// type AttachmentProp = Pick<
//   Attachment,
//   | "id"
//   | "contentType"
//   | "view"
//   | "download"
//   | "filename"
//   | "signedId"
//   | "createdAt"
//   | "uploadedBy"
// >;

export interface AttachmentFileProps {
  attachment: Attachment;
  disposition?: "inline" | "attachment";
  className?: string;
  audited?: boolean;
  card?: boolean;
  onDelete?: (attachment?: Attachment) => Promise<unknown>;
}

const ButtonWrap = styled.div`
  /* position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, -50%); */
  opacity: 0;
`;

const Wrap = styled.div<{ $actions?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin: ${({ $actions }) => ($actions ? "-0.25rem" : "0.5rem")} 0;

  &:hover ${ButtonWrap} {
    opacity: 1;
  }
`;

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  color: var(--dark-gray);
  font-size: 0.875rem;
  line-height: 1.25;

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    text-decoration: none;
  }

  /* span {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: underline;
  }
 */
  ${StyledIcon} {
    flex-shrink: 0;
    margin-right: 0.25rem;
  }
`;

const FilenameWrap = styled.span`
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;

const ButtonsWrap = styled.div`
  /* position: relative; */
`;

const StyledCard = styled(Card)`
  .card-content {
    padding: 0.5rem;
  }
`;

const AttachmentFile: React.FC<AttachmentFileProps> = ({
  attachment,
  attachment: { id, filename, contentType, view, download },
  onDelete,
  disposition = "inline",
  card = false,
  className,
  children,
}) => {
  const File = () => (
    <>
      <StyledLink
        href={(disposition === "attachment" ? download : view) || ""}
        className={className}
        target={disposition === "inline" ? "_blank" : undefined}
      >
        <Icon icon={contentTypeToIcon(contentType)} />
        <FilenameWrap>{filename}</FilenameWrap>
      </StyledLink>

      {children}

      {onDelete && (
        <ButtonsWrap>
          <ButtonWrap>
            <DeleteButton
              size="sm"
              onClick={() => onDelete(attachment)}
              iconOnly
            />
          </ButtonWrap>
        </ButtonsWrap>
      )}
    </>
  );

  if (card) {
    return (
      <StyledCard>
        <File />
      </StyledCard>
    );
  }

  return (
    <Wrap $actions={!!onDelete} id={`attachment-list-${id}`}>
      <File />
    </Wrap>
  );
};

export default AttachmentFile;

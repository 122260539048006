import { DirectUpload } from "@rails/activestorage";
import { Attachment } from "~/attachment";
import { ACTIVESTORAGE_DIRECT_UPLOADS_PATH } from "~/setup";

// export const attachmentToInput = (
//   attachment: Attachment | null
// ): string | null => attachment && attachment.signedId;

export function attachmentInput(attachments: Attachment[]): string[];
export function attachmentInput(attachments: Attachment): string;
export function attachmentInput(
  attachments: Attachment | Attachment[]
): string | string[] {
  return Array.isArray(attachments)
    ? attachments.map((att) => att.signedId)
    : attachments.signedId;
}

// type AttachmentInputType = {
//   (attachments: Attachment[]): string[];
//   (attachments: Attachment): string;
// };
// export const attachmentInput: AttachmentInputType = (attachments) => {
//   return Array.isArray(attachments)
//     ? attachments.map((att) => att.signedId)
//     : attachments.signedId;
// };

// https://edgeguides.rubyonrails.org/active_storage_overview.html#integrating-with-libraries-or-frameworks
const delegate = (progressHandler: progressHandler) => {
  return {
    directUploadWillStoreFileWithXHR: (xhr: XMLHttpRequest) => {
      xhr.upload.addEventListener(
        "progress",
        ({ loaded, total }: ProgressEvent) => {
          const progress = (loaded / total) * 100;
          progressHandler(progress);
        }
      );
    },
  };
};

type progressHandler = (progress: number) => void;

export const uploadFile = (
  file: File,
  progressHandler: progressHandler
): Promise<string> => {
  const upload = new DirectUpload(
    file,
    ACTIVESTORAGE_DIRECT_UPLOADS_PATH,
    progressHandler ? delegate(progressHandler) : undefined
  );
  return new Promise((resolve, reject) => {
    upload.create((error, blob) => {
      if (error) reject(error);
      else resolve(blob.signed_id);
    });
  });
};

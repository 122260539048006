import React, { useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { IconButton } from "~/layout";
import { BlockListHeader } from "~/layout/BlockList";
import { BuildingPartListExpandable, BuildingPartListSortable } from ".";
import { HousingMaintenanceSection } from "../constants";
import { EditBuildingPartProps } from "./EditBuildingPart";

interface Props {
  housingMaintenanceSection: HousingMaintenanceSection;
  buildingPartOptions: EditBuildingPartProps["options"];
  buildingPartRatingRules: EditBuildingPartProps["rules"];
}

type State = "expandable" | "sortable";

const EditBuildingPartList: React.FC<Props> = ({
  housingMaintenanceSection: { extended, buildingParts },
  buildingPartOptions,
  buildingPartRatingRules,
}) => {
  const [state, setState] = useState<State>("expandable");

  const sortedBuildingParts = [...buildingParts].sort(
    (a, b) => a.order - b.order
  );

  const onSortable = () => setState("sortable");
  const onExpandable = () => setState("expandable");

  const getButtonVariant = (buttonState: State) =>
    state === buttonState ? "dark" : "light";

  return (
    <>
      <BlockListHeader>
        <Row className="align-items-center">
          <Col lg="6">Bygningsdel</Col>
          <Col lg="1">TG</Col>
          <Col lg="1">KG</Col>
          <Col lg="1" className={"text-center"}>
            Karakter
          </Col>
          <Col lg="1">Type/Tidsrom</Col>
          <Col className="text-right">
            <ButtonGroup>
              <IconButton
                icon="edit"
                variant={getButtonVariant("expandable")}
                onClick={onExpandable}
              />
              <IconButton
                icon="move"
                variant={getButtonVariant("sortable")}
                onClick={onSortable}
              />
            </ButtonGroup>
          </Col>
        </Row>
      </BlockListHeader>

      {state === "sortable" ? (
        <BuildingPartListSortable
          buildingParts={sortedBuildingParts}
          rules={buildingPartRatingRules}
          options={buildingPartOptions}
          budget={extended}
        />
      ) : (
        <BuildingPartListExpandable
          buildingParts={sortedBuildingParts}
          rules={buildingPartRatingRules}
          options={buildingPartOptions}
          budget={extended}
        />
      )}
    </>
  );
};

export default EditBuildingPartList;

import styled, { css } from "styled-components";

import { Table as BootstrapTable } from "react-bootstrap";
import React from "react";

const Table = styled(BootstrapTable)<{
  costs?: boolean;
  children: React.ReactNode;
}>`
  width: 100%;
  margin-bottom: 90px;

  ${({ costs }) =>
    costs &&
    css`
      background-color: var(--smoke);
    `}
`;

export default Table;

import { useMutation } from "@apollo/client";
import { AttachableInput } from "~/form/attachment";
import { CostLine, COST_LINE_UPDATE_MUTATION } from ".";

type CostLineInput = Partial<CostLine> & Pick<CostLine, "id">;
export type CostLineUpdatePayload = AttachableInput<CostLineInput, "image">;

export const useCostLineUpdate = () => {
  const [mutation] = useMutation(COST_LINE_UPDATE_MUTATION);

  return async (input: CostLineUpdatePayload) => {
    const { data } = await mutation({ variables: { input } });
    const errors = data?.costLineUpdate?.errors || [];

    if (errors.length > 0) alert(errors.join("\n"));

    return {
      costLine: data?.costLineUpdate?.costLine,
      errors,
    };
  };
};

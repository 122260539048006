import React, { ReactNode } from "react";
import styled from "styled-components";
import IndicatorLine from "./IndicatorLine";

interface Props {
  title?: string;
  subtitle?: string | ReactNode;
  active?: boolean;
  finished?: boolean;
}

export const StyledStep = styled.div<Props>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
`;

const StepTitle = styled.h3`
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const StepInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  font-family: var(--font-moon);
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const Step: React.FC<Props> = ({
  title,
  subtitle,
  active = false,
  finished = false,
  children,
}) => {
  return (
    <StyledStep>
      <StepTitle>{title}</StepTitle>
      <IndicatorLine active={active} finished={finished} />
      {subtitle && <StepInfo>{subtitle}</StepInfo>}
      {children}
    </StyledStep>
  );
};

export default Step;

import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToggleButton } from "~/layout";
import Block from "~/layout/Block";
import { Report } from "~/report";
import ReportProvider from "~/report/ReportProvider";
import { Session, SessionProvider } from "~/session";
import { client } from "~/setup";
import {
  HousingMaintenanceSection,
  HOUSING_MAINTENANCE_SECTION_BUILDING_PARTS_ADMIN_QUERY,
  updateMutation,
} from ".";
import { EditBuildingPartList, NewBuildingPart } from "./building-part";

interface Props {
  report: Report;
  housingMaintenanceSection: HousingMaintenanceSection;
}

const HousingMaintenanceSectionPage: React.FC<Omit<Props, "report">> = ({
  housingMaintenanceSection: { id },
}) => {
  const update = updateMutation(id);
  const { loading, error, data } = useQuery(
    HOUSING_MAINTENANCE_SECTION_BUILDING_PARTS_ADMIN_QUERY,
    {
      variables: { id },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    housingMaintenanceSection,
    housingMaintenanceBuildingPartOptions,
    housingMaintenanceBuildingPartRatingRules,
    housingMaintenanceSection: { extended },
  } = data;

  const toggleExtended = () => {
    return update({
      id,
      extended: !extended,
    });
  };

  return (
    <>
      <Row className="text-right">
        <Col lg="12">
          <ToggleButton active={extended} onClick={toggleExtended} />
        </Col>
      </Row>
      <hr />
      <EditBuildingPartList
        buildingPartOptions={housingMaintenanceBuildingPartOptions}
        buildingPartRatingRules={housingMaintenanceBuildingPartRatingRules}
        housingMaintenanceSection={housingMaintenanceSection}
      />
      <hr />
      <Block>
        <NewBuildingPart
          housingMaintenanceSection={housingMaintenanceSection}
        />
      </Block>
    </>
  );
};

interface WrapProps extends Props {
  session: Session;
}

const ApolloWrap: React.FC<WrapProps> = ({ session, report, ...rest }) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <ReportProvider report={{ ...report, id: report.id.toString() }}>
        <HousingMaintenanceSectionPage {...rest} />
      </ReportProvider>
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

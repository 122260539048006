import React from "react";
import {
  InvoiceCategoryTypeEnum,
  InvoiceEntryCategory,
} from "~/graphql-operations";
import { invoiceCategoryTypeOptions } from "~/invoice/category-type";
import {
  CategoryForm,
  useDeleteInvoiceEntryCategory,
  useUpdateInvoiceEntryCategory,
} from ".";

interface Props {
  category: InvoiceEntryCategory;
}

const EditCategory: React.FC<Props> = ({ category, category: { type } }) => {
  const updateMutation = useUpdateInvoiceEntryCategory();
  const deleteMutation = useDeleteInvoiceEntryCategory();

  const typeOptions = invoiceCategoryTypeOptions.filter(({ value }) =>
    (type === InvoiceCategoryTypeEnum.Additional
      ? [InvoiceCategoryTypeEnum.Additional]
      : [
          InvoiceCategoryTypeEnum.Engineering,
          InvoiceCategoryTypeEnum.Production,
        ]
    ).includes(value)
  );

  return (
    <CategoryForm
      category={category}
      onSubmit={updateMutation}
      onDelete={deleteMutation}
      typeOptions={typeOptions}
      typeLocked={type === InvoiceCategoryTypeEnum.Additional}
    />
  );
};

export default EditCategory;

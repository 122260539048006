import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";
import { attachmentAuditFragment, attachmentFragment } from "./fragments";

export interface Attachment<T = ResourceInterface> extends ResourceInterface {
  byteSize: number;
  checksum: string;
  contentType: ContentType;
  download: string;
  filename: string;
  key: string;
  record: T;
  signedId: string;
  variants?: ImageVariants;
  view: string;
  metadata: {
    identified: boolean;
    analyzed: boolean;
    width: string;
    height: string;
  };
}

export interface ImageAttachment extends Attachment {
  variants: ImageVariants;
}

export interface ImageVariants {
  small: string;
  small2x: string;
  large: string;
  large2x: string;
  xlarge: string;
  xlarge2x: string;
  original: string;
}

export type ContentType =
  | "video/x-msvideo"
  | "text/csv"
  | "application/msword"
  | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  | "application/gzip"
  | "image/gif"
  | "text/html"
  | "text/html"
  | "image/vnd.microsoft.icon"
  | "image/jpeg"
  | "image/jpeg"
  | "application/json"
  | "audio/mpeg"
  | "video/mpeg"
  | "application/vnd.oasis.opendocument.presentation"
  | "application/vnd.oasis.opendocument.spreadsheet"
  | "application/vnd.oasis.opendocument.text"
  | "image/png"
  | "application/pdf"
  | "application/vnd.ms-powerpoint"
  | "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  | "application/vnd.rar"
  | "application/rtf"
  | "image/svg+xml"
  | "application/x-tar"
  | "image/tiff"
  | "font/ttf"
  | "text/plain"
  | "application/vnd.visio"
  | "audio/wav"
  | "application/xhtml+xml"
  | "application/vnd.ms-excel"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  | "application/xml"
  | "application/zip"
  | "application/x-7z-compressed";

export type FileExtension =
  | "avi"
  | "csv"
  | "doc"
  | "docx"
  | "gz"
  | "gif"
  | "htm"
  | "html"
  | "ico"
  | "jpeg"
  | "jpg"
  | "json"
  | "mp3"
  | "mpeg"
  | "odp"
  | "ods"
  | "odt"
  | "png"
  | "pdf"
  | "ppt"
  | "pptx"
  | "rar"
  | "rtf"
  | "svg"
  | "tar"
  | "tif"
  | "tiff"
  | "ttf"
  | "txt"
  | "vsd"
  | "wav"
  | "xhtml"
  | "xls"
  | "xlsx"
  | "xml"
  | "zip"
  | "7z";

// export const NODE_FILE_VERSIONS_QUERY = gql`
//   query NodeFileVersions($id: ID!) {
//     node(id: $id) {
//       id
//       ... on FileVersionable {
//         file {
//           ...AttachmentFields
//           ...AttachmentAudit
//         }
//         fileVersions {
//           ...AttachmentFields
//           ...AttachmentAudit
//         }
//       }
//     }
//   }
//   ${attachmentFragment}
//   ${attachmentAuditFragment}
// `;

export const NODE_FILE_VERSION_DELETE_MUTATION = gql`
  mutation NodeFileVersionDelete($input: NodeAttachmentDeleteInput!) {
    nodeAttachmentDelete(input: $input) {
      node {
        id
        ... on FileVersionable {
          file {
            ...AttachmentFields
          }
          fileVersions {
            ...AttachmentFields
            ...AttachmentAudit
          }
        }
      }
      errors
    }
  }
  ${attachmentFragment}
  ${attachmentAuditFragment}
`;

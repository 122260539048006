import React from "react";
import styled from "styled-components";
import { FieldsetTitle } from "~/form";
import {
  ConstructionLoan,
  usePeriodsLoanAmountQuery,
} from "~/graphql-operations";
import { Block } from "~/layout";
import ErrorAlert from "~/layout/ErrorAlert";
import EditLoan from "./EditLoan";

interface Props {
  report: Pick<ConstructionLoan, "id">;
}

const StyledBlock = styled(Block)`
  form:nth-of-type(n + 2) {
    label {
      display: none;
    }
  }
`;

const EditLoanList: React.FC<Props> = ({ report: { id } }) => {
  const { error, data } = usePeriodsLoanAmountQuery({
    variables: { id },
  });
  if (error) return <ErrorAlert error={error} />;

  const periods = data?.constructionLoan?.periods || [];

  return (
    <StyledBlock>
      <FieldsetTitle>Endring av lånebeløp og Egenkapital</FieldsetTitle>
      {periods.map((period) => (
        <EditLoan key={period.id} period={period} />
      ))}
    </StyledBlock>
  );
};

export default EditLoanList;

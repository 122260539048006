import gql from "graphql-tag";

export const reportFieldsFragment = gql`
  fragment ReportFields on Report {
    id
    name
    address
    number
    company {
      id
      name
    }
    lockedAt
    urls {
      show
      edit
    }
  }
`;

export const reportCompanyFragment = gql`
  fragment ReportCompanyFields on Report {
    id
    name
    company {
      id
      name
    }
    lockedAt
  }
`;

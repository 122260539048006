import { useMutation } from "@apollo/client";
import {
  CreateRequestLineTemplateInput,
  RequestLineTemplate,
  REQUEST_LINE_TEMPLATES_QUERY,
  REQUEST_LINE_TEMPLATE_CREATE_MUTATION,
  REQUEST_LINE_TEMPLATE_DELETE_MUTATION,
  REQUEST_LINE_TEMPLATE_UPDATE_MUTATION,
  UpdateRequestlineTemplateInput,
} from ".";

export const useCreateRequestLineTemplate = () => {
  const [createRequestLineTemplate] = useMutation<{
    createRequestLineTemplate: { requestLineTemplate: RequestLineTemplate[] };
  }>(REQUEST_LINE_TEMPLATE_CREATE_MUTATION);
  return (input: CreateRequestLineTemplateInput) => {
    return createRequestLineTemplate({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation Failed");
        const newRequestLineTemplate =
          data?.createRequestLineTemplate.requestLineTemplate;

        const cacheData = cache.readQuery<{
          requestLineTemplates: RequestLineTemplate[];
        }>({
          query: REQUEST_LINE_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { requestLineTemplates } = cacheData;

        cache.writeQuery({
          query: REQUEST_LINE_TEMPLATES_QUERY,
          data: {
            requestLineTemplates: [
              ...requestLineTemplates,
              newRequestLineTemplate,
            ],
          },
        });
      },
    });
  };
};

export const useUpdateRequestLineTemplate = () => {
  const [updateRequestLineTemplate] = useMutation(
    REQUEST_LINE_TEMPLATE_UPDATE_MUTATION
  );
  return (input: UpdateRequestlineTemplateInput) => {
    return updateRequestLineTemplate({
      variables: {
        input,
      },
    });
  };
};

export const useDeleteRequestLineTemplate = () => {
  const [deleteRequestLineTemplate] = useMutation(
    REQUEST_LINE_TEMPLATE_DELETE_MUTATION
  );
  return (id: string) => {
    return deleteRequestLineTemplate({
      variables: {
        input: { id },
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation Failed");

        const cacheData = cache.readQuery<{
          requestLineTemplates: RequestLineTemplate[];
        }>({
          query: REQUEST_LINE_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { requestLineTemplates } = cacheData;

        cache.writeQuery({
          query: REQUEST_LINE_TEMPLATES_QUERY,
          data: {
            requestLineTemplates: requestLineTemplates.filter(
              (requestLineTemplate) => requestLineTemplate.id !== id
            ),
          },
        });
      },
    });
  };
};

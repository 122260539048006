import { nodeAttributeLabel } from "~/node";
import { KG, Rating, TG } from "./constants";

export const maintenancePeriodOptions = [
  { value: "none", label: "-" },
  { value: "1_5_years", label: "1-5 år" },
  { value: "6_10_years", label: "6-10 år" },
  { value: "immediate", label: "Strakstiltak" },
];

export const ratingOptions = [
  { value: Rating.A, label: "A" },
  { value: Rating.B, label: "B" },
  { value: Rating.C, label: "C" },
  { value: Rating.D, label: "D" },
  { value: Rating.E, label: "E" },
  { value: Rating.F, label: "F" },
];

export const TGOptions = [
  { value: TG.IU, label: "IU" },
  { value: TG.ZERO, label: "0" },
  { value: TG.ONE, label: "1" },
  { value: TG.TWO, label: "2" },
  { value: TG.THREE, label: "3" },
];

export const KGOptions = [
  { value: KG.IA, label: "IA" },
  { value: KG.ZERO, label: "0" },
  { value: KG.ONE, label: "1" },
  { value: KG.TWO, label: "2" },
  { value: KG.THREE, label: "3" },
];

export const periodTypeLabel = nodeAttributeLabel(maintenancePeriodOptions);

export const ratingTypeLabel = nodeAttributeLabel(ratingOptions);

export const TGTypeLabel = nodeAttributeLabel(TGOptions);

export const KGTypeLabel = nodeAttributeLabel(KGOptions);

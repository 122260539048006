import { MutationUpdaterFn, Reference, useMutation } from "@apollo/client";
import {
  BuildingPart,
  BUILDING_PART_CREATE_MUTATION,
  BUILDING_PART_DELETE_MUTATION,
  BUILDING_PART_UPDATE_MUTATION,
} from ".";
import { buildingPartFragment, HousingMaintenanceSection } from "..";

export const createMutation = (
  housingMaintenanceSection: HousingMaintenanceSection
) => {
  const [create] = useMutation(BUILDING_PART_CREATE_MUTATION);

  return (input: Partial<BuildingPart>) =>
    create({
      variables: {
        input: {
          ...input,
          housingMaintenanceSectionId: housingMaintenanceSection.id,
        },
      },
      update: (cache, { data: { housingMaintenanceBuildingPartCreate } }) => {
        const cacheId = cache.identify(housingMaintenanceSection);
        if (!cacheId)
          console.log("No cache found for:", housingMaintenanceSection);

        const newRef = cache.writeFragment({
          data: housingMaintenanceBuildingPartCreate,
          fragment: buildingPartFragment,
          fragmentName: "HousingMaintenanceBuildingPartFields",
        });
        cache.modify({
          id: cacheId,
          fields: {
            buildingParts: (refs: Reference[]) => [...refs, newRef],
          },
        });
      },
    });
};

export const updateMutation = (id: string) => {
  const [update] = useMutation(BUILDING_PART_UPDATE_MUTATION);

  return (input: Partial<BuildingPart>, cache: MutationUpdaterFn) => {
    return update({
      variables: {
        input: {
          ...input,
          id,
        },
      },
      update: cache,
    });
  };
};

export const deleteMutation = () => {
  const [mutation] = useMutation(BUILDING_PART_DELETE_MUTATION);

  return (id: string) =>
    mutation({
      variables: {
        input: {
          id,
        },
      },
      update: (
        cache,
        {
          data: {
            housingMaintenanceBuildingPartDelete: { housingMaintenanceSection },
          },
        }
      ) => {
        cache.modify({
          id: cache.identify(housingMaintenanceSection),
          fields: {
            buildingParts: (refs, { readField }) =>
              refs.filter((ref: Reference) => id !== readField("id", ref)),
          },
        });
      },
    });
};

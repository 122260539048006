import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from "./Shortcode";
import { WordLoader } from "~/layout/Loading";

const REPORT_CONSTRUCTION_YEAR_QUERY = gql`
  query EiendomByggear($slug: ID!) {
    report(slug: $slug) {
      id
      constructionYear
    }
  }
`;

const EiendomByggear: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery(REPORT_CONSTRUCTION_YEAR_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  const { constructionYear } = data?.report || {};

  return (
    <>{loading ? <WordLoader /> : constructionYear && `${constructionYear}`}</>
  );
};

export default EiendomByggear;

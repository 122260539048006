import { ApolloProvider } from "@apollo/client";
import React from "react";
import { DocumentList, NewDocument } from "~/Document";
import { IconButton } from "~/layout";
import { Session, SessionProvider, useSession } from "~/session";
import { client } from "~/setup";
import { EditDocumentsComment, useConstructionLoanDocuments } from ".";

interface Props {
  report: {
    id: string;
    slug: string;
  };
}

interface WrapProps extends Props {
  session: Session;
}

const DocumentsPage: React.FC<Props> = ({ report: { id, slug } }) => {
  const session = useSession();
  const {
    loading,
    error,
    documents,
    documentsComment,
  } = useConstructionLoanDocuments(slug, session);

  if (error) return <p>Error :(</p>;

  if (loading)
    return (
      <>
        <p>Loading...</p>
        <NewDocument reportId={id} />
      </>
    );

  return (
    <>
      {documents && (
        <div className="text-right">
          <IconButton
            icon="download"
            size="sm"
            onClick={() =>
              (window.location.href = `/construction_loans/${slug}/documents-download`)
            }
          >
            Last ned alle
          </IconButton>
        </div>
      )}
      {documents && <DocumentList documents={documents} />}

      <NewDocument reportId={id} />

      <EditDocumentsComment report={{ id, documentsComment }} />
    </>
  );
};

const ApolloWrap: React.FC<Props> = ({ session, ...rest }: WrapProps) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <DocumentsPage {...rest} />
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

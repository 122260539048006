import { useAutoAnimate } from "@formkit/auto-animate/react";
import React from "react";
import { Permission } from "~/Membership";
import { EditableMember, EditMemberLine } from ".";

export interface Props {
  members: EditableMember[];
  disabledPermissions?: (m: EditableMember) => Permission[] | undefined;
}

const EditMemberList: React.FC<Props> = ({ members, disabledPermissions }) => {
  const [parent] = useAutoAnimate<HTMLDivElement>();
  return (
    <div ref={parent}>
      {members.map((member) => (
        <EditMemberLine
          key={member.membership.id}
          member={member}
          disabledPermissions={
            disabledPermissions && disabledPermissions(member)
          }
        />
      ))}
    </div>
  );
};

export default EditMemberList;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Col, Form as BSForm, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { NumberFormatProps } from "react-number-format";
import { CheckboxField, Fieldset, Form, NumberField, TextField } from "~/form";
import NumberInput from "~/form/NumberInput";
import { Building, BuildingPart } from "~/graphql-operations";
import { Block } from "~/layout";
import { useUpdateEffect } from "~/util";
import { BUILDING_UPDATE_MUTATION, toFormData } from ".";
import { BuildingFormPartsArray, BuildingTypeSelectField } from "./Form";
import NewBuildingPart from "./NewBuildingPart";

interface Props {
  building: Building;
}

// TODO: has a bug! with autosave when you change a value and save and then change it back to the original value, it will not save it
// with full reset cursor loses focus in parts array https://github.com/react-hook-form/react-hook-form/issues/3719
const EditBuilding: React.FC<Props> = ({ building, building: { id } }) => {
  const [area, setArea] = useState<number>(building.area || 0);
  const [updateBuilding] = useMutation(BUILDING_UPDATE_MUTATION);

  const formMethods = useForm({
    defaultValues: toFormData(building),
    mode: "all",
  });
  const { control, watch } = formMethods;

  const fieldArray = useFieldArray({
    control,
    name: "parts",
    keyName: "_id",
  });
  const { append } = fieldArray;

  const [autoTotal, parts] = watch(["autoTotal", "parts"]);
  const hasParts = (parts || []).length > 0;

  const totalArea = () =>
    (parts || []).map((p) => +(p.area || 0)).reduce((a, b) => a + b, 0);

  useUpdateEffect(() => {
    // if (autoTotal) setValue("area", parseFloat(totalArea().toFixed(1)), { shouldDirty: true });
    if (autoTotal) setArea(totalArea());
  }, [autoTotal, totalArea()]);

  useUpdateEffect(() => {
    if (!autoTotal) onSubmit({});
  }, [area, autoTotal]);

  const onAreaChange: NumberFormatProps["onValueChange"] = ({ floatValue }) => {
    setArea(floatValue || 0);
  };

  const onSubmit = (input: Partial<Building>) => {
    return updateBuilding({
      variables: {
        input: {
          ...input,
          id,
          area: autoTotal ? totalArea() : area,
        },
      },
    });
  };

  const onAddPart = (input: BuildingPart) => {
    append(input);
    return Promise.resolve(input);
  };

  // https://github.com/react-hook-form/react-hook-form/issues/3719
  return (
    <>
      <Form onSubmit={onSubmit} formMethods={formMethods} autoSave>
        <Row className="align-items-end">
          <Col lg="4">
            <TextField name="name" label="Navn" required />
          </Col>
          <Col lg="4">
            <BuildingTypeSelectField
              name="buildingTypeId"
              label="Type"
              required
            />
          </Col>
          <Col lg="2">
            <BSForm.Group>
              <BSForm.Label>
                BTA (M<sup>2</sup>)
              </BSForm.Label>
              <NumberInput
                // type={autoTotal ? "text" : "number"}
                value={area}
                onValueChange={onAreaChange}
                disabled={autoTotal}
              />
              {/* <BSForm.Control
                type={autoTotal ? "text" : "number"}
                step={0.1}
                value={autoTotal ? area : parseFloat(area)}
                onChange={onAreaChange}
                disabled={autoTotal}
              /> */}
            </BSForm.Group>
            {/* <NumberField
              name="area"
              label={
                <>
                  BTA (M<sup>2</sup>)
                </>
              }
              step={0.1}
              // disabled={values.autoTotal}
            /> */}
          </Col>
          <Col lg="2">
            <NumberField name="constructionYear" label="Byggeår" />
          </Col>
        </Row>
        <Row className="align-items-start">
          <Col lg="3">
            <TextField name="gnr" label="GNR" />
          </Col>
          <Col lg="3">
            <TextField name="bnr" label="BNR" />
          </Col>
          <Col lg="3">
            <TextField name="snr" label="SNR" />
          </Col>
          <Col lg="3">
            <TextField name="fnr" label="FNR" />
          </Col>
        </Row>
        <Fieldset title="Arealer tabell">
          <BuildingFormPartsArray fieldArray={fieldArray} />
          {hasParts && (
            <CheckboxField
              name="autoTotal"
              label="Automatisk arealberegning"
              style={{ margin: "1rem 0" }}
            />
          )}
        </Fieldset>
        <TextField name="extraInfo" label="Tilleggsinformasjon" />
      </Form>

      <Block>
        <NewBuildingPart onSubmit={onAddPart} id={`building-${id}-new-part`} />
      </Block>
    </>
  );
};

export default EditBuilding;

import React from "react";
import { useSetSectionVisibilityMutation } from "~/graphql-operations";
import { ToggleButton } from "~/layout";
import { SectionItem } from "./Item";

const VisibilityToggle = ({
  section: { id, visible },
}: {
  section: SectionItem;
}) => {
  const [setVisibility] = useSetSectionVisibilityMutation();
  const handleVisibility = () => {
    setVisibility({
      variables: {
        input: {
          id,
          visible: !visible,
        },
      },
    });
  };

  return (
    <ToggleButton active={visible} onClick={handleVisibility} variant="link" />
  );
};

export default VisibilityToggle;

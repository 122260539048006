import { FetchResult } from "@apollo/client";
import parse from "html-react-parser";
import { map } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export * from "./String";

export const label = (enumMap: Record<string, string>) => {
  return (value: keyof typeof enumMap) => enumMap[value];
};

export const labelsToOptions = (rec: Record<string, string>) =>
  Object.entries(rec).map(([value, label]) => ({ value, label }));

type StandardFetchResult = FetchResult<{
  [key: string]: unknown;
  errors?: string[];
}>;

export const mutationErrors = (result: StandardFetchResult) =>
  map(result.data, "errors")
    .flat()
    .filter((val) => !!val) as string[];

export const mutationSuccess = (result: StandardFetchResult) =>
  mutationErrors(result).length === 0;

export const parseDate = (date?: string): Moment | null =>
  moment(date, "YYYY-MM-DD", true).isValid() ? moment.utc(date) : null;

export const stripTags = (html: string | null) =>
  html?.replace(/(<([^>]+)>)/gi, "");

export const useModal = () => {
  const isMounted = useMounted();
  const [active, setShow] = useState(false);

  const close = () => isMounted() && setShow(false);
  const show = () => setShow(true);

  return { active, show, close };
};

export const useMounted = () => {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return () => isMountedRef.current;
};

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * Idea stolen from: https://stackoverflow.com/a/55075818/1526448
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
export const useUpdateEffect = (
  effect: () => void,
  dependencies: unknown[] = []
): void => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, dependencies);
};

export const useDeepUpdateEffect: typeof useUpdateEffect = (
  effect,
  dependencies
) => {
  const isInitialMount = useRef(true);

  useDeepCompareEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, dependencies);
};

type Orderable = {
  order: number;
};
type sortByOrder = (a: Orderable, b: Orderable) => 1 | -1;
export const sortByOrder: sortByOrder = (a, b) => (a.order > b.order ? 1 : -1);

export const useToggle = (toggleInitial: boolean) => {
  const [toggled, setToggled] = useState(toggleInitial);
  const toggle = () => setToggled(!toggled);
  return [toggled, toggle] as [boolean, () => void];
};

export const parseHtml = (children: string) => {
  if (typeof children !== "string") return null;
  return parse(children);
};

import React, { useEffect, useRef, useState } from "react";
import { ButtonProps } from "react-bootstrap";
import styled, { css } from "styled-components";
import IconButton from "~/layout/Button/IconButton";
import { StyledIcon } from "~/layout/icon";
import { Icons } from "~/layout/icon/constants";

export interface DeleteButtonProps extends ButtonProps {
  onClick: (args?: unknown) => Promise<unknown> | void;
  icon?: Icons;
  iconOnly?: boolean;
  noConfirm?: boolean;
  inverse?: boolean;
}

const TextButton = styled(IconButton)<DeleteButtonProps>`
  display: inline-flex;
  opacity: ${({ disabled }) => (disabled ? "0.67" : "1")};

  /* ${({ iconOnly }) =>
    iconOnly &&
    css`
      &:hover {
        background-color: var(--danger);
      }
    `}
 */
  ${StyledIcon} {
    margin-right: ${({ $noLabel }) => ($noLabel ? "0" : "0.75rem")};
    background-color: var(--white);
    ${({ $inverse }) => $inverse && "background-color: var(--yellow)"};
  }
`;

const IconOnlyButton = styled(IconButton)`
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.33 !important;
        `
      : css`
          &:hover {
            background-color: var(--danger);
            box-shadow: 1px 3px 16px rgba(0, 0, 0, 0.25);
            transform: scale(1.5);

            ${StyledIcon} {
              background-color: var(--white);
              transform: scale(0.667);
            }
          }
        `}
`;

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onClick,
  iconOnly = false,
  icon = "delete",
  noConfirm = false,
  disabled = false,
  inverse = false,
  children,
  ...rest
}) => {
  // prevent setState if component got unmounted - delete succeeded
  const isMountedRef = useRef(true);
  const [deleting, setDeleting] = useState(false);

  const onMyClick: ButtonProps["onClick"] = async (...args) => {
    if (args[0]?.target) args[0].stopPropagation();
    if (!noConfirm && !confirm("Er du sikker?")) return;
    setDeleting(true);
    await onClick(...args);
    if (isMountedRef.current) setDeleting(false);
  };

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <>
      {iconOnly ? (
        <IconOnlyButton
          icon={deleting ? "spinner" : icon}
          spin={deleting}
          // size="sm"
          variant="transparent"
          color={inverse ? "yellow" : undefined}
          onClick={onMyClick}
          disabled={disabled}
          $iconOnly={iconOnly}
          size={iconOnly ? "sm" : undefined}
          {...rest}
        />
      ) : (
        <TextButton
          icon={deleting ? "spinner" : icon}
          spin={deleting}
          variant="danger"
          onClick={onMyClick}
          disabled={disabled || deleting}
          $inverse={inverse}
          $noLabel={children === null}
          {...rest}
        >
          {typeof children === undefined ? "Delete" : children}
        </TextButton>
      )}
    </>
  );
};

export default DeleteButton;

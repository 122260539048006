import { pick } from "lodash";
import React from "react";
import { Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, Row, SelectField } from "~/form";
import { IconButton } from "~/layout";
import { BlockListItemData, BlockListItemTitle } from "~/layout/BlockList";
import Icon, { StyledIcon } from "~/layout/icon";
import { Grade } from "~/node/label";
import { StatusBadge } from "~/sections/cost-section/line";
import { WORK_STATUS } from "~/util/WorkStatus";
import { BuildingPart, handleOptions, updateMutation } from ".";
import { EditBuildingPartProps } from "./EditBuildingPart";

export interface BuildingPartListItemHeader {
  buildingPart: BuildingPart;
  mode: "expandable" | "sortable";
  options: EditBuildingPartProps["options"];
  rules: EditBuildingPartProps["rules"];
  budget?: boolean;
  onClick?: () => void;
}

const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  /* cursor: move; */
  ${StyledIcon} {
    margin-right: 1rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding-left: 0;
  padding-right: 0;
  cursor: move;
  ${StyledIcon} {
    margin-right: 1rem;
  }
`;

const Title = ({ status, name }: { status: WORK_STATUS; name: string }) => {
  return (
    <>
      {status && <StatusBadge status={status} className="mr-3" />}
      <BlockListItemTitle>{name}</BlockListItemTitle>
    </>
  );
};

const BuildingPartListItemHeader: React.FC<BuildingPartListItemHeader> = ({
  mode = "expandable",
  buildingPart,
  buildingPart: { id, name, rating, status },
  rules,
  options: allOptions,
  budget,
  onClick,
}) => {
  const formMethods = useForm({
    defaultValues: pick(buildingPart, ["tg", "kg", "period"]),
    mode: "onChange",
  });
  const options = handleOptions({
    formMethods,
    options: allOptions,
    rules,
  });
  const onSubmit = updateMutation(id);

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} autoSave horizontal>
      <Row className="align-items-center">
        <Col lg="6">
          {mode === "sortable" ? (
            <StyledWrap>
              <Icon icon="move" />
              <Title status={budget && status} name={name} />
            </StyledWrap>
          ) : (
            <StyledIconButton
              icon="chevronRight"
              size="sm"
              variant="link"
              onClick={onClick}
            >
              <Title status={budget && status} name={name} />
            </StyledIconButton>
          )}
        </Col>
        <Col lg="1">
          <SelectField name="tg" label={null} options={options.tg} />
        </Col>
        <Col lg="1">
          <SelectField name="kg" label={null} options={options.kg} />
        </Col>
        <Col lg="1" className="text-center">
          <BlockListItemData>
            <Grade type="simple" grade={rating} />
          </BlockListItemData>
        </Col>
        <Col lg="3">
          <SelectField name="period" label={null} options={options.period} />
        </Col>
      </Row>
    </Form>
  );
};

export default BuildingPartListItemHeader;

import { useMutation } from "@apollo/client";
import {
  BuildingPartRatingRule,
  BUILDING_PART_RATING_RULES_CREATE_MUTATION,
  BUILDING_PART_RATING_RULES_DELETE_MUTATION,
  BUILDING_PART_RATING_RULES_QUERY,
  NewBuildingPartRatingRuleInput,
} from ".";

export const useCreateBuildingPartRatingRule = () => {
  const [createBuildingPartRatingRule] = useMutation<{
    createHousingMaintenanceBuildingPartRatingRule: {
      housingMaintenanceBuildingPartRatingRule: BuildingPartRatingRule;
      errors: string[];
    };
  }>(BUILDING_PART_RATING_RULES_CREATE_MUTATION);
  return (input: NewBuildingPartRatingRuleInput) => {
    return createBuildingPartRatingRule({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation failed");
        const newHousingMaintenanceBuildingPartRatingRule =
          data?.createHousingMaintenanceBuildingPartRatingRule
            .housingMaintenanceBuildingPartRatingRule;
        const cacheData = cache.readQuery<{
          housingMaintenanceBuildingPartRatingRules: BuildingPartRatingRule[];
        }>({
          query: BUILDING_PART_RATING_RULES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { housingMaintenanceBuildingPartRatingRules } = cacheData;
        cache.writeQuery({
          query: BUILDING_PART_RATING_RULES_QUERY,
          data: {
            housingMaintenanceBuildingPartRatingRules: [
              ...housingMaintenanceBuildingPartRatingRules,
              newHousingMaintenanceBuildingPartRatingRule,
            ],
          },
        });
      },
    });
  };
};

export const useDeleteBuildingPartRatingRule = () => {
  const [deleteBuildingPartRatingRule] = useMutation(
    BUILDING_PART_RATING_RULES_DELETE_MUTATION
  );
  return (id: string) => {
    return deleteBuildingPartRatingRule({
      variables: {
        input: { id },
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation faild");
        const cacheData = cache.readQuery<{
          housingMaintenanceBuildingPartRatingRules: BuildingPartRatingRule[];
        }>({
          query: BUILDING_PART_RATING_RULES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { housingMaintenanceBuildingPartRatingRules } = cacheData;
        cache.writeQuery({
          query: BUILDING_PART_RATING_RULES_QUERY,
          data: {
            housingMaintenanceBuildingPartRatingRules: housingMaintenanceBuildingPartRatingRules.filter(
              (buildingPart) => buildingPart.id !== id
            ),
          },
        });
      },
    });
  };
};

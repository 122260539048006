import { ApolloProvider } from "@apollo/client";
import invariant from "invariant";
import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  RequestLineTemplate,
  useRequestLineTemplatesQuery,
} from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import { BlockTitle, List } from "~/layout/block-list";
import { Block, Body, Header } from "~/layout/block-list/collapsible";
import { client } from "~/setup";
import { EditLineTemplate, NewLineTemplate } from ".";
import { useDeleteRequestLineTemplate } from "./hooks";

const LineTemplateListPage: React.FC = () => {
  const deleteTemplate = useDeleteRequestLineTemplate();

  const {
    loading,
    error,
    data: { requestLineTemplates } = {},
  } = useRequestLineTemplatesQuery();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  invariant(requestLineTemplates, "No requestLineTemplates");

  return (
    <>
      <List>
        {requestLineTemplates.map(
          ({ id, title, number }: RequestLineTemplate) => {
            return (
              <Block key={id} id={`request-line-template-${id}`}>
                <Header>
                  <Row className="d-flex align-items-center">
                    <Col sm="auto" style={{ fontSize: "1rem" }}>
                      <strong>{`#${number}`}</strong>
                    </Col>
                    <Col className="pl-0">
                      <BlockTitle>{title}</BlockTitle>
                    </Col>
                    <Col lg="1" className="d-flex justify-content-end">
                      <DeleteButton
                        iconOnly
                        onClick={async () => deleteTemplate(id)}
                      />
                    </Col>
                  </Row>
                </Header>
                <Body>
                  <div style={{ margin: "0 -1rem -1rem" }}>
                    <EditLineTemplate template={{ id, title, number }} />
                  </div>
                </Body>
              </Block>
            );
          }
        )}
      </List>
      <NewLineTemplate />
    </>
  );
};

const ApolloWrap: React.FC = () => (
  <ApolloProvider client={client}>
    <LineTemplateListPage />
  </ApolloProvider>
);

export default ApolloWrap;

import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AddFormButton, Fieldset, Form } from "~/form";
import { createDocumentationBlockMutation, DocumentationBlock } from ".";
import { DocumentationSection } from "../";
import { DocumentationBlockTemplateSelectField } from "../block-template";

interface Props {
  documentationSection: DocumentationSection;
}

const NewDocumentationBlock: React.FC<Props> = ({ documentationSection }) => {
  const formMethods = useForm();
  const { reset } = formMethods;
  const create = createDocumentationBlockMutation(documentationSection);

  const onSubmit = async (
    input: Pick<DocumentationBlock, "documentationBlockTemplateId">
  ) => {
    await create(input);
    reset();
  };

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} horizontal>
      <Fieldset title="Ny innhold">
        <Row>
          <Col lg="8">
            <DocumentationBlockTemplateSelectField
              name="documentationBlockTemplateId"
              label="Innhold"
              required
            />
          </Col>
          <Col lg="auto">
            <FormGroup>
              <AddFormButton style={{ marginTop: "1.5rem" }} />
            </FormGroup>
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewDocumentationBlock;

import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import {
  AddFormButton,
  DateField,
  FileField,
  Form,
  Row,
  TextField,
} from "~/form";
import { FullConstructionLoanPeriod } from "~/report/construction-loan/period";
import { InvoiceAmountFields, InvoiceType, useCreateInvoice } from ".";

interface Props {
  period: FullConstructionLoanPeriod;
}

const NewInvoice: React.FC<Props> = ({
  period: {
    id: periodId,
    report: { currencies },
  },
}) => {
  const [create, uploadProgress] = useCreateInvoice(InvoiceType.CLIENT);

  return (
    <Form
      onSubmit={create}
      defaultValues={{
        periodId,
        currency: "nok",
      }}
      horizontal
      resetBlank
    >
      <Row>
        <Col lg="2">
          <TextField name="sender" label="Avsender" required />
        </Col>
        <Col lg="2">
          <TextField name="number" label="Faktura" required />
        </Col>
        <Col lg="2">
          <DateField name="dueDate" label="Faktura dato" required />
        </Col>
        <Col lg="3">
          <InvoiceAmountFields currencies={currencies} />
        </Col>
        <Col>
          <FileField
            name="file"
            label="Fakturafil"
            progress={uploadProgress}
            required
          />
        </Col>
      </Row>
      <FormGroup style={{ margin: "1rem 0 0.5rem 0" }}>
        <AddFormButton />
      </FormGroup>
    </Form>
  );
};

export default NewInvoice;

import { gql } from "@apollo/client";

export const statesFragment = gql`
  fragment StateFields on CostSection {
    state
    stateMessage
    status
    statusInfo {
      touchedCostLinesCount
      touchedRequestCostLinesCount
    }
    urls {
      show
    }
    steps {
      state
      ffw
      rew
      finished
      deliveryDate
      deliveredDate
      members {
        name
      }
    }
  }
`;

export const COST_SECTION_STATE_QUERY = gql`
  query costSectionState($id: ID!) {
    costSection(id: $id) {
      id
      ...StateFields
    }
  }
  ${statesFragment}
`;

export const COST_SECTION_STATE_FFW_MUTATION = gql`
  mutation CostSectionStateFfw($input: CostSectionStateFfwInput!) {
    costSectionStateFfw(input: $input) {
      id
      ...StateFields
    }
  }
  ${statesFragment}
`;

export const COST_SECTION_STATE_REW_MUTATION = gql`
  mutation CostSectionStateRew($input: CostSectionStateRewInput!) {
    costSectionStateRew(input: $input) {
      id
      ...StateFields
    }
  }
  ${statesFragment}
`;

import React from "react";
import { SelectField, toOptions } from "~/form";
import { SyncSelectFieldProps } from "~/form/SelectField";
import { InvoiceContractor } from "~/graphql-operations";

interface Props extends SyncSelectFieldProps<InvoiceContractor> {
  contractors: InvoiceContractor[];
  loading?: boolean;
}

const ContractorSelectField: React.FC<Props> = ({
  contractors = [],
  name,
  label,
  loading,
  ...rest
}) => {
  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={loading ? [] : toOptions(contractors)}
      {...rest}
    />
  );
};

export default ContractorSelectField;

import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node/constants";
import {
  CostLineTemplate,
  costLineTemplateFragment,
} from "../line-template/constants";

export interface CostBlockTemplate extends ResourceInterface {
  id: string;
  title: string;
  number: number;
  costLineTemplates?: CostLineTemplate[];
}

export type CreateCostBlockTemplateInput = Pick<
  CostBlockTemplate,
  "title" | "number"
>;

export const costBlockTemplateFragment = gql`
  fragment CostBlockTemplateFields on CostBlockTemplate {
    id
    title
    number
    createdAt
  }
`;

export const COST_BLOCK_TEMPLATES_QUERY = gql`
  query costBlockTemplates {
    costBlockTemplates {
      ...CostBlockTemplateFields
      costLineTemplates {
        ...CostLineTemplateFields
      }
    }
  }
  ${costBlockTemplateFragment}
  ${costLineTemplateFragment}
`;

export const COST_BLOCK_TEMPLATE_QUERY = gql`
  query costBlockTemplate($id: ID!) {
    costBlockTemplate(id: $id) {
      ...CostBlockTemplateFields
      costLineTemplates {
        ...CostLineTemplateFields
      }
    }
  }
  ${costBlockTemplateFragment}
  ${costLineTemplateFragment}
`;

export const CREATE_COST_BLOCK_TEMPLATE_MUTATION = gql`
  mutation CreateCostBlockTemplate($input: CreateCostBlockTemplateInput!) {
    createCostBlockTemplate(input: $input) {
      errors
      costBlockTemplate {
        ...CostBlockTemplateFields
        costLineTemplates {
          id
        }
      }
    }
  }
  ${costBlockTemplateFragment}
`;

export const COST_BLOCK_TEMPLATE_UPDATE_MUTATION = gql`
  mutation UpdateCostBlockTemplate($input: UpdateCostBlockTemplateInput!) {
    updateCostBlockTemplate(input: $input) {
      errors
      costBlockTemplate {
        ...CostBlockTemplateFields
        costLineTemplates {
          ...CostLineTemplateFields
        }
      }
    }
  }
  ${costBlockTemplateFragment}
  ${costLineTemplateFragment}
`;

export const COST_BLOCK_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteCostBlockTemplate($input: DeleteCostBlockTemplateInput!) {
    deleteCostBlockTemplate(input: $input) {
      errors
      costBlockTemplate {
        ...CostBlockTemplateFields
      }
    }
  }
  ${costBlockTemplateFragment}
`;

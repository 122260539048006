import { gql } from "@apollo/client";

export const invoiceEntryCategoryGroupFragment = gql`
  fragment InvoiceEntryCategoryGroupFields on InvoiceEntryCategoryGroup {
    id
    contractorId
    name
    order
  }
`;

export const invoiceEntryCategoryFragment = gql`
  fragment InvoiceEntryCategoryFields on InvoiceEntryCategory {
    id
    contractorId
    groupId
    type
    name
    budget
    order
  }
`;

export const invoiceEntryCategorizationFragment = gql`
  fragment InvoiceEntryCategorizationFields on InvoiceContractor {
    id
    invoiceEntryCategorizations {
      ... on InvoiceEntryCategory {
        ...InvoiceEntryCategoryFields
      }
      ... on InvoiceEntryCategoryGroup {
        ...InvoiceEntryCategoryGroupFields
        categories {
          ...InvoiceEntryCategoryFields
        }
      }
    }
  }
  ${invoiceEntryCategoryFragment}
  ${invoiceEntryCategoryGroupFragment}
`;

import React from "react";
import { Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  AttachmentField,
  Form,
  FormUpdateState,
  HiddenField,
  Row,
  SelectField,
  TextAreaField,
  TextField,
} from "~/form";
import { Option } from "~/form/SelectField";
import { DeleteButton } from "~/layout";
import Block from "~/layout/Block";
import { BlockListItemData } from "~/layout/BlockList";
import { Grade } from "~/node/label";
import { CostBlockTemplateSelectField } from "~/sections/cost-section/block-template";
import { BuildingPart, handleOptions, toFormData, updateMutation } from ".";
import { EditBudgetLineList, NewBudgetLine } from "../budget-line";
import { BuildingPartRatingRule } from "../building-part-rating-rule";
import { deleteMutation } from "./actions";

export interface EditBuildingPartProps {
  buildingPart: BuildingPart;
  rules: BuildingPartRatingRule[];
  options: {
    period: Option[];
    tg: Option[];
    kg: Option[];
  };
  budget: boolean;
}

const EditBuildingPart: React.FC<EditBuildingPartProps> = ({
  buildingPart,
  buildingPart: {
    id,
    // housingMaintenanceSectionId,
    tg,
    kg,
    rating,
    description,
    costBlockTemplateTitle,
    costBlockTemplateNumber,
    costBlockTemplateId,
    budgetLinesCount = 0,
    files,
  },
  rules,
  options: allOptions,
  budget,
  children,
}) => {
  const formMethods = useForm({
    defaultValues: toFormData(buildingPart),
    mode: "all",
  });

  const options = handleOptions({
    formMethods,
    rules,
    options: allOptions,
  });

  const onSubmit = updateMutation(id);
  const handleDelete = deleteMutation();

  const budgetable = budget && [tg, kg].every((val) => Number.isInteger(val));

  return (
    <>
      <Form onSubmit={onSubmit} formMethods={formMethods} autoSave horizontal>
        <Row className="align-items-center">
          <Col lg="4">
            <div className="d-flex">
              {children}{" "}
              <TextField
                name="name"
                label={null}
                //
                wrapperStyle={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg="2">
            {budgetLinesCount ? (
              <BlockListItemData>
                {costBlockTemplateNumber} - {costBlockTemplateTitle}
                <HiddenField name="costBlockTemplateId" />
              </BlockListItemData>
            ) : (
              <CostBlockTemplateSelectField
                name="costBlockTemplateId"
                label={null}
              />
            )}
          </Col>
          <Col lg="1">
            <SelectField
              name="tg"
              label={null}
              options={options.tg}
              //
              // disabled={disabledFields.tg}
            />
          </Col>
          <Col lg="1">
            <SelectField
              name="kg"
              label={null}
              options={options.kg}
              //
              // disabled={disabledFields.tg}
            />
          </Col>
          <Col lg="1" className="text-center">
            <BlockListItemData>
              <Grade type="simple" grade={rating} />
            </BlockListItemData>
          </Col>
          <Col>
            <SelectField
              name="period"
              label={null}
              options={options.period}
              // required
              // disabled={disabledFields.tg}
            />
          </Col>
          <Col lg="auto" className="d-flex justify-content-end">
            <FormUpdateState />
            &nbsp;
            {handleDelete && (
              <DeleteButton iconOnly onClick={() => handleDelete(id)} />
            )}
          </Col>
        </Row>
        <hr />
        <Row className="align-items-start">
          <Col lg="6">
            <BlockListItemData>{description}</BlockListItemData>
          </Col>
          <Col lg="6">
            <TextAreaField
              name="descriptionDeviation"
              label={null}
              placeholder="Beskrivelse avvik"
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg="8">
            <TextAreaField
              name="comment"
              label="Kommentar"
              placeholder="Kommentar"
            />
          </Col>
          <Col lg="4">
            <AttachmentField
              attachments={files}
              name="files"
              label="Avviksbilde"
              multiple
            />
          </Col>
        </Row>
      </Form>

      {budgetable && (
        <>
          <hr />
          <EditBudgetLineList buildingPart={buildingPart} />
          {costBlockTemplateId && (
            <Block>
              <NewBudgetLine
                buildingPart={
                  buildingPart as BuildingPart & { costBlockTemplateId: string }
                }
              />
            </Block>
          )}
        </>
      )}
    </>
  );
};

export default EditBuildingPart;

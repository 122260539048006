import React, { ReactNode } from "react";
import { SelectField } from "~/form";
import { Option } from "~/form/SelectField";
import { InvoiceCategoryType } from ".";
import { invoiceCategoryTypeOptions } from "./constants";

interface Props {
  name: string;
  label?: string | ReactNode;
  required?: unknown;
  disabled?: boolean;
  options?: Option<InvoiceCategoryType>[];
}

const InvoiceCategoryForm: React.FC<Props> = ({
  name,
  label,
  required,
  disabled,
  options = invoiceCategoryTypeOptions,
}) => {
  return (
    <SelectField
      name={name}
      label={label}
      required={required}
      options={options}
      disabled={disabled}
    />
  );
};

export default InvoiceCategoryForm;

import { gql, useQuery } from "@apollo/client";

import React from "react";
import { Chart } from "~/chart";
import { WordLoader } from "~/layout/Loading";
import { CostCoverage, costCoverageLabel } from "~/sections/cost-section/line";
import { ShortcodeProps } from ".";

const COST_COVERAGE_CHART_QUERY = gql`
  query ReportCostCoverageQuery($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Costs {
        cost {
          both: total(coverage: BOTH)
          tenant: total(coverage: TENANT)
          landlord: total(coverage: LANDLORD)
          other: total(coverage: OTHER)
        }
      }
    }
  }
`;

const KostnadsfordelingDiagram: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(COST_COVERAGE_CHART_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  const { tenant, landlord, both, other } = data.report?.cost || {};

  const chartData = [
    {
      label: costCoverageLabel(CostCoverage.TENANT),
      value: tenant,
    },
    {
      label: costCoverageLabel(CostCoverage.LANDLORD),
      value: landlord,
    },
    {
      label: costCoverageLabel(CostCoverage.BOTH),
      value: both,
    },
    {
      label: costCoverageLabel(CostCoverage.OTHER),
      value: other,
    },
  ];

  return (
    <Chart
      data={chartData}
      title="KostnadFORDELINGSbilde"
      pieLabel="Totalkostnad"
    />
  );
};

export default KostnadsfordelingDiagram;

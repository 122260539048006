import React, { useState } from "react";
import { InlineInput } from "~/form";
import { useUpdateEffect } from "~/util";
import Currency, { formatCurrency } from "~/util/currency";
import { updateBudgetLineMutation } from "./actions";
import { BudgetLine } from "./constants";
import CostTrend from "./CostTrend";

const CostCol: React.FC<BudgetLine> = ({
  id,
  cost,
  costActual,
  finishedAt,
}) => {
  const [actual, setActual] = useState(costActual as number | null);
  const updateBudgetLine = updateBudgetLineMutation(id);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, "");
    if (Number.isInteger(value)) return;
    setActual(parseInt(value));
  };

  useUpdateEffect(() => {
    const timer = setTimeout(() => {
      updateBudgetLine({ costActual: actual });
    }, 667);
    return () => clearTimeout(timer);
  }, [actual, finishedAt]);

  return (
    <div className="text-center">
      {finishedAt && (
        <div>
          <InlineInput
            type="text"
            size="sm"
            placeholder="Actual cost"
            value={(actual && formatCurrency(actual)) || 0}
            onChange={onChange}
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          />
        </div>
      )}
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "1.5rem", whiteSpace: "nowrap" }}
      >
        <Currency
          style={{ fontWeight: "bold", opacity: finishedAt ? 0.67 : 1 }}
        >
          {cost}
        </Currency>
        {actual && finishedAt && (
          <CostTrend
            estimate={cost}
            actual={costActual}
            style={{ paddingLeft: "0.25rem" }}
          />
        )}
      </div>
    </div>
  );

  // {
  //   !!cost && cost > 0 && (
  //     <div className="mt-2 text-center">
  //       {cost.toLocaleString("nb-NO")}
  //       &nbsp;&nbsp;
  //       <CostTrend buildingPart={buildingPart} />
  //     </div>
  //   );
  // }
};

export default CostCol;

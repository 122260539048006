import { useAutoAnimate } from "@formkit/auto-animate/react";
import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import invariant from "tiny-invariant";
import { Row } from "~/form";
import {
  ConstructionLoan,
  ContractorInvoice,
  InvoiceEntryCategorization,
  useInvoiceEntriesQuery,
} from "~/graphql-operations";
import { NodeListEmpty } from "~/node";
import EditEntry from "./EditEntry";
import NewEntry from "./NewEntry";

interface Props {
  invoice: ContractorInvoice;
  categorizations: InvoiceEntryCategorization[];
  balance: ConstructionLoan["balance"];
  vatIncluded: boolean;
}

const Wrap = styled.div`
  .form-label {
    display: none !important;
  }
  .form-check {
    margin-top: 0.5rem;
  }
`;

const EditInvoiceEntryList: React.FC<Props> = ({
  invoice,
  // invoice: { id, currency, amount, deposit, autoDeposit, total },
  invoice: { id, currency },
  categorizations,
  vatIncluded,
}) => {
  const { loading, error, data } = useInvoiceEntriesQuery({
    variables: { id },
  });
  const [autoAnimRef] = useAutoAnimate<HTMLDivElement>();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error :(</div>;

  invariant(data?.invoice, "Invoice not found");
  invariant("entries" in data.invoice, "Invoice entries not found");
  const entries = data.invoice.entries || [];

  // const offset = total - amount - (deposit || autoDeposit);
  // const sumClassName = (() => {
  //   if (offset === null) return "secondary";
  //   else if (offset === 0) return "success";
  //   else if (offset > 0) return "primary";
  //   else return "danger";
  // })();

  return (
    <Wrap>
      <div ref={autoAnimRef}>
        {entries.map((entry) => {
          const { id } = entry;
          return (
            <EditEntry
              entry={entry}
              categorizations={categorizations}
              currency={currency}
              key={id}
            />
          );
        })}
      </div>
      {entries.length === 0 && <NodeListEmpty />}

      {/* <Row className="my-3">
        <Col>
          <hr />
        </Col>
        <Col sm="auto">
          <Badge variant={`${sumClassName}`}>
            SUM <Currency>{total}</Currency>
          </Badge>
        </Col>
      </Row> */}

      <hr />
      <Row>
        <Col>
          <NewEntry
            invoice={invoice}
            categorizations={categorizations}
            vatIncluded={vatIncluded}
          />
        </Col>
        <Col lg="auto"></Col>
      </Row>
    </Wrap>
  );
};

export default EditInvoiceEntryList;

import { DocumentNode, gql } from "@apollo/client";
import { Membership, User } from "~/graphql-operations";
import { userFragment } from "~/user";
import { capitalize, constantize } from "~/util/String";

export interface EditableMember {
  // membership: Pick<Membership, "id" | "permissions" | "relation">;
  membership: Pick<
    Membership,
    "id" | "permissions" | "preferences" | "relation"
  >;
  user: Pick<User, "id" | "name" | "email" | "companyName" | "role">;
}

// export interface Member
//   extends Pick<User, "email" | "name" | "companyName">,
//     Pick<
//       Membership,
//       | "userId"
//       | "reportId"
//       | "reportSlug"
//       | "relation"
//       | "permissions"
//       | "preferences"
//     > {
//   membershipId: string;
//   companyIds: string[] | null;
// }

export const memberFragment = gql`
  fragment MemberFields on Member {
    membershipId
    reportId
    userId
    email
    name
    companyIds
    companyName
    relation
    permissions
    preferences
    role
    createdAt
  }
`;

export const reportMembersMultiSelectQuery = (
  relationMethod: string
): DocumentNode => {
  const capitalizedRelationMethod = constantize(relationMethod);
  return gql`
    query Report${capitalizedRelationMethod}Query($slug: ID!) {
      report(slug: $slug) {
        id
        ${relationMethod} {
          ...MemberFields
        }
      }
      ${relationMethod} {
        ...UserFields
      }
    }
    ${memberFragment}
    ${userFragment}
  `;
};

export const reportMembersMultiSelectMutation = (
  relationMethod: string
): DocumentNode => {
  const capitalizedRelationMethod = capitalize(relationMethod);
  return gql`
    mutation reportSet${capitalizedRelationMethod}($input: ReportSet${capitalizedRelationMethod}Input!) {
      reportSet${capitalizedRelationMethod}(input: $input) {
        id
        ${relationMethod} {
          ...MemberFields
        }
      }
    }
    ${memberFragment}
  `;
};

export const REPORT_MEMBERS_QUERY = gql`
  query ReportMembers($slug: ID!) {
    report(slug: $slug) {
      id
      client {
        ...MemberFields
      }
      associates {
        ...MemberFields
      }
      collaborators {
        ...MemberFields
      }
    }
  }
  ${memberFragment}
`;

import React, { createContext, useContext, useState } from "react";
// import useCollapse from "react-collapsed";

interface Props {
  collapsible?: CollapsibleContext;
}

export type CollapsibleContext = {
  collapsed: boolean;
  toggle: () => void;
};

export const useCollapsible = () => {
  const [collapsed, setCollapsed] = useState(true);
  return {
    collapsed,
    toggle: () => setCollapsed(!collapsed),
  };
};

const Context = createContext<CollapsibleContext>({
  collapsed: true,
  toggle: () => undefined,
});

export const useCollapsed = () => useContext(Context);

const Collapsible: React.FC<Props> = ({ children, collapsible }) => {
  const defaultContext = useCollapsible();
  return (
    <Context.Provider value={collapsible || defaultContext}>
      {children}
    </Context.Provider>
  );
};

export default Collapsible;

import React from "react";
import { SelectField } from "~/form";
import { SyncSelectFieldProps } from "~/form/SelectField";
import {
  InvoiceEntryCategorization,
  InvoiceEntryCategory
} from "~/graphql-operations";
// import { InvoiceEntryCategory } from ".";
import { toGroupedOptions } from "../categorization";

interface Props extends SyncSelectFieldProps<InvoiceEntryCategory> {
  categorizations: InvoiceEntryCategorization[];
  loading?: boolean;
}

const InvoiceEntryCategorySelectField: React.FC<Props> = ({
  categorizations = [],
  name,
  label,
  loading,
  ...rest
}) => {
  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={loading ? [] : toGroupedOptions(categorizations)}
      {...rest}
    />
  );
};

export default InvoiceEntryCategorySelectField;

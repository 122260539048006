import React from "react";
import styled from "styled-components";
import { StyledIndicatorLine, StyledStep } from ".";

export const StyledSteps = styled.div`
  display: flex;
  ${StyledStep}:first-child {
    ${StyledIndicatorLine}:before {
      display: none;
    }
  }
  ${StyledStep}:last-child {
    ${StyledIndicatorLine}:after {
      display: none;
    }
  }
`;

interface Props {
  title?: string;
}

const Step: React.FC<Props> = ({ children }) => {
  return <StyledSteps>{children}</StyledSteps>;
};

export default Step;

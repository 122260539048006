import React from "react";
import { Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CompanySelectField } from "~/Company";
import {
  CheckboxField,
  Fieldset,
  Form,
  FormProps,
  RadioField,
  Row,
  SelectField,
  TextField,
} from "~/form";
import { User, UserRole } from "~/graphql-operations";
import { userRoleOptions, workingAreaOptions } from ".";

type FormInput = Partial<
  Pick<
    User,
    | "id"
    | "companyIds"
    | "firstName"
    | "lastName"
    | "email"
    | "partner"
    | "phone"
    | "role"
    | "unlockReport"
    | "workingArea"
  >
>;

const UserForm: React.FC<FormProps<FormInput>> = ({
  onSubmit,
  defaultValues,
  children,
  ...rest
}) => {
  const formMethods = useForm({
    defaultValues,
    mode: "all",
  });
  const { watch } = formMethods;
  const role = watch("role");

  return (
    <Form
      onSubmit={onSubmit}
      formMethods={formMethods}
      style={{ width: "600px", margin: "0 auto" }}
      {...rest}
    >
      <Fieldset title="Brukerinformasjon">
        <TextField label="e-post" name="email" />
        <Row>
          <Col sm="6">
            <TextField name="firstName" label="Fornavn" />
          </Col>
          <Col sm="6">
            <TextField name="lastName" label="Etternavn" />
          </Col>
        </Row>

        <TextField name="phone" label="Phone" />

        <SelectField name="role" label="rolle" options={userRoleOptions} />

        {role && [(UserRole.Admin, UserRole.Editor)].includes(role) && (
          <CheckboxField name="partner" label="Kvalitetssikret av" />
        )}

        {role === UserRole.Editor && (
          <RadioField
            name="unlockReport"
            label={null}
            options={[
              { value: "0", label: "Kan ikke låse opp rapporter" },
              { value: "1", label: "Låse opp egen rapporter" },
              { value: "2", label: "Låse opp alle rapporter" },
            ]}
          />
        )}

        {role === UserRole.Consultant && (
          <SelectField
            name="workingArea"
            label="OMRÅDE"
            options={workingAreaOptions}
          />
        )}

        {role && [UserRole.Client, UserRole.Collaborator].includes(role) && (
          <CompanySelectField name="companyIds" label="Kunde" />
        )}
      </Fieldset>

      {children}
    </Form>
  );
};

export default UserForm;

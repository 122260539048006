import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { Fieldset, FieldsetTitle } from "~/form";
import Block from "~/layout/Block";
import { NoReports } from "~/report";
import { client } from "~/setup";
import { User } from "~/user";
import { AffiliateLine, Membership, MEMBERSHIPS_QUERY, NewAffiliate } from ".";

interface Props {
  user: Pick<User, "id">;
}

type Query = { memberships: Membership[] };

export const StyledAffiliateLine = styled(AffiliateLine)`
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted var(--light-gray);

  &:last-of-type {
    border-bottom: none;
  }
`;

const AffiliateList: React.FC<Props> = ({ user, user: { id: userId } }) => {
  const variables = { userId };
  const { loading, error, data } = useQuery<Query>(MEMBERSHIPS_QUERY, {
    variables,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const { memberships = [] } = data || {};
  const excludeReports = memberships.map((m) => m.reportId);

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <Fieldset>
        <FieldsetTitle>Rapportliste</FieldsetTitle>

        {memberships.map((membership) => (
          <StyledAffiliateLine key={membership.id} membership={membership} />
        ))}
        {!memberships.length && <NoReports />}

        <Block>
          <NewAffiliate user={user} excludeReports={excludeReports} />
        </Block>
      </Fieldset>
    </div>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <AffiliateList {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

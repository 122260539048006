import { DocumentNode, gql, useQuery } from "@apollo/client";
import { WorkingAreaEnum } from "~/graphql-operations";
import { Report } from "~/report";
import { memberFragment } from "../constants";
import { ReportConsultants } from "./constants";

export const reportConsultantsQuery = (
  workingArea?: WorkingAreaEnum | WorkingAreaEnum[]
): DocumentNode => {
  const areas = workingArea
    ? Array.isArray(workingArea)
      ? workingArea
      : [workingArea]
    : Object.values(WorkingAreaEnum);

  const queryFields = areas.map(
    (area) =>
      `
        ${area}Consultants {
          ...MemberFields
        }
        ${area}KsConsultants {
          ...MemberFields
        }
      `
  );

  return gql`
    query ReportAll${areas.join("AND")}ConsultantsQuery($slug: ID!) {
      report(slug: $slug) {
        id
        ... on Consultable {
          ${queryFields}
        }
      }
    }
    ${memberFragment}
  `;
};

export const useReportConsultants = (
  { slug }: Pick<Report, "slug">,
  workingAreas?: WorkingAreaEnum | WorkingAreaEnum[]
) => {
  const { loading, error, data } = useQuery<{
    report: Pick<Report, "id"> & ReportConsultants;
  }>(reportConsultantsQuery(workingAreas), { variables: { slug } });

  return { loading, error, report: data?.report };
};

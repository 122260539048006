import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Col } from "react-bootstrap";
import invariant from "tiny-invariant";
import { Row } from "~/form";
import { useConstructionLoanPeriodWithInvoicesQuery } from "~/graphql-operations";
import { EditInvoiceList, NewClientInvoice, NewInvoice } from "~/invoice";
import { Block, IconButton } from "~/layout";
import { Session, SessionProvider, useSession } from "~/session";
import { client } from "~/setup";
import EditCollaboratorPeriod from "./EditCollaboratorPeriod";
import EditPeriodInfo from "./EditPeriodInfo";
import EditPeriodPayout from "./EditPeriodPayout";
import EditPeriodSummary from "./EditPeriodSummary";
import Publish from "./Publish";
import ReadyForReview from "./ReadyForReview";
import Unpublish from "./Unpublish";

interface Props {
  id: string;
}

const ConstructionLoanPeriodPage: React.FC<Props> = ({ id }) => {
  const { generalEditor } = useSession();
  const { loading, error, data } = useConstructionLoanPeriodWithInvoicesQuery({
    variables: { id },
  });

  const period = data?.constructionLoanPeriod;
  const currentUrl = window.location.href;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  invariant(period, "Period not found");
  const { published } = period;

  return (
    <>
      {generalEditor ? (
        <>
          <Row>
            <Col>
              <EditPeriodInfo period={period} />
            </Col>
            <Col lg="auto" style={{ paddingTop: "21px" }}>
              <ReadyForReview period={period} />
            </Col>
            <Col lg="auto" style={{ paddingTop: "21px" }}>
              {published ? (
                <Unpublish period={period} />
              ) : (
                <Publish period={period} />
              )}
            </Col>
          </Row>
          <EditPeriodPayout period={period} />
        </>
      ) : (
        <EditCollaboratorPeriod period={period} />
      )}

      <div style={{ marginTop: "2rem" }}></div>

      {generalEditor && (
        <div className="text-right mb-3" style={{ marginTop: "-1rem" }}>
          <IconButton
            icon="download"
            size="sm"
            onClick={() => (window.location.href = `${currentUrl}/download`)}
          >
            Last ned alle
          </IconButton>
        </div>
      )}

      <EditInvoiceList period={period} />
      <Block>
        {generalEditor ? (
          <NewInvoice period={period} />
        ) : (
          <NewClientInvoice period={period} />
        )}
      </Block>

      {generalEditor && period.full && (
        <>
          <br />
          <hr />
          <EditPeriodSummary period={period} mode="end" />
        </>
      )}
    </>
  );
};

const ApolloWrap: React.FC<Props & { session: Session }> = ({
  session,
  ...rest
}) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <ConstructionLoanPeriodPage {...rest} />
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

import React from "react";
import styled, { css } from "styled-components";
import Icon, { StyledIcon } from "~/layout/icon";
import { VirtualCategorization } from "../constants";
import { isGroup } from "../helpers";
import DroppableInoviceCategoryList from "./DroppableCategoryList";
import ItemHeader from "./ItemHeader";

interface ItemProps {
  categorization: VirtualCategorization;
  isDragging?: boolean;
  dragHandleProps?: unknown;
}

export const HandleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: -0.375rem;
  margin-right: 0.25rem;
  padding-left: 0.375rem;
  background: #fff;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;

  ${StyledIcon} {
    margin-right: 0.25rem;
  }
`;

const ItemWrap = styled.div<{
  $type: "group" | "groupFixed" | "groupVirtual";
  isDragging: boolean;
}>`
  position: relative;
  background: ${({ isDragging }) =>
    isDragging ? "var(--yellow)" : "transparent"};

  &:hover {
    ${HandleWrap} {
      opacity: 1;
    }
  }

  ${({ $type }) => {
    switch ($type) {
      case "group":
        return css`
          padding: 1.5rem 0 1rem 0;
          border: 1px solid var(--light-gray);
          border-radius: 6px;
        `;
      case "groupFixed":
        return css`
          margin-bottom: 2rem;
          padding: 1.5rem 0 1rem 0;
          border: 1px dashed var(--light-gray);
          border-radius: 6px;
        `;
      default:
        return css`
          ${HandleWrap} {
            display: none;
          }
          padding: 1rem 0;
          border: 1px dashed var(--light-gray);
          border-radius: 6px;
        `;
    }
  }}
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  /* margin-top: 0.375rem; */
  padding: 1rem 0.75rem;
  /* background: #fff; */

  + div:empty {
    margin-top: 0 !important;
  }

  .form-control {
    width: auto;
    font-weight: bold;
    border: transparent;
  }
`;

export const VirtualCategorizationItem: React.FC<{
  categorization: VirtualCategorization;
  dragHandleProps?: unknown;
}> = ({ categorization, categorization: { id }, dragHandleProps }) => {
  const hasCategories = categorization.categories.length > 0;

  return (
    <>
      <Header
        id={isGroup(categorization) ? `invoice-category-group-${id}` : ""}
      >
        {dragHandleProps && (
          <HandleWrap>
            <Icon icon="move" {...dragHandleProps} />
          </HandleWrap>
        )}
        {isGroup(categorization) && (
          <ItemHeader
            group={{ id, name: categorization.name }}
            hasCategories={hasCategories}
            editable
            deletable
          />
        )}
      </Header>
      <DroppableInoviceCategoryList categorization={categorization} />
    </>
  );
};

const DraggableCateogorizationItem: React.FC<ItemProps> = ({
  categorization,
  isDragging = false,
  dragHandleProps,
  children,
}) => {
  const type = (() => {
    return isGroup(categorization) ? "group" : "groupVirtual";
  })();
  return (
    <ItemWrap isDragging={isDragging} $type={type}>
      <VirtualCategorizationItem
        categorization={categorization}
        dragHandleProps={dragHandleProps}
      />
      {children}
    </ItemWrap>
  );
};

export default DraggableCateogorizationItem;

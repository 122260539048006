import { gql, useQuery } from "@apollo/client";

import { Chart } from "~/chart";
import { MaintenanceReport } from "../constants";
import React from "react";
import { ShortcodeProps } from ".";
import { WordLoader } from "~/layout/Loading";
import { costTypeLabel } from "~/sections/cost-section";

const SHORTCODE_COST_TYPES_QUERY = gql`
  query ReportCostTypesQuery($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Maintainable {
        maintenance {
          duration
        }
      }
      ... on Costs {
        costSections {
          costType
          costBlocks {
            cost
          }
        }
      }
    }
  }
`;

const VedlikeholdKostnadsDiagram: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery<{
    report: Pick<MaintenanceReport, "id" | "costSections" | "maintenance">;
  }>(SHORTCODE_COST_TYPES_QUERY, {
    variables: {
      slug,
    },
  });

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  const {
    maintenance: { duration },
    costSections,
  } = data?.report || { maintenance: {}, costSections: [] };

  const chartData = costSections.map(({ costBlocks, costType }) => {
    return {
      label: costTypeLabel(costType),
      value: costBlocks.reduce(
        (sum: number, curr: { cost: number }) => sum + curr.cost,
        0
      ),
      icon: costType.toLowerCase(),
    };
  });

  return (
    <Chart
      data={chartData}
      title="Kostnadsbilde"
      pieLabel="Totalkostnad"
      subLabel={`${duration} år`}
    />
  );
};

export default VedlikeholdKostnadsDiagram;

import React from "react";
import { Col } from "react-bootstrap";
import { CheckboxField, Fieldset, Form, Row, TextField } from "~/form";
import { Block } from "~/layout";
import {
  UpdateRequestCostBlockTemplateInput,
  useUpdateRequestCostBlockTemplate,
} from ".";

type Props = {
  template: UpdateRequestCostBlockTemplateInput;
};
const EditRequestCostBlockTemplate: React.FC<Props> = ({ template }) => {
  const { id, title, number, internalUse } = template;
  const defaultValues = { id, title, number, internalUse };

  const update = useUpdateRequestCostBlockTemplate();

  return (
    <Block>
      <Form onSubmit={update} defaultValues={defaultValues} autoSave horizontal>
        <Fieldset title="Informasjon">
          <Row>
            <Col lg="6">
              <TextField name="title" label="tittel" />
            </Col>
            <Col lg="2">
              <TextField name="number" label="nummer" />
            </Col>
            <Col lg="2" className="d-flex align-items-center">
              <CheckboxField name="internalUse" label="Internal Use" />
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default EditRequestCostBlockTemplate;

import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import {
  CostSection,
  CostSectionSummaryInfoDocument,
} from "~/graphql-operations";
import { nodeId } from "~/node";
import { isTdd, ReportType } from "~/report";
import { client } from "~/setup";
import Buildings from "./Buildings";
import Coverage from "./Coverage";
import Priority from "./Priority";

export type CostSectionSummaryProps = {
  costSection: Required<Pick<CostSection, "id" | "__typename">>;
  report: { __typename: ReportType };
};

const CostSectionSummary: React.FC<CostSectionSummaryProps> = ({
  costSection,
  report,
}) => {
  const { loading, error, data } = useQuery(CostSectionSummaryInfoDocument, {
    variables: { id: nodeId(costSection) },
  });

  if (error) return <p>Error</p>;
  if (loading) return <p>Loading</p>;

  const node = data?.node || {};
  if (!("publication" in node)) return <p>No data</p>;

  const {
    publication: { buildingCosts },
  } = node;

  const singleBuilding = buildingCosts.length == 1;

  const priorityTitle = singleBuilding
    ? buildingCosts[0].building.name
    : "Hele eiendommen";

  const coverageTitle = singleBuilding
    ? "Kostnadsfordeling"
    : "Kostnadsfordeling - Hele eiendommen";

  return (
    <>
      <Priority costSection={costSection} title={priorityTitle} />
      <Coverage costSection={costSection} title={coverageTitle} />

      {isTdd(report) && !singleBuilding && (
        <Buildings costSection={costSection} />
      )}
    </>
  );
};

const ApolloWrap: React.FC<CostSectionSummaryProps> = (
  props: CostSectionSummaryProps
) => (
  <ApolloProvider client={client}>
    <CostSectionSummary {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

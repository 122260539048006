import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { Label, Row } from "~/form";
import { useSession } from "~/session";
import { EditDocument } from ".";
import { Document } from "./constants";

interface Props {
  documents: Document[];
}

const StyledList = styled.div`
  .form-label {
    display: none;
  }
`;

const DocumentWrap = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--mystic);
`;

const DocumentList: React.FC<Props> = ({ documents }) => {
  const { generalEditor } = useSession();
  return (
    <StyledList>
      <Row style={{ marginBottom: "0.5rem" }}>
        {generalEditor && (
          <Col lg="2">
            <Label>Visibility</Label>
          </Col>
        )}
        <Col lg={generalEditor ? 2 : 4}>
          <Label>Name</Label>
        </Col>
        <Col lg="2">
          <Label>Sender</Label>
        </Col>
        <Col lg="2">
          <Label>Prepared by</Label>
        </Col>
        <Col lg="4">
          <Label>Date / Received</Label>
        </Col>
      </Row>
      {documents.map((document) => {
        const { id } = document;
        return (
          <DocumentWrap key={id}>
            <EditDocument document={document} />
          </DocumentWrap>
        );
      })}
    </StyledList>
  );
};

export default DocumentList;

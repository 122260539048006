import { gql, useQuery } from "@apollo/client";
import { User, userFragment, UserScope } from ".";

const query = (scope: UserScope) => gql`
  query ${scope} {
    ${scope} {
      ...UserFields
    }
  }
  ${userFragment}
`;

export const loadUserScope = (scope: UserScope) => {
  const { loading, error, data } = useQuery<{
    [scope]: User[];
  }>(query(scope));

  const users = (data && data[scope]) || [];

  return { users, loading, error };
};

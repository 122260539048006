import { DocumentNode } from "graphql";
import { snakeCase } from "lodash";
import {
  Assessment,
  Breeam,
  ConstructionLoan,
  Report,
  ReportType,
  Tdd,
} from "~/graphql-operations";
import { nodeAttributeLabel } from "~/node";
import { API_HOST } from "~/setup";

type ReportWithType = { __typename: ReportType };

export const isConstructionLoan = (
  report:
    | undefined
    | null
    | DocumentNode
    | {
        __typename?: string;
        type?: string;
      }
): report is ConstructionLoan => {
  if (!report || !("__typename" in report)) return false;
  return report?.__typename === "ConstructionLoan";
};

export const isTdd = (report: ReportWithType) =>
  reportType(report) === ReportType.Tdd;

export const reportType = ({ __typename }: ReportWithType) => __typename;

export const reportTypeOptions = [
  {
    value: ReportType.Assessment,
    label: "Tilstandsvurdering",
  },
  {
    value: ReportType.HousingAssessment,
    label: "Bygningssjekk",
  },
  {
    value: ReportType.Tdd,
    label: "Teknisk Due Diligence",
  },
  {
    value: ReportType.Breeam,
    label: "BREEAM In-Use",
  },
  {
    value: ReportType.ConstructionLoan,
    label: "Byggelån",
  },
];

export const reportTypeLabel = nodeAttributeLabel(reportTypeOptions);

type TypedReport = Pick<Report, "type">;

export const isAssessment = (report: TypedReport): report is Assessment =>
  report.type === ReportType.Assessment;

export const isTDD = (report: TypedReport): report is Tdd =>
  report.type === ReportType.Tdd;

export const isBreeam = (report: TypedReport): report is Breeam =>
  report.type === ReportType.Breeam;

const ReportUrlActionEnum = {
  View: "view",
  Edit: "edit",
  DownloadPdf: "download.pdf",
  DownloadXlsx: "download.xlsx",
  ExportCsv: "export.csv",
  ExportXlsx: "export.xlsx",
} as const;

type ReportUrlAction = typeof ReportUrlActionEnum[keyof typeof ReportUrlActionEnum];

export const reportUrl = (
  { slug, __typename }: Pick<Report, "slug"> & { __typename: string },
  action: ReportUrlAction = "view"
) => {
  const url = `${API_HOST}/${snakeCase(__typename)}s/${slug}`;
  return action !== "view" ? `${url}/${action}` : url;
};

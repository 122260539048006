import React from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { BlockListHeader } from ".";
import { IconButton } from "../Button";

interface Props {
  state: "editable" | "sortable";
  onEditable: () => void;
  onSortable: () => void;
}

const BlockListHeaderEditableSortable: React.FC<Props> = ({
  children,
  state,
  onEditable,
  onSortable,
}) => {
  const getButtonColor = (buttonState: Props["state"]) =>
    state === buttonState ? "dark" : "light";

  return (
    <BlockListHeader>
      <Row>
        {children}
        <Col className="text-right">
          <ButtonGroup>
            <IconButton
              icon="edit"
              variant={getButtonColor("editable")}
              onClick={onEditable}
            />
            <IconButton
              icon="move"
              variant={getButtonColor("sortable")}
              onClick={onSortable}
            />
          </ButtonGroup>
        </Col>
      </Row>
    </BlockListHeader>
  );
};

export default BlockListHeaderEditableSortable;

import { TCell, THCell, THead, TRow, Table } from "~/layout/table/CostTable";
import { gql, useQuery } from "@apollo/client";

import React from "react";
import { Report } from "../constants";
import { ShortcodeProps } from "./Shortcode";
import { WordLoader } from "~/layout/Loading";
import { formatDate } from "~/util";

const RENTALS_REPORT_QUERY = gql`
  query Leieavtale($slug: ID!) {
    report(slug: $slug) {
      id
      rentals {
        tenant
        signedAt
        startsAt
        endsAt
      }
    }
  }
`;

const Leieavtale: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery<{
    report: Pick<Report, "id" | "rentals">;
  }>(RENTALS_REPORT_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error :(</p>;
  if (loading) return <WordLoader />;

  const rentals = data?.report.rentals || [];

  return (
    <>
      {rentals.length > 0 && (
        <Table responsive="sm" borderless>
          <THead>
            <TRow>
              <THCell borderRight left>
                Leietaker
              </THCell>
              <THCell>Inngåelse</THCell>
              <THCell>Utløp</THCell>
              <THCell>Signert</THCell>
            </TRow>
          </THead>
          <tbody>
            {rentals.map(({ tenant, startsAt, endsAt, signedAt }, index) => {
              return (
                <TRow key={index}>
                  <TCell left>{tenant}</TCell>
                  <TCell>
                    {startsAt
                      ? formatDate(`${startsAt}`, { format: "L" })
                      : "-"}
                  </TCell>
                  <TCell>
                    {endsAt ? formatDate(`${endsAt}`, { format: "L" }) : "-"}
                  </TCell>
                  <TCell>
                    {signedAt
                      ? formatDate(`${signedAt}`, { format: "L" })
                      : "-"}
                  </TCell>
                </TRow>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Leieavtale;

import { pick } from "lodash";
import { UseFormReturn } from "react-hook-form";
import { AttachableInput, attachmentInput } from "~/form/attachment";
import { useUpdateEffect } from "~/util";
import { BuildingPartRatingRule } from "../building-part-rating-rule";
import { BuildingPart } from "./constants";
import { EditBuildingPartProps } from "./EditBuildingPart";

type fetchOptions = (args: {
  formMethods: UseFormReturn<Partial<AttachableInput<BuildingPart, "files">>>;
  rules: BuildingPartRatingRule[];
  options: EditBuildingPartProps["options"];
}) => EditBuildingPartProps["options"];

export const toFormData = (buildingPart: BuildingPart) => {
  const { files } = buildingPart;
  return {
    ...pick(buildingPart, [
      "costBlockTemplateId",
      "name",
      "tg",
      "kg",
      "period",
      "comment",
      "descriptionDeviation",
    ]),
    files: files ? attachmentInput(files) : undefined,
  };
};

export const handleOptions: fetchOptions = ({
  formMethods,
  rules,
  options,
}) => {
  const { watch, getValues, setValue } = formMethods;
  const [tg, kg] = watch(["tg", "kg"]);

  // set kg on tg change
  useUpdateEffect(() => {
    const kg = getValues("kg");
    const kgs = rules.filter((rule) => rule.tg === tg).map((rule) => rule.kg);

    if (kg && kgs.includes(kg)) return;

    setValue("kg", kgs.length ? kgs[0] : null, { shouldDirty: true });
  }, [tg]);

  const getPeriods = () => {
    return rules
      .filter((rule) => rule.tg === tg && rule.kg === kg)
      .map((rule) => rule.period);
  };

  // set period on tg change
  useUpdateEffect(() => {
    const period = getValues("period");
    const periods = getPeriods();
    if (period && periods.includes(period)) return;

    setValue("period", periods.length ? periods[0] : null, {
      shouldDirty: true,
    });
  }, [tg]);

  // set period on kg change
  useUpdateEffect(() => {
    const period = getValues("period");
    const periods = getPeriods();
    if (period && periods.includes(period)) return;

    setValue("period", periods.length ? periods[0] : null, {
      shouldDirty: true,
    });
  }, [kg]);

  return {
    tg: Array.from(new Set(rules.map((rule) => rule.tg))).map((tg) => {
      return {
        value: tg,
        label: options.tg.find((option) => option.value === tg)?.label,
      };
    }),
    kg: rules
      .filter((rule) => rule.tg === tg)
      .map((rule) => ({
        value: rule.kg,
        label: options.kg.find((option) => option.value === rule.kg)?.label,
      })),
    period: getPeriods().map((period) => ({
      value: period,
      label: options.period.find((option) => option.value === period)?.label,
    })),
  };
};

import { useQuery } from "@apollo/client";
import { pick } from "lodash";
import React from "react";
import { Chart } from "~/chart";
import {
  CostOverviewChartDocument,
  MaintenancePeriodEnum,
  MaintenancePriorityEnum,
  MaintenanceTypeEnum,
  Report,
} from "~/graphql-operations";
import { Icons } from "~/layout/icon";
import { WordLoader } from "~/layout/Loading";
import { maintenanceTypeLabel } from "~/report/maintenance";

type Props = {
  report: Pick<Report, "slug">;
  title: string;
  period?: MaintenancePeriodEnum;
  priority?: MaintenancePriorityEnum;
  small?: true;
};

const CostOverviewChart: React.FC<Props> = ({
  report: { slug },
  period,
  priority,
  title,
  ...rest
}) => {
  const { loading, error, data } = useQuery(CostOverviewChartDocument, {
    variables: { slug, period, priority },
  });

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  const report = data?.report;
  if (!report || !("cost" in report)) return <p>No data</p>;

  const cost = pick(report.cost || {}, [
    "electrical",
    "construction",
    "plumbing",
    "fire",
  ]);

  const chartData = Object.entries(cost).map(([type, value]) => ({
    value: value as number | null,
    label:
      type in MaintenanceTypeEnum
        ? maintenanceTypeLabel(type as MaintenanceTypeEnum)
        : "",
    icon: type.toLowerCase() as Icons,
  }));

  return <Chart data={chartData} title={title} showZero {...rest} />;
};

export default CostOverviewChart;

import { useQuery } from "@apollo/client";
import React from "react";
import { CostSectionSummaryCoverageDocument } from "~/graphql-operations";
import { WordLoader } from "~/layout/Loading";
import { Table, TCell, THCell, THead, TRow } from "~/layout/table/CostTable";
import { nodeId } from "~/node";
import { CostSectionSummaryProps } from "./CostSectionSummary";
import { intervalIndexLabel } from "./helpers";

type Props = Pick<CostSectionSummaryProps, "costSection"> & {
  title: string;
};

const Coverage: React.FC<Props> = ({ costSection, title }) => {
  const { loading, error, data } = useQuery(
    CostSectionSummaryCoverageDocument,
    {
      variables: { id: nodeId(costSection) },
    }
  );

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  const node = data?.node || {};
  if (!("publication" in node)) return <p>No data</p>;

  const {
    publication: {
      missingRegulationBoth,
      missingRegulationLandlord,
      missingRegulationOther,
      missingRegulationTenant,
      missingRegulationTotal,

      immediateBoth,
      immediateLandlord,
      immediateOther,
      immediateTenant,
      immediateTotal,

      intervalsTenant,
      intervalsLandlord,
      intervalsBoth,
      intervalsOther,
      intervalsTotal,

      tenantTotal,
      landlordTotal,
      bothTotal,
      otherTotal,

      total,
    },
  } = node;

  if (!total) return null;

  return (
    <>
      <h3 className="text-center">{title}</h3>
      <Table responsive="sm" borderless>
        <THead>
          <TRow>
            <THCell borderRight left>
              Type /<br></br>Tidsrom
            </THCell>
            <THCell>Leier</THCell>
            <THCell>Gårdeier</THCell>
            <THCell>Leier/Gårdeier</THCell>
            <THCell>Andre</THCell>
            <THCell borderY>Sum kostnad</THCell>
          </TRow>
        </THead>
        <tbody>
          <TRow>
            <TCell borderRight left>
              Forskriftsmangel
            </TCell>
            <TCell>{missingRegulationTenant}</TCell>
            <TCell>{missingRegulationLandlord}</TCell>
            <TCell>{missingRegulationBoth}</TCell>
            <TCell>{missingRegulationOther}</TCell>
            <TCell total borderY>
              {missingRegulationTotal}
            </TCell>
          </TRow>
          <TRow>
            <TCell borderRight left>
              Strakstiltak
            </TCell>
            <TCell>{immediateTenant}</TCell>
            <TCell>{immediateLandlord}</TCell>
            <TCell>{immediateBoth}</TCell>
            <TCell>{immediateOther}</TCell>
            <TCell total borderY>
              {immediateTotal}
            </TCell>
          </TRow>
          {intervalsTotal.map((total, i) => (
            <TRow key={i}>
              <TCell borderRight left>
                {intervalIndexLabel(i)}
              </TCell>
              <TCell>{intervalsTenant?.[i]}</TCell>
              <TCell>{intervalsLandlord?.[i]}</TCell>
              <TCell>{intervalsBoth?.[i]}</TCell>
              <TCell>{intervalsOther?.[i]}</TCell>
              <TCell total borderY>
                {total}
              </TCell>
            </TRow>
          ))}
        </tbody>
        <tfoot>
          <TRow>
            <TCell borderRight left>
              Totalt
            </TCell>
            <TCell>{tenantTotal}</TCell>
            <TCell>{landlordTotal}</TCell>
            <TCell>{bothTotal}</TCell>
            <TCell>{otherTotal}</TCell>
            <TCell total borderY>
              {total}
            </TCell>
          </TRow>
        </tfoot>
      </Table>
    </>
  );
};

export default Coverage;

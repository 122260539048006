import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useUpdateEffect } from "~/util";
import { updateMutation } from "./actions";
import { BuildingPart } from "./constants";

interface Props {
  buildingPart: BuildingPart;
}

const CostEstimate: React.FC<Props> = ({
  buildingPart,
  buildingPart: { id, costEstimate },
}) => {
  const [estimate, setEstimate] = useState(costEstimate as number | null);
  const updateBuildingPart = updateMutation(id);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEstimate(parseInt(e.target.value));
  };

  useUpdateEffect(() => {
    const timer = setTimeout(() => {
      updateBuildingPart({ costEstimate: estimate }, (cache) => {
        const cacheId = cache.identify(buildingPart);
        cache.modify({
          id: cacheId,
          fields: {
            costEstimate: () => estimate,
          },
        });
        // cache.writeFragment({
        //   id: cacheId,
        //   fragment: buildingPartCostFragment,
        //   data: {
        //     id,
        //     costEstimate: estimate,
        //     cost,
        //   },
        // });
      });
    }, 667);
    return () => clearTimeout(timer);
  }, [estimate]);

  return (
    <Form.Control
      type="number"
      placeholder="Legg til estimat"
      value={(estimate || "").toString()}
      onChange={onChange}
      style={{ textAlign: "center" }}
    />
  );
};

export default CostEstimate;

import styled from "styled-components";

const ChartSVG = styled.svg`
  width: 200px;
  height: 200px;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 50%;
`;

export default ChartSVG;

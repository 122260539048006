import { gql } from "@apollo/client";
import { Building } from "~/Building/constants";
import { ResourceInterface } from "~/node/constants";
import { CostBlock } from "~/sections/cost-section/CostBlock/constants";

export interface CostBlockBuildingInfo extends ResourceInterface {
  buildingId: string;
  building?: Building;
  costBlockId: string;
  costBlock?: CostBlock;
  tg: number;
  kg: number;
  description: string;
  order: number;
  hidden: boolean;
}

export const costBlockBuildingInfoFragment = gql`
  fragment CostBlockBuildingInfoFields on CostBlockBuildingInfo {
    id
    costBlockId
    buildingId
    building {
      id
      name
      order
    }
    tg
    kg
    description
    order
    hidden
  }
`;

// export const COST_BLOCK_COST_LINES_QUERY = gql`
//   query costBlockWithCostLines($id: ID!) {
//     costBlock(id: $id) {
//       ...CostBlockFields
//       buildings {
//         id
//         name
//       }
//       # buildingsInfo {
//       #   id
//       #   building {
//       #     id
//       #     name
//       #   }
//       # }
//       costLines {
//         ...CostLineFields
//       }
//     }
//   }
//   ${costBlockFragment}
//   ${costLineFieldsFragment}
// `;

export const COST_BLOCK_BUILDING_INFO_CREATE_MUTATION = gql`
  mutation CostBlockBuildingInfoCreate(
    $input: CostBlockBuildingInfoCreateInput!
  ) {
    costBlockBuildingInfoCreate(input: $input) {
      id
      ...CostBlockBuildingInfoFields
    }
  }
  ${costBlockBuildingInfoFragment}
`;

export const COST_BLOCK_BUILDING_INFO_UPDATE_MUTATION = gql`
  mutation CostBlockBuildingInfoUpdate(
    $input: CostBlockBuildingInfoUpdateInput!
  ) {
    costBlockBuildingInfoUpdate(input: $input) {
      id
      ...CostBlockBuildingInfoFields
    }
  }
  ${costBlockBuildingInfoFragment}
`;

export const COST_BLOCK_BUILDING_INFO_DELETE_MUTATION = gql`
  mutation CostBlockBuildingInfoDelete(
    $input: CostBlockBuildingInfoDeleteInput!
  ) {
    costBlockBuildingInfoDelete(input: $input) {
      id
      ...CostBlockBuildingInfoFields
    }
  }
  ${costBlockBuildingInfoFragment}
`;

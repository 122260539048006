import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import { AddFormButton, Form, Row, TextField } from "~/form";
import {
  CreateParagraphInput,
  useCreateParagraphMutation,
} from "~/graphql-operations";
import { client } from "~/setup";

interface Props {
  textSectionId: string;
}

const NewParagraph: React.FC<Props> = ({ textSectionId }) => {
  const [createParagraph] = useCreateParagraphMutation();

  const onSubmit = async (input: CreateParagraphInput) =>
    createParagraph({
      variables: {
        input,
      },
    });

  return (
    <Form
      onSubmit={onSubmit}
      resetBlank
      defaultValues={{ textSectionId: textSectionId }}
    >
      <Row className="align-items-end">
        <Col>
          <TextField name="title" label="Tittel" />
        </Col>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <NewParagraph {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import React from "react";
import styled from "styled-components";
import { ReportLine } from "~/report";
import { EditPermissions } from "~/report/member";
import { Membership, Permission, updateMembership } from ".";

interface Props {
  membership: Membership;
}

const StyledReportMemberPermissions = styled(EditPermissions)`
  margin-left: 0.5rem;
  label {
    margin-bottom: 0;
  }
`;

const ConsultantPMReportLine: React.FC<Props> = ({
  membership: { id, report, permissions },
}) => {
  const updateMutation = updateMembership();
  const onChange = (permissions: Permission[]) => {
    return updateMutation({ id, permissions });
  };

  return (
    <ReportLine report={report} link>
      <StyledReportMemberPermissions
        permissions={permissions}
        onChange={onChange}
      />
    </ReportLine>
  );
};

export default ConsultantPMReportLine;

import styled, { css } from "styled-components";
import Canvas from "./Canvas";
import SVG from "./SVG";

const ChartWrapper = styled.div<{
  small?: boolean;
}>`
  font-size: 0.75rem;
  text-align: center;

  ${({ small }) =>
    small &&
    css`
      flex-basis: 20%;

      ${Canvas} {
        margin: 0.75rem auto;
        width: 100px;
        height: 100px;
      }

      ${SVG} {
        width: 100px;
        height: 100px;
      }
    `}
`;

export default ChartWrapper;

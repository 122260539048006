import styled from "styled-components";

const Block = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background-color: var(--smoke);
  border: 1px solid var(--mystic);
  border-radius: var(--border-radius);

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Block;

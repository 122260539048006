import React from "react";
import { Permission } from "~/Membership";
import {
  EditableMember,
  EditEmailPreferences,
  EditMemberLine,
} from "~/report/member";

// type EditableMember = EditMemberLineProps["member"] & {
//   membership: Pick<Membership, "preferences">;
// };

export interface Props {
  members: EditableMember[];
  disabledPermissions?: (m: EditableMember) => Permission[] | undefined;
}

const EditMemberList: React.FC<Props> = ({ members, disabledPermissions }) => {
  return (
    <>
      {members.map((member) => {
        const {
          membership,
          membership: { id },
        } = member;
        return (
          <EditMemberLine
            key={id}
            member={member}
            disabledPermissions={
              disabledPermissions && disabledPermissions(member)
            }
          >
            <EditEmailPreferences membership={membership} />
          </EditMemberLine>
        );
      })}
    </>
  );
};

export default EditMemberList;

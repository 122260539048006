import React, { FC } from "react";
import {
  ConstructionLoanPeriod,
  usePeriodReadyForReviewMutation,
} from "~/graphql-operations";
import ConfirmToggleButton from "~/layout/Button/ConfirmToggleButton";

interface Props {
  period: Pick<ConstructionLoanPeriod, "id" | "readyForReview">;
}

const ReadyForReview: FC<Props> = ({ period: { id, readyForReview } }) => {
  const [mutation] = usePeriodReadyForReviewMutation();
  const onConfirm = () =>
    mutation({
      variables: { input: { id, ready: !readyForReview } },
    });

  return (
    <ConfirmToggleButton
      value={readyForReview}
      variant="light"
      onConfirm={onConfirm}
    >
      Kunde revisjon
    </ConfirmToggleButton>
  );
};

export default ReadyForReview;

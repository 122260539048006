import { MutationUpdaterFn, useMutation } from "@apollo/client";
import { DocumentNode } from "graphql";
import { AttachableInput } from "~/form/attachment";
import {
  documentAdminFragment,
  documentClientFragment,
  DOCUMENT_ADMIN_CREATE_MUTATION,
} from ".";
import { Document, DOCUMENT_CREATE_MUTATION } from "./constants";

// Document exetends StoreObject

// type NewDocumentPayload = Omit<Document, "file"> & { file?: string };
export type NewClientDocumentPayload = Pick<
  AttachableInput<Document, "file">,
  "reportId" | "name" | "sender" | "preparedBy" | "date" | "file"
>;

export const useCreateClientDocument = () => {
  const [mutation] = useMutation(DOCUMENT_CREATE_MUTATION);

  return (input: NewClientDocumentPayload, update?: MutationUpdaterFn) =>
    mutation({
      variables: { input },
      update:
        update ||
        documentCreateCacheUpdate(
          documentClientFragment,
          "DocumentClientFields"
        ),
    });
};

type NewDocumentAdminPayload = NewClientDocumentPayload &
  Pick<Document, "visibility" | "receivedDate">;

export const useCreateAdminDocument = () => {
  const [mutation] = useMutation(DOCUMENT_ADMIN_CREATE_MUTATION);

  return (input: NewDocumentAdminPayload, update?: MutationUpdaterFn) =>
    mutation({
      variables: { input },
      update:
        update ||
        documentCreateCacheUpdate(documentAdminFragment, "DocumentAdminFields"),
    });
};

export const documentCreateCacheUpdate = (
  fragment: DocumentNode,
  fragmentName: string
): MutationUpdaterFn<{
  documentCreate: { document: Document; errors: string[] | null };
}> => (cache, { data }) => {
  const newDocument = data?.documentCreate?.document;
  if (!newDocument) {
    console.log("Errors", data?.documentCreate?.errors);
    throw new Error("Could not create document");
  }
  const { report } = newDocument;

  const newRef = cache.writeFragment({
    data: newDocument,
    fragment,
    fragmentName,
  });

  const cacheId = cache.identify(report);
  if (!cacheId) {
    console.log(report, newRef);
    throw new Error(`No cache found for report: ${report.id}`);
  }

  cache.modify({
    id: cacheId,
    fields: {
      documents: (refs) => [newRef, ...refs],
    },
  });
};

// export const useUpdateDocument = () => {
//   const [mutation] = useMutation(DOCUMENT_UPDATE_MUTATION);

//   return async (input: DocumentUpdatePayload) => {
//     const res = await mutation({
//       variables: {
//         input,
//       },
//     });

//     if (res?.data?.documentUpdate?.errors?.length > 0)
//       throw new Error(res.data.documentUpdate.errors.join("\n"));

//     return res;
//   };
// };

// export const useDeleteDocument = () => {
//   const [mutation] = useMutation(DOCUMENT_DELETE_MUTATION);

//   return (id: Document["id"], update?: MutationUpdaterFn) =>
//     mutation({
//       variables: {
//         input: { id },
//       },
//       update:
//         update ||
//         ((cache, { data }) => {
//           const period = data?.documentDelete?.document?.period;
//           if (!period) throw new Error("Cannot update cache");

//           cache.modify({
//             id: cache.identify(period),
//             fields: {
//               documents: (refs, { readField }) =>
//                 refs.filter((ref: Reference) => id !== readField("id", ref)),
//             },
//           });
//         }),
//     });
// };

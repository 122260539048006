import { MutationUpdaterFn, Reference, useMutation } from "@apollo/client";
import {
  DocumentationBlock,
  documentationBlockFragment,
  DOCUMENTATION_BLOCK_CREATE_MUTATION,
  DOCUMENTATION_BLOCK_DELETE_MUTATION,
  DOCUMENTATION_BLOCK_UPDATE_MUTATION,
} from ".";
import { DocumentationSection } from "../constants";

export const createDocumentationBlockMutation = (
  documentationSection: DocumentationSection
) => {
  const [createDocumentationBlock] = useMutation(
    DOCUMENTATION_BLOCK_CREATE_MUTATION
  );

  return (input: Partial<DocumentationBlock>) => {
    return createDocumentationBlock({
      variables: {
        input: {
          ...input,
          documentationSectionId: documentationSection.id,
        },
      },
      update: (
        cache,
        {
          data: {
            documentationBlockCreate: { documentationBlock },
          },
        }
      ) => {
        const newRef = cache.writeFragment({
          data: documentationBlock,
          fragment: documentationBlockFragment,
          fragmentName: "DocumentationBlockFields",
        });

        cache.modify({
          id: cache.identify(documentationSection),
          fields: {
            // documentationBlocksCount: (count: number) => count + 1,
            documentationBlocks: (refs, { readField }) =>
              // [...refs, newRef].sort((a, b) => a.title.localeCompare(b.title)),
              [...refs, newRef].sort((a, b) => {
                const [aTitle, bTitle] = [a, b].map(
                  (ref) => readField("title", ref) as string
                );
                return aTitle.localeCompare(bTitle);
              }),
          },
        });
      },
    });
  };
};

export const updateDocumentationBlockMutation = (id: string) => {
  const [update] = useMutation(DOCUMENTATION_BLOCK_UPDATE_MUTATION);

  return (input: Partial<DocumentationBlock>) => {
    return update({
      variables: {
        input: {
          ...input,
          id,
        },
      },
    });
  };
};

export const deleteDocumentationBlockMutation = (
  documentationSection?: DocumentationSection
) => {
  const [mutation] = useMutation(DOCUMENTATION_BLOCK_DELETE_MUTATION);

  return (id: string, update?: MutationUpdaterFn) =>
    mutation({
      variables: {
        input: {
          id,
        },
      },
      update:
        update ||
        ((cache: ApolloCache) => {
          if (!documentationSection) return;
          cache.modify({
            id: cache.identify(documentationSection),
            fields: {
              documentationBlocks: (refs: Reference[], { readField }) =>
                refs.filter((ref: Reference) => id !== readField("id", ref)),
            },
          });
        }),
    });
};

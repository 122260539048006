import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Form as BSForm, FormProps as BSFormProps } from "react-bootstrap";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import styled, { css } from "styled-components";
import { mutationErrors, useMounted } from "~/util";
import { FormErrors, useFormAutoSave } from ".";
import { HiddenField } from "./InputField";

export interface FormProps<T extends FieldValues = FieldValues>
  extends Omit<BSFormProps, "onSubmit" | "autoSave"> {
  formMethods?: UseFormReturn<T>;
  defaultValues?: DefaultValues<T>;
  values?: DefaultValues<T>; // will reset form on change
  onSubmit?: SubmitHandler<T>;
  // onSubmit?: (
  //   values: Record<string, unknown>,
  //   event?:
  //     | React.BaseSyntheticEvent<Record<string, unknown>, unknown, unknown>
  //     | undefined
  // ) => Promise<unknown>;
  autoSave?: number | boolean;
  noReset?: boolean;
  resetBlank?: boolean;
  horizontal?: boolean;
}

const StyledForm = styled(BSForm)`
  .form-group {
    position: relative;
  }
  > .form-group:last-child {
    margin-bottom: 0;
  }
  .form-label {
    font-weight: normal;
  }
  .invalid-feedback {
    display: initial;
  }

  ${({ $horizontal }) =>
    $horizontal &&
    css`
      width: auto;

      .row,
      .form-row {
        align-items: flex-end;
      }
      .form-row {
        margin-top: 10px;
        margin-bottom: 10px;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .form-group,
      .input-group {
        margin-bottom: 0;
      }
      .form-check {
        margin-top: 0.5rem;
        margin-bottom: 0.675rem;
      }
      .align-items-center .form-check {
        margin-bottom: 0.25rem;
      }
    `}
`;

// TODO: find a way to reuse useFormAutoSave arguments - Parameters<typeof useFormAutoSave> ?
type AutoSaveProps<T extends FieldValues> = {
  onSubmit: SubmitHandler<T>;
  delay?: number;
};
const AutoSave = <T extends FieldValues>({
  onSubmit,
  delay,
}: AutoSaveProps<T>) => {
  useFormAutoSave<T>(onSubmit, { delay });
  return null;
};

// const ResetWatchValues = ({ reset, values }) => {
//   useDeepUpdateEffect(() => reset(values), [values]);
//   return null;
// };

// https://codesandbox.io/s/react-hook-form-auto-save-xgulp?file=/src/index.js
// AutoSave ideas: https://gist.github.com/dbousamra/37d67e2f7b43261d424e7b0d75032d5b
const Form = <T extends FieldValues>({
  formMethods: propFormMethods,
  defaultValues = undefined,
  values = undefined,
  onSubmit = () => Promise.resolve(),
  autoSave = false,
  noReset = false,
  resetBlank = false,
  horizontal = false,
  children,
  ...rest
}: FormProps<T>): React.ReactElement => {
  const isMounted = useMounted();
  const initialValues = defaultValues || values;
  const [submittedValues, setSubmittedValues] = useState<T | undefined>();
  const [errors, setErrors] = useState<string[]>([]);

  // FIXME: always calls useForm to avoid conditional hooks
  const hookFormMethods = useForm<T>({
    defaultValues,
    values,
    mode: "all",
  });
  const formMethods = propFormMethods || hookFormMethods;
  const {
    formState: { isSubmitSuccessful },
    handleSubmit,
    reset,
  } = formMethods;

  // TODO: if watch/values? do not reset?
  useEffect(() => {
    if (noReset) return;
    // TODO: every submit in graphql is successful!
    if (isSubmitSuccessful && !errors.length) {
      resetBlank
        ? reset(initialValues ? cloneDeep(initialValues) : undefined)
        : reset(submittedValues, { keepValues: true });
      // without full reset (with values) changing input state back to initialValues causes isDirty to be false
    }
  }, [
    reset,
    isMounted,
    isSubmitSuccessful,
    initialValues,
    submittedValues,
    errors,
    noReset,
    resetBlank,
  ]);

  const mySubmit: SubmitHandler<T> = async (input) => {
    const response = await onSubmit(input);

    if (isMounted()) {
      setSubmittedValues(input);

      // TODO: not all forms deal with mutations - MutationForm?
      if (response) setErrors(mutationErrors(response));

      // if (noReset || !mutationSuccess(response)) return response;

      // if (resetBlank) {
      //   reset(initialValues ? cloneDeep(initialValues) : undefined);
      // } else {
      //   reset(undefined, {
      //     keepValues: true,
      //     keepSubmitCount: true,
      //   });
      //   // https://github.com/react-hook-form/react-hook-form/issues/8231#issuecomment-1106256702
      //   // however next keystroke it will measure again your current formValues with defaultValues,
      //   // which leads dirty to update again.
      // }
    }
    return response;
  };

  return (
    <StyledForm
      {...rest}
      $horizontal={horizontal}
      onSubmit={handleSubmit(mySubmit)}
    >
      <FormProvider {...formMethods}>
        {errors.length > 0 && <FormErrors errors={errors} />}

        {autoSave !== false && (
          <AutoSave<T>
            onSubmit={mySubmit}
            delay={
              Number.isSafeInteger(autoSave) ? (autoSave as number) : undefined
            }
          />
        )}
        {/* {watchValues && <ResetWatchValues reset={reset} values={watchValues} />} */}

        {initialValues && initialValues.id && <HiddenField name="id" />}
        {children}
      </FormProvider>
    </StyledForm>
  );
};

export default Form;

import React from "react";
import { CheckboxField, Form } from "~/form";
import {
  ConstructionLoan,
  ConstructionLoanUpdateInput,
  useClientBudgetDisplayQuery,
  useSetClientBudgetDisplayMutation,
} from "~/graphql-operations";

interface Props {
  report: Pick<ConstructionLoan, "id">;
}

const EditCategoriesDisplay: React.FC<Props> = ({ report: { id } }) => {
  const [setDisplay] = useSetClientBudgetDisplayMutation();
  const { data, loading, error } = useClientBudgetDisplayQuery({
    variables: { id },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  const clientBudgetGrouped = data?.constructionLoan?.clientBudgetGrouped;

  const onSubmit = async (input: ConstructionLoanUpdateInput) =>
    setDisplay({ variables: { input } });

  // const options = [
  //   {
  //     label: "Grouped",
  //     value: true,
  //   },
  //   {
  //     label: "Ungrouped",
  //     value: false,
  //   },
  // ];

  return (
    <Form
      autoSave
      horizontal
      defaultValues={{ id, clientBudgetGrouped }}
      onSubmit={onSubmit}
      id="edit-client-budget-display"
    >
      <CheckboxField
        name="clientBudgetGrouped"
        label="Vis gruppert"
        className="ml-3"
      />
      {/* <RadioField
        name="clientBudgetGrouped"
        label="BH-Kostnad display"
        options={options}
      /> */}
    </Form>
  );
};

export default EditCategoriesDisplay;

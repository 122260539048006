import React from "react";
import { SelectField } from "~/form";
import { SyncSelectFieldProps } from "~/form/SelectField";
import { InvoiceCategory, InvoiceCategoryTypeEnum } from "~/graphql-operations";
import { toOptions } from ".";
import { invoiceCategoryTypeLabel } from "../category-type";

interface Props extends SyncSelectFieldProps<InvoiceCategory> {
  categories: InvoiceCategory[];
  loading?: boolean;
}

const toGroupedOptions = (categories: Props["categories"]) => {
  return [
    {
      label: invoiceCategoryTypeLabel(InvoiceCategoryTypeEnum.Additional),
      options: toOptions(
        categories.filter(
          (category) => category.type === InvoiceCategoryTypeEnum.Additional
        )
      ),
    },
    {
      label: invoiceCategoryTypeLabel(InvoiceCategoryTypeEnum.Client),
      options: toOptions(
        categories.filter(
          (category) => category.type === InvoiceCategoryTypeEnum.Client
        )
      ),
    },
  ];
};

const InvoiceCategorySelectField: React.FC<Props> = ({
  categories = [],
  name,
  label,
  loading,
  ...rest
}) => {
  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={loading ? [] : toGroupedOptions(categories)}
      {...rest}
    />
  );
};

export default InvoiceCategorySelectField;

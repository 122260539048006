import moment from "moment";
import React, { useState } from "react";
import { Col, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import {
  AddFormButton,
  DateField,
  FileField,
  Form,
  Row,
  SelectField,
  TextField,
} from "~/form";
import { uploadFile } from "~/form/attachment";
import { useSession } from "~/session";
import { parseDate } from "~/util";
import { Document, visibilityOptions } from ".";
import {
  NewClientDocumentPayload,
  useCreateAdminDocument,
  useCreateClientDocument,
} from "./actions";

interface Props {
  reportId: string;
}

export interface NewDocument extends Omit<NewClientDocumentPayload, "file"> {
  file: FileList;
}

const StyledDocumentForm = styled(Form)`
  width: calc(100% + 2rem);
  margin: 1rem -1rem;
  padding: 0.5rem 1rem;
  background-color: var(--mystic);
  border-radius: 4px;
`;

const NewDocumentForm: React.FC<Props> = ({ reportId }) => {
  const { generalEditor } = useSession();
  const createClientDocument = useCreateClientDocument();
  const createAdminDocument = useCreateAdminDocument();
  const [createUploadProgress, setCreateUploadProgress] = useState<
    number | undefined
  >(undefined);

  const createDocument = generalEditor
    ? createAdminDocument
    : createClientDocument;

  const onCreate = async (input: NewDocument) => {
    const { file, date } = input;
    const signedId = file.length
      ? await uploadFile(file[0], (progress) =>
          setCreateUploadProgress(progress)
        )
      : undefined;
    // Array.from(files).forEach(file => uploadFile(file));

    await createDocument({
      ...input,
      reportId,
      file: signedId,
      date: parseDate(date),
    });

    setCreateUploadProgress(undefined);
  };

  let defaultValues = {
    reportId,
    date: moment().format("YYYY-MM-DD"),
  } as Document;

  if (generalEditor)
    defaultValues = {
      ...defaultValues,
      visibility: "private",
      receivedDate: moment().format("YYYY-MM-DD"),
    };

  return (
    <StyledDocumentForm
      defaultValues={defaultValues}
      onSubmit={onCreate}
      resetBlank
      horizontal
    >
      <Row>
        {generalEditor && (
          <Col lg="2">
            <SelectField name="visibility" options={visibilityOptions} />
          </Col>
        )}
        <Col lg={generalEditor ? 2 : 4}>
          <TextField name="name" required />
        </Col>
        <Col lg="2">
          <TextField name="sender" required />
        </Col>
        <Col lg="2">
          <TextField name="preparedBy" />
        </Col>
        <Col>
          <DateField name="date" notClearable />
        </Col>
        {generalEditor && (
          <Col>
            <DateField name="receivedDate" label="Received Date" />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg="auto" className="d-flex flex-wrap align-items-center">
          <FileField
            name="file"
            required={!generalEditor}
            progress={createUploadProgress}
          />
        </Col>
        <Col lg="12">
          <FormGroup style={{ margin: "1rem 0 0.5rem 0" }}>
            <AddFormButton />
          </FormGroup>
        </Col>
      </Row>
    </StyledDocumentForm>
  );
};

export default NewDocumentForm;

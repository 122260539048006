import { pick } from "lodash";
import { attachmentInput } from "~/form/attachment";
import { nodeAttributeLabel } from "~/node";
import { AttachableInput } from "./../../form/attachment/constants";
import { RequestSection } from "./constants";

export const toFormData = (
  requestSection: Partial<RequestSection>
): Partial<AttachableInput<RequestSection, "srlFiles">> => {
  const { srlFiles } = requestSection;
  return {
    ...pick(requestSection, [
      "description",
      "srlTitle",
      "srlAnsweredBy",
      "srlAnswer",
      "srlReference",
    ]),
    srlFiles: srlFiles ? attachmentInput(srlFiles) : undefined,
  };
};

export enum RequestType {
  // HOME_VARRANT = "home_varrant",
  PBL = "pbl",
  BUILDING_REGULATION = "building_regulation",
  TEK = "tek",
  SAK = "sak",
  FOB = "fob",
  NEK = "nek",
  IK_REGULATION = "ik_regulation",
  ENERGY = "energy",
  RECOMMENDED = "recommended",

  POLLUTION_ACT = "pollution_act",
  WASTE_REGULATIONS = "waste_regulations",
  MUNICIPAL_GUIDES = "municipal_guides",
  POLLUTION_REGULATIONS = "pollution_regulations",
  BREEAM_IN_USE = "breeam_in_use",
  TEK_17_SAK_10 = "tek-17_sak-10",
  WORKING_ENVIRONMENT_ACT = "working_environment_act",
  PRODUCT_CONTROL_ACT = "product_control_act",
  CULTURAL_HERITAGE_ACT = "cultural_heritage_act",
  NVE_1_2019 = "nve_1-2019",
  TEK_17 = "tek-17",
  NATURAL_DIVERSITY_ACT = "natural_diversity_act",
  REGULATIONS_ON_SHELTERS = "regulations_on_shelters",
}

export const requestTypeOptions = [
  // { value: RequestType.HOME_VARRANT, label: "Hjemlet i/ årsak" },
  { value: RequestType.PBL, label: "PBL" },
  { value: RequestType.BUILDING_REGULATION, label: "Byggeforskrift" },
  { value: RequestType.TEK, label: "TEK" },
  { value: RequestType.SAK, label: "SAK" },
  { value: RequestType.FOB, label: "FOB" },
  { value: RequestType.NEK, label: "NEK" },
  { value: RequestType.IK_REGULATION, label: "IK-forskrift" },
  { value: RequestType.ENERGY, label: "Energim. fors." },
  { value: RequestType.RECOMMENDED, label: "Anbefales dokumentasjon" },
  { value: RequestType.POLLUTION_ACT, label: "Forurensningsloven" },
  { value: RequestType.WASTE_REGULATIONS, label: "Avfallsforskriften" },
  { value: RequestType.MUNICIPAL_GUIDES, label: "Kommunale føringer" },
  {
    value: RequestType.POLLUTION_REGULATIONS,
    label: "Forurensningsforskriften",
  },
  { value: RequestType.BREEAM_IN_USE, label: "BREEAM In-Use m.fl." },
  { value: RequestType.TEK_17_SAK_10, label: "TEK-17/SAK-10" },
  { value: RequestType.WORKING_ENVIRONMENT_ACT, label: "Arbeidsmiljøloven" },
  { value: RequestType.PRODUCT_CONTROL_ACT, label: "Produktkontrolloven" },
  { value: RequestType.CULTURAL_HERITAGE_ACT, label: "Kulturminneloven" },
  { value: RequestType.NVE_1_2019, label: "NVE 1/2019" },
  { value: RequestType.TEK_17, label: "TEK-17" },
  { value: RequestType.NATURAL_DIVERSITY_ACT, label: "Naturmangfoldloven" },
  {
    value: RequestType.REGULATIONS_ON_SHELTERS,
    label: "Forskrift om tilfluktsrom",
  },
];

export const requestTypeLabel = nodeAttributeLabel(requestTypeOptions);

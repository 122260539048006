import React from "react";
import { Form, FormControlProps } from "react-bootstrap";
import styled from "styled-components";

const StyledInput = styled(Form.Control)`
  padding-top: 0;
  padding-bottom: 0;
  border-color: transparent;
  border-radius: 0;

  &:hover {
    border-bottom-color: var(--dark-gray);
  }
  &:focus {
    border-color: transparent;
    border-bottom-color: var(--info);
    box-shadow: none;
  }

  /* &::-webkit-inner-spin-button {
    display: none;
  } */
`;

const InlineInput: React.ForwardRefRenderFunction<
  typeof Form.Control,
  FormControlProps
> = ({ type = "text", ...rest }, ref) => {
  return <StyledInput type={type} {...rest} ref={ref} />;
};

// const BlockListItem = React.forwardRef<HTMLLIElement, Props>(
//   ({ children, ...rest }, ref) => {
//     return (
//       <StyledBlockListItem {...rest} ref={ref}>
//         {children}
//       </StyledBlockListItem>
//     );
//   }
// );

const InlineInputForwardedRef = React.forwardRef<FormControlProps>(InlineInput);
InlineInputForwardedRef.displayName = "InlineInputForwardedRef";

export default InlineInputForwardedRef;

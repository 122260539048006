import React from "react";
import { attachmentInput } from "~/form/attachment";
import { ConstructionLoanPeriod } from "~/graphql-operations";
import EditPeriodForm from "./EditPeriodForm";
import PayoutFields from "./PayoutFields";

interface Props {
  period: ConstructionLoanPeriod;
}

const EditPeriod: React.FC<Props> = ({
  period: {
    id,
    payout,
    payout: { information, manualAmount, recommendation, files },
  },
}) => {
  const defaultValues = {
    id,
    payout: {
      information,
      manualAmount,
      recommendation,
      files: files ? attachmentInput(files) : undefined,
    },
  };

  return (
    <EditPeriodForm horizontal defaultValues={defaultValues}>
      <PayoutFields payout={payout} />
    </EditPeriodForm>
  );
};

export default EditPeriod;

import React from "react";
import { Col } from "react-bootstrap";
import { AttachmentField, DateField, Form, Row, TextField } from "~/form";
import { attachmentInput } from "~/form/attachment";
import { Invoice } from "~/graphql-operations";
import { Currency } from "~/util/currency";
import { InvoiceAmountFields, useUpdateAffiliateInvoice } from ".";

interface Props {
  invoice: Invoice;
  currencies: Currency[];
}

// Affiliate members edit invoice - client/external/contractor
const EditAffiliateInvoice: React.FC<Props> = ({
  invoice: { id, sender, number, dueDate, amount, currency, file },
  currencies,
}) => {
  const updateMutation = useUpdateAffiliateInvoice();

  const defaultValues = {
    id,
    sender,
    number,
    dueDate,
    amount,
    currency,
    file: file && attachmentInput(file),
  };

  return (
    <Form
      autoSave
      horizontal
      defaultValues={defaultValues}
      onSubmit={updateMutation}
      id={`invoice-${id}-form`}
    >
      <Row className="align-items-end">
        <Col>
          <TextField name="sender" label="Avsender" required />
        </Col>
        <Col lg="2">
          <TextField name="number" label="Faktura" required />
        </Col>
        <Col lg="2">
          <DateField name="dueDate" label="Faktura dato" />
        </Col>
        <Col lg="auto">
          <InvoiceAmountFields currencies={currencies} />
        </Col>
      </Row>

      <AttachmentField
        name="file"
        label="Fakturafil"
        attachments={file}
        noDelete
      />
    </Form>
  );
};

export default EditAffiliateInvoice;

import React from "react";
import { Col } from "react-bootstrap";
import {
  AddFormButton,
  Form,
  FormProps,
  NumberField,
  Row,
  TextField,
} from "~/form";
import { BuildingPart } from "~/graphql-operations";

type FormValues = Partial<BuildingPart>;

interface Props extends FormProps<FormValues> {
  onSubmit: (input: FormValues) => void;
}

const NewBuildingPartForm: React.FC<Props> = ({ onSubmit, ...rest }) => {
  return (
    <Form onSubmit={onSubmit} resetBlank horizontal {...rest}>
      <Row>
        <Col lg="2">
          <TextField name="floor" label="Etasje" />
        </Col>
        <Col lg="4">
          <TextField name="usage" label="Bruk" required />
        </Col>
        <Col lg="3">
          <NumberField
            name="area"
            label={
              <>
                BTA m<sup>2</sup>
              </>
            }
            step={0.1}
          />
        </Col>
        <Col>
          <AddFormButton>Legg til bygningsdel</AddFormButton>
        </Col>
      </Row>
    </Form>
  );
};

export default NewBuildingPartForm;

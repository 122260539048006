import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Fieldset, FieldsetTitle } from "~/form";
import { WorkingAreaEnum } from "~/graphql-operations";
import { client } from "~/setup";
import ReportConsultantsForm from "../form/ReportConsultantsForm";

interface Props {
  report: {
    slug: string;
  };
}

const EditConsultants: React.FC<Props> = ({ report }) => (
  <ApolloProvider client={client}>
    <Fieldset>
      <FieldsetTitle>Konsulenter</FieldsetTitle>
      <ReportConsultantsForm
        report={report}
        workingArea={WorkingAreaEnum.Construction}
      />
      <ReportConsultantsForm
        report={report}
        workingArea={WorkingAreaEnum.Plumbing}
      />
      <ReportConsultantsForm
        report={report}
        workingArea={WorkingAreaEnum.Electrical}
      />
      <ReportConsultantsForm
        report={report}
        workingArea={WorkingAreaEnum.Fire}
      />
    </Fieldset>
  </ApolloProvider>
);

export default EditConsultants;

import { ApolloProvider, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { ReportSelectFieldProps } from "~/report/form/ReportSelectField";
import { client } from "~/setup";
import { CompanyFilter, CompanyIndex, NewCompany } from ".";
import { COMPANIES_QUERY, Company } from "./constants";

interface Props {
  companiesCount: number;
}

export const onReportSelect: ReportSelectFieldProps["onChange"] = ({
  data,
}) => {
  if (!data) return;
  const { lockedAt, urls } = data;
  window.location.href = lockedAt ? urls?.show : urls?.edit;
};

const CompaniesPage: React.FC<Props> = ({ companiesCount }) => {
  const [companies, setCompanies] = useState<Company[]>();
  const { loading, error, data } = useQuery<{
    companies: Company[];
  }>(COMPANIES_QUERY);

  const allCompanies = data?.companies;

  if (error) return <h2>Error</h2>;

  const onSubmit = ({ name }: Pick<Company, "name">) => {
    const filteredCompanies = (
      allCompanies || []
    ).filter(({ name: currentName }: Company) =>
      currentName.toLowerCase().includes(name.toLowerCase())
    );
    setCompanies(filteredCompanies);
  };

  return (
    <>
      <CompanyFilter onSubmit={onSubmit} onReportSelect={onReportSelect} />
      <CompanyIndex
        loading={loading}
        companies={companies || allCompanies || []}
        companiesCount={companiesCount}
      />

      <NewCompany />
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props) => (
  <ApolloProvider client={client}>
    <CompaniesPage {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import gql from "graphql-tag";
import {
  InvoiceEntryCategory,
  InvoiceEntryCategoryGroup,
} from "~/graphql-operations";
import { invoiceEntryCategorizationFragment } from "~/invoice/entry/categorization/fragments";

export type InvoiceEntryCategorization =
  | InvoiceEntryCategory
  | InvoiceEntryCategoryGroup;

export type VirtualGroup = {
  id: string;
  __typename: string;
  categories: InvoiceEntryCategory[];
};

export type VirtualCategorization = InvoiceEntryCategoryGroup | VirtualGroup;

export type ExtendedCategorization =
  | InvoiceEntryCategory
  | InvoiceEntryCategoryGroup
  | VirtualGroup;

export const INVOICE_ENTRY_CATEGORIZATIONS_SORT_MUTATION = gql`
  mutation InvoiceEntryCategorizationsSort(
    $input: InvoiceEntryCategorizationsSortInput!
  ) {
    invoiceEntryCategorizationsSort(input: $input) {
      contractor {
        ...InvoiceEntryCategorizationFields
      }
    }
  }
  ${invoiceEntryCategorizationFragment}
`;

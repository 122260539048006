import React from "react";
import { WordLoader } from "~/layout/Loading";
import { useMaintenanceDuration } from "../maintenance";
import { ShortcodeProps } from "./Shortcode";

const RapportVedlikeholdslengde: React.FC<ShortcodeProps> = ({ report }) => {
  const { duration, loading, error } = useMaintenanceDuration(report);

  if (error) return <p>Error</p>;

  return <>{loading ? <WordLoader /> : duration && `${duration || 0} år`}</>;
};

export default RapportVedlikeholdslengde;

import { gql } from "@apollo/client";
import { Attachment } from "~/attachment";
import { ResourceInterface } from "~/node";
import { CostBlockTemplate } from "~/sections/cost-section/block-template";
import { RequestCostLine } from "~/sections/request-section/cost-line";
import {
  budgetLineFragment,
  buildingPartFragment,
  HousingMaintenanceSection,
} from "..";
import { BudgetLine } from "../budget-line";

export interface BuildingPart extends ResourceInterface {
  housingMaintenanceSectionId?: string;
  housingMaintenanceSection?: HousingMaintenanceSection;

  costBlockTemplateId?: string;
  costBlockTemplate?: CostBlockTemplate;
  costBlockTemplateTitle: string;
  costBlockTemplateNumber: number;

  name: string;
  tg: number;
  kg: number;
  rating: number;
  period: BuildingPartPeriod;
  description: string;
  descriptionDeviation: string;
  comment: string;
  order: number;

  costEstimate: number | null;
  cost: number | null;

  status: RequestCostLine["status"];
  budgetLinesCount: number;
  budgetLines: BudgetLine[] | null;

  files: Attachment[];
}

export type BuildingPartPeriod =
  | "none"
  | "immediate"
  | "1_5_years"
  | "6_10_years"
  | null;

export const BUILDING_PART_BUDGET_LINES_QUERY = gql`
  query HousingMaintenanceBuildingPartBudgetLines($id: ID!) {
    housingMaintenanceBuildingPart(id: $id) {
      id
      ...HousingMaintenanceBuildingPartFields
      budgetLines {
        ...HousingMaintenanceBudgetLineFields
      }
    }
  }
  ${buildingPartFragment}
  ${budgetLineFragment}
`;

export const BUILDING_PART_CREATE_MUTATION = gql`
  mutation HousingMaintenanceBuildingPartCreate(
    $input: HousingMaintenanceBuildingPartCreateInput!
  ) {
    housingMaintenanceBuildingPartCreate(input: $input) {
      id
      ...HousingMaintenanceBuildingPartFields
    }
  }
  ${buildingPartFragment}
`;

export const BUILDING_PART_UPDATE_MUTATION = gql`
  mutation HousingMaintenanceBuildingPartUpdate(
    $input: HousingMaintenanceBuildingPartUpdateInput!
  ) {
    housingMaintenanceBuildingPartUpdate(input: $input) {
      id
      ...HousingMaintenanceBuildingPartFields
    }
  }
  ${buildingPartFragment}
`;

export const BUILDING_PART_DELETE_MUTATION = gql`
  mutation HousingMaintenanceBuildingPartDelete(
    $input: HousingMaintenanceBuildingPartDeleteInput!
  ) {
    housingMaintenanceBuildingPartDelete(input: $input) {
      id
      housingMaintenanceSection {
        id
      }
    }
  }
`;

export const BUILDING_PARTS_SORT_MUTATION = gql`
  mutation HousingMaintenanceBuildingPartsSort(
    $input: HousingMaintenanceBuildingPartsSortInput!
  ) {
    housingMaintenanceBuildingPartsSort(input: $input) {
      id
      order
    }
  }
`;

import React, { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { InputFieldProps, TextAreaField } from "./InputField";

type Props = InputFieldProps;

/**
 *
 * FIXME: cannot receive updated value, e.g. with values
 *
 */
const RedactorFieldOld: React.FC<Props> = ({ name, label, ...rest }) => {
  const { setValue } = useFormContext();
  const textAreaRef = useRef<HTMLTextAreaElement>();

  // a bad way to get content
  // const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
  //   const html = e.currentTarget.innerHTML;
  //   setValue(name, html, { shouldDirty: true });
  // };

  // not a bad way to get content :D
  const onChange = () => {
    const html = window.$R(textAreaRef.current, "source.getCode");
    setValue(name, html, { shouldDirty: true });
  };

  useEffect(() => {
    const extraOptions: Record<string, unknown> = {};
    const limit = textAreaRef.current?.dataset["editorLimit"];
    if (limit) extraOptions.limiter = limit;

    window.$R(textAreaRef.current, {
      minHeight: "100px",
      imageUpload: "/upload",
      imageData: null,
      imageFigure: false,
      imageEditable: false,
      imageLink: false,
      imageCaption: false,
      imagePosition: false,
      imageResizable: false,
      buttonsAdd: ["sup", "sub"],
      plugins: ["table", "variable", "limiter"],
      variables: window.REDACTOR_EDITOR_VARIABLES,
      callbacks: {
        // keyup: onKeyUp,
        keyup: onChange,
        inserted: onChange,
      },
      ...extraOptions,
    });

    return () => window.$R(textAreaRef.current, "destroy");
  }, []);

  return (
    <TextAreaField ref={textAreaRef} name={name} label={label} {...rest} />
  );
};

export default RedactorFieldOld;

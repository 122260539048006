import { get } from "lodash";
import React, { ReactNode, useRef } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { ButtonProps, Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Attachment, AttachmentList } from "~/attachment";
import { FieldError } from "~/form";
import { UploadStatus } from "~/form/attachment";
import { IconButton } from "~/layout";

interface AttachmentFieldProps {
  accept?: string;
  attachments?: Attachment | Attachment[] | null;
  className?: string;
  disposition?: "inline" | "attachment";
  label?: string | ReactNode;
  multiple?: boolean;
  name: string;
  noDelete?: boolean;
  size?: ButtonProps["size"];
  cards?: boolean;
}

const StyledAttachmentList = styled(AttachmentList)``;

const UploadButtonWrap = styled.div``;

const Wrap = styled.div<{ $multiple: boolean }>`
  display: ${({ $multiple }) => ($multiple ? "block" : "flex")};
  align-items: center;

  ${StyledAttachmentList} {
    margin: ${({ $multiple }) => ($multiple ? "0 0 0.5rem 0" : "0 0 0 0.5rem")};
  }

  ${UploadButtonWrap} {
    order: -1;
  }
`;

const arrayedAttachments = (attachments: AttachmentFieldProps["attachments"]) =>
  Array.isArray(attachments)
    ? attachments
      ? attachments
      : []
    : attachments
    ? [attachments]
    : [];

const AttachmentField: React.FC<AttachmentFieldProps> = ({
  name,
  label = name,
  multiple,
  size = "sm",
  noDelete = false,
  attachments: attachmentOrAttachments,
  disposition,
  cards = false,
  className,
  accept,
  ...rest
}) => {
  const attachments = arrayedAttachments(attachmentOrAttachments);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const handleAttachment = (signedIds: string[]) => {
    const value = multiple
      ? [...attachments.map((a) => a.signedId), ...signedIds]
      : signedIds[0];
    setValue(name, value, { shouldDirty: true });
  };

  const onDelete = ({ signedId }: Attachment) => {
    const value = multiple
      ? attachments.map((a) => a.signedId).filter((sid) => sid !== signedId)
      : null;
    setValue(name, value, { shouldDirty: true });
    return Promise.resolve();
  };

  const UploadButton = () => (
    <UploadButtonWrap>
      <IconButton
        icon="upload"
        size={size}
        onClick={() => fileUploadRef.current?.click?.()}
      >
        {multiple && "Last opp"}
      </IconButton>
    </UploadButtonWrap>
  );

  const error = get(errors, name);

  return (
    <Form.Group className={className}>
      {label && <Form.Label>{label}</Form.Label>}

      {/* {!multiple && <UploadButton />} */}

      <DirectUploadProvider
        onSuccess={handleAttachment}
        render={({ handleUpload, uploads, ready }) => (
          <Wrap $multiple={!!multiple}>
            {attachments.length > 0 && (
              <StyledAttachmentList
                cards={cards}
                attachments={attachments}
                disposition={disposition}
                onDelete={noDelete ? undefined : onDelete}
              />
            )}

            <Controller
              name={name}
              control={control}
              render={() => <></>}
              defaultValue={null}
              {...rest}
            />

            <Form.File
              ref={fileUploadRef}
              disabled={!ready}
              multiple={multiple}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleUpload(e.currentTarget.files)
              }
              accept={accept}
              className="d-none"
            />

            {uploads.length > 0 && <UploadStatus uploads={uploads} />}
            <UploadButton />
          </Wrap>
        )}
      />

      {error && <FieldError error={error} />}
    </Form.Group>
  );
};

export default AttachmentField;

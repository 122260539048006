import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node/constants";
import {
  RequestCostLineTemplate,
  requestCostLineTemplateFragment,
} from "../cost-line-template";

export interface RequestCostBlockTemplate extends ResourceInterface {
  title: string;
  number: number;
  internalUse: boolean;
  requestCostLineTemplates: RequestCostLineTemplate[];
}
export type CreateRequestCostBlockTemplateInput = Pick<
  RequestCostBlockTemplate,
  "number" | "title" | "internalUse"
>;
export type UpdateRequestCostBlockTemplateInput = Pick<
  RequestCostBlockTemplate,
  "title" | "number" | "id" | "internalUse"
>;

export const requestCostBlockTemplateFragment = gql`
  fragment RequestCostBlockTemplateFields on RequestCostBlockTemplate {
    id
    title
    number
    createdAt
    internalUse
  }
`;

export const REQUEST_COST_BLOCK_TEMPLATES_QUERY = gql`
  query requestCostBlockTemplates {
    requestCostBlockTemplates {
      ...RequestCostBlockTemplateFields
      requestCostLineTemplates {
        ...RequestCostLineTemplateFields
      }
    }
  }
  ${requestCostBlockTemplateFragment}
  ${requestCostLineTemplateFragment}
`;

export const REQUEST_COST_BLOCK_TEMPLATE_CREATE_MUTATION = gql`
  mutation CreateRequestCostBlockTemplate(
    $input: CreateRequestCostBlockTemplateInput!
  ) {
    createRequestCostBlockTemplate(input: $input) {
      errors
      requestCostBlockTemplate {
        ...RequestCostBlockTemplateFields
        requestCostLineTemplates {
          ...RequestCostLineTemplateFields
        }
      }
    }
  }
  ${requestCostBlockTemplateFragment}
  ${requestCostLineTemplateFragment}
`;

export const REQUEST_COST_BLOCK_TEMPLATE_UPDATE_MUTATION = gql`
  mutation UpdateRequestCostBlockTemplate(
    $input: UpdateRequestCostBlockTemplateInput!
  ) {
    updateRequestCostBlockTemplate(input: $input) {
      errors
      requestCostBlockTemplate {
        ...RequestCostBlockTemplateFields
        requestCostLineTemplates {
          ...RequestCostLineTemplateFields
        }
      }
    }
  }
  ${requestCostBlockTemplateFragment}
  ${requestCostLineTemplateFragment}
`;

export const REQUEST_COST_BLOCK_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteRequestCostBlockTemplate(
    $input: DeleteRequestCostBlockTemplateInput!
  ) {
    deleteRequestCostBlockTemplate(input: $input) {
      errors
      requestCostBlockTemplate {
        ...RequestCostBlockTemplateFields
        requestCostLineTemplates {
          ...RequestCostLineTemplateFields
        }
      }
    }
  }
  ${requestCostBlockTemplateFragment}
  ${requestCostLineTemplateFragment}
`;

import React, { useEffect, useRef } from "react";
import ContentEditable from "react-contenteditable";
import styled from "styled-components";

interface Props {
  name: string;
  onUpdate: (newName: string) => void;
}

const StyledContentEditable = styled(ContentEditable)`
  display: inline-flex;
  margin: 0;
  padding: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: none;
  background: #fff;
  &:hover {
    background: var(--smoke);
  }
  &:focus {
    background: var(--yellow);
    outline: none;
  }
`;

const EditableInvoiceGroupName: React.FC<Props> = ({ name, onUpdate }) => {
  const editableEl = useRef<HTMLInputElement>();
  const groupName = useRef(name);
  const lastValidName = useRef(name);

  useEffect(() => {
    lastValidName.current = name;
  }, [name]);

  const onBlur = () => {
    if (!groupName.current) groupName.current = lastValidName.current;
    onUpdate(groupName.current);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      editableEl?.current?.blur();
    } else if (e.key == "Escape") {
      groupName.current = lastValidName.current;
      editableEl?.current?.blur();
    }
  };

  return (
    <StyledContentEditable
      innerRef={editableEl}
      tagName="h3"
      html={groupName.current}
      onBlur={onBlur}
      onChange={(e) => (groupName.current = e.target.value)}
      onKeyDown={onKeyDown}
    />
  );
};

export default EditableInvoiceGroupName;

import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { ToggleButton } from "~/layout";
import { updateBudgetLineMutation } from "./actions";

const StyledDateInput = styled(Form.Control).attrs({ type: "date" })`
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  border: none;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
  }
`;

const Finished: React.FC<{ id: string; finishedAt: string | null }> = ({
  id,
  finishedAt,
}) => {
  const update = updateBudgetLineMutation(id);
  const onClick = () => {
    return update({
      finishedAt: !!finishedAt ? null : Date().toString(),
    });
  };
  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return update({
      finishedAt: e.target.value,
    });
  };

  return (
    <>
      <div className="text-center">
        {!!finishedAt && (
          <StyledDateInput
            type="date"
            size="sm"
            value={finishedAt}
            onChange={onDateChange}
          />
        )}
        <ToggleButton active={!!finishedAt} onClick={onClick} />
      </div>
    </>
  );
};

export default Finished;

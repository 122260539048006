import React, { AnchorHTMLAttributes, DetailedHTMLProps } from "react";
import styled from "styled-components";
import {
  ConstructionLoanPeriod,
  useUpdateConstructionLoanPeriodMutation,
} from "~/graphql-operations";
import { formatDate } from "~/util";
import CurrencyRatesFetch, {
  Props as CurrencyRatesFetchProps,
} from "~/util/currency/RatesFetch";

interface Props {
  period: ConstructionLoanPeriod;
}

const Info = styled.div`
  display: flex;
`;
const Currency = styled.div`
  display: flex;
  margin: 0 1rem 0 0;
`;
const CurrencyName = styled.strong`
  padding: 0.125rem 0.5rem;
  font-weight: normal;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem 0 0 0.2rem;
`;
const CurrencyValue = styled.span`
  padding: 0.125rem 0.5rem;
  color: var(--titanium);
  background: var(--smoke);
  border-radius: 0 0.2rem 0.2rem 0;
`;

const DateSourceWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  background: var(--smoke);
  border-radius: 0 0.2rem 0.2rem 0;
`;
const Date = styled.span`
  margin-right: 0.25rem;
  font-size: 0.875rem;
  color: var(--titanium);
  &:after {
    content: " @ ";
    opacity: 0.5;
  }
`;
const Source = styled.a<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>`
  line-height: 1.2;
  font-size: 0.875rem;
  text-decoration: underline;
  color: var(--titanium);
`;

const EditPeriod: React.FC<Props> = ({
  period,
  period: {
    id,
    currencyDate,
    currencyLink,
    currencySource,
    report: { currencies },
  },
}) => {
  const [updatePeriod] = useUpdateConstructionLoanPeriodMutation();

  const onCurrencyFetch: CurrencyRatesFetchProps["onFetch"] = (data) => {
    if (!data) return;
    const { sek, dkk, eur, usd, date, source, link } = data;

    return updatePeriod({
      variables: {
        input: {
          id,
          sek,
          dkk,
          eur,
          usd,
          currencyDate: date,
          currencyLink: link,
          currencySource: source,
        },
      },
    });
  };

  if (!currencies.length) return null;
  return (
    <Info>
      {currencies.map((currency) => (
        <Currency key={currency}>
          <CurrencyName>{currency.toUpperCase()}</CurrencyName>
          <CurrencyValue>{period[currency]}</CurrencyValue>
        </Currency>
      ))}
      <CurrencyRatesFetch onFetch={onCurrencyFetch} size="sm" />
      <DateSourceWrap>
        <Date>{formatDate(currencyDate)}</Date>
        <Source
          title={currencyLink as string}
          href={currencyLink as string}
          target="_blank"
          rel="noreferrer"
        >
          {currencySource}
        </Source>
      </DateSourceWrap>
    </Info>
  );
};

export default EditPeriod;

import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { Fieldset, FieldsetTitle } from "~/form";
import { DeleteButton } from "~/layout";
import Block from "~/layout/Block";
import { NoReports, ReportLine } from "~/report";
import { client } from "~/setup";
import { User } from "~/user";
import {
  ConsultantPMReportLine,
  Membership,
  MEMBERSHIPS_QUERY,
  NewAccessor,
} from ".";
import { deleteMembership } from "./actions";

interface Props {
  user: Pick<User, "id">;
}

// const StyledHeading = styled.h3`
//   margin-bottom: 1rem;

//   ${StyledIcon} {
//     margin-right: 0.75rem;
//   }
// `;

const LineWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted var(--light-gray);

  &:last-of-type {
    border-bottom: none;
  }

  p {
    margin-bottom: 0;
  }
`;

const ConsultantList: React.FC<Props> = ({ user, user: { id: userId } }) => {
  const { loading, error, data } = useQuery<{ memberships: Membership[] }>(
    MEMBERSHIPS_QUERY,
    {
      variables: { userId },
    }
  );

  const deleteMutation = deleteMembership();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const { memberships = [] } = data || {};

  const consultantMemberhips = memberships.filter((m) =>
    m.relation.match(/_consultant$/)
  );
  const pmMemberships = memberships.filter(
    (m) => m.relation === "project_manager"
  );
  const accessorMemberships = memberships.filter(
    (m) => m.relation === "accessor"
  );

  // from accessor memberships
  const editorMemberhips = accessorMemberships.filter((m) =>
    m.permissions.includes("edit")
  );
  const spectatorMemberhips = accessorMemberships.filter(
    (m) => m.permissions.includes("view") && !m.permissions.includes("edit")
  );

  const excludeReports = accessorMemberships.map((m) => m.reportId);

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <Fieldset>
        <FieldsetTitle>Konsulent på rapporter</FieldsetTitle>
        {consultantMemberhips.map(({ report }) => (
          <LineWrap key={report.id}>
            <ReportLine report={report} link />
          </LineWrap>
        ))}
        {consultantMemberhips.length === 0 && <NoReports />}

        <br />
        <FieldsetTitle>Prosjektansvarlig på rapporter</FieldsetTitle>
        {pmMemberships.map((membership) => (
          <LineWrap key={membership.id}>
            <ConsultantPMReportLine membership={membership} />
          </LineWrap>
        ))}
        {pmMemberships.length === 0 && <NoReports />}

        <br />
        <FieldsetTitle>Tilgang på rapporter</FieldsetTitle>
        {spectatorMemberhips.map((membership) => {
          const { id, report } = membership;
          return (
            <LineWrap key={id}>
              <ReportLine report={report} link />
              <DeleteButton iconOnly onClick={() => deleteMutation(id)} />
            </LineWrap>
          );
        })}
        {spectatorMemberhips.length === 0 && <NoReports />}
        <Block>
          <NewAccessor
            user={user}
            excludeReports={excludeReports}
            permissions={["view"]}
          />
        </Block>

        <br />
        <FieldsetTitle>EDITOR på rapporter</FieldsetTitle>
        {editorMemberhips.map((membership) => {
          const { id, report } = membership;
          return (
            <LineWrap key={id}>
              <ReportLine report={report} link />
              <DeleteButton iconOnly onClick={() => deleteMutation(id)} />
            </LineWrap>
          );
        })}
        {editorMemberhips.length === 0 && <NoReports />}
        <Block>
          <NewAccessor
            user={user}
            excludeReports={excludeReports}
            permissions={["view", "edit"]}
          />
        </Block>
      </Fieldset>
    </div>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <ConsultantList {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import { Reference, useMutation } from "@apollo/client";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AddFormButton, Fieldset, Form } from "~/form";
import CostBlockTemplateSelectField from "../block-template/CostBlockTemplateSelectField";
import { CostSection } from "../constants";
import { costBlockWithSummaryFragment } from "../fragments";
import { CostBlock, COST_BLOCK_CREATE_MUTATION } from "./constants";

interface Props {
  costSection: CostSection;
}

const NewCostBlock: React.FC<Props> = ({ costSection }) => {
  const [createCostBlock] = useMutation(COST_BLOCK_CREATE_MUTATION);
  const formMethods = useForm();
  const { reset } = formMethods;

  const onSubmit = async (input: Partial<CostBlock>) => {
    await createCostBlock({
      variables: {
        input: {
          ...input,
          costSectionId: costSection.id,
        },
      },
      update: (cache, { data: { costBlockCreate } }) => {
        const newRef = cache.writeFragment({
          data: costBlockCreate,
          fragment: costBlockWithSummaryFragment,
          fragmentName: "CostBlockWithSummary",
        });
        cache.modify({
          id: cache.identify(costSection),
          fields: {
            costBlocks: (refs: Reference[], { readField }) =>
              [...refs, newRef].sort(
                (a, b) =>
                  (readField<number>("number", a) || 0) -
                  (readField<number>("number", b) || 0)
              ),
          },
        });
      },
    });
    reset();
  };

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} horizontal>
      <Fieldset title="Ny innhold">
        <Row>
          <Col lg="4">
            <CostBlockTemplateSelectField
              name="costBlockTemplateId"
              label="Vedlikeholdsdeler"
              required
            />
          </Col>
          <Col lg="auto">
            <FormGroup>
              <AddFormButton style={{ marginTop: "1.5rem" }} />
            </FormGroup>
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewCostBlock;

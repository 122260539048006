import { TCell, THCell, THead, TRow, Table } from "~/layout/table/CostTable";

import React from "react";
import { ShortcodeProps } from ".";

//import { gql } from "@apollo/client";

/* 

NOTE : Check query field  'mandatory' ? 

 ## (tdd_overview_cell.rb  " - if model.request_cost_lines.mandatory.present?")

const REPORT_TDD_OVERVIEW_TABLE_QUERY = gql`
  query TDDOppsummering($slug: ID!) {
    report(slug: $slug) {
      id
      ... on TDD {
        costSections {
          requestCostLines {
            mandatory
            requestCostBlock {
              title
            }
          }
        }
      }
    }
  }
`;

*/

const TddOppsummering: React.FC<ShortcodeProps> = () => {
  return (
    <Table responsive="sm" borderless>
      <THead>
        <TRow>
          <THCell left borderRight>
            Alle bygg
          </THCell>
          <THCell>Bygg</THCell>
          <THCell>VVS</THCell>
          <THCell>Elektro</THCell>
          <THCell>Brann</THCell>
          <THCell borderY>Sum kostnad</THCell>
        </TRow>
      </THead>
      <tbody>
        <TRow>
          <TCell left borderRight>
            Forskrifts- og loverksmangel dokumentasjon
          </TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell total>{}</TCell>
        </TRow>
        <TRow>
          <TCell left borderRight>
            Anbefales dokumentasjon
          </TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell total>{}</TCell>
        </TRow>
        <TRow important>
          <TCell left>Sum dokumentasjon</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell total>{}</TCell>
        </TRow>
        <TRow>
          <TCell left>Merverdiavgift (25%)</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell total>{}</TCell>
        </TRow>
      </tbody>
      <tfoot>
        <TRow>
          <TCell>Sum</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell>{}</TCell>
          <TCell total borderLeft>
            {}
          </TCell>
        </TRow>
      </tfoot>
    </Table>
  );
};

export default TddOppsummering;

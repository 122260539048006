import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, TextField } from "~/form";
import {
  InvoiceEntryCategoryGroup,
  INVOICE_ENTRY_CATEGORY_GROUP_UPDATE_MUTATION
} from "./constants";

interface Props {
  invoiceEntryCategoryGroup: InvoiceEntryCategoryGroup;
}

type FormData = {
  name: string;
};

const GroupNameInput = styled.input.attrs(() => ({ type: "text" }))`
  margin: 0;
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
`;

const InvoiceEntryCategoryGroupForm: React.FC<Props> = ({
  invoiceEntryCategoryGroup: { id, name },
}) => {
  const formMethods = useForm<FormData>({
    defaultValues: { name },
    mode: "all",
  });
  const [updateGroup] = useMutation(
    INVOICE_ENTRY_CATEGORY_GROUP_UPDATE_MUTATION
  );
  const onSubmit = ({ name }: FormData) => {
    updateGroup({
      variables: {
        input: {
          id,
          name,
        },
      },
    });
  };
  return (
    <Form onSubmit={onSubmit} autoSave formMethods={formMethods}>
      <TextField name="name" label={null} required />
    </Form>
  );
};

export default InvoiceEntryCategoryGroupForm;

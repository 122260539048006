import { pick } from "lodash";
import React from "react";
import { Col } from "react-bootstrap";
import { Fieldset, Form, Row, SelectField, TextAreaField } from "~/form";
import {
  UpdateHousingMaintenanceBuildingPartRatingRuleInput,
  useUpdateHousingMaintenanceBuildingPartRatingRuleMutation,
} from "~/graphql-operations";
import {
  KGOptions,
  maintenancePeriodOptions,
  ratingOptions,
  TGOptions,
} from "./helpers";

type Props = {
  buildingPart: UpdateHousingMaintenanceBuildingPartRatingRuleInput;
};

const EditBuildingPartRatingRule: React.FC<Props> = ({ buildingPart }) => {
  const defaultValues = pick(buildingPart, [
    "id",
    "rating",
    "description",
    "period",
    "tg",
    "kg",
  ]);

  const [
    updateMutation,
  ] = useUpdateHousingMaintenanceBuildingPartRatingRuleMutation();

  const update = async (
    input: UpdateHousingMaintenanceBuildingPartRatingRuleInput
  ) => {
    return updateMutation({
      variables: { input },
    });
  };

  return (
    <Form defaultValues={defaultValues} onSubmit={update} autoSave>
      <Fieldset>
        <Row>
          <Col>
            <SelectField name="tg" options={TGOptions} label="Tg" />
          </Col>
          <Col>
            <SelectField name="kg" options={KGOptions} label="Kg" />
          </Col>
          <Col>
            <SelectField
              name="rating"
              options={ratingOptions}
              label="Karakter"
            />
          </Col>
          <Col>
            <SelectField
              name="period"
              options={maintenancePeriodOptions}
              label="Periode"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextAreaField name="description" label="Generell Vurdering" />
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default EditBuildingPartRatingRule;

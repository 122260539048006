import invariant from "invariant";
import React, { useEffect } from "react";
import {
  MaintenancePeriodEnum,
  MaintenanceTypeEnum,
  useVedlikeholdKostnadsTabellLazyQuery,
} from "~/graphql-operations";
import { WordLoader } from "~/layout/Loading";
import { Table, TCell, THCell, THead, TRow } from "~/layout/table/CostTable";
import { Currency } from "~/util";
import { ShortcodeProps } from ".";
import { MaintenanceReport, ReportType } from "../constants";
import { maintenanceTypeLabel, useMaintenanceDuration } from "../maintenance";

const MVA = 0.25;

const costSummaryMaintenanceDuration = ({
  maintenance: { duration: durationOrNull },
  type,
}: Pick<MaintenanceReport, "type"> & {
  maintenance: Pick<MaintenanceReport["maintenance"], "duration">;
}) => {
  const duration = durationOrNull || 0;
  return [ReportType.ASSESSMENT, ReportType.BREEAM].includes(type)
    ? Math.min(duration, 5)
    : duration;
};

const durationToIntervals = (duration: number | null) =>
  [
    MaintenancePeriodEnum["1_5Years"],
    MaintenancePeriodEnum["6_10Years"],
    MaintenancePeriodEnum["11_15Years"],
    MaintenancePeriodEnum["16_20Years"],
    MaintenancePeriodEnum["21_25Years"],
    MaintenancePeriodEnum["26_30Years"],
  ].slice(0, duration ? duration / 5 : 0);

const safeAdd = (...args: (number | null)[]) => {
  if (args.every((a) => a === null)) return null;
  return args.reduce((sum, a) => (sum || 0) + (a || 0), 0);
};

const safeMVA = (a: number | null) => (a === null ? null : a * MVA);

const addedMVA = (a: number | null) =>
  a === null ? null : a + (safeMVA(a) as number);

const VedlikeholdKostnadsTabell: React.FC<ShortcodeProps> = ({
  report: { id, type },
  code,
}) => {
  const { duration, error: error1 } = useMaintenanceDuration({ id });

  const forcedDuration = costSummaryMaintenanceDuration({
    maintenance: { duration: duration || 0 },
    type,
  });
  const intervalsArg =
    duration !== undefined ? durationToIntervals(forcedDuration) : null;

  const [
    loadData,
    { called, loading, error: error2, data },
  ] = useVedlikeholdKostnadsTabellLazyQuery({
    variables: {
      id,
      intervals: intervalsArg,
      periods: [
        MaintenancePeriodEnum.MissingRegulation,
        MaintenancePeriodEnum.Immediate,
        ...(intervalsArg || []),
      ],
    },
  });

  // causes infinite loop without `called`?
  useEffect(() => {
    if (!called && intervalsArg) loadData();
  }, [intervalsArg, called, loadData]);

  if (!called || loading) return <WordLoader />;
  if (error1 || error2) return <p>Error </p>;

  const publication = data?.publication;
  invariant(publication && "maintenance" in publication, "No publication data");

  const {
    maintenance: {
      cost: {
        documentation,
        firstYearM2,
        immediate,
        intervals,
        missingRegulation,
        total,
      },
      sections,
    },
  } = publication;

  if (total === null) return null;

  const showDocumentation = code.includes("dokumentasjon");
  const showArea = !showDocumentation && !code.includes("area");
  const showMVA = !code.includes("mva");
  const showIntervals = forcedDuration > 0;

  return (
    <Table responsive="sm" borderless>
      <THead>
        <TRow>
          <THCell></THCell>
          <THCell right>Forskriftsmangler</THCell>
          <THCell right>Andre strakstiltak</THCell>
          {showIntervals && <THCell right>{forcedDuration} år</THCell>}
          {showDocumentation && <THCell right>Dokumentasjon</THCell>}
          <THCell right>
            {showArea ? (
              <>
                Per m<sup>2</sup> / BTA
              </>
            ) : (
              <>Sum</>
            )}
          </THCell>
        </TRow>
      </THead>
      <tbody>
        {sections.map(
          ({
            type,
            cost: {
              missingRegulation,
              immediate,
              intervals,
              firstYearM2,
              total,
              documentation,
            },
          }) => (
            <TRow right key={type}>
              <TCell>{maintenanceTypeLabel(type as MaintenanceTypeEnum)}</TCell>
              <TCell>{missingRegulation}</TCell>
              <TCell>{immediate}</TCell>
              {showIntervals && <TCell>{safeAdd(...intervals)}</TCell>}
              {showDocumentation && <TCell>{documentation}</TCell>}
              <TCell>
                {showArea ? (
                  <Currency decimals={2}>{firstYearM2}</Currency>
                ) : showDocumentation ? (
                  safeAdd(total, documentation)
                ) : (
                  total
                )}
              </TCell>
            </TRow>
          )
        )}
        {showMVA && (
          <>
            <TRow right important>
              <TCell>Sum ekskl. mva.</TCell>
              <TCell>{missingRegulation}</TCell>
              <TCell>{immediate}</TCell>
              {showIntervals && <TCell>{safeAdd(...intervals)}</TCell>}
              {showDocumentation && <TCell>{documentation}</TCell>}
              <TCell>
                {showArea ? (
                  <Currency decimals={2}>{firstYearM2}</Currency>
                ) : showDocumentation ? (
                  safeAdd(total, documentation)
                ) : (
                  total
                )}
              </TCell>
            </TRow>
            <TRow right>
              <TCell>Merverdiavgift (25%)</TCell>
              <TCell>{safeMVA(missingRegulation)}</TCell>
              <TCell>{safeMVA(immediate)}</TCell>
              {showIntervals && <TCell>{safeMVA(safeAdd(...intervals))}</TCell>}
              {showDocumentation && <TCell>{safeMVA(documentation)}</TCell>}
              <TCell>
                {showArea ? (
                  <Currency decimals={2}>{safeMVA(firstYearM2)}</Currency>
                ) : showDocumentation ? (
                  safeMVA(safeAdd(total, documentation))
                ) : (
                  safeMVA(total)
                )}
              </TCell>
            </TRow>
          </>
        )}
      </tbody>
      <tfoot>
        <TRow right>
          {showMVA ? (
            <>
              <TCell>Sum inkl. mva.</TCell>
              <TCell>{addedMVA(missingRegulation)}</TCell>
              <TCell>{addedMVA(immediate)}</TCell>
              {showIntervals && (
                <TCell>{addedMVA(safeAdd(...intervals))}</TCell>
              )}
              {showDocumentation && <TCell>{addedMVA(documentation)}</TCell>}
              <TCell>
                {showArea ? (
                  <Currency decimals={2}>{addedMVA(firstYearM2)}</Currency>
                ) : showDocumentation ? (
                  addedMVA(safeAdd(total, documentation))
                ) : (
                  addedMVA(total)
                )}
              </TCell>
            </>
          ) : (
            <>
              <TCell>Sum</TCell>
              <TCell>{missingRegulation}</TCell>
              <TCell>{immediate}</TCell>
              {showIntervals && <TCell>{safeAdd(...intervals)}</TCell>}
              {showDocumentation && <TCell>{documentation}</TCell>}
              <TCell>
                {showArea ? (
                  <Currency decimals={2}>{firstYearM2}</Currency>
                ) : showDocumentation ? (
                  safeAdd(total, documentation)
                ) : (
                  total
                )}
              </TCell>
            </>
          )}
        </TRow>
      </tfoot>
    </Table>
  );
};

export default VedlikeholdKostnadsTabell;

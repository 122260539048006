import { gql } from "@apollo/client";
import { NodeUrlsInterface, ResourceInterface } from "~/node";
import { DocumentationBlock, documentationBlockFragment } from "./block";

export interface DocumentationSection
  extends ResourceInterface,
    NodeUrlsInterface {
  title: string;
  rating: number | null;

  creationDate: string | null;
  revisionDate: string | null;

  hidden?: boolean;
  visible?: boolean;

  documentationBlocks: DocumentationBlock[];
  // documentationLines: Line[];
}

export const documentationSectionFragment = gql`
  fragment DocumentationSectionFields on DocumentationSection {
    id
    title
    rating
    creationDate
    revisionDate
    hidden
  }
`;

export const DOCUMENTATION_SECTION_DOCUMENTATION_BLOCKS_QUERY = gql`
  query documentationSectionBlocks($id: ID!) {
    documentationSection(id: $id) {
      id
      documentationBlocks {
        ...DocumentationBlockFields
      }
    }
  }
  ${documentationBlockFragment}
`;

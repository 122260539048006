import React from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Row as FormRow, SelectField, TextField } from "~/form";
import { RequestCostLineTemplate } from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import List, { BlockTitle } from "~/layout/block-list";
import { Block, Body, Header } from "~/layout/block-list/collapsible";
import { costTypeOptions } from "~/sections/cost-section";
import {
  NewRequestCostLineTemplate,
  useDeleteRequestCostLineTemplate,
  useUpdateRequestCostLineTemplate,
} from ".";
import { requestTypeOptions } from "../helpers";

type Props = {
  lines: RequestCostLineTemplate[];
  requestCostBlockTemplateId: string;
};
const EditRequestCostLineTemplateList: React.FC<Props> = ({
  lines,
  requestCostBlockTemplateId,
}) => {
  const update = useUpdateRequestCostLineTemplate();
  const deleteLine = useDeleteRequestCostLineTemplate();

  return (
    <>
      <List>
        {lines.map(
          ({
            id,
            number,
            title,
            requestType,
            costTypePrimary,
            costTypeSecondary,
          }) => (
            <Block key={id} id={`request-cost-line-template-${id}`}>
              <Header>
                <Row className="align-items-center">
                  <Col xs="auto" style={{ fontSize: "1rem" }}>
                    #{number}
                  </Col>
                  <Col className="pl-0">
                    <BlockTitle>{title}</BlockTitle>
                  </Col>
                  <Col xs="auto">
                    <DeleteButton
                      iconOnly
                      onClick={async () => deleteLine(id)}
                    />
                  </Col>
                </Row>
              </Header>
              <Body>
                <Form
                  defaultValues={{
                    id,
                    title,
                    number,
                    requestType,
                    costTypePrimary,
                    costTypeSecondary,
                  }}
                  onSubmit={update}
                  autoSave
                  horizontal
                >
                  <FormRow>
                    <Col sm="1">
                      <TextField name="number" label="No." />
                    </Col>
                    <Col sm="auto">
                      <TextField name="title" label="tittel" />
                    </Col>
                    <Col>
                      <SelectField
                        name="requestType"
                        label="Hjemlet  I Årsak"
                        options={requestTypeOptions}
                      />
                    </Col>
                    <Col>
                      <SelectField
                        name="costTypePrimary"
                        label="Type"
                        options={costTypeOptions}
                      />
                    </Col>
                    <Col>
                      <SelectField
                        name="costTypeSecondary"
                        label="Type Sekundar"
                        options={costTypeOptions}
                      />
                    </Col>
                  </FormRow>
                </Form>
              </Body>
            </Block>
          )
        )}
      </List>
      <NewRequestCostLineTemplate
        requestCostBlockTemplateId={requestCostBlockTemplateId}
      />
    </>
  );
};

export default EditRequestCostLineTemplateList;

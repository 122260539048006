import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { formatDate } from "~/util";
import { ShortcodeProps } from ".";

const DOCUMENTATION_REVISION_DATE_QUERY = gql`
  query DokumentasjonRevisjonDato($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Documentation {
        documentationSection {
          revisionDate
        }
      }
    }
  }
`;

const DokumentasjonRevisjonDato: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(DOCUMENTATION_REVISION_DATE_QUERY, {
    variables: { slug },
  });

  if (loading) return <WordLoader />;
  if (error) return <p>Error :(</p>;

  const { revisionDate } = data?.report?.documentationSection || {};

  return (
    <>
      {revisionDate ? (
        <span>
          , revidert{" "}
          <strong>{formatDate(revisionDate, { format: "L" })}</strong>
        </span>
      ) : (
        <span>.</span>
      )}
    </>
  );
};

export default DokumentasjonRevisjonDato;

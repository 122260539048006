import React, { useState } from "react";
import styled from "styled-components";
import { FileVersionList } from "~/file-version";
import { useNodeFileVersionsLazyQuery } from "~/graphql-operations";
import { IconButton } from "~/layout/Button";
import { nodeId } from "~/node";

interface Props {
  node: { id: string; __typename?: string };
  onDelete?: (id: string) => Promise<unknown>;
}

const StyledFileVersionList = styled(FileVersionList)`
  flex: 0 0 100%;
  margin: 0.5rem 0 0 0;
  padding: 0.5rem 0 0 0;
  border-top: 1px solid var(--mystic);
`;

const FileVersionsToggle: React.FC<Props> = ({
  node: { __typename, id },
  onDelete,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [getFiles, { loading, error, data }] = useNodeFileVersionsLazyQuery({
    variables: { id: __typename ? nodeId({ __typename, id }) : id },
    fetchPolicy: "no-cache", // file uploads do not refresh this
  });

  if (error) return <p>Error :(</p>;

  const fileVersions =
    data?.node && "fileVersions" in data.node ? data.node.fileVersions : null;

  const toggle = () => {
    if (!showMore && !fileVersions) getFiles();
    setShowMore(!showMore);
  };

  return (
    <>
      <IconButton
        onClick={toggle}
        icon={loading ? "spinner" : showMore ? "caretUp" : "caretDown"}
        spin={loading}
        size="sm"
        variant="outline-secondary"
        rounded
      />
      {showMore && fileVersions && (
        <StyledFileVersionList
          fileVersions={fileVersions}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default FileVersionsToggle;

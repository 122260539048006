import React from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import styled from "styled-components";
import invariant from "tiny-invariant";
import {
  ConstructionLoan,
  InvoiceCategoryTypeEnum,
  InvoiceEntryCategory,
  InvoiceEntryCategoryGroup,
  useInvoiceEntryCategorizationsQuery,
} from "~/graphql-operations";
import { InvoiceContractor } from "~/invoice/contractor";
import {
  ExtendedCategorization,
  InvoiceEntryCategorization,
  isCategory,
  isGroup,
  isVirtualGroup,
  VirtualCategorization,
  VirtualGroup,
} from ".";
import { useSortInvoiceEntryCategorizations } from "./hooks";
import { DraggableCategorization, DraggableCategory } from "./sortable";

interface Props extends React.HTMLProps<HTMLDivElement> {
  contractor: Pick<InvoiceContractor, "id">;
  contractorBudgetPlanLabel: ConstructionLoan["contractorBudgetPlanLabel"];
}

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "var(--smoke)" : "transparent",
});

const Wrapper = styled.div`
  .form-group {
    margin: 0;
  }
  .form-label {
    display: none;
  }
`;

const pushVirtualGroup = (
  categorizations: ExtendedCategorization[],
  category?: InvoiceEntryCategory
) => {
  categorizations.push({
    id: categorizations.length.toString(),
    __typename: "VirtualGroup",
    categories: category ? [category] : [],
  });
};

const virtualizeCategorizations = (
  categorizations: InvoiceEntryCategorization[]
) => {
  const virtualizedCategorizations: Array<VirtualCategorization> = [];
  let lastVirtualGroup: VirtualGroup | null = null;

  categorizations.forEach((categorization, index) => {
    if (isCategory(categorization)) {
      if (!lastVirtualGroup) {
        lastVirtualGroup = {
          id: virtualizedCategorizations.length.toString(),
          __typename: "VirtualGroup",
          categories: [categorization],
        };
        virtualizedCategorizations.push(lastVirtualGroup);
      } else {
        virtualizedCategorizations[
          virtualizedCategorizations.length - 1
        ].categories.push(categorization);
      }
    } else {
      if (index === 0) pushVirtualGroup(virtualizedCategorizations);

      lastVirtualGroup = null;
      virtualizedCategorizations.push(categorization);

      const nextCategorization = categorizations[index + 1];
      if (!nextCategorization || isGroup(nextCategorization)) {
        pushVirtualGroup(virtualizedCategorizations);
      }
    }
  });

  return virtualizedCategorizations;
};

const itemRemover = (
  list: VirtualCategorization[],
  item: InvoiceEntryCategory | InvoiceEntryCategoryGroup
) => {
  return list
    .map((el: VirtualCategorization) => {
      // CATEGORY
      if (isCategory(item)) {
        return {
          ...el,
          categories: el.categories.filter(
            (category) => item.id !== category.id
          ),
        };
      }
      // GROUP | VIRTUAL_GROUP
      else {
        if (item.__typename === el.__typename && item.id === el.id) return;
        return el;
      }
    })
    .filter((el) => !!el) as VirtualCategorization[];
};

const SortableInvoiceEntryCategorizationList: React.FC<Props> = ({
  contractorBudgetPlanLabel,
  contractor: { id },
  ...rest
}) => {
  const { loading, error, data } = useInvoiceEntryCategorizationsQuery({
    variables: { contractorId: id },
  });
  const sortMutation = useSortInvoiceEntryCategorizations();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  invariant(data, "Data should be present");
  const categorizations = (data?.invoiceContractor
    ?.invoiceEntryCategorizations || []) as InvoiceEntryCategorization[];

  const groups = categorizations.filter((el) =>
    isGroup(el)
  ) as InvoiceEntryCategoryGroup[];

  const allCategories = [
    ...categorizations.filter((el) => isCategory(el)),
    ...groups.map((group) => group.categories).flat(),
  ] as InvoiceEntryCategory[];

  const additionalCategories = [
    ...categorizations.filter(
      (el) => isCategory(el) && el.type === InvoiceCategoryTypeEnum.Additional
    ),
  ] as InvoiceEntryCategory[];

  const virtualizedCategorizations = virtualizeCategorizations(
    categorizations.filter(
      (el) => !isCategory(el) || el.type !== InvoiceCategoryTypeEnum.Additional
    )
  );

  const onDragEnd: OnDragEndResponder = async (result) => {
    const { draggableId, source, destination } = result;
    if (!destination) return;

    const [itemType, itemId] = draggableId.split("-");
    const item = [...allCategories, ...groups].find(
      (el) => el.__typename === itemType && el.id === itemId
    );
    if (!item) throw new Error("Sortable item not found!");

    const [destinationType, destinationId] = destination.droppableId.split("-");

    // new structure with ITEM removed
    const newVirtualCategorizations = itemRemover(
      virtualizedCategorizations,
      item
    );
    const newAdditionalCategories = additionalCategories.filter(
      (e) => e.id !== item.id
    );

    // insert ITEM in new position
    if (destinationType === "root") {
      if (!isGroup(item)) throw new Error("Impossible drag");
      newVirtualCategorizations.splice(destination.index, 0, item);
    } else {
      if (!isCategory(item)) throw new Error("Impossible drag");

      if (destinationType === "additional") {
        newAdditionalCategories.splice(destination.index, 0, item);
      } else {
        newVirtualCategorizations.forEach((el, index) => {
          if (destinationType === el.__typename && destinationId === el.id) {
            newVirtualCategorizations[index].categories.splice(
              destination.index,
              0,
              item
            );
          }
        });
      }
    }

    let order = 0;
    const newCategorizations: InvoiceEntryCategorization[] = newVirtualCategorizations.reduce(
      (array, el) =>
        isVirtualGroup(el)
          ? [
              ...array,
              ...el.categories.map((cat) => ({
                ...cat,
                groupId: null,
                order: ++order,
              })),
            ]
          : [
              ...array,
              {
                ...(el as InvoiceEntryCategoryGroup),
                order: ++order,
                categories: (el as InvoiceEntryCategoryGroup).categories.map(
                  (cat) => ({
                    ...cat,
                    groupId: (el as InvoiceEntryCategoryGroup).id,
                    order: ++order,
                  })
                ),
              },
            ],
      []
    );
    const optimisticResponse = [
      ...newCategorizations,
      ...newAdditionalCategories.map((cat) => ({
        ...cat,
        order: ++order,
      })),
    ];
    // console.log("DATA", optimisticResponse);

    return sortMutation(id, optimisticResponse);
  };

  return (
    <Wrapper {...rest}>
      <DragDropContext onDragEnd={onDragEnd}>
        <h4>{contractorBudgetPlanLabel}</h4>
        <Droppable droppableId="root">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {virtualizedCategorizations.map((categorization, index) => (
                <DraggableCategorization
                  index={index}
                  categorization={categorization}
                  key={[categorization.id, categorization.__typename].join()}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {additionalCategories.length > 0 && (
        <div className="mt-3">
          <DragDropContext onDragEnd={onDragEnd}>
            <h4>Uforutsett</h4>
            <Droppable droppableId="additional">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {additionalCategories.map((category, index) => (
                    <DraggableCategory
                      index={index}
                      category={category}
                      // group={isGroup(categorization) ? categorization : undefined}
                      key={category.id}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </Wrapper>
  );
};

export default SortableInvoiceEntryCategorizationList;

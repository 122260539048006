import React from "react";
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import styled, { css } from "styled-components";
import { VirtualCategorization } from "../constants";
import { isVirtualGroup } from "../helpers";
import Item, { HandleWrap } from "./Item";
import { ButtonsWrap } from "./ItemHeader";

interface Props {
  index: number;
  categorization: VirtualCategorization;
}

const Wrap = styled.div<{
  virtual: boolean;
  empty: boolean;
}>`
  &:hover {
    ${HandleWrap}, ${ButtonsWrap} {
      opacity: 1;
    }
  }

  ${({ virtual, empty }) =>
    virtual
      ? css`
          padding: 0.75rem 0;
          &:first-of-type {
            padding-top: 0;
          }
          ${empty && "padding: 0"}
        `
      : css`
          padding: 1rem 0 0.75rem 0;
        `}
`;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // padding: "0.25rem 0 0.25rem 0.75rem",
  // margin: `0 0 ${8}px 0`,

  // change background colour if dragging
  // background: isDragging ? "var(--yellow)" : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const DraggableInvoiceCategorization: React.FC<Props> = ({
  index,
  categorization,
  categorization: { __typename, id, categories },
  children,
}) => {
  const hasCategories = categories.length > 0;
  return (
    <Draggable draggableId={`${__typename}-${id}`} index={index}>
      {(provided, snapshot) => (
        <Wrap
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          virtual={isVirtualGroup(categorization)}
          empty={!hasCategories}
        >
          <Item
            categorization={categorization}
            isDragging={snapshot.isDragging}
            dragHandleProps={provided.dragHandleProps}
          >
            {children}
          </Item>
        </Wrap>
      )}
    </Draggable>
  );
};

export default DraggableInvoiceCategorization;

import { ApolloProvider } from "@apollo/client";
import invariant from "invariant";
import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DocumentationBlockTemplate,
  useDeleteDocumentationBlockTemplateMutation,
  useDocumentationBlockTemplatesQuery,
} from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import List, { BlockTitle } from "~/layout/block-list";
import { Block, Body, Header } from "~/layout/block-list/collapsible";
import { BlockListHeader } from "~/layout/BlockList";
import { client } from "~/setup";
import {
  DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
  EditDocumentationBlockTemplate,
  NewDocumentationBlockTemplate,
} from ".";
import { EditDocumentationLineTemplatesList } from "../line-template";

const DocumentationBlockTemplatesListPage: React.FC = () => {
  //const deleteTemplate = useDeleteDocumentationBlockTemplate();

  const [deleteMutation] = useDeleteDocumentationBlockTemplateMutation();

  const deleteTemplate = async (id: string) => {
    return deleteMutation({
      variables: { input: { id } },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation faild");

        const cacheData = cache.readQuery<{
          documentationBlockTemplates: DocumentationBlockTemplate[];
        }>({
          query: DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { documentationBlockTemplates } = cacheData;

        cache.writeQuery({
          query: DOCUMENTATION_BLOCK_TEMPLATES_QUERY,
          data: {
            documentationBlockTemplates: documentationBlockTemplates.filter(
              (template) => template.id !== id
            ),
          },
        });
      },
    });
  };

  const {
    loading,
    error,
    data: { documentationBlockTemplates } = {},
  } = useDocumentationBlockTemplatesQuery();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  invariant(documentationBlockTemplates, "No documentationBlockTemplates");

  return (
    <>
      <BlockListHeader>
        <Row className="align-items-center">
          <Col>Tittel</Col>
        </Row>
      </BlockListHeader>
      <List>
        {documentationBlockTemplates.map(
          ({ id, title, description, documentationLineTemplates }) => (
            <Block key={id} id={`documentation-block-template-${id}`}>
              <Header>
                <Row className="align-items-center">
                  <Col>
                    <BlockTitle>{title}</BlockTitle>
                  </Col>
                  <Col sm="auto">
                    <DeleteButton
                      iconOnly
                      onClick={async () => deleteTemplate(id)}
                    />
                  </Col>
                </Row>
              </Header>
              <Body>
                <div style={{ margin: "0 -1rem -1rem" }}>
                  <EditDocumentationBlockTemplate
                    template={{ id, title, description }}
                  />
                  {documentationLineTemplates && (
                    <EditDocumentationLineTemplatesList
                      lines={documentationLineTemplates}
                      documentationBlockTemplateId={id}
                    />
                  )}
                </div>
              </Body>
            </Block>
          )
        )}
      </List>
      <NewDocumentationBlockTemplate />
    </>
  );
};

const ApolloWrap: React.FC = () => (
  <ApolloProvider client={client}>
    <DocumentationBlockTemplatesListPage />
  </ApolloProvider>
);

export default ApolloWrap;

import { useMutation } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import invariant from "tiny-invariant";
import { Label } from "~/form";
import {
  Delivery,
  useConstructionLoanDeliveriesQuery,
} from "~/graphql-operations";
import { List, ListHeader } from "~/layout/block-list";
import { Block, Body, Header } from "~/layout/block-list/collapsible";
import { DeleteButton } from "~/layout/Button";
import { Report } from "~/report/constants";
import { formatDate, parseDate, stripTags } from "~/util";
import {
  DELIVERY_DELETE_MUTATION,
  DELIVERY_UPDATE_MUTATION,
} from "./constants";
import DeliveryForm from "./DeliveryForm";

interface Props {
  report: Pick<Report, "id" | "slug">;
}

const DeliveryListHeader = () => (
  <ListHeader>
    <Row>
      <Col lg="2">
        <Label>Date</Label>
      </Col>
      <Col>
        <Label>Beskrivelse</Label>
      </Col>
    </Row>
  </ListHeader>
);

const DeliveryList: React.FC<Props> = ({ report: { id } }) => {
  const {
    loading,
    error,
    data: { constructionLoan } = {},
  } = useConstructionLoanDeliveriesQuery({
    variables: { id },
  });

  const [updateDelivery] = useMutation(DELIVERY_UPDATE_MUTATION);
  const [deleteDelivery] = useMutation(DELIVERY_DELETE_MUTATION);

  const onUpdate = (id: string) => (input: Delivery) => {
    const { date } = input;
    return updateDelivery({
      variables: {
        input: {
          ...input,
          id,
          date: parseDate(date),
        },
      },
    });
  };

  const onDelete = (id: string) => () =>
    deleteDelivery({
      variables: {
        input: {
          id,
        },
      },
      // update(cache, { data }) {
      //   const delivery = data?.eventDelete?.delivery;
      //   if (!delivery) throw new Error("Could not delete delivery");

      //   cache.modify({
      //     id: cache.identify(delivery.report),
      //     fields: {
      //       deliveries: removeFromRefs(id),
      //     },
      //   });
      // },
    });

  if (loading)
    return (
      <>
        <DeliveryListHeader />
        <p>Loading...</p>
      </>
    );

  if (error) return <p>Error :(</p>;
  invariant(constructionLoan, "No report data");

  const { deliveries } = constructionLoan;

  return (
    <>
      <DeliveryListHeader />
      <List>
        {deliveries.map(({ id, date, info }) => (
          <Block key={id}>
            <Header noIcon>
              <Row style={{ alignItems: "center", flexWrap: "nowrap" }}>
                <Col lg="2">{formatDate(date)}</Col>
                <Col style={{ minWidth: "0" }}>{info && stripTags(info)}</Col>
                <Col lg="auto" className="_tar">
                  <DeleteButton iconOnly onClick={onDelete(id)} />
                </Col>
              </Row>
            </Header>
            <Body>
              <DeliveryForm
                defaultValues={{ id, date, info }}
                onSubmit={onUpdate(id)}
              />
            </Body>
          </Block>
        ))}
      </List>
    </>
  );
};

export default DeliveryList;

import styled, { css } from "styled-components";

import React from "react";
import { StyledTCell } from "./TCell";

const TRow = styled.tr<{
  borderless?: boolean;
  children: React.ReactNode;
  important?: boolean;
  light?: true;
  right?: true;
}>`
  border-bottom: 1px solid var(--smoke);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;

  ${({ borderless }) =>
    borderless &&
    css`
      border: none;
    `}

  ${({ important }) =>
    important &&
    css`
      font-weight: bold;
      border-top: 3px solid var(--dark-gray);
      border-bottom: 3px solid var(--dark-gray);
    `}

  ${({ light }) =>
    light &&
    css`
      td {
        color: var(--titanium);
      }
    `}

    ${({ right }) =>
    right &&
    css`
      ${StyledTCell} {
        text-align: right;
        &:first-child {
          text-align: left;
        }
      }
    `}
  tfoot & {
    background-color: var(--yellow);
    border-bottom: 6px double var(--dark-gray);
    font-weight: bold;
  }
`;

export default TRow;

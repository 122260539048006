import { useAutoAnimate } from "@formkit/auto-animate/react";
import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import {
  ConstructionLoanPeriodWithInvoicesQuery,
  CurrencyEnum,
  useDeleteInvoiceMutation,
} from "~/graphql-operations";
import BlockList, {
  BlockListHeader,
  BlockListItemData,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { DeleteButton } from "~/layout/Button";
import { NodeListEmpty } from "~/node";
import { Currency, formatDate } from "~/util";
import {
  EditInvoice,
  StatusBadge,
  isContractorInvoice,
  isExternalInvoice,
} from ".";
import PayoutBadge from "./PayoutBadge";

interface Props {
  period: NonNullable<
    ConstructionLoanPeriodWithInvoicesQuery["constructionLoanPeriod"]
  >;
}

const EditInvoiceList: React.FC<Props> = ({
  period: { report, invoices = [] },
}) => {
  const [animRef] = useAutoAnimate<HTMLUListElement>();
  const [deleteMutation] = useDeleteInvoiceMutation();

  return (
    <>
      <BlockListHeader>
        <Row>
          <Col>Avsender</Col>
          <Col lg="1" className="text-right">
            Faktura
          </Col>
          <Col lg="1">Dato</Col>
          <Col lg="2" className="text-right">
            Sum (Inkl./Eks. mva)
          </Col>
          <Col lg="2" className="text-right">
            Utbetaling
          </Col>
          {/* <Col></Col> */}
          <Col xs="auto" style={{ width: "50px" }}></Col>
        </Row>
      </BlockListHeader>
      <BlockList ref={animRef}>
        {invoices.length > 0 ? (
          invoices.map((invoice) => {
            const {
              acl,
              amount,
              currency,
              dueDate,
              id,
              number,
              sender,
              status,
              total,
              // type,
            } = invoice;

            return (
              <BlockListItemExpandable
                key={id}
                id={`invoice-${id}`}
                render={({ expanded }) => (
                  <Row className="align-items-center">
                    <Col className="d-flex align-items-center">
                      <StatusBadge status={status} className="mr-3" />
                      <BlockListItemTitle>{sender}</BlockListItemTitle>
                    </Col>
                    <Col lg="1" className="text-right">
                      <BlockListItemData>{number}</BlockListItemData>
                    </Col>
                    <Col lg="1">
                      <BlockListItemData>
                        {formatDate(dueDate, { format: "L" })}
                      </BlockListItemData>
                    </Col>
                    <Col lg="2" className="text-right">
                      <BlockListItemData>
                        <Currency currency={currency}>{amount}</Currency>
                      </BlockListItemData>
                    </Col>
                    <Col lg="2" className="text-right">
                      {isContractorInvoice(invoice) && (
                        <BlockListItemData>
                          {/* <Currency currency={currency}>{total}</Currency> */}
                          <PayoutBadge invoice={invoice} />
                        </BlockListItemData>
                      )}
                      {isExternalInvoice(invoice) && (
                        <Badge variant="secondary">
                          <Currency currency={CurrencyEnum.Nok}>
                            {total}
                          </Currency>
                        </Badge>
                      )}
                    </Col>
                    {/* <Col className="d-flex justify-content-end">
                      {type === "ClientInvoice" && (
                        <Badge variant="danger">client</Badge>
                      )}
                      {isContractorInvoice(invoice) && (
                        <Badge
                          variant={
                            invoice.accordingToPlan ? "primary" : "warning"
                          }
                        >
                          contractor
                        </Badge>
                      )}
                      {type === "ExternalInvoice" && (
                        <Badge variant="info">external</Badge>
                      )}
                    </Col> */}
                    <Col xs="auto" style={{ width: "50px" }}>
                      {acl.delete && (
                        <DeleteButton
                          onClick={() =>
                            deleteMutation({ variables: { input: { id } } })
                          }
                          iconOnly
                          inverse={expanded}
                          style={{ padding: 0 }}
                        />
                      )}
                    </Col>
                  </Row>
                )}
              >
                <EditInvoice invoice={invoice} report={report} />
              </BlockListItemExpandable>
            );
          })
        ) : (
          <NodeListEmpty />
        )}
      </BlockList>
    </>
  );
};

export default EditInvoiceList;

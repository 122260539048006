import { useMutation } from "@apollo/client";
import {
  COST_LINE_TEMPLATE_CREATE_MUTATION,
  COST_LINE_TEMPLATE_DELETE_MUTATION,
  COST_LINE_TEMPLATE_UPDATE_MUTATION,
  CreateCostLineTemplateInput,
  UpdateCostLineTemplateInput,
} from ".";

export const useCreateCostLineTemplate = () => {
  const [createCostLineTemplate] = useMutation(
    COST_LINE_TEMPLATE_CREATE_MUTATION
  );
  return (input: CreateCostLineTemplateInput) => {
    return createCostLineTemplate({
      variables: {
        input,
      },
    });
  };
};

export const useUpdateCostLineTemplate = () => {
  const [updateCostLineTemplate] = useMutation(
    COST_LINE_TEMPLATE_UPDATE_MUTATION
  );
  return (input: UpdateCostLineTemplateInput) => {
    return updateCostLineTemplate({
      variables: {
        input,
      },
    });
  };
};

export const useDeleteCostLineTemplate = () => {
  const [deleteCostLineTemplate] = useMutation(
    COST_LINE_TEMPLATE_DELETE_MUTATION
  );
  return (id: string) => {
    return deleteCostLineTemplate({
      variables: {
        input: { id },
      },
    });
  };
};

import React from "react";

export const GRADES = ["A", "B", "C", "D", "E", "F"];
export const FULL_GRADES = [
  "A+",
  "A",
  "A-",
  "B+",
  "B",
  "B-",
  "C+",
  "C",
  "C-",
  "D+",
  "D",
  "D-",
  "E+",
  "E",
  "E-",
  "F+",
  "F",
  "F-",
];

interface Props {
  grade: number;
  full?: boolean;
  //type: "simple" | "full";
}

const Grade: React.FC<Props> = ({ grade, full }) => {
  if (full && grade > 1) throw new Error("Grade must be < 1");
  const label = full == true ? FULL_GRADES[grade] : GRADES[6 - grade];
  return <span>{label || "-"}</span>;
};

export default Grade;

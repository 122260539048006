import { QueryResult, Reference, useMutation, useQuery } from "@apollo/client";
import {
  BudgetPlan,
  BUDGET_PLANS_QUERY,
  BUDGET_PLAN_CREATE_MUTATION,
  BUDGET_PLAN_DELETE_MUTATION,
  BUDGET_PLAN_UPDATE_MUTATION,
} from ".";
import { HousingMaintenanceSection } from "..";
import { budgetPlanFragment } from "../fragments";

type budgetPlansQueryData = {
  housingMaintenanceSection: Pick<
    HousingMaintenanceSection,
    "id" | "budgetPlans"
  >;
};

type budgetPlansQueryResult = Pick<QueryResult, "loading" | "error"> & {
  budgetPlans?: BudgetPlan[];
};

export const budgetPlansQuery = (
  housingMaintenanceSection: HousingMaintenanceSection
): budgetPlansQueryResult => {
  const { loading, error, data } = useQuery<budgetPlansQueryData>(
    BUDGET_PLANS_QUERY,
    {
      variables: {
        id: housingMaintenanceSection.id,
      },
    }
  );

  return {
    loading,
    error,
    budgetPlans: data?.housingMaintenanceSection?.budgetPlans,
  };
};

export const createHousingMaintenanceBudgetPlanMutation = (
  housingMaintenanceSection: HousingMaintenanceSection
) => {
  const [create] = useMutation(BUDGET_PLAN_CREATE_MUTATION);

  return async (input: Partial<BudgetPlan>): Promise<BudgetPlan> => {
    const {
      data: { housingMaintenanceBudgetPlanCreate },
    } = await create({
      variables: {
        input: {
          ...input,
          housingMaintenanceSectionId: housingMaintenanceSection.id,
        },
      },
      update: (cache, { data: { housingMaintenanceBudgetPlanCreate } }) => {
        const cacheId = cache.identify(housingMaintenanceSection);
        if (!cacheId)
          console.log("No cache found for:", housingMaintenanceSection);

        const newRef = cache.writeFragment({
          data: housingMaintenanceBudgetPlanCreate,
          fragment: budgetPlanFragment,
          fragmentName: "HousingMaintenanceBudgetPlanFields",
        });
        cache.modify({
          id: cacheId,
          fields: {
            budgetPlans: (refs: Reference[]) => [...refs, newRef],
          },
        });
      },
    });
    return housingMaintenanceBudgetPlanCreate;
  };
};

export const updateHousingMaintenanceBudgetPlanMutation = () => {
  const [update] = useMutation(BUDGET_PLAN_UPDATE_MUTATION);

  return async (input: Pick<BudgetPlan, "id"> & Partial<BudgetPlan>) => {
    const {
      data: { housingMaintenanceBudgetPlanUpdate },
    } = await update({
      variables: {
        input: {
          ...input,
        },
      },
    });
    return housingMaintenanceBudgetPlanUpdate;
  };
};

export const deleteHousingMaintenanceBudgetPlanMutation = (
  housingMaintenanceSection: HousingMaintenanceSection
) => {
  const [mutation] = useMutation(BUDGET_PLAN_DELETE_MUTATION);

  return (id: BudgetPlan["id"]) =>
    mutation({
      variables: {
        input: {
          id,
        },
      },
      update: (
        cache,
        {
          data: {
            housingMaintenanceBudgetPlanDelete: { budgetPlan },
          },
        }
      ) => {
        cache.modify({
          id: cache.identify(housingMaintenanceSection),
          fields: {
            budgetPlans: (refs, { readField }) =>
              refs.filter((ref: Reference) => id !== readField("id", ref)),
          },
        });
      },
    });
};

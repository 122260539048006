import React, { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { shortcodeMap } from "~/report/shortcode";
import { InputFieldProps, TextAreaField } from "./InputField";

import { Report } from "~/report";
import RedactorX from "../vendor/redactorx";
import "../vendor/redactorx/plugins/clips.js";
import "../vendor/redactorx/redactorx.css";

interface Props extends InputFieldProps {
  shortcodes?: true; // Report["type"]
}

type RedactorChangeEvent = {
  name: string;
  params: {
    html: string;
  };
  stopped: boolean;
};

type RedactorClips = Record<string, { title: string; html: string }>;

const getClips = (reportType: Report["type"]) => ({
  items: Object.entries(shortcodeMap).reduce(
    (acc, [code, { display, types }]) => {
      if (types.includes(reportType)) {
        acc[code] = {
          title: code,
          html:
            display === "block"
              ? `<div class="shortcode-block shortcode-block-${code}">[${code}]</div>`
              : `<span class="shortcode-inline shortcode-inline-${code}">[${code}]</span>`,
        };
      }
      return acc;
    },
    {} as RedactorClips
  ),
});

/**
 *
 * FIXME?: cannot receive updated value, e.g. with values
 *
 */
const RedactorField: React.FC<Props> = ({
  name,
  label,
  shortcodes,
  ...rest
}) => {
  const { setValue } = useFormContext();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const redactor = new RedactorX(textAreaRef.current, {
      plugins: shortcodes ? ["clips"] : [],
      // context: true,
      topbar: false,
      image: {
        upload: "/upload/",
      },
      buttons: {
        // addbar: ["paragraph", "image", "table"],
        // context: ["bold", "italic", "mark", "link", "sub", "sup"],
        // topbar: ["undo", "redo", "shortcut"],
      },
      clips: shortcodes ? getClips("Assessment" as Report["type"]) : undefined,
      subscribe: {
        "editor.change": (e: RedactorChangeEvent) => {
          setValue(name, e.params.html, { shouldDirty: true });
        },
      },
    });
    return () => redactor.destroy();
  }, [name, setValue, shortcodes]);

  // useEffect(() => {
  //   const extraOptions: Record<string, unknown> = {};
  //   const limit = textAreaRef.current?.dataset["editorLimit"];
  //   if (limit) extraOptions.limiter = limit;

  //   window.$R(textAreaRef.current, {
  //     minHeight: "100px",
  //     imageUpload: "/upload",
  //     imageData: null,
  //     imageFigure: false,
  //     imageEditable: false,
  //     imageLink: false,
  //     imageCaption: false,
  //     imagePosition: false,
  //     imageResizable: false,
  //     buttonsAdd: ["sup", "sub"],
  //     plugins: ["table", "variable", "limiter"],
  //     variables: window.REDACTOR_EDITOR_VARIABLES,
  //     callbacks: {
  //       keyup: onKeyUp,
  //     },
  //     ...extraOptions,
  //   });

  //   return () => window.$R(textAreaRef.current, "destroy");
  // }, []);

  return (
    <TextAreaField ref={textAreaRef} name={name} label={label} {...rest} />
  );
};

export default RedactorField;

import { Reference } from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";
import { Option } from "~/form/SelectField";

export const nodeAttributeLabel = <T extends Pick<Option, "value" | "label">>(
  options: T[]
) => (value: typeof options[number]["value"]) => {
  return options.find((option) => value === option.value)?.label || "";
};

export const nodeId = ({
  __typename,
  id,
}: {
  __typename: string;
  id: string;
}) => btoa(`${__typename}-${id}`);

export const addToRefs = (ref?: Reference) => {
  if (!ref) throw new Error("REF is undefined");
  return (refs: Reference[]) => [ref, ...refs];
};

export const removeFromRefs = (id: string) => {
  return (refs: Reference[], { readField }: { readField: ReadFieldFunction }) =>
    refs.filter((ref: Reference) => id !== readField("id", ref));
};

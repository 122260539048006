import styled, { css } from "styled-components";

import React from "react";

/**
 * TODO: duplicate of <td> - TCell
 */
const HeaderCell = styled.th<{
  children?: React.ReactNode;
  borderRight?: true;
  borderLeft?: true;
  borderY?: true;
  right?: true;
  left?: true;
}>`
  width: 16%;

  &:first-child {
    width: auto;
  }

  ${({ right }) =>
    right &&
    css`
      text-align: right;
    `}

  ${({ left }) =>
    left &&
    css`
      text-align: left;
    `}

  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid var(--smoke) !important;
    `}

  ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left: 1px solid var(--smoke) !important;
    `}

  ${({ borderY }) =>
    borderY &&
    css`
      border-left: 1px solid var(--smoke) !important;
      border-right: 1px solid var(--smoke) !important;
    `}
`;

export default HeaderCell;

import { Reference, useMutation } from "@apollo/client";
import {
  DocumentationLine,
  documentationLineFragment,
  DOCUMENTATION_LINE_CREATE_MUTATION,
  DOCUMENTATION_LINE_DELETE_MUTATION,
  DOCUMENTATION_LINE_UPDATE_MUTATION,
} from ".";
import { DocumentationBlock } from "../block";

export const createDocumentationLineMutation = (
  documentationBlock: DocumentationBlock
) => {
  const [createDocumentationLine] = useMutation(
    DOCUMENTATION_LINE_CREATE_MUTATION
  );

  return (input: Partial<DocumentationLine>) => {
    return createDocumentationLine({
      variables: {
        input: {
          ...input,
          documentationBlockId: documentationBlock.id, // .toString(),
        },
      },
      update: (
        cache,
        {
          data: {
            documentationLineCreate: { documentationLine },
          },
        }
      ) => {
        const newRef = cache.writeFragment({
          data: documentationLine,
          fragment: documentationLineFragment,
          fragmentName: "DocumentationLineFields",
        });

        cache.modify({
          id: cache.identify(documentationBlock),
          fields: {
            // documentationLinesCount: (count: number) => count + 1,
            documentationLines: (refs, { readField }) =>
              [...refs, newRef].sort((a, b) => {
                const [aNumber, bNumber] = [a, b].map((ref) =>
                  parseInt(
                    (readField("number", ref) as string).replace(/\./g, "")
                  )
                );
                return aNumber > bNumber ? 1 : bNumber > aNumber ? -1 : 0;
              }),
          },
        });
      },
    });
  };
};

export const updateDocumentationLineMutation = (id: string) => {
  const [update] = useMutation(DOCUMENTATION_LINE_UPDATE_MUTATION);

  return (input: Partial<DocumentationLine>) => {
    return update({
      variables: {
        input: {
          ...input,
          id,
        },
      },
    });
  };
};

export const deleteDocumentationLineMutation = (
  documentationBlock?: DocumentationBlock
) => {
  const [mutation] = useMutation(DOCUMENTATION_LINE_DELETE_MUTATION);

  return (id: string) =>
    mutation({
      variables: {
        input: {
          id,
        },
      },
      update(cache) {
        if (!documentationBlock) return;
        cache.modify({
          id: cache.identify(documentationBlock),
          fields: {
            // documentationLinesCount: (count: number) => count - 1,
            documentationLines: (refs, { readField }) =>
              refs.filter((ref: Reference) => id !== readField("id", ref)),
          },
        });
      },
    });
};

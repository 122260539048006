// https://github.com/redwoodjs/redwood/blob/main/packages/forms/src/index.tsx
import { get } from "lodash";
import pascalcase from "pascalcase";
import React, { forwardRef, MutableRefObject, ReactNode, Ref } from "react";
import {
  Form,
  FormControlProps,
  InputGroup,
  ProgressBar,
} from "react-bootstrap";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { FieldError } from ".";

export enum INPUT_TYPES {
  BUTTON = "button",
  COLOR = "color",
  // DATE = "date",
  DATETIME_LOCAL = "datetime-local",
  EMAIL = "email",
  // FILE = "file",
  HIDDEN = "hidden",
  IMAGE = "image",
  MONTH = "month",
  // NUMBER = "number",
  PASSWORD = "password",
  RADIO = "radio",
  RANGE = "range",
  RESET = "reset",
  SEARCH = "search",
  SUBMIT = "submit",
  TEL = "tel",
  TEXT = "text",
  TEXTAREA = "textarea",
  TIME = "time",
  URL = "url",
  WEEK = "week",
}

export interface InputFieldProps
  extends Omit<
    FormControlProps,
    "type"
  > /* Pick<React.HTMLProps<HTMLInputElement>, "placeholder"> */ {
  append?: ReactNode;
  children?: ReactNode;
  defaultValue?: string | number;
  label?: string | ReactNode;
  name: string;
  placeholder?: string | number | null;
  prepend?: ReactNode;
  required?: boolean;
  style?: React.CSSProperties;
  type?: INPUT_TYPES | "file";
  validate?: RegisterOptions["validate"];
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
}

type InputFieldType = React.FC<InputFieldProps>;
type InputElementType = HTMLInputElement | HTMLTextAreaElement;

export const inputFieldRef = (
  hookFormRef: Ref<InputElementType | null>,
  ref: MutableRefObject<InputElementType | null>
) => (element: HTMLInputElement | HTMLTextAreaElement): void => {
  if (typeof hookFormRef === "function") hookFormRef(element);
  else console.log("Unknown hook-form ref type received from register()");

  // if (typeof ref === "function") {
  //   ref(element);
  // } else if (ref) {
  if (ref) ref.current = element;
  // }
};

// const InputFieldRefRenderFunction: ForwardRefRenderFunction<
//   InputElementType,
//   InputFieldProps
// > = (
const InputFieldRefRenderFunction = (
  {
    append,
    as,
    children,
    name,
    label = name,
    prepend,
    required = false,
    type,
    validate,
    wrapperClassName,
    wrapperStyle,
    ...rest
  }: InputFieldProps,
  ref: MutableRefObject<InputElementType | null>
) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const options = validate || { required };

  // if (type === "number") {
  //   // options["valueAsNumber"] = true; // converts NULL to 0
  //   options["setValueAs"] = (value: string) => {
  //     return value !== "" ? Number(value) : null;
  //     // return isNaN(numberVal) ? null : numberVal;
  //   };
  //   if (required && !validate) {
  //     delete options.required;
  //     options["validate"] = (value: number | null) => {
  //       if (value === null) return false;
  //       return !isNaN(value);
  //     };
  //   }
  // }
  // If not treating dates as string, reset fails with new value:
  //   The specified value "Mon Jun 07 2021 02:00:00 GMT+0200 (Central European Summer Time)" does not conform to the required format, "yyyy-MM-dd"
  // else if (type === "date") options["valueAsDate"] = true;

  // get nested fields - parts[1].usage
  const error = get(errors, name);
  const { ref: hookFormRef, ...formControlProps } = register(name, options);
  const formControl = (
    <Form.Control
      isInvalid={!!error}
      type={type}
      as={as}
      {...formControlProps}
      {...rest}
      ref={inputFieldRef(hookFormRef, ref)}
    />
  );

  return (
    <Form.Group className={wrapperClassName} style={wrapperStyle}>
      {label && <Form.Label>{label}</Form.Label>}
      {prepend || append ? (
        <>
          <InputGroup>
            {prepend && (
              <InputGroup.Prepend>
                <InputGroup.Text>{prepend}</InputGroup.Text>
              </InputGroup.Prepend>
            )}
            {formControl}
            {append && (
              <InputGroup.Append>
                <InputGroup.Text>{append}</InputGroup.Text>
              </InputGroup.Append>
            )}
          </InputGroup>
        </>
      ) : (
        formControl
      )}
      {error && <FieldError error={error} />}
      {children}
    </Form.Group>
  );
};

const InputField = forwardRef(InputFieldRefRenderFunction);

const inputComponents: Record<
  string,
  InputFieldType
  // React.ForwardRefRenderFunction<InputFieldType, InputFieldProps>
  // React.ForwardRefExoticComponent<
  //   InputFieldProps & React.RefAttributes<InputFieldType>
  // >
> = {};

Object.values(INPUT_TYPES).forEach((type) => {
  const Component: typeof InputFieldRefRenderFunction = (props, ref) => (
    <InputField ref={ref} type={type} {...props} />
  );
  const componentDisplayName = `${pascalcase(type)}Field`;
  Component.displayName = componentDisplayName;
  inputComponents[componentDisplayName] = forwardRef(Component);
});

interface FileFieldProps extends InputFieldProps {
  progress?: number;
}
export const FileField = forwardRef(
  ({ progress, ...rest }: FileFieldProps, ref: React.Ref<InputElementType>) => {
    return (
      <InputField
        ref={ref}
        type="file"
        {...rest}
        style={progress === undefined ? undefined : { display: "none" }}
      >
        {progress !== undefined && (
          <div
            style={{
              height: "43px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ProgressBar animated now={progress} style={{ flex: "1" }} />
          </div>
        )}
      </InputField>
    );
  }
);
FileField.displayName = "HiddenField";

export const HiddenField = forwardRef(
  (props: InputFieldProps, ref: React.Ref<InputElementType>) => (
    <InputField
      ref={ref}
      type={INPUT_TYPES.HIDDEN}
      label={null}
      {...props}
      wrapperClassName="my-0"
    />
  )
);
HiddenField.displayName = "HiddenField";

// export interface NumberFieldProps extends InputFieldProps {
//   min?: number;
//   max?: number;
//   step?: number;
// }
// export const NumberField = forwardRef(
//   (props: NumberFieldProps, ref: React.Ref<InputElementType>) => (
//     <InputField ref={ref} type={INPUT_TYPES.NUMBER} min={0} {...props} />
//   )
// );
// NumberField.displayName = "NumberField";

export const TextAreaField = forwardRef(
  (props: InputFieldProps, ref: React.Ref<HTMLTextAreaElement>) => (
    <InputField ref={ref} as="textarea" {...props} />
  )
);
TextAreaField.displayName = "TextAreaField";

export const {
  ButtonField,
  ColorField,
  // DateField,
  DatetimeLocalField,
  EmailField,
  // FileField,
  // HiddenField,
  ImageField,
  MonthField,
  // NumberField,
  PasswordField,
  // RadioField,
  RangeField,
  ResetField,
  SearchField,
  SubmitField,
  TelField,
  TextField,
  TimeField,
  UrlField,
  WeekField,
} = inputComponents;

export default InputFieldRefRenderFunction;

import { InvoiceCategoryTypeEnum } from "~/graphql-operations";
import { nodeAttributeLabel } from "~/node";

export const invoiceCategoryTypeOptions = [
  { value: InvoiceCategoryTypeEnum.Client, label: "BH Kostnad" },
  { value: InvoiceCategoryTypeEnum.Engineering, label: "Prosjektering" },
  { value: InvoiceCategoryTypeEnum.Production, label: "Produksjon" },
  { value: InvoiceCategoryTypeEnum.Additional, label: "Tillegg" },
];

export const invoiceCategoryTypeLabel = nodeAttributeLabel(
  invoiceCategoryTypeOptions
);

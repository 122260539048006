import { ApolloProvider } from "@apollo/client";
import { pick } from "lodash";
import React from "react";
import { Col } from "react-bootstrap";
import { BuildingTypeSelectField } from "~/Building/Form";
import {
  CheckboxField,
  DateField,
  Fieldset,
  Form,
  NumberField,
  RedactorField,
  Row,
} from "~/form";
import {
  ConstructionLoan,
  ConstructionLoanUpdateInput,
  useUpdateConstructionLoanExtraMutation,
} from "~/graphql-operations";
import { client } from "~/setup";
import { CurrencySelectField } from "~/util/currency";
import { EditLoanList } from "../loan";

interface Props {
  report: Pick<
    ConstructionLoan,
    | "id"
    | "balance"
    | "currencies"
    | "defaultPayoutInfo"
    | "links"
    | "loanInfo"
    | "plotCost"
    | "projectTypeId"
    | "startDate"
    | "vatIncluded"
  >;
  compact?: boolean;
}

export const ExtraFields: React.FC<{
  loading?: boolean;
  compact?: boolean;
}> = ({ loading, compact = false }) => {
  return (
    <>
      <Fieldset>
        <Row>
          <Col lg="4">
            <DateField name="startDate" label="Startdato" />
          </Col>
          <Col lg="8">
            <BuildingTypeSelectField
              name="projectTypeId"
              label="Prosjekttype"
              required
            />
          </Col>
        </Row>
      </Fieldset>
      <Fieldset title="Regler">
        <Row>
          <Col lg="2">
            <NumberField
              name="balance"
              label="Innestående"
              step={0.1}
              append="%"
              required
              disabled={loading}
            />
          </Col>
          <Col lg="auto">
            <CheckboxField
              name="vatIncluded"
              label="MVA inkludert"
              disabled={loading}
            />
          </Col>
          <Col lg="auto">
            <CurrencySelectField
              name="currencies"
              label="Ekstra valutaer"
              noDefault
              isMulti
              isClearable={false}
            />
          </Col>
        </Row>
      </Fieldset>
      {!compact && (
        <Fieldset>
          <RedactorField name="links" label="Lenker" />
        </Fieldset>
      )}
      {!compact && (
        <RedactorField
          name="loanInfo"
          label="Låninformasjon"
          placeholder="Intern låninformasjon"
        />
      )}
      {!compact && (
        <RedactorField
          name="defaultPayoutInfo"
          label="Standard periodeutbetalingsinfo"
        />
      )}
      {!compact && (
        <Row>
          <Col lg="3">
            <NumberField name="plotCost" label="Tomt" append="NOK" />
          </Col>
          <Col>
            <CheckboxField
              name="plotCostPartOfLoan"
              label="Del av Byggelånsfinansieringenn"
            />
          </Col>
        </Row>
      )}
    </>
  );
};

const EditConstructionLoanExtra: React.FC<Props> = ({
  report,
  compact = false,
}) => {
  const [update] = useUpdateConstructionLoanExtraMutation();
  const defaultValues = pick(report, [
    "id",
    "balance",
    "currencies",
    "defaultPayoutInfo",
    "links",
    "loanInfo",
    "plotCost",
    "plotCostPartOfLoan",
    "projectTypeId",
    "startDate",
    "vatIncluded",
  ]);

  const onSubmit = async (input: ConstructionLoanUpdateInput) =>
    update({ variables: { input } });

  return (
    <>
      <Form
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        autoSave
        horizontal
      >
        <ExtraFields compact={compact} />
      </Form>
      {!compact && <EditLoanList report={report} />}
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <EditConstructionLoanExtra {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

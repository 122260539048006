import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Label } from "~/form";
import {
  BlockList,
  BlockListHeader,
  BlockListItemData,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { DeleteButton } from "~/layout/Button";
import { removeFromRefs } from "~/node";
import { Report } from "~/report/constants";
import { formatDate, parseDate } from "~/util";
import {
  Event,
  EVENTS_QUERY,
  EVENT_DELETE_MUTATION,
  EVENT_UPDATE_MUTATION,
} from "./constants";
import EventForm from "./EventForm";

interface Props {
  report: Report;
}

const EventList: React.FC<Props> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery<{ report: { events: Event[] } }>(
    EVENTS_QUERY,
    {
      variables: { slug },
    }
  );
  const [updateEvent] = useMutation(EVENT_UPDATE_MUTATION);
  const [deleteEvent] = useMutation(EVENT_DELETE_MUTATION);

  const onUpdate = (id: string) => (input: Event) => {
    const { date } = input;
    return updateEvent({
      variables: {
        input: {
          ...input,
          id,
          date: parseDate(date),
        },
      },
    });
  };

  const onDelete = (id: string) => () =>
    deleteEvent({
      variables: {
        input: {
          id,
        },
      },
      update(cache, { data }) {
        const event = data?.eventDelete?.event;
        if (!event) throw new Error("Could not delete event");

        cache.modify({
          id: cache.identify(event.report),
          fields: {
            events: removeFromRefs(id),
          },
        });
      },
    });

  if (error) return <p>Error :(</p>;

  const Header = () => (
    <BlockListHeader>
      <Row>
        <Col lg="3">
          <Label>Place</Label>
        </Col>
        <Col lg="2">
          <Label>Date</Label>
        </Col>
        <Col lg="7">
          <Label>Description</Label>
        </Col>
      </Row>
    </BlockListHeader>
  );

  if (loading)
    return (
      <>
        <Header />
        <p>Loading...</p>
      </>
    );

  const events = data?.report?.events || [];

  return (
    <>
      <Header />
      <BlockList>
        {events.map((event) => {
          const { id, place, date, description } = event;
          return (
            <BlockListItemExpandable
              key={id}
              render={({ expanded }) => (
                <Row style={{ alignItems: "center", flexWrap: "nowrap" }}>
                  <Col lg="3">
                    <BlockListItemTitle>{place}</BlockListItemTitle>
                  </Col>
                  <Col lg="2">
                    <BlockListItemData>{formatDate(date)}</BlockListItemData>
                  </Col>
                  <Col style={{ minWidth: "0" }}>
                    <BlockListItemData ellipsis>
                      {description}
                    </BlockListItemData>
                  </Col>
                  <Col lg="auto" className="_tar">
                    <DeleteButton
                      iconOnly
                      onClick={onDelete(id)}
                      inverse={expanded}
                      style={{ padding: 0 }}
                    />
                  </Col>
                </Row>
              )}
            >
              <EventForm event={event} onSubmit={onUpdate(id)} />
            </BlockListItemExpandable>
          );
        })}
      </BlockList>
    </>
  );
};

export default EventList;

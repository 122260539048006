import { gql } from "@apollo/client";
import { Attachment, attachmentFragment } from "~/attachment";
import { ResourceInterface } from "~/node";
import { CostLine } from "~/sections/cost-section/line";
import { RequestCostBlock } from "../cost-block";

export interface RequestCostLine extends ResourceInterface {
  requestCostBlock: RequestCostBlock;

  description: string;
  reference: string;
  cost: number;
  received: boolean;
  number: string;
  title: string;
  requestType: string;
  costTypePrimary: string;
  costTypeSecondary: string;
  status: CostLine["status"];

  files: Attachment[];
}

export const requestCostLineFragment = gql`
  fragment RequestCostLineFields on RequestCostLine {
    id
    title
    number
    reference
    description
    received
    requestType
    costTypePrimary
    costTypeSecondary
    cost
    status
    files {
      ...AttachmentFields
    }
  }
  ${attachmentFragment}
`;

// export const REQUEST_COST_LINE_QUERY = gql`
//   query RequestCostLine($id: ID!) {
//     requestCostLine(id: $id) {
//       id
//       ...RequestCostLineFields
//     }
//   }
//   ${requestCostLineFragment}
// `;

export const REQUEST_COST_LINE_CREATE_MUTATION = gql`
  mutation RequestCostLineCreate($input: RequestCostLineCreateInput!) {
    requestCostLineCreate(input: $input) {
      id
      ...RequestCostLineFields
    }
  }
  ${requestCostLineFragment}
`;

export const REQUEST_COST_LINE_UPDATE_MUTATION = gql`
  mutation RequestCostLineUpdate($input: RequestCostLineUpdateInput!) {
    requestCostLineUpdate(input: $input) {
      id
      ...RequestCostLineFields
      requestCostBlock {
        id
        cost
        status
      }
    }
  }
  ${requestCostLineFragment}
`;

export const REQUEST_COST_LINE_DELETE_MUTATION = gql`
  mutation RequestCostLineDelete($input: RequestCostLineDeleteInput!) {
    requestCostLineDelete(input: $input) {
      id
      requestCostBlock {
        id
        cost
        status
      }
    }
  }
`;

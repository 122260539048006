import React from "react";
import { useSortParagraphsMutation } from "~/graphql-operations";
import List from "~/layout/block-list/sortable2";
import ParagraphHeader, { ParagraphHeaderProps } from "./ParagraphHeader";

interface Props {
  id: string;
  paragraphs: ParagraphHeaderProps["paragraph"][];
}

const SortParagraphList: React.FC<Props> = ({ paragraphs, id }) => {
  const [sortParagraphs] = useSortParagraphsMutation();
  const onSort = (paragraphs: string[]) =>
    sortParagraphs({
      variables: {
        input: {
          textSection: id,
          paragraphs,
        },
      },
      // optimisticResponse: {
      //   __typename: "Mutation",
      //   sortTextSectionParagraphs: {
      //     __typename: "SortTextSectionParagraphsPayload",
      //     errors: null,
      //     textSection: {
      //       __typename: "TextSection",
      //       id,
      //       paragraphs: paragraphs.map((id) => ({
      //         __typename: "Paragraph",
      //         id,
      //       })),
      //     },
      //   },
      // },
    });

  return (
    <List items={paragraphs} onSort={onSort}>
      {(paragraph) => <ParagraphHeader paragraph={paragraph} />}
    </List>
  );
};

export default SortParagraphList;

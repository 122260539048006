import React, { ReactElement, ReactNode } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import styled from "styled-components";
import { BlockList, BlockListItem } from ".";

type Item = {
  id: string;
};

interface Props<DataItem extends Item> {
  items: DataItem[];
  onSort: (ids: string[]) => void;
  children: (item: DataItem) => ReactNode;
}

const StyledDiv = styled.div`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const reorder = <T extends Item>(
  list: Array<T>,
  startIndex: number,
  endIndex: number
) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "var(--smoke)" : "transparent",
});

const BlockListSortable = <T extends Item>({
  items,
  onSort,
  children,
}: Props<T>): ReactElement => {
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) return;

    onSort(
      reorder(items, result.source.index, result.destination.index).map(
        (item) => item.id
      )
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <BlockList
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <StyledDiv
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    <BlockListItem
                      inverse={snapshot.isDragging}
                      style={{ marginBottom: 0, cursor: "move" }}
                    >
                      {children(item)}
                    </BlockListItem>
                  </StyledDiv>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </BlockList>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default BlockListSortable;

import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import moment from "moment";
import "moment/min/locales";
import ReactOnRails from "react-on-rails";
import "~/App.sass";
import FragmentMatcher from "~/graphql-fragment-matcher";

type RAILS_ENV = "development" | "test" | "staging" | "production";

const getApiHost = (env: RAILS_ENV) => {
  switch (env) {
    case "development":
    case "test":
      return "http://localhost:3000";
    case "staging":
      return "https://rapport.daytwo.no";
    case "production":
      return "https://rapport.ber.no";
  }
};

export const API_HOST = getApiHost(process.env.RAILS_ENV as RAILS_ENV);
export const ACTIVESTORAGE_DIRECT_UPLOADS_PATH =
  "/rails/active_storage/direct_uploads";

moment.locale("nb");

let alertShown = false;
const showAlert = (message: string) => {
  if (alertShown) return;
  alertShown = true;
  alert(message);
};

// apollo cache default field array merge policy
const replace = (_existing: unknown, incoming: unknown) => incoming;

export const client = new ApolloClient({
  cache: new InMemoryCache({
    // https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
    possibleTypes: FragmentMatcher.possibleTypes,
    // suppress array merge warnings for following types, default behavior is OK
    // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
    typePolicies: {
      Membership: {
        fields: {
          permissions: { merge: false },
        },
      },
      // Report: {
      //   fields: {
      //     projectManagers: { merge },
      //     partners: { merge },
      //     associates: { merge },
      //     collaborators: { merge },
      //   },
      // },
      ConstructionLoan: {
        fields: {
          invoiceCategorizations: { merge: false },
        },
      },
      ReportCost: {
        merge: true,
      },
    },
  }),
  link: from([
    onError(({ graphQLErrors, networkError, operation, response }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}`,
            "Operation:",
            operation,
            "Location:",
            locations,
            "Path:",
            path,
            "Response",
            response
          )
        );
        showAlert(
          "Error :(\n\n" +
            graphQLErrors?.map(({ message }) => message).join("\n")
        );
      }

      if (networkError) {
        console.error("[Network error]:", networkError);
        showAlert("Error :(\n\n" + networkError);
      }
    }),
    new HttpLink({
      uri: `${API_HOST}/graphql`,
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": ReactOnRails.authenticityToken(),
      },
    }),
    //  new MyAuthLink(),
  ]),
});

import { ApolloProvider } from "@apollo/client";
import invariant from "invariant";
import React, { useState } from "react";
import { TextSection, useEditTextSectionQuery } from "~/graphql-operations";
import BlockListHeaderEditableSortable from "~/layout/BlockList/BlockListHeaderEditableSortable";
import { NodeListEmpty } from "~/node";
import { client } from "~/setup";
import { EditParagraphList, NewParagraph, VirtualTextSections } from ".";
import SortParagraphList from "./SortParagraphList";

interface Props {
  textSection: Pick<TextSection, "id">;
}

type State = "editable" | "sortable";

const EditTextSectionPage: React.FC<Props> = ({ textSection: { id } }) => {
  const [state, setState] = useState<State>("editable");
  const { loading, error, data: { node } = {} } = useEditTextSectionQuery({
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  // if (!isTypename("TextSection")(data?.textSection)) return <NodeListEmpty />;
  invariant(node?.__typename === "TextSection", "textSection is undefined");

  const { paragraphs, report } = node;
  const costTypes =
    "costSections" in report && report.textSections[0].id === id
      ? report.costSections.map((cs) => cs.costType)
      : [];

  const Component =
    state === "sortable" ? SortParagraphList : EditParagraphList;

  return (
    <>
      <BlockListHeaderEditableSortable
        onSortable={() => setState("sortable")}
        onEditable={() => setState("editable")}
        state={state}
      />

      <VirtualTextSections costTypes={costTypes} />

      {paragraphs.length > 0 ? (
        <Component id={id} paragraphs={paragraphs} />
      ) : (
        <NodeListEmpty />
      )}

      <NewParagraph textSectionId={id} />
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <EditTextSectionPage {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Object } from "ts-toolbelt";
import { ReportSectionsQuery } from "~/graphql-operations";
import { BlockTitle } from "~/layout/block-list";
import Icon from "~/layout/icon";
import Delete from "./Delete";
import { sectionUrl } from "./helpers";
import { ReportProp } from "./List";
import VisibilityToggle from "./VisibilityToggle";

export type SectionItem = NonNullable<
  Object.Path<ReportSectionsQuery, ["report", "sections", 0]>
>;

const SectionListItem = ({
  report,
  section,
  section: { title },
}: {
  report: ReportProp;
  section: SectionItem;
}) => {
  return (
    <Row className="align-items-center">
      <Col>
        <BlockTitle>
          <a href={sectionUrl(report, section, "edit")}>{title}</a>
        </BlockTitle>
      </Col>
      <Col lg="auto" className="pr-0">
        <a
          href={sectionUrl(report, section)}
          className="d-flex align-items-center"
        >
          <Icon icon="externalLink" />
        </a>
      </Col>
      <Col lg="auto" className="pr-0">
        <VisibilityToggle section={section} />
      </Col>
      <Col lg="auto">
        <Delete section={section} />
      </Col>
    </Row>
  );
};

export default SectionListItem;

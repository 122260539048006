import { useMutation } from "@apollo/client";
import React from "react";
import { Modal } from "react-bootstrap";
import { AddButton } from "~/layout/Button";
import { Report } from "~/report";
import { parseDate, useModal } from "~/util";
import {
  Event,
  eventFieldsFragment,
  EventForm,
  EVENT_CREATE_MUTATION,
} from ".";

interface Props {
  report: Report;
}

const NewEventForm: React.FC<Props> = ({ report: { id: reportId } }) => {
  const { active, show, close } = useModal();
  const [createEvent] = useMutation(EVENT_CREATE_MUTATION);

  const onSubmit = async (input: Event) => {
    const { date } = input;
    await createEvent({
      variables: {
        input: {
          ...input,
          reportId,
          date: parseDate(date),
        },
      },
      update(cache, { data }) {
        const newEvent = data?.eventCreate?.event;
        if (!newEvent) throw new Error("Could not create event");
        const report = newEvent.report;

        const newRef = cache.writeFragment({
          data: newEvent,
          fragment: eventFieldsFragment,
          fragmentName: "EventFields",
        });

        const cacheId = cache.identify(report);
        if (!cacheId) {
          throw new Error(`No cache found for report: ${report.id}`);
        }

        cache.modify({
          id: cacheId,
          fields: {
            events: (refs) => [newRef, ...refs],
          },
        });
      },
    });

    close();
  };

  return (
    <>
      <AddButton onClick={() => new Promise((resolve) => resolve(show()))} />
      <Modal show={active} onHide={close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EventForm onSubmit={onSubmit} />
        </Modal.Body>
        {/* <Modal.Footer>
          <SaveButton variant="primary" icon="deny" onClick={close}>
            Cancel
          </SaveButton>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default NewEventForm;

import { useQuery } from "@apollo/client";
import React from "react";
import { CostSectionSummaryBuildingsDocument } from "~/graphql-operations";
import { WordLoader } from "~/layout/Loading";
import { Table, TCell, THCell, THead, TRow } from "~/layout/table/CostTable";
import { nodeId } from "~/node";
import { CostSectionSummaryProps } from "./CostSectionSummary";
import { intervalIndexLabel } from "./helpers";

type Props = Pick<CostSectionSummaryProps, "costSection">;

const Buildings: React.FC<Props> = ({ costSection }) => {
  const { loading, error, data } = useQuery(
    CostSectionSummaryBuildingsDocument,
    {
      variables: { id: nodeId(costSection) },
    }
  );

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  const node = data?.node || {};
  if (!("publication" in node)) return <p>No data</p>;

  const { publication } = node;
  if (!publication) return <p>No data</p>;

  const { buildingCosts } = publication;

  return (
    <>
      {buildingCosts &&
        buildingCosts.map((building) => {
          const { id, name } = building.building;
          const {
            missingRegulationReplacement,
            missingRegulationHigh,
            missingRegulationMedium,
            missingRegulationLow,
            missingRegulationTotal,
            immediateReplacement,
            immediateHigh,
            immediateMedium,
            immediateLow,
            immediateTotal,
            intervalsReplacement,
            intervalsHigh,
            intervalsMedium,
            intervalsLow,
            intervalsTotal,
            replacementTotal,
            highTotal,
            mediumTotal,
            lowTotal,
            total,
          } = building;
          return (
            <div key={id}>
              <h2>{name}</h2>
              <Table responsive="sm" borderless>
                <THead>
                  <TRow>
                    <THCell borderRight left>
                      Type /<br></br>Tidsrom
                    </THCell>
                    <THCell>Utskiftning</THCell>
                    <THCell>Høy</THCell>
                    <THCell>Middels</THCell>
                    <THCell>Lav</THCell>
                    <THCell borderY>Sum kostnad</THCell>
                  </TRow>
                </THead>
                <tbody>
                  <TRow>
                    <TCell borderRight left>
                      Forskriftsmangel
                    </TCell>
                    <TCell>{missingRegulationReplacement}</TCell>
                    <TCell>{missingRegulationHigh}</TCell>
                    <TCell>{missingRegulationMedium}</TCell>
                    <TCell>{missingRegulationLow}</TCell>
                    <TCell total borderY>
                      {missingRegulationTotal}
                    </TCell>
                  </TRow>
                  <TRow>
                    <TCell borderRight left>
                      Strakstiltak
                    </TCell>
                    <TCell>{immediateReplacement}</TCell>
                    <TCell>{immediateHigh}</TCell>
                    <TCell>{immediateMedium}</TCell>
                    <TCell>{immediateLow}</TCell>
                    <TCell total borderY>
                      {immediateTotal}
                    </TCell>
                  </TRow>
                  {intervalsTotal.map((total, i) => (
                    <TRow key={i}>
                      <TCell borderRight left>
                        {intervalIndexLabel(i)}
                      </TCell>
                      <TCell>{intervalsReplacement?.[i]}</TCell>
                      <TCell>{intervalsHigh?.[i]}</TCell>
                      <TCell>{intervalsMedium?.[i]}</TCell>
                      <TCell>{intervalsLow?.[i]}</TCell>
                      <TCell total borderY>
                        {total}
                      </TCell>
                    </TRow>
                  ))}
                </tbody>
                <tfoot>
                  <TRow>
                    <TCell borderRight left>
                      Totalt
                    </TCell>
                    <TCell>{replacementTotal}</TCell>
                    <TCell>{highTotal}</TCell>
                    <TCell>{mediumTotal}</TCell>
                    <TCell>{lowTotal}</TCell>
                    <TCell total borderY>
                      {total}
                    </TCell>
                  </TRow>
                </tfoot>
              </Table>
            </div>
          );
        })}
    </>
  );
};

export default Buildings;

import { CurrencyEnum } from "~/graphql-operations";
export type AdditionalCurrency = Exclude<CurrencyEnum, "nok">;
// export type Currency = "nok" | AdditionalCurrency;

export const additionalCurrencies = [
  CurrencyEnum.Sek,
  CurrencyEnum.Dkk,
  CurrencyEnum.Eur,
  CurrencyEnum.Usd,
] as AdditionalCurrency[];

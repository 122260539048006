import React from "react";
import styled from "styled-components";
import { useCollapsed } from "./Collapsible";

const StyledToggled = styled.div``;

const Toggle: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  const { toggle } = useCollapsed();
  return (
    <StyledToggled onClick={toggle} {...rest}>
      {children}
    </StyledToggled>
  );
};

export default Toggle;

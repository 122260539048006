import { useMutation } from "@apollo/client";
import {
  CreateDocumentationLineTemplateInput,
  DOCUMENTATION_LINE_TEMPLATE_CREATE_MUTATION,
  DOCUMENTATION_LINE_TEMPLATE_DELETE_MUTATION,
  DOCUMENTATION_LINE_TEMPLATE_UPDATE_MUTATION,
  UpdateDocumentationLinetemplateInput,
} from ".";

export const useCreateDocumentationLineTemplate = () => {
  const [createDocumentationLineTemplate] = useMutation(
    DOCUMENTATION_LINE_TEMPLATE_CREATE_MUTATION
  );
  return (input: CreateDocumentationLineTemplateInput) =>
    createDocumentationLineTemplate({
      variables: {
        input,
      },
    });
};

export const useUpdateDocumentationLineTemplate = () => {
  const [updateDocumentationLineTemplate] = useMutation(
    DOCUMENTATION_LINE_TEMPLATE_UPDATE_MUTATION
  );
  return (input: UpdateDocumentationLinetemplateInput) => {
    return updateDocumentationLineTemplate({
      variables: {
        input,
      },
    });
  };
};

export const useDeleteDocumentationLineTemplate = () => {
  const [deleteDocumentationTemplateFields] = useMutation(
    DOCUMENTATION_LINE_TEMPLATE_DELETE_MUTATION
  );
  return (id: string) => {
    return deleteDocumentationTemplateFields({
      variables: {
        input: { id },
      },
    });
  };
};

import React from "react";
import styled, { css } from "styled-components";
import Indicator from "./Indicator";

interface Props {
  active?: boolean;
  finished?: boolean;
}

export const StyledIndicatorLine = styled.div<Props>`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    display: flex;
    width: 50%;
    height: 3px;
    background: ${({ active, finished }) => {
      if (active) return "var(--teal)";
      else if (finished) return "var(--teal)";
      else return "var(--smoke);";
    }};
  }
  &:before {
    transform: translate(calc(-50%), -50%);
  }
  &:after {
    transform: translate(calc(50%), -50%);
    ${({ active }) =>
      active &&
      css`
        background: var(--smoke);
      `};
  }
`;

const IndicatorLine: React.FC<Props> = (props) => {
  return (
    <StyledIndicatorLine {...props}>
      <Indicator {...props} />
    </StyledIndicatorLine>
  );
};

export default IndicatorLine;

import styled, { css, keyframes } from "styled-components";
import { Icons, Size } from "./constants";

import React from "react";
import { Color } from "~/layout";
// SVGs
import caretDown from "./svgs/caret-down.svg";
import caretLeft from "./svgs/caret-left.svg";
import caretRight from "./svgs/caret-right.svg";
import caretUp from "./svgs/caret-up.svg";
import checkSolid from "./svgs/check-solid.svg";
import check from "./svgs/check.svg";
import chevronDown from "./svgs/chevron-down.svg";
import chevronLeft from "./svgs/chevron-left.svg";
import chevronRight from "./svgs/chevron-right.svg";
import chevronUp from "./svgs/chevron-up.svg";
import commentSolid from "./svgs/comment-solid.svg";
import comment from "./svgs/comment.svg";
import construction from "./svgs/construction.svg";
import deny from "./svgs/deny.svg";
import download from "./svgs/download.svg";
import edit from "./svgs/edit.svg";
import electrical from "./svgs/electrical.svg";
import exportIcon from "./svgs/export.svg";
import externalLink from "./svgs/external-link.svg";
import fileArchive from "./svgs/file-archive.svg";
import fileAudio from "./svgs/file-audio.svg";
import fileCsv from "./svgs/file-csv.svg";
import fileExcel from "./svgs/file-excel.svg";
import fileImage from "./svgs/file-image.svg";
import filePdf from "./svgs/file-pdf.svg";
import fileSolid from "./svgs/file-solid.svg";
import fileVideo from "./svgs/file-video.svg";
import fileWord from "./svgs/file-word.svg";
import file from "./svgs/file.svg";
import fire from "./svgs/fire.svg";
import move from "./svgs/move.svg";
// FileTypes
import password from "./svgs/asterisk-solid.svg";
import trash from "./svgs/delete.svg";
import plumbing from "./svgs/plumbing.svg";
import plusCircle from "./svgs/plus-circle.svg";
import plus from "./svgs/plus.svg";
import refresh from "./svgs/refresh.svg";
import search from "./svgs/search.svg";
import spinner from "./svgs/spinner.svg";
import sync from "./svgs/sync.svg";
import times from "./svgs/times.svg";
import upload from "./svgs/upload.svg";
import warning from "./svgs/warning.svg";
import zoomIn from "./svgs/zoom-in.svg";

interface Props extends React.HTMLAttributes<HTMLElement> {
  icon: Icons;
  size?: Size;
  color?: Color;
  spin?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
}

const icons: Record<keyof Icons, SVGElement> = {
  caretDown,
  plumbing,
  fire,
  construction,
  caretLeft,
  caretRight,
  caretUp,
  check,
  checkSolid,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  comment,
  commentSolid,
  delete: trash,
  deny,
  download,
  edit,
  export: exportIcon,
  externalLink,
  file,
  fileArchive,
  fileAudio,
  fileCsv,
  fileExcel,
  fileImage,
  filePdf,
  fileSolid,
  fileVideo,
  fileWord,
  move,
  password,
  plus,
  plusCircle,
  refresh,
  search,
  spinner,
  sync,
  times,
  upload,
  warning,
  zoomIn,
  electrical,
};

const sizes = {
  small: "16px",
  medium: "18px",
  large: "24px",
};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledIcon = styled.i<Props & { size: Size }>`
  display: inline-flex;
  margin: 0;
  width: ${({ size = "medium" }) => sizes[size]};
  height: ${({ size = "medium" }) => sizes[size]};
  text-indent: 999rem;
  background-color: var(--${({ color }) => color});
  mask-image: url(${({ icon }) => icon});
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center bottom;
  transition: all 0.2s ease-in;

  ${({ spin }) =>
    spin &&
    css`
      animation: ${rotate360} 1s linear infinite;
    `}
`;

const Icon: React.FC<Props> = ({
  icon,
  size = "medium",
  color = "dark-gray",
  ...rest
}) => {
  return <StyledIcon color={color} size={size} icon={icons[icon]} {...rest} />;
};

export default Icon;

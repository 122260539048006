import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  AttachmentField,
  Fieldset,
  Form,
  RedactorField,
  TextField,
} from "~/form";
import { RequestSection, toFormData, updateMutation } from ".";

interface Props {
  requestSection: RequestSection;
}

const EditRequestSection: React.FC<Props> = ({ requestSection }) => {
  const { id, srlFiles } = requestSection;
  const onSubmit = updateMutation(id);

  return (
    <Form
      autoSave
      defaultValues={toFormData(requestSection)}
      onSubmit={onSubmit}
    >
      <Fieldset>
        <RedactorField name="description" label="Beskrivelse" />
      </Fieldset>
      <Fieldset>
        <Row>
          <Col lg="8">
            <TextField name="srlTitle" label="Spørsmål" />
          </Col>
          <Col lg="4">
            <TextField name="srlAnsweredBy" label="Besvart av" />
          </Col>
          <Col lg="4">
            <TextField name="srlAnswer" label="Svar" />
          </Col>
          <Col lg="4">
            <TextField name="srlReference" label="Referanse" />
          </Col>
          <Col lg="4">
            <AttachmentField
              name="srlFiles"
              label="File"
              attachments={srlFiles}
              multiple
            />
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default EditRequestSection;

import React, { ReactNode } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { ToggleButton } from "~/layout";
import FieldError from "./FieldError";

interface ToggleFieldProps {
  name: string;
  label?: string | ReactNode;
  required?: boolean;
  validation?: Record<string, unknown>;
}

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;
  min-height: 43px;
`;

const ToggleField: React.FC<ToggleFieldProps> = ({
  name,
  label = name,
  required,
  validation,
  ...rest
}) => {
  const { control } = useFormContext();
  const onButtonClick = (change: (...e: any[]) => void, value: boolean) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => change(!value);
  };
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <ToggleWrap>
        <Controller
          name={name}
          control={control}
          rules={validation || { required }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <ToggleButton
                  active={value}
                  onClick={onButtonClick(onChange, value)}
                  variant="link"
                  {...rest}
                />
                {error && <FieldError error={error} />}
              </>
            );
          }}
        />
      </ToggleWrap>
    </Form.Group>
  );
};

export default ToggleField;

import { useQuery } from "@apollo/client";
import React from "react";
import { EditRequestCostLineList } from "~/sections/request-section/cost-line";
import { CostSection, COST_SECTION_REQUEST_COST_LINES_QUERY } from ".";

interface Props {
  costSection: CostSection;
}

const EditCostSectionRequestCostLines: React.FC<Props> = ({
  costSection: requestCostBlockProp,
}) => {
  const { loading, error, data } = useQuery(
    COST_SECTION_REQUEST_COST_LINES_QUERY,
    {
      variables: {
        id: requestCostBlockProp.id,
      },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    costSection,
    costSection: { requestCostLines },
  } = data;

  return (
    <EditRequestCostLineList
      costSection={costSection}
      requestCostLines={requestCostLines}
    />
  );
};

export default EditCostSectionRequestCostLines;

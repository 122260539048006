import { ApolloProvider } from "@apollo/client";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import React from "react";
import { client } from "~/setup";
import { shortcodeMap } from "./constants";
import Shortcode, { ShortcodeProps } from "./Shortcode";

interface Props extends Pick<ShortcodeProps, "report"> {
  html: string;
}

type replaceFn = Required<HTMLReactParserOptions>["replace"];

const shortcodesToTags = (input: string) =>
  Object.keys(shortcodeMap).reduce(
    (output, code) =>
      output.replace(`[${code}]`, `<shortcode code="${code}" />`),
    input
  );

const Replacement = ({ node }: { node: ReturnType<replaceFn> }) =>
  node ? <>{node}</> : null;

const ShortcodeParser = ({ report, html: rawHtml }: Props) => {
  const html = shortcodesToTags(rawHtml);

  const replace: replaceFn = (domNode) => {
    if (!("name" in domNode && "attribs" in domNode)) return;

    // redactor inserts blue spans around shortcodes!
    if (domNode.attribs["data-redactor-type"] === "variable")
      return (
        <>
          {domNode.childNodes.map((node, i) => (
            <Replacement node={replace(node)} key={i} />
          ))}
        </>
      );

    if (domNode.name === "shortcode")
      return <Shortcode code={domNode.attribs?.code} report={report} />;

    return;
  };

  return (parse(html, { replace }) as unknown) as JSX.Element;
};

const ApolloWrap: React.FC<Props> = ({ report, html }) => (
  <ApolloProvider client={client}>
    <ShortcodeParser report={report} html={html} />
  </ApolloProvider>
);

export default ApolloWrap;

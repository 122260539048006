import React from "react";
import { Building, EditBuilding } from ".";
import { BlockList, BlockListItemExpandable } from "../layout/BlockList";
import BuildingListExpandableItemHeader from "./BuildingListExpandableItemHeader";

interface Props {
  buildings: Building[];
}

const BuildingListExpandable: React.FC<Props> = ({ buildings }) => {
  return (
    <BlockList>
      {buildings.map((building) => (
        <BlockListItemExpandable
          key={building.id}
          render={({ expanded }) => (
            <BuildingListExpandableItemHeader
              building={building}
              inverse={expanded}
              showDelete
            />
          )}
        >
          <EditBuilding building={building} />
        </BlockListItemExpandable>
      ))}
    </BlockList>
  );
};

export default BuildingListExpandable;

import React, { ReactNode } from "react";
import { Form } from "react-bootstrap";
import DatePicker, { DatePickerProps } from "react-date-picker";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import styled, { css } from "styled-components";
import FieldError from "./FieldError";

interface DateProps extends DatePickerProps {
  name: string;
  label?: string | ReactNode;
  notClearable?: boolean;
  required?: boolean;
  validate?: RegisterOptions["validate"];
}

const StyledDatePicker = styled(DatePicker)<
  DatePickerProps & { $isInvalid: boolean }
>`
  padding: 0 0.5rem 0 0.75rem;
  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      border-color: var(--danger);
    `};

  .react-date-picker__wrapper {
    border: none;
  }

  .react-calendar {
    border: none;
    border-radius: 6px;
    box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.33);
  }

  .react-date-picker__calendar {
    z-index: 101;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive,
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    color: black;
    background: var(--primary);
  }
  .react-calendar__tile--now,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    color: white;
    background: var(--info);
  }
`;

// return YYYY-MM-DD string
const stringValue = (onChange: (x: string | null) => void) => (
  date: Date | null
) => {
  if (!date) return onChange(null);

  // Wed Sep 15 2021 00:00:00 GMT+0200 causes -2 time offset and wrong date
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  onChange(`${year}-${month}-${day}`);
};

const dateValue = (date: Date | string | null): Date | null => {
  if (!date) return null;
  return date instanceof Date ? date : new Date(date);
};

const DateField: React.FC<DateProps> = ({
  name,
  label = name,
  required,
  validate,
  notClearable = false,
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Controller
        name={name}
        control={control}
        rules={validate ? { validate } : { required }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <StyledDatePicker
                onChange={stringValue(onChange)}
                value={dateValue(value)}
                locale="nb-NO"
                className="form-control"
                clearIcon={notClearable || required ? null : undefined}
                $isInvalid={!!error}
                format="dd.MM.yyyy"
                {...rest}
              />
              {error && <FieldError error={error} />}
            </>
          );
        }}
      />
    </Form.Group>
  );
};

export default DateField;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Paragraph, useDeleteParagraphMutation } from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import { BlockTitle } from "~/layout/block-list";

export interface ParagraphHeaderProps {
  paragraph: Pick<Paragraph, "id" | "title">;
  inverse?: boolean;
  showDelete?: boolean;
}

const ParagraphHeader: React.FC<ParagraphHeaderProps> = ({
  paragraph: { id, title },
  showDelete,
}) => {
  const [deleteParagraph] = useDeleteParagraphMutation();

  const onDelete = (id: string) => () =>
    deleteParagraph({ variables: { input: { id } } });

  return (
    <Row className="align-items-center">
      <Col>
        <BlockTitle>{title}</BlockTitle>
      </Col>
      {showDelete && (
        <div className="pr-3">
          <DeleteButton iconOnly onClick={onDelete(id)} />
        </div>
      )}
    </Row>
  );
};

export default ParagraphHeader;

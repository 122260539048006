import React from "react";
import styled from "styled-components";
import { FieldsetTitle } from ".";

interface Props {
  children: React.ReactNode;
  title?: string;
  style?: React.CSSProperties;
}

const Styled = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

const Fieldset: React.FC<Props> = ({ title, children, ...rest }: Props) => (
  <Styled {...rest}>
    {title && <FieldsetTitle>{title}</FieldsetTitle>}
    {children}
  </Styled>
);

export default Fieldset;

import React from "react";
import styled, { keyframes } from "styled-components";
import Icon from "~/layout/icon";
import { LoadingDots } from "~/layout/Loading";

interface Props {
  state: State;
}

export type State = "initialized" | "editing" | "saving" | "saved" | "error";

const NodeUpdateState: React.FC<Props> = ({ state, ...rest }) => {
  // return <Saved />;
  switch (state) {
    case "initialized":
      return <Initialized {...rest} />;
    case "editing":
      return <Editing />;
    case "saving":
      return <Saving />;
    case "saved":
      return <Saved />;
    case "error":
      return <Error />;
  }
};

const Initialized = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
`;

const Circle = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  color: var(--white);
  background: var(--secondary);
  border-radius: 50%;
`;

const fadeOut = keyframes`
  0% {opacity: 1;}
  100% {opacity: 0;}
`;
const FadingGreenCircle = styled(Circle)`
  background-color: var(--teal);
  animation-name: ${fadeOut};
  animation-delay: 3s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const Editing = () => null;

const Saving = () => (
  <Circle>
    <div style={{ transform: "scale(0.5)" }}>
      <LoadingDots />
    </div>
  </Circle>
);

const Saved = () => (
  <FadingGreenCircle>
    <Icon icon="check" color="white" />
  </FadingGreenCircle>
);

const Error = () => null;

export default NodeUpdateState;

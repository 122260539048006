import React from "react";
import ContentLoader from "react-content-loader";

type Props = {
  width?: string | number;
  height?: string | number;
};
const WordLoader: React.FC<Props> = ({ width = 200, height = 10 }) => {
  return (
    <ContentLoader
      speed={2}
      width={200}
      height={30}
      backgroundColor="#bebebe"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="10" rx="3" ry="3" width={width} height={height} />
    </ContentLoader>
  );
};

export default WordLoader;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Form, SelectField } from "~/form";
import {
  ConstructionLoanUpdateInput,
  useUpdateConstructionLoanExtraMutation,
} from "~/graphql-operations";
import { client } from "~/setup";

interface Props {
  report: { id: string; constructionWeek?: number | number };
}

const constructionWeekOptions = Array.from(Array(52).keys()).map((i) => ({
  label: `${i + 1}`,
  value: i + 1,
}));

const DocumentsPage: React.FC<Props> = ({
  report: { id, constructionWeek },
}) => {
  const [update] = useUpdateConstructionLoanExtraMutation();
  const onSubmit = (input: ConstructionLoanUpdateInput) =>
    update({ variables: { input } });

  return (
    <Form autoSave defaultValues={{ id, constructionWeek }} onSubmit={onSubmit}>
      <SelectField
        name="constructionWeek"
        label="Uke"
        options={constructionWeekOptions}
      />
    </Form>
  );
};

const ApolloWrap: React.FC<Props> = ({ ...rest }) => (
  <ApolloProvider client={client}>
    <DocumentsPage {...rest} />
  </ApolloProvider>
);

export default ApolloWrap;

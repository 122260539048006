import { useMutation } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Fieldset, Form, NumberField, SaveFormButton, TextField } from "~/form";
import { BUILDING_TYPES_QUERY, BUILDING_TYPE_CREATE_MUTATION } from ".";

type NewBuildingType = {
  title: string;
  cost: number;
};

const NewBuildingTypeForm: React.FC = () => {
  const formMethods = useForm({ mode: "all" });
  const {
    formState: { isValid, isSubmitting },
  } = formMethods;

  const [createBuildingType] = useMutation(BUILDING_TYPE_CREATE_MUTATION);

  const onSubmit = (input: NewBuildingType) => {
    createBuildingType({
      variables: {
        input,
      },
      update(cache, { data: { buildingTypeCreate } }) {
        const query = BUILDING_TYPES_QUERY;
        const { buildingTypes } = cache.readQuery({ query });
        cache.writeQuery({
          query,
          data: {
            buildingTypes: [...buildingTypes, buildingTypeCreate],
          },
        });
      },
    });
  };

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} horizontal>
      <Fieldset title="Legg til ny bygningstype">
        <Row className="align-items-end">
          <Col lg="5">
            <TextField name="name" label="Tittel" required />
          </Col>
          <Col lg="auto">
            <NumberField name="cost" label="Kostnad per kvm" />
          </Col>
          <Col lg="auto">
            <SaveFormButton type="submit" disabled={!isValid || isSubmitting}>
              Legg til
            </SaveFormButton>
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewBuildingTypeForm;

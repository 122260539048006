import { useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDeleteRequestLineMutation } from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import BlockList, {
  BlockListHeader,
  BlockListItemData,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { Session, useSession } from "~/session";
import { EditRequestLine, RequestLine } from ".";
import {
  REQUEST_SECTION_REQUEST_LINES_QUERY,
  RequestSection,
} from "../constants";

interface Props {
  requestSection: RequestSection;
}

const EditRequestLineList: React.FC<Props> = ({ requestSection }) => {
  const { id } = requestSection;
  const { role } = useSession() as Session;
  const { loading, error, data } = useQuery(
    REQUEST_SECTION_REQUEST_LINES_QUERY,
    {
      variables: { id },
    }
  );

  const [deleteMutation] = useDeleteRequestLineMutation();
  const handleDelete = ["admin", "editor"].includes(role)
    ? (id: string) => deleteMutation({ variables: { input: { id } } })
    : null;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    requestSection: { requestLines },
  } = data;

  return (
    <>
      <BlockListHeader>
        <Row>
          <Col lg="1">No.</Col>
          <Col lg={handleDelete ? 5 : 7}>Spørsmålsliste</Col>
          <Col lg="2">Besvart av</Col>
          <Col lg="2">Referanse</Col>
          {handleDelete && (
            <Col lg="2" className="text-right">
              Valg
            </Col>
          )}
        </Row>
      </BlockListHeader>

      {requestLines.length ? (
        <BlockList>
          {requestLines.map((requestLine: RequestLine) => {
            const { id, title, number, answeredBy, reference } = requestLine;
            return (
              <BlockListItemExpandable
                key={id}
                id={`request-line-${requestLine.id}`}
                render={({ expanded }) => (
                  <Row style={{ alignItems: "center" }}>
                    <Col lg="1">
                      <BlockListItemTitle>{number}</BlockListItemTitle>
                    </Col>
                    <Col lg={handleDelete ? 5 : 7}>
                      <BlockListItemData>{title}</BlockListItemData>
                    </Col>
                    <Col lg="2">
                      <BlockListItemData>{answeredBy}</BlockListItemData>
                    </Col>
                    <Col lg="2">
                      <BlockListItemData>{reference}</BlockListItemData>
                    </Col>
                    {handleDelete && (
                      <Col lg="2" className="text-right">
                        <DeleteButton
                          iconOnly
                          onClick={() => handleDelete(id)}
                          inverse={expanded}
                          style={{ padding: 0 }}
                        />
                      </Col>
                    )}
                  </Row>
                )}
              >
                <EditRequestLine
                  requestLine={requestLine}
                  key={requestLine.id}
                />
              </BlockListItemExpandable>
            );
          })}
        </BlockList>
      ) : (
        <p className="empty-records">No cost lines created yet.</p>
      )}
    </>
  );
};

export default EditRequestLineList;

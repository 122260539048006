import { gql, useMutation } from "@apollo/client";
import { toLower } from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { MembershipRelation } from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import { Permission } from "~/Membership";
import { EditableMember } from "./constants";
import editMemberFields from "./editMemberFields.fragment.graphql";
import ReportMemberPermissions from "./EditPermissions";

export interface EditMemberLineProps {
  member: EditableMember;
  disabledPermissions?: Permission[];
}

const Wrap = styled.div`
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: var(--smoke);
  border-radius: 5px;
`;

const removeMutation = (relation: MembershipRelation) => gql`
  mutation ReportRemoveMember($input: ReportRemoveMemberInput!) {
    reportRemoveMember(input: $input) {
      id
      ${toLower(relation)}s {
        ...EditMemberFields
      }
    }
  }
  ${editMemberFields}
`;

const updateMutation = (relation: MembershipRelation) => gql`
  mutation ReportUpdateMember($input: ReportUpdateMemberInput!) {
    reportUpdateMember(input: $input) {
      id
      ${toLower(relation)}s {
        ...EditMemberFields
      }
    }
  }
  ${editMemberFields}
`;

const EditMemberLine: React.FC<EditMemberLineProps> = ({
  member: {
    membership: { id, permissions, relation },
    user: { name, email, companyName },
  },
  disabledPermissions,
  children,
}) => {
  const [updateMember] = useMutation(updateMutation(relation));
  const [removeMember] = useMutation(removeMutation(relation));

  const onPermissionsChange = async (permissions: Permission[]) => {
    return updateMember({
      variables: {
        input: {
          membershipId: id,
          permissions,
        },
      },
    });
  };

  const onRemove = () =>
    removeMember({
      variables: {
        input: {
          membershipId: id,
        },
      },
      // update(cache) {
      //   cache.modify({
      //     fields: {
      //       reportUsers: (existingMemberships: Reference[], { readField }) =>
      //         existingMemberships.filter((ref) => id !== readField("id", ref)),
      //     },
      //   });
      // },
    });

  return (
    <Wrap>
      <Row className="align-items-center">
        <Col lg="7">
          <div className="field">
            <label>Bruker</label>
            <p style={{ margin: 0 }}>
              {name}, {email} - {companyName}
            </p>
          </div>
        </Col>
        <Col>
          <div className="field">
            <label>Tillatelser</label>
            <ReportMemberPermissions
              permissions={permissions}
              disabled={disabledPermissions}
              onChange={onPermissionsChange}
            />
          </div>
        </Col>
        <Col xs="auto" style={{ paddingRight: "2rem" }}>
          <DeleteButton iconOnly onClick={onRemove} />
        </Col>
      </Row>
      {children}
    </Wrap>
  );
};

export default EditMemberLine;

import { BadgeProps } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import styled from "styled-components";
import { WorkStatusEnum } from "~/graphql-operations";
import { statusColor } from "~/sections/cost-section/line";

export type StatusBadgeProps = BadgeProps &
  (
    | {
        status: WorkStatusEnum;
        color?: Variant;
      }
    | {
        status?: WorkStatusEnum;
        color: Variant;
      }
  );

const StatusBadge = styled.div<StatusBadgeProps>`
  display: inline-block;
  vertical-align: middle;
  flex: 0 0 1rem;
  width: 1rem;
  height: 1rem;
  background-color: var(
    --${({ status, color }) => (color ? color : statusColor(status as WorkStatusEnum))}
  );
  border-radius: 50%;
`;

export default StatusBadge;

import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from "./Shortcode";

const INSPECTION_DESCRIPTION_REPORT_QUERY = gql`
  query InspeksjonTilstede($slug: ID!) {
    report(slug: $slug) {
      id
      inspectionDescription
    }
  }
`;

const InspeksjonTilstede: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery(
    INSPECTION_DESCRIPTION_REPORT_QUERY,
    { variables: { slug } }
  );

  if (error) return <p>Error</p>;
  const { inspectionDescription } = data?.report || {};
  return <>{loading ? <WordLoader /> : `${inspectionDescription}`}</>;
};

export default InspeksjonTilstede;

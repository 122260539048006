import React from "react";
import { Col } from "react-bootstrap";
import {
  CheckboxField,
  Fieldset,
  Form,
  Row,
  SaveFormButton,
  TextField,
} from "~/form";
import { Block } from "~/layout";
import { useCreateRequestCostBlockTemplate } from ".";

const NewRequestCostBlockTemplate: React.FC = () => {
  const create = useCreateRequestCostBlockTemplate();

  return (
    <Block>
      <Form onSubmit={create} resetBlank horizontal>
        <Fieldset title="Ny TDD Kostnadsblokkmal">
          <Row>
            <Col lg="1">
              <TextField name="number" label="Nummer" />
            </Col>
            <Col>
              <TextField name="title" label="Tittel" />
            </Col>
            <Col className="d-flex align-items-center" lg="2">
              <CheckboxField name="internalUse" label="Internal Use" />
            </Col>
            <Col lg="auto" className="d-flex justify-content-end">
              <SaveFormButton />
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default NewRequestCostBlockTemplate;

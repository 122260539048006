import { gql } from "@apollo/client";
import { Attachment } from "~/attachment";
import { ResourceInterface } from "~/node";
import { CostLineTemplate } from "~/sections/cost-section/line-template";
import { WORK_STATUS } from "~/util/WorkStatus";
import { BuildingPart } from "../building-part";
import { budgetLineFragment, buildingPartSummaryFragment } from "../fragments";

export interface BudgetLine extends ResourceInterface {
  buildingPartId: string;
  buildingPart: BuildingPart;

  costLineTemplateId?: string;
  costLineTemplate?: CostLineTemplate;
  costLineTemplateTitle: string;
  costLineTemplateRef: number;

  unit: string;
  unitPrice: number;
  amount: number;
  costType: string;
  description: string;
  note: string;
  status: WORK_STATUS;

  files: Attachment[];

  cost: number | null;
  costActual: number | null;
  finishedAt: string;
}

export const BUDGET_LINE_CREATE_MUTATION = gql`
  mutation HousingMaintenanceBudgetLineCreate(
    $input: HousingMaintenanceBudgetLineCreateInput!
  ) {
    housingMaintenanceBudgetLineCreate(input: $input) {
      ...HousingMaintenanceBudgetLineFields
      buildingPart {
        id
        ...HousingMaintenanceBuildingPartSummary
      }
    }
  }
  ${budgetLineFragment}
  ${buildingPartSummaryFragment}
`;

export const BUDGET_LINE_UPDATE_MUTATION = gql`
  mutation HousingMaintenanceBudgetLineUpdate(
    $input: HousingMaintenanceBudgetLineUpdateInput!
  ) {
    housingMaintenanceBudgetLineUpdate(input: $input) {
      id
      ...HousingMaintenanceBudgetLineFields
      buildingPart {
        ...HousingMaintenanceBuildingPartSummary
      }
    }
  }
  ${budgetLineFragment}
  ${buildingPartSummaryFragment}
`;

export const BUDGET_LINE_DELETE_MUTATION = gql`
  mutation HousingMaintenanceBudgetLineDelete(
    $input: HousingMaintenanceBudgetLineDeleteInput!
  ) {
    housingMaintenanceBudgetLineDelete(input: $input) {
      id
      buildingPart {
        id
        ...HousingMaintenanceBuildingPartSummary
      }
    }
  }
  ${buildingPartSummaryFragment}
`;

import React from "react";
import { Col, Row } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import invariant from "tiny-invariant";
import { Option } from "~/form/SelectField";
import {
  PermissionEnum,
  Report,
  User,
  useReportRemoveClientMutation,
  useReportSetClientMutation,
  UserRole,
} from "~/graphql-operations";
import { filteredLoadOptionsRetFunc, toOption } from "~/user";
import { EditableMember, EditPermissions } from ".";

interface Props {
  reportId: Report["id"];
  client: EditableMember | null;
  loadOptions: filteredLoadOptionsRetFunc;
  children?: React.ReactNode;
}

const ClientField: React.FC<Props> = ({
  reportId,
  loadOptions,
  client,
  children,
}) => {
  const [setClient] = useReportSetClientMutation();
  const [removeClient] = useReportRemoveClientMutation();

  const onPermissionsChange = (permissions: PermissionEnum[]) => {
    invariant(client, "Client not set");
    return setClient({
      variables: {
        input: {
          reportId,
          userId: client.user.id,
          permissions,
        },
      },
    });
  };

  const onChange = (option: Option<User, User["id"]> | null) =>
    option
      ? setClient({
          variables: {
            input: {
              reportId,
              userId: option.value,
              permissions:
                client?.membership?.permissions ||
                (option.data.role == UserRole.Client
                  ? [PermissionEnum.View]
                  : []),
            },
          },
        })
      : removeClient({
          variables: {
            input: {
              reportId,
            },
          },
        });

  return (
    <>
      <Row className="align-items-end">
        <Col md="7">
          <AsyncSelect
            isClearable
            loadOptions={loadOptions}
            onChange={onChange}
            value={client && toOption(client.user)}
          />
        </Col>
        <Col md="3">
          {client && (
            <div className="field">
              <label>Tillatelser</label>
              <EditPermissions
                permissions={client.membership.permissions}
                disabled={
                  client?.user?.role === UserRole.Client ? ["view"] : []
                }
                onChange={onPermissionsChange}
              />
            </div>
          )}
        </Col>
      </Row>
      {children}
    </>
  );
};

export default ClientField;

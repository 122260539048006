import React from "react";
import { ShortcodeProps } from ".";

/* 
   NOTE:
  - Query field needed "oblique_image" 


  - Add query in codegen:
  
  query MatrikkelSkrafoto($slug: ID!) {
  report(slug: $slug) {
    id
    obliqueImg
  }
}
*/

const MatrikkelSkrafoto: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  return (
    <>
      <h3>HELLO MatrikkelSkrafoto</h3>
    </>
  );
};

export default MatrikkelSkrafoto;

import { ChartProps } from "./Chart";
import { COLORS } from "./constants";

export const getPieData = (data: ChartProps["data"]) => {
  const total = data.reduce((sum, { value }) => sum + (value || 0), 0);
  const percentages = data.map((el) => ((el.value || 0) / total) * 100);

  const offset = (index: number): number => {
    const prev = index - 1;
    return prev < 0 ? 0 : offset(prev) - percentages[prev];
  };

  return {
    data: total
      ? data.map((el, index) => {
          const { label, color } = el;
          return {
            ...el,
            color: color || COLORS[index],
            key: `${label}-${index}`,
            offset: offset(index),
            percentage: percentages[index],
            width: 4,
          };
        })
      : [
          {
            color: "#eee",
            key: "zero",
            offset: 0,
            percentage: 100,
            width: 1,
          },
        ],
    total,
  };
};

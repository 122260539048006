import { ApolloProvider } from "@apollo/client";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { get, toLower } from "lodash";
import React, { useState } from "react";
import { Alert, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Fieldset, Row, SelectField } from "~/form";
import { Option } from "~/form/SelectField";
import {
  Report,
  ReportType,
  useCreateMultiAssessmentMutation,
  useCreateMultiTddMutation,
} from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import { client } from "~/setup";
import ReportSelectField from "../form/ReportSelectField";
import NewReportForm from "../NewReportForm";

const Wrap = styled.div`
  padding: 1.5rem;
  background-color: var(--smoke);
  border-radius: 4px;
`;

const ReportLi = styled.div`
  display: flex;
  align-items: center;
  &:before {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    display: inline-block;
    background-color: black;
  }

  .btn {
    opacity: 0;
    margin-left: 0.75rem;
  }
  &:hover {
    .btn {
      opacity: 1;
    }
  }
`;

const multiTypes = [
  {
    value: ReportType.Assessment,
    label: "Assessment",
  },
  {
    value: ReportType.Tdd,
    label: "TDD",
  },
];

const ReportList = ({
  reports,
  onRemove,
}: {
  reports: Report[];
  onRemove: (id: string) => void;
}) => {
  const [parent] = useAutoAnimate<HTMLDivElement>();

  return (
    <div ref={parent} style={{ margin: "0.5rem 1rem 1rem 1rem" }}>
      {reports.map(({ id, name }) => (
        <ReportLi key={id}>
          {name}
          <DeleteButton iconOnly onClick={() => onRemove(id)} noConfirm />
        </ReportLi>
      ))}
    </div>
  );
};

const NewMulti: React.FC = () => {
  const formMethods = useForm();
  const { setValue, watch } = formMethods;
  const [companyId, type] = watch(["companyId", "type"]);

  const [createMultiAssessment] = useCreateMultiAssessmentMutation();
  const [createMultiTDD] = useCreateMultiTddMutation();

  // const createConstructionLoan = createConstructionLoanMutation();
  // const onSubmit = async (input: NewConstructionLoan) => {
  //   const { report, errors } = (await createConstructionLoan(input)) || {};
  //   if (!report || errors?.length)
  //     throw new Error(`Failed to create report. ${(errors || []).join("; ")}`);
  //   window.location.href = `/construction_loans/${report.slug}/edit`;
  // };

  const [reports, setReports] = useState<Report[]>([]);
  const [error, setError] = useState("");

  const addReport = ({ data }: Option<Report>) => {
    if (!data) throw new Error("No report data in option");
    setReports([...reports, data]);
    setError("");
    setValue("report", null);
  };
  const removeReport = (id: string) => {
    setReports(reports.filter((report) => report.id !== id));
  };

  const onSubmit = async ({
    name,
    number,
    companyId,
    type,
  }: Pick<Report, "name" | "number" | "companyId" | "type">) => {
    if (!reports.length) return setError("Du må velge minst en rapport");
    if (error) setError("");

    const mutation =
      type === ReportType.Assessment ? createMultiAssessment : createMultiTDD;

    const response = await mutation({
      variables: {
        input: {
          companyId,
          name,
          number,
          reportIds: reports.map((report) => report.id),
        },
      },
    });

    const slug = get(response, `data.createMulti${type}.report.slug`);

    if (slug) window.location.href = `/multi-${toLower(type)}s/${slug}/edit`;
    else window.alert("No report slug found in response");

    return response;
  };

  return (
    <Wrap>
      <h3 style={{ marginBottom: "1.5rem" }}>Ny MultiRapport</h3>
      <NewReportForm formMethods={formMethods} onSubmit={onSubmit}>
        <Fieldset title="Reports">
          {/* <ToggleButtonGroup
            name="type"
            type="radio"
            // value={type?.id}
            // onChange={onChange}
          >
            {multiTypes.map(({ label, value }) => (
              <ToggleButton
                key={value}
                id={`[price-type-${value}`}
                variant="outline-dark"
                value={value}
              >
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup> */}
          <Row>
            <Col lg="5">
              <SelectField
                name="type"
                label="MultiRapport type"
                options={multiTypes}
                required
              />
            </Col>
          </Row>
          {error && <Alert variant="danger">{error}</Alert>}
          {!!reports.length && (
            <ReportList reports={reports} onRemove={removeReport} />
          )}
          <ReportSelectField
            name="report"
            label="Add Rapport"
            onChange={addReport}
            exclude={reports.map((report) => report.id)}
            disabled={!companyId || !type}
            companyId={companyId}
            type={type}
          />
        </Fieldset>
      </NewReportForm>
    </Wrap>
  );
};

const ApolloWrap: React.FC = (props) => (
  <ApolloProvider client={client}>
    <NewMulti {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Company } from "~/Company";
import { CompanyCard } from ".";

type Props = {
  companies: Company[];
  companiesCount: number;
  loading: boolean;
};

const CompaniesTable: React.FC<Props> = ({
  companies,
  companiesCount,
  loading,
}) => {
  return (
    <Row className="mt-3" style={{ flexWrap: "wrap" }}>
      {loading &&
        Array.from({ length: companiesCount }).map((_, index) => (
          <Col key={index} lg="3">
            <CompanyCard loading />
          </Col>
        ))}
      {companies.map((company) => {
        const { id } = company;
        return (
          <Col id={`company-${id}`} lg="3" key={id}>
            <CompanyCard company={company} />
          </Col>
        );
      })}
    </Row>
  );
};

export default CompaniesTable;

import React from "react";
import Alert from "./Alert";

const ErrorAlert = ({ error: { message } }: { error: { message: string } }) => (
  <Alert>
    <pre style={{ margin: 0 }}>{message}</pre>
  </Alert>
);

export default ErrorAlert;

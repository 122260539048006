import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from ".";
import { Report } from "../constants";

const PARTNERS_NAME_REPORT_QUERY = gql`
  query Report($slug: ID!) {
    report(slug: $slug) {
      id
      partners {
        name
      }
    }
  }
`;

const KonsulenterAnsvarligPartnerKS: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery<{
    report: Pick<Report, "partners">;
  }>(PARTNERS_NAME_REPORT_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error :(</p>;

  const partners = data?.report.partners || [];
  const partnerNames = partners.map(({ name }) => name)?.join(", ");

  return <>{loading ? <WordLoader /> : `${partnerNames}`}</>;
};

export default KonsulenterAnsvarligPartnerKS;

import { Company } from "./constants";

type Option = {
  label: string;
  value: string;
};

export const toOption = ({ id, name }: Company): Option => ({
  label: name,
  value: id,
});

export const toOptions = (companies: Company[]): Option[] =>
  companies.map((company) => toOption(company));

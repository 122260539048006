import { pick } from "lodash";
import { attachmentInput } from "~/form/attachment";
import { nodeAttributeLabel } from "~/node";
import { DocumentationLine } from ".";

export const ratingOptions = [
  {
    value: 1,
    label: "Foreligger",
  },
  {
    value: 2,
    label: "Opplyst at foreligger",
  },
  {
    value: 3,
    label: "Foreligger ikke",
  },
  {
    value: 4,
    label: "Feil dokumentasjon",
  },
  {
    value: 5,
    label: "Ingen avvik foreligger eller avvikene er lukket",
  },
  {
    value: 6,
    label: "Ikke relevant",
  },
];
export const ratingLabel = nodeAttributeLabel(ratingOptions);

export const toFormData = (
  documentationLine: DocumentationLine
): Partial<
  Omit<DocumentationLine, "rating1Files" | "rating2Files" | "rating3Files">
> & {
  rating1Files?: string[];
  rating2Files?: string[];
  rating3Files?: string[];
} => {
  const {
    maxScore,
    rating1Files,
    rating2Files,
    rating3Files,
  } = documentationLine;
  const attributes = ["description", "rating1"];
  const fileAttributes: Record<string, string[] | undefined> = {
    rating1Files: rating1Files ? attachmentInput(rating1Files) : undefined,
  };

  if (maxScore > 1) {
    attributes.push("rating2");
    fileAttributes.rating2Files = rating2Files
      ? attachmentInput(rating2Files)
      : undefined;
  }
  if (maxScore > 2) {
    attributes.push("rating3");
    fileAttributes.rating3Files = rating3Files
      ? attachmentInput(rating3Files)
      : undefined;
  }

  return {
    ...pick(documentationLine, attributes),
    ...fileAttributes,
  };
};

import { useQuery } from "@apollo/client";
import React from "react";
import { SelectField } from "~/form";
import { SyncSelectFieldProps } from "~/form/SelectField";
import { Company } from ".";
import { COMPANIES_QUERY } from "./constants";
import { toOptions } from "./helpers";

const CompanySelectField: React.FC<SyncSelectFieldProps<Company>> = ({
  name,
  label,
  ...rest
}) => {
  const { data, loading, error } = useQuery(COMPANIES_QUERY);
  const companies = (data && data.companies) || [];

  if (error) return <p>Error :(</p>;

  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={loading ? [] : toOptions(companies)}
      {...rest}
    />
  );
};

export default CompanySelectField;

import { useMutation } from "@apollo/client";
import React from "react";
import { Badge, Col, FormGroup } from "react-bootstrap";
import { FileVersionsToggle, useDeleteFileVersion } from "~/file-version";
import {
  AttachmentField,
  DateField,
  Form,
  Row,
  SelectField,
  TextField,
  UpdatePayload,
} from "~/form";
import { AttachableInput } from "~/form/attachment";
import { Document } from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import { removeFromRefs } from "~/node";
import { useSession } from "~/session";
import { formatDate, parseDate } from "~/util";
import {
  DOCUMENT_DELETE_MUTATION,
  DOCUMENT_UPDATE_MUTATION,
  toFormData,
  visibilityOptions,
} from ".";

interface Props {
  document: Document;
}

const EditDocument: React.FC<Props> = ({
  document,
  document: { id, file, receivedDate, acl },
  ...rest
}) => {
  const { generalEditor } = useSession();

  const [updateDocument] = useMutation(DOCUMENT_UPDATE_MUTATION);
  const [deleteDocument] = useMutation(DOCUMENT_DELETE_MUTATION);

  const deleteFileVersion = useDeleteFileVersion();

  const onUpdate = (
    input: UpdatePayload<AttachableInput<Document, "file">>
  ) => {
    return updateDocument({
      variables: {
        input: {
          ...input,
          id,
          date: parseDate(input.date),
        },
      },
    });
  };

  const onDelete = () => {
    return deleteDocument({
      variables: {
        input: {
          id,
        },
      },
      update(cache, { data }) {
        const report = data?.documentDelete?.document?.report;
        if (!report) throw new Error("Cannot update cache");

        cache.modify({
          id: cache.identify(report),
          fields: { documents: removeFromRefs(id) },
        });
      },
    });
  };

  return (
    <Form
      defaultValues={toFormData(document)}
      onSubmit={onUpdate}
      id={`document-${id}-form`}
      autoSave
      horizontal
      {...rest}
    >
      <Row className={id ? "align-items-center" : ""}>
        {generalEditor && (
          <Col lg="2">
            <SelectField name="visibility" options={visibilityOptions} />
          </Col>
        )}
        <Col lg={generalEditor ? 2 : 4}>
          <TextField name="name" required />
        </Col>
        <Col lg="2">
          <TextField name="sender" required />
        </Col>
        <Col lg="2">
          <TextField name="preparedBy" />
        </Col>
        <Col>
          <DateField name="date" notClearable />
        </Col>
        {generalEditor ? (
          <Col>
            <DateField
              name="receivedDate"
              label="Received Date"
              disabled={!file}
              notClearable
            />
          </Col>
        ) : (
          <Col lg="auto">
            <FormGroup>
              <Badge>
                {receivedDate ? formatDate(receivedDate) : <>&mdash;</>}
              </Badge>
            </FormGroup>
          </Col>
        )}
        <Col lg="auto">
          <DeleteButton
            onClick={onDelete}
            iconOnly
            disabled={!generalEditor && !acl?.delete}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="auto" className="d-flex flex-wrap align-items-center">
          <AttachmentField
            name="file"
            attachments={file}
            noDelete={!generalEditor}
          />
          {generalEditor && file && (
            <FileVersionsToggle node={{ id }} onDelete={deleteFileVersion} />
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default EditDocument;

import { Reference, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Col, FormGroup, FormLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Building } from "~/Building";
import {
  Form,
  FormUpdateState,
  NumberField,
  RedactorField,
  Row,
  SelectField,
  TextAreaField,
  toOptions,
} from "~/form";
import { DeleteButton } from "~/layout/Button";
import { useReport } from "~/report/ReportProvider";
import { useUpdateEffect } from "~/util";
import {
  CostLine,
  CostLineImageUploader,
  COST_LINE_DELETE_MUTATION,
  StatusBadge,
  toFormData,
} from ".";
import { COST_OPTIONS_QUERY } from "..";
import { CostBlock } from "../block";
import { useCostLineUpdate } from "./actions";

interface Props {
  buildings: Building[];
  costBlock: CostBlock;
  costLine: CostLine;
}

const getDisabledFields = ([period, priority, tg, kg, costCoverage]: [
  CostLine["period"],
  CostLine["priority"],
  CostLine["tg"],
  CostLine["kg"],
  CostLine["costCoverage"]
]) => {
  const text = period == "text";
  return {
    priority:
      !period ||
      ["missing_regulation", "immediate", "reclamation", "text"].includes(
        period as string
      ),
    tg: !period || !priority || text,
    kg: !period || !priority || tg === null || text,
    coverage:
      !period ||
      period == "reclamation" ||
      !priority ||
      tg == null ||
      kg === null ||
      text,
    cost:
      !period ||
      !priority ||
      tg == null ||
      kg === null ||
      !costCoverage ||
      text,
  };
};

// FIXME: refactor no costBlock + maintenanceDuration prop
const EditCostLineForm: React.FC<Props> = ({
  buildings,
  costBlock,
  costLine,
  costLine: { id, title, ref, status },
}) => {
  const formMethods = useForm({
    defaultValues: toFormData(costLine),
    mode: "all",
  });
  const { watch, reset } = formMethods;

  useUpdateEffect(() => reset(toFormData(costLine)), [costLine]);

  const { maintenanceDuration } = useReport();
  const { loading, error, data } = useQuery(COST_OPTIONS_QUERY, {
    variables: {
      maintenanceDuration,
    },
  });

  const updateCostLine = useCostLineUpdate();
  const [deleteCostLine] = useMutation(COST_LINE_DELETE_MUTATION);

  if (error) return <p>Error :(</p>;

  const onDelete = () => {
    return deleteCostLine({
      variables: {
        input: {
          id,
        },
      },
      update(cache) {
        cache.modify({
          id: cache.identify(costBlock),
          fields: {
            costLinesCount: (count: number) => count - 1,
            costLines: (existingCostLines, { readField }) =>
              existingCostLines.filter(
                (costLineRef: Reference) => id !== readField("id", costLineRef)
              ),
          },
        });
      },
    });
  };

  const watchFields = watch([
    "period",
    "priority",
    "tg",
    "kg",
    "costCoverage",
    "cost",
  ]);

  const { costOptions } = data || {};
  const disabledFields = getDisabledFields(watchFields);

  return (
    <Form onSubmit={updateCostLine} formMethods={formMethods} autoSave noReset>
      <div className="block-list-item-row">
        <div className="columns columns-align-middle">
          <div className="column-5">
            <div className="block-list-item-data">
              <StatusBadge status={status} className="mr-2" /> #{ref} {title}
            </div>
          </div>
          {/* <div className="column-5">ln
            <div className="block-list-item-data">{building.name}</div>
          </div> */}
          <div className="column-7">
            <div className="block-list-item-actions d-flex justify-content-end">
              <FormUpdateState />
              &nbsp;
              <DeleteButton onClick={onDelete} iconOnly />
              {/* <SaveFormButton style={{ marginTop: "1.5rem" }} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="block-list-item-row" style={{ overflow: "visible" }}>
        <div style={{ flex: 1 }}>
          <Row>
            <Col lg="4">
              <SelectField
                name="buildingId"
                label="Bygning"
                options={toOptions(buildings)}
              />
            </Col>
            <Col>
              <SelectField
                name="period"
                label="Tidsrom"
                isLoading={loading}
                options={loading ? [] : costOptions?.period}

                // onChange={handlePeriodChange}
              />
            </Col>
            <Col>
              <SelectField
                name="priority"
                label="Prioritet"
                isLoading={loading}
                options={loading ? [] : costOptions?.priority}
                disabled={disabledFields.priority}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="2">
              <SelectField
                name="tg"
                options={loading ? [] : costOptions?.tg}
                isLoading={loading}
                disabled={disabledFields.tg}
              />
            </Col>
            <Col lg="2">
              <SelectField
                name="kg"
                isLoading={loading}
                options={loading ? [] : costOptions?.kg}
                disabled={disabledFields.kg}
              />
            </Col>
            <Col>
              <SelectField
                name="costCoverage"
                label="Kostnadsfordeling"
                isLoading={loading}
                options={loading ? [] : costOptions?.coverage}
                disabled={disabledFields.coverage}
              />
            </Col>
            <Col lg="2">
              <NumberField
                name="cost"
                label="Kostnad"
                placeholder={0}
                disabled={disabledFields.cost}
                required
                // onChange={handleCostChange}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ position: "relative" }}>
              <RedactorField name="description" label="Beskrivelse" />
              <div
                style={{ position: "absolute", bottom: "2rem", right: "1rem" }}
              >
                <FormUpdateState />
              </div>
            </Col>
            <Col lg="3">
              <FormGroup>
                <FormLabel>Avviksbilde</FormLabel>
                <CostLineImageUploader costLine={costLine} />
              </FormGroup>
            </Col>
          </Row>
          <TextAreaField name="note" label="Notater" wrapperClassName="mb-0" />
        </div>
      </div>
    </Form>
  );
};

export default EditCostLineForm;

import {
  MaintenancePeriodEnum,
  MaintenanceTypeEnum,
} from "~/graphql-operations";

// TS enum produces double array key/value + value/key ?!
export const MaintenanceInterval = {
  "1_5Years": MaintenancePeriodEnum["1_5Years"],
  "6_10Years": MaintenancePeriodEnum["6_10Years"],
  "11_15Years": MaintenancePeriodEnum["11_15Years"],
  "16_20Years": MaintenancePeriodEnum["16_20Years"],
  "21_25Years": MaintenancePeriodEnum["21_25Years"],
  "26_30Years": MaintenancePeriodEnum["26_30Years"],
} as const;

export enum MaintenancePeriod {
  NONE = "NONE",
  MISSING_REGULATION = "MISSING_REGULATION",
  IMMEDIATE = "IMMEDIATE",
  ONE_TO_FIVE_YEARS = "_1_5_YEARS",
  SIX_TO_TEN_YEARS = "_6_10_YEARS",
  ELEVEN_TO_FIFTEEN_YEARS = "_11_15_YEARS",
  SIXTEEN_TO_TWENTY_YEARS = "_16_20_YEARS",
  TWENTY_ONE_TO_TWENTY_FIVE_YEARS = "_21_25_YEARS",
  TWENTY_SIX_TO_THIRTY_YEARS = "_26_30_YEARS",
  RECLAMATION = "RECLAMATION",
  TEXT = "TEXT",
}

export const maintenanceIntervalOptions = [
  { value: MaintenancePeriodEnum["1_5Years"], label: "1-5 år" },
  { value: MaintenancePeriodEnum["6_10Years"], label: "6-10 år" },
  { value: MaintenancePeriodEnum["11_15Years"], label: "11-15 år" },
  { value: MaintenancePeriodEnum["16_20Years"], label: "16-20 år" },
  { value: MaintenancePeriodEnum["21_25Years"], label: "21-25 år" },
  { value: MaintenancePeriodEnum["26_30Years"], label: "26-30 år" },
];

export const maintenancePeriodOptions = [
  { value: MaintenancePeriodEnum.None, label: "-" },
  { value: MaintenancePeriodEnum.MissingRegulation, label: "Forskriftsmangel" },
  { value: MaintenancePeriodEnum.Immediate, label: "Strakstiltak" },
  ...maintenanceIntervalOptions,
  { value: MaintenancePeriodEnum.Reclamation, label: "Reklamasjon" },
  { value: MaintenancePeriodEnum.Text, label: "Tekst" },
];

export enum MaintenancePriority {
  NONE = "NONE",
  REPLACEMENT = "REPLACEMENT",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export const maintenancePriorityOptions = [
  { value: MaintenancePriority.NONE, label: "-" },
  { value: MaintenancePriority.REPLACEMENT, label: "Utskiftning" },
  { value: MaintenancePriority.LOW, label: "Lav" },
  { value: MaintenancePriority.MEDIUM, label: "Middels" },
  { value: MaintenancePriority.HIGH, label: "Høy" },
];

// export enum MaintenanceType {
//   CONSTRUCTION = "CONSTRUCTION",
//   PLUMBING = "PLUMBING",
//   ELECTRICAL = "ELECTRICAL",
//   FIRE = "FIRE",
//   ECO = "ECO",
// }

export const maintenanceTypeOptions = [
  { value: MaintenanceTypeEnum.Construction, label: "Bygg" },
  { value: MaintenanceTypeEnum.Electrical, label: "Elektro" },
  { value: MaintenanceTypeEnum.Plumbing, label: "VVS" },
  { value: MaintenanceTypeEnum.Fire, label: "Brann" },
  { value: MaintenanceTypeEnum.Eco, label: "Miljø" },
];

import { useMutation } from "@apollo/client";
import React from "react";
import { Badge } from "react-bootstrap";
import Icon from "~/layout/icon";
import { BlockListItemTitle } from "../layout/BlockList";
import BlockListSortable from "../layout/BlockList/BlockListSortable";
import { BuildingType, BUILDING_TYPES_SORT_MUTATION } from "./constants";

interface Props {
  buildingTypes: BuildingType[];
  costIndex?: number;
}

const BuildingTypeListSortable: React.FC<Props> = ({ buildingTypes }) => {
  const [sortBuildingTypes] = useMutation(BUILDING_TYPES_SORT_MUTATION);

  // make apollo caching easier
  const sortedBuildingTypes = [...buildingTypes].sort(
    (a, b) => a.order - b.order
  );

  const onSort = (ids: string[]) => {
    sortBuildingTypes({
      variables: {
        input: {
          ids,
        },
      },
      // update(cache, { data: { buildingTypesSort } }) {
      //   cache.writeQuery({
      //     query: BUILDING_TYPES_QUERY,
      //     data: {
      //       buildingTypes: buildingTypesSort,
      //     },
      //   });
      // },
    });
  };

  return (
    <BlockListSortable items={sortedBuildingTypes} onSort={onSort}>
      {({ name, default: defaultBuilding }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon icon="move" style={{ marginRight: "1rem" }} />
          <BlockListItemTitle>{name}</BlockListItemTitle>
          {defaultBuilding && <Badge>default</Badge>}
        </div>
      )}
    </BlockListSortable>
  );
};

export default BuildingTypeListSortable;

import React from "react";
import { Form } from "react-bootstrap";
import { FieldError as IFieldError } from "react-hook-form";
import styled from "styled-components";

interface Props {
  error: Partial<IFieldError>;
}

const Wrap = styled(Form.Control.Feedback).attrs({ type: "invalid" })`
  position: absolute;
  top: 100%;
  z-index: 1;
  margin-top: 0.35rem;
`;

const Text = styled.span`
  display: inline-block;
  padding: 0.25rem 0.375rem;
  /* font-size: 11px; */
  color: var(--white);
  background-color: var(--danger);
  border-radius: 0.125rem;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
  opacity: 0.9;

  &:before {
    content: "";
    position: absolute;
    top: -0.7rem;
    border: 0.35rem solid transparent;
    border-bottom-color: var(--danger);
  }
`;

/**
 * Keep an eye on: @hookform/error-message -> https://react-hook-form.com/api/useformstate/errormessage
 */
const FieldError: React.FC<Props> = ({ error }) => {
  return (
    <Wrap type="invalid" style={{ display: "inherit" }}>
      <Text>
        {["required", "validate"].includes(error?.type || "")
          ? "Obligatoriske felt"
          : error.message || "Feil"}
      </Text>
    </Wrap>
  );
};

export default FieldError;

import { pick } from "lodash";
import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import { SubmitHandler } from "react-hook-form";
import {
  AddFormButton,
  CheckboxField,
  Form,
  FormProps,
  NumberField,
  Row,
  TextField,
} from "~/form";
import { Option } from "~/form/SelectField";
import { InvoiceEntryCategory } from "~/graphql-operations";
import { CategoryTypeSelectField } from "~/invoice/category-type";
import { DeleteButton } from "~/layout";
import { Currency } from "~/util";
import { useDeleteInvoiceEntryCategory } from ".";

interface Props extends FormProps<Partial<InvoiceEntryCategory>> {
  category?: any;
  onSubmit: SubmitHandler<InvoiceEntryCategory>;
  onDelete?: ReturnType<typeof useDeleteInvoiceEntryCategory>;
  typeOptions?: Option<InvoiceEntryCategory["type"]>[];
  typeLocked?: boolean;
}

const InvoiceEntryCategoryForm: React.FC<Props> = ({
  category,
  onSubmit,
  onDelete,
  typeLocked = false,
  typeOptions,
  ...rest
}) => {
  const defaultValues = category
    ? pick(category, ["id", "name", "type", "budget", "vatIncluded"])
    : {};
  const { id } = category || {};

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      autoSave={!!id}
      resetBlank={!id}
      id={id && `invoice-category-${id}-form`}
      {...rest}
    >
      <Row className={id ? "align-items-center" : "align-items-end"}>
        <Col lg="4">
          <TextField name="name" label="Kategorinavn" required />
        </Col>
        <Col lg="3">
          <CategoryTypeSelectField
            name="type"
            label="Type"
            disabled={typeLocked}
            options={typeOptions}
            required={!typeLocked}
          />
        </Col>
        <Col lg="2">
          <NumberField name="budget" label="Budsjett" required />
        </Col>
        <Col lg="auto">
          <CheckboxField name="vatIncluded" label="MVA ink." />
        </Col>
        {id && (
          <Col className="text-right">
            <Currency>{category?.total}</Currency>
          </Col>
        )}
        {id && onDelete && (
          <Col lg="auto" style={{ paddingLeft: 0 }}>
            <FormGroup>
              <DeleteButton
                icon="delete"
                iconOnly
                onClick={() => onDelete(id)}
              />
            </FormGroup>
          </Col>
        )}
        {!id && (
          <Col lg="auto">
            <FormGroup>
              <AddFormButton style={{ marginTop: "1.5rem" }} />
            </FormGroup>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default InvoiceEntryCategoryForm;

import { useMutation } from "@apollo/client";
import {
  InvoiceEntryCategoryGroup,
  INVOICE_ENTRY_CATEGORY_GROUP_CREATE_MUTATION,
  INVOICE_ENTRY_CATEGORY_GROUP_DELETE_MUTATION,
  INVOICE_ENTRY_CATEGORY_GROUP_UPDATE_MUTATION,
} from ".";

type CreateInvoiceEntryCategoryGroupInput = Pick<
  InvoiceEntryCategoryGroup,
  "contractorId" | "name"
>;

export const useCreateInvoiceEntryCategoryGroup = () => {
  const [createMutation] = useMutation(
    INVOICE_ENTRY_CATEGORY_GROUP_CREATE_MUTATION
  );

  return async (input: CreateInvoiceEntryCategoryGroupInput) => {
    return createMutation({ variables: { input } });
  };
};

export const useUpdateInvoiceEntryCategoryGroup = () => {
  const [updateMutation] = useMutation(
    INVOICE_ENTRY_CATEGORY_GROUP_UPDATE_MUTATION
  );

  return async (input: InvoiceEntryCategoryGroupFormData) => {
    return updateMutation({ variables: { input } });
  };
};

export const useDeleteInvoiceEntryCategoryGroup = () => {
  const [deleteMutation] = useMutation(
    INVOICE_ENTRY_CATEGORY_GROUP_DELETE_MUTATION
  );

  return async (id: InvoiceEntryCategoryGroup["id"], cascade: boolean) => {
    return deleteMutation({ variables: { input: { id, cascade } } });
  };
};

import { default as React, useState } from "react";
import { Button, ButtonProps, Modal } from "react-bootstrap";
import { SaveButton } from "~/layout";
import { Icons } from "~/layout/icon/constants";
import { useModal, useMounted } from "~/util";

interface Props {
  message?: string | React.ReactNode;
  onConfirm: (ev?: React.MouseEvent<HTMLElement>) => Promise<unknown> | void;
  onReject?: (ev?: React.MouseEvent<HTMLElement>) => Promise<unknown> | void;
  title?: string;
  confirmLabel?: string;
  rejectLabel?: string;
  size?: ButtonProps["size"];
  icon?: Icons;
  danger?: boolean;
}

export interface ConfirmModalProps extends Props {
  confirmMethods: ReturnType<typeof useModal>;
}

export const ConfirmModal = ({
  confirmMethods,
  message = "Er du sikker?",
  title,
  confirmLabel = "Bekrefte",
  rejectLabel = "Avbryt",
  onConfirm,
  onReject,
}: React.PropsWithChildren<ConfirmModalProps>) => {
  const isMounted = useMounted();
  const [loading, setLoading] = useState(false);
  const { active, close } = confirmMethods;

  const handleConfirm: Props["onConfirm"] = async (ev) => {
    setLoading(true);
    await onConfirm(ev);
    if (isMounted()) setLoading(false);
    close();
  };
  const handleReject: Props["onReject"] = async (ev) => {
    if (!onReject) return close();

    setLoading(true);
    await onReject(ev);
    close();
    if (isMounted()) setLoading(false);
  };

  return (
    <Modal show={active} onHide={close}>
      <Modal.Header closeButton>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleReject} disabled={loading}>
          {rejectLabel}
        </Button>
        <SaveButton
          variant="primary"
          icon="check"
          onClick={handleConfirm}
          disabled={loading}
        >
          {confirmLabel}
        </SaveButton>
      </Modal.Footer>
    </Modal>
  );
};

const ConfirmButton: React.FC<Props> = ({
  size,
  icon = "check",
  danger = false,
  children,
  ...rest
}) => {
  const confirmMethods = useModal();
  const { show } = confirmMethods;

  return (
    <>
      <SaveButton
        size={size}
        icon={icon}
        onClick={show}
        variant={danger ? "danger" : undefined}
      >
        {children}
      </SaveButton>

      <ConfirmModal confirmMethods={confirmMethods} {...rest} />
    </>
  );
};

export default ConfirmButton;

import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { AddFormButton, Form, TextField } from "~/form";
import { InvoiceContractor } from "~/invoice/contractor";
import { useCreateInvoiceEntryCategoryGroup } from "./actions";

interface Props {
  contractor: Pick<InvoiceContractor, "id">;
}

const NewInvoiceEntryCategoryGroup: React.FC<Props> = ({
  contractor: { id },
}) => {
  const onSubmit = useCreateInvoiceEntryCategoryGroup();

  return (
    <Form defaultValues={{ contractorId: id }} onSubmit={onSubmit} resetBlank>
      <Row>
        <Col lg="4">
          <TextField name="name" label="Gruppenavn" required />
        </Col>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton style={{ marginTop: "1.5rem" }} />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default NewInvoiceEntryCategoryGroup;

import React from "react";
import ContentLoader from "react-content-loader";

type Props = {
  width?: number | string;
  height?: number | string;
};
const DateLoader: React.FC<Props> = ({ width = 120, height = 10 }) => {
  return (
    <ContentLoader
      speed={2}
      width={120}
      height={20}
      backgroundColor="#bebebe"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="5" rx="3" ry="3" width={width} height={height} />
    </ContentLoader>
  );
};

export default DateLoader;

import React from "react";
import { Object } from "ts-toolbelt";
import {
  ConstructionLoan,
  ConstructionLoanPeriodWithInvoicesQuery,
} from "~/graphql-operations";
import { useSession } from "~/session";
import {
  EditAffiliateInvoice,
  EditContractorInvoice,
  EditExternalInvoice,
  MigrateClientInvoice,
} from ".";
import {
  isClientInvoice,
  isContractorInvoice,
  isExternalInvoice,
} from "./helpers";

interface Props {
  invoice: NonNullable<
    Object.Path<
      ConstructionLoanPeriodWithInvoicesQuery,
      ["constructionLoanPeriod", "invoices"]
    >
  >[number];
  // invoice: ClientInvoice | ExternalInvoice | ContractorInvoice;
  report: Pick<
    ConstructionLoan,
    | "balance"
    | "contractors"
    | "currencies"
    | "invoiceCategories"
    | "vatIncluded"
  >;
}

const EditInvoice: React.FC<Props> = ({
  invoice,
  report,
  report: { currencies, invoiceCategories, vatIncluded },
}) => {
  const { role } = useSession();

  // TODO: should fetch user's report membership - viewer field on report?
  if (["client", "collaborator"].includes(role))
    return <EditAffiliateInvoice invoice={invoice} currencies={currencies} />;

  if (isClientInvoice(invoice))
    return <MigrateClientInvoice invoice={invoice} currencies={currencies} />;

  if (isContractorInvoice(invoice))
    return <EditContractorInvoice invoice={invoice} report={report} />;

  if (isExternalInvoice(invoice)) {
    return (
      <EditExternalInvoice
        invoice={invoice}
        categories={invoiceCategories}
        currencies={currencies}
        vatIncluded={vatIncluded}
      />
    );
  }

  return <p>Error in judgment.</p>;
};

export default EditInvoice;

import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styled, { css } from "styled-components";

interface Props extends ButtonProps {
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const StyledButton = styled(Button)<{
  $active: boolean;
  disabled: boolean;
}>`
  display: inline-flex;
  align-items: center;

  ${({ variant }) =>
    variant == "link" &&
    css`
      padding: 0;
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    `}

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 3.75rem;
    height: 1.5rem;
    margin: -0.25rem 0;
    ${({ $text }) => ($text ? "margin-right: 0.75rem;" : "")}
    padding: 0;
    padding-left: ${({ $active }) => ($active ? "0.75rem" : "0.1875rem")};
    padding-right: ${({ $active }) => ($active ? "0.1875rem" : "0.75rem")};
    font-weight: bold;
    color: var(--white);
    background: rgba(0, 0, 0, ${({ $active }) => ($active ? "0.5" : "0.15")});
    border: none;
    border-radius: 0.75rem;
    cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    &:after {
      content: "";
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      background: var(${({ $active }) => ($active ? "--yellow" : "--white")});
      border-radius: 50%;
      box-shadow: 1px 1px 3px
        var(${({ $active }) => ($active ? "--black" : "--dark-gray")});
      order: ${({ $active }) => ($active ? 1 : -1)};
    }
  }
`;

const ToggleButton: React.FC<Props> = ({ active, children, ...rest }) => {
  return (
    <StyledButton $active={active} $text={!!children} {...rest}>
      <span>{active ? "PÅ" : "AV"}</span>
      {children}
    </StyledButton>
  );
};

export default ToggleButton;

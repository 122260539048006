import { ApolloProvider } from "@apollo/client";
import React from "react";
import invariant from "tiny-invariant";
import { Fieldset, FieldsetTitle } from "~/form";
import { MembershipRelation, useEditAudienceQuery } from "~/graphql-operations";
import ErrorAlert from "~/layout/ErrorAlert";
import { Report } from "~/Report";
import { client as apolloClient } from "~/setup";
import { affiliateLoader, filteredLoadOptions } from "~/user";
import {
  ClientField,
  EditableMember,
  EditMemberList,
  NewReportMember,
} from ".";
import { ReportType } from "../constants";
import { EditConstructionLoanMemberList } from "../construction-loan/member";
import EditEmailPreferences from "./EditEmailPreferences";

interface Props {
  report: Pick<Report, "id" | "slug" | "__typename">;
  clientLabel?: string;
  associatesLabel?: string;
  collaboratorsLabel?: string;
}

const EditAudience: React.FC<Props> = ({
  report: { id, slug, __typename },
  clientLabel = "Oppdragsgiver",
  associatesLabel = "Kopi",
  collaboratorsLabel = "Adgang",
}) => {
  const { loading, error, data } = useEditAudienceQuery({
    variables: { slug },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <ErrorAlert error={error} />;
  invariant(data?.report, "Report not found");

  const {
    report: { client, associates, collaborators },
  } = data;

  const userIds = [client, ...associates, ...collaborators]
    .map((m) => m?.user?.id)
    .filter(Boolean) as string[];

  const loadOptions = filteredLoadOptions(affiliateLoader, {
    exclude: userIds,
  });

  const cl = __typename === ReportType.CONSTRUCTION_LOAN;

  const EditMembersComponent = cl
    ? EditConstructionLoanMemberList
    : EditMemberList;

  return (
    <>
      <Fieldset>
        <FieldsetTitle>{clientLabel}</FieldsetTitle>
        <ClientField
          reportId={id}
          client={client as EditableMember}
          loadOptions={loadOptions}
        >
          {cl && client && client.membership && (
            <EditEmailPreferences membership={client.membership} />
          )}
        </ClientField>
      </Fieldset>
      <Fieldset>
        <FieldsetTitle>{associatesLabel}</FieldsetTitle>
        <EditMembersComponent members={associates as EditableMember[]} />
        <NewReportMember
          reportId={id}
          loadOptions={loadOptions}
          relation={MembershipRelation.Associate}
        />
      </Fieldset>
      <Fieldset>
        <FieldsetTitle>{collaboratorsLabel}</FieldsetTitle>
        <EditMembersComponent members={collaborators as EditableMember[]} />
        <NewReportMember
          reportId={id}
          loadOptions={loadOptions}
          relation={MembershipRelation.Collaborator}
        />
      </Fieldset>
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={apolloClient}>
    <EditAudience {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { EventList, NewEvent } from "~/event";
import { Report } from "~/report";
import { client } from "~/setup";

interface Props {
  report: Report;
}

const EventsPage: React.FC<Props> = ({ report }) => {
  return (
    <>
      <EventList report={report} />
      <NewEvent report={report} />
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <EventsPage {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

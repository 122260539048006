import React from "react";
import { Col, Row } from "react-bootstrap";
import invariant from "tiny-invariant";
import {
  ConstructionLoan,
  useConstructionLoanContractorsQuery,
} from "~/graphql-operations";
import BlockList, {
  BlockListHeader,
  BlockListItemData,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { DeleteButton } from "~/layout/Button";
import { Currency } from "~/util";
import { EditContractor, useDeleteContractor } from ".";
import EditContractorBudgetPlanLabel from "./EditBudgetPlanLabel";

interface Props {
  report: Pick<ConstructionLoan, "id">;
}

const EditContractorList: React.FC<Props> = ({ report: { id } }) => {
  const { loading, error, data } = useConstructionLoanContractorsQuery({
    variables: { id },
  });

  const onDelete = useDeleteContractor();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const report = data?.constructionLoan;
  invariant(report, "Report not found");

  const { contractorBudgetPlanLabel, contractors, vatIncluded } = report;

  return (
    <>
      <EditContractorBudgetPlanLabel report={report} />
      <hr />
      <BlockListHeader>
        <Row>
          <Col lg="8">Entreprenør</Col>
          <Col lg="3">Budsjett</Col>
          <Col lg="1" className="_tar">
            Valg
          </Col>
        </Row>
      </BlockListHeader>
      <BlockList>
        {contractors?.map((contractor) => {
          const { id, name, budget } = contractor;
          return (
            <BlockListItemExpandable
              key={id}
              render={({ expanded }) => (
                <Row style={{ alignItems: "center" }}>
                  <Col lg="8">
                    <BlockListItemTitle>{name}</BlockListItemTitle>
                  </Col>
                  <Col lg="3">
                    <BlockListItemData>
                      <Currency>{budget}</Currency>
                    </BlockListItemData>
                  </Col>
                  <Col lg="1" className="_tar">
                    <DeleteButton
                      iconOnly
                      onClick={() => onDelete(id)}
                      inverse={expanded}
                      style={{ padding: 0 }}
                    />
                  </Col>
                </Row>
              )}
            >
              <EditContractor
                contractor={contractor}
                contractorBudgetPlanLabel={contractorBudgetPlanLabel}
                vatIncluded={vatIncluded}
              />
            </BlockListItemExpandable>
          );
        })}
      </BlockList>
    </>
  );
};

export default EditContractorList;

import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { SaveFormButton } from "~/form";
import { DeleteButton, SaveButton } from "~/layout";
import { BudgetPlan, budgetPlansQuery } from ".";
import { HousingMaintenanceSection } from "../constants";

interface Props {
  housingMaintenanceSection: HousingMaintenanceSection;
  name: string | undefined;
  onLoad: (budgetPlan: BudgetPlan) => void;
  onSave?: () => Promise<unknown>;
  onSaveAs?: (name: string) => Promise<unknown>;
  onDelete?: () => Promise<unknown>;
  onDownload?: () => Promise<unknown>;
}

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: 0.125rem;
  }
`;

const BudgetPlanToolbar: React.FC<Props> = ({
  housingMaintenanceSection,
  name: nameProp,
  onLoad,
  onSave,
  onSaveAs,
  onDownload,
  onDelete,
}) => {
  const { loading, error, budgetPlans = [] } = budgetPlansQuery(
    housingMaintenanceSection
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState(nameProp);
  useEffect(() => setName(nameProp), [nameProp]);

  const handleSaveAs = async () => {
    onSaveAs && (await onSaveAs(name as string));
    handleClose();
  };

  if (error) return <p>Error :(</p>;

  return (
    <>
      <ButtonWrap>
        {onSave && (
          <Button size="sm" onClick={onSave}>
            Save
          </Button>
        )}
        <Button size="sm" disabled={!onSaveAs} onClick={handleShow}>
          Save as
        </Button>
        {onDownload && (
          <SaveButton icon="filePdf" iconOnly size="sm" onClick={onDownload} />
        )}
        <Dropdown>
          <Dropdown.Toggle
            variant="info"
            size="sm"
            disabled={loading || !budgetPlans.length}
          >
            Load
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {budgetPlans.map((budgetPlan) => (
              <Dropdown.Item
                key={budgetPlan.id}
                onClick={() => onLoad(budgetPlan)}
              >
                {budgetPlan.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {onDelete && (
          <DeleteButton size="sm" onClick={onDelete}>
            {null}
          </DeleteButton>
        )}
      </ButtonWrap>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Save as</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <SaveFormButton
            variant="primary"
            icon="check"
            disabled={!name}
            onClick={handleSaveAs}
          >
            Save
          </SaveFormButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BudgetPlanToolbar;

import styled, { css } from "styled-components";

import { Currency } from "~/util";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLTableCellElement> {
  total?: boolean;
  left?: boolean;
  right?: boolean;
  borderLeft?: true;
  borderRight?: true;
  borderY?: true;
  children?: React.ReactNode;
  colSpan?: number;
}

export const StyledTCell = styled.td<Props>`
  text-align: center;
  vertical-align: middle !important;

  ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left: 1px solid var(--smoke) !important;
    `}

  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid var(--smoke) !important;
    `}

  ${({ borderY }) =>
    borderY &&
    css`
      border-left: 1px solid var(--smoke) !important;
      border-right: 1px solid var(--smoke) !important;
    `}

  ${({ left }) =>
    left &&
    css`
      text-align: left;
    `}

  ${({ right }) => {
    return (
      right &&
      css`
        text-align: right;
      `
    );
  }}

  ${({ total }) =>
    total &&
    css`
      background-color: rgba(242, 242, 242, 0.4);
    `}
`;

const TCell: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <StyledTCell {...rest}>
      {typeof children === "number" || children === null ? (
        <Currency>{children}</Currency>
      ) : (
        children
      )}
    </StyledTCell>
  );
};

export default TCell;

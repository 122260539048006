import React from "react";
import SaveButton, { SaveButtonProps } from "./SaveButton";

const AddButton: React.FC<SaveButtonProps> = ({ children, ...rest }) => {
  return (
    <SaveButton icon="plusCircle" {...rest}>
      {children !== undefined ? children : "Legg Til"}
    </SaveButton>
  );
};

export default AddButton;

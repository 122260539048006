import React from "react";
import styled, { css } from "styled-components";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  sub?: boolean;
}

const StyledList = styled.div<Props>`
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;

  /* ${({ sub }) =>
    sub &&
    css`
      padding: 1rem;
      border: 1px solid var(--mystic);
      background-color: var(--smoke);
      border-radius: 4px;

      ${StyledBlockListHeader} {
        padding: 1rem;
        border-bottom: 1px solid var(--mystic);
      }

      ${StyledBlockListItem} {
        border: 0;
      }
    `} */
`;

// const BlockList = React.forwardRef<HTMLDivElement, Props>(
//   ({ children, sub = false }, ref) => {
//     return (
//       <StyledList sub={sub} ref={ref}>
//         {children}
//       </StyledList>
//     );
//   }
// );
// BlockList.displayName = "BlockListFwRef";

export default StyledList;

import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { DeleteButton } from "~/layout";
import BlockList, {
  BlockListHeader,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { client } from "~/setup";
import {
  CostBlockTemplate,
  COST_BLOCK_TEMPLATES_QUERY,
  EditCostBlockTemplate,
  NewCostBlockTemplate,
} from ".";
import { EditCostLineTemplateList } from "../line-template";
import { useDeleteCostBlockTemplate } from "./hooks";

const RefCol = styled(Col)`
  flex: 0 0 3rem;
  padding-right: 0;
`;

const CostBlockTemplateListPage: React.FC = () => {
  const deleteBlock = useDeleteCostBlockTemplate();
  const { loading, error, data } = useQuery<{
    costBlockTemplates: CostBlockTemplate[];
  }>(COST_BLOCK_TEMPLATES_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error || !data) return <p>Error</p>;

  const { costBlockTemplates } = data;

  return (
    <>
      <BlockListHeader>
        <Row className="align-items-center">
          <RefCol>Ref</RefCol>
          <Col>Navn</Col>
        </Row>
      </BlockListHeader>
      <BlockList>
        {costBlockTemplates.map(({ id, title, number, costLineTemplates }) => (
          <BlockListItemExpandable
            key={id}
            id={`cost-block-template-${id}`}
            render={({ expanded }) => (
              <Row className="align-items-center">
                <RefCol>#{number}</RefCol>
                <Col>
                  <BlockListItemTitle>{title}</BlockListItemTitle>
                </Col>
                <Col className="d-flex justify-content-end">
                  <DeleteButton
                    iconOnly
                    onClick={() => deleteBlock(id)}
                    inverse={expanded}
                  />
                </Col>
              </Row>
            )}
          >
            <EditCostBlockTemplate template={{ id, title, number }} />
            {costLineTemplates && (
              <EditCostLineTemplateList
                lines={costLineTemplates}
                costBlockTemplateId={id}
              />
            )}
          </BlockListItemExpandable>
        ))}
      </BlockList>
      <NewCostBlockTemplate />
    </>
  );
};

const ApolloWrap: React.FC = () => (
  <ApolloProvider client={client}>
    <CostBlockTemplateListPage />
  </ApolloProvider>
);

export default ApolloWrap;

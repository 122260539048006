import React from "react";
import { Col } from "react-bootstrap";
import { DateField, Form, Row, SaveFormButton } from "~/form";
import {
  ConstructionLoanPeriod,
  PublishConstructionLoanPeriodInput,
  usePublishConstructionLoanPeriodMutation,
} from "~/graphql-operations";
import { ConfirmButton, SaveButton } from "~/layout";
import Modal, { useModal } from "~/layout/Modal";
import { mutationSuccess } from "~/util";

interface Props {
  period: Pick<
    ConstructionLoanPeriod,
    "id" | "publishable" | "publishedAt" | "status"
  >;
}

const publishTxt = "Lås og publiser";

const Publish: React.FC<Props> = ({
  period: { id, publishable, publishedAt, status },
}) => {
  const { active, show, close } = useModal();
  const showPublishingDateModal = () => show();

  const [publishMutation] = usePublishConstructionLoanPeriodMutation();
  const onPublish = async (input: PublishConstructionLoanPeriodInput) => {
    const response = await publishMutation({ variables: { input } });
    if (mutationSuccess(response)) close();
  };

  const warningMessage =
    status === "touched" && "Not all invoices are complete.";

  return (
    <>
      {publishable && warningMessage ? (
        <ConfirmButton
          icon="warning"
          message={warningMessage}
          title="Advarsel"
          confirmLabel="Revidere"
          rejectLabel="Ignorer og fortsett"
          onConfirm={() => Promise.resolve(true)}
          onReject={showPublishingDateModal}
        >
          {publishTxt}
        </ConfirmButton>
      ) : (
        <SaveButton
          onClick={showPublishingDateModal}
          icon={publishable ? "check" : "deny"}
          disabled={!publishable}
        >
          {publishTxt}
        </SaveButton>
      )}

      <Modal show={active} onHide={close} title="Set publishing date">
        <Form
          defaultValues={{ id, publishedAt: publishedAt || new Date() }}
          onSubmit={onPublish}
          horizontal
        >
          <Row>
            <Col>
              <DateField name="publishedAt" label="Publishing date" required />
            </Col>
            <Col>
              <SaveFormButton>{publishTxt}</SaveFormButton>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Publish;

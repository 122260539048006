import { ApolloProvider } from "@apollo/client";
import React, { useState } from "react";
import { useDeleteUserMutation, useUserIndexQuery } from "~/graphql-operations";
import { client } from "~/setup";
import { UserFilter, UserList } from ".";

const UserIndexPage: React.FC = () => {
  const [term, setTerm] = useState<string>("");
  const [deleteMutation] = useDeleteUserMutation();
  const { loading, error, data } = useUserIndexQuery({
    variables: { term },
  });

  if (error) return <h2>Error</h2>;

  const users = data?.users || [];
  const onFilter = ({ term }: { term: string }) => setTerm(term);

  const deleteUser = (id: string) =>
    deleteMutation({
      variables: { input: { id } },
      refetchQueries: ["UserIndex"],
    });

  return (
    <>
      <UserFilter onSubmit={onFilter} className="mb-3" />
      <UserList loading={loading} users={users} deleteUser={deleteUser} />
    </>
  );
};

const ApolloWrap: React.FC = (props) => (
  <ApolloProvider client={client}>
    <UserIndexPage {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from "./Shortcode";

const REPORT_LEGAL_OWNER_QUERY = gql`
  query Report($slug: ID!) {
    report(slug: $slug) {
      id
      legalOwner
    }
  }
`;

const EiendomHjemmelshaver: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(REPORT_LEGAL_OWNER_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  const { legalOwner } = data?.report || {};

  return <>{loading ? <WordLoader /> : `${legalOwner}`}</>;
};

export default EiendomHjemmelshaver;

import React from "react";
import ContentLoader from "react-content-loader";

interface Props {
  width: number;
  height?: number;
}
const TableDataLoader: React.FC<Props> = ({ width, height = 15 }) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      backgroundColor="#bebebe"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width={width} height={height} />
    </ContentLoader>
  );
};

export default TableDataLoader;

import { gql, useQuery } from "@apollo/client";
import { Table, TCell, THCell, THead, TRow } from "~/layout/table/CostTable";

import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from ".";
import { MVA } from "./KostnadsfordelingTabell";

/*
 NOTE Add query field :
  - query  every type & total for period (1 to last year (sum))
  - query every type & total cost_by_year_by_square_meter_full_length
*/

const REPORT_COST_SUMMARY_TDD_TABLE_QUERY = gql`
  query ReportVedlikeholdKostnadsTddTabellQuery($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Costs {
        cost {
          missingRegulationConstruction: total(
            period: MISSING_REGULATION
            type: CONSTRUCTION
          )
          missingRegulationElectrical: total(
            period: MISSING_REGULATION
            type: ELECTRICAL
          )
          missingRegulationPlumbing: total(
            period: MISSING_REGULATION
            type: PLUMBING
          )
          missingRegulationFire: total(period: MISSING_REGULATION, type: FIRE)

          immediateActionConstruction: total(
            period: IMMEDIATE
            type: CONSTRUCTION
          )
          immediateActionElectrical: total(period: IMMEDIATE, type: ELECTRICAL)
          immediateActionPlumbing: total(period: IMMEDIATE, type: PLUMBING)
          immediateActionFire: total(period: IMMEDIATE, type: FIRE)

          totalConstruction: total(type: CONSTRUCTION)
          totalPlumbing: total(type: PLUMBING)
          totalElectrical: total(type: ELECTRICAL)
          totalFire: total(type: FIRE)

          missingRegulationTotal: total(period: MISSING_REGULATION)
          immediateActionTotal: total(period: IMMEDIATE)
        }
      }
    }
  }
`;

const VedlikeholdKostnadsTddTabell: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(
    REPORT_COST_SUMMARY_TDD_TABLE_QUERY,
    {
      variables: { slug },
    }
  );

  if (loading) return <WordLoader />;
  if (error) return <p>Error</p>;

  const {
    missingRegulationConstruction,
    missingRegulationElectrical,
    missingRegulationPlumbing,
    missingRegulationFire,
    immediateActionConstruction,
    immediateActionElectrical,
    immediateActionPlumbing,
    immediateActionFire,
    missingRegulationTotal,
    immediateActionTotal,
    totalConstruction,
    totalElectrical,
    totalFire,
    totalPlumbing,
    // fullM2YearConstruction,
    // fullM2YearPlumbing,
    // fullM2YearElectrical,
    // fullYearM2Fire,
    // fullM2YearTotal,
  } = data?.report?.cost || {};

  const showConstruction = !!totalConstruction;
  const showElectrical = !!totalElectrical;
  const showFire = !!totalFire;
  const showPlumbing = !!totalPlumbing;

  return (
    <>
      <Table responsive="sm" borderless>
        <THead>
          <TRow>
            <THCell>{}</THCell>
            <THCell right>Forskriftsmangler</THCell>
            <THCell right>Strakstiltak</THCell>
            <THCell right></THCell>
            <THCell right>
              Per m <sup>2</sup> / BTA
            </THCell>
          </TRow>
        </THead>
        <tbody>
          {showConstruction && (
            <TRow right>
              <TCell>Bygg</TCell>
              <TCell>{missingRegulationConstruction}</TCell>
              <TCell>{immediateActionConstruction}</TCell>
              <TCell>one to ...</TCell>
              <TCell>fullYearM2</TCell>
            </TRow>
          )}
          {showPlumbing && (
            <TRow right>
              <TCell>Vvs</TCell>
              <TCell>{missingRegulationPlumbing}</TCell>
              <TCell>{immediateActionPlumbing}</TCell>
              <TCell>one to ...</TCell>
              <TCell>fullYearM2</TCell>
            </TRow>
          )}
          {showElectrical && (
            <TRow right>
              <TCell>Elektro</TCell>
              <TCell>{missingRegulationElectrical}</TCell>
              <TCell>{immediateActionElectrical}</TCell>
              <TCell>one to ...</TCell>
              <TCell>fullYearM2</TCell>
            </TRow>
          )}
          {showFire && (
            <TRow right>
              <TCell>Brann</TCell>
              <TCell>{missingRegulationFire}</TCell>
              <TCell>{immediateActionFire}</TCell>
              <TCell>one to ...</TCell>
              <TCell>fullYearM2</TCell>
            </TRow>
          )}
          <TRow right important>
            <TCell>Sum ekskl. mva.</TCell>
            <TCell>{missingRegulationTotal}</TCell>
            <TCell>{immediateActionTotal}</TCell>
            <TCell>one to ...</TCell>
            <TCell>fullYearM2</TCell>
          </TRow>
          <TRow right>
            <TCell>Merverdiavgift (25%)</TCell>
            <TCell>{missingRegulationTotal * MVA}</TCell>
            <TCell>{immediateActionTotal * MVA}</TCell>
            <TCell>{}</TCell>
            <TCell>fullYearM2</TCell>
          </TRow>
        </tbody>
        <tfoot>
          <TRow right>
            <TCell>Sum ekskl. mva.</TCell>
            <TCell>{missingRegulationTotal * (1 + MVA)}</TCell>
            <TCell>{immediateActionTotal * (1 + MVA)}</TCell>
            <TCell>{}</TCell>
            <TCell>fullYearM2</TCell>
          </TRow>
        </tfoot>
      </Table>
    </>
  );
};

export default VedlikeholdKostnadsTddTabell;

import { useMutation } from "@apollo/client";
import { useState } from "react";
import { AttachableInput, uploadFile } from "~/form/attachment";
import {
  CreateClientInvoiceDocument,
  CreateContractorInvoiceDocument,
  CreateExternalInvoiceDocument,
  Invoice,
} from "./../graphql-operations";
import { InvoiceType, INVOICE_AFFILIATE_UPDATE_MUTATION } from "./constants";

const createInvoiceMutation = (type: InvoiceType) => {
  switch (type) {
    case InvoiceType.CLIENT:
      return CreateClientInvoiceDocument;
    case InvoiceType.CONTRACTOR:
      return CreateContractorInvoiceDocument;
    case InvoiceType.EXTERNAL:
      return CreateExternalInvoiceDocument;
    default:
      throw new Error("Unknown invoice type");
  }
};

// interface CreateClientInvoiceInput
//   extends Pick<
//     ClientInvoice,
//     "id" | "sender" | "number" | "amount" | "currency" | "dueDate"
//   > {
//   file: FileList;
// }

export const useCreateInvoice = <T extends Invoice>(
  type: InvoiceType
): [typeof onSubmit, number | undefined] => {
  const [createMutation] = useMutation(createInvoiceMutation(type));
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(
    undefined
  );

  const onSubmit = async (input: T & { file: FileList }) => {
    const { file } = input;
    const signedId =
      file.length > 0
        ? await uploadFile(file[0], (progress) => setUploadProgress(progress))
        : undefined;

    // Array.from(files).forEach(file => uploadFile(file));

    const res = await createMutation({
      variables: { input: { ...input, file: signedId } },
      // update: invoiceCreateCacheUpdate(type),
    });
    setUploadProgress(undefined);

    return res;
  };

  return [onSubmit, uploadProgress];
};

// const invoiceCreateCacheUpdate = (type: InvoiceType) => {
//   const key = ((type: InvoiceType) => {
//     switch (type) {
//       case InvoiceType.CLIENT:
//         return "createClientInvoice";
//       case InvoiceType.CONTRACTOR:
//         return "createContractorInvoice";
//       case InvoiceType.EXTERNAL:
//         return "createExternalInvoice";
//       default:
//         throw new Error("Unknown invoice type");
//     }
//   })(type);

//   const updateFunction: MutationUpdaterFn = (cache, { data }) => {
//     const newInvoice = data?.[key]?.invoice as Invoice;
//     if (!newInvoice) return;

//     const { period } = newInvoice;

//     const newRef = cache.writeFragment({
//       data: newInvoice,
//       fragment: invoiceFragment,
//       fragmentName: "InvoiceFields",
//     });
//     if (!newRef) throw new Error("Could not get ref for newInvoice");

//     const cacheId = cache.identify(period);
//     if (!cacheId) {
//       console.log(period, newRef);
//       throw new Error(`No cache found for period: ${period.id}`);
//     }

//     cache.modify({
//       id: cacheId,
//       fields: {
//         invoices: (refs: Reference[], { readField }) =>
//           [...refs, newRef]
//             .sort((a, b) => {
//               const getDate = (a: Reference) =>
//                 Date.parse(
//                   readField<string>("dueDate", a) || new Date().toString()
//                 );

//               return getDate(a) - getDate(b);
//             })
//             .reverse(),
//       },
//     });
//   };

//   return updateFunction;
// };

type UpdateClientInvoiceInput = AttachableInput<
  Pick<Invoice, "id" | "file">,
  "file"
> &
  Partial<
    Pick<Invoice, "sender" | "number" | "dueDate" | "amount" | "currency">
  >;

export const useUpdateAffiliateInvoice = () => {
  const [mutation] = useMutation(INVOICE_AFFILIATE_UPDATE_MUTATION);

  return async (input: UpdateClientInvoiceInput) => {
    const res = await mutation({ variables: { input } });

    if (res?.data?.invoiceUpdate?.errors?.length > 0)
      throw new Error(res.data.invoiceUpdate.errors.join("\n"));

    return res;
  };
};

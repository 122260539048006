import { AttachableInput } from "~/form/attachment";
import { Document } from "./constants";

export const visibilityOptions = [
  { value: "private", label: "private (ber only)" },
  { value: "public", label: "public (anyone)" },
  { value: "client", label: "client (client + ber)" },
  { value: "bank", label: "bank (bank + ber) " },
];

type toFormDataInterface = (
  document: Partial<Document>
) => Partial<AttachableInput<Document, "file">>;

// only what form sees, so it know to track changes!
export const toFormData: toFormDataInterface = ({
  visibility,
  name,
  sender,
  date,
  preparedBy,
  file,
  receivedDate,
}) => {
  return {
    visibility,
    name,
    sender,
    date,
    preparedBy,
    file: file?.signedId,
    receivedDate,
  };
};

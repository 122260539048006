import React from "react";
import { Col } from "react-bootstrap";
import { Fieldset, Form, Row, TextField } from "~/form";
import { RequestLineTemplate } from "~/graphql-operations";
import { Block } from "~/layout";
import { useUpdateRequestLineTemplate } from ".";

type Props = {
  template: Pick<RequestLineTemplate, "id" | "number" | "title">;
};

const EditLineTemplate: React.FC<Props> = ({ template }) => {
  const { id, title, number } = template;
  const defaultValues = { id, title, number };

  const update = useUpdateRequestLineTemplate();
  return (
    <Form
      key={id}
      defaultValues={defaultValues}
      onSubmit={update}
      horizontal
      autoSave
    >
      <Block>
        <Fieldset title="Request List Line">
          <Row>
            <Col lg="2">
              <TextField name="number" label="Nummer" />
            </Col>
            <Col>
              <TextField name="title" label="Tittel" />
            </Col>
          </Row>
        </Fieldset>
      </Block>
    </Form>
  );
};

export default EditLineTemplate;

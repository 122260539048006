import React from "react";
import { Badge } from "react-bootstrap";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import styled, { css } from "styled-components";
import { ImageAttachment } from "~/attachment";
import zoomIn from "~/layout/icon/svgs/zoom-in.svg";

interface Props {
  options?: unknown;
  images?: ImageAttachment[];
  group?: boolean;
}

const defaultOptions = {
  settings: {
    disablePanzoom: true,
  },
  buttons: {
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: true,
  },
};

const getOptions = (imageCount: number) => {
  return imageCount > 1
    ? defaultOptions
    : {
        ...defaultOptions,
        buttons: {
          ...defaultOptions.buttons,
          showNextButton: false,
          showPrevButton: false,
        },
        thumbnails: {
          ...defaultOptions.thumbnails,
          showThumbnails: false,
        },
      };
};

const StyledA = styled.a<{ $group: boolean }>`
  display: inline-flex;
  position: relative;

  &:before,
  &:after {
    display: block;
    opacity: 0;
    transition: 0.2s ease-in-out all;
    pointer-events: none;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    width: 32px;
    height: 32px;
    background-color: var(--smoke);
    mask-image: url(${zoomIn});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center bottom;
  }
  &:hover:before,
  &:hover:after {
    opacity: 1;
  }
  &:hover:after {
    transform: translate(-50%, -50%) scale(1);
  }

  ${({ $group }) =>
    $group &&
    css`
      &:nth-child(n + 2) {
        display: none;
      }
    `}
`;

const StyledGridWrap = styled.div`
  margin: 0 -0.5rem;

  ${StyledA} {
    padding: 0.5rem;
  }
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  right: -0.25rem;
  bottom: -0.25rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
`;

const ImageLightbox: React.FC<Props> = ({
  children,
  images,
  options: propOptions,
  group = false,
}) => {
  const imageCount = images?.length || 0;
  const options = propOptions || getOptions(imageCount);

  const GridWrap: React.FC = ({ children }) => {
    return group ? (
      <>{children}</>
    ) : (
      <StyledGridWrap>{children}</StyledGridWrap>
    );
  };

  return (
    <SimpleReactLightbox>
      {!images && <SRLWrapper options={options}>{children}</SRLWrapper>}
      {images && (
        <SRLWrapper options={options}>
          <GridWrap>
            {images.map(({ variants: { small, small2x, large } }, index) => (
              <StyledA
                href={large}
                data-attribute="SRL"
                key={small}
                $group={group}
              >
                <img src={small} srcSet={`${small2x} 2x`} />
                {group && index == 0 && imageCount - 1 > 0 && (
                  <StyledBadge>+ {imageCount - 1}</StyledBadge>
                )}
              </StyledA>
            ))}
          </GridWrap>
        </SRLWrapper>
      )}
    </SimpleReactLightbox>
  );
};

export default ImageLightbox;

import { default as React, useState } from "react";
import { Modal as BSModal, ModalProps as BSModalProps } from "react-bootstrap";
import styled from "styled-components";
import { useMounted } from "~/util/helpers";

interface Props extends BSModalProps {
  title?: string;
  footer?: React.ReactNode;
}

const StyledModal = styled(BSModal)`
  .modal-body > p:last-child {
    margin-bottom: 0;
  }
`;

export const useModal = () => {
  const isMounted = useMounted();
  const [active, setShow] = useState(false);

  const close = () => {
    isMounted() && setShow(false);
  };
  const show = () => setShow(true);

  return { active, show, close };
};

const Modal: React.FC<Props> = ({
  active,
  children,
  footer,
  onHide,
  title,
  ...rest
}) => {
  return (
    <StyledModal show={active} onHide={onHide} {...rest}>
      <BSModal.Header closeButton>
        {title && <BSModal.Title>{title}</BSModal.Title>}
      </BSModal.Header>
      <BSModal.Body>{children}</BSModal.Body>

      {footer && <BSModal.Footer>{footer}</BSModal.Footer>}
    </StyledModal>
  );
};

export default Modal;

import React, { ComponentProps } from "react";
import styled from "styled-components";
import { ConstructionLoanPeriod } from "~/graphql-operations";
import { Date } from "~/util";

interface Props {
  period: Pick<
    ConstructionLoanPeriod,
    "name" | "published" | "publishedAt" | "startedAt"
  >;
}

// export const periodTitle = ({
//   name,
//   published,
//   publishedAt,
//   startedAt,
// }: Pick<
//   ConstructionLoanPeriod,
//   "name" | "published" | "publishedAt" | "startedAt"
// >) => {
//   const date = published ? publishedAt : startedAt;
//   const formattedDate = formatDate(date, { format: "L" }) || "";
//   return name ? `${formattedDate} - ${name}` : formattedDate;
// };

const StartingDate = styled(Date)<ComponentProps<typeof Date>>`
  opacity: 0.5;
`;

const PeriodTitle = ({
  period: { name, published, publishedAt, startedAt },
}: Props) => {
  return (
    <>
      {published ? (
        publishedAt ? (
          <Date date={publishedAt} format="L" />
        ) : (
          "???"
        )
      ) : (
        <StartingDate date={startedAt} format="L" />
      )}
      {" - "}
      {name}
    </>
  );
};

export default PeriodTitle;

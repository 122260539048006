import { upperCase } from "lodash";
import { OptionsType } from "react-select";
import { Option } from "~/form/SelectField";
import { Currency } from "./constants";

export const currencyLabel = (currency: Currency) => upperCase(currency);

export type FormatCurrencyOptions =
  | {
      decimals?: number | boolean;
      maxDecimals?: undefined;
      minDecimals?: undefined;
    }
  | {
      decimals?: undefined;
      maxDecimals?: number;
      minDecimals?: number;
    };

type formatCurrency = (
  amount: number,
  options?: FormatCurrencyOptions
) => string;

const getDecimalNumber = (decimals: FormatCurrencyOptions["decimals"]) =>
  decimals === true ? 2 : decimals === false ? 0 : decimals;

/**
 * @param decimals if not given will use number type to determine decimals, .00 will be removed
 * @returns String with formatted number with nb-NO locale
 */
export const formatCurrency: formatCurrency = (
  amount,
  { decimals, maxDecimals, minDecimals } = { maxDecimals: 2 }
) => {
  const decimalNumber = getDecimalNumber(decimals);

  return amount.toLocaleString(
    "nb-NO",
    decimalNumber !== undefined
      ? {
          maximumFractionDigits: decimalNumber,
          minimumFractionDigits: decimalNumber,
        }
      : {
          maximumFractionDigits: maxDecimals,
          minimumFractionDigits: minDecimals,
        }
  );
};
export const toOption = (currency: Currency): Option<Currency> => ({
  label: currencyLabel(currency),
  value: currency,
});

export const toOptions = (
  currencies: Currency[]
): OptionsType<Option<Currency>> =>
  currencies.map((currency) => toOption(currency));

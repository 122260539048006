import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { NodeUpdateState } from "~/node";
import { State } from "~/node/NodeUpdateState";
import { useUpdateEffect } from "~/util";

interface Props {
  className?: string;
}

const FormUpdateStateWrap: React.FC<Props> = ({ className, ...rest }) => (
  <div className={className}>
    <FormUpdateState {...rest} />
  </div>
);

const FormUpdateState: React.FC = ({ ...rest }) => {
  const [updateState, setUpdateState] = useState<State>("initialized");

  const {
    formState: { isSubmitting, isSubmitSuccessful },
  } = useFormContext();

  useUpdateEffect(() => {
    if (isSubmitting && updateState !== "saving") setUpdateState("saving");
    if (isSubmitSuccessful && updateState !== "saved") setUpdateState("saved");
  }, [isSubmitting, isSubmitSuccessful]);

  return <NodeUpdateState state={updateState} {...rest} />;
};

export default FormUpdateStateWrap;

import React from "react";
import { SelectComponentsProps } from "react-select/src/Select";
import { SelectField } from "~/form";
import { Option } from "~/form/SelectField";
import { User } from "~/graphql-operations";
import { RoleBadge } from ".";
import { toOptions } from "./helpers";

interface Props extends SelectComponentsProps {
  name: string;
  label?: string;
  users: User[];
  role?: boolean;
}

const UserScopeSelectField: React.FC<Props> = ({
  name,
  label,
  users,
  role,
  ...rest
}) => {
  const formatOptionLabel = role
    ? ({ label, data: { role } }: Required<Option<User>>) => {
        return (
          <>
            {label} <RoleBadge role={role} />
          </>
        );
      }
    : undefined;

  return (
    <SelectField
      name={name}
      label={label}
      options={toOptions(users)}
      formatOptionLabel={formatOptionLabel}
      {...rest}
    />
  );
};

export default UserScopeSelectField;

import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  Membership,
  MembershipRelation,
  PermissionEnum,
  UserRole,
  useUpdateMembershipRelationMutation,
} from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import { ReportLine } from "~/report";
import EditPermissions from "~/report/member/EditPermissions";
import { deleteMembership, updateMembership } from "./actions";
import { relationLabel } from "./helpers";

interface AffiliateLineProps {
  membership: Membership;
  className?: string;
}

const StyledLabel = styled.label`
  margin-bottom: 0.5rem !important;
  font-weight: bold;
`;

const affiliateRelations = [
  MembershipRelation.Client,
  MembershipRelation.Associate,
  MembershipRelation.Collaborator,
];

const AffiliateLine: React.FC<AffiliateLineProps> = ({
  membership: {
    id,
    userId,
    relation,
    permissions,
    user: { role },
    report,
    report: { lockedAt, client },
  },
  className = "",
}) => {
  const updateMutation = updateMembership();
  const deleteMutation = deleteMembership();
  const [updateRelation] = useUpdateMembershipRelationMutation();

  const onRelationChange = (relation: MembershipRelation) =>
    updateRelation({
      variables: {
        input: {
          id,
          relation,
        },
      },
    });

  const onPermissionsChange = (permissions: Membership["permissions"]) =>
    updateMutation({
      id,
      permissions,
    });

  const onDelete = () => deleteMutation(id);

  return (
    <Row id={`membership-${id}`} className={`align-items-center ${className}`}>
      <Col>
        <ReportLine report={report} />
      </Col>
      <Col lg="auto">
        <StyledLabel>Tilgangstype</StyledLabel>
        <div className="field-inline">
          {affiliateRelations.map((possibleRelation) => (
            <label key={possibleRelation} style={{ marginRight: "0.5rem" }}>
              <input
                name={`relation-${id}`}
                type="radio"
                value={possibleRelation}
                checked={relation === possibleRelation}
                disabled={
                  !!(
                    lockedAt ||
                    (possibleRelation === MembershipRelation.Client &&
                      client?.userId &&
                      client?.userId !== userId)
                  )
                }
                onChange={() => onRelationChange(possibleRelation)}
              />{" "}
              {relationLabel(possibleRelation)}
            </label>
          ))}
        </div>
      </Col>
      <Col lg="auto">
        <StyledLabel>Tillatelser</StyledLabel>
        <EditPermissions
          permissions={permissions}
          disabled={
            lockedAt
              ? role === UserRole.Client
                ? [PermissionEnum.View, PermissionEnum.Edit]
                : [PermissionEnum.Edit]
              : role === UserRole.Client
              ? [PermissionEnum.View]
              : []
          }
          onChange={onPermissionsChange}
        />
      </Col>
      <Col lg="auto">
        <DeleteButton
          iconOnly
          onClick={onDelete}
          disabled={!!lockedAt && relation !== MembershipRelation.Collaborator}
        />
      </Col>
    </Row>
  );
};

export default AffiliateLine;

import { useQuery } from "@apollo/client";
import React from "react";
import { BlockList, BlockListItem } from "~/layout/BlockList";
import { NodeListEmpty } from "~/node";
import {
  BuildingPart,
  BUILDING_PART_BUDGET_LINES_QUERY,
} from "../building-part";
import EditBudgetLine from "./EditBudgetLine";

interface Props {
  buildingPart: BuildingPart;
}

type QueryResult = {
  housingMaintenanceBuildingPart: BuildingPart;
};

const EditBudgetLineList: React.FC<Props> = ({
  buildingPart,
  buildingPart: { id },
}) => {
  const { loading, error, data } = useQuery<QueryResult>(
    BUILDING_PART_BUDGET_LINES_QUERY,
    {
      variables: {
        id,
      },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const { housingMaintenanceBuildingPart: { budgetLines = [] } = {} } =
    data || {};

  if (!budgetLines?.length)
    return <NodeListEmpty>Ingen vedlikeholdsbehov</NodeListEmpty>;

  return (
    <BlockList sub>
      {budgetLines.map((budgetLine) => (
        <BlockListItem key={budgetLine.id}>
          <EditBudgetLine buildingPart={buildingPart} budgetLine={budgetLine} />
        </BlockListItem>
      ))}
    </BlockList>
  );
};

export default EditBudgetLineList;

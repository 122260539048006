import styled from "styled-components";

const THead = styled.thead`
  font-family: var(--font-circular);
  color: var(--dark-gray);
  border-bottom: 3px solid var(--dark-gray);

  th {
    font-weight: bold;
    /* vertical-align: middle !important; */
  }
`;

export default THead;

import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from "./Shortcode";
import { WordLoader } from "~/layout/Loading";

const REPORT_FALLBACK_AREA_QUERY = gql`
  query EiendomByggear($slug: ID!) {
    report(slug: $slug) {
      id
      fallbackArea
    }
  }
`;

const EiendomTomteareal: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery(REPORT_FALLBACK_AREA_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  const { fallbackArea } = data?.report || {};

  return <>{loading ? <WordLoader /> : fallbackArea && `${fallbackArea}`}</>;
};

export default EiendomTomteareal;

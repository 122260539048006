import { useMutation } from "@apollo/client";
import { RequestSection, REQUEST_SECTION_UPDATE_MUTATION } from ".";

export const updateMutation = (id: string) => {
  const [update] = useMutation(REQUEST_SECTION_UPDATE_MUTATION);

  return (input: Partial<RequestSection>) => {
    return update({
      variables: {
        input: {
          ...input,
          id,
        },
      },
    });
  };
};

import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styled, { css } from "styled-components";
import Icon, { StyledIcon } from "~/layout/icon";
import { Icons } from "~/layout/icon/constants";
import { Color } from "../constants";

export const StyledIconButton = styled(Button)`
  display: inline-flex;
  align-items: center;

  ${({ $rounded }) =>
    $rounded &&
    css`
      padding: 0.25rem;
      border-radius: 50%;
    `}

  ${({ size, $iconOnly, variant }) =>
    size === "sm" &&
    variant === "transparent" &&
    $iconOnly &&
    css`
      padding: 0 !important;
    `}

  ${StyledIcon} {
    margin-right: ${({ $iconOnly }) => ($iconOnly ? "0" : "0.5rem")};
  }
`;

export interface Props extends ButtonProps {
  icon: Icons;
  color?: Color;
  spin?: boolean;
  rounded?: boolean;
}

const getIconColor = (variant: ButtonProps["variant"]): Color => {
  switch (variant) {
    case "danger":
    case "dark":
    case "info":
      return "light-gray";
    default:
      return "dark-gray";
  }
};

const IconButton: React.FC<Props> = ({
  icon,
  variant = "light",
  spin,
  color,
  disabled = false,
  onClick,
  children,
  rounded,
  ...rest
}) => {
  return (
    <StyledIconButton
      type="button"
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      $iconOnly={!children}
      $rounded={rounded}
      {...rest}
    >
      <Icon
        icon={icon}
        color={color ? color : getIconColor(variant)}
        spin={spin}
      />
      {children}
    </StyledIconButton>
  );
};

export default IconButton;

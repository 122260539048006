import React from "react";
import { Col, Row } from "react-bootstrap";
import { BlockListHeader } from "../layout/BlockList";
import { ButtonGroup, IconButton } from "../layout/Button";

interface Props {
  state: "expandable" | "sortable";
  onSortable: () => void;
  onExpandable: () => void;
}

const BuildingListHeader: React.FC<Props> = ({
  state,
  onExpandable,
  onSortable,
}) => {
  const getButtonVariant = (buttonState: Props["state"]) =>
    state === buttonState ? "dark" : "light";

  return (
    <BlockListHeader>
      <Row className="align-items-center">
        <Col lg="4">Bygning</Col>
        <Col lg="4">Type</Col>
        <Col lg="2" className="text-right">
          BTA (M<sup>2</sup>)
        </Col>
        <Col>
          <ButtonGroup className="justify-content-end">
            <IconButton
              icon="edit"
              variant={getButtonVariant("expandable")}
              onClick={onExpandable}
              size="sm"
            />
            <IconButton
              icon="move"
              variant={getButtonVariant("sortable")}
              onClick={onSortable}
              size="sm"
            />
          </ButtonGroup>
        </Col>
      </Row>
    </BlockListHeader>
  );
};

export default BuildingListHeader;

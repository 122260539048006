import { Reference, useMutation } from "@apollo/client";
import {
  BUDGET_LINE_CREATE_MUTATION,
  BUDGET_LINE_DELETE_MUTATION,
  BUDGET_LINE_UPDATE_MUTATION,
} from ".";
import { BuildingPart } from "../building-part";
import { budgetLineFragment } from "../fragments";

export const createBudgetLineMutation = (buildingPart: BuildingPart) => {
  const [create] = useMutation(BUDGET_LINE_CREATE_MUTATION);

  return (input: Partial<BuildingPart>) =>
    create({
      variables: {
        input: {
          ...input,
          buildingPartId: buildingPart.id,
        },
      },
      update: (cache, { data: { housingMaintenanceBudgetLineCreate } }) => {
        const cacheId = cache.identify(buildingPart);
        if (!cacheId) console.log("No cache found for:", buildingPart);

        const newRef = cache.writeFragment({
          data: housingMaintenanceBudgetLineCreate,
          fragment: budgetLineFragment,
          fragmentName: "HousingMaintenanceBudgetLineFields",
        });
        cache.modify({
          id: cacheId,
          fields: {
            budgetLines: (refs: Reference[]) => [...refs, newRef],
          },
        });
      },
    });
};

export const updateBudgetLineMutation = (id: string) => {
  const [update] = useMutation(BUDGET_LINE_UPDATE_MUTATION);

  return (input: Partial<BuildingPart>) => {
    return update({
      variables: {
        input: {
          ...input,
          id,
        },
      },
    });
  };
};

export const deleteBudgetLineMutation = (id: string) => {
  const [mutation] = useMutation(BUDGET_LINE_DELETE_MUTATION);

  return () =>
    mutation({
      variables: {
        input: {
          id,
        },
      },
      update: (
        cache,
        {
          data: {
            housingMaintenanceBudgetLineDelete: { buildingPart },
          },
        }
      ) => {
        cache.modify({
          id: cache.identify(buildingPart),
          fields: {
            budgetLines: (refs, { readField }) =>
              refs.filter((ref: Reference) => id !== readField("id", ref)),
          },
        });
      },
    });
};

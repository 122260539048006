import { MaintenancePeriodEnum } from "~/graphql-operations";
import {
  MaintenanceInterval,
  maintenancePeriodLabel,
} from "~/report/maintenance";

export const intervalIndexLabel = (index: number) => {
  const interval = Object.values(MaintenanceInterval)[index];
  return maintenancePeriodLabel(interval as MaintenancePeriodEnum);
};

import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  AttachmentField,
  CheckboxField,
  Form,
  FormUpdateState,
  NumberField,
  RedactorField,
  TextField,
} from "~/form";
import { DeleteButton } from "~/layout";
import { BlockListItem, BlockListItemHeader } from "~/layout/BlockList";
import { StatusBadge } from "~/sections/cost-section/line";
import { Session, useSession } from "~/session";
import { deleteMutation, RequestCostLine, toFormData, updateMutation } from ".";
import { RequestCostBlock } from "../cost-block";

interface Props {
  requestCostBlock?: RequestCostBlock;
  requestCostLine: RequestCostLine;
}

const EditRequestCostLine: React.FC<Props> = ({
  requestCostBlock,
  requestCostLine,
}) => {
  const { id, title, number, status, files } = requestCostLine;
  const { role } = useSession() as Session;

  const onSubmit = updateMutation(id);
  const handleDelete =
    requestCostBlock && ["admin", "editor"].includes(role)
      ? deleteMutation(requestCostBlock)
      : null;

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={toFormData(requestCostLine)}
      autoSave
    >
      <BlockListItem id={`request-cost-line-${id}`}>
        <BlockListItemHeader>
          <Row className="align-items-center">
            <Col lg="auto">
              <StatusBadge status={status} className="mr-2" /> #{number}
            </Col>
            <Col>{title}</Col>
            <Col lg="auto" className="d-flex justify-content-end">
              <FormUpdateState />
              &nbsp;
              {handleDelete && (
                <DeleteButton iconOnly onClick={() => handleDelete(id)} />
              )}
            </Col>
          </Row>
        </BlockListItemHeader>

        <Row>
          <Col lg="6">
            <RedactorField name="description" label="Kommentar" />
          </Col>
          <Col lg="6">
            <Row>
              <Col lg="8">
                <TextField name="reference" label="Referanse" />
                <CheckboxField name="received" label="Foreligger" />
              </Col>
              <Col lg="4">
                <NumberField name="cost" required />
              </Col>
            </Row>
            <AttachmentField
              attachments={files}
              name="files"
              label="Files"
              multiple
            />
          </Col>
        </Row>
      </BlockListItem>
    </Form>
  );
};

export default EditRequestCostLine;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { useRegeneratePdfMutation } from "~/graphql-operations";
import Icon from "~/layout/icon";
import { client } from "~/setup";

interface Props {
  report: {
    id: string;
  };
}

const RegeneratePdf: React.FC<Props> = ({ report: { id } }) => {
  const [regenerate] = useRegeneratePdfMutation();
  const onClick = async () => {
    const { data } = await regenerate({
      variables: { input: { id } },
    });
    if (data?.regeneratePdf?.status !== "ok") {
      alert("Error regenerating PDF");
    }
  };

  return (
    <Icon
      icon="refresh"
      onClick={onClick}
      // spin={loading}
      style={{ cursor: "pointer" }}
    />
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <RegeneratePdf {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import gql from "graphql-tag";
import { memberFragment } from "~/report/member";

export const CONSTRUCTION_LOAN_AUDIENCE_QUERY = gql`
  query report($slug: ID!) {
    report(slug: $slug) {
      id
      companyId
      ... on ConstructionLoan {
        collaborators {
          ...MemberFields
        }
      }
    }
  }
  ${memberFragment}
`;

import { GroupType } from "react-select";
import { toOption } from "~/form";
import { Option } from "~/form/SelectField";
import {
  InvoiceEntryCategorization,
  InvoiceEntryCategory,
  InvoiceEntryCategoryGroup,
} from "~/graphql-operations";
import { ExtendedCategorization, VirtualGroup } from ".";

export const isCategory = (
  x: ExtendedCategorization
): x is InvoiceEntryCategory => x.__typename === "InvoiceEntryCategory";

export const isGroup = (
  x: ExtendedCategorization
): x is InvoiceEntryCategoryGroup =>
  x.__typename === "InvoiceEntryCategoryGroup";

export const isVirtualGroup = (x: ExtendedCategorization): x is VirtualGroup =>
  x.__typename === "VirtualGroup";

export const toGroupedOptions = (
  categorizations: InvoiceEntryCategorization[]
  // noDefault = false
): ReadonlyArray<GroupType<Option> | Option> => {
  // ): GroupedOptionsType<Option> => {
  return categorizations.reduce((acc, categorization) => {
    if (isGroup(categorization)) {
      if (!categorization.categories.length) return acc;
      return [
        ...acc,
        {
          label: categorization.name,
          options: categorization.categories.map((category) =>
            toOption(category)
          ),
          data: categorization,
        },
      ];
    }
    // if (noDefault && categorization.default) return acc;
    return [
      ...acc,
      {
        label: categorization.name,
        value: categorization.id,
        data: categorization,
      },
    ];
  }, []);
};

import React from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import { Report } from ".";

interface Props {
  report: Report;
  link?: boolean;
}

const Wrap = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledBadge = styled(Badge)`
  margin: 0 0 0 0.5rem;
`;

const CompanyLabel = styled.span`
  margin: 0 0 0 0.5rem;
  font-family: var(--font-moon);
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--titanium);
`;

const reportLink = ({ lockedAt, urls: { show, edit } }: Report) =>
  lockedAt ? show : edit;

const ReportLine: React.FC<Props> = ({
  report,
  report: {
    name,
    lockedAt,
    company: { name: companyName },
  },
  link = false,
  children,
  ...rest
}) => {
  return (
    <Wrap {...rest}>
      {link ? <a href={reportLink(report)}>{name}</a> : name}
      {lockedAt && <StyledBadge>Publisert</StyledBadge>}
      <CompanyLabel>{companyName}</CompanyLabel>
      {children}
    </Wrap>
  );
};

export default ReportLine;

import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from "./Shortcode";
import { WordLoader } from "~/layout/Loading";
import { formatDate } from "~/util";

const REPORT_INSPECTION_DATE_QUERY = gql`
  query InspeksjonBefaringsdato($slug: ID!) {
    report(slug: $slug) {
      id
      inspectionDate
    }
  }
`;

const InspeksjonBefaringsdato: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(REPORT_INSPECTION_DATE_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  const { inspectionDate } = data?.report || {};

  return (
    <>
      {loading ? (
        <WordLoader />
      ) : (
        inspectionDate && formatDate(`${inspectionDate}`, { format: "L" })
      )}
    </>
  );
};

export default InspeksjonBefaringsdato;

import { gql, useMutation } from "@apollo/client";
import { lowerCase } from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { Option } from "~/form/SelectField";
import { MembershipRelation, Report } from "~/graphql-operations";
import { Button } from "~/layout";
import { Permission } from "~/Membership";
import { filteredLoadOptionsRetFunc, User } from "~/user";
import { memberFragment } from ".";
import ReportMemberPermissions from "./EditPermissions";

export interface Props {
  reportId: Report["id"];
  relation: MembershipRelation;
  loadOptions: filteredLoadOptionsRetFunc;
  defaultPermissions?: Permission[];
  disabledPermissions?: Permission[];
  onUserChange?: (user: User) => void;
}

const mutation = (relation: MembershipRelation) => gql`
  mutation ReportAddMember($input: ReportAddMemberInput!) {
    reportAddMember(input: $input) {
      id
      ${lowerCase(relation)}s {
        ...MemberFields
      }
    }
  }
  ${memberFragment}
`;

const NewReportMember: React.FC<Props> = ({
  reportId,
  relation,
  loadOptions,
  defaultPermissions,
  disabledPermissions,
  onUserChange,
}) => {
  const [addMember] = useMutation(mutation(relation));
  const [selectedOption, setSelectedOption] = useState<Option<User> | null>(
    null
  );
  const [permissions, setPermissions] = useState<Permission[]>();

  useEffect(() => setPermissions(defaultPermissions), [defaultPermissions]);

  const handleUserChange = (option: Option<User>) => {
    setSelectedOption(option);
    if (onUserChange) onUserChange(option.data);
  };

  const handleAdd = () =>
    addMember({
      variables: {
        input: {
          reportId,
          userId: selectedOption?.value,
          relation,
          permissions,
        },
      },
      update: () => setSelectedOption(null),
      // update(cache, { data: { reportMembershipCreate } }) {
      //   cache.modify({
      //     fields: {
      //       reportUsers(existingMemberships = []) {
      //         const newMembershipRef = cache.writeFragment({
      //           data: reportMembershipCreate,
      //           fragment: gql`
      //             fragment NewAffiliate on Memberships {
      //               id
      //               relation
      //               permissions
      //               user {
      //                 id
      //                 email
      //                 name
      //               }
      //             }
      //           `,
      //         });
      //         return [...existingMemberships, newMembershipRef];
      //       },
      //     },
      //   });
      // },
    });

  return (
    <Row className="align-items-end">
      <Col lg="7">
        <div className="field">
          <label>Bruker</label>
          <AsyncSelect
            loadOptions={loadOptions}
            onChange={handleUserChange}
            value={selectedOption}
          />
        </div>
      </Col>
      <Col lg="3">
        {selectedOption && (
          <div className="field">
            <label>Permissions</label>
            <ReportMemberPermissions
              permissions={permissions}
              disabled={disabledPermissions}
              onChange={setPermissions}
            />
          </div>
        )}
      </Col>
      <Col lg="2">
        <Button onClick={handleAdd} disabled={!selectedOption || !permissions}>
          Add
        </Button>
      </Col>
    </Row>
  );
};

export default NewReportMember;

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React from "react";
import { ImageAttachment } from "~/attachment";

const ImageCarousel: React.FC<{ images: ImageAttachment[] }> = ({ images }) => {
  return (
    <Splide>
      {images.map(({ signedId, variants: { large, large2x } }) => (
        <SplideSlide key={signedId} className="text-center">
          <img
            src={large}
            srcSet={`${large} 1x, ${large2x} 2x`}
            alt="Image 1"
          />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default ImageCarousel;

import { pick } from "lodash";
import React from "react";
import { Col } from "react-bootstrap";
import { FileVersionsToggle, useDeleteFileVersion } from "~/file-version";
import { AttachmentField, DateField, Form, Row, TextField } from "~/form";
import { attachmentInput } from "~/form/attachment";
import {
  ConstructionLoan,
  ContractorInvoice,
  InvoiceCategoryTypeEnum,
  UpdateContractorInvoiceInput,
  useUpdateContractorInvoiceMutation,
} from "~/graphql-operations";
import { Block } from "~/layout";
import { EditContractorInvoiceDepositFields, InvoiceAmountFields } from ".";
import FakturaFields from "./FakturaFields";
import { ContractorSelectField } from "./contractor";
import { EditEntryList } from "./entry";
import { isCategory } from "./entry/categorization";

interface Props {
  invoice: ContractorInvoice;
  report: Pick<
    ConstructionLoan,
    "balance" | "contractors" | "currencies" | "vatIncluded"
  >;
}

const EditContractorInvoice: React.FC<Props> = ({
  invoice,
  invoice: { id, accordingToPlan, contractorId, file, hasEntries },
  report,
  report: { balance, contractors, currencies, vatIncluded },
}) => {
  const [updateMutation] = useUpdateContractorInvoiceMutation();
  const deleteFile = useDeleteFileVersion();

  const onSubmit = async (input: UpdateContractorInvoiceInput) => {
    return await updateMutation({ variables: { input } });
  };

  const defaultValues = {
    ...pick(invoice, [
      "id",
      "amount",
      "contractorId",
      "currency",
      "description",
      "dueDate",
      "multi",
      "number",
      "receivedDate",
      "sender",
    ]),
    deposit: invoice.deposit.manual,
    file: file && file && attachmentInput(file),
  };

  const invoiceEntryCategorizations = (contractorId
    ? contractors.find((contractor) => contractor.id === contractorId)
        ?.invoiceEntryCategorizations || []
    : []
  ).filter((categorization) => {
    if (!isCategory(categorization)) return true;

    return accordingToPlan
      ? categorization.type !== InvoiceCategoryTypeEnum.Additional
      : categorization.type === InvoiceCategoryTypeEnum.Additional;
  });

  return (
    <>
      <Form
        autoSave
        horizontal
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        id={`contractor-invoice-${id}-form`}
      >
        <Row className="align-items-end">
          <Col>
            <ContractorSelectField
              name="contractorId"
              contractors={contractors}
              disabled={hasEntries}
            />
          </Col>
          <FakturaFields />
          <Col lg="2">
            <DateField name="dueDate" label="Faktura dato" />
          </Col>
          <Col lg="auto">
            <InvoiceAmountFields
              currencies={currencies}
              vatIncluded={vatIncluded}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField name="description" label="Faktura beskrivelse" />
          </Col>
          <Col lg="auto">
            <DateField
              name="receivedDate"
              label="Mottat BER"
              required
              notClearable
            />
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col>
            <AttachmentField
              name="file"
              label="Fakturafil"
              attachments={file}
              noDelete
            />
            {file && <FileVersionsToggle node={{ id }} onDelete={deleteFile} />}
          </Col>
        </Row>
        {accordingToPlan && (
          <EditContractorInvoiceDepositFields
            invoice={invoice}
            report={report}
          />
        )}
      </Form>
      <Block>
        <EditEntryList
          balance={balance}
          categorizations={invoiceEntryCategorizations}
          invoice={invoice}
          vatIncluded={vatIncluded}
        />
      </Block>
    </>
  );
};

export default EditContractorInvoice;

import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import { CompanySelectField } from "~/Company";
import {
  AddFormButton,
  Fieldset,
  Form,
  FormProps,
  Row,
  TextField,
} from "~/form";
import { Report } from "~/graphql-operations";
import { Invoice } from "~/invoice";

export type NewReport<T> = T & Pick<Report, "name" | "number" | "companyId">;
type Props<T> = FormProps<NewReport<T>>;

export interface NewInvoice extends Omit<Invoice, "file"> {
  file: FileList;
}

const NewReportForm = <T extends unknown>({
  formMethods,
  onSubmit,
  children,
  ...rest
}: Props<T>) => {
  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} {...rest}>
      <Fieldset title="Generell informasjon">
        <Row>
          <Col lg="5">
            <TextField name="name" label="Prosjektnavn" required />
          </Col>
          <Col lg="3">
            <TextField name="number" label="Prosjektnummer" required />
          </Col>
          <Col lg="4">
            <CompanySelectField name="companyId" label="Kunde" required />
          </Col>
        </Row>
      </Fieldset>
      {children}
      <Row>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton style={{ marginTop: "1.5rem" }}>
              Create report
            </AddFormButton>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default NewReportForm;

import { Reference, useMutation } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Building } from "~/Building";
import { DeleteButton } from "~/layout";
import {
  BlockListItemData,
  BlockListItemExpandable,
  BlockListItemTitle,
} from "~/layout/BlockList";
import { Currency } from "~/util";
import { CostSection } from "../constants";
import { EditCostLineList, StatusBadge } from "../line";
import { EditBuildingInfoList } from "./building-info";
import { CostBlock, COST_BLOCK_DELETE_MUTATION } from "./constants";

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  costSection: CostSection;
  costBlock: CostBlock;
  reportBuildings: Building[];
}

const EditCostBlockListItem: React.FC<Props> = ({
  reportBuildings,
  costSection,
  costBlock,
  costBlock: {
    id,
    number,
    title,
    buildingsCount,
    costLinesCount,
    cost,
    status,
  },
  ...rest
}) => {
  const [deleteCostBlock] = useMutation(COST_BLOCK_DELETE_MUTATION);
  // const { id, number, title } = costBlock;
  const onDelete = () => {
    return deleteCostBlock({
      variables: {
        input: {
          id,
        },
      },
      update(cache) {
        cache.modify({
          id: cache.identify(costSection),
          fields: {
            costBlocks: (existingCostBlocks, { readField }) =>
              existingCostBlocks.filter(
                (costBlockRef: Reference) =>
                  id !== readField("id", costBlockRef)
              ),
          },
        });
      },
    });
  };
  return (
    <BlockListItemExpandable
      render={({ expanded }) => (
        <Row style={{ alignItems: "center" }}>
          <Col lg="5">
            <BlockListItemTitle>
              <StatusBadge status={status} className="mr-2" /> #{number} -{" "}
              {title}
            </BlockListItemTitle>
          </Col>
          <Col lg="2">
            <BlockListItemData>{buildingsCount} Bygninger</BlockListItemData>
          </Col>
          <Col lg="2">
            <BlockListItemData>{costLinesCount} Elementer</BlockListItemData>
          </Col>
          <Col lg="2" className="_tar">
            <BlockListItemData>
              <Currency>{cost}</Currency>
            </BlockListItemData>
          </Col>
          <Col lg="1" className="_tar">
            <DeleteButton
              onClick={onDelete}
              iconOnly
              inverse={expanded}
              style={{ padding: 0 }}
            />
          </Col>
        </Row>
      )}
      {...rest}
    >
      <EditBuildingInfoList
        reportBuildings={reportBuildings}
        costBlock={costBlock}
      />
      {buildingsCount > 0 && <EditCostLineList costBlock={costBlock} />}
    </BlockListItemExpandable>
  );
};

export default EditCostBlockListItem;

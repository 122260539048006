import React from "react";
import { Report } from "~/report";
import { shortcodeMap } from "./constants";

type ShortcodeMapKey = keyof typeof shortcodeMap;

export type ShortcodeProps = {
  code: ShortcodeMapKey | string;
  report: Pick<Report, "id" | "slug" | "type">;
};

const Shortcode: React.FC<ShortcodeProps> = ({ code, report }) => {
  if (!(code in shortcodeMap))
    return <span style={{ background: "red" }}>UNKNOWN SHORTCODE</span>;

  const Component = shortcodeMap[code as ShortcodeMapKey].component;

  switch (code) {
    default:
      return <Component code={code} report={report} />;
  }
};

export default Shortcode;

import { useMutation } from "@apollo/client";
import React from "react";
import { BlockListSortable } from "~/layout/BlockList";
import { BuildingPart, BUILDING_PARTS_SORT_MUTATION } from ".";
import BuildingPartListItemHeader from "./BuildingPartListItemHeader";
import { EditBuildingPartProps } from "./EditBuildingPart";

interface Props {
  buildingParts: BuildingPart[];
  options: EditBuildingPartProps["options"];
  rules: EditBuildingPartProps["rules"];
  budget?: boolean;
}

// dragging causes rerenders
const MemoizedHeader = React.memo(BuildingPartListItemHeader);

const BuildingPartListSortable: React.FC<Props> = ({
  buildingParts,
  rules,
  options,
  budget,
}) => {
  const [sortBuildingParts] = useMutation(BUILDING_PARTS_SORT_MUTATION);

  // make apollo caching easier
  const onSort = (ids: string[]) => {
    return sortBuildingParts({
      variables: {
        input: {
          ids,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        housingMaintenanceBuildingPartsSort: ids.map((id, index) => ({
          ...buildingParts.find((c) => c.id === id),
          order: index + 1,
        })),
      },
    });
  };

  return (
    <BlockListSortable items={buildingParts} onSort={onSort}>
      {(buildingPart: BuildingPart) => (
        <MemoizedHeader
          mode="sortable"
          buildingPart={buildingPart}
          rules={rules}
          options={options}
          budget={budget}
        />
      )}
    </BlockListSortable>
  );
};

export default BuildingPartListSortable;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { SaveFormButton } from "~/form";
import { Session, SessionProvider } from "~/session";
import { client } from "~/setup";
import { useCreateUser, User, UserForm } from ".";

const NewUser: React.FC = () => {
  const createUser = useCreateUser();

  const onSubmit = async (input: Partial<User>) => {
    const { data } = await createUser(input);
    const user = data?.createUser.user;

    if (user) window.location.href = `/users/${user.id}/edit`;
    else throw new Error("User not created");
  };

  return (
    <UserForm onSubmit={onSubmit}>
      <SaveFormButton />
    </UserForm>
  );
};

interface WrapProps {
  session: Session;
}

const ApolloWrap: React.FC<WrapProps> = ({ session }) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <NewUser />
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

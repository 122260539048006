import { ApolloProvider } from "@apollo/client";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { Fieldset, FileField, NumberField, Row } from "~/form";
import { uploadFile } from "~/form/attachment";
import {
  ConstructionLoanCreateInput,
  useConstructionLoanCreateMutation,
} from "~/graphql-operations";
import { client } from "~/setup";
import NewReportForm from "../../NewReportForm";
import { ExtraFields } from "./EditExtra";

const Wrap = styled.div`
  padding: 1.5rem;
  background-color: var(--smoke);
  border-radius: 4px;
`;

const CreateConstructionLoan: React.FC = () => {
  const [create] = useConstructionLoanCreateMutation();
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(
    undefined
  );

  // const onCreate = async (input: NewDocument) => {
  //   const { file, date } = input;
  //   const signedId = file.length
  //     ? await uploadFile(file[0], (progress) => setUploadProgress(progress))
  //     : undefined;
  //   // Array.from(files).forEach(file => uploadFile(file));

  //   await createDocument({
  //     ...input,
  //     reportId,
  //     file: signedId,
  //     date: parseDate(date),
  //   });

  //   setUploadProgress(undefined);
  // };

  const onSubmit = async (
    input: Omit<ConstructionLoanCreateInput, "initialAssessment"> & {
      initialAssessment: FileList;
    }
  ) => {
    const { initialAssessment: file } = input;
    const signedId = file?.length
      ? await uploadFile(file[0], setUploadProgress)
      : undefined;

    setUploadProgress(undefined);

    const { data, errors } = await create({
      variables: {
        input: {
          ...input,
          initialAssessment: signedId,
        },
      },
    });
    const report = data?.constructionLoanCreate?.report;

    if (!report || errors?.length)
      throw new Error(`Failed to create report. ${(errors || []).join("; ")}`);

    window.location.href = `/construction_loans/${report.slug}/edit`;
  };

  return (
    <Wrap>
      <h3 style={{ marginBottom: "1.5rem" }}>Ny Byggelån</h3>
      <NewReportForm<ConstructionLoanCreateInput>
        onSubmit={onSubmit}
        defaultValues={{ balance: 7.5, vatIncluded: true }}
        horizontal
      >
        <Fieldset>
          <FileField
            name="initialAssessment"
            label="Innledende vurdering"
            progress={uploadProgress}
          />
        </Fieldset>
        <ExtraFields compact />
        <Fieldset title="Lån">
          <Row>
            <Col lg="auto">
              <NumberField
                name="loanAmount"
                label="Lånebeløp"
                append="NOK"
                required
              />
            </Col>
            <Col lg="auto">
              <NumberField name="equity" label="Egenkapital" append="NOK" />
            </Col>
          </Row>
        </Fieldset>
      </NewReportForm>
    </Wrap>
  );
};

const ApolloWrap: React.FC = (props) => (
  <ApolloProvider client={client}>
    <CreateConstructionLoan {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

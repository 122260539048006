import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import Block from "~/layout/Block";
import { Report } from "~/report";
import ReportProvider from "~/report/ReportProvider";
import { Session, SessionProvider, useSession } from "~/session";
import { client } from "~/setup";
import { EditRequestSection } from ".";
import { REQUEST_SECTION_QUERY } from "./constants";
import { EditRequestCostBlockList, NewRequestCostBlock } from "./cost-block";
import { EditRequestLineList, NewRequestLine } from "./line";

interface Props {
  report: Report;
  requestSectionId: string;
}

const RequestSectionPage: React.FC<Omit<Props, "report">> = ({
  requestSectionId,
}) => {
  const { role } = useSession() as Session;
  const { loading, error, data } = useQuery(REQUEST_SECTION_QUERY, {
    variables: { id: requestSectionId },
  });

  if (loading) return "Loading...";
  if (error) return "Error :(";

  const { requestSection } = data;
  const generalEditor = ["admin", "editor"].includes(role);

  return (
    <>
      <EditRequestSection requestSection={requestSection} />
      <br />
      <br />
      <EditRequestLineList requestSection={requestSection} />
      {generalEditor && (
        <>
          <hr />
          <Block>
            <NewRequestLine requestSection={requestSection} />
          </Block>
          <div style={{ margin: "1rem 0" }}>&nbsp;</div>
          <EditRequestCostBlockList requestSection={requestSection} />
          <hr />
          <Block>
            <NewRequestCostBlock requestSection={requestSection} />
          </Block>
        </>
      )}
    </>
  );
};

interface WrapProps extends Props {
  session: Session;
}

const ApolloWrap: React.FC<WrapProps> = ({ session, report, ...rest }) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <ReportProvider report={{ ...report, id: report.id.toString() }}>
        <RequestSectionPage {...rest} />
      </ReportProvider>
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

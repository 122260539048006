import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { AddFormButton, Form, SelectField } from "~/form";
import { createMutation } from ".";
import { RequestCostBlock } from "../cost-block";

interface Props {
  requestCostBlock: RequestCostBlock;
}

const NewRequestCostLine: React.FC<Props> = ({
  requestCostBlock,
  requestCostBlock: { requestCostBlockTemplate },
}) => {
  const requestCostLineTemplateIdOptions = (
    requestCostBlockTemplate?.requestCostLineTemplates || []
  ).map(({ id, number, title }) => ({
    value: id,
    label: `#${number} - ${title}`,
  }));

  const onCreate = createMutation(requestCostBlock);

  if (!requestCostLineTemplateIdOptions.length)
    return <em>No request cost block template</em>;

  return (
    <Form onSubmit={onCreate} horizontal>
      <Row>
        <Col>
          <SelectField
            name="requestCostLineTemplateId"
            label="Element"
            options={requestCostLineTemplateIdOptions}
            required
          />
        </Col>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default NewRequestCostLine;

import React from "react";
import { Table } from "react-bootstrap";
import { DeleteButton, IconButton } from "~/layout";
import { TableDataLoader } from "~/layout/Loading";
import { Date } from "~/util";
import { User, userRoleLabel } from ".";

interface Props {
  loading: boolean;
  users: User[];
  deleteUser: (id: string) => Promise<unknown>;
}

const UserList: React.FC<Props> = ({ loading, users, deleteUser }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>E-post</th>
          <th>Navn</th>
          <th>Rolle</th>
          <th>Bruker siden</th>
          <th className="text-right">Valg</th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          {Array.from({ length: 10 }).map((_, index) => (
            <tr key={index}>
              <td>
                <TableDataLoader width={450} />
              </td>
              <td>
                <TableDataLoader width={150} />
              </td>
              <td>
                <TableDataLoader width={160} />
              </td>
              <td>
                <TableDataLoader width={50} />
              </td>
              <td>
                <TableDataLoader width={50} />
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody>
          {users.map(({ id, name, email, role, createdAt }) => {
            const editUrl = `/users/${id}/edit`;
            return (
              <tr key={id}>
                <td>
                  <a href={editUrl}>{email}</a>
                </td>
                <td>{name}</td>
                <td width="160">{userRoleLabel(role)}</td>
                <td>
                  <Date date={createdAt} format="L" />
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <IconButton
                    icon="edit"
                    onClick={() => (window.location.href = `/users/${id}/edit`)}
                    size="sm"
                    variant="link"
                  />
                  <DeleteButton onClick={() => deleteUser(id)} iconOnly />
                </td>
              </tr>
            );
          })}
        </tbody>
      )}
    </Table>
  );
};

export default UserList;

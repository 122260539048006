import { gql } from "@apollo/client";
import { UserRole, WorkingAreaEnum } from "~/graphql-operations";
import { userFullFragment } from "./fragments";

export type CV = {
  education: CVLine[] | null;
  experience: CVLine[] | null;
  description: string;
};

export type CVLine = {
  text: string;
  startYear: string;
  endYear: string;
  type: string;
  hidden: boolean;
};

export const userRoleOptions = [
  { value: UserRole.Admin, label: "Admin" },
  { value: UserRole.Editor, label: "BER rådgiver" },
  { value: UserRole.Consultant, label: "Ekstern konsulent" },
  { value: UserRole.Client, label: "Kunde Admin" },
  { value: UserRole.Collaborator, label: "Kunde" },
];

export type UserScope = "generalEditors";

export const workingAreaOptions = [
  { value: WorkingAreaEnum.Construction, label: "Bygg" },
  { value: WorkingAreaEnum.Electrical, label: "Elektro" },
  { value: WorkingAreaEnum.Plumbing, label: "VVS" },
  { value: WorkingAreaEnum.Fire, label: "Brann" },
];

export const AFFILIATES_QUERY = gql`
  query($query: String) {
    affiliates(query: $query) {
      id
      email
      name
      companyIds
      companyName
      role
    }
  }
`;

export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserFullFields
    }
  }
  ${userFullFragment}
`;

export const USER_CREATE_MUTATION = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      errors
      user {
        ...UserFullFields
      }
    }
  }
  ${userFullFragment}
`;

export const USER_EDIT_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors
      user {
        ...UserFullFields
      }
    }
  }
  ${userFullFragment}
`;

export const RESET_USER_PASSWORD_MUTATION = gql`
  mutation resetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      errors
      user {
        id
      }
    }
  }
`;

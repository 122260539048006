import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from ".";
import { WordLoader } from "~/layout/Loading";

const REPORT_CLIENT_NAME_QUERY = gql`
  query ReportOppdragsgiverNavnQuery($slug: ID!) {
    report(slug: $slug) {
      client {
        name
      }
    }
  }
`;

const OppdragsgiverNavn: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery<{
    report: {
      client: {
        name: string;
      };
    };
  }>(REPORT_CLIENT_NAME_QUERY, {
    variables: { slug },
  });

  if (loading) return <WordLoader />;
  if (error) return <p>Error :(</p>;

  const { name } = data?.report?.client || {};

  return <>{name && <span>{name}</span>}</>;
};

export default OppdragsgiverNavn;

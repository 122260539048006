import React from "react";
// import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { useCollapsed } from "~/layout/collapsible";
import BlockHeader from "../BlockHeader";

interface Props {
  noIcon?: true;
}

interface StyledBlockHeaderProps
  extends React.ComponentProps<typeof BlockHeader> {
  collapsed: boolean;
}

const StyledBlockHeader = styled(BlockHeader)<StyledBlockHeaderProps>`
  margin: 0;
  cursor: pointer;

  ${({ variant }) =>
    variant === "dark" &&
    css`
      .block-header-icon {
        transform: rotate(-180deg);
      }
    `};
`;

const Header: React.FC<Props> = ({ noIcon, children }) => {
  const { collapsed, toggle } = useCollapsed();

  return (
    <StyledBlockHeader
      collapsed={collapsed}
      onClick={toggle}
      icon={noIcon ? undefined : "chevronDown"}
      variant={collapsed ? "light" : "dark"}
    >
      {children}
    </StyledBlockHeader>
  );
};

export default Header;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import Block from "~/layout/Block";
import { Report } from "~/report";
import ReportProvider from "~/report/ReportProvider";
import { Session, SessionProvider, useSession } from "~/session";
import { client } from "~/setup";
import { generalEditor } from "~/user";
import { DocumentationSection } from ".";
import { DocumentationBlockList, NewDocumentationBlock } from "./block";

interface Props {
  report: Report;
  documentationSection: DocumentationSection;
}

const EditDocumentationSectionPage: React.FC<Omit<Props, "report">> = ({
  documentationSection,
}) => {
  const { role } = useSession() as Session;

  return (
    <>
      <DocumentationBlockList documentationSection={documentationSection} />
      {generalEditor(role) && (
        <>
          <hr />
          <Block>
            <NewDocumentationBlock
              documentationSection={documentationSection}
            />
          </Block>
        </>
      )}
    </>
  );
};

interface WrapProps extends Props {
  session: Session;
}

const ApolloWrap: React.FC<WrapProps> = ({ session, report, ...rest }) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <ReportProvider report={{ ...report, id: report.id.toString() }}>
        <EditDocumentationSectionPage {...rest} />
      </ReportProvider>
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const Styled = styled.h3`
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--titanium);
  margin-top: 0;
`;

const Fieldset: React.FC = ({ children }: Props) => <Styled>{children}</Styled>;

export default Fieldset;

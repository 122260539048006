import React from "react";
import styled from "styled-components";

export const StyledBlockListHeader = styled.div`
  margin: 0 0 1rem 0;
  padding: 0 calc(1rem + 1px);
  font-family: var(--font-moon);
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--titanium);
`;

const BlockListHeader: React.FC = ({ children }) => {
  return <StyledBlockListHeader>{children}</StyledBlockListHeader>;
};

export default BlockListHeader;

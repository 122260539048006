import { Building } from "~/graphql-operations";

export const toFormData = ({
  name,
  constructionYear,
  renovation,
  // area,
  order,
  gnr,
  bnr,
  snr,
  fnr,
  parts,
  autoTotal,
  extraInfo,
  buildingTypeId,
}: Building) => {
  return {
    name,
    constructionYear,
    renovation,
    // area,
    order,
    gnr,
    bnr,
    snr,
    fnr,
    parts: (parts || []).map(({ usage, floor, area }) => ({
      usage,
      floor,
      area,
    })),
    autoTotal,
    extraInfo,
    buildingTypeId,
  };
};

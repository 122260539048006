import {
  ApolloProvider,
  MutationFunction,
  useMutation,
  useQuery,
} from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Alert, ConfirmButton, SaveButton } from "~/layout";
import { client } from "~/setup";
import { CostSectionStateStep } from "..";
import { CostSection } from "../constants";
import TouchedStatusMessage from "../TouchedStatusMessage";
import {
  COST_SECTION_STATE_FFW_MUTATION,
  COST_SECTION_STATE_QUERY,
  COST_SECTION_STATE_REW_MUTATION,
} from "./constants";
import StepRewButton from "./StepRewButton";

export interface Props {
  id: number;
}

type QueryData = { costSection: CostSection };

const StateAlert: React.FC<Props> = ({ id }) => {
  const { loading, error, data } = useQuery<QueryData>(
    COST_SECTION_STATE_QUERY,
    {
      variables: { id },
    }
  );

  const [ffwMutation, { loading: loadingFfw, data: ffwData }] = useMutation(
    COST_SECTION_STATE_FFW_MUTATION
  );
  const [rewMutation, { loading: loadingRew }] = useMutation(
    COST_SECTION_STATE_REW_MUTATION
  );

  if (loading || error)
    return (
      <Alert color="dark-gray">
        <p>{loading ? "Loading..." : "Error :("}</p>
      </Alert>
    );

  const step = (
    mutation: MutationFunction,
    state: CostSection["state"],
    message: string | undefined = undefined
  ) =>
    mutation({
      variables: {
        input: {
          id,
          state,
          message,
        },
      },
    });

  const {
    costSection: {
      status,
      statusInfo,
      state,
      stateMessage,
      steps,
      urls: { show },
    },
  } = data as QueryData;

  const currStep = steps.find(
    (step) => step.state === state
  ) as CostSectionStateStep;
  const ffwStep = steps
    .filter((step) => step.ffw)
    .slice(-1)
    .pop();
  const rewindable = currStep.rew;
  const finished = currStep.finished || ffwData;
  // const finished = !!steps.find(
  //   (step) => step.finished && steps.indexOf(step) < steps.indexOf(currStep)
  // );

  const variant = finished ? "success" : ffwStep ? "warning" : "info";

  const handleFfw = async () => {
    if (!ffwStep) return;

    const {
      data: {
        costSectionStateFfw: { stateMessage: mutationStateMessage },
      },
    } = await step(ffwMutation, ffwStep.state);

    setTimeout(() => {
      alert(mutationStateMessage);
      window.location.href = show;
    }, 2500);
  };

  return (
    <Alert variant={variant}>
      <Row>
        <Col>
          <p>{stateMessage}</p>
        </Col>

        {rewindable && (
          <Col lg="auto">
            <StepRewButton
              rew={(message: string) => step(rewMutation, state, message)}
              rewinding={loadingRew}
            >
              Etterspør endringer fra konsulenten
            </StepRewButton>
          </Col>
        )}

        {ffwStep && (
          <Col lg="auto">
            {status == "touched" ? (
              <ConfirmButton
                size="sm"
                message={<TouchedStatusMessage statusInfo={statusInfo} />}
                title="Advarsel"
                confirmLabel="Revidere"
                rejectLabel="Ignorer og fortsett"
                onConfirm={() => undefined}
                onReject={handleFfw}
              >
                Godkjenne
              </ConfirmButton>
            ) : (
              <SaveButton size="sm" loading={loadingFfw} onClick={handleFfw}>
                Godkjenne
              </SaveButton>
            )}
          </Col>
        )}
      </Row>
    </Alert>
  );
};

const ApolloProviderWrapper: React.FC<Props> = (props) => (
  <ApolloProvider client={client}>
    <StateAlert {...props} />
  </ApolloProvider>
);

export default ApolloProviderWrapper;

import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import {
  InvoiceCategory,
  UpdateInvoiceCategoryInput,
  useDeleteInvoiceCategoryMutation,
  useUpdateInvoiceCategoryMutation,
} from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import { CategoryForm } from ".";

interface Props {
  category: InvoiceCategory;
}

const EditCategory: React.FC<Props> = ({
  category: { id, name, type, budget, invoicesCount, total, vatIncluded },
}) => {
  const [updateMutation] = useUpdateInvoiceCategoryMutation();
  const [deleteMutation] = useDeleteInvoiceCategoryMutation();

  const onUpdate = (input: UpdateInvoiceCategoryInput) => {
    return updateMutation({
      variables: { input },
      refetchQueries: ["ConstructionLoanBudget"],
    });
  };

  const onDelete = () => {
    return deleteMutation({
      variables: { input: { id } },
      refetchQueries: ["ConstructionLoanBudget"],
    });
  };

  return (
    <CategoryForm
      autoSave
      typeLocked
      defaultValues={{ id, name, type, budget, vatIncluded }}
      onSubmit={onUpdate}
      total={total}
      id={`invoice-category-${id}-form`}
    >
      <Col lg="auto" style={{ paddingLeft: 0 }}>
        <FormGroup>
          <DeleteButton
            icon="delete"
            iconOnly
            onClick={onDelete}
            disabled={invoicesCount > 0}
          />
        </FormGroup>
      </Col>
    </CategoryForm>
  );
};

export default EditCategory;

import React from "react";
import { Col } from "react-bootstrap";
import { Form, Row, TextField } from "~/form";
import {
  ConstructionLoan,
  UpdateConstructionLoanPeriodInput,
  useUpdateConstructionLoanMutation,
} from "~/graphql-operations";

interface Props {
  report: Pick<ConstructionLoan, "id" | "contractorBudgetPlanLabel">;
}

const EditContractorBudgetPlanLabel: React.FC<Props> = ({
  report: { id, contractorBudgetPlanLabel },
}) => {
  const [update] = useUpdateConstructionLoanMutation();
  const onSubmit = (input: UpdateConstructionLoanPeriodInput) =>
    update({
      variables: { input },
      refetchQueries: ["ConstructionLoanContractors"],
    });

  return (
    <Form
      id={`edit-budget-plan-${id}`}
      defaultValues={{ id, contractorBudgetPlanLabel }}
      onSubmit={onSubmit}
      autoSave
    >
      <Row>
        <Col lg="6">
          <TextField
            name="contractorBudgetPlanLabel"
            label="Navn på budsjettplan"
            required
          />
        </Col>
      </Row>
    </Form>
  );
};

export default EditContractorBudgetPlanLabel;

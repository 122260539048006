import React from "react";
import { Col } from "react-bootstrap";
import {
  Fieldset,
  Form,
  NumberField,
  Row,
  SaveFormButton,
  TextField,
} from "~/form";
import { Block } from "~/layout";
import { useCreateCostLineTemplate } from "./hooks";

type Props = {
  costBlockTemplateId: string;
};

const NewCostLineTemplate: React.FC<Props> = ({ costBlockTemplateId }) => {
  const create = useCreateCostLineTemplate();

  return (
    <Block>
      <Form
        defaultValues={{
          costBlockTemplateId,
        }}
        onSubmit={create}
        resetBlank
        horizontal
      >
        <Fieldset title="Legg Till Ny Kostnadslinje">
          <Row>
            <Col sm="2">
              <NumberField name="ref" label="Ref" required />
            </Col>
            <Col sm="8">
              <TextField name="title" label="Tittel" required />
            </Col>
            <Col>
              <SaveFormButton />
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default NewCostLineTemplate;

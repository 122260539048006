import invariant from "invariant";
import React from "react";
import styled from "styled-components";
import { useDokumentasjonsKarakterQuery } from "~/graphql-operations";
import { WordLoader } from "~/layout/Loading";
import { Slider } from "~/slider";
import { ShortcodeProps } from "./Shortcode";

const Container = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 5px;
`;

const DokumentasjonsKarakter: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const {
    loading,
    error,
    data: { report } = {},
  } = useDokumentasjonsKarakterQuery({
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  invariant(report && "documentationSection" in report, "No report data");

  const {
    documentationSection: { grade, score },
  } = report;

  if (!(typeof score === "number" && grade)) return null;

  return (
    <Container>
      <Slider score={score * 17} grade={grade} small={false} reversed={true} />
    </Container>
  );
};

export default DokumentasjonsKarakter;

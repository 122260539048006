import { useQuery } from "@apollo/client";
import React from "react";
import { RequestCostBlock } from ".";
import { EditRequestCostLineList } from "../cost-line";
import { REQUEST_COST_BLOCK_REQUEST_COST_LINES_ADMIN_QUERY } from "./constants";

interface Props {
  requestCostBlock: RequestCostBlock;
}

const EditRequestCostBlockCostLines: React.FC<Props> = ({
  requestCostBlock: requestCostBlockProp,
}) => {
  const { loading, error, data } = useQuery(
    REQUEST_COST_BLOCK_REQUEST_COST_LINES_ADMIN_QUERY,
    {
      variables: {
        id: requestCostBlockProp.id,
      },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    requestCostBlock,
    requestCostBlock: { requestCostLines },
  } = data;

  return (
    <EditRequestCostLineList
      requestCostBlock={requestCostBlock}
      requestCostLines={requestCostLines}
    />
  );
};

export default EditRequestCostBlockCostLines;

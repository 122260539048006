import React from "react";
import { Card, CardImgProps } from "react-bootstrap";
import styled from "styled-components";
import { StyledIcon } from "~/layout/icon";
import { Company } from ".";
import { IconButton } from "../layout";
import { LineLoader } from "../layout/Loading";
import LogoSvg from "./company-enterprise.svg";

type CardProps =
  | {
      company: Company;
      loading?: false;
    }
  | {
      company?: undefined;
      loading: true;
    };

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 140px;
`;

interface LogoImgProps extends CardImgProps {
  blank?: boolean;
}

const LogoImg = styled(Card.Img)<LogoImgProps>`
  max-width: 100%;
  max-height: 140px;
  width: auto;
  height: auto;
  padding: 1.75rem;
  opacity: ${({ $blank }) => ($blank ? "0.25" : "1")};
  transition: all 0.16s ease-in-out;
`;

const CardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
`;

const CardTitle = styled(Card.Title)`
  margin: 0;
  padding: 0.75rem 1.25rem;
  text-align: center;
  color: #fff;
  background-color: var(--dark-gray);
  border-radius: 0 0 5px 5px;
`;

const ReportsCount = styled.span`
  position: absolute;
  right: -0.75rem;
  bottom: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 1.25rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  color: var(--white);
  background: var(--titanium);
  border-radius: 50%;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.33s ease-in-out;
`;

const CardBtnWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  margin-bottom: 0;
  opacity: 0;
  z-index: 10;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: all 0.16s ease-in-out;

    &:hover {
      background-color: var(--titanium);
      /* transform: scale(1.15); */

      ${StyledIcon} {
        background-color: var(--white);
      }
    }
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 30px;
  font-size: 0.875rem;
  text-align: center;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.16s ease-in-out;

  ${({ onClick }) => onClick && "cursor: pointer;"}

  &:hover {
    transform: scale(0.95);

    ${CardBtnWrapper} {
      opacity: 1;
    }

    ${CardTitle} {
      color: var(--yellow);
    }

    ${ReportsCount} {
      color: var(--dark-gray);
      background: var(--yellow);
      transform: scale(1.5);
    }
  }
`;

const CompanyCard: React.FC<CardProps> = ({ company, loading }) => {
  const { id, name, reportsCount, logo } = company || {};

  return (
    <StyledCard
      id={`company-${id}`}
      onClick={
        loading ? undefined : () => (window.location.href = `/companies/${id}`)
      }
    >
      {!loading && (
        <CardBtnWrapper>
          <IconButton
            icon="edit"
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = `/companies/${id}/edit`;
            }}
            size="sm"
            variant="link"
            as="a"
          />
        </CardBtnWrapper>
      )}
      <LogoWrap>
        <LogoImg src={logo?.url || LogoSvg} $blank={!logo} />
      </LogoWrap>
      <CardBody>
        <CardTitle>{loading ? <LineLoader /> : name}</CardTitle>
        <ReportsCount>{loading ? "-" : reportsCount}</ReportsCount>
      </CardBody>
    </StyledCard>
  );
};

export default CompanyCard;

import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";
import { Report } from "~/report";

export interface Event extends ResourceInterface {
  date?: string;
  description?: string;
  notes?: string;
  place: string;
  report: Report;
  reportId: string;
  url: string;
}

export const eventFieldsFragment = gql`
  fragment EventFields on Event {
    id
    report {
      id
    }
    place
    date
    description
    notes
    url
    createdAt
  }
`;

export const EVENTS_QUERY = gql`
  query ConstructionLoanEvents($slug: ID!) {
    report(slug: $slug) {
      id
      ... on ConstructionLoan {
        events {
          ...EventFields
        }
      }
    }
  }
  ${eventFieldsFragment}
`;

export const EVENT_CREATE_MUTATION = gql`
  mutation EventCreate($input: EventCreateInput!) {
    eventCreate(input: $input) {
      event {
        ...EventFields
      }
      errors
    }
  }
  ${eventFieldsFragment}
`;

export const EVENT_UPDATE_MUTATION = gql`
  mutation EventUpdate($input: EventUpdateInput!) {
    eventUpdate(input: $input) {
      event {
        ...EventFields
      }
      errors
    }
  }
  ${eventFieldsFragment}
`;

export const EVENT_DELETE_MUTATION = gql`
  mutation EventDelete($input: EventDeleteInput!) {
    eventDelete(input: $input) {
      event {
        id
        report {
          id
        }
      }
    }
  }
`;

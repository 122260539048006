import { pick } from "lodash";
import { attachmentInput } from "~/form/attachment";
import { RequestCostLine } from ".";

export const toFormData = (
  requestCostLine: Partial<RequestCostLine>
): Partial<Omit<RequestCostLine, "files">> & {
  files?: string | string[];
} => {
  const { files } = requestCostLine;
  return {
    ...pick(requestCostLine, ["description", "reference", "received", "cost"]),
    files: files ? attachmentInput(files) : undefined,
  };
};

import React from "react";
import styled from "styled-components";
import { LegendEnvironment, LegendTitle } from "./styles";

const LegendItemPurple = styled.li`
  padding-left: 28px;
  position: relative;
  line-height: 1.75;
  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 21px;
    height: 14px;
    background-color: #800080;
  }
`;

const LegendItemRed = styled.li`
  padding-left: 28px;
  position: relative;
  line-height: 1.75;
  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 21px;
    height: 14px;
    background-color: #ff3265;
  }
`;

const LegendItemYelow = styled.li`
  padding-left: 28px;
  position: relative;
  line-height: 1.75;
  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 21px;
    height: 14px;
    background-color: var(--yellow);
  }
`;

const LegendItemGray = styled.li`
  padding-left: 28px;
  position: relative;
  line-height: 1.75;
  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 21px;
    height: 14px;
    background-color: #bbbbbb;
  }
`;
const LegendRadon: React.FC = () => {
  return (
    <span>
      <LegendEnvironment>
        <LegendTitle>Radon -aktsomhet</LegendTitle>
        <LegendItemPurple>Særlig høy</LegendItemPurple>
        <LegendItemRed>Høy</LegendItemRed>
        <LegendItemYelow>Moderat til lav</LegendItemYelow>
        <LegendItemGray>Usikker</LegendItemGray>
      </LegendEnvironment>
    </span>
  );
};

export default LegendRadon;

import {
  InvoiceEntryCategory,
  UpdateInvoiceEntryCategoryInput,
  useCreateInvoiceEntryCategoryMutation,
  useDeleteInvoiceEntryCategoryMutation,
  useUpdateInvoiceEntryCategoryMutation,
} from "~/graphql-operations";
import { InvoiceContractor } from "~/invoice/contractor";

export type InvoiceEntryCategoryFormData = Pick<
  InvoiceEntryCategory,
  "name" | "type" | "budget"
>;

export interface CreateInvoiceEntryCategoryInput
  extends InvoiceEntryCategoryFormData {
  contractorId: InvoiceContractor["id"];
}

// always update BudgetStatus
const refetchQueries = ["ConstructionLoanBudget"];

export const useCreateInvoiceEntryCategory = () => {
  const [createMutation] = useCreateInvoiceEntryCategoryMutation();

  return async (input: CreateInvoiceEntryCategoryInput) => {
    return createMutation({
      variables: { input },
      refetchQueries,
    });
  };
};

export const useUpdateInvoiceEntryCategory = () => {
  const [updateMutation] = useUpdateInvoiceEntryCategoryMutation();

  return async (input: UpdateInvoiceEntryCategoryInput) => {
    return updateMutation({
      variables: { input },
      refetchQueries,
    });
  };
};

export const useDeleteInvoiceEntryCategory = () => {
  const [deleteMutation] = useDeleteInvoiceEntryCategoryMutation();

  return async (id: InvoiceEntryCategory["id"]) => {
    return deleteMutation({
      variables: { input: { id } },
      refetchQueries,
    });
  };
};

import React, { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";

type FormData = { x: string | undefined };

const object: FormData = {
  x: undefined,
};

let renderCount = 0;

const randString = () => Math.random().toString();

const CustomControl = () => {
  const { control, setValue } = useFormContext();

  const onClickAsync = () => {
    console.log("--- ASYNC ---");
    setTimeout(() => {
      setValue("x", randString(), { shouldDirty: true });
    }, 1000);
  };

  const onClick = () => {
    console.log("--- SYNC ---");
    setValue("x", randString(), { shouldDirty: true });
  };

  return (
    <Controller
      name="x"
      control={control}
      render={() => (
        <>
          <button onClick={onClickAsync} type="button">
            Click Async
          </button>
          <button onClick={onClick} type="button">
            Click Sync
          </button>
        </>
      )}
    />
  );
};

const AutoSave = ({ onSubmit }: { onSubmit: SubmitHandler<FormData> }) => {
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useFormContext();
  const x = useWatch({ name: "x", control });

  useEffect(() => {
    console.log("useEffect();", "isDirty:", isDirty, "x:", x);
    if (isDirty) {
      handleSubmit(onSubmit)();
    }
  }, [x, isDirty, handleSubmit, onSubmit]);

  return null;
};

const MyForm = () => {
  const methods = useForm({
    defaultValues: object,
  });
  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = methods;
  console.log("FORM isDirty:", isDirty);

  const onSubmit = ({ x }: FormData) => {
    console.log("Submit()", x);
    reset(undefined, { keepValues: true });
  };

  renderCount++;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>Form render count: {renderCount}</p>
        <AutoSave onSubmit={onSubmit} />
        <CustomControl />
      </form>
    </FormProvider>
  );
};

const Test = () => {
  return (
    <div style={{ margin: "3rem", padding: "3rem", backgroundColor: "#fff" }}>
      <MyForm />
    </div>
  );
};

export default Test;

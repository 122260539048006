import { pick } from "lodash";
import React from "react";
import { Col } from "react-bootstrap";
import { Fieldset, NumberField, RedactorField, Row } from "~/form";
import { ConstructionLoanPeriod } from "~/graphql-operations";
import EditPeriodForm from "./EditPeriodForm";

interface Props {
  period: ConstructionLoanPeriod;
}

const EditPeriod: React.FC<Props> = ({ period, period: { full } }) => {
  const defaultValues = pick(period, [
    "id",

    "documentsText",
    "financeText",
    "inspectionText",
    "invoiceText",
    "invoicePlanText",
    "payout.recommendation",
    "progressText",
    "qualityText",
    "shaFiguresText",
    "shaGeneralText",

    "accidents",
    "issues",
    "safetyInspections",
  ]);

  return (
    <EditPeriodForm defaultValues={defaultValues}>
      <Fieldset title="Økonomi">
        <RedactorField
          name="invoiceText"
          label="Mottatte fakturaer i perioden"
        />
        <RedactorField
          name="invoicePlanText"
          label="Vurdering faktura mot betalingsplan"
        />
        <RedactorField
          name="payout.recommendation"
          label="Anbefalte utbetalinger"
        />
        <RedactorField
          name="financeText"
          label="Andel kostnader av innvilget byggelån"
        />
      </Fieldset>

      <Fieldset title="Rapportgrunnlag">
        <RedactorField
          name="inspectionText"
          label="Avholdte møter/befaringer i perioden"
        />
        <RedactorField name="documentsText" label="Mottatt dokumentasjon" />
      </Fieldset>
      <Fieldset title="Fremdrift/utførte arbeider">
        <RedactorField name="progressText" label="Status fremdrift" />
        <RedactorField name="qualityText" label="Kvalitet" />
      </Fieldset>
      {full && (
        <Fieldset title="SHA">
          <RedactorField name="shaGeneralText" label="Generelt" />
          <Row>
            <Col lg="2">
              <NumberField name="issues" label="Uønskede hendelser" required />
            </Col>
            <Col lg="2">
              <NumberField name="accidents" label="Ulykker" required />
            </Col>
            <Col lg="2">
              <NumberField
                name="safetyInspections"
                label='"Sikker jobb" analyser'
                required
              />
            </Col>
          </Row>
          <RedactorField name="shaFiguresText" label="SHA figures text" />
        </Fieldset>
      )}
    </EditPeriodForm>
  );
};

export default EditPeriod;

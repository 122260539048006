import React from "react";
import { Form, TextField } from "~/form";
import RedactorFieldOld from "~/form/RedactorFieldOld";
import {
  Paragraph,
  UpdateParagraphInput,
  useUpdateParagraphMutation,
} from "~/graphql-operations";

export interface EditParagraphProps {
  paragraph: Pick<Paragraph, "id" | "content" | "title">;
}

const EditParagraph: React.FC<EditParagraphProps> = ({
  paragraph: { title, content, id },
}) => {
  const defaultValues = { title, content, id };
  const [mutation] = useUpdateParagraphMutation();

  const updateParagraph = (input: UpdateParagraphInput) =>
    mutation({ variables: { input } });

  return (
    <Form defaultValues={defaultValues} onSubmit={updateParagraph} autoSave>
      <TextField name="title" label="Title" />
      {/* <RedactorField name="content" label="Innhold" shortcodes /> */}
      <RedactorFieldOld name="content" label="Innhold" />
    </Form>
  );
};

export default EditParagraph;

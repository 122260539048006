import React from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Row as FormRow, SelectField, TextField } from "~/form";
import { DocumentationLineTemplate } from "~/graphql-operations";
import { DeleteButton } from "~/layout";
import List, { BlockTitle } from "~/layout/block-list";
import { Block, Body, Header } from "~/layout/block-list/collapsible";
import {
  NewDocumentationLineTemplate,
  useDeleteDocumentationLineTemplate,
  useUpdateDocumentationLineTemplate,
} from ".";

type Props = {
  lines: DocumentationLineTemplate[];
  documentationBlockTemplateId: string;
};

const EditDocumentationLineTemplatesList: React.FC<Props> = ({
  lines,
  documentationBlockTemplateId,
}) => {
  const updateLine = useUpdateDocumentationLineTemplate();

  const deleteLine = useDeleteDocumentationLineTemplate();

  const columnOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
  ];

  return (
    <>
      <List>
        {lines.map(({ id, title, number, columns }) => (
          <Block key={id} id={`documentation-line-template-${id}`}>
            <Header>
              <Row className="d-flex justify-content-between align-items-center">
                <Col>
                  <BlockTitle>{`#${number} ${title}`}</BlockTitle>
                </Col>
                <Col sm="auto">
                  <DeleteButton iconOnly onClick={() => deleteLine(id)} />
                </Col>
              </Row>
            </Header>
            <Body>
              <div style={{ margin: "0 -1rem -1rem" }}>
                <Form
                  defaultValues={{ id, title, number, columns }}
                  onSubmit={updateLine}
                  autoSave
                  horizontal
                >
                  <FormRow>
                    <Col lg="8">
                      <TextField name="title" label="Tittel" />
                    </Col>
                    <Col lg="2">
                      <TextField name="number" label="Pkt" />
                    </Col>
                    <Col lg="2">
                      <SelectField
                        name="columns"
                        label="kolonner"
                        options={columnOptions}
                      />
                    </Col>
                  </FormRow>
                </Form>
              </div>
            </Body>
          </Block>
        ))}
      </List>
      <NewDocumentationLineTemplate
        documentationBlockTemplateId={documentationBlockTemplateId}
      />
    </>
  );
};

export default EditDocumentationLineTemplatesList;

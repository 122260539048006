import React from "react";
import { Col } from "react-bootstrap";
import {
  CheckboxField,
  Form,
  FormProps,
  NumberField,
  Row,
  TextField,
} from "~/form";
import { Option } from "~/form/SelectField";
import {
  CreateInvoiceCategoryInput,
  InvoiceCategory,
  UpdateInvoiceEntryCategoryInput,
} from "~/graphql-operations";
import { Currency } from "~/util";
import { CategoryTypeSelectField } from "../category-type";

interface Props
  extends Omit<
    FormProps<CreateInvoiceCategoryInput | UpdateInvoiceEntryCategoryInput>,
    "formMethods"
  > {
  // onDelete?: (id: InvoiceCategory["id"]) => void;
  typeLocked?: boolean;
  typeOptions?: Option<InvoiceCategory["type"]>[];
  total?: number;
}

const InvoiceCategoryForm: React.FC<Props> = ({
  defaultValues,
  onSubmit,
  typeLocked = false,
  typeOptions,
  children,
  total,
  ...rest
}) => {
  const persistedRecord =
    defaultValues && "id" in defaultValues && defaultValues?.id;

  return (
    <Form defaultValues={defaultValues} onSubmit={onSubmit} {...rest}>
      <Row
        className={persistedRecord ? "align-items-center" : "align-items-end"}
      >
        <Col lg="4">
          <TextField name="name" label="Navn" required />
        </Col>
        {typeLocked && persistedRecord && (
          <Col lg="3">
            <CategoryTypeSelectField
              name="type"
              label="Type"
              disabled={typeLocked}
              options={typeOptions}
              required
            />
          </Col>
        )}
        <Col lg="auto">
          <NumberField name="budget" label="Budsjett" required />
        </Col>
        <Col lg="auto">
          <CheckboxField name="vatIncluded" label="MVA ink." />
        </Col>
        {typeof total === "number" && (
          <Col className="text-right">
            <Currency>{total}</Currency>
          </Col>
        )}

        {children}
      </Row>
    </Form>
  );
};

export default InvoiceCategoryForm;

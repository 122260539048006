import { useQuery } from "@apollo/client";
import React from "react";
import { BuildingType, BUILDING_TYPES_QUERY } from "~/BuildingType/constants";
import { SelectField } from "~/form";
import { Option, SyncSelectFieldProps } from "~/form/SelectField";

const buildingTypesToOptions = (
  buildingTypes: BuildingType[]
): Option<BuildingType>[] =>
  buildingTypes.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

const BuildingTypeSelectField: React.FC<SyncSelectFieldProps<BuildingType>> = ({
  name,
  label,
  ...rest
}) => {
  const { data, loading, error } = useQuery<{ buildingTypes: BuildingType[] }>(
    BUILDING_TYPES_QUERY,
    {
      variables: {
        default: false,
      },
    }
  );

  if (error) return <p>Error :(</p>;
  const options = data ? buildingTypesToOptions(data.buildingTypes) : [];

  return (
    <SelectField
      name={name}
      label={label}
      isLoading={loading}
      options={options}
      {...rest}
    />
  );
};

export default BuildingTypeSelectField;

import React from "react";
import { ShortcodeProps } from ".";

const MiljoRadonAktsomhet: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  return <div>MiljoRadonAktsomhet Img</div>;
};

export default MiljoRadonAktsomhet;

import { costCoverageOptions, CostLine } from "./constants";

import { pick } from "lodash";
import { Variant } from "react-bootstrap/esm/types";
import { nodeAttributeLabel } from "~/node";

export const toFormData = (costLine: CostLine) =>
  pick(costLine, [
    "id",
    "buildingId",
    "period",
    "priority",
    "tg",
    "kg",
    "costCoverage",
    "cost",
    "description",
    "note",
  ]);

export const costCoverageLabel = nodeAttributeLabel(costCoverageOptions);

// export const statusLabel = (status: CostLine["status"]) => {
//   switch (status) {
//     case "pristine":
//       return "Tømme";
//     case "touched":
//       return "Pågående";
//     case "complete":
//       return "Klar";
//     default:
//       return "NULL";
//   }
// };

export const statusColor = (status: string): Variant => {
  const normalized = status.toLowerCase();
  switch (normalized) {
    case "pristine":
      return "secondary";
    case "touched":
      return "orange";
    case "complete":
      return "teal";
    default:
      return "black";
  }
};

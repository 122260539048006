import { ApolloProvider } from "@apollo/client";
import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";
import { CloseButton } from "~/layout/Button";
import Icon from "~/layout/icon";
import { client } from "~/setup";

interface Props {
  note: string;
}

const IconWrap = styled.span<{ $active: boolean }>`
  width: 1.75rem;
  height: 1.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(
    --${({ $active }) => ($active ? "dark-gray" : "primary")}
  );
  border-radius: 50%;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)<{ $active: boolean }>`
  width: 1rem;
  height: 1rem;
  background-color: var(
    --${({ $active }) => ($active ? "primary" : "white")}
  ); ;
`;

const CostLineNotePopover: React.FC<Props> = ({ note }) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Title as="h3">Rating</Popover.Title> */}
      <Popover.Content style={{ position: "relative" }}>
        <CloseButton
          onClick={toggle}
          size="sm"
          style={{ position: "absolute", top: "-0.75rem", right: "-1.25rem" }}
        />
        {note}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      show={show}
      onToggle={toggle}
      placement="top"
      overlay={popover}
      rootClose
    >
      <IconWrap $active={show}>
        <StyledIcon icon="commentSolid" $active={show} />
      </IconWrap>
    </OverlayTrigger>
  );
};

const ApolloWrap: React.FC<Props> = ({ ...rest }) => (
  <ApolloProvider client={client}>
    <CostLineNotePopover {...rest} />
  </ApolloProvider>
);

export default ApolloWrap;

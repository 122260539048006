import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { reportTypeLabel } from "~/report";
import { ShortcodeProps } from "./Shortcode";

const REPORT_TYPE_QUERY = gql`
  query ProsjektinformasjonType($slug: ID!) {
    report(slug: $slug) {
      id
      type
    }
  }
`;

const ProsjektinformasjonType: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(REPORT_TYPE_QUERY, {
    variables: { slug },
  });
  if (error) return <p>Error</p>;
  const { type } = data?.report || {};

  return <>{loading ? <WordLoader /> : reportTypeLabel(type)}</>;
};

export default ProsjektinformasjonType;

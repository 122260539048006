import React, { FC, ReactNode, useState } from "react";
import { useModal } from "~/util";
import { IconButton, Modal } from "..";
import DeleteButton from "./DeleteButton";

interface Props {
  // confirmText?: string;
  onDelete: () => Promise<unknown>;
  warning?: () => Promise<ReactNode | null>;
  inverse?: boolean;
}

const DeleteBuildingButton: FC<Props> = ({
  // confirmText = "I am sure",
  onDelete,
  warning,
  inverse,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [warningComponent, setWarningComponent] = useState<ReactNode | null>(
    null
  );
  // const [confirmed, setConfirmed] = useState(false);
  const { active, show, close } = useModal();

  const handleShow = async () => {
    show();
    setLoading(true);
    setWarningComponent(warning ? await warning() : null);
    setLoading(false);
  };

  const footer = (
    <>
      {/* {warningComponent && (
        <FormCheck
          id="delete-building-confirm"
          label={confirmText}
          checked={confirmed}
          onChange={() => setConfirmed(!confirmed)}
          className="mr-3"
        />
      )} */}
      <DeleteButton
        className="mr-2"
        onClick={onDelete}
        // disabled={loading || !!(warningComponent && !confirmed)}
        disabled={loading}
        noConfirm
      >
        Yes
      </DeleteButton>
      <IconButton icon="deny" variant="primary" onClick={() => close()}>
        Cancel
      </IconButton>
    </>
  );

  return (
    <>
      <DeleteButton onClick={handleShow} inverse={inverse} iconOnly noConfirm />

      <Modal active={active} onHide={close} footer={footer}>
        {warningComponent ? (
          warningComponent
        ) : loading ? (
          <p>Loading...</p>
        ) : (
          <p>Are you sure?</p>
        )}
      </Modal>
    </>
  );
};

export default DeleteBuildingButton;

import React, { useState } from "react";
import styled from "styled-components";
import { IconButton } from "~/layout";
import { BlockList, BlockListItem } from "~/layout/BlockList";
import { StyledIcon } from "~/layout/icon";
import { BuildingPart } from ".";
import { BuildingPartRatingRule } from "../building-part-rating-rule";
import BuildingPartListItemHeader from "./BuildingPartListItemHeader";
import EditBuildingPart, { EditBuildingPartProps } from "./EditBuildingPart";

interface Props {
  buildingParts: BuildingPart[];
  rules: BuildingPartRatingRule[];
  options: EditBuildingPartProps["options"];
  budget: boolean;
}

const StyledIconButton = styled(IconButton)`
  padding-left: 0;
  padding-right: 0;
  cursor: move;
  ${StyledIcon} {
    margin-right: 1rem;
  }
`;

const Expandable: React.FC<{
  buildingPart: BuildingPart;
  rules: BuildingPartRatingRule[];
  options: EditBuildingPartProps["options"];
  budget: boolean;
}> = (props) => {
  const [expanded, setExpanded] = useState(false);

  return expanded ? (
    <EditBuildingPart {...props}>
      <StyledIconButton
        icon="chevronDown"
        size="sm"
        variant="link"
        onClick={() => setExpanded(false)}
      />
    </EditBuildingPart>
  ) : (
    <BuildingPartListItemHeader
      {...props}
      mode="expandable"
      onClick={() => setExpanded(true)}
    />
  );
};

const BuildingPartListExpandable: React.FC<Props> = ({
  buildingParts,
  ...rest
}) => {
  return (
    <BlockList>
      {buildingParts.map((buildingPart) => (
        <BlockListItem
          key={buildingPart.id}
          id={`building-part-${buildingPart.id}`}
        >
          <Expandable buildingPart={buildingPart} {...rest} />
        </BlockListItem>
      ))}
    </BlockList>
  );
};

export default BuildingPartListExpandable;

import { gql } from "@apollo/client";
import { SectionInterface } from "~/sections";
import { BudgetLine } from "./budget-line";
import { BudgetPlan } from "./budget-plan";
import { BuildingPart } from "./building-part";
import { buildingPartRatingRuleFragment } from "./building-part-rating-rule";
import {
  budgetLineFragment,
  buildingPartCostFragment,
  buildingPartFragment,
  buildingPartSummaryFragment,
} from "./fragments";

export interface HousingMaintenanceSection extends SectionInterface {
  description: string;
  note: string;
  extended: boolean;
  buildingParts: BuildingPart[];
  actionableBuildingParts: BuildingPart[];
  displayableBuildingParts: BuildingPart[];
  budgetLines?: BudgetLine[];
  displayableBudgetLines?: BudgetLine[];
  budgetPlans?: BudgetPlan[];
}

export const housingMaintenanceSectionFragment = gql`
  fragment HousingMaintenanceSectionFields on HousingMaintenanceSection {
    id
    title
    description
    note
    extended
  }
`;

export const HOUSING_MAINTENANCE_SECTION_ACTIONABLE_BUILDING_PARTS_QUERY = gql`
  query housingMaintenanceSectionBuildingPartsAdmin($id: ID!) {
    housingMaintenanceSection(id: $id) {
      ...HousingMaintenanceSectionFields
      actionableBuildingParts {
        ...HousingMaintenanceBuildingPartFields
        ...HousingMaintenanceBuildingPartCost
      }
    }
  }
  ${buildingPartFragment}
  ${buildingPartCostFragment}
  ${housingMaintenanceSectionFragment}
`;

export const HOUSING_MAINTENANCE_SECTION_BUILDING_PARTS_ADMIN_QUERY = gql`
  query housingMaintenanceSectionBuildingPartsAdmin($id: ID!) {
    housingMaintenanceSection(id: $id) {
      ...HousingMaintenanceSectionFields
      buildingParts {
        ...HousingMaintenanceBuildingPartFields
        ...HousingMaintenanceBuildingPartSummary
      }
    }
    housingMaintenanceBuildingPartRatingRules {
      ...HousingMaintenanceBuildingPartRatingRuleFields
    }
    housingMaintenanceBuildingPartOptions {
      tg {
        value
        label
      }
      kg {
        value
        label
      }
      period {
        value
        label
      }
    }
  }
  ${buildingPartFragment}
  ${buildingPartSummaryFragment}
  ${buildingPartRatingRuleFragment}
  ${housingMaintenanceSectionFragment}
`;

export const HOUSING_MAINTENANCE_SECTION_BUDGET_LINES_QUERY = gql`
  query housingMaintenanceSectionBudgetLines($id: ID!) {
    housingMaintenanceSection(id: $id) {
      ...HousingMaintenanceSectionFields
      displayableBudgetLines {
        ...HousingMaintenanceBudgetLineFields
        buildingPart {
          id
          name
          period
        }
      }
    }
  }
  ${budgetLineFragment}
  ${housingMaintenanceSectionFragment}
`;

export const HOUSING_MAINTENANCE_SECTION_UPDATE_MUTATION = gql`
  mutation HousingMaintenanceSectionUpdate(
    $input: HousingMaintenanceSectionUpdateInput!
  ) {
    housingMaintenanceSectionUpdate(input: $input) {
      id
      ...HousingMaintenanceSectionFields
    }
  }
  ${housingMaintenanceSectionFragment}
`;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { ConstructionLoan } from "~/graphql-operations";
import {
  EditClientBudgetDisplay,
  NewCategory,
  SortableCategoryTypes,
} from "~/invoice/category";
import { EditContractorList, NewContractor } from "~/invoice/contractor";
import { Block } from "~/layout";
import { client } from "~/setup";
import { RequireKeys } from "~/util";
import { BudgetStatus } from "./budget";

interface Props {
  report: RequireKeys<ConstructionLoan, "__typename" | "vatIncluded">;
}

const CategoriesPage: React.FC<Props> = ({ report, report: { id } }) => {
  return (
    <>
      <BudgetStatus report={report} style={{ margin: "0 0 2rem" }} />
      <EditContractorList report={{ id }} />
      <Block>
        <NewContractor report={{ id }} />
      </Block>

      <hr />

      <SortableCategoryTypes report={report} />
      <hr />
      <EditClientBudgetDisplay report={report} />

      <Block>
        <NewCategory report={report} />
      </Block>
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <CategoriesPage {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import invariant from "invariant";
import React from "react";
import styled from "styled-components";
import { useVedlikeholdsKarakterQuery } from "~/graphql-operations";
import { WordLoader } from "~/layout/Loading";
import { Slider } from "~/slider";
import { ShortcodeProps } from ".";

const Container = styled.div`
  margin: 6.25rem 0;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
`;

const SliderWrap = styled.div`
  &:nth-child(n + 2) {
    border-top: 1px solid #f2f2f2;
  }
`;

const VedlikeholdsKarakter: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const {
    loading,
    error,
    data: { report } = {},
  } = useVedlikeholdsKarakterQuery({
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  invariant(report && "cost" in report, "No cost data");
  invariant(report && "buildings" in report, "No buildings data");

  const {
    cost: { score, grade },
    buildings,
  } = report;

  if (!(typeof score === "number" && grade)) return null;

  return (
    <Container>
      {buildings.length > 1 && (
        <SliderWrap>
          <Slider score={score} grade={grade} title="Alle bygg og anlegg" />
        </SliderWrap>
      )}

      {buildings.map(({ id, name, cost: { grade, score } }) => (
        <SliderWrap key={id}>
          <Slider
            score={score}
            grade={grade}
            title={name}
            small={buildings.length > 1}
          />
        </SliderWrap>
      ))}
    </Container>
  );
};

export default VedlikeholdsKarakter;

import React from "react";
import { WordLoader } from "~/layout/Loading";
import { useReportConsultants } from "~/report/member/consultant";
import { ShortcodeProps } from ".";

const KonsulenterUtforendeKonsulenter: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, report } = useReportConsultants({ slug });

  if (error) return <p>Error</p>;
  if (loading) return <WordLoader />;

  const {
    fireConsultants = [],
    constructionConsultants = [],
    plumbingConsultants = [],
    electricalConsultants = [],
  } = report || {};

  return (
    <>
      {fireConsultants.length > 0 && (
        <>
          Brann: {fireConsultants.map(({ name }) => name).join(", ")}
          <br />
        </>
      )}
      {constructionConsultants.length > 0 && (
        <>
          Bygg: {constructionConsultants.map(({ name }) => name).join(", ")}
          <br />
        </>
      )}
      {plumbingConsultants.length > 0 && (
        <>
          VVS: {plumbingConsultants.map(({ name }) => name).join(", ")} <br />
        </>
      )}
      {electricalConsultants.length > 0 && (
        <>
          Elektro: {electricalConsultants.map(({ name }) => name).join(", ")}
          <br />
        </>
      )}
    </>
  );
};

export default KonsulenterUtforendeKonsulenter;

import { nodeAttributeLabel } from "~/node";
import {
  maintenancePeriodOptions,
  maintenancePriorityOptions,
  maintenanceTypeOptions,
} from "./constants";

// export const isMaintenanceType = (
//   maybeMT: string
// ): maybeMT is keyof typeof MaintenanceTypeEnum => {
//   return (
//     Object.values(MaintenanceTypeEnum).indexOf(
//       maybeMT as MaintenanceTypeEnum
//     ) !== -1
//   );
// };

export const maintenancePeriodLabel = nodeAttributeLabel(
  maintenancePeriodOptions
);

export const maintenancePriorityLabel = nodeAttributeLabel(
  maintenancePriorityOptions
);

export const maintenanceTypeLabel = nodeAttributeLabel(maintenanceTypeOptions);

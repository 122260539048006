import { pick } from "lodash";
import React, { useState } from "react";
import { Col, Form as BSForm } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactOnRails from "react-on-rails";
import styled from "styled-components";
import { Form, InlineInput, NumberField, Row, SelectField } from "~/form";
import Block from "~/layout/Block";
import Currency, { formatCurrency } from "~/util/currency";
import {
  BudgetPlan,
  createHousingMaintenanceBudgetPlanMutation,
  EditBudgetPlanToolbar,
} from ".";
import { HousingMaintenanceSection } from "../constants";
import {
  deleteHousingMaintenanceBudgetPlanMutation,
  updateHousingMaintenanceBudgetPlanMutation,
} from "./actions";

interface Props {
  cost: number;
  housingMaintenanceSection: HousingMaintenanceSection;
}

type BudgetPlanFormData = Pick<
  BudgetPlan,
  | "name"
  | "currentBudget"
  | "collectedBudget"
  | "interestRate"
  | "unitTypes"
  | "units"
  | "period"
>;

const unitTypes = [
  {
    value: "apartments",
    label: "Leilligheter",
  },
  {
    value: "areas",
    label: "Eierbrøk",
  },
  {
    value: "m2",
    label: (
      <>
        m<sup>2</sup>
      </>
    ),
  },
];

const defaultBudgetPlan = {
  name: "",
  currentBudget: 0,
  collectedBudget: 0,
  interestRate: undefined,
  unitType: undefined,
  units: undefined,
  period: undefined,
} as Partial<BudgetPlan>;

const calculate = (loanAmount: number, interest: number, years: number) => {
  const monthlyInterest = interest / 12 / 100;
  const totalPayments = years * 12;
  return (
    loanAmount /
    ((Math.pow(1 + monthlyInterest, totalPayments) - 1) /
      (monthlyInterest * Math.pow(1 + monthlyInterest, totalPayments)))
  );
};

const StyledCurrency = styled(Currency)`
  font-family: var(--font-circular);
  font-weight: bold;
`;

const EditBudgetPlan: React.FC<Props> = ({
  cost,
  housingMaintenanceSection,
}) => {
  const [current, setCurrent] = useState<BudgetPlan | null>(null);

  const handleCreate = createHousingMaintenanceBudgetPlanMutation(
    housingMaintenanceSection
  );
  const handleUpdate = updateHousingMaintenanceBudgetPlanMutation();
  const handleDelete = deleteHousingMaintenanceBudgetPlanMutation(
    housingMaintenanceSection
  );

  const formMethods = useForm({
    defaultValues: defaultBudgetPlan,
    mode: "all",
  });

  const {
    register,
    watch,
    getValues,
    reset,
    formState: { isDirty },
  } = formMethods;

  const [
    name,
    currentBudget,
    collectedBudget,
    interestRate,
    unitType,
    units,
    period,
  ] = watch([
    "name",
    "currentBudget",
    "collectedBudget",
    "interestRate",
    "unitType",
    "units",
    "period",
  ]);

  const complete = !!(
    !isNaN(currentBudget || 0) &&
    !isNaN(collectedBudget || 0) &&
    interestRate &&
    unitType &&
    units &&
    period
  );

  const loan = cost - (currentBudget || 0) - (collectedBudget || 0);
  const rate = complete ? (
    calculate(loan, interestRate, period) / units
  ) : (
    <>&mdash;</>
  );

  const onLoad = (budgetPlan: BudgetPlan) => {
    reset(
      pick(budgetPlan, [
        "name",
        "currentBudget",
        "collectedBudget",
        "interestRate",
        "unitType",
        "units",
        "period",
      ])
    );
    setCurrent(budgetPlan);
  };

  const onSave = async () => {
    const updatedBudgetPlan = await handleUpdate({
      ...getValues(),
      id: (current as BudgetPlan).id,
    });
    reset(updatedBudgetPlan);
  };

  const onSaveAs = async (name: string) => {
    const newBudgetPlan = await handleCreate({
      ...getValues(),
      name,
    });
    reset(newBudgetPlan);
    setCurrent(newBudgetPlan);
  };

  const onDelete = async () => {
    await handleDelete((current as BudgetPlan).id);
    setCurrent(null);
    reset(defaultBudgetPlan);
  };

  const onDownload = async () => {
    const data = new FormData();
    data.append(
      "budget_plan",
      JSON.stringify({
        name,
        cost,
        loan,
        currentBudget,
        collectedBudget,
        interestRate,
        unitType,
        units,
        period,
        rate,
      })
    );
    const response = await fetch(
      `../housing_maintenance_sections/${housingMaintenanceSection.id}/download.pdf`,
      {
        method: "POST",
        headers: new Headers({
          "X-CSRF-Token": ReactOnRails.authenticityToken() || "",
        }),
        body: data,
      }
    );

    const disposition = response.headers.get("content-disposition") || "";
    const filenameMatch = disposition.match(/filename="(.*)"/) || [];
    const filename = decodeURIComponent(filenameMatch[1] || "Budget Plan");

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.pdf`;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  };

  return (
    <>
      <Form formMethods={formMethods} horizontal>
        <Row>
          <Col lg="4">
            <InlineInput
              placeholder="Skriv inn budsjettnavnet"
              style={{ fontSize: "1.25rem" }}
              {...register("name")}
            />
          </Col>
          <Col lg="8">
            <EditBudgetPlanToolbar
              housingMaintenanceSection={housingMaintenanceSection}
              name={name}
              onLoad={onLoad}
              onSave={(current && isDirty && onSave) || undefined}
              onSaveAs={(complete && onSaveAs) || undefined}
              onDownload={(complete && onDownload) || undefined}
              onDelete={(current && onDelete) || undefined}
            />
          </Col>
        </Row>
        <Block>
          <Row>
            <Col lg="4">
              <h3>
                <small>Vedlikeholdskostnad: </small>{" "}
                <StyledCurrency>{cost}</StyledCurrency>
              </h3>
            </Col>
            <Col>
              <h3>
                <small>Betalingsplan:</small>{" "}
                <StyledCurrency>{rate}</StyledCurrency>{" "}
                <small>
                  {unitType === "apartments" && "Leil / mnd"}
                  {unitType === "areas" && "Eierandel / mnd"}
                  {unitType === "m2" && (
                    <>
                      m<sup>2</sup> / mnd
                    </>
                  )}
                </small>
              </h3>
            </Col>
            <Col lg="auto"></Col>
          </Row>
          <Row>
            <Col lg="2">
              <NumberField name="currentBudget" label="Vedlikeholdsfond" />
            </Col>
            <Col lg="2" className="pr-4">
              <NumberField name="collectedBudget" label="Egenkapital" />
            </Col>
            <Col>
              <BSForm.Group>
                <BSForm.Label>Lån</BSForm.Label>
                <BSForm.Control readOnly value={formatCurrency(loan)} />
              </BSForm.Group>
            </Col>
            <Col>
              <NumberField name="interestRate" label="Eff. fastrente" />
            </Col>
            <Col>
              <SelectField name="unitType" label="Type" options={unitTypes} />
            </Col>
            <Col>
              <NumberField
                name="units"
                label={(() => {
                  switch (unitType) {
                    case "apartments":
                      return "Leiligheter";
                    case "areas":
                      return "Eierbrøk";
                    case "m2":
                      return (
                        <>
                          m<sup>2</sup>
                        </>
                      );
                    default:
                      return "-";
                  }
                })()}
              />
            </Col>
            <Col lg="2">
              <NumberField name="period" label="Betalingslengde" append="år" />
            </Col>
          </Row>
        </Block>
      </Form>
    </>
  );
};

export default EditBudgetPlan;

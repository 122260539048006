const theme = {
  white: "#fff",
  black: "#000",
  yellow: "#ffcc4f",
  juice: "#d99e38",
  peach: "#fdddc6",
  darkGray: "#374246",
  lightGray: "#d8d8d8",
  smoke: "#f2f2f2",
  titanium: "#7d9198",
  mystic: "#e5ebed",
  eco: "#b2d4bd",
  green: "#80eca4",
  red: "#ff838c",

  fonts: {
    moon: "Moon', sans-serif",
    circular: "'Circular Std', sans-serif",
  },
};

module.exports = theme;

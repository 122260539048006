import { gql } from "@apollo/client";
import { ConstructionLoan } from "~/graphql-operations";
import { ResourceInterface } from "~/node";
import { InvoiceEntryCategorization } from "../entry/categorization";

export interface InvoiceContractor extends ResourceInterface {
  reportId: string;
  report: ConstructionLoan;
  name: string;
  budget: number;
  invoiceEntryCategorizations: InvoiceEntryCategorization[];
}

export const invoiceContractorFragment = gql`
  fragment InvoiceContractorFields on InvoiceContractor {
    id
    name
    budget
  }
`;

export const INVOICE_CONTRACTOR_CREATE_MUTATION = gql`
  mutation InvoiceContractorCreate($input: InvoiceContractorCreateInput!) {
    invoiceContractorCreate(input: $input) {
      errors
      contractor {
        ...InvoiceContractorFields
        report {
          id
          ... on ConstructionLoan {
            contractors {
              ...InvoiceContractorFields
            }
          }
        }
      }
    }
  }
  ${invoiceContractorFragment}
`;

export const INVOICE_CONTRACTOR_UPDATE_MUTATION = gql`
  mutation InvoiceContractorUpdate($input: InvoiceContractorUpdateInput!) {
    invoiceContractorUpdate(input: $input) {
      errors
      contractor {
        ...InvoiceContractorFields
      }
    }
  }
  ${invoiceContractorFragment}
`;

export const INVOICE_CONTRACTOR_DELETE_MUTATION = gql`
  mutation InvoiceContractorDelete($input: InvoiceContractorDeleteInput!) {
    invoiceContractorDelete(input: $input) {
      errors
      contractor {
        report {
          id
          ... on ConstructionLoan {
            contractors {
              ...InvoiceContractorFields
            }
          }
        }
      }
    }
  }
  ${invoiceContractorFragment}
`;

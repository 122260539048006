import React from "react";
import {
  ClientInvoice,
  CurrencyEnum,
  useMigrateClientInvoiceMutation,
} from "~/graphql-operations";
import { IconButton } from "~/layout";
import { EditAffiliateInvoice } from ".";

interface Props {
  invoice: ClientInvoice;
  currencies: CurrencyEnum[];
}

const MigrateClientInvoice: React.FC<Props> = ({
  invoice,
  invoice: { id },
  currencies,
}) => {
  const [migrate] = useMigrateClientInvoiceMutation();

  const migrateContractor = async () => {
    if (confirm("Er du sikker på at du vil migrere denne faktura?")) {
      return migrate({
        variables: {
          input: {
            id,
            to: "contractor",
          },
        },
      });
    }
    return Promise.resolve();
  };

  const migrateExternal = async () => {
    if (confirm("Er du sikker på at du vil migrere denne faktura?")) {
      return migrate({
        variables: {
          input: {
            id,
            to: "external",
          },
        },
      });
    }
    return Promise.resolve();
  };

  return (
    <>
      <EditAffiliateInvoice invoice={invoice} currencies={currencies} />
      <hr />
      <IconButton
        icon="chevronRight"
        variant="primary"
        className="mr-3"
        onClick={migrateContractor}
      >
        Migrate to contractor
      </IconButton>
      <IconButton icon="chevronRight" variant="info" onClick={migrateExternal}>
        Migrate to external
      </IconButton>
    </>
  );
};

export default MigrateClientInvoice;

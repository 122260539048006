import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";

export interface Delivery extends ResourceInterface {
  reportId: string;
  date: string;
  info: string | null;
}

export const deliveryFragment = gql`
  fragment DeliveryFields on Delivery {
    id
    date
    info
  }
`;

export const DELIVERIES_QUERY = gql`
  query ReportDeliveries($slug: ID!) {
    report(slug: $slug) {
      id
      ... on ConstructionLoan {
        deliveries {
          ...DeliveryFields
        }
      }
    }
  }
  ${deliveryFragment}
`;

export const DELIVERY_CREATE_MUTATION = gql`
  mutation DeliveryCreate($input: DeliveryCreateInput!) {
    deliveryCreate(input: $input) {
      delivery {
        ...DeliveryFields
        report {
          id
          ... on ConstructionLoan {
            deliveries {
              ...DeliveryFields
            }
          }
        }
      }
      errors
    }
  }
  ${deliveryFragment}
`;

export const DELIVERY_UPDATE_MUTATION = gql`
  mutation DeliveryUpdate($input: DeliveryUpdateInput!) {
    deliveryUpdate(input: $input) {
      delivery {
        ...DeliveryFields
      }
      errors
    }
  }
  ${deliveryFragment}
`;

export const DELIVERY_DELETE_MUTATION = gql`
  mutation DeliveryDelete($input: DeliveryDeleteInput!) {
    deliveryDelete(input: $input) {
      errors
      delivery {
        id
        report {
          id
          ... on ConstructionLoan {
            deliveries {
              ...DeliveryFields
            }
          }
        }
      }
    }
  }
  ${deliveryFragment}
`;

import styled from "styled-components";

export const LegendEnvironment = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 0.85rem;
`;

export const LegendTitle = styled.li`
  font-weight: 600;
`;

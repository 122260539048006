import React from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { InvoiceCategory } from "~/graphql-operations";
import { DraggableCategory, useSortInvoiceCategories } from ".";

interface Props {
  categories: InvoiceCategory[];
}

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "var(--smoke)" : "transparent",
});

const SortableCategories: React.FC<Props> = ({ categories }) => {
  const sortMutation = useSortInvoiceCategories();

  const onDragEnd: OnDragEndResponder = async ({ source, destination }) => {
    if (!destination) return;

    sortMutation({
      from: categories[source.index],
      to: categories[destination.index],
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="root">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {categories.map((category, index) => (
              <DraggableCategory
                index={index}
                category={category}
                key={category.id}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableCategories;

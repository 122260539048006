import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from ".";
import { WordLoader } from "~/layout/Loading";

const PROPERTY_SECTION_NUMBER_REPORT_QUERY = gql`
  query PropertySectionNumber($slug: ID!) {
    report(slug: $slug) {
      id
      propertySectionNumber
    }
  }
`;

const MatrikkelSnr: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const {
    loading,
    error,
    data,
  } = useQuery(PROPERTY_SECTION_NUMBER_REPORT_QUERY, { variables: { slug } });

  if (error) return <p>Error !</p>;
  const { propertySectionNumber } = data?.report || {};

  return (
    <>
      {loading ? (
        <WordLoader />
      ) : (
        propertySectionNumber && `${propertySectionNumber}`
      )}
    </>
  );
};

export default MatrikkelSnr;

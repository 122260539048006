import React, { useEffect, useState } from "react";
import { Fieldset, FieldsetTitle } from "~/form";
import { Button } from "~/layout";
import AudienceLine from "./AudienceLine";

type Client = {
  company: string;
  name: string;
};

interface Props {
  audience: {
    client: Client | null;
    copies: Client[] | null;
  } | null;
  firstItemStatic?: boolean;
}

const linePresent = (line: Client | null) => {
  if (!line) return false;
  return (
    (line.company && line.company !== "") || (line.name && line.name !== "")
  );
};

const Audience: React.FC<Props> = ({
  audience,
  firstItemStatic = false,
}: Props) => {
  const [audienceState, setAudience] = useState({
    client: audience.client ? audience.client : { company: "", name: "" },
    copies: (audience.copies || []).map((a) => ({
      ...a,
      uid: Math.random(),
    })),
  });
  const { client, copies } = audienceState;

  // remove blank lines on submit hustle
  useEffect(() => {
    const form = document
      .querySelector("#save-report-button")
      .closest("form") as HTMLFormElement;

    const audienceFiltered = {
      client: linePresent(client) ? client : null,
      copies: copies.filter((a) => linePresent(a)),
    };

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      setAudience(audienceFiltered);
      // poor mans wait untill component rerender
      setTimeout(() => form.submit(), 150);
    };

    form.addEventListener("submit", handleSubmit);

    return () => form.removeEventListener("submit", handleSubmit);
  });

  const addCopy = () => {
    setAudience({
      ...audienceState,
      copies: [...copies, { company: "", name: "", uid: Math.random() }],
    });
  };

  const removeCopy = (uid: number) => {
    setAudience({
      ...audienceState,
      copies: copies.filter((c) => c.uid !== uid),
      // copies: [...copies.slice(0, index), ...copies.slice(index + 1)],
    });
  };

  return (
    <>
      <Fieldset style={{ padding: 0 }}>
        <FieldsetTitle>Oppdragsgiver</FieldsetTitle>

        {!client && (
          <input type="hidden" name="report[audience][client]" value="" />
        )}

        {client && (
          <AudienceLine
            line={client}
            param="report[audience][client]"
            onLineChange={(field: string, value: string) => {
              setAudience({
                client: {
                  ...client,
                  [field]: value,
                },
                copies,
              });
            }}
          />
        )}
      </Fieldset>

      <Fieldset style={{ padding: "1rem 0 0 0" }}>
        <FieldsetTitle>Kopi</FieldsetTitle>

        {!copies.length && (
          <input type="hidden" name="report[audience][copies]" value="" />
        )}

        {copies.map((line, index) => (
          <AudienceLine
            key={line.uid}
            line={line}
            param="report[audience][copies][]"
            readOnly={index === 0 && firstItemStatic}
            removeLine={removeCopy}
            onLineChange={(field: string, value: string) => {
              const newCopies = [...copies];
              newCopies[index][field] = value;
              setAudience({
                client,
                copies: newCopies,
              });
            }}
          />
        ))}

        <Button onClick={addCopy}>Add</Button>
      </Fieldset>
    </>
  );
};

export default Audience;

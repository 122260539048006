import { useQuery } from "@apollo/client";
import React from "react";
import { Col, Row } from "react-bootstrap";
import BlockList, { BlockListHeader } from "~/layout/BlockList";
import { Session, useSession } from "~/session";
import {
  RequestSection,
  REQUEST_SECTION_REQUEST_COST_BLOCKS_QUERY,
} from "../constants";
import { RequestCostBlock } from "./constants";
import EditRequestCostBlock from "./EditRequestCostBlock";

interface Props {
  requestSection: RequestSection;
}

const EditRequestCostBlockList: React.FC<Props> = ({
  requestSection: { id },
}) => {
  const { role } = useSession() as Session;
  const { loading, error, data } = useQuery(
    REQUEST_SECTION_REQUEST_COST_BLOCKS_QUERY,
    {
      variables: {
        id: id.toString(),
      },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const canDelete = ["admin", "editor"].includes(role);

  const {
    requestSection,
    requestSection: { requestCostBlocks },
  } = data;

  return (
    <>
      <BlockListHeader>
        <Row>
          <Col lg="2">No.</Col>
          <Col lg={canDelete ? 4 : 6}>Dokumentasjonsbehov</Col>
          <Col lg="2">Avvik</Col>
          <Col lg="2" className="_tar">
            Kostnad
          </Col>
          {canDelete && (
            <Col lg="2" className="_tar">
              Valg
            </Col>
          )}
        </Row>
      </BlockListHeader>
      <BlockList>
        {requestCostBlocks.map((requestCostBlock: RequestCostBlock) => (
          <EditRequestCostBlock
            requestSection={requestSection}
            requestCostBlock={requestCostBlock}
            key={requestCostBlock.id}
            id={`request-cost-block-${requestCostBlock.id}`}
          />
        ))}
      </BlockList>
    </>
  );
};

export default EditRequestCostBlockList;

import React from "react";
import { InputFieldProps, SelectField } from "~/form";
import { AsyncSelectFieldProps, Option } from "~/form/SelectField";
import {
  Report,
  ReportSearchDocument,
  ReportSearchQuery,
  ReportType,
} from "~/graphql-operations";
import { client } from "~/setup";

export interface ReportSelectFieldProps
  extends Omit<AsyncSelectFieldProps<Report>, "async" | "loadOptions"> {
  exclude?: string[];
  companyId?: string[];
  label?: InputFieldProps["label"];
  name: string;
  onChange?: (value: Option<Report>) => void;
  required?: boolean;
  type?: ReportType;
}

type Reports = ReportSearchQuery["reportSearch"];

const reportsToOptions = (reports: Reports, company?: boolean) =>
  reports.map((report) => {
    const {
      id,
      name,
      company: { name: companyName },
    } = report;
    return {
      label: company ? name : `${name} - ${companyName}`,
      value: `${id}`,
      data: report,
    };
  });

const ReportSelectField: React.FC<ReportSelectFieldProps> = ({
  exclude = [],
  companyId,
  name,
  label = "Rapporter",
  type,
  ...rest
}) => {
  const loadOptions = async (query: string) => {
    if (query.trim().length < 2) return [];

    const {
      data: { reportSearch: reports },
    } = await client.query({
      query: ReportSearchDocument,
      variables: { query, companyId, type },
    });

    return reportsToOptions(
      reports.filter((report) => !exclude.includes(report.id)),
      !!companyId
    );
  };

  return (
    <SelectField
      async
      name={name}
      label={label}
      placeholder="Velg rapport"
      loadOptions={loadOptions}
      {...rest}
    />
  );
};

export default ReportSelectField;

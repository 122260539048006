import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  ISO8601DateTime: any;
  JSON: any;
  Url: any;
};

/** Autogenerated return type of AcceptDisclaimer */
export type AcceptDisclaimerPayload = {
  __typename: "AcceptDisclaimerPayload";
  errors: Array<Scalars["String"]>;
  session: Maybe<Session>;
};

/** Autogenerated input type of AddReportToMulti */
export type AddReportToMultiInput = {
  add: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  to: Scalars["ID"];
};

/** Autogenerated return type of AddReportToMulti */
export type AddReportToMultiPayload = {
  __typename: "AddReportToMultiPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  report: Maybe<MultiReport>;
};

export type Assessment = Buildings &
  Consultable &
  Costs &
  Documentation &
  Report &
  Sectionable &
  TextSectionsReport & {
    __typename: "Assessment";
    acl: ReportActionPolicy;
    address: Maybe<Scalars["String"]>;
    associates: Array<Member>;
    buildings: Array<Building>;
    buildingTypeNames: Array<Scalars["String"]>;
    client: Maybe<Member>;
    collaborators: Array<Member>;
    company: Company;
    companyId: Scalars["ID"];
    constructionConsultants: Array<Member>;
    constructionKsConsultants: Array<Member>;
    constructionYear: Maybe<Scalars["String"]>;
    cost: ReportCost;
    costSections: Array<CostSection>;
    createdAt: Scalars["DateTime"];
    documentationControl: Maybe<Scalars["String"]>;
    documentationSection: Maybe<DocumentationSection>;
    ecoConsultants: Array<Member>;
    ecoKsConsultants: Array<Member>;
    electricalConsultants: Array<Member>;
    electricalKsConsultants: Array<Member>;
    fallbackArea: Maybe<Scalars["String"]>;
    featuredImage: Maybe<Image>;
    fireConsultants: Array<Member>;
    fireKsConsultants: Array<Member>;
    id: Scalars["ID"];
    inspectionDate: Maybe<Scalars["Date"]>;
    inspectionDescription: Maybe<Scalars["String"]>;
    lat: Maybe<Scalars["Float"]>;
    legalOwner: Maybe<Scalars["String"]>;
    lng: Maybe<Scalars["Float"]>;
    lockedAt: Maybe<Scalars["Date"]>;
    municipality: Maybe<Scalars["String"]>;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    originalReportDate: Maybe<Scalars["Date"]>;
    partners: Array<Member>;
    plumbingConsultants: Array<Member>;
    plumbingKsConsultants: Array<Member>;
    projectManagers: Array<Member>;
    propertyRegistrationNumber: Maybe<Scalars["String"]>;
    propertySectionNumber: Maybe<Scalars["String"]>;
    propertySubRegistrationNumber: Maybe<Scalars["String"]>;
    publication: AssessmentPublication;
    rentals: Maybe<Array<Rental>>;
    sections: Array<Section>;
    slug: Scalars["String"];
    textSections: Array<TextSection>;
    type: ReportType;
    typeManual: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    /** @deprecated Use client side url helpers instead. */
    urls: Maybe<Urls>;
  };

export type AssessmentPublication = BuildingMaintenance &
  ReportPublication & {
    __typename: "AssessmentPublication";
    address: Maybe<Scalars["String"]>;
    buildings: Array<Building>;
    company: Company;
    featuredImage: Maybe<Image>;
    lat: Maybe<Scalars["Float"]>;
    lng: Maybe<Scalars["Float"]>;
    maintenance: Maintenance;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    report: Maybe<Assessment>;
    sections: Array<PublicationSection>;
  };

export type Attachment = NodeInterface & {
  __typename: "Attachment";
  byteSize: Maybe<Scalars["Int"]>;
  checksum: Maybe<Scalars["String"]>;
  contentType: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  download: Maybe<Scalars["String"]>;
  filename: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  key: Maybe<Scalars["String"]>;
  metadata: Maybe<AttachmentMetadata>;
  signedId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  variants: Maybe<ImageVariants>;
  view: Maybe<Scalars["String"]>;
};

export type AttachmentDownloadArgs = {
  disposition?: InputMaybe<Scalars["String"]>;
};

export type AttachmentMetadata = {
  __typename: "AttachmentMetadata";
  analyzed: Maybe<Scalars["Boolean"]>;
  height: Maybe<Scalars["String"]>;
  identified: Scalars["Boolean"];
  width: Maybe<Scalars["String"]>;
};

export type Breeam = Buildings &
  Consultable &
  Costs &
  Documentation &
  Report &
  Sectionable &
  TextSectionsReport & {
    __typename: "Breeam";
    acl: ReportActionPolicy;
    address: Maybe<Scalars["String"]>;
    associates: Array<Member>;
    buildings: Array<Building>;
    buildingTypeNames: Array<Scalars["String"]>;
    client: Maybe<Member>;
    collaborators: Array<Member>;
    company: Company;
    companyId: Scalars["ID"];
    constructionConsultants: Array<Member>;
    constructionKsConsultants: Array<Member>;
    constructionYear: Maybe<Scalars["String"]>;
    cost: ReportCost;
    costSections: Array<CostSection>;
    createdAt: Scalars["DateTime"];
    documentationControl: Maybe<Scalars["String"]>;
    documentationSection: Maybe<DocumentationSection>;
    ecoConsultants: Array<Member>;
    ecoKsConsultants: Array<Member>;
    electricalConsultants: Array<Member>;
    electricalKsConsultants: Array<Member>;
    fallbackArea: Maybe<Scalars["String"]>;
    featuredImage: Maybe<Image>;
    fireConsultants: Array<Member>;
    fireKsConsultants: Array<Member>;
    id: Scalars["ID"];
    inspectionDate: Maybe<Scalars["Date"]>;
    inspectionDescription: Maybe<Scalars["String"]>;
    lat: Maybe<Scalars["Float"]>;
    legalOwner: Maybe<Scalars["String"]>;
    lng: Maybe<Scalars["Float"]>;
    lockedAt: Maybe<Scalars["Date"]>;
    municipality: Maybe<Scalars["String"]>;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    originalReportDate: Maybe<Scalars["Date"]>;
    partners: Array<Member>;
    plumbingConsultants: Array<Member>;
    plumbingKsConsultants: Array<Member>;
    projectManagers: Array<Member>;
    propertyRegistrationNumber: Maybe<Scalars["String"]>;
    propertySectionNumber: Maybe<Scalars["String"]>;
    propertySubRegistrationNumber: Maybe<Scalars["String"]>;
    publication: BreeamPublication;
    rentals: Maybe<Array<Rental>>;
    sections: Array<Section>;
    slug: Scalars["String"];
    textSections: Array<TextSection>;
    type: ReportType;
    typeManual: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    /** @deprecated Use client side url helpers instead. */
    urls: Maybe<Urls>;
  };

export type BreeamPublication = BuildingMaintenance &
  ReportPublication & {
    __typename: "BreeamPublication";
    address: Maybe<Scalars["String"]>;
    buildings: Array<Building>;
    company: Company;
    featuredImage: Maybe<Image>;
    lat: Maybe<Scalars["Float"]>;
    lng: Maybe<Scalars["Float"]>;
    maintenance: Maintenance;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    report: Maybe<Assessment>;
    sections: Array<PublicationSection>;
  };

export type Building = NodeInterface & {
  __typename: "Building";
  area: Maybe<Scalars["Float"]>;
  autoTotal: Scalars["Boolean"];
  bnr: Maybe<Scalars["String"]>;
  buildingType: Maybe<BuildingType>;
  buildingTypeId: Maybe<Scalars["ID"]>;
  buildingTypeName: Scalars["String"];
  constructionYear: Maybe<Scalars["Int"]>;
  cost: BuildingCost;
  costBlocksCount: Scalars["Int"];
  costLinesCount: Scalars["Int"];
  costPerM2: Maybe<Scalars["Float"]>;
  createdAt: Scalars["DateTime"];
  default: Scalars["Boolean"];
  extraInfo: Maybe<Scalars["String"]>;
  fnr: Maybe<Scalars["String"]>;
  gnr: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Maybe<Scalars["Int"]>;
  parts: Maybe<Array<BuildingPart>>;
  renovation: Maybe<Scalars["String"]>;
  report: Report;
  reportId: Scalars["ID"];
  snr: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type BuildingCost = {
  __typename: "BuildingCost";
  grade: Scalars["String"];
  intervalsTotal: Array<Maybe<Scalars["Float"]>>;
  m2Total: Maybe<Scalars["Float"]>;
  score: Scalars["Float"];
  total: Maybe<Scalars["Float"]>;
  yearlyM2Total: Maybe<Scalars["Float"]>;
};

export type BuildingCostIntervalsTotalArgs = {
  coverage?: InputMaybe<Array<MaintenanceCoverageEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type BuildingCostM2TotalArgs = {
  period?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
};

export type BuildingCostTotalArgs = {
  coverage?: InputMaybe<Array<MaintenanceCoverageEnum>>;
  period?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type BuildingCostYearlyM2TotalArgs = {
  period?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
};

/** Autogenerated input type of BuildingCreate */
export type BuildingCreateInput = {
  area?: InputMaybe<Scalars["Float"]>;
  autoTotal?: InputMaybe<Scalars["Boolean"]>;
  bnr?: InputMaybe<Scalars["String"]>;
  buildingTypeId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  constructionYear?: InputMaybe<Scalars["Int"]>;
  extraInfo?: InputMaybe<Scalars["String"]>;
  fnr?: InputMaybe<Scalars["String"]>;
  gnr?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  order?: InputMaybe<Scalars["Int"]>;
  parts?: InputMaybe<Scalars["JSON"]>;
  renovation?: InputMaybe<Scalars["String"]>;
  reportId: Scalars["ID"];
  snr?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated input type of BuildingDelete */
export type BuildingDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of BuildingDelete */
export type BuildingDeletePayload = {
  __typename: "BuildingDeletePayload";
  building: Maybe<Building>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
};

export type BuildingDeviationPublication = {
  __typename: "BuildingDeviationPublication";
  building: Building;
  description: Maybe<Scalars["String"]>;
  deviations: Array<CostLine>;
  deviationsCount: Scalars["Int"];
  kg: Maybe<Scalars["Int"]>;
  tg: Maybe<Scalars["Int"]>;
  total: Maybe<Scalars["Int"]>;
};

export type BuildingMaintenance = {
  buildings: Array<Building>;
  maintenance: Maintenance;
};

export type BuildingMaintenanceCost = {
  __typename: "BuildingMaintenanceCost";
  documentation: Maybe<Scalars["Float"]>;
  firstYearM2: Maybe<Scalars["Float"]>;
  grade: Maybe<Scalars["String"]>;
  intervals: Array<Maybe<Scalars["Float"]>>;
  score: Maybe<Scalars["Float"]>;
  total: Maybe<Scalars["Float"]>;
};

export type BuildingMaintenanceCostDocumentationArgs = {
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type BuildingMaintenanceCostFirstYearM2Args = {
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type BuildingMaintenanceCostIntervalsArgs = {
  coverage?: InputMaybe<Array<MaintenanceCoverageEnum>>;
  interval?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type BuildingMaintenanceCostTotalArgs = {
  coverage?: InputMaybe<Array<MaintenanceCoverageEnum>>;
  period?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type BuildingMaintenanceSectionCost = {
  __typename: "BuildingMaintenanceSectionCost";
  documentation: Maybe<Scalars["Float"]>;
  firstYearM2: Maybe<Scalars["Float"]>;
  intervals: Array<Maybe<Scalars["Float"]>>;
  total: Maybe<Scalars["Float"]>;
};

export type BuildingMaintenanceSectionCostDocumentationArgs = {
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type BuildingMaintenanceSectionCostFirstYearM2Args = {
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type BuildingMaintenanceSectionCostIntervalsArgs = {
  coverage?: InputMaybe<Array<MaintenanceCoverageEnum>>;
  interval?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
};

export type BuildingMaintenanceSectionCostTotalArgs = {
  coverage?: InputMaybe<Array<MaintenanceCoverageEnum>>;
  period?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
};

export type BuildingMaintenanceStats = {
  __typename: "BuildingMaintenanceStats";
  area: Scalars["Int"];
  cost: BuildingMaintenanceStatsCost;
  count: Scalars["Int"];
};

export type BuildingMaintenanceStatsCost = {
  __typename: "BuildingMaintenanceStatsCost";
  fiveYears: Scalars["Float"];
  immediate: Scalars["Float"];
  missingRegulation: Scalars["Float"];
  total: Scalars["Float"];
};

export type BuildingPart = {
  __typename: "BuildingPart";
  area: Maybe<Scalars["Float"]>;
  floor: Maybe<Scalars["String"]>;
  usage: Scalars["String"];
};

export type BuildingPartOptions = {
  __typename: "BuildingPartOptions";
  kg: Array<OptionInteger>;
  period: Array<OptionString>;
  tg: Array<OptionInteger>;
};

export type BuildingPartOptionsPeriodArgs = {
  maintenanceDuration?: InputMaybe<Scalars["Int"]>;
};

export type Buildings = {
  buildings: Array<Building>;
  buildingTypeNames: Array<Scalars["String"]>;
};

/** Autogenerated input type of BuildingsSort */
export type BuildingsSortInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids: Array<Scalars["ID"]>;
};

export type BuildingType = NodeInterface & {
  __typename: "BuildingType";
  cost: Maybe<Scalars["Float"]>;
  createdAt: Scalars["DateTime"];
  default: Scalars["Boolean"];
  hidden: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of BuildingTypeCreate */
export type BuildingTypeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated input type of BuildingTypesSort */
export type BuildingTypesSortInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids: Array<Scalars["ID"]>;
};

/** Autogenerated input type of BuildingTypeUpdate */
export type BuildingTypeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  default?: InputMaybe<Scalars["Boolean"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated input type of BuildingUpdate */
export type BuildingUpdateInput = {
  area?: InputMaybe<Scalars["Float"]>;
  autoTotal?: InputMaybe<Scalars["Boolean"]>;
  bnr?: InputMaybe<Scalars["String"]>;
  buildingTypeId?: InputMaybe<Scalars["ID"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  constructionYear?: InputMaybe<Scalars["Int"]>;
  extraInfo?: InputMaybe<Scalars["String"]>;
  fnr?: InputMaybe<Scalars["String"]>;
  gnr?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  parts?: InputMaybe<Scalars["JSON"]>;
  renovation?: InputMaybe<Scalars["String"]>;
  snr?: InputMaybe<Scalars["String"]>;
};

export type ClientInvoice = FileVersionable &
  Invoice &
  Node &
  NodeInterface & {
    __typename: "ClientInvoice";
    acl: InvoiceActionPolicy;
    amount: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    currency: CurrencyEnum;
    description: Maybe<Scalars["String"]>;
    dueDate: Maybe<Scalars["Date"]>;
    file: Maybe<Attachment>;
    fileVersions: Maybe<Array<FileVersion>>;
    /** ID of the object. */
    id: Scalars["ID"];
    number: Scalars["String"];
    period: ConstructionLoanPeriod;
    receivedDate: Scalars["Date"];
    sender: Maybe<Scalars["String"]>;
    status: WorkStatusEnum;
    total: Scalars["Float"];
    type: Scalars["String"];
    updatedAt: Scalars["DateTime"];
  };

export type Company = NodeInterface & {
  __typename: "Company";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  logo: Maybe<Image>;
  name: Scalars["String"];
  reports: Maybe<ReportConnection>;
  reportsCount: Scalars["Int"];
  stats: CompanyStats;
  updatedAt: Scalars["DateTime"];
  users: Maybe<UserConnection>;
};

export type CompanyReportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<ReportStatus>;
  term?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<ReportType>;
};

export type CompanyUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<UserRole>;
  status?: InputMaybe<UserStatus>;
  term?: InputMaybe<Scalars["String"]>;
};

export type CompanyCost = {
  __typename: "CompanyCost";
  total: Maybe<Scalars["Float"]>;
};

/** Autogenerated input type of CompanyCreate */
export type CompanyCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

/** Autogenerated return type of CompanyCreate */
export type CompanyCreatePayload = {
  __typename: "CompanyCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  company: Maybe<Company>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of CompanyDelete */
export type CompanyDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of CompanyDelete */
export type CompanyDeletePayload = {
  __typename: "CompanyDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  company: Maybe<Company>;
  errors: Maybe<Array<Scalars["String"]>>;
};

export type CompanyStats = NodeInterface & {
  __typename: "CompanyStats";
  buildingCount: Scalars["Int"];
  cost: Maybe<CompanyCost>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  reportCount: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of CompanyUpdate */
export type CompanyUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  logo?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

/** Autogenerated return type of CompanyUpdate */
export type CompanyUpdatePayload = {
  __typename: "CompanyUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  company: Maybe<Company>;
  errors: Maybe<Array<Scalars["String"]>>;
};

export type ConstructionLoan = Report &
  Sectionable &
  TextSectionsReport & {
    __typename: "ConstructionLoan";
    acl: ReportActionPolicy;
    address: Maybe<Scalars["String"]>;
    associates: Array<Member>;
    balance: Maybe<Scalars["Float"]>;
    budget: ConstructionLoanBudget;
    client: Maybe<Member>;
    clientBudgetGrouped: Scalars["Boolean"];
    collaborators: Array<Member>;
    company: Company;
    companyId: Scalars["ID"];
    constructionWeek: Scalars["Int"];
    constructionYear: Maybe<Scalars["String"]>;
    contractorBudgetPlanLabel: Maybe<Scalars["String"]>;
    contractors: Array<InvoiceContractor>;
    createdAt: Scalars["DateTime"];
    currencies: Array<CurrencyEnum>;
    cycle: Maybe<ConstructionLoanCycle>;
    defaultPayoutInfo: Maybe<Scalars["String"]>;
    deliveries: Array<Delivery>;
    documentationControl: Maybe<Scalars["String"]>;
    documents: Array<Document>;
    documentsComment: Maybe<Scalars["String"]>;
    equity: Maybe<Scalars["Float"]>;
    events: Array<Event>;
    fallbackArea: Maybe<Scalars["String"]>;
    featuredImage: Maybe<Image>;
    id: Scalars["ID"];
    initialAssessment: Maybe<Attachment>;
    inspectionDate: Maybe<Scalars["Date"]>;
    inspectionDescription: Maybe<Scalars["String"]>;
    invoiceCategories: Array<InvoiceCategory>;
    invoices: Array<Invoice>;
    lat: Maybe<Scalars["Float"]>;
    legalOwner: Maybe<Scalars["String"]>;
    links: Maybe<Scalars["String"]>;
    lng: Maybe<Scalars["Float"]>;
    loanAmount: Maybe<Scalars["Float"]>;
    loanInfo: Maybe<Scalars["String"]>;
    lockedAt: Maybe<Scalars["Date"]>;
    municipality: Maybe<Scalars["String"]>;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    originalReportDate: Maybe<Scalars["Date"]>;
    partners: Array<Member>;
    periods: Array<ConstructionLoanPeriod>;
    plotCost: Maybe<Scalars["Int"]>;
    plotCostPartOfLoan: Scalars["Boolean"];
    projectManagers: Array<Member>;
    projectTypeId: Maybe<Scalars["String"]>;
    projectTypeName: Maybe<Scalars["String"]>;
    propertyRegistrationNumber: Maybe<Scalars["String"]>;
    propertySectionNumber: Maybe<Scalars["String"]>;
    propertySubRegistrationNumber: Maybe<Scalars["String"]>;
    rentals: Maybe<Array<Rental>>;
    sections: Array<Section>;
    slug: Scalars["String"];
    startDate: Maybe<Scalars["Date"]>;
    textSections: Array<TextSection>;
    type: ReportType;
    typeManual: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    /** @deprecated Use client side url helpers instead. */
    urls: Maybe<Urls>;
    vatIncluded: Scalars["Boolean"];
  };

export type ConstructionLoanCycleArgs = {
  period?: InputMaybe<Scalars["ID"]>;
};

export type ConstructionLoanBudget = {
  __typename: "ConstructionLoanBudget";
  client: Scalars["Float"];
  enterprise: Scalars["Float"];
  plot: Scalars["Int"];
  total: Scalars["Float"];
};

/** Autogenerated input type of ConstructionLoanCreate */
export type ConstructionLoanCreateInput = {
  balance: Scalars["Float"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyId: Scalars["String"];
  currencies?: InputMaybe<Array<CurrencyEnum>>;
  equity?: InputMaybe<Scalars["Float"]>;
  initialAssessment?: InputMaybe<Scalars["String"]>;
  loanAmount: Scalars["Float"];
  name: Scalars["String"];
  number: Scalars["String"];
  projectTypeId: Scalars["String"];
  startDate?: InputMaybe<Scalars["Date"]>;
  vatIncluded?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of ConstructionLoanCreate */
export type ConstructionLoanCreatePayload = {
  __typename: "ConstructionLoanCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  report: Maybe<ConstructionLoan>;
};

export type ConstructionLoanCycle = {
  __typename: "ConstructionLoanCycle";
  dates: Array<Scalars["Date"]>;
  documents: Array<Document>;
  events: Array<Event>;
  images: Maybe<Array<Attachment>>;
  invoices: Array<Invoice>;
  report: ConstructionLoan;
};

export type ConstructionLoanPeriod = NodeInterface & {
  __typename: "ConstructionLoanPeriod";
  accidents: Scalars["Int"];
  acl: PeriodActionPolicy;
  aggregatedImages: Maybe<Array<Attachment>>;
  createdAt: Scalars["DateTime"];
  currencyDate: Maybe<Scalars["Date"]>;
  currencyLink: Maybe<Scalars["String"]>;
  currencySource: Maybe<Scalars["String"]>;
  dkk: Maybe<Scalars["Float"]>;
  documentsText: Maybe<Scalars["String"]>;
  equity: Maybe<Scalars["Float"]>;
  eur: Maybe<Scalars["Float"]>;
  eventsDescription: Maybe<Scalars["String"]>;
  files: Maybe<Array<Attachment>>;
  financeText: Maybe<Scalars["String"]>;
  full: Scalars["Boolean"];
  id: Scalars["ID"];
  images: Maybe<Array<Attachment>>;
  inspectionText: Maybe<Scalars["String"]>;
  invoicePlanText: Maybe<Scalars["String"]>;
  invoices: Array<Invoice>;
  invoiceText: Maybe<Scalars["String"]>;
  issues: Scalars["Int"];
  loanAmount: Maybe<Scalars["Float"]>;
  name: Maybe<Scalars["String"]>;
  payout: ConstructionLoanPeriodPayout;
  progressText: Maybe<Scalars["String"]>;
  publishable: Scalars["Boolean"];
  published: Scalars["Boolean"];
  publishedAt: Maybe<Scalars["Date"]>;
  qualityText: Maybe<Scalars["String"]>;
  readyForReview: Scalars["Boolean"];
  report: ConstructionLoan;
  safetyInspections: Scalars["Int"];
  sek: Maybe<Scalars["Float"]>;
  shaFiguresText: Maybe<Scalars["String"]>;
  shaGeneralText: Maybe<Scalars["String"]>;
  startedAt: Scalars["Date"];
  status: WorkStatusEnum;
  title: Scalars["String"];
  unpublishable: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  usd: Maybe<Scalars["Float"]>;
};

export type ConstructionLoanPeriodPayout = {
  __typename: "ConstructionLoanPeriodPayout";
  amount: Scalars["Float"];
  files: Maybe<Array<Attachment>>;
  information: Maybe<Scalars["String"]>;
  manualAmount: Maybe<Scalars["Float"]>;
  recommendation: Maybe<Scalars["String"]>;
};

/** Autogenerated input type of ConstructionLoanUpdate */
export type ConstructionLoanUpdateInput = {
  balance?: InputMaybe<Scalars["Float"]>;
  clientBudgetGrouped?: InputMaybe<Scalars["Boolean"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  constructionWeek?: InputMaybe<Scalars["Int"]>;
  contractorBudgetPlanLabel?: InputMaybe<Scalars["String"]>;
  currencies?: InputMaybe<Array<CurrencyEnum>>;
  defaultPayoutInfo?: InputMaybe<Scalars["String"]>;
  documentsComment?: InputMaybe<Scalars["String"]>;
  equity?: InputMaybe<Scalars["Float"]>;
  id: Scalars["ID"];
  initialAssessment?: InputMaybe<Scalars["String"]>;
  links?: InputMaybe<Scalars["String"]>;
  loanAmount?: InputMaybe<Scalars["Float"]>;
  loanInfo?: InputMaybe<Scalars["String"]>;
  plotCost?: InputMaybe<Scalars["Int"]>;
  plotCostPartOfLoan?: InputMaybe<Scalars["Boolean"]>;
  projectTypeId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  vatIncluded?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of ConstructionLoanUpdate */
export type ConstructionLoanUpdatePayload = {
  __typename: "ConstructionLoanUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  report: Maybe<ConstructionLoan>;
};

export type Consultable = {
  constructionConsultants: Array<Member>;
  constructionKsConsultants: Array<Member>;
  ecoConsultants: Array<Member>;
  ecoKsConsultants: Array<Member>;
  electricalConsultants: Array<Member>;
  electricalKsConsultants: Array<Member>;
  fireConsultants: Array<Member>;
  fireKsConsultants: Array<Member>;
  plumbingConsultants: Array<Member>;
  plumbingKsConsultants: Array<Member>;
};

export type ContractorInvoice = FileVersionable &
  Invoice &
  Node &
  NodeInterface & {
    __typename: "ContractorInvoice";
    accordingToPlan: Scalars["Boolean"];
    acl: InvoiceActionPolicy;
    amount: Scalars["Float"];
    contractor: Maybe<InvoiceContractor>;
    contractorId: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    currency: CurrencyEnum;
    deposit: ContractorInvoiceDeposit;
    description: Maybe<Scalars["String"]>;
    dueDate: Maybe<Scalars["Date"]>;
    entries: Maybe<Array<InvoiceEntry>>;
    file: Maybe<Attachment>;
    fileVersions: Maybe<Array<FileVersion>>;
    hasEntries: Scalars["Boolean"];
    /** ID of the object. */
    id: Scalars["ID"];
    multi: Scalars["Boolean"];
    number: Maybe<Scalars["String"]>;
    period: ConstructionLoanPeriod;
    produced: Scalars["Float"];
    receivedDate: Scalars["Date"];
    sender: Maybe<Scalars["String"]>;
    status: WorkStatusEnum;
    total: Scalars["Float"];
    type: Scalars["String"];
    updatedAt: Scalars["DateTime"];
  };

export type ContractorInvoiceDeposit = Node &
  NodeInterface & {
    __typename: "ContractorInvoiceDeposit";
    /** Deposit amount */
    amount: Scalars["Float"];
    /** Deposit auto calculated amount */
    auto: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    /** ID of the object. */
    id: Scalars["ID"];
    /** Deposit manually entered amount */
    manual: Maybe<Scalars["Float"]>;
    /** Deposit amount in NOK */
    total: Scalars["Float"];
    updatedAt: Scalars["DateTime"];
  };

export type CostBlock = NodeInterface & {
  __typename: "CostBlock";
  buildings: Array<Building>;
  buildingsCount: Scalars["Int"];
  buildingsInfo: Array<CostBlockBuildingInfo>;
  cost: Scalars["Int"];
  costBlockTemplate: Maybe<CostBlockTemplate>;
  costBlockTemplateId: Maybe<Scalars["ID"]>;
  costLines: Array<CostLine>;
  costLinesCount: Scalars["Int"];
  costSectionId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  number: Maybe<Scalars["Int"]>;
  order: Maybe<Scalars["Int"]>;
  status: WorkStatusEnum;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type CostBlockBuildingInfo = NodeInterface & {
  __typename: "CostBlockBuildingInfo";
  building: Building;
  buildingId: Scalars["ID"];
  costBlock: CostBlock;
  costBlockId: Scalars["ID"];
  costLines: Array<CostLine>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  hidden: Scalars["Boolean"];
  id: Scalars["ID"];
  kg: Maybe<Scalars["Int"]>;
  order: Scalars["Int"];
  tg: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of CostBlockBuildingInfoCreate */
export type CostBlockBuildingInfoCreateInput = {
  buildingId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  costBlockId: Scalars["ID"];
  hidden?: InputMaybe<Scalars["Boolean"]>;
  kg?: InputMaybe<Scalars["Int"]>;
  tg?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated input type of CostBlockBuildingInfoDelete */
export type CostBlockBuildingInfoDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated input type of CostBlockBuildingInfoUpdate */
export type CostBlockBuildingInfoUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  kg?: InputMaybe<Scalars["Int"]>;
  tg?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated input type of CostBlockCreate */
export type CostBlockCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  costBlockTemplateId: Scalars["ID"];
  costSectionId: Scalars["ID"];
};

/** Autogenerated input type of CostBlockDelete */
export type CostBlockDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CostBlockTemplate = NodeInterface & {
  __typename: "CostBlockTemplate";
  costLineTemplates: Array<CostLineTemplate>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  number: Scalars["Int"];
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type CostLine = NodeInterface & {
  __typename: "CostLine";
  building: Building;
  buildingId: Scalars["ID"];
  cost: Scalars["Int"];
  costBlock: CostBlock;
  costBlockBuildingId: Scalars["ID"];
  costBlockId: Scalars["ID"];
  costCoverage: Maybe<Scalars["String"]>;
  costLineTemplateId: Maybe<Scalars["ID"]>;
  costSection: CostSection;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  image: Maybe<Attachment>;
  kg: Maybe<Scalars["Int"]>;
  note: Maybe<Scalars["String"]>;
  period: Maybe<Scalars["String"]>;
  priority: Maybe<Scalars["String"]>;
  ref: Maybe<Scalars["String"]>;
  showImage: Scalars["Boolean"];
  status: WorkStatusEnum;
  tg: Maybe<Scalars["Int"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of CostLineCreate */
export type CostLineCreateInput = {
  buildingId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  costBlockId: Scalars["ID"];
  costLineTemplateId: Scalars["ID"];
};

/** Autogenerated input type of CostLineDelete */
export type CostLineDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CostLineTemplate = NodeInterface & {
  __typename: "CostLineTemplate";
  costBlockTemplate: CostBlockTemplate;
  costBlockTemplateId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  ref: Scalars["Int"];
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of CostLineUpdate */
export type CostLineUpdateInput = {
  buildingId?: InputMaybe<Scalars["ID"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Int"]>;
  costCoverage?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  image?: InputMaybe<Scalars["String"]>;
  kg?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  period?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["String"]>;
  ref?: InputMaybe<Scalars["String"]>;
  showImage?: InputMaybe<Scalars["Boolean"]>;
  tg?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of CostLineUpdate */
export type CostLineUpdatePayload = {
  __typename: "CostLineUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  costLine: Maybe<CostLine>;
  errors: Maybe<Array<Scalars["String"]>>;
};

export type CostOptions = {
  __typename: "CostOptions";
  coverage: Array<OptionString>;
  kg: Array<OptionInteger>;
  period: Array<OptionString>;
  priority: Array<OptionString>;
  tg: Array<OptionInteger>;
};

export type CostOptionsPeriodArgs = {
  maintenanceDuration?: InputMaybe<Scalars["Int"]>;
};

export type Costs = {
  cost: ReportCost;
  costSections: Array<CostSection>;
};

export type CostSection = Node &
  NodeInterface &
  NodeUrlsInterface &
  Section & {
    __typename: "CostSection";
    costBlocks: Array<CostBlock>;
    costType: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    description: Maybe<Scalars["String"]>;
    hidden: Scalars["Boolean"];
    /** ID of the object. */
    id: Scalars["ID"];
    note: Maybe<Scalars["String"]>;
    primary: Scalars["Boolean"];
    publication: MaintenanceSectionPublication;
    report: Sectionable;
    requestCostLineCount: Scalars["Int"];
    requestCostLines: Maybe<Array<RequestCostLine>>;
    state: CostSectionStateEnum;
    stateMessage: Maybe<Scalars["String"]>;
    status: WorkStatusEnum;
    statusInfo: StatusInfo;
    steps: Maybe<Array<CostSectionStep>>;
    title: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    urls: Maybe<Urls>;
    visible: Scalars["Boolean"];
  };

export enum CostSectionStateEnum {
  /** Section is awaiting consultant action */
  AwaitingConsultant = "awaiting_consultant",
  /** Section is awaiting ks consultant action */
  AwaitingKsConsultant = "awaiting_ks_consultant",
  /** Section is awaiting project manager action */
  AwaitingProjectManager = "awaiting_project_manager",
  /** Section is done */
  Done = "done",
}

/** Autogenerated input type of CostSectionStateFfw */
export type CostSectionStateFfwInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  state: Scalars["String"];
};

/** Autogenerated input type of CostSectionStateRew */
export type CostSectionStateRewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  state: Scalars["String"];
};

export type CostSectionStep = {
  __typename: "CostSectionStep";
  deliveredDate: Maybe<Scalars["Date"]>;
  deliveryDate: Maybe<Scalars["Date"]>;
  ffw: Scalars["Boolean"];
  finished: Scalars["Boolean"];
  members: Array<Member>;
  rew: Scalars["Boolean"];
  state: CostSectionStateEnum;
};

/** Autogenerated input type of CreateClientInvoice */
export type CreateClientInvoiceInput = {
  amount: Scalars["Float"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  currency: CurrencyEnum;
  dueDate: Scalars["Date"];
  file: Scalars["String"];
  number: Scalars["String"];
  periodId: Scalars["ID"];
  sender: Scalars["String"];
};

/** Autogenerated return type of CreateClientInvoice */
export type CreateClientInvoicePayload = {
  __typename: "CreateClientInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  invoice: Maybe<ClientInvoice>;
  status: Scalars["String"];
};

/** Autogenerated input type of CreateConstructionLoanPeriod */
export type CreateConstructionLoanPeriodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  reportId: Scalars["ID"];
  startedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** Autogenerated return type of CreateConstructionLoanPeriod */
export type CreateConstructionLoanPeriodPayload = {
  __typename: "CreateConstructionLoanPeriodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  period: Maybe<ConstructionLoanPeriod>;
};

/** Autogenerated input type of CreateContractorInvoice */
export type CreateContractorInvoiceInput = {
  accordingToPlan: Scalars["Boolean"];
  amount: Scalars["Float"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contractorId: Scalars["String"];
  currency: CurrencyEnum;
  dueDate: Scalars["Date"];
  file?: InputMaybe<Scalars["String"]>;
  multi?: InputMaybe<Scalars["Boolean"]>;
  number: Scalars["String"];
  periodId: Scalars["ID"];
  receivedDate: Scalars["Date"];
};

/** Autogenerated return type of CreateContractorInvoice */
export type CreateContractorInvoicePayload = {
  __typename: "CreateContractorInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  invoice: Maybe<ContractorInvoice>;
  status: Scalars["String"];
};

/** Autogenerated input type of CreateCostBlockTemplate */
export type CreateCostBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  number: Scalars["Int"];
  title: Scalars["String"];
};

/** Autogenerated return type of CreateCostBlockTemplate */
export type CreateCostBlockTemplatePayload = {
  __typename: "CreateCostBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  costBlockTemplate: Maybe<CostBlockTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of CreateCostLineTemplate */
export type CreateCostLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  costBlockTemplateId: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  ref: Scalars["Int"];
  title: Scalars["String"];
};

/** Autogenerated return type of CreateCostLineTemplate */
export type CreateCostLineTemplatePayload = {
  __typename: "CreateCostLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  costLineTemplate: Maybe<CostLineTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of CreateDocumentationBlockTemplate */
export type CreateDocumentationBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

/** Autogenerated return type of CreateDocumentationBlockTemplate */
export type CreateDocumentationBlockTemplatePayload = {
  __typename: "CreateDocumentationBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationBlockTemplate: Maybe<DocumentationBlockTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of CreateDocumentationLineTemplate */
export type CreateDocumentationLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  columns?: InputMaybe<Scalars["Int"]>;
  documentationBlockTemplateId: Scalars["String"];
  number: Scalars["String"];
  title: Scalars["String"];
};

/** Autogenerated return type of CreateDocumentationLineTemplate */
export type CreateDocumentationLineTemplatePayload = {
  __typename: "CreateDocumentationLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationLineTemplate: Maybe<DocumentationLineTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of CreateExternalInvoice */
export type CreateExternalInvoiceInput = {
  amount: Scalars["Float"];
  categoryId: Scalars["String"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  currency: CurrencyEnum;
  dueDate: Scalars["Date"];
  file?: InputMaybe<Scalars["String"]>;
  number: Scalars["String"];
  periodId: Scalars["ID"];
  receivedDate: Scalars["Date"];
  sender: Scalars["String"];
};

/** Autogenerated return type of CreateExternalInvoice */
export type CreateExternalInvoicePayload = {
  __typename: "CreateExternalInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  invoice: Maybe<ExternalInvoice>;
  status: Scalars["String"];
};

/** Autogenerated input type of CreateHousingMaintenanceBuildingPartRatingRule */
export type CreateHousingMaintenanceBuildingPartRatingRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description: Scalars["String"];
  kg?: InputMaybe<Scalars["Int"]>;
  period?: InputMaybe<Scalars["String"]>;
  rating?: InputMaybe<Scalars["Int"]>;
  tg?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated return type of CreateHousingMaintenanceBuildingPartRatingRule */
export type CreateHousingMaintenanceBuildingPartRatingRulePayload = {
  __typename: "CreateHousingMaintenanceBuildingPartRatingRulePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  housingMaintenanceBuildingPartRatingRule: Maybe<HousingMaintenanceBuildingPartRatingRule>;
};

/** Autogenerated input type of CreateInvoiceCategory */
export type CreateInvoiceCategoryInput = {
  budget: Scalars["Int"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  reportId: Scalars["ID"];
  type: InvoiceCategoryTypeEnum;
  vatIncluded: Scalars["Boolean"];
};

/** Autogenerated return type of CreateInvoiceCategory */
export type CreateInvoiceCategoryPayload = {
  __typename: "CreateInvoiceCategoryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceCategory: Maybe<InvoiceCategory>;
};

/** Autogenerated input type of CreateInvoiceEntryCategory */
export type CreateInvoiceEntryCategoryInput = {
  budget: Scalars["Int"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contractorId: Scalars["ID"];
  name: Scalars["String"];
  type: InvoiceCategoryTypeEnum;
  vatIncluded: Scalars["Boolean"];
};

/** Autogenerated return type of CreateInvoiceEntryCategory */
export type CreateInvoiceEntryCategoryPayload = {
  __typename: "CreateInvoiceEntryCategoryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceEntryCategory: Maybe<InvoiceEntryCategory>;
};

/** Autogenerated input type of CreateInvoiceEntry */
export type CreateInvoiceEntryInput = {
  amount: Scalars["Float"];
  categoryId: Scalars["String"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  invoiceId: Scalars["String"];
  vatIncluded?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CreateInvoiceEntry */
export type CreateInvoiceEntryPayload = {
  __typename: "CreateInvoiceEntryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceEntry: Maybe<InvoiceEntry>;
};

/** Autogenerated input type of CreateMultiAssessment */
export type CreateMultiAssessmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyId: Scalars["String"];
  name: Scalars["String"];
  number: Scalars["String"];
  reportIds: Array<Scalars["ID"]>;
};

/** Autogenerated return type of CreateMultiAssessment */
export type CreateMultiAssessmentPayload = {
  __typename: "CreateMultiAssessmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  report: Maybe<MultiAssessment>;
};

/** Autogenerated input type of CreateMultiTDD */
export type CreateMultiTddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyId: Scalars["String"];
  name: Scalars["String"];
  number: Scalars["String"];
  reportIds: Array<Scalars["ID"]>;
};

/** Autogenerated return type of CreateMultiTDD */
export type CreateMultiTddPayload = {
  __typename: "CreateMultiTDDPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  report: Maybe<MultiTdd>;
};

/** Autogenerated input type of CreateParagraph */
export type CreateParagraphInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  textSectionId: Scalars["String"];
  title: Scalars["String"];
};

/** Autogenerated return type of CreateParagraph */
export type CreateParagraphPayload = {
  __typename: "CreateParagraphPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  paragraph: Maybe<Paragraph>;
};

/** Autogenerated input type of CreateRequestCostBlockTemplate */
export type CreateRequestCostBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  internalUse?: InputMaybe<Scalars["Boolean"]>;
  number: Scalars["String"];
  title: Scalars["String"];
};

/** Autogenerated return type of CreateRequestCostBlockTemplate */
export type CreateRequestCostBlockTemplatePayload = {
  __typename: "CreateRequestCostBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestCostBlockTemplate: Maybe<RequestCostBlockTemplate>;
};

/** Autogenerated input type of CreateRequestCostLineTemplate */
export type CreateRequestCostLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  number: Scalars["String"];
  requestCostBlockTemplateId: Scalars["String"];
  title: Scalars["String"];
};

/** Autogenerated return type of CreateRequestCostLineTemplate */
export type CreateRequestCostLineTemplatePayload = {
  __typename: "CreateRequestCostLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestCostLineTemplate: Maybe<RequestCostLineTemplate>;
};

/** Autogenerated input type of CreateRequestLine */
export type CreateRequestLineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  requestLineTemplateId: Scalars["ID"];
  requestSectionId: Scalars["ID"];
};

/** Autogenerated return type of CreateRequestLine */
export type CreateRequestLinePayload = {
  __typename: "CreateRequestLinePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestLine: Maybe<RequestLine>;
};

/** Autogenerated input type of CreateRequestLineTemplate */
export type CreateRequestLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  number: Scalars["String"];
  title: Scalars["String"];
};

/** Autogenerated return type of CreateRequestLineTemplate */
export type CreateRequestLineTemplatePayload = {
  __typename: "CreateRequestLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestLineTemplate: Maybe<RequestLineTemplate>;
};

/** Autogenerated input type of CreateSection */
export type CreateSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  reportId: Scalars["ID"];
  title: Scalars["String"];
  type: Scalars["String"];
};

/** Autogenerated return type of CreateSection */
export type CreateSectionPayload = {
  __typename: "CreateSectionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  section: Maybe<Section>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyIds?: InputMaybe<Array<Scalars["ID"]>>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  partner?: InputMaybe<Scalars["Boolean"]>;
  phone?: InputMaybe<Scalars["String"]>;
  role: UserRole;
  unlockReport?: InputMaybe<Scalars["String"]>;
  workingArea?: InputMaybe<WorkingAreaEnum>;
};

/** Autogenerated return type of CreateUser */
export type CreateUserPayload = {
  __typename: "CreateUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  user: Maybe<User>;
};

export enum CurrencyEnum {
  /** DKK */
  Dkk = "dkk",
  /** EUR */
  Eur = "eur",
  /** NOK */
  Nok = "nok",
  /** SEK */
  Sek = "sek",
  /** USD */
  Usd = "usd",
}

export type CurrencyRates = {
  __typename: "CurrencyRates";
  date: Scalars["Date"];
  dkk: Maybe<Scalars["Float"]>;
  eur: Maybe<Scalars["Float"]>;
  link: Scalars["String"];
  sek: Maybe<Scalars["Float"]>;
  source: Scalars["String"];
  usd: Maybe<Scalars["Float"]>;
};

export type CvLine = {
  __typename: "CVLine";
  endYear: Maybe<Scalars["String"]>;
  hidden: Maybe<Scalars["Boolean"]>;
  startYear: Scalars["String"];
  text: Scalars["String"];
  type: Scalars["String"];
};

export type CvLineAttributes = {
  endYear?: InputMaybe<Scalars["String"]>;
  hidden: Scalars["Boolean"];
  startYear: Scalars["String"];
  text: Scalars["String"];
  type: Scalars["String"];
};

export type CvSection = Node &
  NodeInterface &
  Section & {
    __typename: "CVSection";
    createdAt: Scalars["DateTime"];
    hidden: Scalars["Boolean"];
    /** ID of the object. */
    id: Scalars["ID"];
    primary: Scalars["Boolean"];
    report: Sectionable;
    title: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    visible: Scalars["Boolean"];
  };

/** Autogenerated input type of DeleteConstructionLoanPeriod */
export type DeleteConstructionLoanPeriodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteConstructionLoanPeriod */
export type DeleteConstructionLoanPeriodPayload = {
  __typename: "DeleteConstructionLoanPeriodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  period: Maybe<ConstructionLoanPeriod>;
};

/** Autogenerated input type of DeleteCostBlockTemplate */
export type DeleteCostBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteCostBlockTemplate */
export type DeleteCostBlockTemplatePayload = {
  __typename: "DeleteCostBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  costBlockTemplate: Maybe<CostBlockTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of DeleteCostLineTemplate */
export type DeleteCostLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteCostLineTemplate */
export type DeleteCostLineTemplatePayload = {
  __typename: "DeleteCostLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  costLineTemplate: Maybe<CostLineTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of DeleteDocumentationBlockTemplate */
export type DeleteDocumentationBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteDocumentationBlockTemplate */
export type DeleteDocumentationBlockTemplatePayload = {
  __typename: "DeleteDocumentationBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationBlockTemplate: Maybe<DocumentationBlockTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of DeleteDocumentationLineTemplate */
export type DeleteDocumentationLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteDocumentationLineTemplate */
export type DeleteDocumentationLineTemplatePayload = {
  __typename: "DeleteDocumentationLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationLineTemplate: Maybe<DocumentationLineTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of DeleteFileVersion */
export type DeleteFileVersionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteFileVersion */
export type DeleteFileVersionPayload = {
  __typename: "DeleteFileVersionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  fileVersion: Maybe<FileVersion>;
};

/** Autogenerated input type of DeleteHousingMaintenanceBuildingPartRatingRule */
export type DeleteHousingMaintenanceBuildingPartRatingRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteHousingMaintenanceBuildingPartRatingRule */
export type DeleteHousingMaintenanceBuildingPartRatingRulePayload = {
  __typename: "DeleteHousingMaintenanceBuildingPartRatingRulePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  housingMaintenanceBuildingPartRatingRule: Maybe<HousingMaintenanceBuildingPartRatingRule>;
};

/** Autogenerated input type of DeleteInvoiceCategory */
export type DeleteInvoiceCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteInvoiceCategory */
export type DeleteInvoiceCategoryPayload = {
  __typename: "DeleteInvoiceCategoryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceCategory: Maybe<InvoiceCategory>;
};

/** Autogenerated input type of DeleteInvoiceEntryCategory */
export type DeleteInvoiceEntryCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteInvoiceEntryCategory */
export type DeleteInvoiceEntryCategoryPayload = {
  __typename: "DeleteInvoiceEntryCategoryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceEntryCategory: Maybe<InvoiceEntryCategory>;
};

/** Autogenerated input type of DeleteInvoiceEntry */
export type DeleteInvoiceEntryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteInvoiceEntry */
export type DeleteInvoiceEntryPayload = {
  __typename: "DeleteInvoiceEntryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceEntry: Maybe<InvoiceEntry>;
};

/** Autogenerated input type of DeleteParagraph */
export type DeleteParagraphInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteParagraph */
export type DeleteParagraphPayload = {
  __typename: "DeleteParagraphPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  paragraph: Maybe<Paragraph>;
};

/** Autogenerated input type of DeleteRequestCostBlockTemplate */
export type DeleteRequestCostBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteRequestCostBlockTemplate */
export type DeleteRequestCostBlockTemplatePayload = {
  __typename: "DeleteRequestCostBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestCostBlockTemplate: Maybe<RequestCostBlockTemplate>;
};

/** Autogenerated input type of DeleteRequestCostLineTemplate */
export type DeleteRequestCostLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteRequestCostLineTemplate */
export type DeleteRequestCostLineTemplatePayload = {
  __typename: "DeleteRequestCostLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestCostLineTemplate: Maybe<RequestCostLineTemplate>;
};

/** Autogenerated input type of DeleteRequestLine */
export type DeleteRequestLineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteRequestLine */
export type DeleteRequestLinePayload = {
  __typename: "DeleteRequestLinePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestLine: Maybe<RequestLine>;
};

/** Autogenerated input type of DeleteRequestLineTemplate */
export type DeleteRequestLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeleteRequestLineTemplate */
export type DeleteRequestLineTemplatePayload = {
  __typename: "DeleteRequestLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestLineTemplate: Maybe<RequestLineTemplate>;
};

/** Autogenerated input type of DeleteSection */
export type DeleteSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteSection */
export type DeleteSectionPayload = {
  __typename: "DeleteSectionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  section: Maybe<Section>;
};

/** Autogenerated input type of DeleteUser */
export type DeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteUser */
export type DeleteUserPayload = {
  __typename: "DeleteUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  user: Maybe<User>;
};

export type Delivery = {
  __typename: "Delivery";
  date: Scalars["Date"];
  id: Scalars["ID"];
  info: Maybe<Scalars["String"]>;
  report: Report;
  reportId: Scalars["ID"];
};

/** Autogenerated input type of DeliveryCreate */
export type DeliveryCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  date: Scalars["Date"];
  info?: InputMaybe<Scalars["String"]>;
  reportId: Scalars["String"];
};

/** Autogenerated return type of DeliveryCreate */
export type DeliveryCreatePayload = {
  __typename: "DeliveryCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  delivery: Maybe<Delivery>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of DeliveryDelete */
export type DeliveryDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of DeliveryDelete */
export type DeliveryDeletePayload = {
  __typename: "DeliveryDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  delivery: Maybe<Delivery>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of DeliveryUpdate */
export type DeliveryUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["Date"]>;
  id: Scalars["String"];
  info?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of DeliveryUpdate */
export type DeliveryUpdatePayload = {
  __typename: "DeliveryUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  delivery: Maybe<Delivery>;
  errors: Maybe<Array<Scalars["String"]>>;
};

export type DeviationGroupPublication = {
  __typename: "DeviationGroupPublication";
  buildingDeviations: Array<BuildingDeviationPublication>;
  deviations: Array<CostLine>;
  deviationsCount: Scalars["Int"];
  number: Maybe<Scalars["Int"]>;
  title: Maybe<Scalars["String"]>;
  total: Scalars["Int"];
};

export type Document = FileVersionable &
  Node &
  NodeInterface & {
    __typename: "Document";
    acl: DocumentActionPolicy;
    createdAt: Scalars["DateTime"];
    date: Maybe<Scalars["Date"]>;
    file: Maybe<Attachment>;
    fileVersions: Maybe<Array<FileVersion>>;
    /** ID of the object. */
    id: Scalars["ID"];
    name: Scalars["String"];
    preparedBy: Maybe<Scalars["String"]>;
    receivedDate: Maybe<Scalars["Date"]>;
    report: Report;
    reportId: Scalars["ID"];
    sender: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    visibility: Maybe<Scalars["String"]>;
  };

export type DocumentActionPolicy = {
  __typename: "DocumentActionPolicy";
  delete: Scalars["Boolean"];
  view: Scalars["Boolean"];
};

export type Documentation = {
  documentationSection: Maybe<DocumentationSection>;
};

export type DocumentationBlock = NodeInterface & {
  __typename: "DocumentationBlock";
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  documentationBlockTemplate: Maybe<DocumentationBlockTemplate>;
  documentationBlockTemplateId: Maybe<Scalars["ID"]>;
  documentationLines: Array<DocumentationLine>;
  documentationSection: DocumentationSection;
  documentationSectionId: Scalars["ID"];
  id: Scalars["ID"];
  maxScore: Maybe<Scalars["Int"]>;
  order: Maybe<Scalars["Int"]>;
  score: Maybe<Scalars["Int"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of DocumentationBlockCreate */
export type DocumentationBlockCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  documentationBlockTemplateId: Scalars["ID"];
  documentationSectionId: Scalars["ID"];
};

/** Autogenerated return type of DocumentationBlockCreate */
export type DocumentationBlockCreatePayload = {
  __typename: "DocumentationBlockCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationBlock: Maybe<DocumentationBlock>;
  errors: Array<Scalars["String"]>;
};

/** Autogenerated input type of DocumentationBlockDelete */
export type DocumentationBlockDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DocumentationBlockDelete */
export type DocumentationBlockDeletePayload = {
  __typename: "DocumentationBlockDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationBlock: Maybe<DocumentationBlock>;
  errors: Maybe<Array<Scalars["String"]>>;
};

export type DocumentationBlockTemplate = NodeInterface & {
  __typename: "DocumentationBlockTemplate";
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  documentationBlocks: Array<DocumentationBlock>;
  documentationLineTemplates: Array<DocumentationLineTemplate>;
  id: Scalars["ID"];
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type DocumentationLine = NodeInterface & {
  __typename: "DocumentationLine";
  averageRating: Scalars["Float"];
  columns: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  documentationBlock: DocumentationBlock;
  documentationBlockId: Scalars["Int"];
  documentationLineTemplate: Maybe<DocumentationLineTemplate>;
  documentationLineTemplateId: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  maxScore: Scalars["Int"];
  number: Maybe<Scalars["String"]>;
  rating1: Maybe<Scalars["Int"]>;
  rating1Files: Maybe<Array<Attachment>>;
  rating2: Maybe<Scalars["Int"]>;
  rating2Files: Maybe<Array<Attachment>>;
  rating3: Maybe<Scalars["Int"]>;
  rating3Files: Maybe<Array<Attachment>>;
  score: Scalars["Int"];
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of DocumentationLineCreate */
export type DocumentationLineCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  documentationBlockId: Scalars["ID"];
  documentationLineTemplateId: Scalars["ID"];
};

/** Autogenerated return type of DocumentationLineCreate */
export type DocumentationLineCreatePayload = {
  __typename: "DocumentationLineCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationLine: Maybe<DocumentationLine>;
  errors: Array<Scalars["String"]>;
};

/** Autogenerated input type of DocumentationLineDelete */
export type DocumentationLineDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DocumentationLineDelete */
export type DocumentationLineDeletePayload = {
  __typename: "DocumentationLineDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationLine: Maybe<DocumentationLine>;
  errors: Maybe<Array<Scalars["String"]>>;
};

export type DocumentationLineTemplate = NodeInterface & {
  __typename: "DocumentationLineTemplate";
  columns: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  documentationBlockTemplate: DocumentationBlockTemplate;
  documentationBlockTemplateId: Scalars["ID"];
  documentationLines: Array<DocumentationLine>;
  id: Scalars["ID"];
  number: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of DocumentationLineUpdate */
export type DocumentationLineUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  rating1?: InputMaybe<Scalars["Int"]>;
  rating1Files?: InputMaybe<Array<Scalars["String"]>>;
  rating2?: InputMaybe<Scalars["Int"]>;
  rating2Files?: InputMaybe<Array<Scalars["String"]>>;
  rating3?: InputMaybe<Scalars["Int"]>;
  rating3Files?: InputMaybe<Array<Scalars["String"]>>;
};

/** Autogenerated return type of DocumentationLineUpdate */
export type DocumentationLineUpdatePayload = {
  __typename: "DocumentationLineUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationLine: Maybe<DocumentationLine>;
  errors: Array<Scalars["String"]>;
};

export type DocumentationSection = NodeInterface &
  NodeUrlsInterface &
  Section & {
    __typename: "DocumentationSection";
    createdAt: Scalars["DateTime"];
    creationDate: Maybe<Scalars["Date"]>;
    documentationBlocks: Array<DocumentationBlock>;
    grade: Maybe<Scalars["String"]>;
    hidden: Scalars["Boolean"];
    id: Scalars["ID"];
    primary: Scalars["Boolean"];
    rating: Maybe<Scalars["Int"]>;
    report: Sectionable;
    revisionDate: Maybe<Scalars["Date"]>;
    score: Maybe<Scalars["Float"]>;
    title: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    urls: Maybe<Urls>;
    visible: Scalars["Boolean"];
  };

/** Autogenerated input type of DocumentCreate */
export type DocumentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["Date"]>;
  file?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  preparedBy?: InputMaybe<Scalars["String"]>;
  receivedDate?: InputMaybe<Scalars["Date"]>;
  reportId: Scalars["ID"];
  sender: Scalars["String"];
  visibility?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of DocumentCreate */
export type DocumentCreatePayload = {
  __typename: "DocumentCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  document: Maybe<Document>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of DocumentDelete */
export type DocumentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DocumentDelete */
export type DocumentDeletePayload = {
  __typename: "DocumentDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  document: Maybe<Document>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of DocumentUpdate */
export type DocumentUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["Date"]>;
  file?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  preparedBy?: InputMaybe<Scalars["String"]>;
  receivedDate?: InputMaybe<Scalars["Date"]>;
  sender?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of DocumentUpdate */
export type DocumentUpdatePayload = {
  __typename: "DocumentUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  document: Maybe<Document>;
  errors: Maybe<Array<Scalars["String"]>>;
};

export type Event = NodeInterface & {
  __typename: "Event";
  createdAt: Scalars["DateTime"];
  date: Scalars["Date"];
  description: Scalars["String"];
  id: Scalars["ID"];
  notes: Maybe<Scalars["String"]>;
  place: Scalars["String"];
  report: Report;
  reportId: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
  url: Maybe<Scalars["String"]>;
};

/** Autogenerated input type of EventCreate */
export type EventCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  date: Scalars["Date"];
  description: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  place: Scalars["String"];
  reportId: Scalars["ID"];
  url?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of EventCreate */
export type EventCreatePayload = {
  __typename: "EventCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  event: Maybe<Event>;
};

/** Autogenerated input type of EventDelete */
export type EventDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of EventDelete */
export type EventDeletePayload = {
  __typename: "EventDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  event: Maybe<Event>;
};

/** Autogenerated input type of EventUpdate */
export type EventUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["Date"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
  place?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of EventUpdate */
export type EventUpdatePayload = {
  __typename: "EventUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  event: Maybe<Event>;
};

export type ExternalInvoice = FileVersionable &
  Invoice &
  Node &
  NodeInterface & {
    __typename: "ExternalInvoice";
    acl: InvoiceActionPolicy;
    amount: Scalars["Float"];
    category: Maybe<InvoiceCategory>;
    categoryId: Maybe<Scalars["ID"]>;
    createdAt: Scalars["DateTime"];
    currency: CurrencyEnum;
    description: Maybe<Scalars["String"]>;
    dueDate: Maybe<Scalars["Date"]>;
    file: Maybe<Attachment>;
    fileVersions: Maybe<Array<FileVersion>>;
    /** ID of the object. */
    id: Scalars["ID"];
    number: Maybe<Scalars["String"]>;
    period: ConstructionLoanPeriod;
    receivedDate: Scalars["Date"];
    sender: Maybe<Scalars["String"]>;
    status: WorkStatusEnum;
    total: Scalars["Float"];
    type: Scalars["String"];
    updatedAt: Scalars["DateTime"];
  };

export type FileVersion = Node &
  NodeInterface & {
    __typename: "FileVersion";
    createdAt: Scalars["DateTime"];
    file: Attachment;
    /** ID of the object. */
    id: Scalars["ID"];
    record: Node;
    updatedAt: Scalars["DateTime"];
    user: User;
    userId: Scalars["String"];
  };

export type FileVersionable = {
  file: Maybe<Attachment>;
  fileVersions: Maybe<Array<FileVersion>>;
};

export type FilterInput = {
  company?: InputMaybe<Array<Scalars["ID"]>>;
  query?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<ReportStatus>;
  type?: InputMaybe<Array<ReportType>>;
};

export type HousingAssessment = Report &
  Sectionable &
  TextSectionsReport & {
    __typename: "HousingAssessment";
    acl: ReportActionPolicy;
    address: Maybe<Scalars["String"]>;
    associates: Array<Member>;
    client: Maybe<Member>;
    collaborators: Array<Member>;
    company: Company;
    companyId: Scalars["ID"];
    constructionYear: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    documentationControl: Maybe<Scalars["String"]>;
    fallbackArea: Maybe<Scalars["String"]>;
    featuredImage: Maybe<Image>;
    housingMaintenanceSection: Maybe<HousingMaintenanceSection>;
    id: Scalars["ID"];
    inspectionDate: Maybe<Scalars["Date"]>;
    inspectionDescription: Maybe<Scalars["String"]>;
    lat: Maybe<Scalars["Float"]>;
    legalOwner: Maybe<Scalars["String"]>;
    lng: Maybe<Scalars["Float"]>;
    lockedAt: Maybe<Scalars["Date"]>;
    municipality: Maybe<Scalars["String"]>;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    originalReportDate: Maybe<Scalars["Date"]>;
    partners: Array<Member>;
    projectManagers: Array<Member>;
    propertyRegistrationNumber: Maybe<Scalars["String"]>;
    propertySectionNumber: Maybe<Scalars["String"]>;
    propertySubRegistrationNumber: Maybe<Scalars["String"]>;
    rentals: Maybe<Array<Rental>>;
    sections: Array<Section>;
    slug: Scalars["String"];
    textSections: Array<TextSection>;
    type: ReportType;
    typeManual: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    /** @deprecated Use client side url helpers instead. */
    urls: Maybe<Urls>;
  };

export type HousingMaintenanceBudgetLine = NodeInterface & {
  __typename: "HousingMaintenanceBudgetLine";
  amount: Maybe<Scalars["Int"]>;
  buildingPart: HousingMaintenanceBuildingPart;
  buildingPartId: Scalars["String"];
  cost: Maybe<Scalars["Float"]>;
  costActual: Maybe<Scalars["Int"]>;
  costLineTemplate: Maybe<CostLineTemplate>;
  costLineTemplateId: Maybe<Scalars["String"]>;
  costLineTemplateRef: Scalars["Int"];
  costLineTemplateTitle: Scalars["String"];
  costType: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  files: Maybe<Array<Attachment>>;
  finishedAt: Maybe<Scalars["Date"]>;
  housingMaintenanceSection: HousingMaintenanceSection;
  housingMaintenanceSectionId: Scalars["String"];
  id: Scalars["ID"];
  note: Maybe<Scalars["String"]>;
  status: WorkStatusEnum;
  unit: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Float"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of HousingMaintenanceBudgetLineCreate */
export type HousingMaintenanceBudgetLineCreateInput = {
  buildingPartId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  costLineTemplateId: Scalars["ID"];
};

/** Autogenerated input type of HousingMaintenanceBudgetLineDelete */
export type HousingMaintenanceBudgetLineDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated input type of HousingMaintenanceBudgetLineUpdate */
export type HousingMaintenanceBudgetLineUpdateInput = {
  amount?: InputMaybe<Scalars["Int"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  costActual?: InputMaybe<Scalars["Int"]>;
  costLineTemplateId?: InputMaybe<Scalars["ID"]>;
  costType?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  files?: InputMaybe<Array<Scalars["String"]>>;
  finishedAt?: InputMaybe<Scalars["Date"]>;
  id: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
  unit?: InputMaybe<Scalars["String"]>;
  unitPrice?: InputMaybe<Scalars["Float"]>;
};

export type HousingMaintenanceBudgetPlan = NodeInterface & {
  __typename: "HousingMaintenanceBudgetPlan";
  collectedBudget: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  currentBudget: Scalars["Int"];
  housingMaintenanceSection: HousingMaintenanceSection;
  housingMaintenanceSectionId: Scalars["String"];
  id: Scalars["ID"];
  interestRate: Scalars["Float"];
  name: Scalars["String"];
  period: Scalars["Int"];
  units: Scalars["Int"];
  unitType: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  user: User;
  userId: Scalars["String"];
};

/** Autogenerated input type of HousingMaintenanceBudgetPlanCreate */
export type HousingMaintenanceBudgetPlanCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectedBudget: Scalars["Int"];
  currentBudget: Scalars["Int"];
  housingMaintenanceSectionId: Scalars["ID"];
  interestRate: Scalars["Float"];
  name: Scalars["String"];
  period: Scalars["Int"];
  units: Scalars["Int"];
  unitType: Scalars["String"];
};

/** Autogenerated input type of HousingMaintenanceBudgetPlanDelete */
export type HousingMaintenanceBudgetPlanDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated input type of HousingMaintenanceBudgetPlanUpdate */
export type HousingMaintenanceBudgetPlanUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectedBudget: Scalars["Int"];
  currentBudget: Scalars["Int"];
  id: Scalars["String"];
  interestRate: Scalars["Float"];
  name: Scalars["String"];
  period: Scalars["Int"];
  units: Scalars["Int"];
  unitType: Scalars["String"];
};

export type HousingMaintenanceBuildingPart = NodeInterface & {
  __typename: "HousingMaintenanceBuildingPart";
  budgetLines: Maybe<Array<HousingMaintenanceBudgetLine>>;
  budgetLinesCount: Maybe<Scalars["Int"]>;
  comment: Maybe<Scalars["String"]>;
  cost: Maybe<Scalars["Int"]>;
  costBlockTemplate: Maybe<CostBlockTemplate>;
  costBlockTemplateId: Maybe<Scalars["String"]>;
  costBlockTemplateNumber: Maybe<Scalars["Int"]>;
  costBlockTemplateTitle: Maybe<Scalars["String"]>;
  costEstimate: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  descriptionDeviation: Maybe<Scalars["String"]>;
  files: Maybe<Array<Attachment>>;
  housingMaintenanceSection: HousingMaintenanceSection;
  housingMaintenanceSectionId: Scalars["String"];
  id: Scalars["ID"];
  kg: Maybe<Scalars["Int"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<Scalars["Int"]>;
  period: Maybe<Scalars["String"]>;
  rating: Maybe<Scalars["Int"]>;
  status: Maybe<WorkStatusEnum>;
  tg: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of HousingMaintenanceBuildingPartCreate */
export type HousingMaintenanceBuildingPartCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  costBlockTemplateId: Scalars["ID"];
  housingMaintenanceSectionId: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated input type of HousingMaintenanceBuildingPartDelete */
export type HousingMaintenanceBuildingPartDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type HousingMaintenanceBuildingPartRatingRule = NodeInterface & {
  __typename: "HousingMaintenanceBuildingPartRatingRule";
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  kg: Maybe<Scalars["Int"]>;
  period: Maybe<Scalars["String"]>;
  rating: Maybe<Scalars["Int"]>;
  tg: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of HousingMaintenanceBuildingPartsSort */
export type HousingMaintenanceBuildingPartsSortInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids: Array<Scalars["String"]>;
};

/** Autogenerated input type of HousingMaintenanceBuildingPartUpdate */
export type HousingMaintenanceBuildingPartUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  comment?: InputMaybe<Scalars["String"]>;
  costBlockTemplateId?: InputMaybe<Scalars["ID"]>;
  costEstimate?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  descriptionDeviation?: InputMaybe<Scalars["String"]>;
  files?: InputMaybe<Array<Scalars["String"]>>;
  id: Scalars["String"];
  kg?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  period?: InputMaybe<Scalars["String"]>;
  tg?: InputMaybe<Scalars["Int"]>;
};

export type HousingMaintenanceSection = Section & {
  __typename: "HousingMaintenanceSection";
  actionableBuildingParts: Array<HousingMaintenanceBuildingPart>;
  budgetLines: Maybe<Array<HousingMaintenanceBudgetLine>>;
  budgetPlans: Array<HousingMaintenanceBudgetPlan>;
  buildingParts: Array<HousingMaintenanceBuildingPart>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  displayableBudgetLines: Maybe<Array<HousingMaintenanceBudgetLine>>;
  displayableBuildingParts: Array<HousingMaintenanceBuildingPart>;
  extended: Maybe<Scalars["Boolean"]>;
  hidden: Scalars["Boolean"];
  id: Scalars["ID"];
  note: Maybe<Scalars["String"]>;
  primary: Scalars["Boolean"];
  report: Sectionable;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  visible: Scalars["Boolean"];
};

/** Autogenerated input type of HousingMaintenanceSectionUpdate */
export type HousingMaintenanceSectionUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  extended?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
};

export type Image = {
  __typename: "Image";
  attachment: Maybe<Attachment>;
  url: Scalars["Url"];
};

export type ImageVariants = {
  __typename: "ImageVariants";
  large: Scalars["String"];
  large2x: Scalars["String"];
  original: Scalars["String"];
  small: Scalars["String"];
  small2x: Scalars["String"];
  xlarge: Scalars["String"];
  xlarge2x: Scalars["String"];
};

export type Invoice = {
  acl: InvoiceActionPolicy;
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  currency: CurrencyEnum;
  description: Maybe<Scalars["String"]>;
  dueDate: Maybe<Scalars["Date"]>;
  file: Maybe<Attachment>;
  fileVersions: Maybe<Array<FileVersion>>;
  id: Scalars["ID"];
  number: Maybe<Scalars["String"]>;
  period: ConstructionLoanPeriod;
  receivedDate: Scalars["Date"];
  sender: Maybe<Scalars["String"]>;
  status: WorkStatusEnum;
  total: Scalars["Float"];
  type: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type InvoiceActionPolicy = {
  __typename: "InvoiceActionPolicy";
  delete: Scalars["Boolean"];
  view: Scalars["Boolean"];
};

export type InvoiceCategory = NodeInterface & {
  __typename: "InvoiceCategory";
  budget: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  invoices: Array<Invoice>;
  invoicesCount: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
  report: ConstructionLoan;
  reportId: Scalars["ID"];
  total: Scalars["Int"];
  type: InvoiceCategoryTypeEnum;
  updatedAt: Scalars["DateTime"];
  vatIncluded: Scalars["Boolean"];
};

export enum InvoiceCategoryTypeEnum {
  /** Additional category type */
  Additional = "additional",
  /** Client category type */
  Client = "client",
  /** Engineering category type */
  Engineering = "engineering",
  /** Production category type */
  Production = "production",
}

export type InvoiceContractor = NodeInterface & {
  __typename: "InvoiceContractor";
  budget: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  invoiceEntryCategorizations: Array<InvoiceEntryCategorization>;
  name: Scalars["String"];
  report: ConstructionLoan;
  reportId: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of InvoiceContractorCreate */
export type InvoiceContractorCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  reportId: Scalars["ID"];
};

/** Autogenerated return type of InvoiceContractorCreate */
export type InvoiceContractorCreatePayload = {
  __typename: "InvoiceContractorCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  contractor: Maybe<InvoiceContractor>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of InvoiceContractorDelete */
export type InvoiceContractorDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of InvoiceContractorDelete */
export type InvoiceContractorDeletePayload = {
  __typename: "InvoiceContractorDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  contractor: Maybe<InvoiceContractor>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of InvoiceContractorUpdate */
export type InvoiceContractorUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of InvoiceContractorUpdate */
export type InvoiceContractorUpdatePayload = {
  __typename: "InvoiceContractorUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  contractor: Maybe<InvoiceContractor>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of InvoiceDelete */
export type InvoiceDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of InvoiceDelete */
export type InvoiceDeletePayload = {
  __typename: "InvoiceDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoice: Maybe<Invoice>;
};

export type InvoiceEntry = NodeInterface & {
  __typename: "InvoiceEntry";
  amount: Scalars["Float"];
  category: InvoiceEntryCategory;
  categoryId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  invoice: ContractorInvoice;
  invoiceId: Scalars["ID"];
  total: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
  vatIncluded: Scalars["Boolean"];
};

/** InvoiceEntryCategory or InvoiceEntryCategoryGroup */
export type InvoiceEntryCategorization =
  | InvoiceEntryCategory
  | InvoiceEntryCategoryGroup;

/** Autogenerated input type of InvoiceEntryCategorizationsSort */
export type InvoiceEntryCategorizationsSortInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contractorId: Scalars["String"];
  optimisticResponse: Scalars["String"];
};

/** Autogenerated return type of InvoiceEntryCategorizationsSort */
export type InvoiceEntryCategorizationsSortPayload = {
  __typename: "InvoiceEntryCategorizationsSortPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  contractor: InvoiceContractor;
  errors: Maybe<Array<Scalars["String"]>>;
};

export type InvoiceEntryCategory = NodeInterface & {
  __typename: "InvoiceEntryCategory";
  budget: Scalars["Int"];
  contractor: InvoiceContractor;
  contractorId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  group: Maybe<InvoiceEntryCategoryGroup>;
  groupId: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
  total: Scalars["Int"];
  type: InvoiceCategoryTypeEnum;
  updatedAt: Scalars["DateTime"];
  vatIncluded: Scalars["Boolean"];
};

export type InvoiceEntryCategoryGroup = NodeInterface & {
  __typename: "InvoiceEntryCategoryGroup";
  aggregated: Scalars["Boolean"];
  categories: Array<InvoiceEntryCategory>;
  contractor: InvoiceContractor;
  contractorId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  fixed: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of InvoiceEntryCategoryGroupCreate */
export type InvoiceEntryCategoryGroupCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contractorId: Scalars["ID"];
  name: Scalars["String"];
};

/** Autogenerated return type of InvoiceEntryCategoryGroupCreate */
export type InvoiceEntryCategoryGroupCreatePayload = {
  __typename: "InvoiceEntryCategoryGroupCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceEntryCategoryGroup: Maybe<InvoiceEntryCategoryGroup>;
};

/** Autogenerated input type of InvoiceEntryCategoryGroupDelete */
export type InvoiceEntryCategoryGroupDeleteInput = {
  cascade?: InputMaybe<Scalars["Boolean"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of InvoiceEntryCategoryGroupDelete */
export type InvoiceEntryCategoryGroupDeletePayload = {
  __typename: "InvoiceEntryCategoryGroupDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  invoiceEntryCategoryGroup: Maybe<InvoiceEntryCategoryGroup>;
};

/** Autogenerated input type of InvoiceEntryCategoryGroupUpdate */
export type InvoiceEntryCategoryGroupUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
};

/** Autogenerated return type of InvoiceEntryCategoryGroupUpdate */
export type InvoiceEntryCategoryGroupUpdatePayload = {
  __typename: "InvoiceEntryCategoryGroupUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  invoiceEntryCategoryGroup: Maybe<InvoiceEntryCategoryGroup>;
};

export type Maintenance = {
  __typename: "Maintenance";
  cost: BuildingMaintenanceCost;
  duration: Maybe<Scalars["Int"]>;
  sections: Array<MaintenanceSectionPublication>;
};

export enum MaintenanceCoverageEnum {
  /** Both maintenance coverage */
  Both = "BOTH",
  /** Landlord maintenance coverage */
  Landlord = "LANDLORD",
  /** Other maintenance coverage */
  Other = "OTHER",
  /** Tenant maintenance coverage */
  Tenant = "TENANT",
}

export enum MaintenancePeriodEnum {
  /** 1-5 år maintenance period */
  "1_5Years" = "_1_5_YEARS",
  /** 11-15 år maintenance period */
  "11_15Years" = "_11_15_YEARS",
  /** 16-20 år maintenance period */
  "16_20Years" = "_16_20_YEARS",
  /** 21-25 år maintenance period */
  "21_25Years" = "_21_25_YEARS",
  /** 26-30 år maintenance period */
  "26_30Years" = "_26_30_YEARS",
  /** 6-10 år maintenance period */
  "6_10Years" = "_6_10_YEARS",
  /** Strakstiltak maintenance period */
  Immediate = "IMMEDIATE",
  /** Forskriftsmangel maintenance period */
  MissingRegulation = "MISSING_REGULATION",
  /** - maintenance period */
  None = "NONE",
  /** Reklamasjon maintenance period */
  Reclamation = "RECLAMATION",
  /** IA maintenance period */
  Text = "TEXT",
}

export enum MaintenancePriorityEnum {
  /** High maintenance priority */
  High = "HIGH",
  /** Low maintenance priority */
  Low = "LOW",
  /** Medium maintenance priority */
  Medium = "MEDIUM",
  /** None maintenance priority */
  None = "NONE",
  /** Replacement maintenance priority */
  Replacement = "REPLACEMENT",
}

export type MaintenanceSectionBuildingCost = {
  __typename: "MaintenanceSectionBuildingCost";
  building: Building;
  intervalsTotal: Array<Maybe<Scalars["Float"]>>;
  total: Maybe<Scalars["Int"]>;
};

export type MaintenanceSectionBuildingCostIntervalsTotalArgs = {
  period?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
};

export type MaintenanceSectionBuildingCostTotalArgs = {
  period?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
};

export type MaintenanceSectionPublication = Section & {
  __typename: "MaintenanceSectionPublication";
  buildingCosts: Array<MaintenanceSectionBuildingCost>;
  cost: BuildingMaintenanceSectionCost;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  deviationGroups: Maybe<Array<DeviationGroupPublication>>;
  hidden: Scalars["Boolean"];
  id: Scalars["ID"];
  primary: Scalars["Boolean"];
  report: Sectionable;
  title: Maybe<Scalars["String"]>;
  type: MaintenanceTypeEnum;
  updatedAt: Scalars["DateTime"];
  visible: Scalars["Boolean"];
};

export enum MaintenanceTypeEnum {
  /** Construction maintenance type */
  Construction = "CONSTRUCTION",
  /** Eco maintenance type */
  Eco = "ECO",
  /** Electrical maintenance type */
  Electrical = "ELECTRICAL",
  /** Fire maintenance type */
  Fire = "FIRE",
  /** Plumbing maintenance type */
  Plumbing = "PLUMBING",
}

export type Member = {
  __typename: "Member";
  companyIds: Maybe<Array<Scalars["String"]>>;
  companyName: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  cv: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  membership: Maybe<Membership>;
  membershipId: Scalars["ID"];
  name: Maybe<Scalars["String"]>;
  partner: Scalars["Boolean"];
  permissions: Array<PermissionEnum>;
  phone: Maybe<Scalars["String"]>;
  photo: Maybe<Scalars["Url"]>;
  preferences: Maybe<Scalars["String"]>;
  relation: Scalars["String"];
  report: Maybe<Report>;
  reportId: Scalars["ID"];
  reportSlug: Scalars["String"];
  role: Scalars["String"];
  user: Maybe<User>;
  userId: Scalars["ID"];
  workingArea: Maybe<Scalars["String"]>;
};

export type Membership = {
  __typename: "Membership";
  id: Scalars["ID"];
  permissions: Array<PermissionEnum>;
  preferences: Maybe<Scalars["JSON"]>;
  relation: MembershipRelation;
  report: Report;
  reportId: Scalars["ID"];
  reportSlug: Scalars["String"];
  user: User;
  userId: Scalars["ID"];
};

/** Autogenerated input type of MembershipCreate */
export type MembershipCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<PermissionEnum>>;
  relation: MembershipRelation;
  reportId: Scalars["ID"];
  userId: Scalars["ID"];
};

/** Autogenerated return type of MembershipCreate */
export type MembershipCreatePayload = {
  __typename: "MembershipCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  membership: Maybe<Membership>;
};

/** Autogenerated input type of MembershipDelete */
export type MembershipDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of MembershipDelete */
export type MembershipDeletePayload = {
  __typename: "MembershipDeletePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  membership: Maybe<Membership>;
};

export enum MembershipRelation {
  Accessor = "ACCESSOR",
  Associate = "ASSOCIATE",
  Client = "CLIENT",
  Collaborator = "COLLABORATOR",
  ConstructionConsultant = "CONSTRUCTION_CONSULTANT",
  ConstructionKsConsultant = "CONSTRUCTION_KS_CONSULTANT",
  ElectricalConsultant = "ELECTRICAL_CONSULTANT",
  ElectricalKsConsultant = "ELECTRICAL_KS_CONSULTANT",
  FireConsultant = "FIRE_CONSULTANT",
  FireKsConsultant = "FIRE_KS_CONSULTANT",
  Partner = "PARTNER",
  PlumbingConsultant = "PLUMBING_CONSULTANT",
  PlumbingKsConsultant = "PLUMBING_KS_CONSULTANT",
  ProjectManager = "PROJECT_MANAGER",
}

/** Autogenerated input type of MembershipUpdate */
export type MembershipUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  permissions?: InputMaybe<Array<PermissionEnum>>;
  preferences?: InputMaybe<Scalars["JSON"]>;
};

/** Autogenerated return type of MembershipUpdate */
export type MembershipUpdatePayload = {
  __typename: "MembershipUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  membership: Maybe<Membership>;
};

/** Autogenerated input type of MigrateClientInvoice */
export type MigrateClientInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  to: Scalars["String"];
};

/** Autogenerated return type of MigrateClientInvoice */
export type MigrateClientInvoicePayload = {
  __typename: "MigrateClientInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoice: Maybe<Invoice>;
};

export type MultiAssessment = MultiReport &
  Report &
  Sectionable &
  TextSectionsReport & {
    __typename: "MultiAssessment";
    acl: ReportActionPolicy;
    address: Maybe<Scalars["String"]>;
    associates: Array<Member>;
    client: Maybe<Member>;
    collaborators: Array<Member>;
    company: Company;
    companyId: Scalars["ID"];
    connections: Array<MultiAssessmentConnection>;
    constructionYear: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    documentationControl: Maybe<Scalars["String"]>;
    fallbackArea: Maybe<Scalars["String"]>;
    featuredImage: Maybe<Image>;
    id: Scalars["ID"];
    inspectionDate: Maybe<Scalars["Date"]>;
    inspectionDescription: Maybe<Scalars["String"]>;
    lat: Maybe<Scalars["Float"]>;
    legalOwner: Maybe<Scalars["String"]>;
    lng: Maybe<Scalars["Float"]>;
    lockedAt: Maybe<Scalars["Date"]>;
    municipality: Maybe<Scalars["String"]>;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    originalReportDate: Maybe<Scalars["Date"]>;
    partners: Array<Member>;
    projectManagers: Array<Member>;
    propertyRegistrationNumber: Maybe<Scalars["String"]>;
    propertySectionNumber: Maybe<Scalars["String"]>;
    propertySubRegistrationNumber: Maybe<Scalars["String"]>;
    rentals: Maybe<Array<Rental>>;
    sections: Array<Section>;
    slug: Scalars["String"];
    textSections: Array<TextSection>;
    type: ReportType;
    typeManual: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    /** @deprecated Use client side url helpers instead. */
    urls: Maybe<Urls>;
  };

export type MultiAssessmentConnection = MultiReportConnection &
  Node &
  NodeInterface & {
    __typename: "MultiAssessmentConnection";
    createdAt: Scalars["DateTime"];
    deviations: MultiReportDeviationList;
    /** ID of the object. */
    id: Scalars["ID"];
    name: Scalars["String"];
    report: Assessment;
    riskLevel: MultiReportConnectionRiskLevel;
    updatedAt: Scalars["DateTime"];
  };

/** Report base */
export type MultiReport = {
  acl: ReportActionPolicy;
  address: Maybe<Scalars["String"]>;
  associates: Array<Member>;
  client: Maybe<Member>;
  collaborators: Array<Member>;
  company: Company;
  companyId: Scalars["ID"];
  connections: Array<MultiReportConnection>;
  constructionYear: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  documentationControl: Maybe<Scalars["String"]>;
  fallbackArea: Maybe<Scalars["String"]>;
  featuredImage: Maybe<Image>;
  id: Scalars["ID"];
  inspectionDate: Maybe<Scalars["Date"]>;
  inspectionDescription: Maybe<Scalars["String"]>;
  lat: Maybe<Scalars["Float"]>;
  legalOwner: Maybe<Scalars["String"]>;
  lng: Maybe<Scalars["Float"]>;
  lockedAt: Maybe<Scalars["Date"]>;
  municipality: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  originalReportDate: Maybe<Scalars["Date"]>;
  partners: Array<Member>;
  projectManagers: Array<Member>;
  propertyRegistrationNumber: Maybe<Scalars["String"]>;
  propertySectionNumber: Maybe<Scalars["String"]>;
  propertySubRegistrationNumber: Maybe<Scalars["String"]>;
  rentals: Maybe<Array<Rental>>;
  sections: Array<Section>;
  slug: Scalars["String"];
  textSections: Array<TextSection>;
  type: ReportType;
  typeManual: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  /** @deprecated Use client side url helpers instead. */
  urls: Maybe<Urls>;
};

/** An object with an ID. */
export type MultiReportConnection = {
  deviations: MultiReportDeviationList;
  /** ID of the object. */
  id: Scalars["ID"];
  name: Scalars["String"];
  report: Report;
  riskLevel: MultiReportConnectionRiskLevel;
};

export enum MultiReportConnectionRiskLevel {
  /** High risk level */
  High = "HIGH",
  /** Low risk level */
  Low = "LOW",
  /** Medium risk level */
  Medium = "MEDIUM",
}

export type MultiReportDeviation = Node &
  NodeInterface & {
    __typename: "MultiReportDeviation";
    buildingName: Scalars["String"];
    cost: Scalars["Int"];
    coverage: Maybe<MaintenanceCoverageEnum>;
    createdAt: Scalars["DateTime"];
    highlighted: Scalars["Boolean"];
    /** ID of the object. */
    id: Scalars["ID"];
    kg: Maybe<Scalars["Int"]>;
    period: Maybe<MaintenancePeriodEnum>;
    priority: Maybe<MaintenancePriorityEnum>;
    ref: Maybe<Scalars["String"]>;
    tg: Maybe<Scalars["Int"]>;
    title: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
  };

export type MultiReportDeviationList = {
  __typename: "MultiReportDeviationList";
  all: Array<MultiReportDeviation>;
  count: Scalars["Int"];
};

/** Autogenerated return type of MultiReportMutations */
export type MultiReportMutationsPayload = {
  __typename: "MultiReportMutationsPayload";
  addReport: AddReportToMultiPayload;
  removeReport: RemoveReportFromMultiPayload;
  setRiskLevel: SetMultiReportConnectionRiskLevelPayload;
  sortConnections: SortMultiReportConnectionsPayload;
  toggleDeviation: ToggleMultiDeviationPayload;
};

/** Autogenerated return type of MultiReportMutations */
export type MultiReportMutationsPayloadAddReportArgs = {
  input: AddReportToMultiInput;
};

/** Autogenerated return type of MultiReportMutations */
export type MultiReportMutationsPayloadRemoveReportArgs = {
  input: RemoveReportFromMultiInput;
};

/** Autogenerated return type of MultiReportMutations */
export type MultiReportMutationsPayloadSetRiskLevelArgs = {
  input: SetMultiReportConnectionRiskLevelInput;
};

/** Autogenerated return type of MultiReportMutations */
export type MultiReportMutationsPayloadSortConnectionsArgs = {
  input: SortMultiReportConnectionsInput;
};

/** Autogenerated return type of MultiReportMutations */
export type MultiReportMutationsPayloadToggleDeviationArgs = {
  input: ToggleMultiDeviationInput;
};

export type MultiTdd = MultiReport &
  Report &
  Sectionable &
  TextSectionsReport & {
    __typename: "MultiTDD";
    acl: ReportActionPolicy;
    address: Maybe<Scalars["String"]>;
    associates: Array<Member>;
    client: Maybe<Member>;
    collaborators: Array<Member>;
    company: Company;
    companyId: Scalars["ID"];
    connections: Array<MultiTddConnection>;
    constructionYear: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    documentationControl: Maybe<Scalars["String"]>;
    fallbackArea: Maybe<Scalars["String"]>;
    featuredImage: Maybe<Image>;
    id: Scalars["ID"];
    inspectionDate: Maybe<Scalars["Date"]>;
    inspectionDescription: Maybe<Scalars["String"]>;
    lat: Maybe<Scalars["Float"]>;
    legalOwner: Maybe<Scalars["String"]>;
    lng: Maybe<Scalars["Float"]>;
    lockedAt: Maybe<Scalars["Date"]>;
    municipality: Maybe<Scalars["String"]>;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    originalReportDate: Maybe<Scalars["Date"]>;
    partners: Array<Member>;
    projectManagers: Array<Member>;
    propertyRegistrationNumber: Maybe<Scalars["String"]>;
    propertySectionNumber: Maybe<Scalars["String"]>;
    propertySubRegistrationNumber: Maybe<Scalars["String"]>;
    rentals: Maybe<Array<Rental>>;
    sections: Array<Section>;
    slug: Scalars["String"];
    textSections: Array<TextSection>;
    type: ReportType;
    typeManual: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    /** @deprecated Use client side url helpers instead. */
    urls: Maybe<Urls>;
  };

export type MultiTddConnection = MultiReportConnection &
  Node &
  NodeInterface & {
    __typename: "MultiTDDConnection";
    createdAt: Scalars["DateTime"];
    deviations: MultiReportDeviationList;
    /** ID of the object. */
    id: Scalars["ID"];
    name: Scalars["String"];
    report: Tdd;
    riskLevel: MultiReportConnectionRiskLevel;
    updatedAt: Scalars["DateTime"];
  };

export type Mutation = {
  __typename: "Mutation";
  buildingCreate: Maybe<Building>;
  buildingDelete: Maybe<BuildingDeletePayload>;
  buildingsSort: Maybe<Array<Building>>;
  buildingTypeCreate: Maybe<BuildingType>;
  buildingTypesSort: Maybe<Array<BuildingType>>;
  buildingTypeUpdate: Maybe<BuildingType>;
  buildingUpdate: Maybe<Building>;
  companyCreate: Maybe<CompanyCreatePayload>;
  companyDelete: Maybe<CompanyDeletePayload>;
  companyUpdate: Maybe<CompanyUpdatePayload>;
  constructionLoanCreate: Maybe<ConstructionLoanCreatePayload>;
  constructionLoanPeriods: Maybe<PeriodMutationsPayload>;
  constructionLoanUpdate: Maybe<ConstructionLoanUpdatePayload>;
  costBlockBuildingInfoCreate: Maybe<CostBlockBuildingInfo>;
  costBlockBuildingInfoDelete: Maybe<CostBlockBuildingInfo>;
  costBlockBuildingInfoUpdate: Maybe<CostBlockBuildingInfo>;
  costBlockCreate: Maybe<CostBlock>;
  costBlockDelete: Maybe<CostBlock>;
  costLineCreate: Maybe<CostLine>;
  costLineDelete: Maybe<CostLine>;
  costLineUpdate: Maybe<CostLineUpdatePayload>;
  costSectionStateFfw: Maybe<CostSection>;
  costSectionStateRew: Maybe<CostSection>;
  createClientInvoice: Maybe<CreateClientInvoicePayload>;
  createConstructionLoanPeriod: Maybe<CreateConstructionLoanPeriodPayload>;
  createContractorInvoice: Maybe<CreateContractorInvoicePayload>;
  createCostBlockTemplate: Maybe<CreateCostBlockTemplatePayload>;
  createCostLineTemplate: Maybe<CreateCostLineTemplatePayload>;
  createDocumentationBlockTemplate: Maybe<CreateDocumentationBlockTemplatePayload>;
  createDocumentationLineTemplate: Maybe<CreateDocumentationLineTemplatePayload>;
  createExternalInvoice: Maybe<CreateExternalInvoicePayload>;
  createHousingMaintenanceBuildingPartRatingRule: Maybe<CreateHousingMaintenanceBuildingPartRatingRulePayload>;
  createInvoiceCategory: Maybe<CreateInvoiceCategoryPayload>;
  createInvoiceEntry: Maybe<CreateInvoiceEntryPayload>;
  createInvoiceEntryCategory: Maybe<CreateInvoiceEntryCategoryPayload>;
  createMultiAssessment: Maybe<CreateMultiAssessmentPayload>;
  createMultiTDD: Maybe<CreateMultiTddPayload>;
  createRequestCostBlockTemplate: Maybe<CreateRequestCostBlockTemplatePayload>;
  createRequestCostLineTemplate: Maybe<CreateRequestCostLineTemplatePayload>;
  createRequestLineTemplate: Maybe<CreateRequestLineTemplatePayload>;
  createUser: Maybe<CreateUserPayload>;
  deleteConstructionLoanPeriod: Maybe<DeleteConstructionLoanPeriodPayload>;
  deleteCostBlockTemplate: Maybe<DeleteCostBlockTemplatePayload>;
  deleteCostLineTemplate: Maybe<DeleteCostLineTemplatePayload>;
  deleteDocumentationBlockTemplate: Maybe<DeleteDocumentationBlockTemplatePayload>;
  deleteDocumentationLineTemplate: Maybe<DeleteDocumentationLineTemplatePayload>;
  deleteFileVersion: Maybe<DeleteFileVersionPayload>;
  deleteHousingMaintenanceBuildingPartRatingRule: Maybe<DeleteHousingMaintenanceBuildingPartRatingRulePayload>;
  deleteInvoice: Maybe<InvoiceDeletePayload>;
  deleteInvoiceCategory: Maybe<DeleteInvoiceCategoryPayload>;
  deleteInvoiceEntry: Maybe<DeleteInvoiceEntryPayload>;
  deleteInvoiceEntryCategory: Maybe<DeleteInvoiceEntryCategoryPayload>;
  deleteRequestCostBlockTemplate: Maybe<DeleteRequestCostBlockTemplatePayload>;
  deleteRequestCostLineTemplate: Maybe<DeleteRequestCostLineTemplatePayload>;
  deleteRequestLineTemplate: Maybe<DeleteRequestLineTemplatePayload>;
  deleteUser: Maybe<DeleteUserPayload>;
  deliveryCreate: Maybe<DeliveryCreatePayload>;
  deliveryDelete: Maybe<DeliveryDeletePayload>;
  deliveryUpdate: Maybe<DeliveryUpdatePayload>;
  documentationBlockCreate: Maybe<DocumentationBlockCreatePayload>;
  documentationBlockDelete: Maybe<DocumentationBlockDeletePayload>;
  documentationLineCreate: Maybe<DocumentationLineCreatePayload>;
  documentationLineDelete: Maybe<DocumentationLineDeletePayload>;
  documentationLineUpdate: Maybe<DocumentationLineUpdatePayload>;
  documentCreate: Maybe<DocumentCreatePayload>;
  documentDelete: Maybe<DocumentDeletePayload>;
  documentUpdate: Maybe<DocumentUpdatePayload>;
  eventCreate: Maybe<EventCreatePayload>;
  eventDelete: Maybe<EventDeletePayload>;
  eventUpdate: Maybe<EventUpdatePayload>;
  housingMaintenanceBudgetLineCreate: Maybe<HousingMaintenanceBudgetLine>;
  housingMaintenanceBudgetLineDelete: Maybe<HousingMaintenanceBudgetLine>;
  housingMaintenanceBudgetLineUpdate: Maybe<HousingMaintenanceBudgetLine>;
  housingMaintenanceBudgetPlanCreate: Maybe<HousingMaintenanceBudgetPlan>;
  housingMaintenanceBudgetPlanDelete: Maybe<HousingMaintenanceBudgetPlan>;
  housingMaintenanceBudgetPlanUpdate: Maybe<HousingMaintenanceBudgetPlan>;
  housingMaintenanceBuildingPartCreate: Maybe<HousingMaintenanceBuildingPart>;
  housingMaintenanceBuildingPartDelete: Maybe<HousingMaintenanceBuildingPart>;
  housingMaintenanceBuildingPartsSort: Maybe<
    Array<HousingMaintenanceBuildingPart>
  >;
  housingMaintenanceBuildingPartUpdate: Maybe<HousingMaintenanceBuildingPart>;
  housingMaintenanceSectionUpdate: Maybe<HousingMaintenanceSection>;
  invoiceContractorCreate: Maybe<InvoiceContractorCreatePayload>;
  invoiceContractorDelete: Maybe<InvoiceContractorDeletePayload>;
  invoiceContractorUpdate: Maybe<InvoiceContractorUpdatePayload>;
  invoiceEntryCategorizationsSort: Maybe<InvoiceEntryCategorizationsSortPayload>;
  invoiceEntryCategoryGroupCreate: Maybe<InvoiceEntryCategoryGroupCreatePayload>;
  invoiceEntryCategoryGroupDelete: Maybe<InvoiceEntryCategoryGroupDeletePayload>;
  invoiceEntryCategoryGroupUpdate: Maybe<InvoiceEntryCategoryGroupUpdatePayload>;
  membershipCreate: Maybe<MembershipCreatePayload>;
  membershipDelete: Maybe<MembershipDeletePayload>;
  membershipUpdate: Maybe<MembershipUpdatePayload>;
  migrateClientInvoice: Maybe<MigrateClientInvoicePayload>;
  publishConstructionLoanPeriod: Maybe<PublishConstructionLoanPeriodPayload>;
  regeneratePdf: Maybe<RegeneratePdfPayload>;
  reportAddMember: Maybe<Report>;
  reportPublish: Maybe<Report>;
  reportRemoveClient: Maybe<Report>;
  reportRemoveMember: Maybe<Report>;
  /** Report mutations namespace */
  reports: ReportMutationsPayload;
  reportSetClient: Maybe<Report>;
  reportSetConsultants: Maybe<Report>;
  reportSetPartners: Maybe<Report>;
  reportSetProjectManagers: Maybe<Report>;
  reportUpdateMember: Maybe<Report>;
  requestCostBlockCreate: Maybe<RequestCostBlock>;
  requestCostBlockDelete: Maybe<RequestCostBlock>;
  requestCostLineCreate: Maybe<RequestCostLine>;
  requestCostLineDelete: Maybe<RequestCostLine>;
  requestCostLineUpdate: Maybe<RequestCostLine>;
  requestSectionUpdate: Maybe<RequestSectionUpdatePayload>;
  resetUserPassword: Maybe<ResetUserPasswordPayload>;
  sections: SectionMutationsPayload;
  session: Maybe<SessionMutationsPayload>;
  setReportCoordinates: Maybe<SetReportCoordinatesPayload>;
  sortInvoiceCategory: Maybe<SortInvoiceCategoryPayload>;
  unpublishConstructionLoanPeriod: Maybe<UnpublishConstructionLoanPeriodPayload>;
  updateAffiliateInvoice: Maybe<UpdateAffiliateInvoicePayload>;
  updateConstructionLoanPeriod: Maybe<UpdateConstructionLoanPeriodPayload>;
  updateContractorInvoice: Maybe<UpdateContractorInvoicePayload>;
  updateCostBlockTemplate: Maybe<UpdateCostBlockTemplatePayload>;
  updateCostLineTemplate: Maybe<UpdateCostLineTemplatePayload>;
  updateDocumentationBlockTemplate: Maybe<UpdateDocumentationBlockTemplatePayload>;
  updateDocumentationLineTemplate: Maybe<UpdateDocumentationLineTemplatePayload>;
  updateExternalInvoice: Maybe<UpdateExternalInvoicePayload>;
  updateHousingMaintenanceBuildingPartRatingRule: Maybe<UpdateHousingMaintenanceBuildingPartRatingRulePayload>;
  updateInvoiceCategory: Maybe<UpdateInvoiceCategoryPayload>;
  updateInvoiceEntry: Maybe<UpdateInvoiceEntryPayload>;
  updateInvoiceEntryCategory: Maybe<UpdateInvoiceEntryCategoryPayload>;
  updateMembershipRelation: Maybe<UpdateMembershipRelationPayload>;
  updatePeriodLoan: Maybe<UpdatePeriodLoanPayload>;
  updateRequestCostBlockTemplate: Maybe<UpdateRequestCostBlockTemplatePayload>;
  updateRequestCostLineTemplate: Maybe<UpdateRequestCostLineTemplatePayload>;
  updateRequestLineTemplate: Maybe<UpdateRequestLineTemplatePayload>;
  updateUser: Maybe<UpdateUserPayload>;
};

export type MutationBuildingCreateArgs = {
  input: BuildingCreateInput;
};

export type MutationBuildingDeleteArgs = {
  input: BuildingDeleteInput;
};

export type MutationBuildingsSortArgs = {
  input: BuildingsSortInput;
};

export type MutationBuildingTypeCreateArgs = {
  input: BuildingTypeCreateInput;
};

export type MutationBuildingTypesSortArgs = {
  input: BuildingTypesSortInput;
};

export type MutationBuildingTypeUpdateArgs = {
  input: BuildingTypeUpdateInput;
};

export type MutationBuildingUpdateArgs = {
  input: BuildingUpdateInput;
};

export type MutationCompanyCreateArgs = {
  input: CompanyCreateInput;
};

export type MutationCompanyDeleteArgs = {
  input: CompanyDeleteInput;
};

export type MutationCompanyUpdateArgs = {
  input: CompanyUpdateInput;
};

export type MutationConstructionLoanCreateArgs = {
  input: ConstructionLoanCreateInput;
};

export type MutationConstructionLoanUpdateArgs = {
  input: ConstructionLoanUpdateInput;
};

export type MutationCostBlockBuildingInfoCreateArgs = {
  input: CostBlockBuildingInfoCreateInput;
};

export type MutationCostBlockBuildingInfoDeleteArgs = {
  input: CostBlockBuildingInfoDeleteInput;
};

export type MutationCostBlockBuildingInfoUpdateArgs = {
  input: CostBlockBuildingInfoUpdateInput;
};

export type MutationCostBlockCreateArgs = {
  input: CostBlockCreateInput;
};

export type MutationCostBlockDeleteArgs = {
  input: CostBlockDeleteInput;
};

export type MutationCostLineCreateArgs = {
  input: CostLineCreateInput;
};

export type MutationCostLineDeleteArgs = {
  input: CostLineDeleteInput;
};

export type MutationCostLineUpdateArgs = {
  input: CostLineUpdateInput;
};

export type MutationCostSectionStateFfwArgs = {
  input: CostSectionStateFfwInput;
};

export type MutationCostSectionStateRewArgs = {
  input: CostSectionStateRewInput;
};

export type MutationCreateClientInvoiceArgs = {
  input: CreateClientInvoiceInput;
};

export type MutationCreateConstructionLoanPeriodArgs = {
  input: CreateConstructionLoanPeriodInput;
};

export type MutationCreateContractorInvoiceArgs = {
  input: CreateContractorInvoiceInput;
};

export type MutationCreateCostBlockTemplateArgs = {
  input: CreateCostBlockTemplateInput;
};

export type MutationCreateCostLineTemplateArgs = {
  input: CreateCostLineTemplateInput;
};

export type MutationCreateDocumentationBlockTemplateArgs = {
  input: CreateDocumentationBlockTemplateInput;
};

export type MutationCreateDocumentationLineTemplateArgs = {
  input: CreateDocumentationLineTemplateInput;
};

export type MutationCreateExternalInvoiceArgs = {
  input: CreateExternalInvoiceInput;
};

export type MutationCreateHousingMaintenanceBuildingPartRatingRuleArgs = {
  input: CreateHousingMaintenanceBuildingPartRatingRuleInput;
};

export type MutationCreateInvoiceCategoryArgs = {
  input: CreateInvoiceCategoryInput;
};

export type MutationCreateInvoiceEntryArgs = {
  input: CreateInvoiceEntryInput;
};

export type MutationCreateInvoiceEntryCategoryArgs = {
  input: CreateInvoiceEntryCategoryInput;
};

export type MutationCreateMultiAssessmentArgs = {
  input: CreateMultiAssessmentInput;
};

export type MutationCreateMultiTddArgs = {
  input: CreateMultiTddInput;
};

export type MutationCreateRequestCostBlockTemplateArgs = {
  input: CreateRequestCostBlockTemplateInput;
};

export type MutationCreateRequestCostLineTemplateArgs = {
  input: CreateRequestCostLineTemplateInput;
};

export type MutationCreateRequestLineTemplateArgs = {
  input: CreateRequestLineTemplateInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteConstructionLoanPeriodArgs = {
  input: DeleteConstructionLoanPeriodInput;
};

export type MutationDeleteCostBlockTemplateArgs = {
  input: DeleteCostBlockTemplateInput;
};

export type MutationDeleteCostLineTemplateArgs = {
  input: DeleteCostLineTemplateInput;
};

export type MutationDeleteDocumentationBlockTemplateArgs = {
  input: DeleteDocumentationBlockTemplateInput;
};

export type MutationDeleteDocumentationLineTemplateArgs = {
  input: DeleteDocumentationLineTemplateInput;
};

export type MutationDeleteFileVersionArgs = {
  input: DeleteFileVersionInput;
};

export type MutationDeleteHousingMaintenanceBuildingPartRatingRuleArgs = {
  input: DeleteHousingMaintenanceBuildingPartRatingRuleInput;
};

export type MutationDeleteInvoiceArgs = {
  input: InvoiceDeleteInput;
};

export type MutationDeleteInvoiceCategoryArgs = {
  input: DeleteInvoiceCategoryInput;
};

export type MutationDeleteInvoiceEntryArgs = {
  input: DeleteInvoiceEntryInput;
};

export type MutationDeleteInvoiceEntryCategoryArgs = {
  input: DeleteInvoiceEntryCategoryInput;
};

export type MutationDeleteRequestCostBlockTemplateArgs = {
  input: DeleteRequestCostBlockTemplateInput;
};

export type MutationDeleteRequestCostLineTemplateArgs = {
  input: DeleteRequestCostLineTemplateInput;
};

export type MutationDeleteRequestLineTemplateArgs = {
  input: DeleteRequestLineTemplateInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationDeliveryCreateArgs = {
  input: DeliveryCreateInput;
};

export type MutationDeliveryDeleteArgs = {
  input: DeliveryDeleteInput;
};

export type MutationDeliveryUpdateArgs = {
  input: DeliveryUpdateInput;
};

export type MutationDocumentationBlockCreateArgs = {
  input: DocumentationBlockCreateInput;
};

export type MutationDocumentationBlockDeleteArgs = {
  input: DocumentationBlockDeleteInput;
};

export type MutationDocumentationLineCreateArgs = {
  input: DocumentationLineCreateInput;
};

export type MutationDocumentationLineDeleteArgs = {
  input: DocumentationLineDeleteInput;
};

export type MutationDocumentationLineUpdateArgs = {
  input: DocumentationLineUpdateInput;
};

export type MutationDocumentCreateArgs = {
  input: DocumentCreateInput;
};

export type MutationDocumentDeleteArgs = {
  input: DocumentDeleteInput;
};

export type MutationDocumentUpdateArgs = {
  input: DocumentUpdateInput;
};

export type MutationEventCreateArgs = {
  input: EventCreateInput;
};

export type MutationEventDeleteArgs = {
  input: EventDeleteInput;
};

export type MutationEventUpdateArgs = {
  input: EventUpdateInput;
};

export type MutationHousingMaintenanceBudgetLineCreateArgs = {
  input: HousingMaintenanceBudgetLineCreateInput;
};

export type MutationHousingMaintenanceBudgetLineDeleteArgs = {
  input: HousingMaintenanceBudgetLineDeleteInput;
};

export type MutationHousingMaintenanceBudgetLineUpdateArgs = {
  input: HousingMaintenanceBudgetLineUpdateInput;
};

export type MutationHousingMaintenanceBudgetPlanCreateArgs = {
  input: HousingMaintenanceBudgetPlanCreateInput;
};

export type MutationHousingMaintenanceBudgetPlanDeleteArgs = {
  input: HousingMaintenanceBudgetPlanDeleteInput;
};

export type MutationHousingMaintenanceBudgetPlanUpdateArgs = {
  input: HousingMaintenanceBudgetPlanUpdateInput;
};

export type MutationHousingMaintenanceBuildingPartCreateArgs = {
  input: HousingMaintenanceBuildingPartCreateInput;
};

export type MutationHousingMaintenanceBuildingPartDeleteArgs = {
  input: HousingMaintenanceBuildingPartDeleteInput;
};

export type MutationHousingMaintenanceBuildingPartsSortArgs = {
  input: HousingMaintenanceBuildingPartsSortInput;
};

export type MutationHousingMaintenanceBuildingPartUpdateArgs = {
  input: HousingMaintenanceBuildingPartUpdateInput;
};

export type MutationHousingMaintenanceSectionUpdateArgs = {
  input: HousingMaintenanceSectionUpdateInput;
};

export type MutationInvoiceContractorCreateArgs = {
  input: InvoiceContractorCreateInput;
};

export type MutationInvoiceContractorDeleteArgs = {
  input: InvoiceContractorDeleteInput;
};

export type MutationInvoiceContractorUpdateArgs = {
  input: InvoiceContractorUpdateInput;
};

export type MutationInvoiceEntryCategorizationsSortArgs = {
  input: InvoiceEntryCategorizationsSortInput;
};

export type MutationInvoiceEntryCategoryGroupCreateArgs = {
  input: InvoiceEntryCategoryGroupCreateInput;
};

export type MutationInvoiceEntryCategoryGroupDeleteArgs = {
  input: InvoiceEntryCategoryGroupDeleteInput;
};

export type MutationInvoiceEntryCategoryGroupUpdateArgs = {
  input: InvoiceEntryCategoryGroupUpdateInput;
};

export type MutationMembershipCreateArgs = {
  input: MembershipCreateInput;
};

export type MutationMembershipDeleteArgs = {
  input: MembershipDeleteInput;
};

export type MutationMembershipUpdateArgs = {
  input: MembershipUpdateInput;
};

export type MutationMigrateClientInvoiceArgs = {
  input: MigrateClientInvoiceInput;
};

export type MutationPublishConstructionLoanPeriodArgs = {
  input: PublishConstructionLoanPeriodInput;
};

export type MutationRegeneratePdfArgs = {
  input: RegeneratePdfInput;
};

export type MutationReportAddMemberArgs = {
  input: ReportAddMemberInput;
};

export type MutationReportPublishArgs = {
  input: ReportPublishInput;
};

export type MutationReportRemoveClientArgs = {
  input: ReportRemoveClientInput;
};

export type MutationReportRemoveMemberArgs = {
  input: ReportRemoveMemberInput;
};

export type MutationReportSetClientArgs = {
  input: ReportSetClientInput;
};

export type MutationReportSetConsultantsArgs = {
  input: ReportSetConsultantsInput;
};

export type MutationReportSetPartnersArgs = {
  input: ReportSetPartnersInput;
};

export type MutationReportSetProjectManagersArgs = {
  input: ReportSetProjectManagersInput;
};

export type MutationReportUpdateMemberArgs = {
  input: ReportUpdateMemberInput;
};

export type MutationRequestCostBlockCreateArgs = {
  input: RequestCostBlockCreateInput;
};

export type MutationRequestCostBlockDeleteArgs = {
  input: RequestCostBlockDeleteInput;
};

export type MutationRequestCostLineCreateArgs = {
  input: RequestCostLineCreateInput;
};

export type MutationRequestCostLineDeleteArgs = {
  input: RequestCostLineDeleteInput;
};

export type MutationRequestCostLineUpdateArgs = {
  input: RequestCostLineUpdateInput;
};

export type MutationRequestSectionUpdateArgs = {
  input: RequestSectionUpdateInput;
};

export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type MutationSetReportCoordinatesArgs = {
  input: SetReportCoordinatesInput;
};

export type MutationSortInvoiceCategoryArgs = {
  input: SortInvoiceCategoryInput;
};

export type MutationUnpublishConstructionLoanPeriodArgs = {
  input: UnpublishConstructionLoanPeriodInput;
};

export type MutationUpdateAffiliateInvoiceArgs = {
  input: UpdateAffiliateInvoiceInput;
};

export type MutationUpdateConstructionLoanPeriodArgs = {
  input: UpdateConstructionLoanPeriodInput;
};

export type MutationUpdateContractorInvoiceArgs = {
  input: UpdateContractorInvoiceInput;
};

export type MutationUpdateCostBlockTemplateArgs = {
  input: UpdateCostBlockTemplateInput;
};

export type MutationUpdateCostLineTemplateArgs = {
  input: UpdateCostLineTemplateInput;
};

export type MutationUpdateDocumentationBlockTemplateArgs = {
  input: UpdateDocumentationBlockTemplateInput;
};

export type MutationUpdateDocumentationLineTemplateArgs = {
  input: UpdateDocumentationLineTemplateInput;
};

export type MutationUpdateExternalInvoiceArgs = {
  input: UpdateExternalInvoiceInput;
};

export type MutationUpdateHousingMaintenanceBuildingPartRatingRuleArgs = {
  input: UpdateHousingMaintenanceBuildingPartRatingRuleInput;
};

export type MutationUpdateInvoiceCategoryArgs = {
  input: UpdateInvoiceCategoryInput;
};

export type MutationUpdateInvoiceEntryArgs = {
  input: UpdateInvoiceEntryInput;
};

export type MutationUpdateInvoiceEntryCategoryArgs = {
  input: UpdateInvoiceEntryCategoryInput;
};

export type MutationUpdateMembershipRelationArgs = {
  input: UpdateMembershipRelationInput;
};

export type MutationUpdatePeriodLoanArgs = {
  input: UpdatePeriodLoanInput;
};

export type MutationUpdateRequestCostBlockTemplateArgs = {
  input: UpdateRequestCostBlockTemplateInput;
};

export type MutationUpdateRequestCostLineTemplateArgs = {
  input: UpdateRequestCostLineTemplateInput;
};

export type MutationUpdateRequestLineTemplateArgs = {
  input: UpdateRequestLineTemplateInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars["ID"];
};

export type NodeInterface = {
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export type NodeUrlsInterface = {
  urls: Maybe<Urls>;
};

export type OptionInteger = {
  __typename: "OptionInteger";
  label: Scalars["String"];
  value: Maybe<Scalars["Int"]>;
};

export type OptionString = {
  __typename: "OptionString";
  label: Scalars["String"];
  value: Maybe<Scalars["String"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars["String"]>;
};

export type Paragraph = Node &
  NodeInterface & {
    __typename: "Paragraph";
    content: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    /** ID of the object. */
    id: Scalars["ID"];
    order: Maybe<Scalars["Int"]>;
    textSection: Maybe<TextSection>;
    textSectionId: Maybe<Scalars["String"]>;
    title: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
  };

/** Autogenerated return type of ParagraphMutations */
export type ParagraphMutationsPayload = {
  __typename: "ParagraphMutationsPayload";
  create: Maybe<CreateParagraphPayload>;
  delete: Maybe<DeleteParagraphPayload>;
  sort: Maybe<SortParagraphsPayload>;
  update: Maybe<UpdateParagraphPayload>;
};

/** Autogenerated return type of ParagraphMutations */
export type ParagraphMutationsPayloadCreateArgs = {
  input: CreateParagraphInput;
};

/** Autogenerated return type of ParagraphMutations */
export type ParagraphMutationsPayloadDeleteArgs = {
  input: DeleteParagraphInput;
};

/** Autogenerated return type of ParagraphMutations */
export type ParagraphMutationsPayloadSortArgs = {
  input: SortParagraphsInput;
};

/** Autogenerated return type of ParagraphMutations */
export type ParagraphMutationsPayloadUpdateArgs = {
  input: UpdateParagraphInput;
};

export type PeriodActionPolicy = {
  __typename: "PeriodActionPolicy";
  delete: Scalars["Boolean"];
  edit: Scalars["Boolean"];
  view: Scalars["Boolean"];
};

/** Autogenerated return type of PeriodMutations */
export type PeriodMutationsPayload = {
  __typename: "PeriodMutationsPayload";
  readyForReview: Maybe<ReadyForReviewPayload>;
};

/** Autogenerated return type of PeriodMutations */
export type PeriodMutationsPayloadReadyForReviewArgs = {
  input: ReadyForReviewInput;
};

export type PeriodPayoutAttributes = {
  files?: InputMaybe<Array<Scalars["String"]>>;
  information?: InputMaybe<Scalars["String"]>;
  manualAmount?: InputMaybe<Scalars["Float"]>;
  recommendation?: InputMaybe<Scalars["String"]>;
};

export enum PermissionEnum {
  /** Edit access to corresponding entity */
  Edit = "edit",
  /** View access to corresponding entity */
  View = "view",
}

/** Different types of sections */
export type PublicationSection =
  | CvSection
  | DocumentationSection
  | MaintenanceSectionPublication
  | RequestSection
  | TextSection;

/** Autogenerated input type of PublishConstructionLoanPeriod */
export type PublishConstructionLoanPeriodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  publishedAt: Scalars["ISO8601DateTime"];
};

/** Autogenerated return type of PublishConstructionLoanPeriod */
export type PublishConstructionLoanPeriodPayload = {
  __typename: "PublishConstructionLoanPeriodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  period: Maybe<ConstructionLoanPeriod>;
};

export type Query = {
  __typename: "Query";
  /** Searchable list of clients and collaborators */
  affiliates: Maybe<Array<User>>;
  assessmentPublication: Maybe<AssessmentPublication>;
  building: Maybe<Building>;
  buildingStats: BuildingMaintenanceStats;
  buildingTypes: Array<BuildingType>;
  companies: Maybe<Array<Company>>;
  company: Maybe<Company>;
  constructionLoan: Maybe<ConstructionLoan>;
  constructionLoanPeriod: Maybe<ConstructionLoanPeriod>;
  /** Consultants list */
  consultants: Array<User>;
  costBlock: Maybe<CostBlock>;
  costBlockTemplate: Maybe<CostBlockTemplate>;
  costBlockTemplates: Array<CostBlockTemplate>;
  costLine: Maybe<CostLine>;
  costLineTemplates: Maybe<Array<CostLineTemplate>>;
  costOptions: CostOptions;
  costSection: Maybe<CostSection>;
  currencyRates: Maybe<CurrencyRates>;
  decodeId: Maybe<Scalars["String"]>;
  document: Maybe<Document>;
  documentationBlock: Maybe<DocumentationBlock>;
  documentationBlockTemplates: Array<DocumentationBlockTemplate>;
  documentationLineTemplates: Maybe<Array<DocumentationLineTemplate>>;
  documentationSection: Maybe<DocumentationSection>;
  documents: Maybe<Array<Document>>;
  events: Maybe<Array<Event>>;
  generalEditors: Maybe<Array<User>>;
  housingMaintenanceBuildingPart: Maybe<HousingMaintenanceBuildingPart>;
  housingMaintenanceBuildingPartOptions: BuildingPartOptions;
  housingMaintenanceBuildingPartRatingRules: Array<HousingMaintenanceBuildingPartRatingRule>;
  housingMaintenanceSection: Maybe<HousingMaintenanceSection>;
  invoice: Maybe<Invoice>;
  invoiceContractor: Maybe<InvoiceContractor>;
  memberships: Maybe<Array<Membership>>;
  /** Fetches an object given its ID. */
  node: Maybe<Node>;
  partners: Maybe<Array<User>>;
  projectManagers: Maybe<Array<User>>;
  publication: Maybe<ReportPublication>;
  report: Maybe<Report>;
  reports: Maybe<ReportConnection>;
  reportSearch: Array<Report>;
  requestCostBlock: Maybe<RequestCostBlock>;
  requestCostBlockTemplates: Array<RequestCostBlockTemplate>;
  requestLineTemplates: Maybe<Array<RequestLineTemplate>>;
  requestSection: Maybe<RequestSection>;
  session: Maybe<Session>;
  user: Maybe<User>;
  users: Array<User>;
};

export type QueryAffiliatesArgs = {
  query?: InputMaybe<Scalars["String"]>;
};

export type QueryAssessmentPublicationArgs = {
  id: Scalars["ID"];
};

export type QueryBuildingArgs = {
  id: Scalars["ID"];
};

export type QueryBuildingStatsArgs = {
  type?: InputMaybe<Scalars["ID"]>;
  year: Scalars["Int"];
};

export type QueryBuildingTypesArgs = {
  default?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryCompanyArgs = {
  id: Scalars["ID"];
};

export type QueryConstructionLoanArgs = {
  id: Scalars["ID"];
};

export type QueryConstructionLoanPeriodArgs = {
  id: Scalars["ID"];
};

export type QueryConsultantsArgs = {
  workingArea: Scalars["String"];
};

export type QueryCostBlockArgs = {
  id: Scalars["ID"];
};

export type QueryCostBlockTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryCostLineArgs = {
  id: Scalars["ID"];
};

export type QueryCostLineTemplatesArgs = {
  costBlockTemplateId: Scalars["ID"];
};

export type QueryCostSectionArgs = {
  id: Scalars["ID"];
};

export type QueryDecodeIdArgs = {
  id: Scalars["String"];
};

export type QueryDocumentArgs = {
  id: Scalars["ID"];
};

export type QueryDocumentationBlockArgs = {
  id: Scalars["ID"];
};

export type QueryDocumentationLineTemplatesArgs = {
  documentationBlockTemplateId: Scalars["ID"];
};

export type QueryDocumentationSectionArgs = {
  id: Scalars["ID"];
};

export type QueryDocumentsArgs = {
  reportId: Scalars["ID"];
};

export type QueryEventsArgs = {
  reportId: Scalars["ID"];
};

export type QueryHousingMaintenanceBuildingPartArgs = {
  id: Scalars["ID"];
};

export type QueryHousingMaintenanceSectionArgs = {
  id: Scalars["ID"];
};

export type QueryInvoiceArgs = {
  id: Scalars["ID"];
};

export type QueryInvoiceContractorArgs = {
  id: Scalars["ID"];
};

export type QueryMembershipsArgs = {
  permission?: InputMaybe<Scalars["String"]>;
  relation?: InputMaybe<Array<Scalars["String"]>>;
  reportId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
};

export type QueryPublicationArgs = {
  id: Scalars["ID"];
};

export type QueryReportArgs = {
  slug: Scalars["ID"];
};

export type QueryReportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<FilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ReportSortEnum>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type QueryReportSearchArgs = {
  companyId?: InputMaybe<Array<Scalars["ID"]>>;
  query: Scalars["String"];
  type?: InputMaybe<Array<ReportType>>;
};

export type QueryRequestCostBlockArgs = {
  id: Scalars["ID"];
};

export type QueryRequestSectionArgs = {
  id: Scalars["ID"];
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryUsersArgs = {
  term?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated input type of ReadyForReview */
export type ReadyForReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  ready: Scalars["Boolean"];
};

/** Autogenerated return type of ReadyForReview */
export type ReadyForReviewPayload = {
  __typename: "ReadyForReviewPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  period: Maybe<ConstructionLoanPeriod>;
};

/** Autogenerated input type of RegeneratePdf */
export type RegeneratePdfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of RegeneratePdf */
export type RegeneratePdfPayload = {
  __typename: "RegeneratePdfPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  report: Maybe<Report>;
  status: Scalars["String"];
};

/** Autogenerated input type of RemoveReportFromMulti */
export type RemoveReportFromMultiInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  from: Scalars["ID"];
  remove: Scalars["ID"];
};

/** Autogenerated return type of RemoveReportFromMulti */
export type RemoveReportFromMultiPayload = {
  __typename: "RemoveReportFromMultiPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  report: Maybe<MultiReport>;
};

export type Rental = {
  __typename: "Rental";
  endsAt: Maybe<Scalars["Date"]>;
  signedAt: Maybe<Scalars["Date"]>;
  startsAt: Maybe<Scalars["Date"]>;
  tenant: Maybe<Scalars["String"]>;
};

/** Report base */
export type Report = {
  acl: ReportActionPolicy;
  address: Maybe<Scalars["String"]>;
  associates: Array<Member>;
  client: Maybe<Member>;
  collaborators: Array<Member>;
  company: Company;
  companyId: Scalars["ID"];
  constructionYear: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  documentationControl: Maybe<Scalars["String"]>;
  fallbackArea: Maybe<Scalars["String"]>;
  featuredImage: Maybe<Image>;
  id: Scalars["ID"];
  inspectionDate: Maybe<Scalars["Date"]>;
  inspectionDescription: Maybe<Scalars["String"]>;
  lat: Maybe<Scalars["Float"]>;
  legalOwner: Maybe<Scalars["String"]>;
  lng: Maybe<Scalars["Float"]>;
  lockedAt: Maybe<Scalars["Date"]>;
  municipality: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  originalReportDate: Maybe<Scalars["Date"]>;
  partners: Array<Member>;
  projectManagers: Array<Member>;
  propertyRegistrationNumber: Maybe<Scalars["String"]>;
  propertySectionNumber: Maybe<Scalars["String"]>;
  propertySubRegistrationNumber: Maybe<Scalars["String"]>;
  rentals: Maybe<Array<Rental>>;
  slug: Scalars["String"];
  type: ReportType;
  typeManual: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  /** @deprecated Use client side url helpers instead. */
  urls: Maybe<Urls>;
};

export type ReportActionPolicy = {
  __typename: "ReportActionPolicy";
  edit: Scalars["Boolean"];
  sections: Scalars["Boolean"];
  view: Scalars["Boolean"];
};

/** Autogenerated input type of ReportAddMember */
export type ReportAddMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<PermissionEnum>>;
  relation: MembershipRelation;
  reportId: Scalars["ID"];
  userId: Scalars["ID"];
};

/** The connection type for Report. */
export type ReportConnection = {
  __typename: "ReportConnection";
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Report>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ReportCost = {
  __typename: "ReportCost";
  documentation: Maybe<Scalars["Float"]>;
  firstYearM2: Maybe<Scalars["Float"]>;
  grade: Maybe<Scalars["String"]>;
  intervals: Array<Maybe<Scalars["Float"]>>;
  score: Maybe<Scalars["Float"]>;
  total: Maybe<Scalars["Float"]>;
};

export type ReportCostDocumentationArgs = {
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type ReportCostFirstYearM2Args = {
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type ReportCostIntervalsArgs = {
  coverage?: InputMaybe<Array<MaintenanceCoverageEnum>>;
  interval?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

export type ReportCostTotalArgs = {
  coverage?: InputMaybe<Array<MaintenanceCoverageEnum>>;
  period?: InputMaybe<Array<MaintenancePeriodEnum>>;
  priority?: InputMaybe<Array<MaintenancePriorityEnum>>;
  type?: InputMaybe<Array<MaintenanceTypeEnum>>;
};

/** An edge in a connection. */
export type ReportEdge = {
  __typename: "ReportEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Maybe<Report>;
};

/** Autogenerated return type of ReportMutations */
export type ReportMutationsPayload = {
  __typename: "ReportMutationsPayload";
  multis: MultiReportMutationsPayload;
  update: Maybe<UpdateReportPayload>;
};

/** Autogenerated return type of ReportMutations */
export type ReportMutationsPayloadUpdateArgs = {
  input: UpdateReportInput;
};

export type ReportPublication = {
  address: Maybe<Scalars["String"]>;
  company: Company;
  featuredImage: Maybe<Image>;
  lat: Maybe<Scalars["Float"]>;
  lng: Maybe<Scalars["Float"]>;
  name: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  report: Maybe<Report>;
};

/** Autogenerated input type of ReportPublish */
export type ReportPublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated input type of ReportRemoveClient */
export type ReportRemoveClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  reportId: Scalars["ID"];
};

/** Autogenerated input type of ReportRemoveMember */
export type ReportRemoveMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  membershipId: Scalars["ID"];
};

/** Autogenerated input type of ReportSetClient */
export type ReportSetClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  permissions: Array<PermissionEnum>;
  reportId: Scalars["ID"];
  userId: Scalars["ID"];
};

/** Autogenerated input type of ReportSetConsultants */
export type ReportSetConsultantsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  consultantIds: Array<Scalars["ID"]>;
  ksConsultantIds: Array<Scalars["ID"]>;
  reportId: Scalars["ID"];
  workingArea: WorkingAreaEnum;
};

/** Autogenerated input type of ReportSetPartners */
export type ReportSetPartnersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  reportId: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
};

/** Autogenerated input type of ReportSetProjectManagers */
export type ReportSetProjectManagersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  reportId: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
};

export enum ReportSortEnum {
  /** Sort reports by Date ASC */
  DateAsc = "DATE_ASC",
  /** Sort reports by Date DESC */
  DateDesc = "DATE_DESC",
  /** Sort reports by Name ASC */
  NameAsc = "NAME_ASC",
  /** Sort reports by Name DESC */
  NameDesc = "NAME_DESC",
}

export enum ReportStatus {
  /** Published reports */
  Published = "PUBLISHED",
  /** Unpublished reports */
  Unpublished = "UNPUBLISHED",
}

export enum ReportType {
  /** Assessment report */
  Assessment = "ASSESSMENT",
  /** Breeam report */
  Breeam = "BREEAM",
  /** ConstructionLoan report */
  ConstructionLoan = "CONSTRUCTION_LOAN",
  /** HousingAssessment report */
  HousingAssessment = "HOUSING_ASSESSMENT",
  /** MultiAssessment report */
  MultiAssessment = "MULTI_ASSESSMENT",
  /** MultiTDD report */
  MultiTdd = "MULTI_TDD",
  /** TDD report */
  Tdd = "TDD",
}

/** Autogenerated input type of ReportUpdateMember */
export type ReportUpdateMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  membershipId: Scalars["ID"];
  permissions?: InputMaybe<Array<PermissionEnum>>;
};

export type RequestCostBlock = NodeInterface & {
  __typename: "RequestCostBlock";
  cost: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  number: Maybe<Scalars["String"]>;
  order: Maybe<Scalars["Int"]>;
  requestCostBlockTemplate: Maybe<RequestCostBlockTemplate>;
  requestCostBlockTemplateId: Maybe<Scalars["ID"]>;
  requestCostLines: Array<RequestCostLine>;
  requestCostLinesCount: Scalars["Int"];
  requestSection: RequestSection;
  requestSectionId: Scalars["ID"];
  status: WorkStatusEnum;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of RequestCostBlockCreate */
export type RequestCostBlockCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  requestCostBlockTemplateId: Scalars["ID"];
  requestSectionId: Scalars["ID"];
};

/** Autogenerated input type of RequestCostBlockDelete */
export type RequestCostBlockDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type RequestCostBlockTemplate = NodeInterface & {
  __typename: "RequestCostBlockTemplate";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  internalUse: Scalars["Boolean"];
  number: Scalars["String"];
  requestCostLineTemplates: Array<RequestCostLineTemplate>;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type RequestCostLine = NodeInterface & {
  __typename: "RequestCostLine";
  cost: Maybe<Scalars["Float"]>;
  costTypePrimary: Maybe<Scalars["String"]>;
  costTypeSecondary: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  files: Maybe<Array<Attachment>>;
  id: Scalars["ID"];
  number: Maybe<Scalars["String"]>;
  received: Maybe<Scalars["Boolean"]>;
  reference: Maybe<Scalars["String"]>;
  requestCostBlock: RequestCostBlock;
  requestType: Maybe<Scalars["String"]>;
  status: WorkStatusEnum;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of RequestCostLineCreate */
export type RequestCostLineCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  requestCostBlockId: Scalars["ID"];
  requestCostLineTemplateId: Scalars["ID"];
};

/** Autogenerated input type of RequestCostLineDelete */
export type RequestCostLineDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type RequestCostLineTemplate = NodeInterface & {
  __typename: "RequestCostLineTemplate";
  costTypePrimary: Maybe<Scalars["String"]>;
  costTypeSecondary: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  number: Maybe<Scalars["String"]>;
  requestCostBlockTemplate: RequestCostBlockTemplate;
  requestCostBlockTemplateId: Scalars["ID"];
  requestType: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of RequestCostLineUpdate */
export type RequestCostLineUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  files?: InputMaybe<Array<Scalars["String"]>>;
  id: Scalars["ID"];
  received?: InputMaybe<Scalars["Boolean"]>;
  reference?: InputMaybe<Scalars["String"]>;
};

export type RequestLine = NodeInterface & {
  __typename: "RequestLine";
  answer: Maybe<Scalars["String"]>;
  answeredBy: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  files: Maybe<Array<Attachment>>;
  id: Scalars["ID"];
  number: Maybe<Scalars["String"]>;
  reference: Maybe<Scalars["String"]>;
  section: RequestSection;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type RequestLineTemplate = NodeInterface & {
  __typename: "RequestLineTemplate";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  number: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type RequestSection = NodeInterface &
  NodeUrlsInterface & {
    __typename: "RequestSection";
    createdAt: Scalars["DateTime"];
    description: Maybe<Scalars["String"]>;
    hidden: Scalars["Boolean"];
    id: Scalars["ID"];
    requestCostBlocks: Array<RequestCostBlock>;
    requestLines: Array<RequestLine>;
    srlAnswer: Maybe<Scalars["String"]>;
    srlAnsweredBy: Maybe<Scalars["String"]>;
    srlFiles: Maybe<Array<Attachment>>;
    srlReference: Maybe<Scalars["String"]>;
    srlTitle: Maybe<Scalars["String"]>;
    status: WorkStatusEnum;
    title: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    urls: Maybe<Urls>;
    visible: Scalars["Boolean"];
  };

/** Autogenerated return type of RequestSectionMutations */
export type RequestSectionMutationsPayload = {
  __typename: "RequestSectionMutationsPayload";
  createLine: Maybe<CreateRequestLinePayload>;
  deleteLine: Maybe<DeleteRequestLinePayload>;
  updateLine: Maybe<UpdateRequestLinePayload>;
};

/** Autogenerated return type of RequestSectionMutations */
export type RequestSectionMutationsPayloadCreateLineArgs = {
  input: CreateRequestLineInput;
};

/** Autogenerated return type of RequestSectionMutations */
export type RequestSectionMutationsPayloadDeleteLineArgs = {
  input: DeleteRequestLineInput;
};

/** Autogenerated return type of RequestSectionMutations */
export type RequestSectionMutationsPayloadUpdateLineArgs = {
  input: UpdateRequestLineInput;
};

/** Autogenerated input type of RequestSectionUpdate */
export type RequestSectionUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  srlAnswer?: InputMaybe<Scalars["String"]>;
  srlAnsweredBy?: InputMaybe<Scalars["String"]>;
  srlFiles?: InputMaybe<Array<Scalars["String"]>>;
  srlReference?: InputMaybe<Scalars["String"]>;
  srlTitle?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of RequestSectionUpdate */
export type RequestSectionUpdatePayload = {
  __typename: "RequestSectionUpdatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  requestSection: Maybe<RequestSection>;
};

/** Autogenerated input type of ResetUserPassword */
export type ResetUserPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** Autogenerated return type of ResetUserPassword */
export type ResetUserPasswordPayload = {
  __typename: "ResetUserPasswordPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  user: Maybe<User>;
};

export type Section = {
  createdAt: Scalars["DateTime"];
  hidden: Scalars["Boolean"];
  id: Scalars["ID"];
  primary: Scalars["Boolean"];
  report: Sectionable;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  visible: Scalars["Boolean"];
};

/** Report base */
export type Sectionable = {
  acl: ReportActionPolicy;
  address: Maybe<Scalars["String"]>;
  associates: Array<Member>;
  client: Maybe<Member>;
  collaborators: Array<Member>;
  company: Company;
  companyId: Scalars["ID"];
  constructionYear: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  documentationControl: Maybe<Scalars["String"]>;
  fallbackArea: Maybe<Scalars["String"]>;
  featuredImage: Maybe<Image>;
  id: Scalars["ID"];
  inspectionDate: Maybe<Scalars["Date"]>;
  inspectionDescription: Maybe<Scalars["String"]>;
  lat: Maybe<Scalars["Float"]>;
  legalOwner: Maybe<Scalars["String"]>;
  lng: Maybe<Scalars["Float"]>;
  lockedAt: Maybe<Scalars["Date"]>;
  municipality: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  originalReportDate: Maybe<Scalars["Date"]>;
  partners: Array<Member>;
  projectManagers: Array<Member>;
  propertyRegistrationNumber: Maybe<Scalars["String"]>;
  propertySectionNumber: Maybe<Scalars["String"]>;
  propertySubRegistrationNumber: Maybe<Scalars["String"]>;
  rentals: Maybe<Array<Rental>>;
  sections: Array<Section>;
  slug: Scalars["String"];
  type: ReportType;
  typeManual: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  /** @deprecated Use client side url helpers instead. */
  urls: Maybe<Urls>;
};

/** Autogenerated return type of SectionMutations */
export type SectionMutationsPayload = {
  __typename: "SectionMutationsPayload";
  create: Maybe<CreateSectionPayload>;
  delete: Maybe<DeleteSectionPayload>;
  requests: RequestSectionMutationsPayload;
  sort: Maybe<SortSectionPayload>;
  texts: TextSectionMutationsPayload;
  update: Maybe<UpdateSectionPayload>;
};

/** Autogenerated return type of SectionMutations */
export type SectionMutationsPayloadCreateArgs = {
  input: CreateSectionInput;
};

/** Autogenerated return type of SectionMutations */
export type SectionMutationsPayloadDeleteArgs = {
  input: DeleteSectionInput;
};

/** Autogenerated return type of SectionMutations */
export type SectionMutationsPayloadSortArgs = {
  input: SortSectionInput;
};

/** Autogenerated return type of SectionMutations */
export type SectionMutationsPayloadUpdateArgs = {
  input: UpdateSectionInput;
};

export type Session = NodeInterface & {
  __typename: "Session";
  createdAt: Scalars["DateTime"];
  disclaimer: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
  user: User;
};

/** Autogenerated return type of SessionMutations */
export type SessionMutationsPayload = {
  __typename: "SessionMutationsPayload";
  acceptDisclaimer: Maybe<AcceptDisclaimerPayload>;
};

/** Autogenerated input type of SetMultiReportConnectionRiskLevel */
export type SetMultiReportConnectionRiskLevelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  riskLevel: MultiReportConnectionRiskLevel;
};

/** Autogenerated return type of SetMultiReportConnectionRiskLevel */
export type SetMultiReportConnectionRiskLevelPayload = {
  __typename: "SetMultiReportConnectionRiskLevelPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  connection: Maybe<MultiReportConnection>;
  errors: Array<Scalars["String"]>;
};

/** Autogenerated input type of SetReportCoordinates */
export type SetReportCoordinatesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lat: Scalars["Float"];
  lng: Scalars["Float"];
};

/** Autogenerated return type of SetReportCoordinates */
export type SetReportCoordinatesPayload = {
  __typename: "SetReportCoordinatesPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  error: Maybe<Array<Scalars["String"]>>;
  report: Maybe<Report>;
};

/** Autogenerated input type of SortInvoiceCategory */
export type SortInvoiceCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  from: Scalars["String"];
  to: Scalars["String"];
};

/** Autogenerated return type of SortInvoiceCategory */
export type SortInvoiceCategoryPayload = {
  __typename: "SortInvoiceCategoryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  report: ConstructionLoan;
};

/** Autogenerated input type of SortMultiReportConnections */
export type SortMultiReportConnectionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  connections: Array<Scalars["ID"]>;
  report: Scalars["ID"];
};

/** Autogenerated return type of SortMultiReportConnections */
export type SortMultiReportConnectionsPayload = {
  __typename: "SortMultiReportConnectionsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  report: Maybe<MultiReport>;
};

/** Autogenerated input type of SortParagraphs */
export type SortParagraphsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  paragraphs: Array<Scalars["ID"]>;
  textSection: Scalars["ID"];
};

/** Autogenerated return type of SortParagraphs */
export type SortParagraphsPayload = {
  __typename: "SortParagraphsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  textSection: Maybe<TextSection>;
};

/** Autogenerated input type of SortSection */
export type SortSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  report: Scalars["ID"];
  sections: Array<Scalars["ID"]>;
};

/** Autogenerated return type of SortSection */
export type SortSectionPayload = {
  __typename: "SortSectionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  report: Maybe<Sectionable>;
};

export type StatusInfo = {
  __typename: "StatusInfo";
  touchedCostLinesCount: Scalars["Int"];
  touchedRequestCostLinesCount: Maybe<Scalars["Int"]>;
};

export type Tdd = Buildings &
  Consultable &
  Costs &
  Report &
  Sectionable &
  TextSectionsReport & {
    __typename: "TDD";
    acl: ReportActionPolicy;
    address: Maybe<Scalars["String"]>;
    associates: Array<Member>;
    buildings: Array<Building>;
    buildingTypeNames: Array<Scalars["String"]>;
    client: Maybe<Member>;
    collaborators: Array<Member>;
    company: Company;
    companyId: Scalars["ID"];
    constructionConsultants: Array<Member>;
    constructionKsConsultants: Array<Member>;
    constructionYear: Maybe<Scalars["String"]>;
    cost: ReportCost;
    costSections: Array<CostSection>;
    createdAt: Scalars["DateTime"];
    documentationControl: Maybe<Scalars["String"]>;
    ecoConsultants: Array<Member>;
    ecoKsConsultants: Array<Member>;
    electricalConsultants: Array<Member>;
    electricalKsConsultants: Array<Member>;
    fallbackArea: Maybe<Scalars["String"]>;
    featuredImage: Maybe<Image>;
    fireConsultants: Array<Member>;
    fireKsConsultants: Array<Member>;
    id: Scalars["ID"];
    inspectionDate: Maybe<Scalars["Date"]>;
    inspectionDescription: Maybe<Scalars["String"]>;
    lat: Maybe<Scalars["Float"]>;
    legalOwner: Maybe<Scalars["String"]>;
    lng: Maybe<Scalars["Float"]>;
    lockedAt: Maybe<Scalars["Date"]>;
    municipality: Maybe<Scalars["String"]>;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    originalReportDate: Maybe<Scalars["Date"]>;
    partners: Array<Member>;
    plumbingConsultants: Array<Member>;
    plumbingKsConsultants: Array<Member>;
    projectManagers: Array<Member>;
    propertyRegistrationNumber: Maybe<Scalars["String"]>;
    propertySectionNumber: Maybe<Scalars["String"]>;
    propertySubRegistrationNumber: Maybe<Scalars["String"]>;
    publication: TddPublication;
    rentals: Maybe<Array<Rental>>;
    sections: Array<Section>;
    slug: Scalars["String"];
    textSections: Array<TextSection>;
    type: ReportType;
    typeManual: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    /** @deprecated Use client side url helpers instead. */
    urls: Maybe<Urls>;
  };

export type TddPublication = BuildingMaintenance &
  ReportPublication & {
    __typename: "TDDPublication";
    address: Maybe<Scalars["String"]>;
    buildings: Array<Building>;
    company: Company;
    featuredImage: Maybe<Image>;
    lat: Maybe<Scalars["Float"]>;
    lng: Maybe<Scalars["Float"]>;
    maintenance: Maintenance;
    name: Maybe<Scalars["String"]>;
    number: Maybe<Scalars["String"]>;
    report: Maybe<Assessment>;
    sections: Array<PublicationSection>;
  };

export type TextSection = Node &
  NodeInterface &
  Section & {
    __typename: "TextSection";
    createdAt: Scalars["DateTime"];
    hidden: Scalars["Boolean"];
    /** ID of the object. */
    id: Scalars["ID"];
    paragraphs: Array<Paragraph>;
    primary: Scalars["Boolean"];
    report: TextSectionsReport;
    title: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    visible: Scalars["Boolean"];
  };

/** Autogenerated return type of TextSectionMutations */
export type TextSectionMutationsPayload = {
  __typename: "TextSectionMutationsPayload";
  paragraphs: ParagraphMutationsPayload;
};

/** Report base */
export type TextSectionsReport = {
  acl: ReportActionPolicy;
  address: Maybe<Scalars["String"]>;
  associates: Array<Member>;
  client: Maybe<Member>;
  collaborators: Array<Member>;
  company: Company;
  companyId: Scalars["ID"];
  constructionYear: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  documentationControl: Maybe<Scalars["String"]>;
  fallbackArea: Maybe<Scalars["String"]>;
  featuredImage: Maybe<Image>;
  id: Scalars["ID"];
  inspectionDate: Maybe<Scalars["Date"]>;
  inspectionDescription: Maybe<Scalars["String"]>;
  lat: Maybe<Scalars["Float"]>;
  legalOwner: Maybe<Scalars["String"]>;
  lng: Maybe<Scalars["Float"]>;
  lockedAt: Maybe<Scalars["Date"]>;
  municipality: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  originalReportDate: Maybe<Scalars["Date"]>;
  partners: Array<Member>;
  projectManagers: Array<Member>;
  propertyRegistrationNumber: Maybe<Scalars["String"]>;
  propertySectionNumber: Maybe<Scalars["String"]>;
  propertySubRegistrationNumber: Maybe<Scalars["String"]>;
  rentals: Maybe<Array<Rental>>;
  sections: Array<Section>;
  slug: Scalars["String"];
  textSections: Array<TextSection>;
  type: ReportType;
  typeManual: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  /** @deprecated Use client side url helpers instead. */
  urls: Maybe<Urls>;
};

/** Autogenerated input type of ToggleMultiDeviation */
export type ToggleMultiDeviationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  connection: Scalars["ID"];
  id: Scalars["ID"];
};

/** Autogenerated return type of ToggleMultiDeviation */
export type ToggleMultiDeviationPayload = {
  __typename: "ToggleMultiDeviationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  deviation: Maybe<MultiReportDeviation>;
  errors: Array<Scalars["String"]>;
};

/** Autogenerated input type of UnpublishConstructionLoanPeriod */
export type UnpublishConstructionLoanPeriodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of UnpublishConstructionLoanPeriod */
export type UnpublishConstructionLoanPeriodPayload = {
  __typename: "UnpublishConstructionLoanPeriodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  period: Maybe<ConstructionLoanPeriod>;
};

/** Autogenerated input type of UpdateAffiliateInvoice */
export type UpdateAffiliateInvoiceInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars["String"]>;
  dueDate?: InputMaybe<Scalars["Date"]>;
  file?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  number?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateAffiliateInvoice */
export type UpdateAffiliateInvoicePayload = {
  __typename: "UpdateAffiliateInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoice: Maybe<Invoice>;
};

/** Autogenerated input type of UpdateConstructionLoanPeriod */
export type UpdateConstructionLoanPeriodInput = {
  accidents?: InputMaybe<Scalars["Int"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  currencyDate?: InputMaybe<Scalars["Date"]>;
  currencyLink?: InputMaybe<Scalars["String"]>;
  currencySource?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["Date"]>;
  dkk?: InputMaybe<Scalars["Float"]>;
  documentsText?: InputMaybe<Scalars["String"]>;
  eur?: InputMaybe<Scalars["Float"]>;
  files?: InputMaybe<Array<Scalars["String"]>>;
  financeText?: InputMaybe<Scalars["String"]>;
  full?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  inspectionText?: InputMaybe<Scalars["String"]>;
  invoicePlanText?: InputMaybe<Scalars["String"]>;
  invoiceText?: InputMaybe<Scalars["String"]>;
  issues?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  payout?: InputMaybe<PeriodPayoutAttributes>;
  progressText?: InputMaybe<Scalars["String"]>;
  qualityText?: InputMaybe<Scalars["String"]>;
  safetyInspections?: InputMaybe<Scalars["Int"]>;
  sek?: InputMaybe<Scalars["Float"]>;
  shaFiguresText?: InputMaybe<Scalars["String"]>;
  shaGeneralText?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["Date"]>;
  usd?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of UpdateConstructionLoanPeriod */
export type UpdateConstructionLoanPeriodPayload = {
  __typename: "UpdateConstructionLoanPeriodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  period: Maybe<ConstructionLoanPeriod>;
};

/** Autogenerated input type of UpdateContractorInvoice */
export type UpdateContractorInvoiceInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contractorId?: InputMaybe<Scalars["ID"]>;
  currency?: InputMaybe<CurrencyEnum>;
  deposit?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  dueDate?: InputMaybe<Scalars["Date"]>;
  file?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  multi?: InputMaybe<Scalars["Boolean"]>;
  number?: InputMaybe<Scalars["String"]>;
  receivedDate?: InputMaybe<Scalars["Date"]>;
  sender?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateContractorInvoice */
export type UpdateContractorInvoicePayload = {
  __typename: "UpdateContractorInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoice: Maybe<ContractorInvoice>;
};

/** Autogenerated input type of UpdateCostBlockTemplate */
export type UpdateCostBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  number?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateCostBlockTemplate */
export type UpdateCostBlockTemplatePayload = {
  __typename: "UpdateCostBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  costBlockTemplate: Maybe<CostBlockTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of UpdateCostLineTemplate */
export type UpdateCostLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  ref: Scalars["Int"];
  title: Scalars["String"];
};

/** Autogenerated return type of UpdateCostLineTemplate */
export type UpdateCostLineTemplatePayload = {
  __typename: "UpdateCostLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  costLineTemplate: Maybe<CostLineTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of UpdateDocumentationBlockTemplate */
export type UpdateDocumentationBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateDocumentationBlockTemplate */
export type UpdateDocumentationBlockTemplatePayload = {
  __typename: "UpdateDocumentationBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationBlockTemplate: Maybe<DocumentationBlockTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of UpdateDocumentationLineTemplate */
export type UpdateDocumentationLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  columns?: InputMaybe<Scalars["Int"]>;
  id: Scalars["String"];
  number?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateDocumentationLineTemplate */
export type UpdateDocumentationLineTemplatePayload = {
  __typename: "UpdateDocumentationLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  documentationLineTemplate: Maybe<DocumentationLineTemplate>;
  errors: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of UpdateExternalInvoice */
export type UpdateExternalInvoiceInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  categoryId?: InputMaybe<Scalars["ID"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars["String"]>;
  dueDate?: InputMaybe<Scalars["Date"]>;
  file?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  number?: InputMaybe<Scalars["String"]>;
  receivedDate?: InputMaybe<Scalars["Date"]>;
  sender?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateExternalInvoice */
export type UpdateExternalInvoicePayload = {
  __typename: "UpdateExternalInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoice: Maybe<ExternalInvoice>;
};

/** Autogenerated input type of UpdateHousingMaintenanceBuildingPartRatingRule */
export type UpdateHousingMaintenanceBuildingPartRatingRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  kg?: InputMaybe<Scalars["Int"]>;
  period?: InputMaybe<Scalars["String"]>;
  rating?: InputMaybe<Scalars["Int"]>;
  tg?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated return type of UpdateHousingMaintenanceBuildingPartRatingRule */
export type UpdateHousingMaintenanceBuildingPartRatingRulePayload = {
  __typename: "UpdateHousingMaintenanceBuildingPartRatingRulePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  housingMaintenanceBuildingPartRatingRule: Maybe<HousingMaintenanceBuildingPartRatingRule>;
};

/** Autogenerated input type of UpdateInvoiceCategory */
export type UpdateInvoiceCategoryInput = {
  budget?: InputMaybe<Scalars["Int"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<InvoiceCategoryTypeEnum>;
  vatIncluded?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of UpdateInvoiceCategory */
export type UpdateInvoiceCategoryPayload = {
  __typename: "UpdateInvoiceCategoryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceCategory: Maybe<InvoiceCategory>;
};

/** Autogenerated input type of UpdateInvoiceEntryCategory */
export type UpdateInvoiceEntryCategoryInput = {
  budget?: InputMaybe<Scalars["Int"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  entryable?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<InvoiceCategoryTypeEnum>;
  vatIncluded?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of UpdateInvoiceEntryCategory */
export type UpdateInvoiceEntryCategoryPayload = {
  __typename: "UpdateInvoiceEntryCategoryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceEntryCategory: Maybe<InvoiceEntryCategory>;
};

/** Autogenerated input type of UpdateInvoiceEntry */
export type UpdateInvoiceEntryInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  categoryId?: InputMaybe<Scalars["ID"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  vatIncluded?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of UpdateInvoiceEntry */
export type UpdateInvoiceEntryPayload = {
  __typename: "UpdateInvoiceEntryPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  invoiceEntry: Maybe<InvoiceEntry>;
};

/** Autogenerated input type of UpdateMembershipRelation */
export type UpdateMembershipRelationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  relation: MembershipRelation;
};

/** Autogenerated return type of UpdateMembershipRelation */
export type UpdateMembershipRelationPayload = {
  __typename: "UpdateMembershipRelationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  membership: Maybe<Membership>;
};

/** Autogenerated input type of UpdateParagraph */
export type UpdateParagraphInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateParagraph */
export type UpdateParagraphPayload = {
  __typename: "UpdateParagraphPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  paragraph: Maybe<Paragraph>;
};

/** Autogenerated input type of UpdatePeriodLoan */
export type UpdatePeriodLoanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  equity?: InputMaybe<Scalars["Float"]>;
  id: Scalars["ID"];
  loanAmount?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of UpdatePeriodLoan */
export type UpdatePeriodLoanPayload = {
  __typename: "UpdatePeriodLoanPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  period: Maybe<ConstructionLoanPeriod>;
};

/** Autogenerated input type of UpdateReport */
export type UpdateReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateReport */
export type UpdateReportPayload = {
  __typename: "UpdateReportPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  report: Maybe<Report>;
};

/** Autogenerated input type of UpdateRequestCostBlockTemplate */
export type UpdateRequestCostBlockTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  internalUse?: InputMaybe<Scalars["Boolean"]>;
  number?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateRequestCostBlockTemplate */
export type UpdateRequestCostBlockTemplatePayload = {
  __typename: "UpdateRequestCostBlockTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestCostBlockTemplate: Maybe<RequestCostBlockTemplate>;
};

/** Autogenerated input type of UpdateRequestCostLineTemplate */
export type UpdateRequestCostLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  costTypePrimary?: InputMaybe<Scalars["String"]>;
  costTypeSecondary?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  number?: InputMaybe<Scalars["String"]>;
  requestType?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateRequestCostLineTemplate */
export type UpdateRequestCostLineTemplatePayload = {
  __typename: "UpdateRequestCostLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestCostLineTemplate: Maybe<RequestCostLineTemplate>;
};

/** Autogenerated input type of UpdateRequestLine */
export type UpdateRequestLineInput = {
  answer?: InputMaybe<Scalars["String"]>;
  answeredBy?: InputMaybe<Scalars["String"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  files?: InputMaybe<Array<Scalars["String"]>>;
  id: Scalars["ID"];
  number?: InputMaybe<Scalars["String"]>;
  reference?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateRequestLine */
export type UpdateRequestLinePayload = {
  __typename: "UpdateRequestLinePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestLine: Maybe<RequestLine>;
};

/** Autogenerated input type of UpdateRequestLineTemplate */
export type UpdateRequestLineTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  number?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateRequestLineTemplate */
export type UpdateRequestLineTemplatePayload = {
  __typename: "UpdateRequestLineTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  requestLineTemplate: Maybe<RequestLineTemplate>;
};

/** Autogenerated input type of UpdateSection */
export type UpdateSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  order?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
  visible?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of UpdateSection */
export type UpdateSectionPayload = {
  __typename: "UpdateSectionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Maybe<Array<Scalars["String"]>>;
  section: Maybe<Section>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyIds?: InputMaybe<Array<Scalars["String"]>>;
  cv?: InputMaybe<UserCvAttributes>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: InputMaybe<Scalars["String"]>;
  partner?: InputMaybe<Scalars["Boolean"]>;
  phone?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<UserRole>;
  unlockReport?: InputMaybe<Scalars["Boolean"]>;
  workingArea?: InputMaybe<WorkingAreaEnum>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename: "UpdateUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  user: Maybe<User>;
};

export type Urls = {
  __typename: "Urls";
  edit: Maybe<Scalars["String"]>;
  show: Maybe<Scalars["String"]>;
};

export type User = NodeInterface & {
  __typename: "User";
  active: Scalars["Boolean"];
  companies: Array<Company>;
  companyIds: Maybe<Array<Scalars["String"]>>;
  companyName: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  cv: Maybe<UserCv>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  name: Scalars["String"];
  partner: Scalars["Boolean"];
  phone: Maybe<Scalars["String"]>;
  photo: Maybe<Attachment>;
  reportsCount: Scalars["Int"];
  role: UserRole;
  stats: Maybe<UserStats>;
  unlockReport: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  workingArea: Maybe<Scalars["String"]>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename: "UserConnection";
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type UserCv = {
  __typename: "UserCV";
  description: Maybe<Scalars["String"]>;
  education: Maybe<Array<CvLine>>;
  experience: Maybe<Array<CvLine>>;
};

export type UserCvAttributes = {
  description: Scalars["String"];
  education: Array<CvLineAttributes>;
  experience: Array<CvLineAttributes>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Maybe<User>;
};

export enum UserRole {
  /** Admin user role */
  Admin = "ADMIN",
  /** Client user role */
  Client = "CLIENT",
  /** Collaborator user role */
  Collaborator = "COLLABORATOR",
  /** Consultant user role */
  Consultant = "CONSULTANT",
  /** Editor user role */
  Editor = "EDITOR",
}

export type UserStats = {
  __typename: "UserStats";
  buildings: BuildingMaintenanceStats;
  buildingTypes: Array<BuildingType>;
  years: Array<Scalars["Int"]>;
};

export type UserStatsBuildingsArgs = {
  type?: InputMaybe<Scalars["ID"]>;
  year: Scalars["Int"];
};

export type UserStatsBuildingTypesArgs = {
  year: Scalars["Int"];
};

export type UserStatsYearsArgs = {
  type?: InputMaybe<Scalars["ID"]>;
};

export enum UserStatus {
  /** Active user status */
  Active = "ACTIVE",
  /** Inactive user status */
  Inactive = "INACTIVE",
}

export enum WorkingAreaEnum {
  /** Construction consultant working area */
  Construction = "construction",
  /** Electrical consultant working area */
  Electrical = "electrical",
  /** Fire consultant working area */
  Fire = "fire",
  /** Plumbing consultant working area */
  Plumbing = "plumbing",
}

export enum WorkStatusEnum {
  /** Work is completed */
  Complete = "complete",
  /** Untouched, new record from template */
  Pristine = "pristine",
  /** Work is in progress */
  Touched = "touched",
}

export type EditMembershipFieldsFragment = {
  __typename: "Membership";
  id: string;
  userId: string;
  reportId: string;
  relation: MembershipRelation;
  permissions: Array<PermissionEnum>;
  report:
    | {
        __typename: "Assessment";
        id: string;
        name: string | null;
        lockedAt: string | null;
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
        company: { __typename: "Company"; id: string; name: string };
      }
    | {
        __typename: "Breeam";
        id: string;
        name: string | null;
        lockedAt: string | null;
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
        company: { __typename: "Company"; id: string; name: string };
      }
    | {
        __typename: "ConstructionLoan";
        id: string;
        name: string | null;
        lockedAt: string | null;
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
        company: { __typename: "Company"; id: string; name: string };
      }
    | {
        __typename: "HousingAssessment";
        id: string;
        name: string | null;
        lockedAt: string | null;
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
        company: { __typename: "Company"; id: string; name: string };
      }
    | {
        __typename: "MultiAssessment";
        id: string;
        name: string | null;
        lockedAt: string | null;
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
        company: { __typename: "Company"; id: string; name: string };
      }
    | {
        __typename: "MultiTDD";
        id: string;
        name: string | null;
        lockedAt: string | null;
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
        company: { __typename: "Company"; id: string; name: string };
      }
    | {
        __typename: "TDD";
        id: string;
        name: string | null;
        lockedAt: string | null;
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
        company: { __typename: "Company"; id: string; name: string };
      };
  user: {
    __typename: "User";
    id: string;
    email: string;
    name: string;
    companyIds: Array<string> | null;
    companyName: string | null;
    role: UserRole;
  };
};

export type MembershipCreateMutationVariables = Exact<{
  input: MembershipCreateInput;
}>;

export type MembershipCreateMutation = {
  __typename: "Mutation";
  membershipCreate: {
    __typename: "MembershipCreatePayload";
    errors: Array<string>;
    membership: {
      __typename: "Membership";
      id: string;
      userId: string;
      reportId: string;
      relation: MembershipRelation;
      permissions: Array<PermissionEnum>;
      report:
        | {
            __typename: "Assessment";
            id: string;
            name: string | null;
            lockedAt: string | null;
            urls: {
              __typename: "Urls";
              show: string | null;
              edit: string | null;
            } | null;
            company: { __typename: "Company"; id: string; name: string };
          }
        | {
            __typename: "Breeam";
            id: string;
            name: string | null;
            lockedAt: string | null;
            urls: {
              __typename: "Urls";
              show: string | null;
              edit: string | null;
            } | null;
            company: { __typename: "Company"; id: string; name: string };
          }
        | {
            __typename: "ConstructionLoan";
            id: string;
            name: string | null;
            lockedAt: string | null;
            urls: {
              __typename: "Urls";
              show: string | null;
              edit: string | null;
            } | null;
            company: { __typename: "Company"; id: string; name: string };
          }
        | {
            __typename: "HousingAssessment";
            id: string;
            name: string | null;
            lockedAt: string | null;
            urls: {
              __typename: "Urls";
              show: string | null;
              edit: string | null;
            } | null;
            company: { __typename: "Company"; id: string; name: string };
          }
        | {
            __typename: "MultiAssessment";
            id: string;
            name: string | null;
            lockedAt: string | null;
            urls: {
              __typename: "Urls";
              show: string | null;
              edit: string | null;
            } | null;
            company: { __typename: "Company"; id: string; name: string };
          }
        | {
            __typename: "MultiTDD";
            id: string;
            name: string | null;
            lockedAt: string | null;
            urls: {
              __typename: "Urls";
              show: string | null;
              edit: string | null;
            } | null;
            company: { __typename: "Company"; id: string; name: string };
          }
        | {
            __typename: "TDD";
            id: string;
            name: string | null;
            lockedAt: string | null;
            urls: {
              __typename: "Urls";
              show: string | null;
              edit: string | null;
            } | null;
            company: { __typename: "Company"; id: string; name: string };
          };
      user: {
        __typename: "User";
        id: string;
        email: string;
        name: string;
        companyIds: Array<string> | null;
        companyName: string | null;
        role: UserRole;
      };
    } | null;
  } | null;
};

export type MembershipFieldsFragment = {
  __typename: "Membership";
  id: string;
  userId: string;
  reportId: string;
  relation: MembershipRelation;
  permissions: Array<PermissionEnum>;
  preferences: any | null;
};

export type UpdateMembershipRelationMutationVariables = Exact<{
  input: UpdateMembershipRelationInput;
}>;

export type UpdateMembershipRelationMutation = {
  __typename: "Mutation";
  updateMembershipRelation: {
    __typename: "UpdateMembershipRelationPayload";
    errors: Array<string>;
    membership: {
      __typename: "Membership";
      id: string;
      userId: string;
      reportId: string;
      relation: MembershipRelation;
      permissions: Array<PermissionEnum>;
      preferences: any | null;
    } | null;
  } | null;
};

export type AttachmentFieldsFragment = {
  __typename: "Attachment";
  id: string;
  key: string | null;
  signedId: string;
  filename: string | null;
  contentType: string | null;
  byteSize: number | null;
  view: string | null;
  download: string | null;
  variants: {
    __typename: "ImageVariants";
    small: string;
    small2x: string;
    large: string;
    large2x: string;
  } | null;
};

export type ConstructionLoanDeliveriesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ConstructionLoanDeliveriesQuery = {
  __typename: "Query";
  constructionLoan: {
    __typename: "ConstructionLoan";
    id: string;
    deliveries: Array<{
      __typename: "Delivery";
      id: string;
      date: string;
      info: string | null;
    }>;
  } | null;
};

export type DeliveryFieldsFragment = {
  __typename: "Delivery";
  id: string;
  date: string;
  info: string | null;
};

export type DeleteFileVersionMutationVariables = Exact<{
  input: DeleteFileVersionInput;
}>;

export type DeleteFileVersionMutation = {
  __typename: "Mutation";
  deleteFileVersion: {
    __typename: "DeleteFileVersionPayload";
    errors: Array<string> | null;
    fileVersion: {
      __typename: "FileVersion";
      id: string;
      record:
        | {
            __typename: "ClientInvoice";
            id: string;
            file: {
              __typename: "Attachment";
              id: string;
              key: string | null;
              signedId: string;
              filename: string | null;
              contentType: string | null;
              byteSize: number | null;
              view: string | null;
              download: string | null;
              variants: {
                __typename: "ImageVariants";
                small: string;
                small2x: string;
                large: string;
                large2x: string;
              } | null;
            } | null;
            fileVersions: Array<{
              __typename: "FileVersion";
              id: string;
              createdAt: string;
              file: {
                __typename: "Attachment";
                id: string;
                key: string | null;
                signedId: string;
                filename: string | null;
                contentType: string | null;
                byteSize: number | null;
                view: string | null;
                download: string | null;
                variants: {
                  __typename: "ImageVariants";
                  small: string;
                  small2x: string;
                  large: string;
                  large2x: string;
                } | null;
              };
              user: {
                __typename: "User";
                id: string;
                email: string;
                name: string;
              };
            }> | null;
          }
        | {
            __typename: "ContractorInvoice";
            id: string;
            file: {
              __typename: "Attachment";
              id: string;
              key: string | null;
              signedId: string;
              filename: string | null;
              contentType: string | null;
              byteSize: number | null;
              view: string | null;
              download: string | null;
              variants: {
                __typename: "ImageVariants";
                small: string;
                small2x: string;
                large: string;
                large2x: string;
              } | null;
            } | null;
            fileVersions: Array<{
              __typename: "FileVersion";
              id: string;
              createdAt: string;
              file: {
                __typename: "Attachment";
                id: string;
                key: string | null;
                signedId: string;
                filename: string | null;
                contentType: string | null;
                byteSize: number | null;
                view: string | null;
                download: string | null;
                variants: {
                  __typename: "ImageVariants";
                  small: string;
                  small2x: string;
                  large: string;
                  large2x: string;
                } | null;
              };
              user: {
                __typename: "User";
                id: string;
                email: string;
                name: string;
              };
            }> | null;
          }
        | { __typename: "ContractorInvoiceDeposit"; id: string }
        | { __typename: "CostSection"; id: string }
        | { __typename: "CVSection"; id: string }
        | {
            __typename: "Document";
            id: string;
            file: {
              __typename: "Attachment";
              id: string;
              key: string | null;
              signedId: string;
              filename: string | null;
              contentType: string | null;
              byteSize: number | null;
              view: string | null;
              download: string | null;
              variants: {
                __typename: "ImageVariants";
                small: string;
                small2x: string;
                large: string;
                large2x: string;
              } | null;
            } | null;
            fileVersions: Array<{
              __typename: "FileVersion";
              id: string;
              createdAt: string;
              file: {
                __typename: "Attachment";
                id: string;
                key: string | null;
                signedId: string;
                filename: string | null;
                contentType: string | null;
                byteSize: number | null;
                view: string | null;
                download: string | null;
                variants: {
                  __typename: "ImageVariants";
                  small: string;
                  small2x: string;
                  large: string;
                  large2x: string;
                } | null;
              };
              user: {
                __typename: "User";
                id: string;
                email: string;
                name: string;
              };
            }> | null;
          }
        | {
            __typename: "ExternalInvoice";
            id: string;
            file: {
              __typename: "Attachment";
              id: string;
              key: string | null;
              signedId: string;
              filename: string | null;
              contentType: string | null;
              byteSize: number | null;
              view: string | null;
              download: string | null;
              variants: {
                __typename: "ImageVariants";
                small: string;
                small2x: string;
                large: string;
                large2x: string;
              } | null;
            } | null;
            fileVersions: Array<{
              __typename: "FileVersion";
              id: string;
              createdAt: string;
              file: {
                __typename: "Attachment";
                id: string;
                key: string | null;
                signedId: string;
                filename: string | null;
                contentType: string | null;
                byteSize: number | null;
                view: string | null;
                download: string | null;
                variants: {
                  __typename: "ImageVariants";
                  small: string;
                  small2x: string;
                  large: string;
                  large2x: string;
                } | null;
              };
              user: {
                __typename: "User";
                id: string;
                email: string;
                name: string;
              };
            }> | null;
          }
        | { __typename: "FileVersion"; id: string }
        | { __typename: "MultiAssessmentConnection"; id: string }
        | { __typename: "MultiReportDeviation"; id: string }
        | { __typename: "MultiTDDConnection"; id: string }
        | { __typename: "Paragraph"; id: string }
        | { __typename: "TextSection"; id: string };
    } | null;
  } | null;
};

export type FileVersionFieldsFragment = {
  __typename: "FileVersion";
  id: string;
  createdAt: string;
  file: {
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  };
  user: { __typename: "User"; id: string; email: string; name: string };
};

export type NodeFileVersionsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type NodeFileVersionsQuery = {
  __typename: "Query";
  node:
    | {
        __typename: "ClientInvoice";
        id: string;
        file: {
          __typename: "Attachment";
          id: string;
          key: string | null;
          signedId: string;
          filename: string | null;
          contentType: string | null;
          byteSize: number | null;
          view: string | null;
          download: string | null;
          variants: {
            __typename: "ImageVariants";
            small: string;
            small2x: string;
            large: string;
            large2x: string;
          } | null;
        } | null;
        fileVersions: Array<{
          __typename: "FileVersion";
          id: string;
          createdAt: string;
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          };
          user: { __typename: "User"; id: string; email: string; name: string };
        }> | null;
      }
    | {
        __typename: "ContractorInvoice";
        id: string;
        file: {
          __typename: "Attachment";
          id: string;
          key: string | null;
          signedId: string;
          filename: string | null;
          contentType: string | null;
          byteSize: number | null;
          view: string | null;
          download: string | null;
          variants: {
            __typename: "ImageVariants";
            small: string;
            small2x: string;
            large: string;
            large2x: string;
          } | null;
        } | null;
        fileVersions: Array<{
          __typename: "FileVersion";
          id: string;
          createdAt: string;
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          };
          user: { __typename: "User"; id: string; email: string; name: string };
        }> | null;
      }
    | { __typename: "ContractorInvoiceDeposit"; id: string }
    | { __typename: "CostSection"; id: string }
    | { __typename: "CVSection"; id: string }
    | {
        __typename: "Document";
        id: string;
        file: {
          __typename: "Attachment";
          id: string;
          key: string | null;
          signedId: string;
          filename: string | null;
          contentType: string | null;
          byteSize: number | null;
          view: string | null;
          download: string | null;
          variants: {
            __typename: "ImageVariants";
            small: string;
            small2x: string;
            large: string;
            large2x: string;
          } | null;
        } | null;
        fileVersions: Array<{
          __typename: "FileVersion";
          id: string;
          createdAt: string;
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          };
          user: { __typename: "User"; id: string; email: string; name: string };
        }> | null;
      }
    | {
        __typename: "ExternalInvoice";
        id: string;
        file: {
          __typename: "Attachment";
          id: string;
          key: string | null;
          signedId: string;
          filename: string | null;
          contentType: string | null;
          byteSize: number | null;
          view: string | null;
          download: string | null;
          variants: {
            __typename: "ImageVariants";
            small: string;
            small2x: string;
            large: string;
            large2x: string;
          } | null;
        } | null;
        fileVersions: Array<{
          __typename: "FileVersion";
          id: string;
          createdAt: string;
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          };
          user: { __typename: "User"; id: string; email: string; name: string };
        }> | null;
      }
    | { __typename: "FileVersion"; id: string }
    | { __typename: "MultiAssessmentConnection"; id: string }
    | { __typename: "MultiReportDeviation"; id: string }
    | { __typename: "MultiTDDConnection"; id: string }
    | { __typename: "Paragraph"; id: string }
    | { __typename: "TextSection"; id: string }
    | null;
};

export type ClientBudgetDisplayQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ClientBudgetDisplayQuery = {
  __typename: "Query";
  constructionLoan: {
    __typename: "ConstructionLoan";
    id: string;
    clientBudgetGrouped: boolean;
  } | null;
};

export type CreateInvoiceCategoryMutationVariables = Exact<{
  input: CreateInvoiceCategoryInput;
}>;

export type CreateInvoiceCategoryMutation = {
  __typename: "Mutation";
  createInvoiceCategory: {
    __typename: "CreateInvoiceCategoryPayload";
    errors: Array<string> | null;
    invoiceCategory: {
      __typename: "InvoiceCategory";
      id: string;
      report: {
        __typename: "ConstructionLoan";
        id: string;
        invoiceCategories: Array<{
          __typename: "InvoiceCategory";
          id: string;
          budget: number;
          invoicesCount: number;
          name: string;
          order: number;
          reportId: string;
          total: number;
          type: InvoiceCategoryTypeEnum;
          vatIncluded: boolean;
        }>;
      };
    } | null;
  } | null;
};

export type DeleteInvoiceCategoryMutationVariables = Exact<{
  input: DeleteInvoiceCategoryInput;
}>;

export type DeleteInvoiceCategoryMutation = {
  __typename: "Mutation";
  deleteInvoiceCategory: {
    __typename: "DeleteInvoiceCategoryPayload";
    errors: Array<string> | null;
    invoiceCategory: {
      __typename: "InvoiceCategory";
      id: string;
      report: {
        __typename: "ConstructionLoan";
        id: string;
        invoiceCategories: Array<{
          __typename: "InvoiceCategory";
          id: string;
          budget: number;
          invoicesCount: number;
          name: string;
          order: number;
          reportId: string;
          total: number;
          type: InvoiceCategoryTypeEnum;
          vatIncluded: boolean;
        }>;
      };
    } | null;
  } | null;
};

export type InvoiceCategoriesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InvoiceCategoriesQuery = {
  __typename: "Query";
  constructionLoan: {
    __typename: "ConstructionLoan";
    id: string;
    invoiceCategories: Array<{
      __typename: "InvoiceCategory";
      id: string;
      budget: number;
      invoicesCount: number;
      name: string;
      order: number;
      reportId: string;
      total: number;
      type: InvoiceCategoryTypeEnum;
      vatIncluded: boolean;
    }>;
  } | null;
};

export type InvoiceCategoriesFieldsFragment = {
  __typename: "ConstructionLoan";
  invoiceCategories: Array<{
    __typename: "InvoiceCategory";
    id: string;
    budget: number;
    invoicesCount: number;
    name: string;
    order: number;
    reportId: string;
    total: number;
    type: InvoiceCategoryTypeEnum;
    vatIncluded: boolean;
  }>;
};

export type InvoiceCategoryFieldsFragment = {
  __typename: "InvoiceCategory";
  id: string;
  budget: number;
  invoicesCount: number;
  name: string;
  order: number;
  reportId: string;
  total: number;
  type: InvoiceCategoryTypeEnum;
  vatIncluded: boolean;
};

export type SetClientBudgetDisplayMutationVariables = Exact<{
  input: ConstructionLoanUpdateInput;
}>;

export type SetClientBudgetDisplayMutation = {
  __typename: "Mutation";
  constructionLoanUpdate: {
    __typename: "ConstructionLoanUpdatePayload";
    errors: Array<string> | null;
    report: {
      __typename: "ConstructionLoan";
      id: string;
      clientBudgetGrouped: boolean;
    } | null;
  } | null;
};

export type SortInvoiceCategoryMutationVariables = Exact<{
  input: SortInvoiceCategoryInput;
}>;

export type SortInvoiceCategoryMutation = {
  __typename: "Mutation";
  sortInvoiceCategory: {
    __typename: "SortInvoiceCategoryPayload";
    report: {
      __typename: "ConstructionLoan";
      id: string;
      invoiceCategories: Array<{
        __typename: "InvoiceCategory";
        id: string;
        budget: number;
        invoicesCount: number;
        name: string;
        order: number;
        reportId: string;
        total: number;
        type: InvoiceCategoryTypeEnum;
        vatIncluded: boolean;
      }>;
    };
  } | null;
};

export type UpdateInvoiceCategoryMutationVariables = Exact<{
  input: UpdateInvoiceCategoryInput;
}>;

export type UpdateInvoiceCategoryMutation = {
  __typename: "Mutation";
  updateInvoiceCategory: {
    __typename: "UpdateInvoiceCategoryPayload";
    errors: Array<string> | null;
    invoiceCategory: {
      __typename: "InvoiceCategory";
      id: string;
      budget: number;
      invoicesCount: number;
      name: string;
      order: number;
      reportId: string;
      total: number;
      type: InvoiceCategoryTypeEnum;
      vatIncluded: boolean;
    } | null;
  } | null;
};

export type ConstructionLoanContractorsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ConstructionLoanContractorsQuery = {
  __typename: "Query";
  constructionLoan: {
    __typename: "ConstructionLoan";
    id: string;
    contractorBudgetPlanLabel: string | null;
    vatIncluded: boolean;
    contractors: Array<{
      __typename: "InvoiceContractor";
      id: string;
      name: string;
      budget: number;
    }>;
  } | null;
};

export type InvoiceContractorFieldsFragment = {
  __typename: "InvoiceContractor";
  id: string;
  name: string;
  budget: number;
};

export type CreateClientInvoiceMutationVariables = Exact<{
  input: CreateClientInvoiceInput;
}>;

export type CreateClientInvoiceMutation = {
  __typename: "Mutation";
  createClientInvoice: {
    __typename: "CreateClientInvoicePayload";
    errors: Array<string>;
    invoice: {
      __typename: "ClientInvoice";
      id: string;
      amount: number;
      currency: CurrencyEnum;
      description: string | null;
      dueDate: string | null;
      number: string;
      receivedDate: string;
      sender: string | null;
      status: WorkStatusEnum;
      total: number;
      type: string;
      period: {
        __typename: "ConstructionLoanPeriod";
        id: string;
        publishable: boolean;
        status: WorkStatusEnum;
      };
      file: {
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      } | null;
      acl: {
        __typename: "InvoiceActionPolicy";
        view: boolean;
        delete: boolean;
      };
    } | null;
  } | null;
};

export type CreateContractorInvoiceMutationVariables = Exact<{
  input: CreateContractorInvoiceInput;
}>;

export type CreateContractorInvoiceMutation = {
  __typename: "Mutation";
  createContractorInvoice: {
    __typename: "CreateContractorInvoicePayload";
    errors: Array<string>;
    invoice: {
      __typename: "ContractorInvoice";
      accordingToPlan: boolean;
      contractorId: string | null;
      hasEntries: boolean;
      multi: boolean;
      produced: number;
      id: string;
      amount: number;
      currency: CurrencyEnum;
      description: string | null;
      dueDate: string | null;
      number: string | null;
      receivedDate: string;
      sender: string | null;
      status: WorkStatusEnum;
      total: number;
      type: string;
      period: {
        __typename: "ConstructionLoanPeriod";
        id: string;
        publishable: boolean;
        status: WorkStatusEnum;
        invoices: Array<
          | { __typename: "ClientInvoice"; id: string }
          | { __typename: "ContractorInvoice"; id: string }
          | { __typename: "ExternalInvoice"; id: string }
        >;
      };
      file: {
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      } | null;
      deposit: {
        __typename: "ContractorInvoiceDeposit";
        amount: number;
        auto: number;
        manual: number | null;
      };
      acl: {
        __typename: "InvoiceActionPolicy";
        view: boolean;
        delete: boolean;
      };
    } | null;
  } | null;
};

export type CreateExternalInvoiceMutationVariables = Exact<{
  input: CreateExternalInvoiceInput;
}>;

export type CreateExternalInvoiceMutation = {
  __typename: "Mutation";
  createExternalInvoice: {
    __typename: "CreateExternalInvoicePayload";
    errors: Array<string>;
    invoice: {
      __typename: "ExternalInvoice";
      categoryId: string | null;
      id: string;
      amount: number;
      currency: CurrencyEnum;
      description: string | null;
      dueDate: string | null;
      number: string | null;
      receivedDate: string;
      sender: string | null;
      status: WorkStatusEnum;
      total: number;
      type: string;
      period: {
        __typename: "ConstructionLoanPeriod";
        id: string;
        publishable: boolean;
        status: WorkStatusEnum;
        invoices: Array<
          | { __typename: "ClientInvoice"; id: string }
          | { __typename: "ContractorInvoice"; id: string }
          | { __typename: "ExternalInvoice"; id: string }
        >;
      };
      file: {
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      } | null;
      category: {
        __typename: "InvoiceCategory";
        id: string;
        budget: number;
        invoicesCount: number;
        name: string;
        order: number;
        reportId: string;
        total: number;
        type: InvoiceCategoryTypeEnum;
        vatIncluded: boolean;
      } | null;
      acl: {
        __typename: "InvoiceActionPolicy";
        view: boolean;
        delete: boolean;
      };
    } | null;
  } | null;
};

export type DeleteInvoiceMutationVariables = Exact<{
  input: InvoiceDeleteInput;
}>;

export type DeleteInvoiceMutation = {
  __typename: "Mutation";
  deleteInvoice: {
    __typename: "InvoiceDeletePayload";
    invoice:
      | {
          __typename: "ClientInvoice";
          id: string;
          period: {
            __typename: "ConstructionLoanPeriod";
            id: string;
            publishable: boolean;
            status: WorkStatusEnum;
            invoices: Array<
              | { __typename: "ClientInvoice"; id: string }
              | { __typename: "ContractorInvoice"; id: string }
              | { __typename: "ExternalInvoice"; id: string }
            >;
          };
        }
      | {
          __typename: "ContractorInvoice";
          id: string;
          period: {
            __typename: "ConstructionLoanPeriod";
            id: string;
            publishable: boolean;
            status: WorkStatusEnum;
            invoices: Array<
              | { __typename: "ClientInvoice"; id: string }
              | { __typename: "ContractorInvoice"; id: string }
              | { __typename: "ExternalInvoice"; id: string }
            >;
          };
        }
      | {
          __typename: "ExternalInvoice";
          id: string;
          period: {
            __typename: "ConstructionLoanPeriod";
            id: string;
            publishable: boolean;
            status: WorkStatusEnum;
            invoices: Array<
              | { __typename: "ClientInvoice"; id: string }
              | { __typename: "ContractorInvoice"; id: string }
              | { __typename: "ExternalInvoice"; id: string }
            >;
          };
        }
      | null;
  } | null;
};

export type InvoiceEntryCategorizationFieldsFragment = {
  __typename: "InvoiceContractor";
  id: string;
  invoiceEntryCategorizations: Array<
    | {
        __typename: "InvoiceEntryCategory";
        id: string;
        budget: number;
        contractorId: string;
        groupId: string | null;
        name: string;
        order: number;
        total: number;
        type: InvoiceCategoryTypeEnum;
        vatIncluded: boolean;
      }
    | {
        __typename: "InvoiceEntryCategoryGroup";
        id: string;
        contractorId: string;
        name: string;
        order: number;
        categories: Array<{
          __typename: "InvoiceEntryCategory";
          id: string;
          budget: number;
          contractorId: string;
          groupId: string | null;
          name: string;
          order: number;
          total: number;
          type: InvoiceCategoryTypeEnum;
          vatIncluded: boolean;
        }>;
      }
  >;
};

export type InvoiceEntryCategorizationsQueryVariables = Exact<{
  contractorId: Scalars["ID"];
}>;

export type InvoiceEntryCategorizationsQuery = {
  __typename: "Query";
  invoiceContractor: {
    __typename: "InvoiceContractor";
    id: string;
    invoiceEntryCategorizations: Array<
      | {
          __typename: "InvoiceEntryCategory";
          id: string;
          budget: number;
          contractorId: string;
          groupId: string | null;
          name: string;
          order: number;
          total: number;
          type: InvoiceCategoryTypeEnum;
          vatIncluded: boolean;
        }
      | {
          __typename: "InvoiceEntryCategoryGroup";
          id: string;
          contractorId: string;
          name: string;
          order: number;
          categories: Array<{
            __typename: "InvoiceEntryCategory";
            id: string;
            budget: number;
            contractorId: string;
            groupId: string | null;
            name: string;
            order: number;
            total: number;
            type: InvoiceCategoryTypeEnum;
            vatIncluded: boolean;
          }>;
        }
    >;
  } | null;
};

export type CreateInvoiceEntryCategoryMutationVariables = Exact<{
  input: CreateInvoiceEntryCategoryInput;
}>;

export type CreateInvoiceEntryCategoryMutation = {
  __typename: "Mutation";
  createInvoiceEntryCategory: {
    __typename: "CreateInvoiceEntryCategoryPayload";
    invoiceEntryCategory: {
      __typename: "InvoiceEntryCategory";
      id: string;
      budget: number;
      contractorId: string;
      groupId: string | null;
      name: string;
      order: number;
      total: number;
      type: InvoiceCategoryTypeEnum;
      vatIncluded: boolean;
      contractor: {
        __typename: "InvoiceContractor";
        id: string;
        budget: number;
        invoiceEntryCategorizations: Array<
          | {
              __typename: "InvoiceEntryCategory";
              id: string;
              budget: number;
              contractorId: string;
              groupId: string | null;
              name: string;
              order: number;
              total: number;
              type: InvoiceCategoryTypeEnum;
              vatIncluded: boolean;
            }
          | {
              __typename: "InvoiceEntryCategoryGroup";
              id: string;
              contractorId: string;
              name: string;
              order: number;
              categories: Array<{
                __typename: "InvoiceEntryCategory";
                id: string;
                budget: number;
                contractorId: string;
                groupId: string | null;
                name: string;
                order: number;
                total: number;
                type: InvoiceCategoryTypeEnum;
                vatIncluded: boolean;
              }>;
            }
        >;
      };
    } | null;
  } | null;
};

export type DeleteInvoiceEntryCategoryMutationVariables = Exact<{
  input: DeleteInvoiceEntryCategoryInput;
}>;

export type DeleteInvoiceEntryCategoryMutation = {
  __typename: "Mutation";
  deleteInvoiceEntryCategory: {
    __typename: "DeleteInvoiceEntryCategoryPayload";
    errors: Array<string> | null;
    invoiceEntryCategory: {
      __typename: "InvoiceEntryCategory";
      id: string;
      contractor: {
        __typename: "InvoiceContractor";
        id: string;
        budget: number;
        invoiceEntryCategorizations: Array<
          | {
              __typename: "InvoiceEntryCategory";
              id: string;
              budget: number;
              contractorId: string;
              groupId: string | null;
              name: string;
              order: number;
              total: number;
              type: InvoiceCategoryTypeEnum;
              vatIncluded: boolean;
            }
          | {
              __typename: "InvoiceEntryCategoryGroup";
              id: string;
              contractorId: string;
              name: string;
              order: number;
              categories: Array<{
                __typename: "InvoiceEntryCategory";
                id: string;
                budget: number;
                contractorId: string;
                groupId: string | null;
                name: string;
                order: number;
                total: number;
                type: InvoiceCategoryTypeEnum;
                vatIncluded: boolean;
              }>;
            }
        >;
      };
    } | null;
  } | null;
};

export type InvoiceEntryCategoryGroupFieldsFragment = {
  __typename: "InvoiceEntryCategoryGroup";
  id: string;
  contractorId: string;
  name: string;
  order: number;
};

export type InvoiceEntryCategoryFieldsFragment = {
  __typename: "InvoiceEntryCategory";
  id: string;
  budget: number;
  contractorId: string;
  groupId: string | null;
  name: string;
  order: number;
  total: number;
  type: InvoiceCategoryTypeEnum;
  vatIncluded: boolean;
};

export type UpdateInvoiceEntryCategoryMutationVariables = Exact<{
  input: UpdateInvoiceEntryCategoryInput;
}>;

export type UpdateInvoiceEntryCategoryMutation = {
  __typename: "Mutation";
  updateInvoiceEntryCategory: {
    __typename: "UpdateInvoiceEntryCategoryPayload";
    errors: Array<string> | null;
    invoiceEntryCategory: {
      __typename: "InvoiceEntryCategory";
      id: string;
      budget: number;
      contractorId: string;
      groupId: string | null;
      name: string;
      order: number;
      total: number;
      type: InvoiceCategoryTypeEnum;
      vatIncluded: boolean;
      contractor: {
        __typename: "InvoiceContractor";
        id: string;
        budget: number;
      };
    } | null;
  } | null;
};

export type CreateInvoiceEntryMutationVariables = Exact<{
  input: CreateInvoiceEntryInput;
}>;

export type CreateInvoiceEntryMutation = {
  __typename: "Mutation";
  createInvoiceEntry: {
    __typename: "CreateInvoiceEntryPayload";
    errors: Array<string> | null;
    invoiceEntry: {
      __typename: "InvoiceEntry";
      id: string;
      amount: number;
      categoryId: string;
      createdAt: string;
      total: number;
      vatIncluded: boolean;
      invoice: {
        __typename: "ContractorInvoice";
        hasEntries: boolean;
        total: number;
        id: string;
        entries: Array<{ __typename: "InvoiceEntry"; id: string }> | null;
        deposit: {
          __typename: "ContractorInvoiceDeposit";
          amount: number;
          auto: number;
          manual: number | null;
        };
      };
    } | null;
  } | null;
};

export type DeleteInvoiceEntryMutationVariables = Exact<{
  input: DeleteInvoiceEntryInput;
}>;

export type DeleteInvoiceEntryMutation = {
  __typename: "Mutation";
  deleteInvoiceEntry: {
    __typename: "DeleteInvoiceEntryPayload";
    errors: Array<string> | null;
    invoiceEntry: {
      __typename: "InvoiceEntry";
      id: string;
      invoice: {
        __typename: "ContractorInvoice";
        hasEntries: boolean;
        total: number;
        id: string;
        status: WorkStatusEnum;
        entries: Array<{ __typename: "InvoiceEntry"; id: string }> | null;
      };
    } | null;
  } | null;
};

export type InvoiceEntriesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InvoiceEntriesQuery = {
  __typename: "Query";
  invoice:
    | { __typename: "ClientInvoice"; id: string }
    | {
        __typename: "ContractorInvoice";
        id: string;
        entries: Array<{
          __typename: "InvoiceEntry";
          id: string;
          amount: number;
          categoryId: string;
          createdAt: string;
          total: number;
          vatIncluded: boolean;
          invoice: {
            __typename: "ContractorInvoice";
            id: string;
            deposit: {
              __typename: "ContractorInvoiceDeposit";
              amount: number;
              auto: number;
              manual: number | null;
            };
          };
        }> | null;
      }
    | { __typename: "ExternalInvoice"; id: string }
    | null;
};

export type InvoiceEntryFieldsFragment = {
  __typename: "InvoiceEntry";
  id: string;
  amount: number;
  categoryId: string;
  createdAt: string;
  total: number;
  vatIncluded: boolean;
  invoice: {
    __typename: "ContractorInvoice";
    id: string;
    deposit: {
      __typename: "ContractorInvoiceDeposit";
      amount: number;
      auto: number;
      manual: number | null;
    };
  };
};

export type UpdateInvoiceEntryMutationVariables = Exact<{
  input: UpdateInvoiceEntryInput;
}>;

export type UpdateInvoiceEntryMutation = {
  __typename: "Mutation";
  updateInvoiceEntry: {
    __typename: "UpdateInvoiceEntryPayload";
    errors: Array<string> | null;
    invoiceEntry: {
      __typename: "InvoiceEntry";
      id: string;
      amount: number;
      categoryId: string;
      createdAt: string;
      total: number;
      vatIncluded: boolean;
      invoice: {
        __typename: "ContractorInvoice";
        hasEntries: boolean;
        produced: number;
        total: number;
        id: string;
        status: WorkStatusEnum;
        deposit: {
          __typename: "ContractorInvoiceDeposit";
          amount: number;
          auto: number;
          manual: number | null;
        };
      };
    } | null;
  } | null;
};

type InvoiceFields_ClientInvoice_Fragment = {
  __typename: "ClientInvoice";
  id: string;
  amount: number;
  currency: CurrencyEnum;
  description: string | null;
  dueDate: string | null;
  number: string;
  receivedDate: string;
  sender: string | null;
  status: WorkStatusEnum;
  total: number;
  type: string;
  file: {
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  } | null;
  acl: { __typename: "InvoiceActionPolicy"; view: boolean; delete: boolean };
};

type InvoiceFields_ContractorInvoice_Fragment = {
  __typename: "ContractorInvoice";
  accordingToPlan: boolean;
  contractorId: string | null;
  hasEntries: boolean;
  multi: boolean;
  produced: number;
  id: string;
  amount: number;
  currency: CurrencyEnum;
  description: string | null;
  dueDate: string | null;
  number: string | null;
  receivedDate: string;
  sender: string | null;
  status: WorkStatusEnum;
  total: number;
  type: string;
  file: {
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  } | null;
  deposit: {
    __typename: "ContractorInvoiceDeposit";
    amount: number;
    auto: number;
    manual: number | null;
  };
  acl: { __typename: "InvoiceActionPolicy"; view: boolean; delete: boolean };
};

type InvoiceFields_ExternalInvoice_Fragment = {
  __typename: "ExternalInvoice";
  categoryId: string | null;
  id: string;
  amount: number;
  currency: CurrencyEnum;
  description: string | null;
  dueDate: string | null;
  number: string | null;
  receivedDate: string;
  sender: string | null;
  status: WorkStatusEnum;
  total: number;
  type: string;
  file: {
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  } | null;
  category: {
    __typename: "InvoiceCategory";
    id: string;
    budget: number;
    invoicesCount: number;
    name: string;
    order: number;
    reportId: string;
    total: number;
    type: InvoiceCategoryTypeEnum;
    vatIncluded: boolean;
  } | null;
  acl: { __typename: "InvoiceActionPolicy"; view: boolean; delete: boolean };
};

export type InvoiceFieldsFragment =
  | InvoiceFields_ClientInvoice_Fragment
  | InvoiceFields_ContractorInvoice_Fragment
  | InvoiceFields_ExternalInvoice_Fragment;

type InvoiceUpdateFields_ClientInvoice_Fragment = {
  __typename: "ClientInvoice";
  id: string;
  amount: number;
  currency: CurrencyEnum;
  description: string | null;
  dueDate: string | null;
  number: string;
  receivedDate: string;
  sender: string | null;
  status: WorkStatusEnum;
  total: number;
  type: string;
  period: {
    __typename: "ConstructionLoanPeriod";
    id: string;
    publishable: boolean;
    status: WorkStatusEnum;
  };
  file: {
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  } | null;
  acl: { __typename: "InvoiceActionPolicy"; view: boolean; delete: boolean };
};

type InvoiceUpdateFields_ContractorInvoice_Fragment = {
  __typename: "ContractorInvoice";
  accordingToPlan: boolean;
  contractorId: string | null;
  hasEntries: boolean;
  multi: boolean;
  produced: number;
  id: string;
  amount: number;
  currency: CurrencyEnum;
  description: string | null;
  dueDate: string | null;
  number: string | null;
  receivedDate: string;
  sender: string | null;
  status: WorkStatusEnum;
  total: number;
  type: string;
  period: {
    __typename: "ConstructionLoanPeriod";
    id: string;
    publishable: boolean;
    status: WorkStatusEnum;
  };
  file: {
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  } | null;
  deposit: {
    __typename: "ContractorInvoiceDeposit";
    amount: number;
    auto: number;
    manual: number | null;
  };
  acl: { __typename: "InvoiceActionPolicy"; view: boolean; delete: boolean };
};

type InvoiceUpdateFields_ExternalInvoice_Fragment = {
  __typename: "ExternalInvoice";
  categoryId: string | null;
  id: string;
  amount: number;
  currency: CurrencyEnum;
  description: string | null;
  dueDate: string | null;
  number: string | null;
  receivedDate: string;
  sender: string | null;
  status: WorkStatusEnum;
  total: number;
  type: string;
  period: {
    __typename: "ConstructionLoanPeriod";
    id: string;
    publishable: boolean;
    status: WorkStatusEnum;
  };
  file: {
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  } | null;
  category: {
    __typename: "InvoiceCategory";
    id: string;
    budget: number;
    invoicesCount: number;
    name: string;
    order: number;
    reportId: string;
    total: number;
    type: InvoiceCategoryTypeEnum;
    vatIncluded: boolean;
  } | null;
  acl: { __typename: "InvoiceActionPolicy"; view: boolean; delete: boolean };
};

export type InvoiceUpdateFieldsFragment =
  | InvoiceUpdateFields_ClientInvoice_Fragment
  | InvoiceUpdateFields_ContractorInvoice_Fragment
  | InvoiceUpdateFields_ExternalInvoice_Fragment;

export type MigrateClientInvoiceMutationVariables = Exact<{
  input: MigrateClientInvoiceInput;
}>;

export type MigrateClientInvoiceMutation = {
  __typename: "Mutation";
  migrateClientInvoice: {
    __typename: "MigrateClientInvoicePayload";
    invoice:
      | {
          __typename: "ClientInvoice";
          id: string;
          amount: number;
          currency: CurrencyEnum;
          description: string | null;
          dueDate: string | null;
          number: string;
          receivedDate: string;
          sender: string | null;
          status: WorkStatusEnum;
          total: number;
          type: string;
          period: {
            __typename: "ConstructionLoanPeriod";
            id: string;
            publishable: boolean;
            status: WorkStatusEnum;
            invoices: Array<
              | {
                  __typename: "ClientInvoice";
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
              | {
                  __typename: "ContractorInvoice";
                  accordingToPlan: boolean;
                  contractorId: string | null;
                  hasEntries: boolean;
                  multi: boolean;
                  produced: number;
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string | null;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  deposit: {
                    __typename: "ContractorInvoiceDeposit";
                    amount: number;
                    auto: number;
                    manual: number | null;
                  };
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
              | {
                  __typename: "ExternalInvoice";
                  categoryId: string | null;
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string | null;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  category: {
                    __typename: "InvoiceCategory";
                    id: string;
                    budget: number;
                    invoicesCount: number;
                    name: string;
                    order: number;
                    reportId: string;
                    total: number;
                    type: InvoiceCategoryTypeEnum;
                    vatIncluded: boolean;
                  } | null;
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
            >;
          };
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          } | null;
          acl: {
            __typename: "InvoiceActionPolicy";
            view: boolean;
            delete: boolean;
          };
        }
      | {
          __typename: "ContractorInvoice";
          accordingToPlan: boolean;
          contractorId: string | null;
          hasEntries: boolean;
          multi: boolean;
          produced: number;
          id: string;
          amount: number;
          currency: CurrencyEnum;
          description: string | null;
          dueDate: string | null;
          number: string | null;
          receivedDate: string;
          sender: string | null;
          status: WorkStatusEnum;
          total: number;
          type: string;
          period: {
            __typename: "ConstructionLoanPeriod";
            id: string;
            publishable: boolean;
            status: WorkStatusEnum;
            invoices: Array<
              | {
                  __typename: "ClientInvoice";
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
              | {
                  __typename: "ContractorInvoice";
                  accordingToPlan: boolean;
                  contractorId: string | null;
                  hasEntries: boolean;
                  multi: boolean;
                  produced: number;
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string | null;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  deposit: {
                    __typename: "ContractorInvoiceDeposit";
                    amount: number;
                    auto: number;
                    manual: number | null;
                  };
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
              | {
                  __typename: "ExternalInvoice";
                  categoryId: string | null;
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string | null;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  category: {
                    __typename: "InvoiceCategory";
                    id: string;
                    budget: number;
                    invoicesCount: number;
                    name: string;
                    order: number;
                    reportId: string;
                    total: number;
                    type: InvoiceCategoryTypeEnum;
                    vatIncluded: boolean;
                  } | null;
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
            >;
          };
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          } | null;
          deposit: {
            __typename: "ContractorInvoiceDeposit";
            amount: number;
            auto: number;
            manual: number | null;
          };
          acl: {
            __typename: "InvoiceActionPolicy";
            view: boolean;
            delete: boolean;
          };
        }
      | {
          __typename: "ExternalInvoice";
          categoryId: string | null;
          id: string;
          amount: number;
          currency: CurrencyEnum;
          description: string | null;
          dueDate: string | null;
          number: string | null;
          receivedDate: string;
          sender: string | null;
          status: WorkStatusEnum;
          total: number;
          type: string;
          period: {
            __typename: "ConstructionLoanPeriod";
            id: string;
            publishable: boolean;
            status: WorkStatusEnum;
            invoices: Array<
              | {
                  __typename: "ClientInvoice";
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
              | {
                  __typename: "ContractorInvoice";
                  accordingToPlan: boolean;
                  contractorId: string | null;
                  hasEntries: boolean;
                  multi: boolean;
                  produced: number;
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string | null;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  deposit: {
                    __typename: "ContractorInvoiceDeposit";
                    amount: number;
                    auto: number;
                    manual: number | null;
                  };
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
              | {
                  __typename: "ExternalInvoice";
                  categoryId: string | null;
                  id: string;
                  amount: number;
                  currency: CurrencyEnum;
                  description: string | null;
                  dueDate: string | null;
                  number: string | null;
                  receivedDate: string;
                  sender: string | null;
                  status: WorkStatusEnum;
                  total: number;
                  type: string;
                  file: {
                    __typename: "Attachment";
                    id: string;
                    key: string | null;
                    signedId: string;
                    filename: string | null;
                    contentType: string | null;
                    byteSize: number | null;
                    view: string | null;
                    download: string | null;
                    variants: {
                      __typename: "ImageVariants";
                      small: string;
                      small2x: string;
                      large: string;
                      large2x: string;
                    } | null;
                  } | null;
                  category: {
                    __typename: "InvoiceCategory";
                    id: string;
                    budget: number;
                    invoicesCount: number;
                    name: string;
                    order: number;
                    reportId: string;
                    total: number;
                    type: InvoiceCategoryTypeEnum;
                    vatIncluded: boolean;
                  } | null;
                  acl: {
                    __typename: "InvoiceActionPolicy";
                    view: boolean;
                    delete: boolean;
                  };
                }
            >;
          };
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          } | null;
          category: {
            __typename: "InvoiceCategory";
            id: string;
            budget: number;
            invoicesCount: number;
            name: string;
            order: number;
            reportId: string;
            total: number;
            type: InvoiceCategoryTypeEnum;
            vatIncluded: boolean;
          } | null;
          acl: {
            __typename: "InvoiceActionPolicy";
            view: boolean;
            delete: boolean;
          };
        }
      | null;
  } | null;
};

export type UpdateContractorInvoiceMutationVariables = Exact<{
  input: UpdateContractorInvoiceInput;
}>;

export type UpdateContractorInvoiceMutation = {
  __typename: "Mutation";
  updateContractorInvoice: {
    __typename: "UpdateContractorInvoicePayload";
    errors: Array<string> | null;
    invoice: {
      __typename: "ContractorInvoice";
      accordingToPlan: boolean;
      contractorId: string | null;
      hasEntries: boolean;
      multi: boolean;
      produced: number;
      id: string;
      amount: number;
      currency: CurrencyEnum;
      description: string | null;
      dueDate: string | null;
      number: string | null;
      receivedDate: string;
      sender: string | null;
      status: WorkStatusEnum;
      total: number;
      type: string;
      period: {
        __typename: "ConstructionLoanPeriod";
        id: string;
        publishable: boolean;
        status: WorkStatusEnum;
      };
      file: {
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      } | null;
      deposit: {
        __typename: "ContractorInvoiceDeposit";
        amount: number;
        auto: number;
        manual: number | null;
      };
      acl: {
        __typename: "InvoiceActionPolicy";
        view: boolean;
        delete: boolean;
      };
    } | null;
  } | null;
};

export type UpdateExternalInvoiceMutationVariables = Exact<{
  input: UpdateExternalInvoiceInput;
}>;

export type UpdateExternalInvoiceMutation = {
  __typename: "Mutation";
  updateExternalInvoice: {
    __typename: "UpdateExternalInvoicePayload";
    errors: Array<string> | null;
    invoice: {
      __typename: "ExternalInvoice";
      categoryId: string | null;
      id: string;
      amount: number;
      currency: CurrencyEnum;
      description: string | null;
      dueDate: string | null;
      number: string | null;
      receivedDate: string;
      sender: string | null;
      status: WorkStatusEnum;
      total: number;
      type: string;
      period: {
        __typename: "ConstructionLoanPeriod";
        id: string;
        publishable: boolean;
        status: WorkStatusEnum;
      };
      file: {
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      } | null;
      category: {
        __typename: "InvoiceCategory";
        id: string;
        budget: number;
        invoicesCount: number;
        name: string;
        order: number;
        reportId: string;
        total: number;
        type: InvoiceCategoryTypeEnum;
        vatIncluded: boolean;
      } | null;
      acl: {
        __typename: "InvoiceActionPolicy";
        view: boolean;
        delete: boolean;
      };
    } | null;
  } | null;
};

export type CostSectionSummaryBuildingsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CostSectionSummaryBuildingsQuery = {
  __typename: "Query";
  node:
    | { __typename: "ClientInvoice" }
    | { __typename: "ContractorInvoice" }
    | { __typename: "ContractorInvoiceDeposit" }
    | {
        __typename: "CostSection";
        publication: {
          __typename: "MaintenanceSectionPublication";
          buildingCosts: Array<{
            __typename: "MaintenanceSectionBuildingCost";
            total: number | null;
            missingRegulationReplacement: number | null;
            missingRegulationHigh: number | null;
            missingRegulationMedium: number | null;
            missingRegulationLow: number | null;
            missingRegulationTotal: number | null;
            immediateReplacement: number | null;
            immediateHigh: number | null;
            immediateMedium: number | null;
            immediateLow: number | null;
            immediateTotal: number | null;
            intervalsReplacement: Array<number | null>;
            intervalsHigh: Array<number | null>;
            intervalsMedium: Array<number | null>;
            intervalsLow: Array<number | null>;
            intervalsTotal: Array<number | null>;
            replacementTotal: number | null;
            highTotal: number | null;
            mediumTotal: number | null;
            lowTotal: number | null;
            building: { __typename: "Building"; id: string; name: string };
          }>;
        };
      }
    | { __typename: "CVSection" }
    | { __typename: "Document" }
    | { __typename: "ExternalInvoice" }
    | { __typename: "FileVersion" }
    | { __typename: "MultiAssessmentConnection" }
    | { __typename: "MultiReportDeviation" }
    | { __typename: "MultiTDDConnection" }
    | { __typename: "Paragraph" }
    | { __typename: "TextSection" }
    | null;
};

export type CostSectionSummaryCoverageQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CostSectionSummaryCoverageQuery = {
  __typename: "Query";
  node:
    | { __typename: "ClientInvoice" }
    | { __typename: "ContractorInvoice" }
    | { __typename: "ContractorInvoiceDeposit" }
    | {
        __typename: "CostSection";
        publication: {
          __typename: "MaintenanceSectionPublication";
          cost: {
            __typename: "BuildingMaintenanceSectionCost";
            intervals: Array<number | null>;
            missingRegulationTenant: number | null;
            missingRegulationLandlord: number | null;
            missingRegulationBoth: number | null;
            missingRegulationOther: number | null;
            missingRegulation: number | null;
            immediateTenant: number | null;
            immediateLandlord: number | null;
            immediateBoth: number | null;
            immediateOther: number | null;
            immediate: number | null;
            intervalsTenant: Array<number | null>;
            intervalsLandlord: Array<number | null>;
            intervalsBoth: Array<number | null>;
            intervalsOther: Array<number | null>;
            tenant: number | null;
            landlord: number | null;
            both: number | null;
            other: number | null;
            total: number | null;
          };
        };
      }
    | { __typename: "CVSection" }
    | { __typename: "Document" }
    | { __typename: "ExternalInvoice" }
    | { __typename: "FileVersion" }
    | { __typename: "MultiAssessmentConnection" }
    | { __typename: "MultiReportDeviation" }
    | { __typename: "MultiTDDConnection" }
    | { __typename: "Paragraph" }
    | { __typename: "TextSection" }
    | null;
};

export type CostSectionSummaryInfoQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CostSectionSummaryInfoQuery = {
  __typename: "Query";
  node:
    | { __typename: "ClientInvoice" }
    | { __typename: "ContractorInvoice" }
    | { __typename: "ContractorInvoiceDeposit" }
    | {
        __typename: "CostSection";
        publication: {
          __typename: "MaintenanceSectionPublication";
          buildingCosts: Array<{
            __typename: "MaintenanceSectionBuildingCost";
            building: { __typename: "Building"; id: string; name: string };
          }>;
        };
      }
    | { __typename: "CVSection" }
    | { __typename: "Document" }
    | { __typename: "ExternalInvoice" }
    | { __typename: "FileVersion" }
    | { __typename: "MultiAssessmentConnection" }
    | { __typename: "MultiReportDeviation" }
    | { __typename: "MultiTDDConnection" }
    | { __typename: "Paragraph" }
    | { __typename: "TextSection" }
    | null;
};

export type CostSectionSummaryPriorityQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CostSectionSummaryPriorityQuery = {
  __typename: "Query";
  node:
    | { __typename: "ClientInvoice" }
    | { __typename: "ContractorInvoice" }
    | { __typename: "ContractorInvoiceDeposit" }
    | {
        __typename: "CostSection";
        publication: {
          __typename: "MaintenanceSectionPublication";
          cost: {
            __typename: "BuildingMaintenanceSectionCost";
            intervals: Array<number | null>;
            total: number | null;
            missingRegulationReplacement: number | null;
            missingRegulationHigh: number | null;
            missingRegulationMedium: number | null;
            missingRegulationLow: number | null;
            missingRegulation: number | null;
            immediateReplacement: number | null;
            immediateHigh: number | null;
            immediateMedium: number | null;
            immediateLow: number | null;
            immediate: number | null;
            intervalsReplacement: Array<number | null>;
            intervalsHigh: Array<number | null>;
            intervalsMedium: Array<number | null>;
            intervalsLow: Array<number | null>;
            replacement: number | null;
            high: number | null;
            medium: number | null;
            low: number | null;
          };
        };
      }
    | { __typename: "CVSection" }
    | { __typename: "Document" }
    | { __typename: "ExternalInvoice" }
    | { __typename: "FileVersion" }
    | { __typename: "MultiAssessmentConnection" }
    | { __typename: "MultiReportDeviation" }
    | { __typename: "MultiTDDConnection" }
    | { __typename: "Paragraph" }
    | { __typename: "TextSection" }
    | null;
};

export type ConstructionLoanBudgetQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ConstructionLoanBudgetQuery = {
  __typename: "Query";
  constructionLoan: {
    __typename: "ConstructionLoan";
    id: string;
    equity: number | null;
    loanAmount: number | null;
    budget: {
      __typename: "ConstructionLoanBudget";
      client: number;
      enterprise: number;
      plot: number;
      total: number;
    };
  } | null;
};

export type ConstructionLoanExtraFieldsFragment = {
  __typename: "ConstructionLoan";
  id: string;
  balance: number | null;
  currencies: Array<CurrencyEnum>;
  loanInfo: string | null;
  plotCost: number | null;
  plotCostPartOfLoan: boolean;
  startDate: string | null;
  vatIncluded: boolean;
};

export type ConstructionLoanCreateMutationVariables = Exact<{
  input: ConstructionLoanCreateInput;
}>;

export type ConstructionLoanCreateMutation = {
  __typename: "Mutation";
  constructionLoanCreate: {
    __typename: "ConstructionLoanCreatePayload";
    errors: Array<string>;
    report: { __typename: "ConstructionLoan"; id: string; slug: string } | null;
  } | null;
};

export type UpdateConstructionLoanMutationVariables = Exact<{
  input: ConstructionLoanUpdateInput;
}>;

export type UpdateConstructionLoanMutation = {
  __typename: "Mutation";
  constructionLoanUpdate: {
    __typename: "ConstructionLoanUpdatePayload";
    report: { __typename: "ConstructionLoan"; id: string } | null;
  } | null;
};

export type UpdateConstructionLoanExtraMutationVariables = Exact<{
  input: ConstructionLoanUpdateInput;
}>;

export type UpdateConstructionLoanExtraMutation = {
  __typename: "Mutation";
  constructionLoanUpdate: {
    __typename: "ConstructionLoanUpdatePayload";
    report: {
      __typename: "ConstructionLoan";
      id: string;
      balance: number | null;
      currencies: Array<CurrencyEnum>;
      loanInfo: string | null;
      plotCost: number | null;
      plotCostPartOfLoan: boolean;
      startDate: string | null;
      vatIncluded: boolean;
    } | null;
  } | null;
};

export type PeriodsLoanAmountQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PeriodsLoanAmountQuery = {
  __typename: "Query";
  constructionLoan: {
    __typename: "ConstructionLoan";
    id: string;
    periods: Array<{
      __typename: "ConstructionLoanPeriod";
      id: string;
      title: string;
      loanAmount: number | null;
      equity: number | null;
      published: boolean;
      publishedAt: string | null;
      startedAt: string;
    }>;
  } | null;
};

export type UpdatePeriodLoanMutationVariables = Exact<{
  input: UpdatePeriodLoanInput;
}>;

export type UpdatePeriodLoanMutation = {
  __typename: "Mutation";
  updatePeriodLoan: {
    __typename: "UpdatePeriodLoanPayload";
    errors: Array<string>;
    period: {
      __typename: "ConstructionLoanPeriod";
      id: string;
      loanAmount: number | null;
      equity: number | null;
    } | null;
  } | null;
};

export type ConstructionLoanPeriodFieldsFragment = {
  __typename: "ConstructionLoanPeriod";
  accidents: number;
  currencyDate: string | null;
  currencyLink: string | null;
  currencySource: string | null;
  dkk: number | null;
  documentsText: string | null;
  financeText: string | null;
  eur: number | null;
  full: boolean;
  id: string;
  inspectionText: string | null;
  invoiceText: string | null;
  invoicePlanText: string | null;
  issues: number;
  name: string | null;
  progressText: string | null;
  publishable: boolean;
  publishedAt: string | null;
  published: boolean;
  qualityText: string | null;
  readyForReview: boolean;
  safetyInspections: number;
  sek: number | null;
  shaFiguresText: string | null;
  shaGeneralText: string | null;
  startedAt: string;
  status: WorkStatusEnum;
  title: string;
  unpublishable: boolean;
  usd: number | null;
  files: Array<{
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  }> | null;
  payout: {
    __typename: "ConstructionLoanPeriodPayout";
    amount: number;
    information: string | null;
    manualAmount: number | null;
    recommendation: string | null;
    files: Array<{
      __typename: "Attachment";
      id: string;
      key: string | null;
      signedId: string;
      filename: string | null;
      contentType: string | null;
      byteSize: number | null;
      view: string | null;
      download: string | null;
      variants: {
        __typename: "ImageVariants";
        small: string;
        small2x: string;
        large: string;
        large2x: string;
      } | null;
    }> | null;
  };
};

export type ConstructionLoanPeriodWithInvoicesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ConstructionLoanPeriodWithInvoicesQuery = {
  __typename: "Query";
  constructionLoanPeriod: {
    __typename: "ConstructionLoanPeriod";
    accidents: number;
    currencyDate: string | null;
    currencyLink: string | null;
    currencySource: string | null;
    dkk: number | null;
    documentsText: string | null;
    financeText: string | null;
    eur: number | null;
    full: boolean;
    id: string;
    inspectionText: string | null;
    invoiceText: string | null;
    invoicePlanText: string | null;
    issues: number;
    name: string | null;
    progressText: string | null;
    publishable: boolean;
    publishedAt: string | null;
    published: boolean;
    qualityText: string | null;
    readyForReview: boolean;
    safetyInspections: number;
    sek: number | null;
    shaFiguresText: string | null;
    shaGeneralText: string | null;
    startedAt: string;
    status: WorkStatusEnum;
    title: string;
    unpublishable: boolean;
    usd: number | null;
    report: {
      __typename: "ConstructionLoan";
      id: string;
      balance: number | null;
      currencies: Array<CurrencyEnum>;
      vatIncluded: boolean;
      contractors: Array<{
        __typename: "InvoiceContractor";
        id: string;
        name: string;
        budget: number;
        invoiceEntryCategorizations: Array<
          | {
              __typename: "InvoiceEntryCategory";
              id: string;
              budget: number;
              contractorId: string;
              groupId: string | null;
              name: string;
              order: number;
              total: number;
              type: InvoiceCategoryTypeEnum;
              vatIncluded: boolean;
            }
          | {
              __typename: "InvoiceEntryCategoryGroup";
              id: string;
              contractorId: string;
              name: string;
              order: number;
              categories: Array<{
                __typename: "InvoiceEntryCategory";
                id: string;
                budget: number;
                contractorId: string;
                groupId: string | null;
                name: string;
                order: number;
                total: number;
                type: InvoiceCategoryTypeEnum;
                vatIncluded: boolean;
              }>;
            }
        >;
      }>;
      invoiceCategories: Array<{
        __typename: "InvoiceCategory";
        id: string;
        budget: number;
        invoicesCount: number;
        name: string;
        order: number;
        reportId: string;
        total: number;
        type: InvoiceCategoryTypeEnum;
        vatIncluded: boolean;
      }>;
    };
    invoices: Array<
      | {
          __typename: "ClientInvoice";
          id: string;
          amount: number;
          currency: CurrencyEnum;
          description: string | null;
          dueDate: string | null;
          number: string;
          receivedDate: string;
          sender: string | null;
          status: WorkStatusEnum;
          total: number;
          type: string;
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          } | null;
          acl: {
            __typename: "InvoiceActionPolicy";
            view: boolean;
            delete: boolean;
          };
        }
      | {
          __typename: "ContractorInvoice";
          accordingToPlan: boolean;
          contractorId: string | null;
          hasEntries: boolean;
          multi: boolean;
          produced: number;
          id: string;
          amount: number;
          currency: CurrencyEnum;
          description: string | null;
          dueDate: string | null;
          number: string | null;
          receivedDate: string;
          sender: string | null;
          status: WorkStatusEnum;
          total: number;
          type: string;
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          } | null;
          deposit: {
            __typename: "ContractorInvoiceDeposit";
            amount: number;
            auto: number;
            manual: number | null;
          };
          acl: {
            __typename: "InvoiceActionPolicy";
            view: boolean;
            delete: boolean;
          };
        }
      | {
          __typename: "ExternalInvoice";
          categoryId: string | null;
          id: string;
          amount: number;
          currency: CurrencyEnum;
          description: string | null;
          dueDate: string | null;
          number: string | null;
          receivedDate: string;
          sender: string | null;
          status: WorkStatusEnum;
          total: number;
          type: string;
          file: {
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          } | null;
          category: {
            __typename: "InvoiceCategory";
            id: string;
            budget: number;
            invoicesCount: number;
            name: string;
            order: number;
            reportId: string;
            total: number;
            type: InvoiceCategoryTypeEnum;
            vatIncluded: boolean;
          } | null;
          acl: {
            __typename: "InvoiceActionPolicy";
            view: boolean;
            delete: boolean;
          };
        }
    >;
    files: Array<{
      __typename: "Attachment";
      id: string;
      key: string | null;
      signedId: string;
      filename: string | null;
      contentType: string | null;
      byteSize: number | null;
      view: string | null;
      download: string | null;
      variants: {
        __typename: "ImageVariants";
        small: string;
        small2x: string;
        large: string;
        large2x: string;
      } | null;
    }> | null;
    payout: {
      __typename: "ConstructionLoanPeriodPayout";
      amount: number;
      information: string | null;
      manualAmount: number | null;
      recommendation: string | null;
      files: Array<{
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      }> | null;
    };
  } | null;
};

export type ConstructionLoanPeriodsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ConstructionLoanPeriodsQuery = {
  __typename: "Query";
  constructionLoan: {
    __typename: "ConstructionLoan";
    id: string;
    periods: Array<{
      __typename: "ConstructionLoanPeriod";
      id: string;
      full: boolean;
      name: string | null;
      published: boolean;
      publishedAt: string | null;
      startedAt: string;
      status: WorkStatusEnum;
      acl: {
        __typename: "PeriodActionPolicy";
        view: boolean;
        edit: boolean;
        delete: boolean;
      };
    }>;
  } | null;
};

export type CreateConstructionLoanPeriodMutationVariables = Exact<{
  input: CreateConstructionLoanPeriodInput;
}>;

export type CreateConstructionLoanPeriodMutation = {
  __typename: "Mutation";
  createConstructionLoanPeriod: {
    __typename: "CreateConstructionLoanPeriodPayload";
    period: {
      __typename: "ConstructionLoanPeriod";
      accidents: number;
      currencyDate: string | null;
      currencyLink: string | null;
      currencySource: string | null;
      dkk: number | null;
      documentsText: string | null;
      financeText: string | null;
      eur: number | null;
      full: boolean;
      id: string;
      inspectionText: string | null;
      invoiceText: string | null;
      invoicePlanText: string | null;
      issues: number;
      name: string | null;
      progressText: string | null;
      publishable: boolean;
      publishedAt: string | null;
      published: boolean;
      qualityText: string | null;
      readyForReview: boolean;
      safetyInspections: number;
      sek: number | null;
      shaFiguresText: string | null;
      shaGeneralText: string | null;
      startedAt: string;
      status: WorkStatusEnum;
      title: string;
      unpublishable: boolean;
      usd: number | null;
      report: {
        __typename: "ConstructionLoan";
        id: string;
        periods: Array<{ __typename: "ConstructionLoanPeriod"; id: string }>;
      };
      files: Array<{
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      }> | null;
      payout: {
        __typename: "ConstructionLoanPeriodPayout";
        amount: number;
        information: string | null;
        manualAmount: number | null;
        recommendation: string | null;
        files: Array<{
          __typename: "Attachment";
          id: string;
          key: string | null;
          signedId: string;
          filename: string | null;
          contentType: string | null;
          byteSize: number | null;
          view: string | null;
          download: string | null;
          variants: {
            __typename: "ImageVariants";
            small: string;
            small2x: string;
            large: string;
            large2x: string;
          } | null;
        }> | null;
      };
    } | null;
  } | null;
};

export type DeleteConstructionLoanPeriodMutationVariables = Exact<{
  input: DeleteConstructionLoanPeriodInput;
}>;

export type DeleteConstructionLoanPeriodMutation = {
  __typename: "Mutation";
  deleteConstructionLoanPeriod: {
    __typename: "DeleteConstructionLoanPeriodPayload";
    period: {
      __typename: "ConstructionLoanPeriod";
      report: {
        __typename: "ConstructionLoan";
        id: string;
        periods: Array<{ __typename: "ConstructionLoanPeriod"; id: string }>;
      };
    } | null;
  } | null;
};

export type PublishConstructionLoanPeriodMutationVariables = Exact<{
  input: PublishConstructionLoanPeriodInput;
}>;

export type PublishConstructionLoanPeriodMutation = {
  __typename: "Mutation";
  publishConstructionLoanPeriod: {
    __typename: "PublishConstructionLoanPeriodPayload";
    period: {
      __typename: "ConstructionLoanPeriod";
      accidents: number;
      currencyDate: string | null;
      currencyLink: string | null;
      currencySource: string | null;
      dkk: number | null;
      documentsText: string | null;
      financeText: string | null;
      eur: number | null;
      full: boolean;
      id: string;
      inspectionText: string | null;
      invoiceText: string | null;
      invoicePlanText: string | null;
      issues: number;
      name: string | null;
      progressText: string | null;
      publishable: boolean;
      publishedAt: string | null;
      published: boolean;
      qualityText: string | null;
      readyForReview: boolean;
      safetyInspections: number;
      sek: number | null;
      shaFiguresText: string | null;
      shaGeneralText: string | null;
      startedAt: string;
      status: WorkStatusEnum;
      title: string;
      unpublishable: boolean;
      usd: number | null;
      files: Array<{
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      }> | null;
      payout: {
        __typename: "ConstructionLoanPeriodPayout";
        amount: number;
        information: string | null;
        manualAmount: number | null;
        recommendation: string | null;
        files: Array<{
          __typename: "Attachment";
          id: string;
          key: string | null;
          signedId: string;
          filename: string | null;
          contentType: string | null;
          byteSize: number | null;
          view: string | null;
          download: string | null;
          variants: {
            __typename: "ImageVariants";
            small: string;
            small2x: string;
            large: string;
            large2x: string;
          } | null;
        }> | null;
      };
    } | null;
  } | null;
};

export type PeriodReadyForReviewMutationVariables = Exact<{
  input: ReadyForReviewInput;
}>;

export type PeriodReadyForReviewMutation = {
  __typename: "Mutation";
  constructionLoanPeriods: {
    __typename: "PeriodMutationsPayload";
    readyForReview: {
      __typename: "ReadyForReviewPayload";
      errors: Array<string>;
      period: {
        __typename: "ConstructionLoanPeriod";
        id: string;
        title: string;
        readyForReview: boolean;
      } | null;
    } | null;
  } | null;
};

export type UnpublishConstructionLoanPeriodMutationVariables = Exact<{
  input: UnpublishConstructionLoanPeriodInput;
}>;

export type UnpublishConstructionLoanPeriodMutation = {
  __typename: "Mutation";
  unpublishConstructionLoanPeriod: {
    __typename: "UnpublishConstructionLoanPeriodPayload";
    period: {
      __typename: "ConstructionLoanPeriod";
      accidents: number;
      currencyDate: string | null;
      currencyLink: string | null;
      currencySource: string | null;
      dkk: number | null;
      documentsText: string | null;
      financeText: string | null;
      eur: number | null;
      full: boolean;
      id: string;
      inspectionText: string | null;
      invoiceText: string | null;
      invoicePlanText: string | null;
      issues: number;
      name: string | null;
      progressText: string | null;
      publishable: boolean;
      publishedAt: string | null;
      published: boolean;
      qualityText: string | null;
      readyForReview: boolean;
      safetyInspections: number;
      sek: number | null;
      shaFiguresText: string | null;
      shaGeneralText: string | null;
      startedAt: string;
      status: WorkStatusEnum;
      title: string;
      unpublishable: boolean;
      usd: number | null;
      files: Array<{
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      }> | null;
      payout: {
        __typename: "ConstructionLoanPeriodPayout";
        amount: number;
        information: string | null;
        manualAmount: number | null;
        recommendation: string | null;
        files: Array<{
          __typename: "Attachment";
          id: string;
          key: string | null;
          signedId: string;
          filename: string | null;
          contentType: string | null;
          byteSize: number | null;
          view: string | null;
          download: string | null;
          variants: {
            __typename: "ImageVariants";
            small: string;
            small2x: string;
            large: string;
            large2x: string;
          } | null;
        }> | null;
      };
    } | null;
  } | null;
};

export type UpdateConstructionLoanPeriodMutationVariables = Exact<{
  input: UpdateConstructionLoanPeriodInput;
}>;

export type UpdateConstructionLoanPeriodMutation = {
  __typename: "Mutation";
  updateConstructionLoanPeriod: {
    __typename: "UpdateConstructionLoanPeriodPayload";
    period: {
      __typename: "ConstructionLoanPeriod";
      accidents: number;
      currencyDate: string | null;
      currencyLink: string | null;
      currencySource: string | null;
      dkk: number | null;
      documentsText: string | null;
      financeText: string | null;
      eur: number | null;
      full: boolean;
      id: string;
      inspectionText: string | null;
      invoiceText: string | null;
      invoicePlanText: string | null;
      issues: number;
      name: string | null;
      progressText: string | null;
      publishable: boolean;
      publishedAt: string | null;
      published: boolean;
      qualityText: string | null;
      readyForReview: boolean;
      safetyInspections: number;
      sek: number | null;
      shaFiguresText: string | null;
      shaGeneralText: string | null;
      startedAt: string;
      status: WorkStatusEnum;
      title: string;
      unpublishable: boolean;
      usd: number | null;
      files: Array<{
        __typename: "Attachment";
        id: string;
        key: string | null;
        signedId: string;
        filename: string | null;
        contentType: string | null;
        byteSize: number | null;
        view: string | null;
        download: string | null;
        variants: {
          __typename: "ImageVariants";
          small: string;
          small2x: string;
          large: string;
          large2x: string;
        } | null;
      }> | null;
      payout: {
        __typename: "ConstructionLoanPeriodPayout";
        amount: number;
        information: string | null;
        manualAmount: number | null;
        recommendation: string | null;
        files: Array<{
          __typename: "Attachment";
          id: string;
          key: string | null;
          signedId: string;
          filename: string | null;
          contentType: string | null;
          byteSize: number | null;
          view: string | null;
          download: string | null;
          variants: {
            __typename: "ImageVariants";
            small: string;
            small2x: string;
            large: string;
            large2x: string;
          } | null;
        }> | null;
      };
    } | null;
  } | null;
};

export type ReportSearchQueryVariables = Exact<{
  query: Scalars["String"];
  companyId?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  type?: InputMaybe<Array<ReportType> | ReportType>;
}>;

export type ReportSearchQuery = {
  __typename: "Query";
  reportSearch: Array<
    | {
        __typename: "Assessment";
        id: string;
        slug: string;
        name: string | null;
        lockedAt: string | null;
        company: { __typename: "Company"; id: string; name: string };
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
      }
    | {
        __typename: "Breeam";
        id: string;
        slug: string;
        name: string | null;
        lockedAt: string | null;
        company: { __typename: "Company"; id: string; name: string };
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
      }
    | {
        __typename: "ConstructionLoan";
        id: string;
        slug: string;
        name: string | null;
        lockedAt: string | null;
        company: { __typename: "Company"; id: string; name: string };
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
      }
    | {
        __typename: "HousingAssessment";
        id: string;
        slug: string;
        name: string | null;
        lockedAt: string | null;
        company: { __typename: "Company"; id: string; name: string };
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
      }
    | {
        __typename: "MultiAssessment";
        id: string;
        slug: string;
        name: string | null;
        lockedAt: string | null;
        company: { __typename: "Company"; id: string; name: string };
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
      }
    | {
        __typename: "MultiTDD";
        id: string;
        slug: string;
        name: string | null;
        lockedAt: string | null;
        company: { __typename: "Company"; id: string; name: string };
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
      }
    | {
        __typename: "TDD";
        id: string;
        slug: string;
        name: string | null;
        lockedAt: string | null;
        company: { __typename: "Company"; id: string; name: string };
        urls: {
          __typename: "Urls";
          show: string | null;
          edit: string | null;
        } | null;
      }
  >;
};

export type ReportHeroQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type ReportHeroQuery = {
  __typename: "Query";
  report:
    | {
        __typename: "Assessment";
        id: string;
        name: string | null;
        lockedAt: string | null;
        originalReportDate: string | null;
        type: ReportType;
        typeManual: string;
      }
    | {
        __typename: "Breeam";
        id: string;
        name: string | null;
        lockedAt: string | null;
        originalReportDate: string | null;
        type: ReportType;
        typeManual: string;
      }
    | {
        __typename: "ConstructionLoan";
        id: string;
        name: string | null;
        lockedAt: string | null;
        originalReportDate: string | null;
        type: ReportType;
        typeManual: string;
      }
    | {
        __typename: "HousingAssessment";
        id: string;
        name: string | null;
        lockedAt: string | null;
        originalReportDate: string | null;
        type: ReportType;
        typeManual: string;
      }
    | {
        __typename: "MultiAssessment";
        id: string;
        name: string | null;
        lockedAt: string | null;
        originalReportDate: string | null;
        type: ReportType;
        typeManual: string;
      }
    | {
        __typename: "MultiTDD";
        id: string;
        name: string | null;
        lockedAt: string | null;
        originalReportDate: string | null;
        type: ReportType;
        typeManual: string;
      }
    | {
        __typename: "TDD";
        id: string;
        name: string | null;
        lockedAt: string | null;
        originalReportDate: string | null;
        type: ReportType;
        typeManual: string;
      }
    | null;
};

export type EditAudienceQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type EditAudienceQuery = {
  __typename: "Query";
  report:
    | {
        __typename: "Assessment";
        id: string;
        client: {
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        } | null;
        associates: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
        collaborators: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
      }
    | {
        __typename: "Breeam";
        id: string;
        client: {
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        } | null;
        associates: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
        collaborators: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
      }
    | {
        __typename: "ConstructionLoan";
        id: string;
        client: {
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        } | null;
        associates: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
        collaborators: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
      }
    | {
        __typename: "HousingAssessment";
        id: string;
        client: {
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        } | null;
        associates: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
        collaborators: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
      }
    | {
        __typename: "MultiAssessment";
        id: string;
        client: {
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        } | null;
        associates: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
        collaborators: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
      }
    | {
        __typename: "MultiTDD";
        id: string;
        client: {
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        } | null;
        associates: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
        collaborators: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
      }
    | {
        __typename: "TDD";
        id: string;
        client: {
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        } | null;
        associates: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
        collaborators: Array<{
          __typename: "Member";
          membership: {
            __typename: "Membership";
            id: string;
            permissions: Array<PermissionEnum>;
            preferences: any | null;
            relation: MembershipRelation;
          } | null;
          user: {
            __typename: "User";
            id: string;
            companyName: string | null;
            name: string;
            email: string;
            role: UserRole;
          } | null;
        }>;
      }
    | null;
};

export type EditMemberFieldsFragment = {
  __typename: "Member";
  membership: {
    __typename: "Membership";
    id: string;
    permissions: Array<PermissionEnum>;
    preferences: any | null;
    relation: MembershipRelation;
  } | null;
  user: {
    __typename: "User";
    id: string;
    companyName: string | null;
    name: string;
    email: string;
    role: UserRole;
  } | null;
};

export type MemberFieldsFragment = {
  __typename: "Member";
  membershipId: string;
  reportId: string;
  userId: string;
  email: string;
  name: string | null;
  companyIds: Array<string> | null;
  companyName: string | null;
  relation: string;
  permissions: Array<PermissionEnum>;
  preferences: string | null;
  role: string;
  createdAt: string;
};

export type ReportRemoveClientMutationVariables = Exact<{
  input: ReportRemoveClientInput;
}>;

export type ReportRemoveClientMutation = {
  __typename: "Mutation";
  reportRemoveClient:
    | {
        __typename: "Assessment";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "Breeam";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "ConstructionLoan";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "HousingAssessment";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "MultiAssessment";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "MultiTDD";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "TDD";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | null;
};

export type ReportSetClientMutationVariables = Exact<{
  input: ReportSetClientInput;
}>;

export type ReportSetClientMutation = {
  __typename: "Mutation";
  reportSetClient:
    | {
        __typename: "Assessment";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "Breeam";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "ConstructionLoan";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "HousingAssessment";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "MultiAssessment";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "MultiTDD";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | {
        __typename: "TDD";
        id: string;
        client: {
          __typename: "Member";
          membershipId: string;
          reportId: string;
          userId: string;
          email: string;
          name: string | null;
          companyIds: Array<string> | null;
          companyName: string | null;
          relation: string;
          permissions: Array<PermissionEnum>;
          preferences: string | null;
          role: string;
          createdAt: string;
        } | null;
      }
    | null;
};

export type CreateMultiAssessmentMutationVariables = Exact<{
  input: CreateMultiAssessmentInput;
}>;

export type CreateMultiAssessmentMutation = {
  __typename: "Mutation";
  createMultiAssessment: {
    __typename: "CreateMultiAssessmentPayload";
    errors: Array<string>;
    report: { __typename: "MultiAssessment"; id: string; slug: string } | null;
  } | null;
};

export type CreateMultiTddMutationVariables = Exact<{
  input: CreateMultiTddInput;
}>;

export type CreateMultiTddMutation = {
  __typename: "Mutation";
  createMultiTDD: {
    __typename: "CreateMultiTDDPayload";
    errors: Array<string>;
    report: { __typename: "MultiTDD"; id: string; slug: string } | null;
  } | null;
};

export type RegeneratePdfMutationVariables = Exact<{
  input: RegeneratePdfInput;
}>;

export type RegeneratePdfMutation = {
  __typename: "Mutation";
  regeneratePdf: {
    __typename: "RegeneratePdfPayload";
    errors: Array<string>;
    status: string;
  } | null;
};

type ReportCompanyFields_Assessment_Fragment = {
  __typename: "Assessment";
  id: string;
  name: string | null;
  lockedAt: string | null;
  company: { __typename: "Company"; id: string; name: string };
};

type ReportCompanyFields_Breeam_Fragment = {
  __typename: "Breeam";
  id: string;
  name: string | null;
  lockedAt: string | null;
  company: { __typename: "Company"; id: string; name: string };
};

type ReportCompanyFields_ConstructionLoan_Fragment = {
  __typename: "ConstructionLoan";
  id: string;
  name: string | null;
  lockedAt: string | null;
  company: { __typename: "Company"; id: string; name: string };
};

type ReportCompanyFields_HousingAssessment_Fragment = {
  __typename: "HousingAssessment";
  id: string;
  name: string | null;
  lockedAt: string | null;
  company: { __typename: "Company"; id: string; name: string };
};

type ReportCompanyFields_MultiAssessment_Fragment = {
  __typename: "MultiAssessment";
  id: string;
  name: string | null;
  lockedAt: string | null;
  company: { __typename: "Company"; id: string; name: string };
};

type ReportCompanyFields_MultiTdd_Fragment = {
  __typename: "MultiTDD";
  id: string;
  name: string | null;
  lockedAt: string | null;
  company: { __typename: "Company"; id: string; name: string };
};

type ReportCompanyFields_Tdd_Fragment = {
  __typename: "TDD";
  id: string;
  name: string | null;
  lockedAt: string | null;
  company: { __typename: "Company"; id: string; name: string };
};

export type ReportCompanyFieldsFragment =
  | ReportCompanyFields_Assessment_Fragment
  | ReportCompanyFields_Breeam_Fragment
  | ReportCompanyFields_ConstructionLoan_Fragment
  | ReportCompanyFields_HousingAssessment_Fragment
  | ReportCompanyFields_MultiAssessment_Fragment
  | ReportCompanyFields_MultiTdd_Fragment
  | ReportCompanyFields_Tdd_Fragment;

export type BoligselskapTiltakslisteQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type BoligselskapTiltakslisteQuery = {
  __typename: "Query";
  report:
    | { __typename: "Assessment"; id: string }
    | { __typename: "Breeam"; id: string }
    | { __typename: "ConstructionLoan"; id: string }
    | {
        __typename: "HousingAssessment";
        id: string;
        housingMaintenanceSection: {
          __typename: "HousingMaintenanceSection";
          displayableBuildingParts: Array<{
            __typename: "HousingMaintenanceBuildingPart";
            id: string;
            name: string | null;
            kg: number | null;
            tg: number | null;
            comment: string | null;
            description: string | null;
            descriptionDeviation: string | null;
          }>;
        } | null;
      }
    | { __typename: "MultiAssessment"; id: string }
    | { __typename: "MultiTDD"; id: string }
    | { __typename: "TDD"; id: string }
    | null;
};

export type CostOverviewChartQueryVariables = Exact<{
  slug: Scalars["ID"];
  period?: InputMaybe<Array<MaintenancePeriodEnum> | MaintenancePeriodEnum>;
  priority?: InputMaybe<
    Array<MaintenancePriorityEnum> | MaintenancePriorityEnum
  >;
}>;

export type CostOverviewChartQuery = {
  __typename: "Query";
  report:
    | {
        __typename: "Assessment";
        id: string;
        cost: {
          __typename: "ReportCost";
          electrical: number | null;
          construction: number | null;
          plumbing: number | null;
          fire: number | null;
        };
      }
    | {
        __typename: "Breeam";
        id: string;
        cost: {
          __typename: "ReportCost";
          electrical: number | null;
          construction: number | null;
          plumbing: number | null;
          fire: number | null;
        };
      }
    | { __typename: "ConstructionLoan"; id: string }
    | { __typename: "HousingAssessment"; id: string }
    | { __typename: "MultiAssessment"; id: string }
    | { __typename: "MultiTDD"; id: string }
    | {
        __typename: "TDD";
        id: string;
        cost: {
          __typename: "ReportCost";
          electrical: number | null;
          construction: number | null;
          plumbing: number | null;
          fire: number | null;
        };
      }
    | null;
};

export type CostOverviewTableQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type CostOverviewTableQuery = {
  __typename: "Query";
  report:
    | {
        __typename: "Assessment";
        id: string;
        cost: {
          __typename: "ReportCost";
          missingRegulationElectrical: number | null;
          missingRegulationConstruction: number | null;
          missingRegulationPlumbing: number | null;
          missingRegulationTotal: number | null;
          immediateActionElectrical: number | null;
          immediateActionConstruction: number | null;
          immediateActionPlumbing: number | null;
          immediateActionTotal: number | null;
          oneToFiveHighElectrical: number | null;
          oneToFiveHighConstruction: number | null;
          oneToFiveHighPlumbing: number | null;
          oneToFiveHighTotal: number | null;
          oneToFiveMediumElectrical: number | null;
          oneToFiveMediumConstruction: number | null;
          oneToFiveMediumPlumbing: number | null;
          oneToFiveMediumTotal: number | null;
          oneToFiveLowElectrical: number | null;
          oneToFiveLowConstruction: number | null;
          oneToFiveLowPlumbing: number | null;
          oneToFiveLowTotal: number | null;
          oneToFiveReplacementElectrical: number | null;
          oneToFiveReplacementConstruction: number | null;
          oneToFiveReplacementPlumbing: number | null;
          oneToFiveReplacementTotal: number | null;
          sixToTenHighElectrical: number | null;
          sixToTenHighConstruction: number | null;
          sixToTenHighPlumbing: number | null;
          sixToTenHighTotal: number | null;
          sixToTenMediumElectrical: number | null;
          sixToTenMediumConstruction: number | null;
          sixToTenMediumPlumbing: number | null;
          sixToTenMediumTotal: number | null;
          sixToTenLowElectrical: number | null;
          sixToTenLowConstruction: number | null;
          sixToTenLowPlumbing: number | null;
          sixToTenLowTotal: number | null;
          sixToTenReplacementElectrical: number | null;
          sixToTenReplacementConstruction: number | null;
          sixToTenReplacementPlumbing: number | null;
          sixToTenReplacementTotal: number | null;
          oneToFiveTotal: number | null;
          sixToTenTotal: number | null;
          constructionTotal: number | null;
          electricalTotal: number | null;
          plumbingTotal: number | null;
          total: number | null;
        };
      }
    | {
        __typename: "Breeam";
        id: string;
        cost: {
          __typename: "ReportCost";
          missingRegulationElectrical: number | null;
          missingRegulationConstruction: number | null;
          missingRegulationPlumbing: number | null;
          missingRegulationTotal: number | null;
          immediateActionElectrical: number | null;
          immediateActionConstruction: number | null;
          immediateActionPlumbing: number | null;
          immediateActionTotal: number | null;
          oneToFiveHighElectrical: number | null;
          oneToFiveHighConstruction: number | null;
          oneToFiveHighPlumbing: number | null;
          oneToFiveHighTotal: number | null;
          oneToFiveMediumElectrical: number | null;
          oneToFiveMediumConstruction: number | null;
          oneToFiveMediumPlumbing: number | null;
          oneToFiveMediumTotal: number | null;
          oneToFiveLowElectrical: number | null;
          oneToFiveLowConstruction: number | null;
          oneToFiveLowPlumbing: number | null;
          oneToFiveLowTotal: number | null;
          oneToFiveReplacementElectrical: number | null;
          oneToFiveReplacementConstruction: number | null;
          oneToFiveReplacementPlumbing: number | null;
          oneToFiveReplacementTotal: number | null;
          sixToTenHighElectrical: number | null;
          sixToTenHighConstruction: number | null;
          sixToTenHighPlumbing: number | null;
          sixToTenHighTotal: number | null;
          sixToTenMediumElectrical: number | null;
          sixToTenMediumConstruction: number | null;
          sixToTenMediumPlumbing: number | null;
          sixToTenMediumTotal: number | null;
          sixToTenLowElectrical: number | null;
          sixToTenLowConstruction: number | null;
          sixToTenLowPlumbing: number | null;
          sixToTenLowTotal: number | null;
          sixToTenReplacementElectrical: number | null;
          sixToTenReplacementConstruction: number | null;
          sixToTenReplacementPlumbing: number | null;
          sixToTenReplacementTotal: number | null;
          oneToFiveTotal: number | null;
          sixToTenTotal: number | null;
          constructionTotal: number | null;
          electricalTotal: number | null;
          plumbingTotal: number | null;
          total: number | null;
        };
      }
    | { __typename: "ConstructionLoan"; id: string }
    | { __typename: "HousingAssessment"; id: string }
    | { __typename: "MultiAssessment"; id: string }
    | { __typename: "MultiTDD"; id: string }
    | {
        __typename: "TDD";
        id: string;
        cost: {
          __typename: "ReportCost";
          missingRegulationElectrical: number | null;
          missingRegulationConstruction: number | null;
          missingRegulationPlumbing: number | null;
          missingRegulationTotal: number | null;
          immediateActionElectrical: number | null;
          immediateActionConstruction: number | null;
          immediateActionPlumbing: number | null;
          immediateActionTotal: number | null;
          oneToFiveHighElectrical: number | null;
          oneToFiveHighConstruction: number | null;
          oneToFiveHighPlumbing: number | null;
          oneToFiveHighTotal: number | null;
          oneToFiveMediumElectrical: number | null;
          oneToFiveMediumConstruction: number | null;
          oneToFiveMediumPlumbing: number | null;
          oneToFiveMediumTotal: number | null;
          oneToFiveLowElectrical: number | null;
          oneToFiveLowConstruction: number | null;
          oneToFiveLowPlumbing: number | null;
          oneToFiveLowTotal: number | null;
          oneToFiveReplacementElectrical: number | null;
          oneToFiveReplacementConstruction: number | null;
          oneToFiveReplacementPlumbing: number | null;
          oneToFiveReplacementTotal: number | null;
          sixToTenHighElectrical: number | null;
          sixToTenHighConstruction: number | null;
          sixToTenHighPlumbing: number | null;
          sixToTenHighTotal: number | null;
          sixToTenMediumElectrical: number | null;
          sixToTenMediumConstruction: number | null;
          sixToTenMediumPlumbing: number | null;
          sixToTenMediumTotal: number | null;
          sixToTenLowElectrical: number | null;
          sixToTenLowConstruction: number | null;
          sixToTenLowPlumbing: number | null;
          sixToTenLowTotal: number | null;
          sixToTenReplacementElectrical: number | null;
          sixToTenReplacementConstruction: number | null;
          sixToTenReplacementPlumbing: number | null;
          sixToTenReplacementTotal: number | null;
          oneToFiveTotal: number | null;
          sixToTenTotal: number | null;
          constructionTotal: number | null;
          electricalTotal: number | null;
          plumbingTotal: number | null;
          total: number | null;
        };
      }
    | null;
};

export type DokumentasjonsKarakterQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type DokumentasjonsKarakterQuery = {
  __typename: "Query";
  report:
    | {
        __typename: "Assessment";
        documentationSection: {
          __typename: "DocumentationSection";
          id: string;
          grade: string | null;
          score: number | null;
        } | null;
      }
    | {
        __typename: "Breeam";
        documentationSection: {
          __typename: "DocumentationSection";
          id: string;
          grade: string | null;
          score: number | null;
        } | null;
      }
    | { __typename: "ConstructionLoan" }
    | { __typename: "HousingAssessment" }
    | { __typename: "MultiAssessment" }
    | { __typename: "MultiTDD" }
    | { __typename: "TDD" }
    | null;
};

export type KostnadsfordelingTabellQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type KostnadsfordelingTabellQuery = {
  __typename: "Query";
  report:
    | {
        __typename: "Assessment";
        id: string;
        cost: {
          __typename: "ReportCost";
          coverageTenant: number | null;
          coverageLandlord: number | null;
          coverageBoth: number | null;
          coverageTenantConstruction: number | null;
          coverageTenantElectrical: number | null;
          coverageTenantPlumbing: number | null;
          coverageTenantFire: number | null;
          coverageLandlordConstruction: number | null;
          coverageLandlordElectrical: number | null;
          coverageLandlordPlumbing: number | null;
          coverageLandlordFire: number | null;
          coverageBothConstruction: number | null;
          coverageBothElectrical: number | null;
          coverageBothPlumbing: number | null;
          coverageBothFire: number | null;
          totalConstruction: number | null;
          totalElectrical: number | null;
          totalPlumbing: number | null;
          totalFire: number | null;
          total: number | null;
        };
      }
    | {
        __typename: "Breeam";
        id: string;
        cost: {
          __typename: "ReportCost";
          coverageTenant: number | null;
          coverageLandlord: number | null;
          coverageBoth: number | null;
          coverageTenantConstruction: number | null;
          coverageTenantElectrical: number | null;
          coverageTenantPlumbing: number | null;
          coverageTenantFire: number | null;
          coverageLandlordConstruction: number | null;
          coverageLandlordElectrical: number | null;
          coverageLandlordPlumbing: number | null;
          coverageLandlordFire: number | null;
          coverageBothConstruction: number | null;
          coverageBothElectrical: number | null;
          coverageBothPlumbing: number | null;
          coverageBothFire: number | null;
          totalConstruction: number | null;
          totalElectrical: number | null;
          totalPlumbing: number | null;
          totalFire: number | null;
          total: number | null;
        };
      }
    | { __typename: "ConstructionLoan"; id: string }
    | { __typename: "HousingAssessment"; id: string }
    | { __typename: "MultiAssessment"; id: string }
    | { __typename: "MultiTDD"; id: string }
    | {
        __typename: "TDD";
        id: string;
        cost: {
          __typename: "ReportCost";
          coverageTenant: number | null;
          coverageLandlord: number | null;
          coverageBoth: number | null;
          coverageTenantConstruction: number | null;
          coverageTenantElectrical: number | null;
          coverageTenantPlumbing: number | null;
          coverageTenantFire: number | null;
          coverageLandlordConstruction: number | null;
          coverageLandlordElectrical: number | null;
          coverageLandlordPlumbing: number | null;
          coverageLandlordFire: number | null;
          coverageBothConstruction: number | null;
          coverageBothElectrical: number | null;
          coverageBothPlumbing: number | null;
          coverageBothFire: number | null;
          totalConstruction: number | null;
          totalElectrical: number | null;
          totalPlumbing: number | null;
          totalFire: number | null;
          total: number | null;
        };
      }
    | null;
};

export type VedlikeholdKostnadsTabellQueryVariables = Exact<{
  id: Scalars["ID"];
  intervals?: InputMaybe<Array<MaintenancePeriodEnum> | MaintenancePeriodEnum>;
  periods?: InputMaybe<Array<MaintenancePeriodEnum> | MaintenancePeriodEnum>;
}>;

export type VedlikeholdKostnadsTabellQuery = {
  __typename: "Query";
  publication:
    | {
        __typename: "AssessmentPublication";
        maintenance: {
          __typename: "Maintenance";
          cost: {
            __typename: "BuildingMaintenanceCost";
            intervals: Array<number | null>;
            total: number | null;
            firstYearM2: number | null;
            documentation: number | null;
            missingRegulation: number | null;
            immediate: number | null;
          };
          sections: Array<{
            __typename: "MaintenanceSectionPublication";
            type: MaintenanceTypeEnum;
            cost: {
              __typename: "BuildingMaintenanceSectionCost";
              intervals: Array<number | null>;
              total: number | null;
              firstYearM2: number | null;
              documentation: number | null;
              missingRegulation: number | null;
              immediate: number | null;
            };
          }>;
        };
      }
    | {
        __typename: "BreeamPublication";
        maintenance: {
          __typename: "Maintenance";
          cost: {
            __typename: "BuildingMaintenanceCost";
            intervals: Array<number | null>;
            total: number | null;
            firstYearM2: number | null;
            documentation: number | null;
            missingRegulation: number | null;
            immediate: number | null;
          };
          sections: Array<{
            __typename: "MaintenanceSectionPublication";
            type: MaintenanceTypeEnum;
            cost: {
              __typename: "BuildingMaintenanceSectionCost";
              intervals: Array<number | null>;
              total: number | null;
              firstYearM2: number | null;
              documentation: number | null;
              missingRegulation: number | null;
              immediate: number | null;
            };
          }>;
        };
      }
    | {
        __typename: "TDDPublication";
        maintenance: {
          __typename: "Maintenance";
          cost: {
            __typename: "BuildingMaintenanceCost";
            intervals: Array<number | null>;
            total: number | null;
            firstYearM2: number | null;
            documentation: number | null;
            missingRegulation: number | null;
            immediate: number | null;
          };
          sections: Array<{
            __typename: "MaintenanceSectionPublication";
            type: MaintenanceTypeEnum;
            cost: {
              __typename: "BuildingMaintenanceSectionCost";
              intervals: Array<number | null>;
              total: number | null;
              firstYearM2: number | null;
              documentation: number | null;
              missingRegulation: number | null;
              immediate: number | null;
            };
          }>;
        };
      }
    | null;
};

export type VedlikeholdsKarakterQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type VedlikeholdsKarakterQuery = {
  __typename: "Query";
  report:
    | {
        __typename: "Assessment";
        cost: {
          __typename: "ReportCost";
          grade: string | null;
          score: number | null;
        };
        buildings: Array<{
          __typename: "Building";
          id: string;
          name: string;
          cost: { __typename: "BuildingCost"; grade: string; score: number };
        }>;
      }
    | {
        __typename: "Breeam";
        cost: {
          __typename: "ReportCost";
          grade: string | null;
          score: number | null;
        };
        buildings: Array<{
          __typename: "Building";
          id: string;
          name: string;
          cost: { __typename: "BuildingCost"; grade: string; score: number };
        }>;
      }
    | { __typename: "ConstructionLoan" }
    | { __typename: "HousingAssessment" }
    | { __typename: "MultiAssessment" }
    | { __typename: "MultiTDD" }
    | {
        __typename: "TDD";
        cost: {
          __typename: "ReportCost";
          grade: string | null;
          score: number | null;
        };
        buildings: Array<{
          __typename: "Building";
          id: string;
          name: string;
          cost: { __typename: "BuildingCost"; grade: string; score: number };
        }>;
      }
    | null;
};

export type CreateSectionMutationVariables = Exact<{
  input: CreateSectionInput;
}>;

export type CreateSectionMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    create: {
      __typename: "CreateSectionPayload";
      errors: Array<string> | null;
      section:
        | {
            __typename: "CostSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                };
          }
        | {
            __typename: "CVSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                };
          }
        | {
            __typename: "DocumentationSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                };
          }
        | {
            __typename: "HousingMaintenanceSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                };
          }
        | {
            __typename: "MaintenanceSectionPublication";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                };
          }
        | {
            __typename: "TextSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | {
                        __typename: "CostSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "CVSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "DocumentationSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "HousingMaintenanceSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                    | {
                        __typename: "TextSection";
                        id: string;
                        primary: boolean;
                        title: string | null;
                        visible: boolean;
                      }
                  >;
                };
          }
        | null;
    } | null;
  };
};

export type DeleteSectionMutationVariables = Exact<{
  input: DeleteSectionInput;
}>;

export type DeleteSectionMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    delete: {
      __typename: "DeleteSectionPayload";
      errors: Array<string> | null;
      section:
        | {
            __typename: "CostSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                };
          }
        | {
            __typename: "CVSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                };
          }
        | {
            __typename: "DocumentationSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                };
          }
        | {
            __typename: "HousingMaintenanceSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                };
          }
        | {
            __typename: "MaintenanceSectionPublication";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                };
          }
        | {
            __typename: "TextSection";
            id: string;
            report:
              | {
                  __typename: "Assessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "Breeam";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "ConstructionLoan";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "HousingAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiAssessment";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "MultiTDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                }
              | {
                  __typename: "TDD";
                  id: string;
                  sections: Array<
                    | { __typename: "CostSection"; id: string }
                    | { __typename: "CVSection"; id: string }
                    | { __typename: "DocumentationSection"; id: string }
                    | { __typename: "HousingMaintenanceSection"; id: string }
                    | {
                        __typename: "MaintenanceSectionPublication";
                        id: string;
                      }
                    | { __typename: "TextSection"; id: string }
                  >;
                };
          }
        | null;
    } | null;
  };
};

export type DeleteDocumentationBlockTemplateMutationVariables = Exact<{
  input: DeleteDocumentationBlockTemplateInput;
}>;

export type DeleteDocumentationBlockTemplateMutation = {
  __typename: "Mutation";
  deleteDocumentationBlockTemplate: {
    __typename: "DeleteDocumentationBlockTemplatePayload";
    errors: Array<string> | null;
    documentationBlockTemplate: {
      __typename: "DocumentationBlockTemplate";
      id: string;
      title: string | null;
      description: string | null;
      createdAt: string;
    } | null;
  } | null;
};

export type DocumentationBlockTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DocumentationBlockTemplatesQuery = {
  __typename: "Query";
  documentationBlockTemplates: Array<{
    __typename: "DocumentationBlockTemplate";
    id: string;
    title: string | null;
    description: string | null;
    createdAt: string;
    documentationLineTemplates: Array<{
      __typename: "DocumentationLineTemplate";
      id: string;
      title: string | null;
      number: string | null;
      columns: number | null;
      documentationBlockTemplateId: string;
    }>;
  }>;
};

export type CreateHousingMaintenanceBuildingPartRatingRuleMutationVariables = Exact<{
  input: CreateHousingMaintenanceBuildingPartRatingRuleInput;
}>;

export type CreateHousingMaintenanceBuildingPartRatingRuleMutation = {
  __typename: "Mutation";
  createHousingMaintenanceBuildingPartRatingRule: {
    __typename: "CreateHousingMaintenanceBuildingPartRatingRulePayload";
    errors: Array<string> | null;
    housingMaintenanceBuildingPartRatingRule: {
      __typename: "HousingMaintenanceBuildingPartRatingRule";
      id: string;
      tg: number | null;
      kg: number | null;
      rating: number | null;
      period: string | null;
      description: string | null;
    } | null;
  } | null;
};

export type DeleteHousingMaintenanceBuildingPartRatingRuleMutationVariables = Exact<{
  input: DeleteHousingMaintenanceBuildingPartRatingRuleInput;
}>;

export type DeleteHousingMaintenanceBuildingPartRatingRuleMutation = {
  __typename: "Mutation";
  deleteHousingMaintenanceBuildingPartRatingRule: {
    __typename: "DeleteHousingMaintenanceBuildingPartRatingRulePayload";
    errors: Array<string> | null;
    housingMaintenanceBuildingPartRatingRule: {
      __typename: "HousingMaintenanceBuildingPartRatingRule";
      id: string;
      tg: number | null;
      kg: number | null;
      rating: number | null;
      period: string | null;
      description: string | null;
    } | null;
  } | null;
};

export type HousingMaintenanceBuildingPartRatingRulesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HousingMaintenanceBuildingPartRatingRulesQuery = {
  __typename: "Query";
  housingMaintenanceBuildingPartRatingRules: Array<{
    __typename: "HousingMaintenanceBuildingPartRatingRule";
    id: string;
    tg: number | null;
    kg: number | null;
    rating: number | null;
    period: string | null;
    description: string | null;
  }>;
};

export type UpdateHousingMaintenanceBuildingPartRatingRuleMutationVariables = Exact<{
  input: UpdateHousingMaintenanceBuildingPartRatingRuleInput;
}>;

export type UpdateHousingMaintenanceBuildingPartRatingRuleMutation = {
  __typename: "Mutation";
  updateHousingMaintenanceBuildingPartRatingRule: {
    __typename: "UpdateHousingMaintenanceBuildingPartRatingRulePayload";
    errors: Array<string> | null;
    housingMaintenanceBuildingPartRatingRule: {
      __typename: "HousingMaintenanceBuildingPartRatingRule";
      id: string;
      tg: number | null;
      kg: number | null;
      rating: number | null;
      period: string | null;
      description: string | null;
    } | null;
  } | null;
};

export type ReportSectionsQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type ReportSectionsQuery = {
  __typename: "Query";
  report:
    | {
        __typename: "Assessment";
        id: string;
        sections: Array<
          | {
              __typename: "CostSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "CVSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "DocumentationSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "HousingMaintenanceSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "MaintenanceSectionPublication";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "TextSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
        >;
      }
    | {
        __typename: "Breeam";
        id: string;
        sections: Array<
          | {
              __typename: "CostSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "CVSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "DocumentationSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "HousingMaintenanceSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "MaintenanceSectionPublication";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "TextSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
        >;
      }
    | {
        __typename: "ConstructionLoan";
        id: string;
        sections: Array<
          | {
              __typename: "CostSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "CVSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "DocumentationSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "HousingMaintenanceSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "MaintenanceSectionPublication";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "TextSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
        >;
      }
    | {
        __typename: "HousingAssessment";
        id: string;
        sections: Array<
          | {
              __typename: "CostSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "CVSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "DocumentationSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "HousingMaintenanceSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "MaintenanceSectionPublication";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "TextSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
        >;
      }
    | {
        __typename: "MultiAssessment";
        id: string;
        sections: Array<
          | {
              __typename: "CostSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "CVSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "DocumentationSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "HousingMaintenanceSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "MaintenanceSectionPublication";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "TextSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
        >;
      }
    | {
        __typename: "MultiTDD";
        id: string;
        sections: Array<
          | {
              __typename: "CostSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "CVSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "DocumentationSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "HousingMaintenanceSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "MaintenanceSectionPublication";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "TextSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
        >;
      }
    | {
        __typename: "TDD";
        id: string;
        sections: Array<
          | {
              __typename: "CostSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "CVSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "DocumentationSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "HousingMaintenanceSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "MaintenanceSectionPublication";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
          | {
              __typename: "TextSection";
              id: string;
              primary: boolean;
              title: string | null;
              visible: boolean;
            }
        >;
      }
    | null;
};

export type RequestCostBlockTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RequestCostBlockTemplatesQuery = {
  __typename: "Query";
  requestCostBlockTemplates: Array<{
    __typename: "RequestCostBlockTemplate";
    id: string;
    title: string;
    number: string;
    createdAt: string;
    internalUse: boolean;
    requestCostLineTemplates: Array<{
      __typename: "RequestCostLineTemplate";
      id: string;
      number: string | null;
      title: string | null;
      requestType: string | null;
      costTypePrimary: string | null;
      costTypeSecondary: string | null;
    }>;
  }>;
};

export type RequestLineTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RequestLineTemplatesQuery = {
  __typename: "Query";
  requestLineTemplates: Array<{
    __typename: "RequestLineTemplate";
    id: string;
    title: string | null;
    number: string | null;
  }> | null;
};

export type CreateRequestLineMutationVariables = Exact<{
  input: CreateRequestLineInput;
}>;

export type CreateRequestLineMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    requests: {
      __typename: "RequestSectionMutationsPayload";
      createLine: {
        __typename: "CreateRequestLinePayload";
        errors: Array<string> | null;
        requestLine: {
          __typename: "RequestLine";
          id: string;
          number: string | null;
          title: string | null;
          answer: string | null;
          answeredBy: string | null;
          reference: string | null;
          section: {
            __typename: "RequestSection";
            id: string;
            requestLines: Array<{ __typename: "RequestLine"; id: string }>;
          };
          files: Array<{
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          }> | null;
        } | null;
      } | null;
    };
  };
};

export type DeleteRequestLineMutationVariables = Exact<{
  input: DeleteRequestLineInput;
}>;

export type DeleteRequestLineMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    requests: {
      __typename: "RequestSectionMutationsPayload";
      deleteLine: {
        __typename: "DeleteRequestLinePayload";
        errors: Array<string> | null;
        requestLine: {
          __typename: "RequestLine";
          id: string;
          section: {
            __typename: "RequestSection";
            id: string;
            requestLines: Array<{ __typename: "RequestLine"; id: string }>;
          };
        } | null;
      } | null;
    };
  };
};

export type RequestLineFieldsFragment = {
  __typename: "RequestLine";
  id: string;
  number: string | null;
  title: string | null;
  answer: string | null;
  answeredBy: string | null;
  reference: string | null;
  files: Array<{
    __typename: "Attachment";
    id: string;
    key: string | null;
    signedId: string;
    filename: string | null;
    contentType: string | null;
    byteSize: number | null;
    view: string | null;
    download: string | null;
    variants: {
      __typename: "ImageVariants";
      small: string;
      small2x: string;
      large: string;
      large2x: string;
    } | null;
  }> | null;
};

export type UpdateRequestLineMutationVariables = Exact<{
  input: UpdateRequestLineInput;
}>;

export type UpdateRequestLineMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    requests: {
      __typename: "RequestSectionMutationsPayload";
      updateLine: {
        __typename: "UpdateRequestLinePayload";
        errors: Array<string> | null;
        requestLine: {
          __typename: "RequestLine";
          id: string;
          number: string | null;
          title: string | null;
          answer: string | null;
          answeredBy: string | null;
          reference: string | null;
          files: Array<{
            __typename: "Attachment";
            id: string;
            key: string | null;
            signedId: string;
            filename: string | null;
            contentType: string | null;
            byteSize: number | null;
            view: string | null;
            download: string | null;
            variants: {
              __typename: "ImageVariants";
              small: string;
              small2x: string;
              large: string;
              large2x: string;
            } | null;
          }> | null;
        } | null;
      } | null;
    };
  };
};

export type SetSectionVisibilityMutationVariables = Exact<{
  input: UpdateSectionInput;
}>;

export type SetSectionVisibilityMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    update: {
      __typename: "UpdateSectionPayload";
      errors: Array<string> | null;
      section:
        | { __typename: "CostSection"; id: string; visible: boolean }
        | { __typename: "CVSection"; id: string; visible: boolean }
        | { __typename: "DocumentationSection"; id: string; visible: boolean }
        | {
            __typename: "HousingMaintenanceSection";
            id: string;
            visible: boolean;
          }
        | {
            __typename: "MaintenanceSectionPublication";
            id: string;
            visible: boolean;
          }
        | { __typename: "TextSection"; id: string; visible: boolean }
        | null;
    } | null;
  };
};

export type SortSectionsMutationVariables = Exact<{
  input: SortSectionInput;
}>;

export type SortSectionsMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    sort: {
      __typename: "SortSectionPayload";
      errors: Array<string> | null;
      report:
        | {
            __typename: "Assessment";
            id: string;
            sections: Array<
              | { __typename: "CostSection"; id: string }
              | { __typename: "CVSection"; id: string }
              | { __typename: "DocumentationSection"; id: string }
              | { __typename: "HousingMaintenanceSection"; id: string }
              | { __typename: "MaintenanceSectionPublication"; id: string }
              | { __typename: "TextSection"; id: string }
            >;
          }
        | {
            __typename: "Breeam";
            id: string;
            sections: Array<
              | { __typename: "CostSection"; id: string }
              | { __typename: "CVSection"; id: string }
              | { __typename: "DocumentationSection"; id: string }
              | { __typename: "HousingMaintenanceSection"; id: string }
              | { __typename: "MaintenanceSectionPublication"; id: string }
              | { __typename: "TextSection"; id: string }
            >;
          }
        | {
            __typename: "ConstructionLoan";
            id: string;
            sections: Array<
              | { __typename: "CostSection"; id: string }
              | { __typename: "CVSection"; id: string }
              | { __typename: "DocumentationSection"; id: string }
              | { __typename: "HousingMaintenanceSection"; id: string }
              | { __typename: "MaintenanceSectionPublication"; id: string }
              | { __typename: "TextSection"; id: string }
            >;
          }
        | {
            __typename: "HousingAssessment";
            id: string;
            sections: Array<
              | { __typename: "CostSection"; id: string }
              | { __typename: "CVSection"; id: string }
              | { __typename: "DocumentationSection"; id: string }
              | { __typename: "HousingMaintenanceSection"; id: string }
              | { __typename: "MaintenanceSectionPublication"; id: string }
              | { __typename: "TextSection"; id: string }
            >;
          }
        | {
            __typename: "MultiAssessment";
            id: string;
            sections: Array<
              | { __typename: "CostSection"; id: string }
              | { __typename: "CVSection"; id: string }
              | { __typename: "DocumentationSection"; id: string }
              | { __typename: "HousingMaintenanceSection"; id: string }
              | { __typename: "MaintenanceSectionPublication"; id: string }
              | { __typename: "TextSection"; id: string }
            >;
          }
        | {
            __typename: "MultiTDD";
            id: string;
            sections: Array<
              | { __typename: "CostSection"; id: string }
              | { __typename: "CVSection"; id: string }
              | { __typename: "DocumentationSection"; id: string }
              | { __typename: "HousingMaintenanceSection"; id: string }
              | { __typename: "MaintenanceSectionPublication"; id: string }
              | { __typename: "TextSection"; id: string }
            >;
          }
        | {
            __typename: "TDD";
            id: string;
            sections: Array<
              | { __typename: "CostSection"; id: string }
              | { __typename: "CVSection"; id: string }
              | { __typename: "DocumentationSection"; id: string }
              | { __typename: "HousingMaintenanceSection"; id: string }
              | { __typename: "MaintenanceSectionPublication"; id: string }
              | { __typename: "TextSection"; id: string }
            >;
          }
        | null;
    } | null;
  };
};

export type CreateParagraphMutationVariables = Exact<{
  input: CreateParagraphInput;
}>;

export type CreateParagraphMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    texts: {
      __typename: "TextSectionMutationsPayload";
      paragraphs: {
        __typename: "ParagraphMutationsPayload";
        create: {
          __typename: "CreateParagraphPayload";
          errors: Array<string> | null;
          paragraph: {
            __typename: "Paragraph";
            id: string;
            title: string | null;
            content: string | null;
            order: number | null;
            textSection: {
              __typename: "TextSection";
              id: string;
              paragraphs: Array<{ __typename: "Paragraph"; id: string }>;
            } | null;
          } | null;
        } | null;
      };
    };
  };
};

export type DeleteParagraphMutationVariables = Exact<{
  input: DeleteParagraphInput;
}>;

export type DeleteParagraphMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    texts: {
      __typename: "TextSectionMutationsPayload";
      paragraphs: {
        __typename: "ParagraphMutationsPayload";
        delete: {
          __typename: "DeleteParagraphPayload";
          errors: Array<string> | null;
          paragraph: {
            __typename: "Paragraph";
            id: string;
            textSection: {
              __typename: "TextSection";
              id: string;
              paragraphs: Array<{ __typename: "Paragraph"; id: string }>;
            } | null;
          } | null;
        } | null;
      };
    };
  };
};

export type EditTextSectionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EditTextSectionQuery = {
  __typename: "Query";
  node:
    | { __typename: "ClientInvoice"; id: string }
    | { __typename: "ContractorInvoice"; id: string }
    | { __typename: "ContractorInvoiceDeposit"; id: string }
    | { __typename: "CostSection"; id: string }
    | { __typename: "CVSection"; id: string }
    | { __typename: "Document"; id: string }
    | { __typename: "ExternalInvoice"; id: string }
    | { __typename: "FileVersion"; id: string }
    | { __typename: "MultiAssessmentConnection"; id: string }
    | { __typename: "MultiReportDeviation"; id: string }
    | { __typename: "MultiTDDConnection"; id: string }
    | { __typename: "Paragraph"; id: string }
    | {
        __typename: "TextSection";
        title: string | null;
        id: string;
        paragraphs: Array<{
          __typename: "Paragraph";
          id: string;
          title: string | null;
          content: string | null;
          order: number | null;
        }>;
        report:
          | {
              __typename: "Assessment";
              costSections: Array<{
                __typename: "CostSection";
                id: string;
                costType: string | null;
              }>;
              textSections: Array<{ __typename: "TextSection"; id: string }>;
            }
          | {
              __typename: "Breeam";
              costSections: Array<{
                __typename: "CostSection";
                id: string;
                costType: string | null;
              }>;
              textSections: Array<{ __typename: "TextSection"; id: string }>;
            }
          | {
              __typename: "ConstructionLoan";
              textSections: Array<{ __typename: "TextSection"; id: string }>;
            }
          | {
              __typename: "HousingAssessment";
              textSections: Array<{ __typename: "TextSection"; id: string }>;
            }
          | {
              __typename: "MultiAssessment";
              textSections: Array<{ __typename: "TextSection"; id: string }>;
            }
          | {
              __typename: "MultiTDD";
              textSections: Array<{ __typename: "TextSection"; id: string }>;
            }
          | {
              __typename: "TDD";
              costSections: Array<{
                __typename: "CostSection";
                id: string;
                costType: string | null;
              }>;
              textSections: Array<{ __typename: "TextSection"; id: string }>;
            };
      }
    | null;
};

export type ParagraphFieldsFragment = {
  __typename: "Paragraph";
  id: string;
  title: string | null;
  content: string | null;
  order: number | null;
};

export type SortParagraphsMutationVariables = Exact<{
  input: SortParagraphsInput;
}>;

export type SortParagraphsMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    texts: {
      __typename: "TextSectionMutationsPayload";
      paragraphs: {
        __typename: "ParagraphMutationsPayload";
        sort: {
          __typename: "SortParagraphsPayload";
          errors: Array<string> | null;
          textSection: {
            __typename: "TextSection";
            id: string;
            paragraphs: Array<{ __typename: "Paragraph"; id: string }>;
          } | null;
        } | null;
      };
    };
  };
};

export type UpdateParagraphMutationVariables = Exact<{
  input: UpdateParagraphInput;
}>;

export type UpdateParagraphMutation = {
  __typename: "Mutation";
  sections: {
    __typename: "SectionMutationsPayload";
    texts: {
      __typename: "TextSectionMutationsPayload";
      paragraphs: {
        __typename: "ParagraphMutationsPayload";
        update: {
          __typename: "UpdateParagraphPayload";
          errors: Array<string> | null;
          paragraph: {
            __typename: "Paragraph";
            id: string;
            title: string | null;
            content: string | null;
            order: number | null;
          } | null;
        } | null;
      };
    };
  };
};

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename: "Mutation";
  deleteUser: {
    __typename: "DeleteUserPayload";
    errors: Array<string>;
    user: { __typename: "User"; id: string } | null;
  } | null;
};

export type UserFieldsFragment = {
  __typename: "User";
  id: string;
  email: string;
  name: string;
  companyIds: Array<string> | null;
  companyName: string | null;
  role: UserRole;
};

export type UserIndexQueryVariables = Exact<{
  term?: InputMaybe<Scalars["String"]>;
}>;

export type UserIndexQuery = {
  __typename: "Query";
  users: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: UserRole;
    createdAt: string;
  }>;
};

export const ReportCompanyFieldsFragmentDoc = gql`
  fragment ReportCompanyFields on Report {
    id
    name
    company {
      id
      name
    }
    lockedAt
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    email
    name
    companyIds
    companyName
    role
  }
`;
export const EditMembershipFieldsFragmentDoc = gql`
  fragment EditMembershipFields on Membership {
    id
    userId
    reportId
    relation
    permissions
    report {
      ...ReportCompanyFields
      urls {
        show
        edit
      }
    }
    user {
      ...UserFields
    }
  }
  ${ReportCompanyFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const MembershipFieldsFragmentDoc = gql`
  fragment MembershipFields on Membership {
    id
    userId
    reportId
    relation
    permissions
    preferences
  }
`;
export const DeliveryFieldsFragmentDoc = gql`
  fragment DeliveryFields on Delivery {
    id
    date
    info
  }
`;
export const AttachmentFieldsFragmentDoc = gql`
  fragment AttachmentFields on Attachment {
    id
    key
    signedId
    filename
    contentType
    byteSize
    view
    download
    variants {
      small
      small2x
      large
      large2x
    }
  }
`;
export const FileVersionFieldsFragmentDoc = gql`
  fragment FileVersionFields on FileVersion {
    id
    createdAt
    file {
      ...AttachmentFields
    }
    user {
      id
      email
      name
    }
  }
  ${AttachmentFieldsFragmentDoc}
`;
export const InvoiceCategoryFieldsFragmentDoc = gql`
  fragment InvoiceCategoryFields on InvoiceCategory {
    id
    budget
    invoicesCount
    name
    order
    reportId
    total
    type
    vatIncluded
  }
`;
export const InvoiceCategoriesFieldsFragmentDoc = gql`
  fragment InvoiceCategoriesFields on ConstructionLoan {
    invoiceCategories {
      ...InvoiceCategoryFields
    }
  }
  ${InvoiceCategoryFieldsFragmentDoc}
`;
export const InvoiceContractorFieldsFragmentDoc = gql`
  fragment InvoiceContractorFields on InvoiceContractor {
    id
    name
    budget
  }
`;
export const InvoiceEntryCategoryFieldsFragmentDoc = gql`
  fragment InvoiceEntryCategoryFields on InvoiceEntryCategory {
    id
    budget
    contractorId
    groupId
    name
    order
    total
    type
    vatIncluded
  }
`;
export const InvoiceEntryCategoryGroupFieldsFragmentDoc = gql`
  fragment InvoiceEntryCategoryGroupFields on InvoiceEntryCategoryGroup {
    id
    contractorId
    name
    order
  }
`;
export const InvoiceEntryCategorizationFieldsFragmentDoc = gql`
  fragment InvoiceEntryCategorizationFields on InvoiceContractor {
    id
    invoiceEntryCategorizations {
      ... on InvoiceEntryCategory {
        ...InvoiceEntryCategoryFields
      }
      ... on InvoiceEntryCategoryGroup {
        ...InvoiceEntryCategoryGroupFields
        categories {
          ...InvoiceEntryCategoryFields
        }
      }
    }
  }
  ${InvoiceEntryCategoryFieldsFragmentDoc}
  ${InvoiceEntryCategoryGroupFieldsFragmentDoc}
`;
export const InvoiceEntryFieldsFragmentDoc = gql`
  fragment InvoiceEntryFields on InvoiceEntry {
    id
    amount
    categoryId
    createdAt
    total
    vatIncluded
    invoice {
      id
      deposit {
        amount
        auto
        manual
      }
    }
  }
`;
export const InvoiceFieldsFragmentDoc = gql`
  fragment InvoiceFields on Invoice {
    id
    acl {
      view
      delete
    }
    amount
    currency
    description
    dueDate
    number
    receivedDate
    sender
    status
    total
    type
    ... on FileVersionable {
      file {
        ...AttachmentFields
      }
    }
    ... on ContractorInvoice {
      accordingToPlan
      contractorId
      deposit {
        amount
        auto
        manual
      }
      hasEntries
      multi
      produced
    }
    ... on ExternalInvoice {
      categoryId
      category {
        ...InvoiceCategoryFields
      }
    }
  }
  ${AttachmentFieldsFragmentDoc}
  ${InvoiceCategoryFieldsFragmentDoc}
`;
export const InvoiceUpdateFieldsFragmentDoc = gql`
  fragment InvoiceUpdateFields on Invoice {
    ...InvoiceFields
    period {
      id
      publishable
      status
    }
  }
  ${InvoiceFieldsFragmentDoc}
`;
export const ConstructionLoanExtraFieldsFragmentDoc = gql`
  fragment ConstructionLoanExtraFields on ConstructionLoan {
    id
    balance
    currencies
    loanInfo
    plotCost
    plotCostPartOfLoan
    startDate
    vatIncluded
  }
`;
export const ConstructionLoanPeriodFieldsFragmentDoc = gql`
  fragment ConstructionLoanPeriodFields on ConstructionLoanPeriod {
    accidents
    currencyDate
    currencyLink
    currencySource
    dkk
    documentsText
    financeText
    eur
    files {
      ...AttachmentFields
    }
    full
    id
    inspectionText
    invoiceText
    invoicePlanText
    issues
    name
    payout {
      amount
      files {
        ...AttachmentFields
      }
      information
      manualAmount
      recommendation
    }
    progressText
    publishable
    publishedAt
    published
    qualityText
    readyForReview
    safetyInspections
    sek
    shaFiguresText
    shaGeneralText
    startedAt
    status
    title
    unpublishable
    usd
  }
  ${AttachmentFieldsFragmentDoc}
`;
export const EditMemberFieldsFragmentDoc = gql`
  fragment EditMemberFields on Member {
    membership {
      id
      permissions
      preferences
      relation
    }
    user {
      id
      companyName
      name
      email
      role
    }
  }
`;
export const MemberFieldsFragmentDoc = gql`
  fragment MemberFields on Member {
    membershipId
    reportId
    userId
    email
    name
    companyIds
    companyName
    relation
    permissions
    preferences
    role
    createdAt
  }
`;
export const RequestLineFieldsFragmentDoc = gql`
  fragment RequestLineFields on RequestLine {
    id
    number
    title
    answer
    answeredBy
    reference
    files {
      ...AttachmentFields
    }
  }
  ${AttachmentFieldsFragmentDoc}
`;
export const ParagraphFieldsFragmentDoc = gql`
  fragment ParagraphFields on Paragraph {
    id
    title
    content
    order
  }
`;
export const MembershipCreateDocument = gql`
  mutation MembershipCreate($input: MembershipCreateInput!) {
    membershipCreate(input: $input) {
      errors
      membership {
        ...EditMembershipFields
      }
    }
  }
  ${EditMembershipFieldsFragmentDoc}
`;
export type MembershipCreateMutationFn = Apollo.MutationFunction<
  MembershipCreateMutation,
  MembershipCreateMutationVariables
>;

/**
 * __useMembershipCreateMutation__
 *
 * To run a mutation, you first call `useMembershipCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembershipCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membershipCreateMutation, { data, loading, error }] = useMembershipCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMembershipCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MembershipCreateMutation,
    MembershipCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MembershipCreateMutation,
    MembershipCreateMutationVariables
  >(MembershipCreateDocument, options);
}
export type MembershipCreateMutationHookResult = ReturnType<
  typeof useMembershipCreateMutation
>;
export type MembershipCreateMutationResult = Apollo.MutationResult<MembershipCreateMutation>;
export type MembershipCreateMutationOptions = Apollo.BaseMutationOptions<
  MembershipCreateMutation,
  MembershipCreateMutationVariables
>;
export const UpdateMembershipRelationDocument = gql`
  mutation UpdateMembershipRelation($input: UpdateMembershipRelationInput!) {
    updateMembershipRelation(input: $input) {
      errors
      membership {
        ...MembershipFields
      }
    }
  }
  ${MembershipFieldsFragmentDoc}
`;
export type UpdateMembershipRelationMutationFn = Apollo.MutationFunction<
  UpdateMembershipRelationMutation,
  UpdateMembershipRelationMutationVariables
>;

/**
 * __useUpdateMembershipRelationMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipRelationMutation, { data, loading, error }] = useUpdateMembershipRelationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipRelationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembershipRelationMutation,
    UpdateMembershipRelationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembershipRelationMutation,
    UpdateMembershipRelationMutationVariables
  >(UpdateMembershipRelationDocument, options);
}
export type UpdateMembershipRelationMutationHookResult = ReturnType<
  typeof useUpdateMembershipRelationMutation
>;
export type UpdateMembershipRelationMutationResult = Apollo.MutationResult<UpdateMembershipRelationMutation>;
export type UpdateMembershipRelationMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembershipRelationMutation,
  UpdateMembershipRelationMutationVariables
>;
export const ConstructionLoanDeliveriesDocument = gql`
  query ConstructionLoanDeliveries($id: ID!) {
    constructionLoan(id: $id) {
      id
      deliveries {
        ...DeliveryFields
      }
    }
  }
  ${DeliveryFieldsFragmentDoc}
`;

/**
 * __useConstructionLoanDeliveriesQuery__
 *
 * To run a query within a React component, call `useConstructionLoanDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionLoanDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionLoanDeliveriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionLoanDeliveriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConstructionLoanDeliveriesQuery,
    ConstructionLoanDeliveriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConstructionLoanDeliveriesQuery,
    ConstructionLoanDeliveriesQueryVariables
  >(ConstructionLoanDeliveriesDocument, options);
}
export function useConstructionLoanDeliveriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConstructionLoanDeliveriesQuery,
    ConstructionLoanDeliveriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConstructionLoanDeliveriesQuery,
    ConstructionLoanDeliveriesQueryVariables
  >(ConstructionLoanDeliveriesDocument, options);
}
export type ConstructionLoanDeliveriesQueryHookResult = ReturnType<
  typeof useConstructionLoanDeliveriesQuery
>;
export type ConstructionLoanDeliveriesLazyQueryHookResult = ReturnType<
  typeof useConstructionLoanDeliveriesLazyQuery
>;
export type ConstructionLoanDeliveriesQueryResult = Apollo.QueryResult<
  ConstructionLoanDeliveriesQuery,
  ConstructionLoanDeliveriesQueryVariables
>;
export const DeleteFileVersionDocument = gql`
  mutation DeleteFileVersion($input: DeleteFileVersionInput!) {
    deleteFileVersion(input: $input) {
      fileVersion {
        id
        record {
          id
          ... on FileVersionable {
            file {
              ...AttachmentFields
            }
            fileVersions {
              ...FileVersionFields
            }
          }
        }
      }
      errors
    }
  }
  ${AttachmentFieldsFragmentDoc}
  ${FileVersionFieldsFragmentDoc}
`;
export type DeleteFileVersionMutationFn = Apollo.MutationFunction<
  DeleteFileVersionMutation,
  DeleteFileVersionMutationVariables
>;

/**
 * __useDeleteFileVersionMutation__
 *
 * To run a mutation, you first call `useDeleteFileVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileVersionMutation, { data, loading, error }] = useDeleteFileVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFileVersionMutation,
    DeleteFileVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFileVersionMutation,
    DeleteFileVersionMutationVariables
  >(DeleteFileVersionDocument, options);
}
export type DeleteFileVersionMutationHookResult = ReturnType<
  typeof useDeleteFileVersionMutation
>;
export type DeleteFileVersionMutationResult = Apollo.MutationResult<DeleteFileVersionMutation>;
export type DeleteFileVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileVersionMutation,
  DeleteFileVersionMutationVariables
>;
export const NodeFileVersionsDocument = gql`
  query NodeFileVersions($id: ID!) {
    node(id: $id) {
      id
      ... on FileVersionable {
        file {
          ...AttachmentFields
        }
        fileVersions {
          ...FileVersionFields
        }
      }
    }
  }
  ${AttachmentFieldsFragmentDoc}
  ${FileVersionFieldsFragmentDoc}
`;

/**
 * __useNodeFileVersionsQuery__
 *
 * To run a query within a React component, call `useNodeFileVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeFileVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeFileVersionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeFileVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NodeFileVersionsQuery,
    NodeFileVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NodeFileVersionsQuery, NodeFileVersionsQueryVariables>(
    NodeFileVersionsDocument,
    options
  );
}
export function useNodeFileVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NodeFileVersionsQuery,
    NodeFileVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NodeFileVersionsQuery,
    NodeFileVersionsQueryVariables
  >(NodeFileVersionsDocument, options);
}
export type NodeFileVersionsQueryHookResult = ReturnType<
  typeof useNodeFileVersionsQuery
>;
export type NodeFileVersionsLazyQueryHookResult = ReturnType<
  typeof useNodeFileVersionsLazyQuery
>;
export type NodeFileVersionsQueryResult = Apollo.QueryResult<
  NodeFileVersionsQuery,
  NodeFileVersionsQueryVariables
>;
export const ClientBudgetDisplayDocument = gql`
  query ClientBudgetDisplay($id: ID!) {
    constructionLoan(id: $id) {
      id
      clientBudgetGrouped
    }
  }
`;

/**
 * __useClientBudgetDisplayQuery__
 *
 * To run a query within a React component, call `useClientBudgetDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientBudgetDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientBudgetDisplayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientBudgetDisplayQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientBudgetDisplayQuery,
    ClientBudgetDisplayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientBudgetDisplayQuery,
    ClientBudgetDisplayQueryVariables
  >(ClientBudgetDisplayDocument, options);
}
export function useClientBudgetDisplayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientBudgetDisplayQuery,
    ClientBudgetDisplayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientBudgetDisplayQuery,
    ClientBudgetDisplayQueryVariables
  >(ClientBudgetDisplayDocument, options);
}
export type ClientBudgetDisplayQueryHookResult = ReturnType<
  typeof useClientBudgetDisplayQuery
>;
export type ClientBudgetDisplayLazyQueryHookResult = ReturnType<
  typeof useClientBudgetDisplayLazyQuery
>;
export type ClientBudgetDisplayQueryResult = Apollo.QueryResult<
  ClientBudgetDisplayQuery,
  ClientBudgetDisplayQueryVariables
>;
export const CreateInvoiceCategoryDocument = gql`
  mutation CreateInvoiceCategory($input: CreateInvoiceCategoryInput!) {
    createInvoiceCategory(input: $input) {
      errors
      invoiceCategory {
        id
        report {
          id
          invoiceCategories {
            ...InvoiceCategoryFields
          }
        }
      }
    }
  }
  ${InvoiceCategoryFieldsFragmentDoc}
`;
export type CreateInvoiceCategoryMutationFn = Apollo.MutationFunction<
  CreateInvoiceCategoryMutation,
  CreateInvoiceCategoryMutationVariables
>;

/**
 * __useCreateInvoiceCategoryMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceCategoryMutation, { data, loading, error }] = useCreateInvoiceCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceCategoryMutation,
    CreateInvoiceCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoiceCategoryMutation,
    CreateInvoiceCategoryMutationVariables
  >(CreateInvoiceCategoryDocument, options);
}
export type CreateInvoiceCategoryMutationHookResult = ReturnType<
  typeof useCreateInvoiceCategoryMutation
>;
export type CreateInvoiceCategoryMutationResult = Apollo.MutationResult<CreateInvoiceCategoryMutation>;
export type CreateInvoiceCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceCategoryMutation,
  CreateInvoiceCategoryMutationVariables
>;
export const DeleteInvoiceCategoryDocument = gql`
  mutation DeleteInvoiceCategory($input: DeleteInvoiceCategoryInput!) {
    deleteInvoiceCategory(input: $input) {
      errors
      invoiceCategory {
        id
        report {
          id
          invoiceCategories {
            ...InvoiceCategoryFields
          }
        }
      }
    }
  }
  ${InvoiceCategoryFieldsFragmentDoc}
`;
export type DeleteInvoiceCategoryMutationFn = Apollo.MutationFunction<
  DeleteInvoiceCategoryMutation,
  DeleteInvoiceCategoryMutationVariables
>;

/**
 * __useDeleteInvoiceCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceCategoryMutation, { data, loading, error }] = useDeleteInvoiceCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvoiceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceCategoryMutation,
    DeleteInvoiceCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceCategoryMutation,
    DeleteInvoiceCategoryMutationVariables
  >(DeleteInvoiceCategoryDocument, options);
}
export type DeleteInvoiceCategoryMutationHookResult = ReturnType<
  typeof useDeleteInvoiceCategoryMutation
>;
export type DeleteInvoiceCategoryMutationResult = Apollo.MutationResult<DeleteInvoiceCategoryMutation>;
export type DeleteInvoiceCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceCategoryMutation,
  DeleteInvoiceCategoryMutationVariables
>;
export const InvoiceCategoriesDocument = gql`
  query InvoiceCategories($id: ID!) {
    constructionLoan(id: $id) {
      id
      ...InvoiceCategoriesFields
    }
  }
  ${InvoiceCategoriesFieldsFragmentDoc}
`;

/**
 * __useInvoiceCategoriesQuery__
 *
 * To run a query within a React component, call `useInvoiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvoiceCategoriesQuery,
    InvoiceCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvoiceCategoriesQuery,
    InvoiceCategoriesQueryVariables
  >(InvoiceCategoriesDocument, options);
}
export function useInvoiceCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceCategoriesQuery,
    InvoiceCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvoiceCategoriesQuery,
    InvoiceCategoriesQueryVariables
  >(InvoiceCategoriesDocument, options);
}
export type InvoiceCategoriesQueryHookResult = ReturnType<
  typeof useInvoiceCategoriesQuery
>;
export type InvoiceCategoriesLazyQueryHookResult = ReturnType<
  typeof useInvoiceCategoriesLazyQuery
>;
export type InvoiceCategoriesQueryResult = Apollo.QueryResult<
  InvoiceCategoriesQuery,
  InvoiceCategoriesQueryVariables
>;
export const SetClientBudgetDisplayDocument = gql`
  mutation SetClientBudgetDisplay($input: ConstructionLoanUpdateInput!) {
    constructionLoanUpdate(input: $input) {
      errors
      report {
        id
        clientBudgetGrouped
      }
    }
  }
`;
export type SetClientBudgetDisplayMutationFn = Apollo.MutationFunction<
  SetClientBudgetDisplayMutation,
  SetClientBudgetDisplayMutationVariables
>;

/**
 * __useSetClientBudgetDisplayMutation__
 *
 * To run a mutation, you first call `useSetClientBudgetDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientBudgetDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientBudgetDisplayMutation, { data, loading, error }] = useSetClientBudgetDisplayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetClientBudgetDisplayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetClientBudgetDisplayMutation,
    SetClientBudgetDisplayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetClientBudgetDisplayMutation,
    SetClientBudgetDisplayMutationVariables
  >(SetClientBudgetDisplayDocument, options);
}
export type SetClientBudgetDisplayMutationHookResult = ReturnType<
  typeof useSetClientBudgetDisplayMutation
>;
export type SetClientBudgetDisplayMutationResult = Apollo.MutationResult<SetClientBudgetDisplayMutation>;
export type SetClientBudgetDisplayMutationOptions = Apollo.BaseMutationOptions<
  SetClientBudgetDisplayMutation,
  SetClientBudgetDisplayMutationVariables
>;
export const SortInvoiceCategoryDocument = gql`
  mutation SortInvoiceCategory($input: SortInvoiceCategoryInput!) {
    sortInvoiceCategory(input: $input) {
      report {
        id
        ...InvoiceCategoriesFields
      }
    }
  }
  ${InvoiceCategoriesFieldsFragmentDoc}
`;
export type SortInvoiceCategoryMutationFn = Apollo.MutationFunction<
  SortInvoiceCategoryMutation,
  SortInvoiceCategoryMutationVariables
>;

/**
 * __useSortInvoiceCategoryMutation__
 *
 * To run a mutation, you first call `useSortInvoiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortInvoiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortInvoiceCategoryMutation, { data, loading, error }] = useSortInvoiceCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortInvoiceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortInvoiceCategoryMutation,
    SortInvoiceCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SortInvoiceCategoryMutation,
    SortInvoiceCategoryMutationVariables
  >(SortInvoiceCategoryDocument, options);
}
export type SortInvoiceCategoryMutationHookResult = ReturnType<
  typeof useSortInvoiceCategoryMutation
>;
export type SortInvoiceCategoryMutationResult = Apollo.MutationResult<SortInvoiceCategoryMutation>;
export type SortInvoiceCategoryMutationOptions = Apollo.BaseMutationOptions<
  SortInvoiceCategoryMutation,
  SortInvoiceCategoryMutationVariables
>;
export const UpdateInvoiceCategoryDocument = gql`
  mutation UpdateInvoiceCategory($input: UpdateInvoiceCategoryInput!) {
    updateInvoiceCategory(input: $input) {
      errors
      invoiceCategory {
        ...InvoiceCategoryFields
      }
    }
  }
  ${InvoiceCategoryFieldsFragmentDoc}
`;
export type UpdateInvoiceCategoryMutationFn = Apollo.MutationFunction<
  UpdateInvoiceCategoryMutation,
  UpdateInvoiceCategoryMutationVariables
>;

/**
 * __useUpdateInvoiceCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceCategoryMutation, { data, loading, error }] = useUpdateInvoiceCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceCategoryMutation,
    UpdateInvoiceCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceCategoryMutation,
    UpdateInvoiceCategoryMutationVariables
  >(UpdateInvoiceCategoryDocument, options);
}
export type UpdateInvoiceCategoryMutationHookResult = ReturnType<
  typeof useUpdateInvoiceCategoryMutation
>;
export type UpdateInvoiceCategoryMutationResult = Apollo.MutationResult<UpdateInvoiceCategoryMutation>;
export type UpdateInvoiceCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceCategoryMutation,
  UpdateInvoiceCategoryMutationVariables
>;
export const ConstructionLoanContractorsDocument = gql`
  query ConstructionLoanContractors($id: ID!) {
    constructionLoan(id: $id) {
      id
      contractorBudgetPlanLabel
      contractors {
        ...InvoiceContractorFields
      }
      vatIncluded
    }
  }
  ${InvoiceContractorFieldsFragmentDoc}
`;

/**
 * __useConstructionLoanContractorsQuery__
 *
 * To run a query within a React component, call `useConstructionLoanContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionLoanContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionLoanContractorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionLoanContractorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConstructionLoanContractorsQuery,
    ConstructionLoanContractorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConstructionLoanContractorsQuery,
    ConstructionLoanContractorsQueryVariables
  >(ConstructionLoanContractorsDocument, options);
}
export function useConstructionLoanContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConstructionLoanContractorsQuery,
    ConstructionLoanContractorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConstructionLoanContractorsQuery,
    ConstructionLoanContractorsQueryVariables
  >(ConstructionLoanContractorsDocument, options);
}
export type ConstructionLoanContractorsQueryHookResult = ReturnType<
  typeof useConstructionLoanContractorsQuery
>;
export type ConstructionLoanContractorsLazyQueryHookResult = ReturnType<
  typeof useConstructionLoanContractorsLazyQuery
>;
export type ConstructionLoanContractorsQueryResult = Apollo.QueryResult<
  ConstructionLoanContractorsQuery,
  ConstructionLoanContractorsQueryVariables
>;
export const CreateClientInvoiceDocument = gql`
  mutation CreateClientInvoice($input: CreateClientInvoiceInput!) {
    createClientInvoice(input: $input) {
      errors
      invoice {
        ...InvoiceUpdateFields
      }
    }
  }
  ${InvoiceUpdateFieldsFragmentDoc}
`;
export type CreateClientInvoiceMutationFn = Apollo.MutationFunction<
  CreateClientInvoiceMutation,
  CreateClientInvoiceMutationVariables
>;

/**
 * __useCreateClientInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateClientInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientInvoiceMutation, { data, loading, error }] = useCreateClientInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientInvoiceMutation,
    CreateClientInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientInvoiceMutation,
    CreateClientInvoiceMutationVariables
  >(CreateClientInvoiceDocument, options);
}
export type CreateClientInvoiceMutationHookResult = ReturnType<
  typeof useCreateClientInvoiceMutation
>;
export type CreateClientInvoiceMutationResult = Apollo.MutationResult<CreateClientInvoiceMutation>;
export type CreateClientInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateClientInvoiceMutation,
  CreateClientInvoiceMutationVariables
>;
export const CreateContractorInvoiceDocument = gql`
  mutation CreateContractorInvoice($input: CreateContractorInvoiceInput!) {
    createContractorInvoice(input: $input) {
      errors
      invoice {
        ...InvoiceUpdateFields
        period {
          invoices {
            id
          }
        }
      }
    }
  }
  ${InvoiceUpdateFieldsFragmentDoc}
`;
export type CreateContractorInvoiceMutationFn = Apollo.MutationFunction<
  CreateContractorInvoiceMutation,
  CreateContractorInvoiceMutationVariables
>;

/**
 * __useCreateContractorInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateContractorInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractorInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractorInvoiceMutation, { data, loading, error }] = useCreateContractorInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractorInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContractorInvoiceMutation,
    CreateContractorInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContractorInvoiceMutation,
    CreateContractorInvoiceMutationVariables
  >(CreateContractorInvoiceDocument, options);
}
export type CreateContractorInvoiceMutationHookResult = ReturnType<
  typeof useCreateContractorInvoiceMutation
>;
export type CreateContractorInvoiceMutationResult = Apollo.MutationResult<CreateContractorInvoiceMutation>;
export type CreateContractorInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateContractorInvoiceMutation,
  CreateContractorInvoiceMutationVariables
>;
export const CreateExternalInvoiceDocument = gql`
  mutation CreateExternalInvoice($input: CreateExternalInvoiceInput!) {
    createExternalInvoice(input: $input) {
      errors
      invoice {
        ...InvoiceUpdateFields
        period {
          invoices {
            id
          }
        }
      }
    }
  }
  ${InvoiceUpdateFieldsFragmentDoc}
`;
export type CreateExternalInvoiceMutationFn = Apollo.MutationFunction<
  CreateExternalInvoiceMutation,
  CreateExternalInvoiceMutationVariables
>;

/**
 * __useCreateExternalInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateExternalInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalInvoiceMutation, { data, loading, error }] = useCreateExternalInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalInvoiceMutation,
    CreateExternalInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExternalInvoiceMutation,
    CreateExternalInvoiceMutationVariables
  >(CreateExternalInvoiceDocument, options);
}
export type CreateExternalInvoiceMutationHookResult = ReturnType<
  typeof useCreateExternalInvoiceMutation
>;
export type CreateExternalInvoiceMutationResult = Apollo.MutationResult<CreateExternalInvoiceMutation>;
export type CreateExternalInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateExternalInvoiceMutation,
  CreateExternalInvoiceMutationVariables
>;
export const DeleteInvoiceDocument = gql`
  mutation DeleteInvoice($input: InvoiceDeleteInput!) {
    deleteInvoice(input: $input) {
      invoice {
        id
        period {
          id
          publishable
          status
          invoices {
            id
          }
        }
      }
    }
  }
`;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables
>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceMutation,
    DeleteInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceMutation,
    DeleteInvoiceMutationVariables
  >(DeleteInvoiceDocument, options);
}
export type DeleteInvoiceMutationHookResult = ReturnType<
  typeof useDeleteInvoiceMutation
>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables
>;
export const InvoiceEntryCategorizationsDocument = gql`
  query InvoiceEntryCategorizations($contractorId: ID!) {
    invoiceContractor(id: $contractorId) {
      id
      ...InvoiceEntryCategorizationFields
    }
  }
  ${InvoiceEntryCategorizationFieldsFragmentDoc}
`;

/**
 * __useInvoiceEntryCategorizationsQuery__
 *
 * To run a query within a React component, call `useInvoiceEntryCategorizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceEntryCategorizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceEntryCategorizationsQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *   },
 * });
 */
export function useInvoiceEntryCategorizationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvoiceEntryCategorizationsQuery,
    InvoiceEntryCategorizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvoiceEntryCategorizationsQuery,
    InvoiceEntryCategorizationsQueryVariables
  >(InvoiceEntryCategorizationsDocument, options);
}
export function useInvoiceEntryCategorizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceEntryCategorizationsQuery,
    InvoiceEntryCategorizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvoiceEntryCategorizationsQuery,
    InvoiceEntryCategorizationsQueryVariables
  >(InvoiceEntryCategorizationsDocument, options);
}
export type InvoiceEntryCategorizationsQueryHookResult = ReturnType<
  typeof useInvoiceEntryCategorizationsQuery
>;
export type InvoiceEntryCategorizationsLazyQueryHookResult = ReturnType<
  typeof useInvoiceEntryCategorizationsLazyQuery
>;
export type InvoiceEntryCategorizationsQueryResult = Apollo.QueryResult<
  InvoiceEntryCategorizationsQuery,
  InvoiceEntryCategorizationsQueryVariables
>;
export const CreateInvoiceEntryCategoryDocument = gql`
  mutation CreateInvoiceEntryCategory(
    $input: CreateInvoiceEntryCategoryInput!
  ) {
    createInvoiceEntryCategory(input: $input) {
      invoiceEntryCategory {
        ...InvoiceEntryCategoryFields
        contractor {
          id
          budget
          ...InvoiceEntryCategorizationFields
        }
      }
    }
  }
  ${InvoiceEntryCategoryFieldsFragmentDoc}
  ${InvoiceEntryCategorizationFieldsFragmentDoc}
`;
export type CreateInvoiceEntryCategoryMutationFn = Apollo.MutationFunction<
  CreateInvoiceEntryCategoryMutation,
  CreateInvoiceEntryCategoryMutationVariables
>;

/**
 * __useCreateInvoiceEntryCategoryMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceEntryCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceEntryCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceEntryCategoryMutation, { data, loading, error }] = useCreateInvoiceEntryCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceEntryCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceEntryCategoryMutation,
    CreateInvoiceEntryCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoiceEntryCategoryMutation,
    CreateInvoiceEntryCategoryMutationVariables
  >(CreateInvoiceEntryCategoryDocument, options);
}
export type CreateInvoiceEntryCategoryMutationHookResult = ReturnType<
  typeof useCreateInvoiceEntryCategoryMutation
>;
export type CreateInvoiceEntryCategoryMutationResult = Apollo.MutationResult<CreateInvoiceEntryCategoryMutation>;
export type CreateInvoiceEntryCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceEntryCategoryMutation,
  CreateInvoiceEntryCategoryMutationVariables
>;
export const DeleteInvoiceEntryCategoryDocument = gql`
  mutation DeleteInvoiceEntryCategory(
    $input: DeleteInvoiceEntryCategoryInput!
  ) {
    deleteInvoiceEntryCategory(input: $input) {
      errors
      invoiceEntryCategory {
        id
        contractor {
          id
          budget
          ...InvoiceEntryCategorizationFields
        }
      }
    }
  }
  ${InvoiceEntryCategorizationFieldsFragmentDoc}
`;
export type DeleteInvoiceEntryCategoryMutationFn = Apollo.MutationFunction<
  DeleteInvoiceEntryCategoryMutation,
  DeleteInvoiceEntryCategoryMutationVariables
>;

/**
 * __useDeleteInvoiceEntryCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceEntryCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceEntryCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceEntryCategoryMutation, { data, loading, error }] = useDeleteInvoiceEntryCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvoiceEntryCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceEntryCategoryMutation,
    DeleteInvoiceEntryCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceEntryCategoryMutation,
    DeleteInvoiceEntryCategoryMutationVariables
  >(DeleteInvoiceEntryCategoryDocument, options);
}
export type DeleteInvoiceEntryCategoryMutationHookResult = ReturnType<
  typeof useDeleteInvoiceEntryCategoryMutation
>;
export type DeleteInvoiceEntryCategoryMutationResult = Apollo.MutationResult<DeleteInvoiceEntryCategoryMutation>;
export type DeleteInvoiceEntryCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceEntryCategoryMutation,
  DeleteInvoiceEntryCategoryMutationVariables
>;
export const UpdateInvoiceEntryCategoryDocument = gql`
  mutation UpdateInvoiceEntryCategory(
    $input: UpdateInvoiceEntryCategoryInput!
  ) {
    updateInvoiceEntryCategory(input: $input) {
      errors
      invoiceEntryCategory {
        ...InvoiceEntryCategoryFields
        contractor {
          id
          budget
        }
      }
    }
  }
  ${InvoiceEntryCategoryFieldsFragmentDoc}
`;
export type UpdateInvoiceEntryCategoryMutationFn = Apollo.MutationFunction<
  UpdateInvoiceEntryCategoryMutation,
  UpdateInvoiceEntryCategoryMutationVariables
>;

/**
 * __useUpdateInvoiceEntryCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceEntryCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceEntryCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceEntryCategoryMutation, { data, loading, error }] = useUpdateInvoiceEntryCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceEntryCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceEntryCategoryMutation,
    UpdateInvoiceEntryCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceEntryCategoryMutation,
    UpdateInvoiceEntryCategoryMutationVariables
  >(UpdateInvoiceEntryCategoryDocument, options);
}
export type UpdateInvoiceEntryCategoryMutationHookResult = ReturnType<
  typeof useUpdateInvoiceEntryCategoryMutation
>;
export type UpdateInvoiceEntryCategoryMutationResult = Apollo.MutationResult<UpdateInvoiceEntryCategoryMutation>;
export type UpdateInvoiceEntryCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceEntryCategoryMutation,
  UpdateInvoiceEntryCategoryMutationVariables
>;
export const CreateInvoiceEntryDocument = gql`
  mutation CreateInvoiceEntry($input: CreateInvoiceEntryInput!) {
    createInvoiceEntry(input: $input) {
      errors
      invoiceEntry {
        ...InvoiceEntryFields
        invoice {
          id
          ... on ContractorInvoice {
            entries {
              id
            }
            hasEntries
            total
          }
        }
      }
    }
  }
  ${InvoiceEntryFieldsFragmentDoc}
`;
export type CreateInvoiceEntryMutationFn = Apollo.MutationFunction<
  CreateInvoiceEntryMutation,
  CreateInvoiceEntryMutationVariables
>;

/**
 * __useCreateInvoiceEntryMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceEntryMutation, { data, loading, error }] = useCreateInvoiceEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceEntryMutation,
    CreateInvoiceEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoiceEntryMutation,
    CreateInvoiceEntryMutationVariables
  >(CreateInvoiceEntryDocument, options);
}
export type CreateInvoiceEntryMutationHookResult = ReturnType<
  typeof useCreateInvoiceEntryMutation
>;
export type CreateInvoiceEntryMutationResult = Apollo.MutationResult<CreateInvoiceEntryMutation>;
export type CreateInvoiceEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceEntryMutation,
  CreateInvoiceEntryMutationVariables
>;
export const DeleteInvoiceEntryDocument = gql`
  mutation DeleteInvoiceEntry($input: DeleteInvoiceEntryInput!) {
    deleteInvoiceEntry(input: $input) {
      errors
      invoiceEntry {
        id
        invoice {
          id
          status
          ... on ContractorInvoice {
            entries {
              id
            }
            hasEntries
            total
          }
        }
      }
    }
  }
`;
export type DeleteInvoiceEntryMutationFn = Apollo.MutationFunction<
  DeleteInvoiceEntryMutation,
  DeleteInvoiceEntryMutationVariables
>;

/**
 * __useDeleteInvoiceEntryMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceEntryMutation, { data, loading, error }] = useDeleteInvoiceEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvoiceEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceEntryMutation,
    DeleteInvoiceEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceEntryMutation,
    DeleteInvoiceEntryMutationVariables
  >(DeleteInvoiceEntryDocument, options);
}
export type DeleteInvoiceEntryMutationHookResult = ReturnType<
  typeof useDeleteInvoiceEntryMutation
>;
export type DeleteInvoiceEntryMutationResult = Apollo.MutationResult<DeleteInvoiceEntryMutation>;
export type DeleteInvoiceEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceEntryMutation,
  DeleteInvoiceEntryMutationVariables
>;
export const InvoiceEntriesDocument = gql`
  query InvoiceEntries($id: ID!) {
    invoice(id: $id) {
      id
      ... on ContractorInvoice {
        entries {
          ...InvoiceEntryFields
        }
      }
    }
  }
  ${InvoiceEntryFieldsFragmentDoc}
`;

/**
 * __useInvoiceEntriesQuery__
 *
 * To run a query within a React component, call `useInvoiceEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceEntriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvoiceEntriesQuery,
    InvoiceEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceEntriesQuery, InvoiceEntriesQueryVariables>(
    InvoiceEntriesDocument,
    options
  );
}
export function useInvoiceEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceEntriesQuery,
    InvoiceEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceEntriesQuery, InvoiceEntriesQueryVariables>(
    InvoiceEntriesDocument,
    options
  );
}
export type InvoiceEntriesQueryHookResult = ReturnType<
  typeof useInvoiceEntriesQuery
>;
export type InvoiceEntriesLazyQueryHookResult = ReturnType<
  typeof useInvoiceEntriesLazyQuery
>;
export type InvoiceEntriesQueryResult = Apollo.QueryResult<
  InvoiceEntriesQuery,
  InvoiceEntriesQueryVariables
>;
export const UpdateInvoiceEntryDocument = gql`
  mutation UpdateInvoiceEntry($input: UpdateInvoiceEntryInput!) {
    updateInvoiceEntry(input: $input) {
      errors
      invoiceEntry {
        ...InvoiceEntryFields
        invoice {
          id
          status
          ... on ContractorInvoice {
            hasEntries
            produced
            total
          }
        }
      }
    }
  }
  ${InvoiceEntryFieldsFragmentDoc}
`;
export type UpdateInvoiceEntryMutationFn = Apollo.MutationFunction<
  UpdateInvoiceEntryMutation,
  UpdateInvoiceEntryMutationVariables
>;

/**
 * __useUpdateInvoiceEntryMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceEntryMutation, { data, loading, error }] = useUpdateInvoiceEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceEntryMutation,
    UpdateInvoiceEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceEntryMutation,
    UpdateInvoiceEntryMutationVariables
  >(UpdateInvoiceEntryDocument, options);
}
export type UpdateInvoiceEntryMutationHookResult = ReturnType<
  typeof useUpdateInvoiceEntryMutation
>;
export type UpdateInvoiceEntryMutationResult = Apollo.MutationResult<UpdateInvoiceEntryMutation>;
export type UpdateInvoiceEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceEntryMutation,
  UpdateInvoiceEntryMutationVariables
>;
export const MigrateClientInvoiceDocument = gql`
  mutation MigrateClientInvoice($input: MigrateClientInvoiceInput!) {
    migrateClientInvoice(input: $input) {
      invoice {
        ...InvoiceFields
        period {
          id
          publishable
          status
          invoices {
            ...InvoiceFields
          }
        }
      }
    }
  }
  ${InvoiceFieldsFragmentDoc}
`;
export type MigrateClientInvoiceMutationFn = Apollo.MutationFunction<
  MigrateClientInvoiceMutation,
  MigrateClientInvoiceMutationVariables
>;

/**
 * __useMigrateClientInvoiceMutation__
 *
 * To run a mutation, you first call `useMigrateClientInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateClientInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateClientInvoiceMutation, { data, loading, error }] = useMigrateClientInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMigrateClientInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MigrateClientInvoiceMutation,
    MigrateClientInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MigrateClientInvoiceMutation,
    MigrateClientInvoiceMutationVariables
  >(MigrateClientInvoiceDocument, options);
}
export type MigrateClientInvoiceMutationHookResult = ReturnType<
  typeof useMigrateClientInvoiceMutation
>;
export type MigrateClientInvoiceMutationResult = Apollo.MutationResult<MigrateClientInvoiceMutation>;
export type MigrateClientInvoiceMutationOptions = Apollo.BaseMutationOptions<
  MigrateClientInvoiceMutation,
  MigrateClientInvoiceMutationVariables
>;
export const UpdateContractorInvoiceDocument = gql`
  mutation UpdateContractorInvoice($input: UpdateContractorInvoiceInput!) {
    updateContractorInvoice(input: $input) {
      invoice {
        ...InvoiceUpdateFields
      }
      errors
    }
  }
  ${InvoiceUpdateFieldsFragmentDoc}
`;
export type UpdateContractorInvoiceMutationFn = Apollo.MutationFunction<
  UpdateContractorInvoiceMutation,
  UpdateContractorInvoiceMutationVariables
>;

/**
 * __useUpdateContractorInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateContractorInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractorInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractorInvoiceMutation, { data, loading, error }] = useUpdateContractorInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractorInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractorInvoiceMutation,
    UpdateContractorInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractorInvoiceMutation,
    UpdateContractorInvoiceMutationVariables
  >(UpdateContractorInvoiceDocument, options);
}
export type UpdateContractorInvoiceMutationHookResult = ReturnType<
  typeof useUpdateContractorInvoiceMutation
>;
export type UpdateContractorInvoiceMutationResult = Apollo.MutationResult<UpdateContractorInvoiceMutation>;
export type UpdateContractorInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractorInvoiceMutation,
  UpdateContractorInvoiceMutationVariables
>;
export const UpdateExternalInvoiceDocument = gql`
  mutation UpdateExternalInvoice($input: UpdateExternalInvoiceInput!) {
    updateExternalInvoice(input: $input) {
      invoice {
        ...InvoiceUpdateFields
      }
      errors
    }
  }
  ${InvoiceUpdateFieldsFragmentDoc}
`;
export type UpdateExternalInvoiceMutationFn = Apollo.MutationFunction<
  UpdateExternalInvoiceMutation,
  UpdateExternalInvoiceMutationVariables
>;

/**
 * __useUpdateExternalInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateExternalInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalInvoiceMutation, { data, loading, error }] = useUpdateExternalInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExternalInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExternalInvoiceMutation,
    UpdateExternalInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExternalInvoiceMutation,
    UpdateExternalInvoiceMutationVariables
  >(UpdateExternalInvoiceDocument, options);
}
export type UpdateExternalInvoiceMutationHookResult = ReturnType<
  typeof useUpdateExternalInvoiceMutation
>;
export type UpdateExternalInvoiceMutationResult = Apollo.MutationResult<UpdateExternalInvoiceMutation>;
export type UpdateExternalInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateExternalInvoiceMutation,
  UpdateExternalInvoiceMutationVariables
>;
export const CostSectionSummaryBuildingsDocument = gql`
  query CostSectionSummaryBuildings($id: ID!) {
    node(id: $id) {
      ... on CostSection {
        publication {
          buildingCosts {
            building {
              id
              name
            }
            missingRegulationReplacement: total(
              period: MISSING_REGULATION
              priority: REPLACEMENT
            )
            missingRegulationHigh: total(
              period: MISSING_REGULATION
              priority: HIGH
            )
            missingRegulationMedium: total(
              period: MISSING_REGULATION
              priority: MEDIUM
            )
            missingRegulationLow: total(
              period: MISSING_REGULATION
              priority: LOW
            )
            missingRegulationTotal: total(period: MISSING_REGULATION)
            immediateReplacement: total(
              period: IMMEDIATE
              priority: REPLACEMENT
            )
            immediateHigh: total(period: IMMEDIATE, priority: HIGH)
            immediateMedium: total(period: IMMEDIATE, priority: MEDIUM)
            immediateLow: total(period: IMMEDIATE, priority: LOW)
            immediateTotal: total(period: IMMEDIATE)
            intervalsReplacement: intervalsTotal(priority: REPLACEMENT)
            intervalsHigh: intervalsTotal(priority: HIGH)
            intervalsMedium: intervalsTotal(priority: MEDIUM)
            intervalsLow: intervalsTotal(priority: LOW)
            intervalsTotal: intervalsTotal(
              priority: [REPLACEMENT, HIGH, MEDIUM, LOW]
            )
            replacementTotal: total(priority: REPLACEMENT)
            highTotal: total(priority: HIGH)
            mediumTotal: total(priority: MEDIUM)
            lowTotal: total(priority: LOW)
            total
          }
        }
      }
    }
  }
`;

/**
 * __useCostSectionSummaryBuildingsQuery__
 *
 * To run a query within a React component, call `useCostSectionSummaryBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostSectionSummaryBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostSectionSummaryBuildingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCostSectionSummaryBuildingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CostSectionSummaryBuildingsQuery,
    CostSectionSummaryBuildingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CostSectionSummaryBuildingsQuery,
    CostSectionSummaryBuildingsQueryVariables
  >(CostSectionSummaryBuildingsDocument, options);
}
export function useCostSectionSummaryBuildingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostSectionSummaryBuildingsQuery,
    CostSectionSummaryBuildingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CostSectionSummaryBuildingsQuery,
    CostSectionSummaryBuildingsQueryVariables
  >(CostSectionSummaryBuildingsDocument, options);
}
export type CostSectionSummaryBuildingsQueryHookResult = ReturnType<
  typeof useCostSectionSummaryBuildingsQuery
>;
export type CostSectionSummaryBuildingsLazyQueryHookResult = ReturnType<
  typeof useCostSectionSummaryBuildingsLazyQuery
>;
export type CostSectionSummaryBuildingsQueryResult = Apollo.QueryResult<
  CostSectionSummaryBuildingsQuery,
  CostSectionSummaryBuildingsQueryVariables
>;
export const CostSectionSummaryCoverageDocument = gql`
  query CostSectionSummaryCoverage($id: ID!) {
    node(id: $id) {
      ... on CostSection {
        publication {
          cost {
            missingRegulationTenant: total(
              coverage: TENANT
              period: MISSING_REGULATION
            )
            missingRegulationLandlord: total(
              coverage: LANDLORD
              period: MISSING_REGULATION
            )
            missingRegulationBoth: total(
              coverage: BOTH
              period: MISSING_REGULATION
            )
            missingRegulationOther: total(
              coverage: OTHER
              period: MISSING_REGULATION
            )
            missingRegulation: total(
              period: MISSING_REGULATION
              coverage: [TENANT, LANDLORD, BOTH, OTHER]
            )
            immediateTenant: total(coverage: TENANT, period: IMMEDIATE)
            immediateLandlord: total(coverage: LANDLORD, period: IMMEDIATE)
            immediateBoth: total(coverage: BOTH, period: IMMEDIATE)
            immediateOther: total(coverage: OTHER, period: IMMEDIATE)
            immediate: total(
              period: IMMEDIATE
              coverage: [TENANT, LANDLORD, BOTH, OTHER]
            )
            intervalsTenant: intervals(coverage: TENANT)
            intervalsLandlord: intervals(coverage: LANDLORD)
            intervalsBoth: intervals(coverage: BOTH)
            intervalsOther: intervals(coverage: OTHER)
            intervals(coverage: [TENANT, LANDLORD, BOTH, OTHER])
            tenant: total(coverage: TENANT)
            landlord: total(coverage: LANDLORD)
            both: total(coverage: BOTH)
            other: total(coverage: OTHER)
            total: total(coverage: [TENANT, LANDLORD, BOTH, OTHER])
          }
        }
      }
    }
  }
`;

/**
 * __useCostSectionSummaryCoverageQuery__
 *
 * To run a query within a React component, call `useCostSectionSummaryCoverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostSectionSummaryCoverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostSectionSummaryCoverageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCostSectionSummaryCoverageQuery(
  baseOptions: Apollo.QueryHookOptions<
    CostSectionSummaryCoverageQuery,
    CostSectionSummaryCoverageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CostSectionSummaryCoverageQuery,
    CostSectionSummaryCoverageQueryVariables
  >(CostSectionSummaryCoverageDocument, options);
}
export function useCostSectionSummaryCoverageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostSectionSummaryCoverageQuery,
    CostSectionSummaryCoverageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CostSectionSummaryCoverageQuery,
    CostSectionSummaryCoverageQueryVariables
  >(CostSectionSummaryCoverageDocument, options);
}
export type CostSectionSummaryCoverageQueryHookResult = ReturnType<
  typeof useCostSectionSummaryCoverageQuery
>;
export type CostSectionSummaryCoverageLazyQueryHookResult = ReturnType<
  typeof useCostSectionSummaryCoverageLazyQuery
>;
export type CostSectionSummaryCoverageQueryResult = Apollo.QueryResult<
  CostSectionSummaryCoverageQuery,
  CostSectionSummaryCoverageQueryVariables
>;
export const CostSectionSummaryInfoDocument = gql`
  query CostSectionSummaryInfo($id: ID!) {
    node(id: $id) {
      ... on CostSection {
        publication {
          buildingCosts {
            building {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCostSectionSummaryInfoQuery__
 *
 * To run a query within a React component, call `useCostSectionSummaryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostSectionSummaryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostSectionSummaryInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCostSectionSummaryInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    CostSectionSummaryInfoQuery,
    CostSectionSummaryInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CostSectionSummaryInfoQuery,
    CostSectionSummaryInfoQueryVariables
  >(CostSectionSummaryInfoDocument, options);
}
export function useCostSectionSummaryInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostSectionSummaryInfoQuery,
    CostSectionSummaryInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CostSectionSummaryInfoQuery,
    CostSectionSummaryInfoQueryVariables
  >(CostSectionSummaryInfoDocument, options);
}
export type CostSectionSummaryInfoQueryHookResult = ReturnType<
  typeof useCostSectionSummaryInfoQuery
>;
export type CostSectionSummaryInfoLazyQueryHookResult = ReturnType<
  typeof useCostSectionSummaryInfoLazyQuery
>;
export type CostSectionSummaryInfoQueryResult = Apollo.QueryResult<
  CostSectionSummaryInfoQuery,
  CostSectionSummaryInfoQueryVariables
>;
export const CostSectionSummaryPriorityDocument = gql`
  query CostSectionSummaryPriority($id: ID!) {
    node(id: $id) {
      ... on CostSection {
        publication {
          cost {
            missingRegulationReplacement: total(
              period: MISSING_REGULATION
              priority: REPLACEMENT
            )
            missingRegulationHigh: total(
              period: MISSING_REGULATION
              priority: HIGH
            )
            missingRegulationMedium: total(
              period: MISSING_REGULATION
              priority: MEDIUM
            )
            missingRegulationLow: total(
              period: MISSING_REGULATION
              priority: LOW
            )
            missingRegulation: total(period: MISSING_REGULATION)
            missingRegulationReplacement: total(
              period: MISSING_REGULATION
              priority: REPLACEMENT
            )
            immediateReplacement: total(
              period: IMMEDIATE
              priority: REPLACEMENT
            )
            immediateHigh: total(period: IMMEDIATE, priority: HIGH)
            immediateMedium: total(period: IMMEDIATE, priority: MEDIUM)
            immediateLow: total(period: IMMEDIATE, priority: LOW)
            immediate: total(period: IMMEDIATE)
            intervalsReplacement: intervals(priority: REPLACEMENT)
            intervalsHigh: intervals(priority: HIGH)
            intervalsMedium: intervals(priority: MEDIUM)
            intervalsLow: intervals(priority: LOW)
            intervals
            replacement: total(priority: REPLACEMENT)
            high: total(priority: HIGH)
            medium: total(priority: MEDIUM)
            low: total(priority: LOW)
            total
          }
        }
      }
    }
  }
`;

/**
 * __useCostSectionSummaryPriorityQuery__
 *
 * To run a query within a React component, call `useCostSectionSummaryPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostSectionSummaryPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostSectionSummaryPriorityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCostSectionSummaryPriorityQuery(
  baseOptions: Apollo.QueryHookOptions<
    CostSectionSummaryPriorityQuery,
    CostSectionSummaryPriorityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CostSectionSummaryPriorityQuery,
    CostSectionSummaryPriorityQueryVariables
  >(CostSectionSummaryPriorityDocument, options);
}
export function useCostSectionSummaryPriorityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostSectionSummaryPriorityQuery,
    CostSectionSummaryPriorityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CostSectionSummaryPriorityQuery,
    CostSectionSummaryPriorityQueryVariables
  >(CostSectionSummaryPriorityDocument, options);
}
export type CostSectionSummaryPriorityQueryHookResult = ReturnType<
  typeof useCostSectionSummaryPriorityQuery
>;
export type CostSectionSummaryPriorityLazyQueryHookResult = ReturnType<
  typeof useCostSectionSummaryPriorityLazyQuery
>;
export type CostSectionSummaryPriorityQueryResult = Apollo.QueryResult<
  CostSectionSummaryPriorityQuery,
  CostSectionSummaryPriorityQueryVariables
>;
export const ConstructionLoanBudgetDocument = gql`
  query ConstructionLoanBudget($id: ID!) {
    constructionLoan(id: $id) {
      id
      equity
      loanAmount
      budget {
        client
        enterprise
        plot
        total
      }
    }
  }
`;

/**
 * __useConstructionLoanBudgetQuery__
 *
 * To run a query within a React component, call `useConstructionLoanBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionLoanBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionLoanBudgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionLoanBudgetQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConstructionLoanBudgetQuery,
    ConstructionLoanBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConstructionLoanBudgetQuery,
    ConstructionLoanBudgetQueryVariables
  >(ConstructionLoanBudgetDocument, options);
}
export function useConstructionLoanBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConstructionLoanBudgetQuery,
    ConstructionLoanBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConstructionLoanBudgetQuery,
    ConstructionLoanBudgetQueryVariables
  >(ConstructionLoanBudgetDocument, options);
}
export type ConstructionLoanBudgetQueryHookResult = ReturnType<
  typeof useConstructionLoanBudgetQuery
>;
export type ConstructionLoanBudgetLazyQueryHookResult = ReturnType<
  typeof useConstructionLoanBudgetLazyQuery
>;
export type ConstructionLoanBudgetQueryResult = Apollo.QueryResult<
  ConstructionLoanBudgetQuery,
  ConstructionLoanBudgetQueryVariables
>;
export const ConstructionLoanCreateDocument = gql`
  mutation ConstructionLoanCreate($input: ConstructionLoanCreateInput!) {
    constructionLoanCreate(input: $input) {
      errors
      report {
        id
        slug
      }
    }
  }
`;
export type ConstructionLoanCreateMutationFn = Apollo.MutationFunction<
  ConstructionLoanCreateMutation,
  ConstructionLoanCreateMutationVariables
>;

/**
 * __useConstructionLoanCreateMutation__
 *
 * To run a mutation, you first call `useConstructionLoanCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionLoanCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionLoanCreateMutation, { data, loading, error }] = useConstructionLoanCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionLoanCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConstructionLoanCreateMutation,
    ConstructionLoanCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConstructionLoanCreateMutation,
    ConstructionLoanCreateMutationVariables
  >(ConstructionLoanCreateDocument, options);
}
export type ConstructionLoanCreateMutationHookResult = ReturnType<
  typeof useConstructionLoanCreateMutation
>;
export type ConstructionLoanCreateMutationResult = Apollo.MutationResult<ConstructionLoanCreateMutation>;
export type ConstructionLoanCreateMutationOptions = Apollo.BaseMutationOptions<
  ConstructionLoanCreateMutation,
  ConstructionLoanCreateMutationVariables
>;
export const UpdateConstructionLoanDocument = gql`
  mutation UpdateConstructionLoan($input: ConstructionLoanUpdateInput!) {
    constructionLoanUpdate(input: $input) {
      report {
        id
      }
    }
  }
`;
export type UpdateConstructionLoanMutationFn = Apollo.MutationFunction<
  UpdateConstructionLoanMutation,
  UpdateConstructionLoanMutationVariables
>;

/**
 * __useUpdateConstructionLoanMutation__
 *
 * To run a mutation, you first call `useUpdateConstructionLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConstructionLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConstructionLoanMutation, { data, loading, error }] = useUpdateConstructionLoanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConstructionLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConstructionLoanMutation,
    UpdateConstructionLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConstructionLoanMutation,
    UpdateConstructionLoanMutationVariables
  >(UpdateConstructionLoanDocument, options);
}
export type UpdateConstructionLoanMutationHookResult = ReturnType<
  typeof useUpdateConstructionLoanMutation
>;
export type UpdateConstructionLoanMutationResult = Apollo.MutationResult<UpdateConstructionLoanMutation>;
export type UpdateConstructionLoanMutationOptions = Apollo.BaseMutationOptions<
  UpdateConstructionLoanMutation,
  UpdateConstructionLoanMutationVariables
>;
export const UpdateConstructionLoanExtraDocument = gql`
  mutation UpdateConstructionLoanExtra($input: ConstructionLoanUpdateInput!) {
    constructionLoanUpdate(input: $input) {
      report {
        ...ConstructionLoanExtraFields
      }
    }
  }
  ${ConstructionLoanExtraFieldsFragmentDoc}
`;
export type UpdateConstructionLoanExtraMutationFn = Apollo.MutationFunction<
  UpdateConstructionLoanExtraMutation,
  UpdateConstructionLoanExtraMutationVariables
>;

/**
 * __useUpdateConstructionLoanExtraMutation__
 *
 * To run a mutation, you first call `useUpdateConstructionLoanExtraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConstructionLoanExtraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConstructionLoanExtraMutation, { data, loading, error }] = useUpdateConstructionLoanExtraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConstructionLoanExtraMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConstructionLoanExtraMutation,
    UpdateConstructionLoanExtraMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConstructionLoanExtraMutation,
    UpdateConstructionLoanExtraMutationVariables
  >(UpdateConstructionLoanExtraDocument, options);
}
export type UpdateConstructionLoanExtraMutationHookResult = ReturnType<
  typeof useUpdateConstructionLoanExtraMutation
>;
export type UpdateConstructionLoanExtraMutationResult = Apollo.MutationResult<UpdateConstructionLoanExtraMutation>;
export type UpdateConstructionLoanExtraMutationOptions = Apollo.BaseMutationOptions<
  UpdateConstructionLoanExtraMutation,
  UpdateConstructionLoanExtraMutationVariables
>;
export const PeriodsLoanAmountDocument = gql`
  query PeriodsLoanAmount($id: ID!) {
    constructionLoan(id: $id) {
      id
      periods {
        id
        title
        loanAmount
        equity
        published
        publishedAt
        startedAt
      }
    }
  }
`;

/**
 * __usePeriodsLoanAmountQuery__
 *
 * To run a query within a React component, call `usePeriodsLoanAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodsLoanAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodsLoanAmountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePeriodsLoanAmountQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodsLoanAmountQuery,
    PeriodsLoanAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PeriodsLoanAmountQuery,
    PeriodsLoanAmountQueryVariables
  >(PeriodsLoanAmountDocument, options);
}
export function usePeriodsLoanAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodsLoanAmountQuery,
    PeriodsLoanAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PeriodsLoanAmountQuery,
    PeriodsLoanAmountQueryVariables
  >(PeriodsLoanAmountDocument, options);
}
export type PeriodsLoanAmountQueryHookResult = ReturnType<
  typeof usePeriodsLoanAmountQuery
>;
export type PeriodsLoanAmountLazyQueryHookResult = ReturnType<
  typeof usePeriodsLoanAmountLazyQuery
>;
export type PeriodsLoanAmountQueryResult = Apollo.QueryResult<
  PeriodsLoanAmountQuery,
  PeriodsLoanAmountQueryVariables
>;
export const UpdatePeriodLoanDocument = gql`
  mutation UpdatePeriodLoan($input: UpdatePeriodLoanInput!) {
    updatePeriodLoan(input: $input) {
      errors
      period {
        id
        loanAmount
        equity
      }
    }
  }
`;
export type UpdatePeriodLoanMutationFn = Apollo.MutationFunction<
  UpdatePeriodLoanMutation,
  UpdatePeriodLoanMutationVariables
>;

/**
 * __useUpdatePeriodLoanMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodLoanMutation, { data, loading, error }] = useUpdatePeriodLoanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeriodLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePeriodLoanMutation,
    UpdatePeriodLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePeriodLoanMutation,
    UpdatePeriodLoanMutationVariables
  >(UpdatePeriodLoanDocument, options);
}
export type UpdatePeriodLoanMutationHookResult = ReturnType<
  typeof useUpdatePeriodLoanMutation
>;
export type UpdatePeriodLoanMutationResult = Apollo.MutationResult<UpdatePeriodLoanMutation>;
export type UpdatePeriodLoanMutationOptions = Apollo.BaseMutationOptions<
  UpdatePeriodLoanMutation,
  UpdatePeriodLoanMutationVariables
>;
export const ConstructionLoanPeriodWithInvoicesDocument = gql`
  query constructionLoanPeriodWithInvoices($id: ID!) {
    constructionLoanPeriod(id: $id) {
      ...ConstructionLoanPeriodFields
      report {
        id
        balance
        currencies
        contractors {
          ...InvoiceContractorFields
          ...InvoiceEntryCategorizationFields
        }
        invoiceCategories {
          ...InvoiceCategoryFields
        }
        vatIncluded
      }
      invoices {
        ...InvoiceFields
      }
    }
  }
  ${ConstructionLoanPeriodFieldsFragmentDoc}
  ${InvoiceContractorFieldsFragmentDoc}
  ${InvoiceEntryCategorizationFieldsFragmentDoc}
  ${InvoiceCategoryFieldsFragmentDoc}
  ${InvoiceFieldsFragmentDoc}
`;

/**
 * __useConstructionLoanPeriodWithInvoicesQuery__
 *
 * To run a query within a React component, call `useConstructionLoanPeriodWithInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionLoanPeriodWithInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionLoanPeriodWithInvoicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionLoanPeriodWithInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConstructionLoanPeriodWithInvoicesQuery,
    ConstructionLoanPeriodWithInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConstructionLoanPeriodWithInvoicesQuery,
    ConstructionLoanPeriodWithInvoicesQueryVariables
  >(ConstructionLoanPeriodWithInvoicesDocument, options);
}
export function useConstructionLoanPeriodWithInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConstructionLoanPeriodWithInvoicesQuery,
    ConstructionLoanPeriodWithInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConstructionLoanPeriodWithInvoicesQuery,
    ConstructionLoanPeriodWithInvoicesQueryVariables
  >(ConstructionLoanPeriodWithInvoicesDocument, options);
}
export type ConstructionLoanPeriodWithInvoicesQueryHookResult = ReturnType<
  typeof useConstructionLoanPeriodWithInvoicesQuery
>;
export type ConstructionLoanPeriodWithInvoicesLazyQueryHookResult = ReturnType<
  typeof useConstructionLoanPeriodWithInvoicesLazyQuery
>;
export type ConstructionLoanPeriodWithInvoicesQueryResult = Apollo.QueryResult<
  ConstructionLoanPeriodWithInvoicesQuery,
  ConstructionLoanPeriodWithInvoicesQueryVariables
>;
export const ConstructionLoanPeriodsDocument = gql`
  query constructionLoanPeriods($id: ID!) {
    constructionLoan(id: $id) {
      id
      ... on ConstructionLoan {
        periods {
          id
          full
          name
          published
          publishedAt
          startedAt
          status
          acl {
            view
            edit
            delete
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionLoanPeriodsQuery__
 *
 * To run a query within a React component, call `useConstructionLoanPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionLoanPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionLoanPeriodsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionLoanPeriodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConstructionLoanPeriodsQuery,
    ConstructionLoanPeriodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConstructionLoanPeriodsQuery,
    ConstructionLoanPeriodsQueryVariables
  >(ConstructionLoanPeriodsDocument, options);
}
export function useConstructionLoanPeriodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConstructionLoanPeriodsQuery,
    ConstructionLoanPeriodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConstructionLoanPeriodsQuery,
    ConstructionLoanPeriodsQueryVariables
  >(ConstructionLoanPeriodsDocument, options);
}
export type ConstructionLoanPeriodsQueryHookResult = ReturnType<
  typeof useConstructionLoanPeriodsQuery
>;
export type ConstructionLoanPeriodsLazyQueryHookResult = ReturnType<
  typeof useConstructionLoanPeriodsLazyQuery
>;
export type ConstructionLoanPeriodsQueryResult = Apollo.QueryResult<
  ConstructionLoanPeriodsQuery,
  ConstructionLoanPeriodsQueryVariables
>;
export const CreateConstructionLoanPeriodDocument = gql`
  mutation CreateConstructionLoanPeriod(
    $input: CreateConstructionLoanPeriodInput!
  ) {
    createConstructionLoanPeriod(input: $input) {
      period {
        ...ConstructionLoanPeriodFields
        report {
          id
          periods {
            id
          }
        }
      }
    }
  }
  ${ConstructionLoanPeriodFieldsFragmentDoc}
`;
export type CreateConstructionLoanPeriodMutationFn = Apollo.MutationFunction<
  CreateConstructionLoanPeriodMutation,
  CreateConstructionLoanPeriodMutationVariables
>;

/**
 * __useCreateConstructionLoanPeriodMutation__
 *
 * To run a mutation, you first call `useCreateConstructionLoanPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConstructionLoanPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConstructionLoanPeriodMutation, { data, loading, error }] = useCreateConstructionLoanPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConstructionLoanPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConstructionLoanPeriodMutation,
    CreateConstructionLoanPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConstructionLoanPeriodMutation,
    CreateConstructionLoanPeriodMutationVariables
  >(CreateConstructionLoanPeriodDocument, options);
}
export type CreateConstructionLoanPeriodMutationHookResult = ReturnType<
  typeof useCreateConstructionLoanPeriodMutation
>;
export type CreateConstructionLoanPeriodMutationResult = Apollo.MutationResult<CreateConstructionLoanPeriodMutation>;
export type CreateConstructionLoanPeriodMutationOptions = Apollo.BaseMutationOptions<
  CreateConstructionLoanPeriodMutation,
  CreateConstructionLoanPeriodMutationVariables
>;
export const DeleteConstructionLoanPeriodDocument = gql`
  mutation DeleteConstructionLoanPeriod(
    $input: DeleteConstructionLoanPeriodInput!
  ) {
    deleteConstructionLoanPeriod(input: $input) {
      period {
        report {
          id
          periods {
            id
          }
        }
      }
    }
  }
`;
export type DeleteConstructionLoanPeriodMutationFn = Apollo.MutationFunction<
  DeleteConstructionLoanPeriodMutation,
  DeleteConstructionLoanPeriodMutationVariables
>;

/**
 * __useDeleteConstructionLoanPeriodMutation__
 *
 * To run a mutation, you first call `useDeleteConstructionLoanPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConstructionLoanPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConstructionLoanPeriodMutation, { data, loading, error }] = useDeleteConstructionLoanPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConstructionLoanPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConstructionLoanPeriodMutation,
    DeleteConstructionLoanPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConstructionLoanPeriodMutation,
    DeleteConstructionLoanPeriodMutationVariables
  >(DeleteConstructionLoanPeriodDocument, options);
}
export type DeleteConstructionLoanPeriodMutationHookResult = ReturnType<
  typeof useDeleteConstructionLoanPeriodMutation
>;
export type DeleteConstructionLoanPeriodMutationResult = Apollo.MutationResult<DeleteConstructionLoanPeriodMutation>;
export type DeleteConstructionLoanPeriodMutationOptions = Apollo.BaseMutationOptions<
  DeleteConstructionLoanPeriodMutation,
  DeleteConstructionLoanPeriodMutationVariables
>;
export const PublishConstructionLoanPeriodDocument = gql`
  mutation PublishConstructionLoanPeriod(
    $input: PublishConstructionLoanPeriodInput!
  ) {
    publishConstructionLoanPeriod(input: $input) {
      period {
        ...ConstructionLoanPeriodFields
      }
    }
  }
  ${ConstructionLoanPeriodFieldsFragmentDoc}
`;
export type PublishConstructionLoanPeriodMutationFn = Apollo.MutationFunction<
  PublishConstructionLoanPeriodMutation,
  PublishConstructionLoanPeriodMutationVariables
>;

/**
 * __usePublishConstructionLoanPeriodMutation__
 *
 * To run a mutation, you first call `usePublishConstructionLoanPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishConstructionLoanPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishConstructionLoanPeriodMutation, { data, loading, error }] = usePublishConstructionLoanPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishConstructionLoanPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishConstructionLoanPeriodMutation,
    PublishConstructionLoanPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishConstructionLoanPeriodMutation,
    PublishConstructionLoanPeriodMutationVariables
  >(PublishConstructionLoanPeriodDocument, options);
}
export type PublishConstructionLoanPeriodMutationHookResult = ReturnType<
  typeof usePublishConstructionLoanPeriodMutation
>;
export type PublishConstructionLoanPeriodMutationResult = Apollo.MutationResult<PublishConstructionLoanPeriodMutation>;
export type PublishConstructionLoanPeriodMutationOptions = Apollo.BaseMutationOptions<
  PublishConstructionLoanPeriodMutation,
  PublishConstructionLoanPeriodMutationVariables
>;
export const PeriodReadyForReviewDocument = gql`
  mutation PeriodReadyForReview($input: ReadyForReviewInput!) {
    constructionLoanPeriods {
      readyForReview(input: $input) {
        errors
        period {
          id
          title
          readyForReview
        }
      }
    }
  }
`;
export type PeriodReadyForReviewMutationFn = Apollo.MutationFunction<
  PeriodReadyForReviewMutation,
  PeriodReadyForReviewMutationVariables
>;

/**
 * __usePeriodReadyForReviewMutation__
 *
 * To run a mutation, you first call `usePeriodReadyForReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePeriodReadyForReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [periodReadyForReviewMutation, { data, loading, error }] = usePeriodReadyForReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePeriodReadyForReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PeriodReadyForReviewMutation,
    PeriodReadyForReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PeriodReadyForReviewMutation,
    PeriodReadyForReviewMutationVariables
  >(PeriodReadyForReviewDocument, options);
}
export type PeriodReadyForReviewMutationHookResult = ReturnType<
  typeof usePeriodReadyForReviewMutation
>;
export type PeriodReadyForReviewMutationResult = Apollo.MutationResult<PeriodReadyForReviewMutation>;
export type PeriodReadyForReviewMutationOptions = Apollo.BaseMutationOptions<
  PeriodReadyForReviewMutation,
  PeriodReadyForReviewMutationVariables
>;
export const UnpublishConstructionLoanPeriodDocument = gql`
  mutation UnpublishConstructionLoanPeriod(
    $input: UnpublishConstructionLoanPeriodInput!
  ) {
    unpublishConstructionLoanPeriod(input: $input) {
      period {
        ...ConstructionLoanPeriodFields
      }
    }
  }
  ${ConstructionLoanPeriodFieldsFragmentDoc}
`;
export type UnpublishConstructionLoanPeriodMutationFn = Apollo.MutationFunction<
  UnpublishConstructionLoanPeriodMutation,
  UnpublishConstructionLoanPeriodMutationVariables
>;

/**
 * __useUnpublishConstructionLoanPeriodMutation__
 *
 * To run a mutation, you first call `useUnpublishConstructionLoanPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishConstructionLoanPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishConstructionLoanPeriodMutation, { data, loading, error }] = useUnpublishConstructionLoanPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishConstructionLoanPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpublishConstructionLoanPeriodMutation,
    UnpublishConstructionLoanPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpublishConstructionLoanPeriodMutation,
    UnpublishConstructionLoanPeriodMutationVariables
  >(UnpublishConstructionLoanPeriodDocument, options);
}
export type UnpublishConstructionLoanPeriodMutationHookResult = ReturnType<
  typeof useUnpublishConstructionLoanPeriodMutation
>;
export type UnpublishConstructionLoanPeriodMutationResult = Apollo.MutationResult<UnpublishConstructionLoanPeriodMutation>;
export type UnpublishConstructionLoanPeriodMutationOptions = Apollo.BaseMutationOptions<
  UnpublishConstructionLoanPeriodMutation,
  UnpublishConstructionLoanPeriodMutationVariables
>;
export const UpdateConstructionLoanPeriodDocument = gql`
  mutation UpdateConstructionLoanPeriod(
    $input: UpdateConstructionLoanPeriodInput!
  ) {
    updateConstructionLoanPeriod(input: $input) {
      period {
        ...ConstructionLoanPeriodFields
      }
    }
  }
  ${ConstructionLoanPeriodFieldsFragmentDoc}
`;
export type UpdateConstructionLoanPeriodMutationFn = Apollo.MutationFunction<
  UpdateConstructionLoanPeriodMutation,
  UpdateConstructionLoanPeriodMutationVariables
>;

/**
 * __useUpdateConstructionLoanPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateConstructionLoanPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConstructionLoanPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConstructionLoanPeriodMutation, { data, loading, error }] = useUpdateConstructionLoanPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConstructionLoanPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConstructionLoanPeriodMutation,
    UpdateConstructionLoanPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConstructionLoanPeriodMutation,
    UpdateConstructionLoanPeriodMutationVariables
  >(UpdateConstructionLoanPeriodDocument, options);
}
export type UpdateConstructionLoanPeriodMutationHookResult = ReturnType<
  typeof useUpdateConstructionLoanPeriodMutation
>;
export type UpdateConstructionLoanPeriodMutationResult = Apollo.MutationResult<UpdateConstructionLoanPeriodMutation>;
export type UpdateConstructionLoanPeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdateConstructionLoanPeriodMutation,
  UpdateConstructionLoanPeriodMutationVariables
>;
export const ReportSearchDocument = gql`
  query ReportSearch($query: String!, $companyId: [ID!], $type: [ReportType!]) {
    reportSearch(query: $query, companyId: $companyId, type: $type) {
      id
      slug
      name
      company {
        id
        name
      }
      lockedAt
      urls {
        show
        edit
      }
    }
  }
`;

/**
 * __useReportSearchQuery__
 *
 * To run a query within a React component, call `useReportSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReportSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportSearchQuery,
    ReportSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportSearchQuery, ReportSearchQueryVariables>(
    ReportSearchDocument,
    options
  );
}
export function useReportSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportSearchQuery,
    ReportSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportSearchQuery, ReportSearchQueryVariables>(
    ReportSearchDocument,
    options
  );
}
export type ReportSearchQueryHookResult = ReturnType<
  typeof useReportSearchQuery
>;
export type ReportSearchLazyQueryHookResult = ReturnType<
  typeof useReportSearchLazyQuery
>;
export type ReportSearchQueryResult = Apollo.QueryResult<
  ReportSearchQuery,
  ReportSearchQueryVariables
>;
export const ReportHeroDocument = gql`
  query ReportHero($slug: ID!) {
    report(slug: $slug) {
      id
      name
      lockedAt
      originalReportDate
      type
      typeManual
    }
  }
`;

/**
 * __useReportHeroQuery__
 *
 * To run a query within a React component, call `useReportHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportHeroQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useReportHeroQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportHeroQuery,
    ReportHeroQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportHeroQuery, ReportHeroQueryVariables>(
    ReportHeroDocument,
    options
  );
}
export function useReportHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportHeroQuery,
    ReportHeroQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportHeroQuery, ReportHeroQueryVariables>(
    ReportHeroDocument,
    options
  );
}
export type ReportHeroQueryHookResult = ReturnType<typeof useReportHeroQuery>;
export type ReportHeroLazyQueryHookResult = ReturnType<
  typeof useReportHeroLazyQuery
>;
export type ReportHeroQueryResult = Apollo.QueryResult<
  ReportHeroQuery,
  ReportHeroQueryVariables
>;
export const EditAudienceDocument = gql`
  query EditAudience($slug: ID!) {
    report(slug: $slug) {
      id
      client {
        ...EditMemberFields
      }
      associates {
        ...EditMemberFields
      }
      collaborators {
        ...EditMemberFields
      }
    }
  }
  ${EditMemberFieldsFragmentDoc}
`;

/**
 * __useEditAudienceQuery__
 *
 * To run a query within a React component, call `useEditAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditAudienceQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEditAudienceQuery(
  baseOptions: Apollo.QueryHookOptions<
    EditAudienceQuery,
    EditAudienceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditAudienceQuery, EditAudienceQueryVariables>(
    EditAudienceDocument,
    options
  );
}
export function useEditAudienceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditAudienceQuery,
    EditAudienceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EditAudienceQuery, EditAudienceQueryVariables>(
    EditAudienceDocument,
    options
  );
}
export type EditAudienceQueryHookResult = ReturnType<
  typeof useEditAudienceQuery
>;
export type EditAudienceLazyQueryHookResult = ReturnType<
  typeof useEditAudienceLazyQuery
>;
export type EditAudienceQueryResult = Apollo.QueryResult<
  EditAudienceQuery,
  EditAudienceQueryVariables
>;
export const ReportRemoveClientDocument = gql`
  mutation ReportRemoveClient($input: ReportRemoveClientInput!) {
    reportRemoveClient(input: $input) {
      id
      client {
        ...MemberFields
      }
    }
  }
  ${MemberFieldsFragmentDoc}
`;
export type ReportRemoveClientMutationFn = Apollo.MutationFunction<
  ReportRemoveClientMutation,
  ReportRemoveClientMutationVariables
>;

/**
 * __useReportRemoveClientMutation__
 *
 * To run a mutation, you first call `useReportRemoveClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportRemoveClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportRemoveClientMutation, { data, loading, error }] = useReportRemoveClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportRemoveClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportRemoveClientMutation,
    ReportRemoveClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReportRemoveClientMutation,
    ReportRemoveClientMutationVariables
  >(ReportRemoveClientDocument, options);
}
export type ReportRemoveClientMutationHookResult = ReturnType<
  typeof useReportRemoveClientMutation
>;
export type ReportRemoveClientMutationResult = Apollo.MutationResult<ReportRemoveClientMutation>;
export type ReportRemoveClientMutationOptions = Apollo.BaseMutationOptions<
  ReportRemoveClientMutation,
  ReportRemoveClientMutationVariables
>;
export const ReportSetClientDocument = gql`
  mutation ReportSetClient($input: ReportSetClientInput!) {
    reportSetClient(input: $input) {
      id
      client {
        ...MemberFields
      }
    }
  }
  ${MemberFieldsFragmentDoc}
`;
export type ReportSetClientMutationFn = Apollo.MutationFunction<
  ReportSetClientMutation,
  ReportSetClientMutationVariables
>;

/**
 * __useReportSetClientMutation__
 *
 * To run a mutation, you first call `useReportSetClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportSetClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportSetClientMutation, { data, loading, error }] = useReportSetClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportSetClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportSetClientMutation,
    ReportSetClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReportSetClientMutation,
    ReportSetClientMutationVariables
  >(ReportSetClientDocument, options);
}
export type ReportSetClientMutationHookResult = ReturnType<
  typeof useReportSetClientMutation
>;
export type ReportSetClientMutationResult = Apollo.MutationResult<ReportSetClientMutation>;
export type ReportSetClientMutationOptions = Apollo.BaseMutationOptions<
  ReportSetClientMutation,
  ReportSetClientMutationVariables
>;
export const CreateMultiAssessmentDocument = gql`
  mutation createMultiAssessment($input: CreateMultiAssessmentInput!) {
    createMultiAssessment(input: $input) {
      errors
      report {
        id
        slug
      }
    }
  }
`;
export type CreateMultiAssessmentMutationFn = Apollo.MutationFunction<
  CreateMultiAssessmentMutation,
  CreateMultiAssessmentMutationVariables
>;

/**
 * __useCreateMultiAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateMultiAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultiAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultiAssessmentMutation, { data, loading, error }] = useCreateMultiAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMultiAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMultiAssessmentMutation,
    CreateMultiAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMultiAssessmentMutation,
    CreateMultiAssessmentMutationVariables
  >(CreateMultiAssessmentDocument, options);
}
export type CreateMultiAssessmentMutationHookResult = ReturnType<
  typeof useCreateMultiAssessmentMutation
>;
export type CreateMultiAssessmentMutationResult = Apollo.MutationResult<CreateMultiAssessmentMutation>;
export type CreateMultiAssessmentMutationOptions = Apollo.BaseMutationOptions<
  CreateMultiAssessmentMutation,
  CreateMultiAssessmentMutationVariables
>;
export const CreateMultiTddDocument = gql`
  mutation createMultiTDD($input: CreateMultiTDDInput!) {
    createMultiTDD(input: $input) {
      errors
      report {
        id
        slug
      }
    }
  }
`;
export type CreateMultiTddMutationFn = Apollo.MutationFunction<
  CreateMultiTddMutation,
  CreateMultiTddMutationVariables
>;

/**
 * __useCreateMultiTddMutation__
 *
 * To run a mutation, you first call `useCreateMultiTddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultiTddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultiTddMutation, { data, loading, error }] = useCreateMultiTddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMultiTddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMultiTddMutation,
    CreateMultiTddMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMultiTddMutation,
    CreateMultiTddMutationVariables
  >(CreateMultiTddDocument, options);
}
export type CreateMultiTddMutationHookResult = ReturnType<
  typeof useCreateMultiTddMutation
>;
export type CreateMultiTddMutationResult = Apollo.MutationResult<CreateMultiTddMutation>;
export type CreateMultiTddMutationOptions = Apollo.BaseMutationOptions<
  CreateMultiTddMutation,
  CreateMultiTddMutationVariables
>;
export const RegeneratePdfDocument = gql`
  mutation RegeneratePdf($input: RegeneratePdfInput!) {
    regeneratePdf(input: $input) {
      errors
      status
    }
  }
`;
export type RegeneratePdfMutationFn = Apollo.MutationFunction<
  RegeneratePdfMutation,
  RegeneratePdfMutationVariables
>;

/**
 * __useRegeneratePdfMutation__
 *
 * To run a mutation, you first call `useRegeneratePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegeneratePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regeneratePdfMutation, { data, loading, error }] = useRegeneratePdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegeneratePdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegeneratePdfMutation,
    RegeneratePdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegeneratePdfMutation,
    RegeneratePdfMutationVariables
  >(RegeneratePdfDocument, options);
}
export type RegeneratePdfMutationHookResult = ReturnType<
  typeof useRegeneratePdfMutation
>;
export type RegeneratePdfMutationResult = Apollo.MutationResult<RegeneratePdfMutation>;
export type RegeneratePdfMutationOptions = Apollo.BaseMutationOptions<
  RegeneratePdfMutation,
  RegeneratePdfMutationVariables
>;
export const BoligselskapTiltakslisteDocument = gql`
  query BoligselskapTiltaksliste($slug: ID!) {
    report(slug: $slug) {
      id
      ... on HousingAssessment {
        housingMaintenanceSection {
          displayableBuildingParts {
            id
            name
            kg
            tg
            comment
            description
            descriptionDeviation
          }
        }
      }
    }
  }
`;

/**
 * __useBoligselskapTiltakslisteQuery__
 *
 * To run a query within a React component, call `useBoligselskapTiltakslisteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoligselskapTiltakslisteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoligselskapTiltakslisteQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBoligselskapTiltakslisteQuery(
  baseOptions: Apollo.QueryHookOptions<
    BoligselskapTiltakslisteQuery,
    BoligselskapTiltakslisteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BoligselskapTiltakslisteQuery,
    BoligselskapTiltakslisteQueryVariables
  >(BoligselskapTiltakslisteDocument, options);
}
export function useBoligselskapTiltakslisteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoligselskapTiltakslisteQuery,
    BoligselskapTiltakslisteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BoligselskapTiltakslisteQuery,
    BoligselskapTiltakslisteQueryVariables
  >(BoligselskapTiltakslisteDocument, options);
}
export type BoligselskapTiltakslisteQueryHookResult = ReturnType<
  typeof useBoligselskapTiltakslisteQuery
>;
export type BoligselskapTiltakslisteLazyQueryHookResult = ReturnType<
  typeof useBoligselskapTiltakslisteLazyQuery
>;
export type BoligselskapTiltakslisteQueryResult = Apollo.QueryResult<
  BoligselskapTiltakslisteQuery,
  BoligselskapTiltakslisteQueryVariables
>;
export const CostOverviewChartDocument = gql`
  query costOverviewChart(
    $slug: ID!
    $period: [MaintenancePeriodEnum!]
    $priority: [MaintenancePriorityEnum!]
  ) {
    report(slug: $slug) {
      id
      ... on Costs {
        cost {
          electrical: total(
            type: ELECTRICAL
            period: $period
            priority: $priority
          )
          construction: total(
            type: CONSTRUCTION
            period: $period
            priority: $priority
          )
          plumbing: total(type: PLUMBING, period: $period, priority: $priority)
          fire: total(type: FIRE, period: $period, priority: $priority)
        }
      }
    }
  }
`;

/**
 * __useCostOverviewChartQuery__
 *
 * To run a query within a React component, call `useCostOverviewChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostOverviewChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostOverviewChartQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      period: // value for 'period'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useCostOverviewChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    CostOverviewChartQuery,
    CostOverviewChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CostOverviewChartQuery,
    CostOverviewChartQueryVariables
  >(CostOverviewChartDocument, options);
}
export function useCostOverviewChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostOverviewChartQuery,
    CostOverviewChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CostOverviewChartQuery,
    CostOverviewChartQueryVariables
  >(CostOverviewChartDocument, options);
}
export type CostOverviewChartQueryHookResult = ReturnType<
  typeof useCostOverviewChartQuery
>;
export type CostOverviewChartLazyQueryHookResult = ReturnType<
  typeof useCostOverviewChartLazyQuery
>;
export type CostOverviewChartQueryResult = Apollo.QueryResult<
  CostOverviewChartQuery,
  CostOverviewChartQueryVariables
>;
export const CostOverviewTableDocument = gql`
  query CostOverviewTable($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Costs {
        cost {
          missingRegulationElectrical: total(
            period: MISSING_REGULATION
            type: ELECTRICAL
          )
          missingRegulationConstruction: total(
            period: MISSING_REGULATION
            type: CONSTRUCTION
          )
          missingRegulationPlumbing: total(
            period: MISSING_REGULATION
            type: PLUMBING
          )
          missingRegulationTotal: total(period: MISSING_REGULATION)
          immediateActionElectrical: total(period: IMMEDIATE, type: ELECTRICAL)
          immediateActionConstruction: total(
            period: IMMEDIATE
            type: CONSTRUCTION
          )
          immediateActionPlumbing: total(period: IMMEDIATE, type: PLUMBING)
          immediateActionTotal: total(period: IMMEDIATE)
          oneToFiveHighElectrical: total(
            period: _1_5_YEARS
            priority: HIGH
            type: ELECTRICAL
          )
          oneToFiveHighConstruction: total(
            period: _1_5_YEARS
            priority: HIGH
            type: CONSTRUCTION
          )
          oneToFiveHighPlumbing: total(
            period: _1_5_YEARS
            priority: HIGH
            type: PLUMBING
          )
          oneToFiveHighTotal: total(period: _1_5_YEARS, priority: HIGH)
          oneToFiveMediumElectrical: total(
            period: _1_5_YEARS
            priority: MEDIUM
            type: ELECTRICAL
          )
          oneToFiveMediumConstruction: total(
            period: _1_5_YEARS
            priority: MEDIUM
            type: CONSTRUCTION
          )
          oneToFiveMediumPlumbing: total(
            period: _1_5_YEARS
            priority: MEDIUM
            type: PLUMBING
          )
          oneToFiveMediumTotal: total(period: _1_5_YEARS, priority: MEDIUM)
          oneToFiveLowElectrical: total(
            period: _1_5_YEARS
            priority: LOW
            type: ELECTRICAL
          )
          oneToFiveLowConstruction: total(
            period: _1_5_YEARS
            priority: LOW
            type: CONSTRUCTION
          )
          oneToFiveLowPlumbing: total(
            period: _1_5_YEARS
            priority: LOW
            type: PLUMBING
          )
          oneToFiveLowTotal: total(period: _1_5_YEARS, priority: LOW)
          oneToFiveReplacementElectrical: total(
            period: _1_5_YEARS
            priority: REPLACEMENT
            type: ELECTRICAL
          )
          oneToFiveReplacementConstruction: total(
            period: _1_5_YEARS
            priority: REPLACEMENT
            type: CONSTRUCTION
          )
          oneToFiveReplacementPlumbing: total(
            period: _1_5_YEARS
            priority: REPLACEMENT
            type: PLUMBING
          )
          oneToFiveReplacementTotal: total(
            period: _1_5_YEARS
            priority: REPLACEMENT
          )
          sixToTenHighElectrical: total(
            period: _6_10_YEARS
            priority: HIGH
            type: ELECTRICAL
          )
          sixToTenHighConstruction: total(
            period: _6_10_YEARS
            priority: HIGH
            type: CONSTRUCTION
          )
          sixToTenHighPlumbing: total(
            period: _6_10_YEARS
            priority: HIGH
            type: PLUMBING
          )
          sixToTenHighTotal: total(period: _6_10_YEARS, priority: HIGH)
          sixToTenMediumElectrical: total(
            period: _6_10_YEARS
            priority: MEDIUM
            type: ELECTRICAL
          )
          sixToTenMediumConstruction: total(
            period: _6_10_YEARS
            priority: MEDIUM
            type: CONSTRUCTION
          )
          sixToTenMediumPlumbing: total(
            period: _6_10_YEARS
            priority: MEDIUM
            type: PLUMBING
          )
          sixToTenMediumTotal: total(period: _6_10_YEARS, priority: MEDIUM)
          sixToTenLowElectrical: total(
            period: _6_10_YEARS
            priority: LOW
            type: ELECTRICAL
          )
          sixToTenLowConstruction: total(
            period: _6_10_YEARS
            priority: LOW
            type: CONSTRUCTION
          )
          sixToTenLowPlumbing: total(
            period: _6_10_YEARS
            priority: LOW
            type: PLUMBING
          )
          sixToTenLowTotal: total(period: _6_10_YEARS, priority: LOW)
          sixToTenReplacementElectrical: total(
            period: _6_10_YEARS
            priority: REPLACEMENT
            type: ELECTRICAL
          )
          sixToTenReplacementConstruction: total(
            period: _6_10_YEARS
            priority: REPLACEMENT
            type: CONSTRUCTION
          )
          sixToTenReplacementPlumbing: total(
            period: _6_10_YEARS
            priority: REPLACEMENT
            type: PLUMBING
          )
          sixToTenReplacementTotal: total(
            period: _6_10_YEARS
            priority: REPLACEMENT
          )
          oneToFiveTotal: total(period: _1_5_YEARS)
          sixToTenTotal: total(period: _6_10_YEARS)
          constructionTotal: total(type: CONSTRUCTION)
          electricalTotal: total(type: ELECTRICAL)
          plumbingTotal: total(type: PLUMBING)
          total: total
        }
      }
    }
  }
`;

/**
 * __useCostOverviewTableQuery__
 *
 * To run a query within a React component, call `useCostOverviewTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostOverviewTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostOverviewTableQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCostOverviewTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    CostOverviewTableQuery,
    CostOverviewTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CostOverviewTableQuery,
    CostOverviewTableQueryVariables
  >(CostOverviewTableDocument, options);
}
export function useCostOverviewTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostOverviewTableQuery,
    CostOverviewTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CostOverviewTableQuery,
    CostOverviewTableQueryVariables
  >(CostOverviewTableDocument, options);
}
export type CostOverviewTableQueryHookResult = ReturnType<
  typeof useCostOverviewTableQuery
>;
export type CostOverviewTableLazyQueryHookResult = ReturnType<
  typeof useCostOverviewTableLazyQuery
>;
export type CostOverviewTableQueryResult = Apollo.QueryResult<
  CostOverviewTableQuery,
  CostOverviewTableQueryVariables
>;
export const DokumentasjonsKarakterDocument = gql`
  query DokumentasjonsKarakter($slug: ID!) {
    report(slug: $slug) {
      ... on Documentation {
        documentationSection {
          id
          grade
          score
        }
      }
    }
  }
`;

/**
 * __useDokumentasjonsKarakterQuery__
 *
 * To run a query within a React component, call `useDokumentasjonsKarakterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDokumentasjonsKarakterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDokumentasjonsKarakterQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDokumentasjonsKarakterQuery(
  baseOptions: Apollo.QueryHookOptions<
    DokumentasjonsKarakterQuery,
    DokumentasjonsKarakterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DokumentasjonsKarakterQuery,
    DokumentasjonsKarakterQueryVariables
  >(DokumentasjonsKarakterDocument, options);
}
export function useDokumentasjonsKarakterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DokumentasjonsKarakterQuery,
    DokumentasjonsKarakterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DokumentasjonsKarakterQuery,
    DokumentasjonsKarakterQueryVariables
  >(DokumentasjonsKarakterDocument, options);
}
export type DokumentasjonsKarakterQueryHookResult = ReturnType<
  typeof useDokumentasjonsKarakterQuery
>;
export type DokumentasjonsKarakterLazyQueryHookResult = ReturnType<
  typeof useDokumentasjonsKarakterLazyQuery
>;
export type DokumentasjonsKarakterQueryResult = Apollo.QueryResult<
  DokumentasjonsKarakterQuery,
  DokumentasjonsKarakterQueryVariables
>;
export const KostnadsfordelingTabellDocument = gql`
  query KostnadsfordelingTabell($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Costs {
        cost {
          coverageTenant: total(coverage: TENANT)
          coverageLandlord: total(coverage: LANDLORD)
          coverageBoth: total(coverage: BOTH)
          coverageTenantConstruction: total(
            coverage: TENANT
            type: CONSTRUCTION
          )
          coverageTenantElectrical: total(coverage: TENANT, type: ELECTRICAL)
          coverageTenantPlumbing: total(coverage: TENANT, type: PLUMBING)
          coverageTenantFire: total(coverage: TENANT, type: FIRE)
          coverageLandlordConstruction: total(
            coverage: LANDLORD
            type: CONSTRUCTION
          )
          coverageLandlordElectrical: total(
            coverage: LANDLORD
            type: ELECTRICAL
          )
          coverageLandlordPlumbing: total(coverage: LANDLORD, type: PLUMBING)
          coverageLandlordFire: total(coverage: LANDLORD, type: FIRE)
          coverageBothConstruction: total(coverage: BOTH, type: CONSTRUCTION)
          coverageBothElectrical: total(coverage: BOTH, type: ELECTRICAL)
          coverageBothPlumbing: total(coverage: BOTH, type: PLUMBING)
          coverageBothFire: total(coverage: BOTH, type: FIRE)
          totalConstruction: total(
            type: CONSTRUCTION
            coverage: [BOTH, LANDLORD, TENANT]
          )
          totalElectrical: total(
            type: ELECTRICAL
            coverage: [BOTH, LANDLORD, TENANT]
          )
          totalPlumbing: total(
            type: PLUMBING
            coverage: [BOTH, LANDLORD, TENANT]
          )
          totalFire: total(type: FIRE, coverage: [BOTH, LANDLORD, TENANT])
          total: total(coverage: [BOTH, LANDLORD, TENANT])
        }
      }
    }
  }
`;

/**
 * __useKostnadsfordelingTabellQuery__
 *
 * To run a query within a React component, call `useKostnadsfordelingTabellQuery` and pass it any options that fit your needs.
 * When your component renders, `useKostnadsfordelingTabellQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKostnadsfordelingTabellQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useKostnadsfordelingTabellQuery(
  baseOptions: Apollo.QueryHookOptions<
    KostnadsfordelingTabellQuery,
    KostnadsfordelingTabellQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    KostnadsfordelingTabellQuery,
    KostnadsfordelingTabellQueryVariables
  >(KostnadsfordelingTabellDocument, options);
}
export function useKostnadsfordelingTabellLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KostnadsfordelingTabellQuery,
    KostnadsfordelingTabellQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    KostnadsfordelingTabellQuery,
    KostnadsfordelingTabellQueryVariables
  >(KostnadsfordelingTabellDocument, options);
}
export type KostnadsfordelingTabellQueryHookResult = ReturnType<
  typeof useKostnadsfordelingTabellQuery
>;
export type KostnadsfordelingTabellLazyQueryHookResult = ReturnType<
  typeof useKostnadsfordelingTabellLazyQuery
>;
export type KostnadsfordelingTabellQueryResult = Apollo.QueryResult<
  KostnadsfordelingTabellQuery,
  KostnadsfordelingTabellQueryVariables
>;
export const VedlikeholdKostnadsTabellDocument = gql`
  query VedlikeholdKostnadsTabell(
    $id: ID!
    $intervals: [MaintenancePeriodEnum!]
    $periods: [MaintenancePeriodEnum!]
  ) {
    publication(id: $id) {
      ... on BuildingMaintenance {
        maintenance {
          cost {
            missingRegulation: total(period: MISSING_REGULATION)
            immediate: total(period: IMMEDIATE)
            intervals(interval: $intervals)
            total(period: $periods)
            firstYearM2
            documentation
          }
          sections {
            type
            cost {
              missingRegulation: total(period: MISSING_REGULATION)
              immediate: total(period: IMMEDIATE)
              intervals(interval: $intervals)
              total(period: $periods)
              firstYearM2
              documentation
            }
          }
        }
      }
    }
  }
`;

/**
 * __useVedlikeholdKostnadsTabellQuery__
 *
 * To run a query within a React component, call `useVedlikeholdKostnadsTabellQuery` and pass it any options that fit your needs.
 * When your component renders, `useVedlikeholdKostnadsTabellQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVedlikeholdKostnadsTabellQuery({
 *   variables: {
 *      id: // value for 'id'
 *      intervals: // value for 'intervals'
 *      periods: // value for 'periods'
 *   },
 * });
 */
export function useVedlikeholdKostnadsTabellQuery(
  baseOptions: Apollo.QueryHookOptions<
    VedlikeholdKostnadsTabellQuery,
    VedlikeholdKostnadsTabellQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VedlikeholdKostnadsTabellQuery,
    VedlikeholdKostnadsTabellQueryVariables
  >(VedlikeholdKostnadsTabellDocument, options);
}
export function useVedlikeholdKostnadsTabellLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VedlikeholdKostnadsTabellQuery,
    VedlikeholdKostnadsTabellQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VedlikeholdKostnadsTabellQuery,
    VedlikeholdKostnadsTabellQueryVariables
  >(VedlikeholdKostnadsTabellDocument, options);
}
export type VedlikeholdKostnadsTabellQueryHookResult = ReturnType<
  typeof useVedlikeholdKostnadsTabellQuery
>;
export type VedlikeholdKostnadsTabellLazyQueryHookResult = ReturnType<
  typeof useVedlikeholdKostnadsTabellLazyQuery
>;
export type VedlikeholdKostnadsTabellQueryResult = Apollo.QueryResult<
  VedlikeholdKostnadsTabellQuery,
  VedlikeholdKostnadsTabellQueryVariables
>;
export const VedlikeholdsKarakterDocument = gql`
  query VedlikeholdsKarakter($slug: ID!) {
    report(slug: $slug) {
      ... on Costs {
        cost {
          grade
          score
        }
      }
      ... on Buildings {
        buildings {
          id
          name
          cost {
            grade
            score
          }
        }
      }
    }
  }
`;

/**
 * __useVedlikeholdsKarakterQuery__
 *
 * To run a query within a React component, call `useVedlikeholdsKarakterQuery` and pass it any options that fit your needs.
 * When your component renders, `useVedlikeholdsKarakterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVedlikeholdsKarakterQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useVedlikeholdsKarakterQuery(
  baseOptions: Apollo.QueryHookOptions<
    VedlikeholdsKarakterQuery,
    VedlikeholdsKarakterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VedlikeholdsKarakterQuery,
    VedlikeholdsKarakterQueryVariables
  >(VedlikeholdsKarakterDocument, options);
}
export function useVedlikeholdsKarakterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VedlikeholdsKarakterQuery,
    VedlikeholdsKarakterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VedlikeholdsKarakterQuery,
    VedlikeholdsKarakterQueryVariables
  >(VedlikeholdsKarakterDocument, options);
}
export type VedlikeholdsKarakterQueryHookResult = ReturnType<
  typeof useVedlikeholdsKarakterQuery
>;
export type VedlikeholdsKarakterLazyQueryHookResult = ReturnType<
  typeof useVedlikeholdsKarakterLazyQuery
>;
export type VedlikeholdsKarakterQueryResult = Apollo.QueryResult<
  VedlikeholdsKarakterQuery,
  VedlikeholdsKarakterQueryVariables
>;
export const CreateSectionDocument = gql`
  mutation CreateSection($input: CreateSectionInput!) {
    sections {
      create(input: $input) {
        errors
        section {
          id
          report {
            id
            sections {
              id
              primary
              title
              visible
            }
          }
        }
      }
    }
  }
`;
export type CreateSectionMutationFn = Apollo.MutationFunction<
  CreateSectionMutation,
  CreateSectionMutationVariables
>;

/**
 * __useCreateSectionMutation__
 *
 * To run a mutation, you first call `useCreateSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSectionMutation, { data, loading, error }] = useCreateSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSectionMutation,
    CreateSectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSectionMutation,
    CreateSectionMutationVariables
  >(CreateSectionDocument, options);
}
export type CreateSectionMutationHookResult = ReturnType<
  typeof useCreateSectionMutation
>;
export type CreateSectionMutationResult = Apollo.MutationResult<CreateSectionMutation>;
export type CreateSectionMutationOptions = Apollo.BaseMutationOptions<
  CreateSectionMutation,
  CreateSectionMutationVariables
>;
export const DeleteSectionDocument = gql`
  mutation DeleteSection($input: DeleteSectionInput!) {
    sections {
      delete(input: $input) {
        errors
        section {
          id
          report {
            id
            sections {
              id
            }
          }
        }
      }
    }
  }
`;
export type DeleteSectionMutationFn = Apollo.MutationFunction<
  DeleteSectionMutation,
  DeleteSectionMutationVariables
>;

/**
 * __useDeleteSectionMutation__
 *
 * To run a mutation, you first call `useDeleteSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSectionMutation, { data, loading, error }] = useDeleteSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSectionMutation,
    DeleteSectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSectionMutation,
    DeleteSectionMutationVariables
  >(DeleteSectionDocument, options);
}
export type DeleteSectionMutationHookResult = ReturnType<
  typeof useDeleteSectionMutation
>;
export type DeleteSectionMutationResult = Apollo.MutationResult<DeleteSectionMutation>;
export type DeleteSectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteSectionMutation,
  DeleteSectionMutationVariables
>;
export const DeleteDocumentationBlockTemplateDocument = gql`
  mutation DeleteDocumentationBlockTemplate(
    $input: DeleteDocumentationBlockTemplateInput!
  ) {
    deleteDocumentationBlockTemplate(input: $input) {
      errors
      documentationBlockTemplate {
        id
        title
        description
        createdAt
      }
    }
  }
`;
export type DeleteDocumentationBlockTemplateMutationFn = Apollo.MutationFunction<
  DeleteDocumentationBlockTemplateMutation,
  DeleteDocumentationBlockTemplateMutationVariables
>;

/**
 * __useDeleteDocumentationBlockTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentationBlockTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentationBlockTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentationBlockTemplateMutation, { data, loading, error }] = useDeleteDocumentationBlockTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentationBlockTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentationBlockTemplateMutation,
    DeleteDocumentationBlockTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDocumentationBlockTemplateMutation,
    DeleteDocumentationBlockTemplateMutationVariables
  >(DeleteDocumentationBlockTemplateDocument, options);
}
export type DeleteDocumentationBlockTemplateMutationHookResult = ReturnType<
  typeof useDeleteDocumentationBlockTemplateMutation
>;
export type DeleteDocumentationBlockTemplateMutationResult = Apollo.MutationResult<DeleteDocumentationBlockTemplateMutation>;
export type DeleteDocumentationBlockTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentationBlockTemplateMutation,
  DeleteDocumentationBlockTemplateMutationVariables
>;
export const DocumentationBlockTemplatesDocument = gql`
  query documentationBlockTemplates {
    documentationBlockTemplates {
      id
      title
      description
      createdAt
      documentationLineTemplates {
        id
        title
        number
        columns
        documentationBlockTemplateId
      }
    }
  }
`;

/**
 * __useDocumentationBlockTemplatesQuery__
 *
 * To run a query within a React component, call `useDocumentationBlockTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationBlockTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationBlockTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentationBlockTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DocumentationBlockTemplatesQuery,
    DocumentationBlockTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DocumentationBlockTemplatesQuery,
    DocumentationBlockTemplatesQueryVariables
  >(DocumentationBlockTemplatesDocument, options);
}
export function useDocumentationBlockTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentationBlockTemplatesQuery,
    DocumentationBlockTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DocumentationBlockTemplatesQuery,
    DocumentationBlockTemplatesQueryVariables
  >(DocumentationBlockTemplatesDocument, options);
}
export type DocumentationBlockTemplatesQueryHookResult = ReturnType<
  typeof useDocumentationBlockTemplatesQuery
>;
export type DocumentationBlockTemplatesLazyQueryHookResult = ReturnType<
  typeof useDocumentationBlockTemplatesLazyQuery
>;
export type DocumentationBlockTemplatesQueryResult = Apollo.QueryResult<
  DocumentationBlockTemplatesQuery,
  DocumentationBlockTemplatesQueryVariables
>;
export const CreateHousingMaintenanceBuildingPartRatingRuleDocument = gql`
  mutation CreateHousingMaintenanceBuildingPartRatingRule(
    $input: CreateHousingMaintenanceBuildingPartRatingRuleInput!
  ) {
    createHousingMaintenanceBuildingPartRatingRule(input: $input) {
      errors
      housingMaintenanceBuildingPartRatingRule {
        id
        tg
        kg
        rating
        period
        description
      }
    }
  }
`;
export type CreateHousingMaintenanceBuildingPartRatingRuleMutationFn = Apollo.MutationFunction<
  CreateHousingMaintenanceBuildingPartRatingRuleMutation,
  CreateHousingMaintenanceBuildingPartRatingRuleMutationVariables
>;

/**
 * __useCreateHousingMaintenanceBuildingPartRatingRuleMutation__
 *
 * To run a mutation, you first call `useCreateHousingMaintenanceBuildingPartRatingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHousingMaintenanceBuildingPartRatingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHousingMaintenanceBuildingPartRatingRuleMutation, { data, loading, error }] = useCreateHousingMaintenanceBuildingPartRatingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHousingMaintenanceBuildingPartRatingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHousingMaintenanceBuildingPartRatingRuleMutation,
    CreateHousingMaintenanceBuildingPartRatingRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateHousingMaintenanceBuildingPartRatingRuleMutation,
    CreateHousingMaintenanceBuildingPartRatingRuleMutationVariables
  >(CreateHousingMaintenanceBuildingPartRatingRuleDocument, options);
}
export type CreateHousingMaintenanceBuildingPartRatingRuleMutationHookResult = ReturnType<
  typeof useCreateHousingMaintenanceBuildingPartRatingRuleMutation
>;
export type CreateHousingMaintenanceBuildingPartRatingRuleMutationResult = Apollo.MutationResult<CreateHousingMaintenanceBuildingPartRatingRuleMutation>;
export type CreateHousingMaintenanceBuildingPartRatingRuleMutationOptions = Apollo.BaseMutationOptions<
  CreateHousingMaintenanceBuildingPartRatingRuleMutation,
  CreateHousingMaintenanceBuildingPartRatingRuleMutationVariables
>;
export const DeleteHousingMaintenanceBuildingPartRatingRuleDocument = gql`
  mutation DeleteHousingMaintenanceBuildingPartRatingRule(
    $input: DeleteHousingMaintenanceBuildingPartRatingRuleInput!
  ) {
    deleteHousingMaintenanceBuildingPartRatingRule(input: $input) {
      errors
      housingMaintenanceBuildingPartRatingRule {
        id
        tg
        kg
        rating
        period
        description
      }
    }
  }
`;
export type DeleteHousingMaintenanceBuildingPartRatingRuleMutationFn = Apollo.MutationFunction<
  DeleteHousingMaintenanceBuildingPartRatingRuleMutation,
  DeleteHousingMaintenanceBuildingPartRatingRuleMutationVariables
>;

/**
 * __useDeleteHousingMaintenanceBuildingPartRatingRuleMutation__
 *
 * To run a mutation, you first call `useDeleteHousingMaintenanceBuildingPartRatingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHousingMaintenanceBuildingPartRatingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHousingMaintenanceBuildingPartRatingRuleMutation, { data, loading, error }] = useDeleteHousingMaintenanceBuildingPartRatingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteHousingMaintenanceBuildingPartRatingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteHousingMaintenanceBuildingPartRatingRuleMutation,
    DeleteHousingMaintenanceBuildingPartRatingRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteHousingMaintenanceBuildingPartRatingRuleMutation,
    DeleteHousingMaintenanceBuildingPartRatingRuleMutationVariables
  >(DeleteHousingMaintenanceBuildingPartRatingRuleDocument, options);
}
export type DeleteHousingMaintenanceBuildingPartRatingRuleMutationHookResult = ReturnType<
  typeof useDeleteHousingMaintenanceBuildingPartRatingRuleMutation
>;
export type DeleteHousingMaintenanceBuildingPartRatingRuleMutationResult = Apollo.MutationResult<DeleteHousingMaintenanceBuildingPartRatingRuleMutation>;
export type DeleteHousingMaintenanceBuildingPartRatingRuleMutationOptions = Apollo.BaseMutationOptions<
  DeleteHousingMaintenanceBuildingPartRatingRuleMutation,
  DeleteHousingMaintenanceBuildingPartRatingRuleMutationVariables
>;
export const HousingMaintenanceBuildingPartRatingRulesDocument = gql`
  query HousingMaintenanceBuildingPartRatingRules {
    housingMaintenanceBuildingPartRatingRules {
      id
      tg
      kg
      rating
      period
      description
    }
  }
`;

/**
 * __useHousingMaintenanceBuildingPartRatingRulesQuery__
 *
 * To run a query within a React component, call `useHousingMaintenanceBuildingPartRatingRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHousingMaintenanceBuildingPartRatingRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHousingMaintenanceBuildingPartRatingRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHousingMaintenanceBuildingPartRatingRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HousingMaintenanceBuildingPartRatingRulesQuery,
    HousingMaintenanceBuildingPartRatingRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HousingMaintenanceBuildingPartRatingRulesQuery,
    HousingMaintenanceBuildingPartRatingRulesQueryVariables
  >(HousingMaintenanceBuildingPartRatingRulesDocument, options);
}
export function useHousingMaintenanceBuildingPartRatingRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HousingMaintenanceBuildingPartRatingRulesQuery,
    HousingMaintenanceBuildingPartRatingRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HousingMaintenanceBuildingPartRatingRulesQuery,
    HousingMaintenanceBuildingPartRatingRulesQueryVariables
  >(HousingMaintenanceBuildingPartRatingRulesDocument, options);
}
export type HousingMaintenanceBuildingPartRatingRulesQueryHookResult = ReturnType<
  typeof useHousingMaintenanceBuildingPartRatingRulesQuery
>;
export type HousingMaintenanceBuildingPartRatingRulesLazyQueryHookResult = ReturnType<
  typeof useHousingMaintenanceBuildingPartRatingRulesLazyQuery
>;
export type HousingMaintenanceBuildingPartRatingRulesQueryResult = Apollo.QueryResult<
  HousingMaintenanceBuildingPartRatingRulesQuery,
  HousingMaintenanceBuildingPartRatingRulesQueryVariables
>;
export const UpdateHousingMaintenanceBuildingPartRatingRuleDocument = gql`
  mutation UpdateHousingMaintenanceBuildingPartRatingRule(
    $input: UpdateHousingMaintenanceBuildingPartRatingRuleInput!
  ) {
    updateHousingMaintenanceBuildingPartRatingRule(input: $input) {
      errors
      housingMaintenanceBuildingPartRatingRule {
        id
        tg
        kg
        rating
        period
        description
      }
    }
  }
`;
export type UpdateHousingMaintenanceBuildingPartRatingRuleMutationFn = Apollo.MutationFunction<
  UpdateHousingMaintenanceBuildingPartRatingRuleMutation,
  UpdateHousingMaintenanceBuildingPartRatingRuleMutationVariables
>;

/**
 * __useUpdateHousingMaintenanceBuildingPartRatingRuleMutation__
 *
 * To run a mutation, you first call `useUpdateHousingMaintenanceBuildingPartRatingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHousingMaintenanceBuildingPartRatingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHousingMaintenanceBuildingPartRatingRuleMutation, { data, loading, error }] = useUpdateHousingMaintenanceBuildingPartRatingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHousingMaintenanceBuildingPartRatingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHousingMaintenanceBuildingPartRatingRuleMutation,
    UpdateHousingMaintenanceBuildingPartRatingRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateHousingMaintenanceBuildingPartRatingRuleMutation,
    UpdateHousingMaintenanceBuildingPartRatingRuleMutationVariables
  >(UpdateHousingMaintenanceBuildingPartRatingRuleDocument, options);
}
export type UpdateHousingMaintenanceBuildingPartRatingRuleMutationHookResult = ReturnType<
  typeof useUpdateHousingMaintenanceBuildingPartRatingRuleMutation
>;
export type UpdateHousingMaintenanceBuildingPartRatingRuleMutationResult = Apollo.MutationResult<UpdateHousingMaintenanceBuildingPartRatingRuleMutation>;
export type UpdateHousingMaintenanceBuildingPartRatingRuleMutationOptions = Apollo.BaseMutationOptions<
  UpdateHousingMaintenanceBuildingPartRatingRuleMutation,
  UpdateHousingMaintenanceBuildingPartRatingRuleMutationVariables
>;
export const ReportSectionsDocument = gql`
  query ReportSections($slug: ID!) {
    report(slug: $slug) {
      id
      ... on Sectionable {
        sections {
          id
          primary
          title
          visible
        }
      }
    }
  }
`;

/**
 * __useReportSectionsQuery__
 *
 * To run a query within a React component, call `useReportSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSectionsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useReportSectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportSectionsQuery,
    ReportSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportSectionsQuery, ReportSectionsQueryVariables>(
    ReportSectionsDocument,
    options
  );
}
export function useReportSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportSectionsQuery,
    ReportSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportSectionsQuery, ReportSectionsQueryVariables>(
    ReportSectionsDocument,
    options
  );
}
export type ReportSectionsQueryHookResult = ReturnType<
  typeof useReportSectionsQuery
>;
export type ReportSectionsLazyQueryHookResult = ReturnType<
  typeof useReportSectionsLazyQuery
>;
export type ReportSectionsQueryResult = Apollo.QueryResult<
  ReportSectionsQuery,
  ReportSectionsQueryVariables
>;
export const RequestCostBlockTemplatesDocument = gql`
  query requestCostBlockTemplates {
    requestCostBlockTemplates {
      id
      title
      number
      createdAt
      internalUse
      requestCostLineTemplates {
        id
        number
        title
        requestType
        costTypePrimary
        costTypeSecondary
      }
    }
  }
`;

/**
 * __useRequestCostBlockTemplatesQuery__
 *
 * To run a query within a React component, call `useRequestCostBlockTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestCostBlockTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestCostBlockTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestCostBlockTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RequestCostBlockTemplatesQuery,
    RequestCostBlockTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestCostBlockTemplatesQuery,
    RequestCostBlockTemplatesQueryVariables
  >(RequestCostBlockTemplatesDocument, options);
}
export function useRequestCostBlockTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestCostBlockTemplatesQuery,
    RequestCostBlockTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestCostBlockTemplatesQuery,
    RequestCostBlockTemplatesQueryVariables
  >(RequestCostBlockTemplatesDocument, options);
}
export type RequestCostBlockTemplatesQueryHookResult = ReturnType<
  typeof useRequestCostBlockTemplatesQuery
>;
export type RequestCostBlockTemplatesLazyQueryHookResult = ReturnType<
  typeof useRequestCostBlockTemplatesLazyQuery
>;
export type RequestCostBlockTemplatesQueryResult = Apollo.QueryResult<
  RequestCostBlockTemplatesQuery,
  RequestCostBlockTemplatesQueryVariables
>;
export const RequestLineTemplatesDocument = gql`
  query requestLineTemplates {
    requestLineTemplates {
      id
      title
      number
    }
  }
`;

/**
 * __useRequestLineTemplatesQuery__
 *
 * To run a query within a React component, call `useRequestLineTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestLineTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestLineTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestLineTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RequestLineTemplatesQuery,
    RequestLineTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestLineTemplatesQuery,
    RequestLineTemplatesQueryVariables
  >(RequestLineTemplatesDocument, options);
}
export function useRequestLineTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestLineTemplatesQuery,
    RequestLineTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestLineTemplatesQuery,
    RequestLineTemplatesQueryVariables
  >(RequestLineTemplatesDocument, options);
}
export type RequestLineTemplatesQueryHookResult = ReturnType<
  typeof useRequestLineTemplatesQuery
>;
export type RequestLineTemplatesLazyQueryHookResult = ReturnType<
  typeof useRequestLineTemplatesLazyQuery
>;
export type RequestLineTemplatesQueryResult = Apollo.QueryResult<
  RequestLineTemplatesQuery,
  RequestLineTemplatesQueryVariables
>;
export const CreateRequestLineDocument = gql`
  mutation CreateRequestLine($input: CreateRequestLineInput!) {
    sections {
      requests {
        createLine(input: $input) {
          errors
          requestLine {
            ...RequestLineFields
            section {
              id
              requestLines {
                id
              }
            }
          }
        }
      }
    }
  }
  ${RequestLineFieldsFragmentDoc}
`;
export type CreateRequestLineMutationFn = Apollo.MutationFunction<
  CreateRequestLineMutation,
  CreateRequestLineMutationVariables
>;

/**
 * __useCreateRequestLineMutation__
 *
 * To run a mutation, you first call `useCreateRequestLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestLineMutation, { data, loading, error }] = useCreateRequestLineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRequestLineMutation,
    CreateRequestLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRequestLineMutation,
    CreateRequestLineMutationVariables
  >(CreateRequestLineDocument, options);
}
export type CreateRequestLineMutationHookResult = ReturnType<
  typeof useCreateRequestLineMutation
>;
export type CreateRequestLineMutationResult = Apollo.MutationResult<CreateRequestLineMutation>;
export type CreateRequestLineMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestLineMutation,
  CreateRequestLineMutationVariables
>;
export const DeleteRequestLineDocument = gql`
  mutation DeleteRequestLine($input: DeleteRequestLineInput!) {
    sections {
      requests {
        deleteLine(input: $input) {
          errors
          requestLine {
            id
            section {
              id
              requestLines {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export type DeleteRequestLineMutationFn = Apollo.MutationFunction<
  DeleteRequestLineMutation,
  DeleteRequestLineMutationVariables
>;

/**
 * __useDeleteRequestLineMutation__
 *
 * To run a mutation, you first call `useDeleteRequestLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestLineMutation, { data, loading, error }] = useDeleteRequestLineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequestLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRequestLineMutation,
    DeleteRequestLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRequestLineMutation,
    DeleteRequestLineMutationVariables
  >(DeleteRequestLineDocument, options);
}
export type DeleteRequestLineMutationHookResult = ReturnType<
  typeof useDeleteRequestLineMutation
>;
export type DeleteRequestLineMutationResult = Apollo.MutationResult<DeleteRequestLineMutation>;
export type DeleteRequestLineMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequestLineMutation,
  DeleteRequestLineMutationVariables
>;
export const UpdateRequestLineDocument = gql`
  mutation UpdateRequestLine($input: UpdateRequestLineInput!) {
    sections {
      requests {
        updateLine(input: $input) {
          errors
          requestLine {
            ...RequestLineFields
          }
        }
      }
    }
  }
  ${RequestLineFieldsFragmentDoc}
`;
export type UpdateRequestLineMutationFn = Apollo.MutationFunction<
  UpdateRequestLineMutation,
  UpdateRequestLineMutationVariables
>;

/**
 * __useUpdateRequestLineMutation__
 *
 * To run a mutation, you first call `useUpdateRequestLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestLineMutation, { data, loading, error }] = useUpdateRequestLineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRequestLineMutation,
    UpdateRequestLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRequestLineMutation,
    UpdateRequestLineMutationVariables
  >(UpdateRequestLineDocument, options);
}
export type UpdateRequestLineMutationHookResult = ReturnType<
  typeof useUpdateRequestLineMutation
>;
export type UpdateRequestLineMutationResult = Apollo.MutationResult<UpdateRequestLineMutation>;
export type UpdateRequestLineMutationOptions = Apollo.BaseMutationOptions<
  UpdateRequestLineMutation,
  UpdateRequestLineMutationVariables
>;
export const SetSectionVisibilityDocument = gql`
  mutation SetSectionVisibility($input: UpdateSectionInput!) {
    sections {
      update(input: $input) {
        errors
        section {
          id
          visible
        }
      }
    }
  }
`;
export type SetSectionVisibilityMutationFn = Apollo.MutationFunction<
  SetSectionVisibilityMutation,
  SetSectionVisibilityMutationVariables
>;

/**
 * __useSetSectionVisibilityMutation__
 *
 * To run a mutation, you first call `useSetSectionVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSectionVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSectionVisibilityMutation, { data, loading, error }] = useSetSectionVisibilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSectionVisibilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSectionVisibilityMutation,
    SetSectionVisibilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetSectionVisibilityMutation,
    SetSectionVisibilityMutationVariables
  >(SetSectionVisibilityDocument, options);
}
export type SetSectionVisibilityMutationHookResult = ReturnType<
  typeof useSetSectionVisibilityMutation
>;
export type SetSectionVisibilityMutationResult = Apollo.MutationResult<SetSectionVisibilityMutation>;
export type SetSectionVisibilityMutationOptions = Apollo.BaseMutationOptions<
  SetSectionVisibilityMutation,
  SetSectionVisibilityMutationVariables
>;
export const SortSectionsDocument = gql`
  mutation SortSections($input: SortSectionInput!) {
    sections {
      sort(input: $input) {
        errors
        report {
          id
          sections {
            id
          }
        }
      }
    }
  }
`;
export type SortSectionsMutationFn = Apollo.MutationFunction<
  SortSectionsMutation,
  SortSectionsMutationVariables
>;

/**
 * __useSortSectionsMutation__
 *
 * To run a mutation, you first call `useSortSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortSectionsMutation, { data, loading, error }] = useSortSectionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortSectionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortSectionsMutation,
    SortSectionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SortSectionsMutation,
    SortSectionsMutationVariables
  >(SortSectionsDocument, options);
}
export type SortSectionsMutationHookResult = ReturnType<
  typeof useSortSectionsMutation
>;
export type SortSectionsMutationResult = Apollo.MutationResult<SortSectionsMutation>;
export type SortSectionsMutationOptions = Apollo.BaseMutationOptions<
  SortSectionsMutation,
  SortSectionsMutationVariables
>;
export const CreateParagraphDocument = gql`
  mutation CreateParagraph($input: CreateParagraphInput!) {
    sections {
      texts {
        paragraphs {
          create(input: $input) {
            errors
            paragraph {
              ...ParagraphFields
              textSection {
                id
                paragraphs {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
  ${ParagraphFieldsFragmentDoc}
`;
export type CreateParagraphMutationFn = Apollo.MutationFunction<
  CreateParagraphMutation,
  CreateParagraphMutationVariables
>;

/**
 * __useCreateParagraphMutation__
 *
 * To run a mutation, you first call `useCreateParagraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParagraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParagraphMutation, { data, loading, error }] = useCreateParagraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateParagraphMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateParagraphMutation,
    CreateParagraphMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateParagraphMutation,
    CreateParagraphMutationVariables
  >(CreateParagraphDocument, options);
}
export type CreateParagraphMutationHookResult = ReturnType<
  typeof useCreateParagraphMutation
>;
export type CreateParagraphMutationResult = Apollo.MutationResult<CreateParagraphMutation>;
export type CreateParagraphMutationOptions = Apollo.BaseMutationOptions<
  CreateParagraphMutation,
  CreateParagraphMutationVariables
>;
export const DeleteParagraphDocument = gql`
  mutation DeleteParagraph($input: DeleteParagraphInput!) {
    sections {
      texts {
        paragraphs {
          delete(input: $input) {
            errors
            paragraph {
              id
              textSection {
                id
                paragraphs {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type DeleteParagraphMutationFn = Apollo.MutationFunction<
  DeleteParagraphMutation,
  DeleteParagraphMutationVariables
>;

/**
 * __useDeleteParagraphMutation__
 *
 * To run a mutation, you first call `useDeleteParagraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParagraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParagraphMutation, { data, loading, error }] = useDeleteParagraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteParagraphMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteParagraphMutation,
    DeleteParagraphMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteParagraphMutation,
    DeleteParagraphMutationVariables
  >(DeleteParagraphDocument, options);
}
export type DeleteParagraphMutationHookResult = ReturnType<
  typeof useDeleteParagraphMutation
>;
export type DeleteParagraphMutationResult = Apollo.MutationResult<DeleteParagraphMutation>;
export type DeleteParagraphMutationOptions = Apollo.BaseMutationOptions<
  DeleteParagraphMutation,
  DeleteParagraphMutationVariables
>;
export const EditTextSectionDocument = gql`
  query editTextSection($id: ID!) {
    node(id: $id) {
      id
      ... on TextSection {
        title
        paragraphs {
          ...ParagraphFields
        }
        report {
          textSections {
            id
          }
          ... on Costs {
            costSections {
              id
              costType
            }
          }
        }
      }
    }
  }
  ${ParagraphFieldsFragmentDoc}
`;

/**
 * __useEditTextSectionQuery__
 *
 * To run a query within a React component, call `useEditTextSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTextSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTextSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditTextSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    EditTextSectionQuery,
    EditTextSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditTextSectionQuery, EditTextSectionQueryVariables>(
    EditTextSectionDocument,
    options
  );
}
export function useEditTextSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditTextSectionQuery,
    EditTextSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditTextSectionQuery,
    EditTextSectionQueryVariables
  >(EditTextSectionDocument, options);
}
export type EditTextSectionQueryHookResult = ReturnType<
  typeof useEditTextSectionQuery
>;
export type EditTextSectionLazyQueryHookResult = ReturnType<
  typeof useEditTextSectionLazyQuery
>;
export type EditTextSectionQueryResult = Apollo.QueryResult<
  EditTextSectionQuery,
  EditTextSectionQueryVariables
>;
export const SortParagraphsDocument = gql`
  mutation SortParagraphs($input: SortParagraphsInput!) {
    sections {
      texts {
        paragraphs {
          sort(input: $input) {
            errors
            textSection {
              id
              paragraphs {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export type SortParagraphsMutationFn = Apollo.MutationFunction<
  SortParagraphsMutation,
  SortParagraphsMutationVariables
>;

/**
 * __useSortParagraphsMutation__
 *
 * To run a mutation, you first call `useSortParagraphsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortParagraphsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortParagraphsMutation, { data, loading, error }] = useSortParagraphsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortParagraphsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortParagraphsMutation,
    SortParagraphsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SortParagraphsMutation,
    SortParagraphsMutationVariables
  >(SortParagraphsDocument, options);
}
export type SortParagraphsMutationHookResult = ReturnType<
  typeof useSortParagraphsMutation
>;
export type SortParagraphsMutationResult = Apollo.MutationResult<SortParagraphsMutation>;
export type SortParagraphsMutationOptions = Apollo.BaseMutationOptions<
  SortParagraphsMutation,
  SortParagraphsMutationVariables
>;
export const UpdateParagraphDocument = gql`
  mutation UpdateParagraph($input: UpdateParagraphInput!) {
    sections {
      texts {
        paragraphs {
          update(input: $input) {
            errors
            paragraph {
              ...ParagraphFields
            }
          }
        }
      }
    }
  }
  ${ParagraphFieldsFragmentDoc}
`;
export type UpdateParagraphMutationFn = Apollo.MutationFunction<
  UpdateParagraphMutation,
  UpdateParagraphMutationVariables
>;

/**
 * __useUpdateParagraphMutation__
 *
 * To run a mutation, you first call `useUpdateParagraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParagraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParagraphMutation, { data, loading, error }] = useUpdateParagraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateParagraphMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParagraphMutation,
    UpdateParagraphMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateParagraphMutation,
    UpdateParagraphMutationVariables
  >(UpdateParagraphDocument, options);
}
export type UpdateParagraphMutationHookResult = ReturnType<
  typeof useUpdateParagraphMutation
>;
export type UpdateParagraphMutationResult = Apollo.MutationResult<UpdateParagraphMutation>;
export type UpdateParagraphMutationOptions = Apollo.BaseMutationOptions<
  UpdateParagraphMutation,
  UpdateParagraphMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      errors
      user {
        id
      }
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const UserIndexDocument = gql`
  query UserIndex($term: String) {
    users(term: $term) {
      id
      name
      email
      role
      createdAt
    }
  }
`;

/**
 * __useUserIndexQuery__
 *
 * To run a query within a React component, call `useUserIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIndexQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useUserIndexQuery(
  baseOptions?: Apollo.QueryHookOptions<UserIndexQuery, UserIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserIndexQuery, UserIndexQueryVariables>(
    UserIndexDocument,
    options
  );
}
export function useUserIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserIndexQuery,
    UserIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserIndexQuery, UserIndexQueryVariables>(
    UserIndexDocument,
    options
  );
}
export type UserIndexQueryHookResult = ReturnType<typeof useUserIndexQuery>;
export type UserIndexLazyQueryHookResult = ReturnType<
  typeof useUserIndexLazyQuery
>;
export type UserIndexQueryResult = Apollo.QueryResult<
  UserIndexQuery,
  UserIndexQueryVariables
>;

import { Alert as BSAlert } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  color?: string;
}

const Alert = styled(BSAlert)<Props>`
  /* margin-bottom: 1rem;
  padding: 1rem; */

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

export default Alert;

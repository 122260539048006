import { useMutation } from "@apollo/client";
import React from "react";
import { BlockListSortable } from "../layout/BlockList";
import BuildingListExpandableItemHeader from "./BuildingListExpandableItemHeader";
import { Building, BUILDINGS_SORT_MUTATION } from "./constants";

interface Props {
  buildings: Building[];
}

const BuildingListSortable: React.FC<Props> = ({ buildings }) => {
  const [sortBuildings] = useMutation(BUILDINGS_SORT_MUTATION);

  const onSort = (ids: string[]) =>
    sortBuildings({
      variables: {
        input: {
          ids,
        },
      },
    });

  return (
    <BlockListSortable items={buildings} onSort={onSort}>
      {(building) => <BuildingListExpandableItemHeader building={building} />}
    </BlockListSortable>
  );
};

export default BuildingListSortable;

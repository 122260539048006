import { pick } from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Fieldset, Form, RedactorField, TextField } from "~/form";
import { DocumentationBlockTemplate } from "~/graphql-operations";
import { Block } from "~/layout";
import { useUpdateDocumentationBlockTemplate } from ".";

type Props = { template: DocumentationBlockTemplate };

const EditDocumentationBlockTemplate: React.FC<Props> = ({ template }) => {
  const defaultValues = pick(template, ["id", "description", "title"]);

  const update = useUpdateDocumentationBlockTemplate();

  return (
    <Block>
      <Form onSubmit={update} defaultValues={defaultValues} autoSave horizontal>
        <Fieldset title="Dokumentasjonsblokkmal">
          <Row>
            <Col>
              <TextField name="title" label="Tittel" />
            </Col>
          </Row>
          <Row>
            <Col>
              <RedactorField name="description" label="Beskrivelse" />
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default EditDocumentationBlockTemplate;

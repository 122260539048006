import React, { useState } from "react";
import styled, { css } from "styled-components";
import { BlockListItem } from ".";

interface Props {
  id?: string;
  style?: React.CSSProperties;
  render: (props: { expanded: boolean }) => React.ReactNode;
}

interface StyledToggleProps {
  active: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const StyledToggle = styled.div<StyledToggleProps>`
  margin: ${(props) => (props.active ? "-1rem -1rem 1rem -1rem" : "-1rem")};
  /* margin: -1rem -1rem 0 -1rem; */
  /* padding: ${(props) => (props.active ? "1rem" : "0")}; */
  padding: 1rem;
  color: var(--${({ active }) => (active ? "white" : "dark-gray")});
  background: var(--${({ active }) => (active ? "dark-gray" : "white")});
  border-radius: ${({ active }) => (active ? "6px 6px 0 0" : "6px")};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        background: var(--smoke);
      }
    `}
`;

const BlockListItemExpandable: React.FC<Props> = ({
  render,
  children,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);

  const onClickToggle: StyledToggleProps["onClick"] = (e) => {
    switch ((e.target as HTMLElement).tagName) {
      case "A":
      case "BUTTON":
        return;
      default:
        setExpanded(!expanded);
    }
  };

  return (
    <BlockListItem {...rest}>
      {render && (
        <StyledToggle active={expanded} onClick={onClickToggle}>
          {render({ expanded })}
        </StyledToggle>
      )}
      {typeof children == "function"
        ? children(expanded, setExpanded)
        : expanded && children}
    </BlockListItem>
  );
};

export default BlockListItemExpandable;

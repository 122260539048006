import { Reference, useMutation } from "@apollo/client";
import {
  RequestCostLine,
  REQUEST_COST_LINE_CREATE_MUTATION,
  REQUEST_COST_LINE_DELETE_MUTATION,
  REQUEST_COST_LINE_UPDATE_MUTATION,
} from ".";
import { RequestCostBlock } from "../cost-block/constants";
import { requestCostLineFragment } from "./constants";

export const createMutation = (requestCostBlock: RequestCostBlock) => {
  const [createCostLine] = useMutation(REQUEST_COST_LINE_CREATE_MUTATION);

  return (input: Partial<RequestCostLine>) => {
    return createCostLine({
      variables: {
        input: {
          ...input,
          requestCostBlockId: requestCostBlock.id, // .toString(),
        },
      },
      update: (cache, { data: { requestCostLineCreate } }) => {
        const newRef = cache.writeFragment({
          data: requestCostLineCreate,
          fragment: requestCostLineFragment,
          fragmentName: "RequestCostLineFields",
        });

        cache.modify({
          id: cache.identify(requestCostBlock),
          fields: {
            requestCostLinesCount: (count: number) => count + 1,
            requestCostLines: (refs, { readField }) =>
              [...refs, newRef].sort((a, b) => {
                const [aNumber, bNumber] = [a, b].map((ref) =>
                  parseInt(
                    (readField("number", ref) as string).replace(/\./g, "")
                  )
                );
                return aNumber > bNumber ? 1 : bNumber > aNumber ? -1 : 0;
              }),
          },
        });
      },
    });
  };
};

export const updateMutation = (id: string) => {
  const [update] = useMutation(REQUEST_COST_LINE_UPDATE_MUTATION);

  return (input: Partial<RequestCostLine>) => {
    return update({
      variables: {
        input: {
          ...input,
          id,
        },
      },
    });
  };
};

export const deleteMutation = (requestCostBlock?: RequestCostBlock) => {
  const [mutation] = useMutation(REQUEST_COST_LINE_DELETE_MUTATION);

  return (id: string) =>
    mutation({
      variables: {
        input: {
          id,
        },
      },
      update(cache) {
        if (!requestCostBlock) return;
        cache.modify({
          id: cache.identify(requestCostBlock),
          fields: {
            requestCostLinesCount: (count: number) => count - 1,
            requestCostLines: (refs, { readField }) =>
              refs.filter((ref: Reference) => id !== readField("id", ref)),
          },
        });
      },
    });
};

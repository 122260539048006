import KostnadsfordelingDiagram from "./KostnadsfordelingDiagram";
import React from "react";
import { ShortcodeProps } from ".";
import VedlikeholdKostnadsDiagram from "./VedlikeholdKostnadsDiagram";
import styled from "styled-components";

const ComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ChartWrapper = styled.div`
  max-width: 50%;
  flex-basis: 50%;
`;

const VedlikeholdKostnadsfordelingDiagram: React.FC<ShortcodeProps> = ({
  report,
}) => {
  return (
    <ComponentWrapper>
      <ChartWrapper>
        <KostnadsfordelingDiagram
          report={report}
          code={"kostnadsfordeling_diagram"}
        />
      </ChartWrapper>

      <ChartWrapper>
        <VedlikeholdKostnadsDiagram
          report={report}
          code={"vedlikehold_kostnads_diagram"}
        />
      </ChartWrapper>
    </ComponentWrapper>
  );
};

export default VedlikeholdKostnadsfordelingDiagram;

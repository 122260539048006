import React from "react";
import styled from "styled-components";
import {
  DeleteGroupButton,
  EditableGroupName,
  InvoiceEntryCategoryGroup,
  useUpdateInvoiceEntryCategoryGroup,
} from "~/invoice/entry/category/group";

export const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
  background: #fff;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
`;

const ItemHeader: React.FC<{
  group: Pick<InvoiceEntryCategoryGroup, "id" | "name">;
  hasCategories: boolean;
}> = ({ group: { id, name }, hasCategories }) => {
  const updateMutation = useUpdateInvoiceEntryCategoryGroup();
  const onUpdate = (newName: string) => updateMutation({ id, name: newName });

  return (
    <>
      <EditableGroupName name={name} onUpdate={onUpdate} />
      <ButtonsWrap>
        <DeleteGroupButton group={{ id, name }} hasChildren={hasCategories} />
      </ButtonsWrap>
    </>
  );
};

export default ItemHeader;

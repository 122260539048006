import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from ".";
import { WordLoader } from "~/layout/Loading";

const REPORT_BUILDINGS_INFO_QUERY = gql`
  query EiendomMatrikkel($slug: ID!) {
    report(slug: $slug) {
      municipality
      ... on Buildings {
        buildings {
          id
          name
          bnr
          gnr
          fnr
          snr
        }
      }
    }
  }
`;

const EiendomMatrikkel: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery<{
    report: {
      municipality?: string;
      buildings: {
        id: string | number;
        name?: string;
        bnr?: string;
        gnr?: string;
        fnr?: string;
        snr?: string;
      }[];
    };
  }>(REPORT_BUILDINGS_INFO_QUERY, {
    variables: { slug },
  });
  if (loading) return <WordLoader />;
  if (error) return <p>Error!</p>;

  const { buildings, municipality } = data?.report || {};

  return (
    <span>
      {buildings.map((building) => {
        const { id, bnr, gnr, fnr, snr, name } = building;
        return (
          <span key={id}>
            {buildings.length > 1 && name} med {gnr && <span> gnr. {gnr}</span>}
            {bnr && <span>, bnr. {bnr}</span>}
            {fnr && <span>, fnr. {fnr}</span>}
            {snr && <span>, snr. {snr}</span>}
            {municipality && <span> i {municipality} kommune</span>}
            <br />
          </span>
        );
      })}
    </span>
  );
};

export default EiendomMatrikkel;

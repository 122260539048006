import { gql } from "@apollo/client";
import { Report, reportCompanyFragment } from "~/report";
import { User, userFragment } from "~/user";

export type Relation =
  | "project_manager"
  | "partner"
  | "construction_consultant"
  | "plumbing_consultant"
  | "electrical_consultant"
  | "fire_consultant"
  | "construction_ks_consultant"
  | "plumbing_ks_consultant"
  | "electrical_ks_consultant"
  | "fire_ks_consultant"
  | "client"
  | "associate"
  | "collaborator"
  | "accessor";

export type Permission = "view" | "edit";

export interface Membership {
  id: string;
  userId: string;
  reportId: string;
  reportSlug: string;
  relation: Relation;
  permissions: Permission[];
  preferences: string; // FIXME: JSON
  report: Report;
  user: User;
}

export const membershipFieldsFragment = gql`
  fragment MembershipFields on Membership {
    id
    userId
    reportId
    relation
    permissions
    preferences
  }
`;

export const editMembershipFieldsFragment = gql`
  fragment EditMembershipFields on Membership {
    id
    userId
    reportId
    relation
    permissions
    report {
      ...ReportCompanyFields
      # client {
      #   userId
      # }
      urls {
        show
        edit
      }
    }
    user {
      ...UserFields
    }
  }
  ${reportCompanyFragment}
  ${userFragment}
`;

export const MEMBERSHIPS_QUERY = gql`
  query Memberships($reportId: ID, $userId: ID) {
    memberships(reportId: $reportId, userId: $userId) {
      ...EditMembershipFields
    }
  }
  ${editMembershipFieldsFragment}
`;

export const MEMBERSHIP_CREATE_MUTATION = gql`
  mutation MembershipCreate($input: MembershipCreateInput!) {
    membershipCreate(input: $input) {
      membership {
        ...EditMembershipFields
      }
    }
  }
  ${editMembershipFieldsFragment}
`;

export const MEMBERSHIP_UPDATE_MUTATION = gql`
  mutation MembershipUpdate($input: MembershipUpdateInput!) {
    membershipUpdate(input: $input) {
      membership {
        ...MembershipFields
      }
    }
  }
  ${membershipFieldsFragment}
`;

export const MEMBERSHIP_DELETE_MUTATION = gql`
  mutation MembershipDelete($input: MembershipDeleteInput!) {
    membershipDelete(input: $input) {
      membership {
        id
      }
    }
  }
`;

import { useQuery } from "@apollo/client";
import React from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import styled from "styled-components";
import { BoligselskapTiltakslisteDocument } from "~/graphql-operations";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from ".";

const Container = styled.div`
  width: 100%;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
`;

const Table = styled(BootstrapTable)`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-color: grey;
`;

const THead = styled.thead`
  background-color: #374246;
  display: table-header-group;
  vertical-align: middle;
`;

const THCell = styled.th`
  font-size: 0.75rem !important;
  color: #fff !important;
  border-left: 1px solid rgba(216, 216, 216, 0.2);
  border-right: 1px solid rgba(216, 216, 216, 0.2) !important;
  padding: 15px !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  vertical-align: middle !important;
`;

const TCell = styled.td<{
  children?: React.ReactNode;
}>`
  vertical-align: middle !important;
  padding: 1em !important;
  font-size: 0.875rem;
  border-left: 1px solid rgba(125, 145, 152, 0.2);
  border-right: 1px solid rgba(125, 145, 152, 0.2);
`;

const TRow = styled.tr`
  ${TCell} {
    &:first-child {
      font-weight: 700;
    }
  }
`;

const StyledP = styled.p`
  line-height: 1.86;
`;

const BoligselskapTiltaksliste: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(BoligselskapTiltakslisteDocument, {
    variables: { slug },
  });

  if (loading) return <WordLoader />;
  if (error) return <p>Error</p>;

  const report = data?.report || {};
  if (!("housingMaintenanceSection" in report)) return <p>Error </p>;

  const { housingMaintenanceSection } = report;
  if (!housingMaintenanceSection) return <p>Error</p>;

  const { displayableBuildingParts } = housingMaintenanceSection;
  if (!displayableBuildingParts) return null;

  return (
    <Container>
      <Table responsive="sm">
        <THead>
          <tr>
            <THCell>Bygningsdel</THCell>
            <THCell>TG</THCell>
            <THCell>KG</THCell>
            <THCell>Beskrivelse tilstand</THCell>
            <THCell>Kommentar</THCell>
          </tr>
        </THead>
        <tbody>
          {displayableBuildingParts.map((buildingPart) => {
            const {
              id,
              name,
              tg,
              kg,
              description,
              descriptionDeviation,
              comment,
            } = buildingPart;
            return (
              <TRow key={id}>
                <TCell>{name}</TCell>
                <TCell>{tg}</TCell>
                <TCell>{kg}</TCell>
                <TCell>
                  <StyledP>{description}</StyledP>

                  {descriptionDeviation && (
                    <>
                      <hr></hr>
                      <StyledP>{descriptionDeviation}</StyledP>
                    </>
                  )}
                </TCell>
                <TCell>{comment}</TCell>
              </TRow>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default BoligselskapTiltaksliste;

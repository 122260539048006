import { ApolloProvider, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  BuildingListExpandable,
  BuildingListHeader,
  BuildingListSortable,
} from ".";
import { client } from "../setup";
import { Building, BUILDINGS_QUERY } from "./constants";

interface Props {
  slug: string;
}

type State = "expandable" | "sortable";

const BuildingList: React.FC<Props> = ({ slug }) => {
  const [state, setState] = useState<State>("expandable");
  const { loading, error, data } = useQuery<{
    report: { buildings: [Building] };
  }>(BUILDINGS_QUERY, {
    variables: { slug },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const buildings = [...(data?.report.buildings || [])];

  const onSortable = () => setState("sortable");
  const onExpandable = () => setState("expandable");

  const Component =
    state == "sortable" ? BuildingListSortable : BuildingListExpandable;

  return (
    <>
      <BuildingListHeader
        onSortable={onSortable}
        onExpandable={onExpandable}
        state={state}
      />
      <Component buildings={buildings} />
    </>
  );
};

const ApolloWrap: React.FC<Props> = (props: Props) => (
  <ApolloProvider client={client}>
    <BuildingList {...props} />
  </ApolloProvider>
);

export default ApolloWrap;

import { ApolloProvider, useQuery } from "@apollo/client";
import { pick } from "lodash";
import React from "react";
import { Form, RedactorField } from "~/form";
import { ConfirmButton } from "~/layout";
import { NodeUpdateInput } from "~/node";
import { Session, SessionProvider } from "~/session";
import { client } from "~/setup";
import {
  CVLine,
  cvLineToInput,
  User,
  useResetUserPassword,
  USER_QUERY,
  useUpdateUser,
} from ".";
import { EditCVGroup } from "./cv";
import UserForm from "./UserForm";

interface WrapProps {
  session: Session;
  id: User["id"];
}

const EditUser: React.FC<{ id: User["id"] }> = ({ id }) => {
  const updateUser = useUpdateUser();
  const resetPassword = useResetUserPassword();
  const { loading, error, data } = useQuery<{ user: User }>(USER_QUERY, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error || !data) return <p>Error! {error?.message}</p>;

  const {
    user: {
      cv: { education, experience, description },
    },
  } = data;

  const cv = {
    education: cvLineToInput(education || []),
    experience: cvLineToInput(experience || []),
    description,
  };

  const userFormInput = pick(data.user, [
    "id",
    "companyIds",
    "email",
    "firstName",
    "lastName",
    "phone",
    "role",
  ]) as NodeUpdateInput<User>;

  const updateLines = (
    attribute: "education" | "experience" | "description"
  ) => (input: { lines: CVLine[] } | { description: string }) =>
    updateUser({
      id,
      cv: {
        ...cv,
        [attribute]: "lines" in input ? input.lines : input.description,
      },
    });

  const addNewLine = (attribute: "education" | "experience") => (
    newLine: CVLine
  ) =>
    updateUser({
      id,
      cv: {
        ...cv,
        [attribute]: [...cv[attribute], newLine].sort(
          (a, b) => parseInt(a?.startYear) - parseInt(b?.startYear)
        ),
      },
    });

  return (
    <>
      <UserForm autoSave onSubmit={updateUser} defaultValues={userFormInput} />

      <ConfirmButton
        message="Tilbakestill brukerpassord?"
        onConfirm={() => resetPassword(id)}
        //variant="danger"
      >
        Tilbakestill brukerpassord?
      </ConfirmButton>

      <EditCVGroup
        lines={education || []}
        title="Education"
        onSubmit={updateLines("education")}
        addNewLine={addNewLine("education")}
      />
      <EditCVGroup
        lines={experience || []}
        title="Experience"
        onSubmit={updateLines("experience")}
        addNewLine={addNewLine("experience")}
      />

      <Form
        onSubmit={updateLines("description")}
        defaultValues={{ description }}
        autoSave
      >
        <RedactorField name="description" label="Beskrivelse" />
      </Form>
    </>
  );
};

const ApolloWrap: React.FC<WrapProps> = ({ session, id }) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <EditUser id={id} />
    </SessionProvider>
  </ApolloProvider>
);
export default ApolloWrap;

import React from "react";
import styled from "styled-components";
import { Collapsible, CollapsibleContext } from "~/layout/collapsible";
import Block from "../Block";

interface Props {
  id?: string;
  style?: React.CSSProperties;
  collapsible?: CollapsibleContext;
}

const StyledBlock = styled(Block)`
  padding: 0;
`;

const CollapsibleBlock: React.FC<Props> = ({
  children,
  collapsible,
  ...rest
}) => {
  return (
    <Collapsible collapsible={collapsible}>
      <StyledBlock {...rest}>{children}</StyledBlock>
    </Collapsible>
  );
};

export default CollapsibleBlock;

import { gql } from "@apollo/client";
import { Attachment, attachmentFragment } from "~/attachment";

export interface RequestLine {
  id: string;
  number: string;
  title: string;
  answer: string;
  answeredBy: string;
  reference: string;
  files: Attachment[] | null;
}

export const requestLineFragment = gql`
  fragment RequestLineFields on RequestLine {
    id
    number
    title
    answer
    answeredBy
    reference
    files {
      ...AttachmentFields
    }
  }
  ${attachmentFragment}
`;

// export const REQUEST_LINE_QUERY = gql`
//   query($id: ID!) {
//     requestLine(id: $id) {
//       id
//       ...requestLineFields
//     }
//   }
//   ${requestLineFragment}
// `;

import { gql, useQuery } from "@apollo/client";

import React from "react";
import { ShortcodeProps } from ".";
import { WordLoader } from "~/layout/Loading";

const REPORT_BUILDINGS_LIST_QUERY = gql`
  query EiendomMatrikkelList($slug: ID!) {
    report(slug: $slug) {
      municipality
      ... on Buildings {
        buildings {
          id
          bnr
          gnr
        }
      }
    }
  }
`;

const EiendomMatrikkelList: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery<{
    report: {
      municipality?: string;
      buildings: {
        id: string | number;
        bnr?: string;
        gnr?: string;
      }[];
    };
  }>(REPORT_BUILDINGS_LIST_QUERY, {
    variables: { slug },
  });

  if (loading) return <WordLoader />;
  if (error) return <p>Error </p>;

  const { municipality, buildings } = data?.report || {};

  return (
    <>
      {buildings &&
        buildings.map((building) => {
          const { id, gnr, bnr } = building;
          return (
            <span key={id}>
              {gnr && <span>Gnr. {gnr}, </span>}
              {bnr && <span>Bnr. {bnr}</span>}
              {(gnr || bnr) && municipality && (
                <span> i {municipality} Kommune</span>
              )}
              <br />
            </span>
          );
        })}
    </>
  );
};

export default EiendomMatrikkelList;

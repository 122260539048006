import { pick } from "lodash";
import React from "react";
import { attachmentInput } from "~/form/attachment";
import { nodeAttributeLabel } from "~/node";
import { BudgetLine } from "./constants";

export const costTypes = {
  maintenance: "Vedlikehold",
  restore: "Tilbakestilling",
  other: "Øvrige",
};

export const toFormData = (budgetLine: BudgetLine) => {
  const { files } = budgetLine;
  return {
    ...pick(budgetLine, [
      "id",
      "costLineTemplateId",
      "unit",
      "unitPrice",
      "amount",
      "costType",
      "description",
      "note",
    ]),
    files: files ? attachmentInput(files) : undefined,
  };
};

export const costTypeOptions = [
  {
    value: "maintenance",
    label: "Vedlikehold",
  },
  {
    value: "restore",
    label: "Tilbakestilling",
  },
  {
    value: "other",
    label: "Øvrige",
  },
];
export const costTypeLabel = nodeAttributeLabel(costTypeOptions);

export const unitOptions = [
  {
    value: "m2",
    label: (
      <>
        m<sup>2</sup>
      </>
    ),
  },
  { value: "lm", label: "lm" },
  { value: "stk", label: "stk" },
  { value: "rs", label: "rs" },
  { value: "t", label: "t" },
];
export const unitLabel = nodeAttributeLabel(unitOptions);

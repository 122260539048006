import { default as React } from "react";
import { ButtonProps } from "react-bootstrap";
import { ToggleButton } from "~/layout";
import { useModal } from "~/util";
import { ConfirmModal, ConfirmModalProps } from "./ConfirmButton";

interface Props
  extends Pick<ButtonProps, "variant">,
    Omit<ConfirmModalProps, "confirmMethods"> {
  value: boolean;
}

const ConfirmToggleButton: React.FC<Props> = ({
  value,
  variant,
  children,
  ...rest
}) => {
  const confirmMethods = useModal();
  const { show } = confirmMethods;

  return (
    <>
      <ToggleButton onClick={show} active={value} variant={variant}>
        {children}
      </ToggleButton>

      <ConfirmModal confirmMethods={confirmMethods} {...rest} />
    </>
  );
};

export default ConfirmToggleButton;

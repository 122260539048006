import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node/constants";
import { MaintenancePeriod } from "~/report";

export interface BuildingPartRatingRule extends ResourceInterface {
  id: string;
  tg: number;
  kg: number;
  rating: number;
  period:
    | MaintenancePeriod.NONE
    | MaintenancePeriod.ONE_TO_FIVE_YEARS
    | MaintenancePeriod.SIX_TO_TEN_YEARS
    | MaintenancePeriod.IMMEDIATE;
  description: string;
}

export type NewBuildingPartRatingRuleInput = Pick<
  BuildingPartRatingRule,
  "rating" | "description" | "period" | "tg" | "kg"
>;

export type UpdateBuildingPartRatingRuleInput = Pick<
  BuildingPartRatingRule,
  "id" | "rating" | "description" | "period" | "tg" | "kg"
>;

export enum Rating {
  A = 6,
  B = 5,
  C = 4,
  D = 3,
  E = 2,
  F = 1,
}

export enum TG {
  IU = -1,
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export enum KG {
  IA = -1,
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export const buildingPartRatingRuleFragment = gql`
  fragment HousingMaintenanceBuildingPartRatingRuleFields on HousingMaintenanceBuildingPartRatingRule {
    id
    tg
    kg
    rating
    period
    description
  }
`;

export const BUILDING_PART_RATING_RULES_QUERY = gql`
  query buildingPartRatingRules {
    housingMaintenanceBuildingPartRatingRules {
      id
      tg
      kg
      rating
      period
      description
    }
  }
`;

export const BUILDING_PART_RATING_RULES_CREATE_MUTATION = gql`
  mutation CreateHousingMaintenanceBuildingPartRatingRule(
    $input: CreateHousingMaintenanceBuildingPartRatingRuleInput!
  ) {
    createHousingMaintenanceBuildingPartRatingRule(input: $input) {
      errors
      housingMaintenanceBuildingPartRatingRule {
        ...HousingMaintenanceBuildingPartRatingRuleFields
      }
    }
  }
  ${buildingPartRatingRuleFragment}
`;

export const BUILDING_PART_RATING_RULES_DELETE_MUTATION = gql`
  mutation DeleteHousingMaintenanceBuildingPartRatingRule(
    $input: DeleteHousingMaintenanceBuildingPartRatingRuleInput!
  ) {
    deleteHousingMaintenanceBuildingPartRatingRule(input: $input) {
      errors
      housingMaintenanceBuildingPartRatingRule {
        ...HousingMaintenanceBuildingPartRatingRuleFields
      }
    }
  }
  ${buildingPartRatingRuleFragment}
`;

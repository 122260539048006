import { useMutation } from "@apollo/client";
import {
  CostBlockTemplate,
  COST_BLOCK_TEMPLATES_QUERY,
  COST_BLOCK_TEMPLATE_DELETE_MUTATION,
  COST_BLOCK_TEMPLATE_UPDATE_MUTATION,
  CreateCostBlockTemplateInput,
  CREATE_COST_BLOCK_TEMPLATE_MUTATION,
} from ".";

export const useCreateCostBlockTemplate = () => {
  const [createCostBlockTemplate] = useMutation<{
    createCostBlockTemplate: {
      costBlockTemplate: CostBlockTemplate;
      errors: string[];
    };
  }>(CREATE_COST_BLOCK_TEMPLATE_MUTATION);
  return (input: CreateCostBlockTemplateInput) => {
    return createCostBlockTemplate({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation failed");
        const newCostBlockTemplate =
          data?.createCostBlockTemplate.costBlockTemplate;

        const cacheData = cache.readQuery<{
          costBlockTemplates: CostBlockTemplate[];
        }>({
          query: COST_BLOCK_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { costBlockTemplates } = cacheData;

        cache.writeQuery({
          query: COST_BLOCK_TEMPLATES_QUERY,
          data: {
            costBlockTemplates: [...costBlockTemplates, newCostBlockTemplate],
          },
        });
      },
    });
  };
};

export const useUpdateCostBlockTemplate = () => {
  const [updateCostBlockTemplate] = useMutation(
    COST_BLOCK_TEMPLATE_UPDATE_MUTATION
  );
  return (input: CreateCostBlockTemplateInput) => {
    return updateCostBlockTemplate({
      variables: {
        input,
      },
    });
  };
};

export const useDeleteCostBlockTemplate = () => {
  const [deleteCostBlockTemplate] = useMutation(
    COST_BLOCK_TEMPLATE_DELETE_MUTATION
  );
  return (id: string) => {
    return deleteCostBlockTemplate({
      variables: {
        input: { id },
      },
      update: (cache, { data }) => {
        if (!data) throw new Error("Mutation failed");

        const cacheData = cache.readQuery<{
          costBlockTemplates: CostBlockTemplate[];
        }>({
          query: COST_BLOCK_TEMPLATES_QUERY,
        });
        if (!cacheData) throw new Error("Cache read failed");
        const { costBlockTemplates } = cacheData;

        cache.writeQuery({
          query: COST_BLOCK_TEMPLATES_QUERY,
          data: {
            costBlockTemplates: costBlockTemplates.filter(
              (template) => template.id !== id
            ),
          },
        });
      },
    });
  };
};

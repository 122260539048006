import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from "./Shortcode";

const NUMBER_REPORT_QUERY = gql`
  query ProsjektinformasjonProsjektnummer($slug: ID!) {
    report(slug: $slug) {
      id
      number
    }
  }
`;

const ProsjektinformasjonProsjektnummer: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(NUMBER_REPORT_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  const { number } = data?.report || {};

  return <>{loading ? <WordLoader /> : `${number}`}</>;
};

export default ProsjektinformasjonProsjektnummer;

import { gql } from "@apollo/client";
import { ResourceInterface } from "~/node";
import { DocumentationBlockTemplate } from "../block-template";

export type CreateDocumentationLineTemplateInput = Pick<
  DocumentationLineTemplate,
  "number" | "title" | "columns" | "documentationBlockTemplateId"
>;

export type UpdateDocumentationLinetemplateInput = Pick<
  DocumentationLineTemplate,
  "id" | "number" | "title" | "columns"
>;

export interface DocumentationLineTemplate extends ResourceInterface {
  documentationBlockTemplate: DocumentationBlockTemplate;

  columns: number;
  number: string;
  title: string;
  documentationBlockTemplateId: string;
}

export const documentationLineTemplateFragment = gql`
  fragment DocumentationLineTemplateFields on DocumentationLineTemplate {
    id
    title
    number
    columns
    documentationBlockTemplateId
  }
`;

export const DOCUMENTATION_LINE_TEMPLATES_QUERY = gql`
  query documentationLineTemplates($documentationBlockTemplateId: ID!) {
    documentationLineTemplates(
      documentationBlockTemplateId: $documentationBlockTemplateId
    ) {
      ...DocumentationLineTemplateFields
    }
  }
  ${documentationLineTemplateFragment}
`;

export const DOCUMENTATION_LINE_TEMPLATE_CREATE_MUTATION = gql`
  mutation CreateDocumentationLineTemplate(
    $input: CreateDocumentationLineTemplateInput!
  ) {
    createDocumentationLineTemplate(input: $input) {
      errors
      documentationLineTemplate {
        ...DocumentationLineTemplateFields
        documentationBlockTemplate {
          id
          documentationLineTemplates {
            id
          }
        }
      }
    }
  }
  ${documentationLineTemplateFragment}
`;

export const DOCUMENTATION_LINE_TEMPLATE_UPDATE_MUTATION = gql`
  mutation UpdateDocumentationLineTemplate(
    $input: UpdateDocumentationLineTemplateInput!
  ) {
    updateDocumentationLineTemplate(input: $input) {
      errors
      documentationLineTemplate {
        ...DocumentationLineTemplateFields
      }
    }
  }
  ${documentationLineTemplateFragment}
`;

export const DOCUMENTATION_LINE_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteDocumentationLineTemplate(
    $input: DeleteDocumentationLineTemplateInput!
  ) {
    deleteDocumentationLineTemplate(input: $input) {
      errors
      documentationLineTemplate {
        ...DocumentationLineTemplateFields
        documentationBlockTemplate {
          id
          documentationLineTemplates {
            id
          }
        }
      }
    }
  }
  ${documentationLineTemplateFragment}
`;

import React from "react";
import styled from "styled-components";
import Card from "./Card";

type CardProps = {
  report: {
    name: string;
    obligueImg: {
      record: {
        number: number | string;
      };
    };
  };
};
const InfoCardsWrapper = styled.div`
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  font-size: 1rem;
`;
const InfoCardsContainer = styled.div`
  padding: 105px 20px;
`;
const CardsTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 70px;
  text-align: center;
  font-size: 2.125rem;
  text-transform: uppercase;
  font-family: "Moon", sans-serif;
  font-weight: 300;
`;
const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1rem;
  margin-right: -1rem;
`;
const CardWrapper = styled.div`
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  padding-left: 1rem;
  padding-right: 1rem;
`;
const ReportInfoCard: React.FC<CardProps> = ({
  report: { name, obligueImg },
}) => {
  const { number } = obligueImg.record;
  // console.log(obligueImg);
  // console.log(number);
  return (
    <InfoCardsWrapper>
      <InfoCardsContainer>
        <CardsTitle>NØkkelINFORMASJON</CardsTitle>
        <CardsContainer>
          <CardWrapper>
            <Card name={name} number={number} />
          </CardWrapper>
        </CardsContainer>
      </InfoCardsContainer>
    </InfoCardsWrapper>
  );
};

export default ReportInfoCard;

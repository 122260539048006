import {
  ApolloCache,
  MutationUpdaterFn,
  Reference,
  useMutation,
} from "@apollo/client";
import { Membership } from "~/graphql-operations";
import {
  MEMBERSHIPS_QUERY,
  MEMBERSHIP_CREATE_MUTATION,
  MEMBERSHIP_DELETE_MUTATION,
  MEMBERSHIP_UPDATE_MUTATION,
} from "./constants";

type MembershipsQueryResult = { memberships: Membership[] };

export const createMembership = () => {
  const [mutation] = useMutation(MEMBERSHIP_CREATE_MUTATION);

  return (input: Partial<Membership>, update?: MutationUpdaterFn) => {
    return mutation({
      variables: {
        input,
      },
      update: update || membershipCreateCacheUpdate({ userId: input.userId }),
    });
  };
};

export const membershipCreateCacheUpdate = (
  variables: Record<string, unknown>,
  query = MEMBERSHIPS_QUERY
): MutationUpdaterFn => (
  cache: ApolloCache<MembershipsQueryResult>,
  { data }
) => {
  const membership = data?.membershipCreate?.membership;
  if (!membership) return;

  const { memberships = [] } =
    cache.readQuery<MembershipsQueryResult>({
      query,
      variables,
    }) || {};

  cache.writeQuery({
    query,
    variables,
    data: {
      memberships: [...memberships, membership],
    },
  });
};

export const updateMembership = () => {
  const [mutation] = useMutation(MEMBERSHIP_UPDATE_MUTATION);

  return (input: Partial<Membership> & Pick<Membership, "id">) =>
    mutation({
      variables: {
        input,
      },
    });
};

export const deleteMembership = () => {
  const [mutation] = useMutation(MEMBERSHIP_DELETE_MUTATION);

  return (id: Membership["id"], update?: MutationUpdaterFn) =>
    mutation({
      variables: {
        input: { id },
      },
      update:
        update ||
        ((cache) => {
          cache.modify({
            // id: cache.identify(housingMaintenanceSection),
            fields: {
              memberships: (refs, { readField }) =>
                refs.filter((ref: Reference) => id !== readField("id", ref)),
            },
          });
        }),
    });
};

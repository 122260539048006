import { gql } from "@apollo/client";

export const userFragment = gql`
  fragment UserFields on User {
    id
    email
    name
    companyIds
    companyName
    role
  }
`;

export const userFullFragment = gql`
  fragment UserFullFields on User {
    id

    companyIds
    email
    firstName
    lastName
    partner
    phone
    role
    unlockReport
    workingArea

    cv {
      education {
        text
        startYear
        endYear
        hidden
        type
      }
      experience {
        text
        startYear
        endYear
        hidden
        type
      }
      description
    }
  }
`;

import React, { forwardRef, ReactNode } from "react";
import { Form, FormCheck, FormCheckProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { FieldError, inputFieldRef } from ".";
import { Option } from "./SelectField";

export interface Props extends Omit<FormCheckProps, "type" | "disabled"> {
  name: string;
  options: Option[];
  label?: string | ReactNode;
  disabled?: Option["value"][];
  validation?: Record<string, unknown>;
  style?: React.CSSProperties;
}

const OptionsWrapper = styled.div`
  display: flex;

  .form-check {
    margin-right: 0.5rem;
    margin-bottom: 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const RadioField: React.ForwardRefRenderFunction<FormCheck, Props> = (
  { name, label = name, options, validation, disabled = [], ...rest },
  ref
) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const registerOptions = validation || { required: false };
  // if (type === "number") options["valueAsNumber"] = true;
  // else if (type === "date") options["valueAsDate"] = true;

  // TODO: ref maps checkbox fields!
  const { ref: hookFormRef, ...formControlProps } = register(
    name,
    registerOptions
  );

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <OptionsWrapper>
        {options.map(({ value, label }) => (
          <Form.Check
            key={value || "null"}
            type="radio"
            label={label}
            value={value}
            disabled={disabled.includes(value)}
            // isInvalid={errors[name]}
            {...formControlProps}
            ref={inputFieldRef(hookFormRef, ref)}
            {...rest}
          />
        ))}
      </OptionsWrapper>

      {errors[name] && <FieldError error={errors[name]} />}
    </Form.Group>
  );
};

const ForwardedRadioField = forwardRef<FormCheck, Props>(RadioField);

export default ForwardedRadioField;

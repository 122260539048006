import { useMutation } from "@apollo/client";
import {
  INVOICE_CONTRACTOR_DELETE_MUTATION,
  INVOICE_CONTRACTOR_UPDATE_MUTATION,
} from ".";
import {
  InvoiceContractor,
  INVOICE_CONTRACTOR_CREATE_MUTATION,
} from "./constants";

export type NewContractorInput = Pick<
  InvoiceContractor,
  "reportId" | "name" | "budget"
>;

export const useCreateContractor = () => {
  const [createMutation] = useMutation<{
    errors?: string[];
    invoiceContractorCreate: { invoiceContractor: InvoiceContractor };
  }>(INVOICE_CONTRACTOR_CREATE_MUTATION);

  return async (input: NewContractorInput) => {
    return createMutation({ variables: { input } });
  };
};

export type UpdateContractorInput = Pick<InvoiceContractor, "id"> &
  Partial<Omit<InvoiceContractor, "id">>;

export const useUpdateContractor = () => {
  const [updateMutation] = useMutation(INVOICE_CONTRACTOR_UPDATE_MUTATION);
  return (input: Partial<UpdateContractorInput>) => {
    return updateMutation({ variables: { input } });
  };
};

export const useDeleteContractor = () => {
  const [updateMutation] = useMutation(INVOICE_CONTRACTOR_DELETE_MUTATION);
  return (id: InvoiceContractor["id"]) => {
    return updateMutation({ variables: { input: { id } } });
  };
};

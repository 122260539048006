import React from "react";
import { Permission } from "~/Membership";

interface MemberPermissionsProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  permissions?: Permission[];
  disabled?: Permission[];
  onChange: (permissions: Permission[]) => void;
}

const MemberPermissions: React.FC<MemberPermissionsProps> = ({
  permissions = [],
  disabled = [],
  onChange,
  ...rest
}) => {
  return (
    <div className="form-check pl-0" {...rest}>
      <label className="pr-2">
        <input
          type="checkbox"
          value="view"
          checked={permissions.includes("view")}
          disabled={disabled.includes("view")}
          onChange={(e) =>
            e.target.checked
              ? onChange([...permissions, "view"])
              : onChange(permissions.filter((p) => p !== "view"))
          }
        />{" "}
        Se
      </label>
      <label>
        <input
          type="checkbox"
          value="edit"
          checked={permissions.includes("edit")}
          disabled={disabled.includes("edit")}
          onChange={(e) =>
            e.target.checked
              ? onChange([...permissions, "edit"])
              : onChange(permissions.filter((p) => p !== "edit"))
          }
        />{" "}
        Rediger
      </label>
    </div>
  );
};

export default MemberPermissions;

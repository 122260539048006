import React from "react";
import { Form, RedactorField } from "~/form";
import {
  ConstructionLoan,
  ConstructionLoanUpdateInput,
  useUpdateConstructionLoanExtraMutation,
} from "~/graphql-operations";

interface Props {
  report: Pick<ConstructionLoan, "id" | "documentsComment">;
}

const EditDocumentsComment: React.FC<Props> = ({
  report: { id, documentsComment },
}) => {
  const [update] = useUpdateConstructionLoanExtraMutation();
  const onSubmit = (input: ConstructionLoanUpdateInput) =>
    update({ variables: { input } });

  const defaultValues = { id, documentsComment };

  return (
    <Form defaultValues={defaultValues} onSubmit={onSubmit} autoSave horizontal>
      <RedactorField name="documentsComment" label="Comment" />
    </Form>
  );
};

export default EditDocumentsComment;

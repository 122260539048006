import { useMutation } from "@apollo/client";
import { pick } from "lodash";
import React, { useState } from "react";
import { ButtonGroup, Col, FormGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Fieldset, Form, NumberField } from "~/form";
import { IconButton, ToggleButton } from "~/layout/Button";
import { Currency } from "~/util";
import { BuildingType, BUILDING_TYPE_UPDATE_MUTATION } from "./constants";

interface Props {
  buildingType: BuildingType;
  costIndex: number;
}

const toFormData = (buildingType: Partial<BuildingType>) =>
  pick(buildingType, ["cost"]);

const EditBuildingType: React.FC<Props> = ({ buildingType, costIndex }) => {
  const [editable, setEditable] = useState(false);
  const [updateBuilding] = useMutation(BUILDING_TYPE_UPDATE_MUTATION);

  const formMethods = useForm({
    defaultValues: toFormData(buildingType),
    mode: "all",
  });
  const { handleSubmit, reset, watch } = formMethods;
  const { cost } = watch();

  const actualCost = (cost || 0) * (1 + costIndex / 100);

  const onSubmit = (values: Pick<BuildingType, "cost">) => {
    return updateBuilding({
      variables: {
        input: {
          ...values,
          id: buildingType.id,
        },
      },
    });
  };

  const visibilityToggle = ({ id, hidden }: BuildingType) => {
    return updateBuilding({
      variables: {
        input: {
          id,
          hidden: !hidden,
        },
      },
    });
  };

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} horizontal>
      <Fieldset>
        <h3 className="card-title">
          {buildingType.name}
          {buildingType.default && (
            <small
              className="badge"
              style={{ marginLeft: "0.5rem", fontSize: "0.75rem" }}
            >
              default
            </small>
          )}
        </h3>
        <Row className="align-items-end">
          <Col>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div style={{ position: "relative" }}>
                <NumberField
                  name="cost"
                  label="Basekostnad per kvm"
                  disabled={!editable}
                  step="0.01"
                  style={{ width: "100px" }}
                />
                {!editable && (
                  <IconButton
                    icon="edit"
                    size="sm"
                    onClick={() => setEditable(true)}
                    style={{
                      position: "absolute",
                      top: "34px",
                      right: "0.5rem",
                    }}
                  />
                )}
              </div>
              {editable && (
                <FormGroup>
                  <ButtonGroup style={{ marginBottom: "7px" }}>
                    <IconButton
                      icon="check"
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        handleSubmit(onSubmit)();
                        setEditable(false);
                      }}
                    />
                    <IconButton
                      icon="deny"
                      variant="dark"
                      size="sm"
                      onClick={() => {
                        reset();
                        setEditable(false);
                      }}
                    />
                  </ButtonGroup>
                </FormGroup>
              )}
            </div>
          </Col>
          <Col lg="auto">
            <FormGroup>
              <ToggleButton
                active={!buildingType.hidden}
                onClick={() => visibilityToggle(buildingType)}
              />
            </FormGroup>
          </Col>
        </Row>
        <label>
          AKTUELT KOSTNAD: <Currency>{actualCost}</Currency>
        </label>
      </Fieldset>
    </Form>
  );
};

export default EditBuildingType;

import { useMutation } from "@apollo/client";
import {
  HousingMaintenanceSection,
  HOUSING_MAINTENANCE_SECTION_UPDATE_MUTATION,
} from "./constants";

export const updateMutation = (id: string) => {
  const [update] = useMutation(HOUSING_MAINTENANCE_SECTION_UPDATE_MUTATION);

  return (input: Partial<HousingMaintenanceSection>) => {
    return update({
      variables: {
        input: {
          ...input,
          id,
        },
      },
    });
  };
};

import React from "react";
import ContentLoader from "react-content-loader";

type Props = {
  width?: number | string;
  height?: number | string;
};
const H1Loader: React.FC<Props> = ({ width = 500, height = 20 }) => {
  return (
    <ContentLoader
      speed={2}
      width={500}
      height={160}
      backgroundColor="#bebebe"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="70" rx="3" ry="3" width={width} height={height} />
    </ContentLoader>
  );
};

export default H1Loader;

import React from "react";
import { Col, Row } from "react-bootstrap";
import Icon from "~/layout/icon";
import { Currency } from "~/util";
import { formatCurrency } from "~/util/currency";
import { ChartProps } from "./Chart";
import { COLORS } from "./constants";
import { Label } from "./styled";

interface Props
  extends Pick<
    ChartProps,
    "data" | "hideLabel" | "hideValue" | "showZero" | "small"
  > {
  title?: string;
}

const Legend: React.FC<Props> = ({
  data,
  hideValue,
  hideLabel,
  showZero,
  small,
}) => {
  return (
    <Row className="justify-content-center">
      {data.map(({ value, label, icon, color }, index) => {
        if (!value && !showZero) return null;
        return (
          <Col
            key={`${label}-${index}`}
            className={small ? "col-auto px-2" : "col-auto px-2"}
            title={small && `${label}: ${formatCurrency(value || 0)}`}
          >
            {icon && <Icon icon={icon} size="large" />}
            <Label bulletColor={color || COLORS[index]}>
              {!hideLabel && label}
            </Label>
            {!hideValue && <Currency>{value}</Currency>}
          </Col>
        );
      })}
    </Row>
  );
};

export default Legend;

import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from "./Shortcode";

const MUNICIPALITY_REPORT_QUERY = gql`
  query ShortcodeMatrikkelKomunne($slug: ID!) {
    report(slug: $slug) {
      id
      municipality
    }
  }
`;

const MatrikkelKomune: React.FC<ShortcodeProps> = ({ report: { slug } }) => {
  const { loading, error, data } = useQuery(MUNICIPALITY_REPORT_QUERY, {
    variables: { slug },
  });
  if (error) return <p>Error</p>;

  const { municipality } = data?.report || {};

  return <>{loading ? <WordLoader /> : `${municipality}`}</>;
};

export default MatrikkelKomune;

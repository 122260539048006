import React from "react";
import styled from "styled-components";
import { ConstructionLoan } from "~/graphql-operations";
import { SortableCategorizations } from "~/invoice/entry/categorization";
import { NewCategory } from "~/invoice/entry/category";
import { NewGroup } from "~/invoice/entry/category/group";
import { ContractorForm, InvoiceContractor, useUpdateContractor } from ".";

interface Props {
  contractor: Pick<InvoiceContractor, "id" | "name" | "budget">;
  contractorBudgetPlanLabel: ConstructionLoan["contractorBudgetPlanLabel"];
  vatIncluded: ConstructionLoan["vatIncluded"];
}

const StyledContractorForm = styled(ContractorForm)`
  width: calc(100% + 2rem);
  margin: -1rem -1rem 1rem -1rem;
  padding: 0.5rem 1rem;
  background-color: var(--mystic);
`;

const EditContractor: React.FC<Props> = ({
  contractor,
  contractor: { id, name },
  contractorBudgetPlanLabel,
  vatIncluded,
}) => {
  const updateContractor = useUpdateContractor();

  return (
    <>
      <StyledContractorForm
        id={`edit-contractor-${id}`}
        defaultValues={{ id, name }}
        onSubmit={updateContractor}
        autoSave
      />
      <SortableCategorizations
        contractor={contractor}
        contractorBudgetPlanLabel={contractorBudgetPlanLabel}
      />

      <hr />
      <NewGroup contractor={contractor} />
      <NewCategory contractor={contractor} vatIncluded={vatIncluded} />
    </>
  );
};

export default EditContractor;

import React from "react";
import styled, { css } from "styled-components";

type SliderProps = {
  score: number;
  grade: string;
  title?: string;
  small?: boolean;
  reversed?: boolean;
};

const Wrap = styled.div`
  padding: 36px 40px 60px 40px;
  text-align: center;
`;

const Title = styled.h4`
  font-size: 0.875rem;
  margin-bottom: 2.5rem;
  text-align: center;
  font-weight: 700;
`;

const SliderWrap = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;

  &:before {
    content: "F-";
    margin-right: 12px;
    display: inline-block;
    font-size: 1rem;
    color: #374246;
    font-family: "Moon", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }

  &:after {
    content: "A+";
    margin-left: 12px;
    display: inline-block;
    font-size: 1rem;
    color: #374246;
    font-family: "Moon", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const Line = styled.div<{
  small?: boolean;
}>`
  display: flex;
  width: calc(100% - 45px);
  height: 12px;
  border-radius: 6px;
  position: relative;
  background-image: linear-gradient(
    to right,
    #ff838c,
    #ffcc4f 35%,
    #ffcc4f 65%,
    #80eca4
  );

  ${({ small }) =>
    small &&
    css`
      height: 5px;
      border-radius: 3px;
    `}
`;

const ScoreWrapper = styled.div`
  position: relative;
  width: calc(100% - 44px);
  height: 100%;
  margin: auto;
`;

const Score = styled.div<{
  reversed?: boolean;
}>`
  position: absolute;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 26px 0 rgb(0 0 0 / 15%);
  font-size: 22px;
  top: 50%;
  transform: translate(50%, -50%);
  font-family: "Moon", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 100;

  ${({ reversed }) =>
    reversed &&
    css`
      transform: translate(-50%, -50%);
    `}
`;

const Slider: React.FC<SliderProps> = ({
  grade,
  score,
  title,
  small,
  reversed,
}) => {
  const percentage = (score / 17) * 100;
  return (
    <Wrap>
      <Title>{title}</Title>
      <SliderWrap>
        <Line small={small}>
          <ScoreWrapper>
            <Score
              reversed={reversed}
              style={
                reversed
                  ? { left: `${percentage}%` }
                  : { right: `${percentage}%` }
              }
            >
              {grade}
            </Score>
          </ScoreWrapper>
        </Line>
      </SliderWrap>
    </Wrap>
  );
};

export default Slider;

import React from "react";
import { ShortcodeProps } from "./Shortcode";

/* 
   NOTE:
  - Query field needed "cadastral_image" 


  - Add query in codegen:
  
  query MatrikkelMatrikkelkart($slug: ID!) {
  report(slug: $slug) {
    id
    cadastralImg
  }
}
*/

const MatrikkelMatrikkelkart: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  return (
    <>
      <h3>React Component : MatrikkelMatrikkelkart</h3>
    </>
  );
};

export default MatrikkelMatrikkelkart;

import React, { useEffect, useRef, useState } from "react";
import { ButtonProps } from "react-bootstrap";
import styled from "styled-components";
import IconButton from "~/layout/Button/IconButton";
import { Icons, StyledIcon } from "~/layout/icon";

export interface SaveButtonProps extends Omit<ButtonProps, "onClick"> {
  loading?: boolean;
  icon?: Icons;
  iconOnly?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => Promise<unknown> | void;
}

const StyledIconButton = styled(IconButton)<
  Omit<SaveButtonProps, "iconOnly"> & {
    $iconOnly?: boolean;
  }
>`
  display: inline-flex;
  opacity: ${({ disabled }) => (disabled ? "0.67" : "1")};

  ${StyledIcon} {
    margin-right: ${({ $iconOnly }) => ($iconOnly ? "0" : "0.75rem")};
  }
`;

const iconColor = (variant: ButtonProps["variant"]) => {
  switch (variant) {
    // case "primary":
    // case "success":
    // case "danger":
    //   return "initial";
    case "secondary":
      return "white";
    default:
      return undefined;
  }
};

const SaveButton: React.FC<SaveButtonProps> = ({
  children,
  disabled = false,
  loading = false,
  icon = "check",
  iconOnly = false,
  onClick,
  variant = "primary",
  ...rest
}) => {
  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  const [submitting, setSubmitting] = useState(false);

  const handleClick = onClick
    ? async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setSubmitting(true);
        await onClick(event);
        if (isMountedRef.current) setSubmitting(false);
      }
    : undefined;

  return (
    <StyledIconButton
      {...rest}
      onClick={handleClick}
      disabled={disabled || loading || submitting}
      $iconOnly={iconOnly}
      icon={loading || submitting ? "spinner" : icon}
      spin={loading || submitting}
      variant={variant}
      color={iconColor(variant)}
    >
      {!iconOnly && (children !== undefined ? children : "Save")}
    </StyledIconButton>
  );
};

export default SaveButton;

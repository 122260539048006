import React from "react";
import styled from "styled-components";
import { CostSection } from "~/graphql-operations";
import { costTypeLabel } from "../cost-section";

const CostSectionList = styled.ul`
  padding: 0;
`;

const CostSectionListItem = styled.li`
  margin-bottom: 1rem;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  padding: 1rem 3.5rem 1rem;
  background-color: #f2f2f2;
  list-style: none;

  .cost-section-list-title {
    font-weight: 300;
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0;
  }
`;

interface Props {
  costTypes: CostSection["costType"][];
}

const VirtualTextSections: React.FC<Props> = ({ costTypes }) => {
  if (!costTypes.length) return null;

  return (
    <CostSectionList>
      {costTypes.map((type) => (
        <CostSectionListItem key={type}>
          <h3 className="cost-section-list-title">{costTypeLabel(type)}</h3>
          <span>Sammendrag</span>
        </CostSectionListItem>
      ))}
    </CostSectionList>
  );
};

export default VirtualTextSections;

import { Option } from "~/form/SelectField";
import { client } from "~/setup";
import { AFFILIATES_QUERY, toOptions, User } from "~/user";

type UserLoader = (query: string) => Promise<User[]>;

export const affiliateLoader: UserLoader = async (query: string) => {
  const { data: { affiliates } = {} } = await client.query<{
    affiliates: User[];
  }>({
    query: AFFILIATES_QUERY,
    variables: { query },
  });
  if (!affiliates) throw new Error("Could not load users");
  return affiliates;
};

export type filteredLoadOptionsType = (
  loader: UserLoader,
  options: {
    exclude?: string[];
  }
) => filteredLoadOptionsRetFunc;

export type filteredLoadOptionsRetFunc = (query: string) => Promise<Option[]>;

export const filteredLoadOptions: filteredLoadOptionsType = (
  loader,
  { exclude = [] }
) => async (term) => {
  if (term.length < 2) return [];
  const users = await loader(term);
  return toOptions(users.filter((user) => !exclude.includes(user.id)));
};

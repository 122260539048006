import React from "react";
import { Col } from "react-bootstrap";
import { AddFormButton, Fieldset, Form, Row, TextField } from "~/form";
import { useCreateSectionMutation } from "~/graphql-operations";

interface Props {
  report: { id: string };
  // type: string;
}

const NewTextSection = ({ report: { id: reportId } }: Props) => {
  const [create] = useCreateSectionMutation();
  const onSubmit = ({ title }: { title: string }) =>
    create({
      variables: {
        input: {
          reportId,
          title,
          type: "text",
        },
      },
    });

  return (
    <Form id="new-text-section" onSubmit={onSubmit} horizontal>
      <Fieldset title="Tekstinhold">
        <Row>
          <Col lg="4">
            <TextField name="title" label="Tittel" />
          </Col>
          <Col lg="auto">
            <AddFormButton />
          </Col>
        </Row>
      </Fieldset>
    </Form>
  );
};

export default NewTextSection;

import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import {
  CheckboxField,
  Fieldset,
  Form,
  HiddenField,
  Row,
  TextField,
} from "~/form";
import { DeleteButton } from "~/layout";
import { useUpdateEffect } from "~/util";
import { CVLine } from "..";

export type LinesInput = {
  lines: CVLine[];
};

export interface EditCVLinesProps extends LinesInput {
  onSubmit: (input: LinesInput) => void;
}

const EditCVLines: React.FC<EditCVLinesProps> = ({
  lines,
  onSubmit,
  ...rest
}) => {
  const formMethods = useForm({
    defaultValues: {
      lines,
    },
    mode: "all",
  });
  const { control, reset } = formMethods;

  const { fields, remove } = useFieldArray({
    control,
    name: "lines",
    keyName: "lineKey",
  });

  useUpdateEffect(() => {
    reset({ lines });
  }, [lines, reset]);

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit} autoSave {...rest}>
      <Fieldset title="Utdanning">
        {fields.map(({ lineKey }, index) => {
          return (
            <Row key={lineKey}>
              <Col>
                <TextField
                  name={`lines.${index}.text`}
                  label="Innold"
                  readOnly={lines[index].hidden}
                  required
                />
              </Col>
              <Col sm="2">
                <TextField
                  name={`lines.${index}.startYear`}
                  label="Startår"
                  required
                  readOnly={lines[index].hidden}
                />
              </Col>
              <Col sm="2">
                <TextField
                  name={`lines.${index}.endYear`}
                  label="Sluttår"
                  required
                  readOnly={lines[index].hidden}
                />
              </Col>
              <HiddenField name={`lines.${index}.type`} />
              <Col sm="auto">
                <CheckboxField name={`lines.${index}.hidden`} label="hidden" />
              </Col>
              <Col sm="auto">
                <FormGroup>
                  <DeleteButton iconOnly onClick={async () => remove(index)} />
                </FormGroup>
              </Col>
            </Row>
          );
        })}
      </Fieldset>
    </Form>
  );
};

export default EditCVLines;

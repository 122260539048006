import React from "react";
import {
  ConstructionLoanPeriod,
  useUnpublishConstructionLoanPeriodMutation,
} from "~/graphql-operations";
import { SaveButton } from "~/layout";

interface Props {
  period: Pick<ConstructionLoanPeriod, "id" | "unpublishable">;
}

const unpublishTxt = "Lås og unpubliser";

const Publish: React.FC<Props> = ({ period: { id, unpublishable } }) => {
  const [unpublishMutation] = useUnpublishConstructionLoanPeriodMutation();
  const onUnpublish = async () => {
    if (window.confirm("Er du sikker?"))
      return unpublishMutation({ variables: { input: { id } } });
    else return Promise.resolve();
  };

  return (
    <SaveButton
      onClick={onUnpublish}
      disabled={unpublishable}
      variant="info"
      icon="deny"
    >
      {unpublishTxt}
    </SaveButton>
  );
};

export default Publish;

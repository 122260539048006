import { ApolloProvider, useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import styled, { css } from "styled-components";
import { BuildingPart } from "~/Building";
import { Attachment, filterImages } from "~/attachment";
import { HTMLContent, ImageLightbox } from "~/layout";
import { NodeListEmpty } from "~/node";
import Period, { periodSeverity } from "~/node/label/Period";
import { Report, ReportProvider } from "~/report";
import { Session, SessionProvider } from "~/session";
import { client } from "~/setup";
import { Currency } from "~/util";
import { CostCol, FinishedToggle } from ".";
import { HousingMaintenanceSection } from "..";
import { EditBudgetPlan } from "../budget-plan";
import { HOUSING_MAINTENANCE_SECTION_BUDGET_LINES_QUERY } from "../constants";

interface Props {
  report: Report;
  housingMaintenanceSection: HousingMaintenanceSection;
}

type QueryResult = {
  housingMaintenanceSection: HousingMaintenanceSection;
};

const customStyles = {
  cells: {
    style: {
      paddingBottom: "0.25rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      paddingTop: "0.25rem",
    },
  },
  headCells: {
    style: {
      color: "var(--white)",
      fontSize: "0.875rem",
      paddingBottom: "1rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      paddingTop: "1rem",
    },
    activeSortStyle: {
      color: "var(--yellow)",
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: "var(--yellow)",
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: "var(--white)",
      },
      "&:hover": {
        color: "var(--yellow)",
      },
    },
  },
  headRow: {
    style: {
      backgroundColor: "var(--dark-gray)",
    },
  },
  rows: {
    style: {
      fontSize: "0.875rem",
    },
  },
  table: {
    style: {
      border: "1px solid rgba(0,0,0,.12)",
    },
  },
};

const Description: React.FC<{ description: string }> = ({ description }) => (
  <HTMLContent>{description}</HTMLContent>
);

const StyledPeriod = styled(Period).attrs(({ children }) => ({
  className: periodSeverity(children),
}))`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BudgetLinePeriod: React.FC<{ buildingPart: BuildingPart }> = ({
  buildingPart: { period },
}) => <StyledPeriod colored>{period}</StyledPeriod>;

const Images: React.FC<{ files: Attachment[] }> = ({ files = [] }) => (
  <ImageLightbox images={filterImages(files)} group />
);

const footerBaseStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5rem;
  font-weight: bold;
`;

const FooterTotal = styled.div`
  ${footerBaseStyles}
  background-color: var(--yellow);
  border-bottom: 6px double #333;
`;

const FooterLoan = styled.div`
  ${footerBaseStyles}
  background-color: #f9db98;
`;

const FooterCol = styled.div`
  width: 120px;
  margin-right: 120px;
  padding: 0.5rem;
  text-align: center;
  background-color: rgba(244, 244, 244, 0.4);
`;

const BudgetLinesPage: React.FC<Omit<Props, "report">> = ({
  housingMaintenanceSection: { id: sectionId },
}) => {
  // const update = updateMutation(id);
  const { loading, error, data } = useQuery<QueryResult>(
    HOUSING_MAINTENANCE_SECTION_BUDGET_LINES_QUERY,
    {
      variables: { id: sectionId },
    }
  );

  const {
    housingMaintenanceSection,
    housingMaintenanceSection: {
      displayableBudgetLines = [],
      extended = false,
    } = {},
  } = data || {};

  const images = displayableBudgetLines.find(
    ({ files }) => files && filterImages(files).length
  );

  const columns = useMemo(() => {
    const cols = [
      {
        name: "Ref",
        sortable: true,
        selector: "costLineTemplateRef",
        center: true,
        width: "60px",
      },
      {
        name: "Bygningsdel",
        sortable: true,
        // selector: ({ buildingPart: { name } }) => name,
        selector: "buildingPart.name",
      },
      {
        name: "Beskrivelse tilstand / tiltak",
        sortable: false,
        selector: "description",
        cell: Description,
      },
    ];

    if (images) cols.push({ name: "Bilder", sortable: false, cell: Images });

    return [
      ...cols,
      {
        name: "Prioritet/ tidsforløp",
        sortable: true,
        selector: "buildingPart.period",
        // selector: ({ buildingPart: { period } }) => period,
        cell: BudgetLinePeriod,
        center: true,
        width: "90px",
      },
      {
        name: "Enhet",
        sortable: true,
        selector: "unit",
        center: true,
        width: "90px",
      },
      {
        name: "Mengde",
        sortable: true,
        selector: "amount",
        center: true,
        width: "90px",
      },
      {
        name: "Enhetspris",
        sortable: true,
        selector: "unitPrice",
        center: true,
        width: "90px",
      },
      {
        name: "Kostnad",
        sortable: true,
        selector: "cost",
        cell: CostCol,
        center: true,
        width: "120px",
      },
      {
        name: "Fullført",
        sortable: true,
        selector: "finishedAt",
        cell: FinishedToggle,
        center: true,
        width: "120px",
      },
    ];
  }, []);

  if (!extended) return <p>NOT EXTENDED</p>;

  if (loading) return <p>Loading...</p>;
  if (error || !housingMaintenanceSection) return <p>Error :(</p>;

  const cost = displayableBudgetLines.reduce(
    (total, line) => (total += line.cost || 0),
    0
  );

  const remainingCost =
    cost -
    displayableBudgetLines.reduce(
      (total, line) => (line.finishedAt ? (total += line.cost || 0) : total),
      0
    );

  return (
    <div className="section">
      <div className="container card flat" style={{ padding: "30px" }}>
        <EditBudgetPlan
          housingMaintenanceSection={housingMaintenanceSection}
          cost={cost}
        />
        <DataTable
          noHeader
          defaultSortField="buildingPart.period"
          columns={columns}
          data={displayableBudgetLines}
          noDataComponent={
            <NodeListEmpty style={{ padding: "1rem 0 0 0" }}>
              Ingen vedlikeholdsbehov
            </NodeListEmpty>
          }
          customStyles={customStyles}
        />
        <FooterTotal>
          Estimert sum
          <FooterCol>
            <Currency>{cost}</Currency>
          </FooterCol>
        </FooterTotal>
        <FooterLoan>
          Gjenstående
          <FooterCol>
            <Currency>{remainingCost}</Currency>
          </FooterCol>
        </FooterLoan>
      </div>
    </div>
  );
};

interface WrapProps extends Props {
  session: Session;
}

const ApolloWrap: React.FC<WrapProps> = ({ session, report, ...rest }) => (
  <ApolloProvider client={client}>
    <SessionProvider session={session}>
      <ReportProvider report={{ ...report, id: report.id.toString() }}>
        <BudgetLinesPage {...rest} />
      </ReportProvider>
    </SessionProvider>
  </ApolloProvider>
);

export default ApolloWrap;

import {
  costBlockSummaryFragment,
  costLineFragment,
} from "~/sections/cost-section/fragments";

import { Building } from "~/Building";
import { ImageAttachment } from "~/attachment";
import { ResourceInterface } from "~/node/constants";
import { WORK_STATUS } from "~/util/WorkStatus";
import { gql } from "@apollo/client";
import { statesFragment } from "../state";

export interface CostLine extends ResourceInterface {
  costBlockId: string;
  buildingId: string;
  building: Building;
  ref: string;
  title: string;
  period:
    | "missing_regulation"
    | "immediate"
    | "1_5_years"
    | "6_10_years"
    | "11_15_years"
    | "16_20_years"
    | "21_25_years"
    | "26_30_years"
    | "reclamation"
    | "text";
  priority: string;
  costCoverage: CostCoverage;
  tg: number;
  kg: number;
  cost: number;
  description: string;
  note: string;
  status: WORK_STATUS;
  image: ImageAttachment;
  showImage: boolean;
}

export enum CostCoverage {
  TENANT = "tenant",
  LANDLORD = "landlord",
  OTHER = "other",
  BOTH = "both",
}

export const costCoverageOptions = [
  { value: CostCoverage.TENANT, label: "Leier" },
  { value: CostCoverage.LANDLORD, label: "Gårdeier" },
  { value: CostCoverage.OTHER, label: "Annet" },
  { value: CostCoverage.BOTH, label: "Leier/Gårdeier" },
];

export const COST_LINE_QUERY = gql`
  query CostLine($id: ID!) {
    costLine(id: $id) {
      id
      ...CostLineFields
    }
  }
  ${costLineFragment}
`;

export const COST_LINE_CREATE_MUTATION = gql`
  mutation CostLineCreate($input: CostLineCreateInput!) {
    costLineCreate(input: $input) {
      id
      ...CostLineFields
    }
  }
  ${costLineFragment}
`;

export const COST_LINE_UPDATE_MUTATION = gql`
  mutation CostLineUpdate($input: CostLineUpdateInput!) {
    costLineUpdate(input: $input) {
      costLine {
        id
        ...CostLineFields
        costBlock {
          id
          ...CostBlockSummary
        }
        costSection {
          id
          ...StateFields
        }
      }
      errors
    }
  }
  ${costLineFragment}
  ${costBlockSummaryFragment}
  ${statesFragment}
`;

export const COST_LINE_DELETE_MUTATION = gql`
  mutation CostLineDelete($input: CostLineDeleteInput!) {
    costLineDelete(input: $input) {
      id
      costBlock {
        id
        ...CostBlockSummary
      }
    }
  }
  ${costBlockSummaryFragment}
`;

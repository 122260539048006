import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import { UseFieldArrayReturn } from "react-hook-form";
import { NumberField, TextField } from "~/form";
import { DeleteButton } from "~/layout";
import Icon from "~/layout/icon";

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "0",
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  // background: isDraggingOver ? "lightblue" : "transparent",
  // padding: grid,
  width: "auto",
  margin: "0",
});

const makeOnDragEndFunction = (move) => (result) => {
  if (!result.destination) return; // dropped outside the list
  move(result.source.index, result.destination.index);
};

const BuildingFormPartsArray: React.FC<{
  fieldArray: UseFieldArrayReturn;
}> = ({ fieldArray }) => {
  const { fields, remove, move } = fieldArray;

  return (
    <>
      <DragDropContext onDragEnd={makeOnDragEndFunction(move)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {fields.map((item, index) => (
                <Draggable
                  key={item._id}
                  draggableId={`${item._id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Row className="align-items-center" key={index}>
                        <Col
                          lg="auto"
                          style={{ paddingTop: "0.5rem", paddingRight: 0 }}
                        >
                          <Icon icon="move" />
                        </Col>
                        <Col lg="2">
                          <TextField
                            name={`parts[${index}].floor`}
                            label="Etasje"
                            defaultValue={item.floor}
                          />
                        </Col>
                        <Col lg="4">
                          <TextField
                            name={`parts[${index}].usage`}
                            label="Bruk"
                            defaultValue={item.usage}
                            required
                          />
                        </Col>
                        <Col lg="3">
                          <NumberField
                            name={`parts[${index}].area`}
                            label={
                              <>
                                BTA m<sup>2</sup>
                              </>
                            }
                            step={0.1}
                            defaultValue={item.area}
                          />
                        </Col>
                        <Col>
                          <DeleteButton
                            iconOnly
                            onClick={async () => remove(index)}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* <AddButton onClick={() => append({})}>Legg til bygningsdel</AddButton> */}
    </>
  );
};

export default BuildingFormPartsArray;

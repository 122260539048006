import React from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import Icon from "~/layout/icon";

interface Props {
  errors: string[];
}

const Header = styled.ul`
  margin: -0.75rem -1.25rem 1rem -1.25rem;
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0 0;
`;

const FormErrors: React.FC<Props> = ({ errors }) => {
  return (
    <Alert variant="danger">
      <Header className="d-flex align-items-center">
        <Icon icon="warning" className="mr-2" />
        Ooops, looks like there was an error.
      </Header>
      <ul>
        {errors.map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ul>
    </Alert>
  );
};

export default FormErrors;

import React from "react";
import { Col } from "react-bootstrap";
import { useWatch } from "react-hook-form";
import { CheckboxField, TextField } from "~/form";

const FakturaFields = () => {
  const multi = useWatch({ name: "multi" });

  return (
    <>
      <Col md="auto">
        <CheckboxField name="multi" label="Multi?" />
      </Col>
      <Col lg="2">
        <TextField
          name="number"
          label="Faktura"
          disabled={multi}
          validate={(value: string) => (multi ? true : !!value)}
        />
      </Col>
    </>
  );
};

export default FakturaFields;

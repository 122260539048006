import { MembershipRelation } from "~/graphql-operations";

export const relationLabel = (relation: MembershipRelation) => {
  switch (relation) {
    case MembershipRelation.ProjectManager:
      return "Prosjektansvarlig";
    case MembershipRelation.Partner:
      return "Kvalitetssikret av";
    case MembershipRelation.ConstructionConsultant:
      return "Konsulenter Bygg";
    case MembershipRelation.PlumbingConsultant:
      return "Konsulenter VVS";
    case MembershipRelation.ElectricalConsultant:
      return "Konsulenter Elektro";
    case MembershipRelation.FireConsultant:
      return "Konsulenter Brann";
    case MembershipRelation.ConstructionKsConsultant:
      return "Konsulenter KS Bygg";
    case MembershipRelation.PlumbingKsConsultant:
      return "Konsulenter KS VVS";
    case MembershipRelation.ElectricalKsConsultant:
      return "Konsulenter KS Elektro";
    case MembershipRelation.FireKsConsultant:
      return "Konsulenter KS Brann";
    case MembershipRelation.Client:
      return "Oppdragsgiver";
    case MembershipRelation.Associate:
      return "Kopi";
    case MembershipRelation.Collaborator:
      return "Adgang";
    default:
      return "";
  }
};

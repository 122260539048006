import React from "react";
import Block from "~/layout/Block";
import { BlockList } from "~/layout/BlockList";
import { CostSection } from "~/sections/cost-section";
import { Session, useSession } from "~/session";
import { EditRequestCostLine, NewRequestCostLine, RequestCostLine } from ".";
import { RequestCostBlock } from "../cost-block";

interface Props {
  costSection?: CostSection;
  requestCostBlock?: RequestCostBlock;
  requestCostLines: RequestCostLine[];
}

const EditRequestCostLineList: React.FC<Props> = ({
  requestCostBlock,
  requestCostLines,
}) => {
  const { role } = useSession() as Session;
  const canDelete = ["admin", "editor"].includes(role);

  return (
    <>
      {requestCostLines.length ? (
        <BlockList sub>
          {requestCostLines.map((requestCostLine: RequestCostLine) => {
            return (
              <EditRequestCostLine
                key={requestCostLine.id}
                requestCostBlock={requestCostBlock}
                requestCostLine={requestCostLine}
              />
            );
          })}
        </BlockList>
      ) : (
        <p className="empty-records">No request cost lines created yet.</p>
      )}
      {requestCostBlock && canDelete && (
        <Block>
          <NewRequestCostLine requestCostBlock={requestCostBlock} />
        </Block>
      )}
    </>
  );
};

export default EditRequestCostLineList;

import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { AddFormButton, Form } from "~/form";
import { ReportSelectField } from "~/report";
import { User } from "~/user";
import { createMembership, Permission } from ".";

interface Props {
  user: Pick<User, "id">;
  permissions: Permission[];
  excludeReports?: string[];
  className?: string;
}

const NewSpectator: React.FC<Props> = ({
  user: { id: userId },
  excludeReports,
  permissions,
}) => {
  const createMutation = createMembership();

  const onSubmit = async ({ reportId }: { reportId: string }) => {
    await createMutation({
      userId,
      reportId,
      relation: "accessor",
      permissions,
    });
  };

  return (
    <Form onSubmit={onSubmit} horizontal resetBlank>
      <Row className="align-items-end">
        <Col>
          <ReportSelectField
            name="reportId"
            exclude={excludeReports}
            required
          />
        </Col>
        <Col lg="auto">
          <FormGroup>
            <AddFormButton />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default NewSpectator;

import React from "react";
import { Section, useDeleteSectionMutation } from "~/graphql-operations";
import { DeleteButton } from "~/layout";

const DeleteSectionButton = ({
  section: { id, primary },
}: {
  section: Pick<Section, "id" | "primary">;
}) => {
  const [mutation] = useDeleteSectionMutation({ variables: { input: { id } } });
  return (
    <DeleteButton onClick={() => mutation()} disabled={primary} iconOnly />
  );
};

export default DeleteSectionButton;

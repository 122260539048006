import React from "react";
import { Col, Row } from "react-bootstrap";
import { Form, FormProps, TextField } from "~/form";
import { InvoiceContractor } from "~/graphql-operations";

type Props = FormProps<Partial<InvoiceContractor>>;

const ContractorForm: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Form {...rest}>
      <Row>
        <Col lg="4">
          <TextField name="name" label="Navn på entreprenør" required />
        </Col>
        {children}
      </Row>
    </Form>
  );
};

export default ContractorForm;

import React from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import { ContractorInvoice, CurrencyEnum } from "~/graphql-operations";
import Currency from "~/util/currency";

interface Props {
  invoice: Pick<ContractorInvoice, "amount" | "deposit" | "produced">;
}

const StyledBadge = styled(Badge)`
  font-size: inherit;
`;

const PayoutBadge = ({
  invoice: {
    amount,
    deposit: { amount: deposit },
    produced,
  },
}: Props) => {
  const variant = (() => {
    const offset = Math.round(produced - deposit - amount);
    if (offset === null) return "secondary";
    else if (offset === 0) return "success";
    else if (offset > 0) return "primary";
    else return "danger";
  })();

  return (
    <StyledBadge variant={variant}>
      <Currency currency={CurrencyEnum.Nok} decimals={false}>
        {produced - deposit}
      </Currency>
    </StyledBadge>
  );
};

export default PayoutBadge;

import { upperCase } from "lodash";
import React from "react";
import styled from "styled-components";
import { CurrencyEnum } from "~/graphql-operations";
import { formatCurrency, FormatCurrencyOptions } from "./helpers";

export type CurrencyProps = {
  currency?: CurrencyEnum;
  style?: React.CSSProperties;
} & FormatCurrencyOptions;

const Wrap = styled.span.attrs({ className: "currency" })`
  white-space: nowrap;
`;

const CurrencyWrap = styled.span`
  padding-left: 0.25rem;
`;

const CurrencyComponent: React.FC<CurrencyProps> = ({
  currency,
  decimals,
  maxDecimals,
  minDecimals,
  children,
  ...rest
}) => {
  if (children === null) return <span>-</span>;
  return (
    <Wrap {...rest}>
      {children &&
        (typeof children === "number"
          ? formatCurrency(
              children,
              decimals !== undefined
                ? { decimals }
                : {
                    maxDecimals,
                    minDecimals,
                  }
            )
          : children)}
      {currency && <CurrencyWrap>{upperCase(currency)}</CurrencyWrap>}
    </Wrap>
  );
};

export default CurrencyComponent;

import { ApolloProvider, useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { AttachmentField, Fieldset, Form, TextField } from "~/form";
import { attachmentInput } from "~/form/attachment";
import { client } from "~/setup";
import { Company, COMPANY_QUERY } from ".";
import { useUpdateCompany } from "./hooks";

interface Props {
  id: string;
}

const Wrap = styled.div`
  margin: auto;
  max-width: 600px;
`;

const EditCompanyPage: React.FC<Props> = ({ id }) => {
  const updateCompany = useUpdateCompany();
  const { loading, error, data } = useQuery<{ company: Company }>(
    COMPANY_QUERY,
    {
      variables: { id },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error! {error.message}</p>;

  const { name, logo } = data?.company || {};
  const defaultValues = {
    id,
    name,
    logo: logo && attachmentInput(logo),
  };

  return (
    <Wrap>
      <Form autoSave onSubmit={updateCompany} defaultValues={defaultValues}>
        <Fieldset title="Kunde">
          <TextField label="navn" name="name" required />
          <AttachmentField
            name="logo"
            accept="image/png, image/gif, image/jpeg"
          />
          {logo && <img src={logo.url} />}
        </Fieldset>
      </Form>
    </Wrap>
  );
};

const ApolloWrap: React.FC<Props> = ({ id }) => (
  <ApolloProvider client={client}>
    <EditCompanyPage id={id} />
  </ApolloProvider>
);

export default ApolloWrap;

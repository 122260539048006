import React from "react";
import NumberFormat from "react-number-format";
import { formatCurrency } from "~/util/currency";

interface NumberInputProps
  extends Omit<React.ComponentProps<typeof NumberFormat>, "placeholder"> {
  placeholder?: number;
  decimals?: boolean;
}

const NumberInput = ({
  placeholder,
  decimals,
  className,
  ...rest
}: NumberInputProps) => {
  return (
    <NumberFormat
      className={className ? `form-control ${className}` : "form-control"}
      decimalScale={2}
      decimalSeparator=","
      displayType="input"
      fixedDecimalScale={!!decimals}
      // prefix="$"
      thousandSeparator=" "
      placeholder={placeholder ? formatCurrency(placeholder) : undefined}
      {...rest}
    />
  );
};

export default NumberInput;

import { pick } from "lodash";
import React from "react";
import { Col } from "react-bootstrap";
import { AttachmentField, Form, Row, TextField } from "~/form";
import { attachmentInput } from "~/form/attachment";
import {
  UpdateRequestLineInput,
  useUpdateRequestLineMutation,
} from "~/graphql-operations";
import { RequestLine } from ".";

interface Props {
  requestLine: RequestLine;
}

const EditRequestLine: React.FC<Props> = ({ requestLine }) => {
  // const [titleDisabled, setTitleDisabled] = useState(true);

  const { files } = requestLine;
  const defaultValues = {
    ...pick(requestLine, ["id", "answeredBy", "answer", "reference", "title"]),
    files: files ? attachmentInput(files) : null,
  };

  const [updateRequestLine] = useUpdateRequestLineMutation();

  const onSubmit = async (input: UpdateRequestLineInput) =>
    updateRequestLine({ variables: { input } });

  return (
    <Form autoSave defaultValues={defaultValues} onSubmit={onSubmit}>
      <Row className="align-items-end">
        <Col>
          <TextField
            name="title"
            label="Spørsmål"
            // disabled={titleDisabled}
            style={{ width: "100%" }}
          />
        </Col>
        {/* <Col xs="auto" style={{ paddingBottom: "1rem" }}>
          <IconButton
            icon="edit"
            onClick={() => setTitleDisabled(!titleDisabled)}
          />
        </Col> */}
      </Row>
      <Row className="align-items-start">
        <Col lg="3">
          <TextField name="answeredBy" label="Besvart av" />
        </Col>
        <Col lg="6">
          <TextField name="answer" label="Svar" />
        </Col>
        <Col lg="3">
          <TextField name="reference" label="Reference" />
        </Col>
        <Col lg="4">
          <AttachmentField
            name="files"
            label="Files"
            attachments={files}
            multiple
          />
        </Col>
      </Row>
    </Form>
  );
};

export default EditRequestLine;

import React from "react";
import { SubmitHandler } from "react-hook-form";
import { Block } from "~/layout";
import { EditCVLines, NewCVLine } from ".";
import { CVLine } from "..";
import { EditCVLinesProps, LinesInput } from "./EditCVLines";

interface Props {
  lines: EditCVLinesProps["lines"];
  title: string;
  onSubmit: (input: LinesInput) => void;
  addNewLine: SubmitHandler<CVLine>;
}

const EditCVGroup: React.FC<Props> = ({
  lines,
  title,
  onSubmit,
  addNewLine,
}) => {
  return (
    <Block style={{ margin: "2rem" }}>
      <h3>{title}</h3>
      <EditCVLines lines={lines} onSubmit={onSubmit} />
      <NewCVLine onSubmit={addNewLine} />
    </Block>
  );
};

export default EditCVGroup;

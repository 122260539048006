import styled, { css } from "styled-components";

const Label = styled.label<{
  bulletColor?: string;
}>`
  display: block;
  align-items: center;
  text-transform: uppercase;
  word-break: break-all;
  font-size: 0.75rem;
  margin-bottom: 0.25em;
  font-family: "Moon", sans-serif;

  ${({ bulletColor }) =>
    bulletColor &&
    css`
      &:before {
        content: "";
        display: block;
        margin: 0.25rem auto;
        width: 10px;
        height: 10px;
        top: 10px;
        left: -2px;
        background: ${bulletColor};
        border-radius: 50%;
      }
    `}
`;

export default Label;

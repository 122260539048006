import { gql, useQuery } from "@apollo/client";
import React from "react";
import { WordLoader } from "~/layout/Loading";
import { ShortcodeProps } from "./Shortcode";

const ADDRESS_REPORT_QUERY = gql`
  query ProsjektinformasjonAdresse($slug: ID!) {
    report(slug: $slug) {
      id
      address
    }
  }
`;

const ProsjektinformasjonAdresse: React.FC<ShortcodeProps> = ({
  report: { slug },
}) => {
  const { loading, error, data } = useQuery(ADDRESS_REPORT_QUERY, {
    variables: { slug },
  });

  if (error) return <p>Error</p>;
  const { address } = data?.report || {};

  return <>{loading ? <WordLoader /> : `${address}`}</>;
};

export default ProsjektinformasjonAdresse;

import { gql } from "@apollo/client";
import { InvoiceContractor } from "~/invoice/contractor";
import { invoiceEntryCategorizationFragment } from "~/invoice/entry/categorization";
import { InvoiceEntryCategory } from "~/invoice/entry/category";
import { ResourceInterface } from "~/node";
import { invoiceEntryCategoryGroupFragment } from "./../../categorization/fragments";

export interface InvoiceEntryCategoryGroup extends ResourceInterface {
  contractorId: string;
  contractor: InvoiceContractor;
  name: string;
  order: number;
  categories: InvoiceEntryCategory[];
}

export const INVOICE_ENTRY_CATEGORY_GROUP_CREATE_MUTATION = gql`
  mutation InvoiceEntryCategoryGroupCreate(
    $input: InvoiceEntryCategoryGroupCreateInput!
  ) {
    invoiceEntryCategoryGroupCreate(input: $input) {
      errors
      invoiceEntryCategoryGroup {
        id
        contractor {
          ...InvoiceEntryCategorizationFields
        }
      }
    }
  }
  ${invoiceEntryCategorizationFragment}
`;

export const INVOICE_ENTRY_CATEGORY_GROUP_UPDATE_MUTATION = gql`
  mutation InvoiceEntryCategoryGroupUpdate(
    $input: InvoiceEntryCategoryGroupUpdateInput!
  ) {
    invoiceEntryCategoryGroupUpdate(input: $input) {
      errors
      invoiceEntryCategoryGroup {
        ...InvoiceEntryCategoryGroupFields
      }
    }
  }
  ${invoiceEntryCategoryGroupFragment}
`;

export const INVOICE_ENTRY_CATEGORY_GROUP_DELETE_MUTATION = gql`
  mutation InvoiceEntryCategoryGroupDelete(
    $input: InvoiceEntryCategoryGroupDeleteInput!
  ) {
    invoiceEntryCategoryGroupDelete(input: $input) {
      errors
      invoiceEntryCategoryGroup {
        id
        contractor {
          ...InvoiceEntryCategorizationFields
        }
      }
    }
  }
  ${invoiceEntryCategorizationFragment}
`;

import React from "react";
import { Col } from "react-bootstrap";
import { AddFormButton, Fieldset, Form, Row, TextField } from "~/form";
import { Block } from "~/layout";
import { useCreateCompany } from "./hooks";

const NewCompany: React.FC = () => {
  const createCompany = useCreateCompany();
  return (
    <Block>
      <Form onSubmit={createCompany} horizontal resetBlank>
        <Fieldset title="Kunde">
          <Row>
            <Col>
              <TextField label="navn" name="name" required />
            </Col>
            <Col lg="auto">
              <AddFormButton>Legg til ny kunde</AddFormButton>
            </Col>
          </Row>
        </Fieldset>
      </Form>
    </Block>
  );
};

export default NewCompany;
